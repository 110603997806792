import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProhibitDealerLoginMutationVariables = Types.Exact<{
    input: Types.ProhibitDealerLoginInput;
}>;

export type ProhibitDealerLoginMutation = { __typename?: 'Mutation'; prohibit_dealer_login: Types.SuccessResponse };

export const ProhibitDealerLoginDocument = gql`
    mutation prohibitDealerLogin($input: ProhibitDealerLoginInput!) {
        prohibit_dealer_login(input: $input)
    }
`;
export type ProhibitDealerLoginMutationFn = Apollo.MutationFunction<
    ProhibitDealerLoginMutation,
    ProhibitDealerLoginMutationVariables
>;

/**
 * __useProhibitDealerLoginMutation__
 *
 * To run a mutation, you first call `useProhibitDealerLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProhibitDealerLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prohibitDealerLoginMutation, { data, loading, error }] = useProhibitDealerLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProhibitDealerLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<ProhibitDealerLoginMutation, ProhibitDealerLoginMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ProhibitDealerLoginMutation, ProhibitDealerLoginMutationVariables>(
        ProhibitDealerLoginDocument,
        options,
    );
}
export type ProhibitDealerLoginMutationHookResult = ReturnType<typeof useProhibitDealerLoginMutation>;
export type ProhibitDealerLoginMutationResult = Apollo.MutationResult<ProhibitDealerLoginMutation>;
export type ProhibitDealerLoginMutationOptions = Apollo.BaseMutationOptions<
    ProhibitDealerLoginMutation,
    ProhibitDealerLoginMutationVariables
>;
