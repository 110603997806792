import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditMachineGameSettingMutationVariables = Types.Exact<{
    input: Types.EditMachineGameSettingInput;
}>;

export type EditMachineGameSettingMutation = {
    __typename?: 'Mutation';
    edit_machine_game_setting: Types.SuccessResponse;
};

export const EditMachineGameSettingDocument = gql`
    mutation editMachineGameSetting($input: EditMachineGameSettingInput!) {
        edit_machine_game_setting(input: $input)
    }
`;
export type EditMachineGameSettingMutationFn = Apollo.MutationFunction<
    EditMachineGameSettingMutation,
    EditMachineGameSettingMutationVariables
>;

/**
 * __useEditMachineGameSettingMutation__
 *
 * To run a mutation, you first call `useEditMachineGameSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMachineGameSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMachineGameSettingMutation, { data, loading, error }] = useEditMachineGameSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMachineGameSettingMutation(
    baseOptions?: Apollo.MutationHookOptions<EditMachineGameSettingMutation, EditMachineGameSettingMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<EditMachineGameSettingMutation, EditMachineGameSettingMutationVariables>(
        EditMachineGameSettingDocument,
        options,
    );
}
export type EditMachineGameSettingMutationHookResult = ReturnType<typeof useEditMachineGameSettingMutation>;
export type EditMachineGameSettingMutationResult = Apollo.MutationResult<EditMachineGameSettingMutation>;
export type EditMachineGameSettingMutationOptions = Apollo.BaseMutationOptions<
    EditMachineGameSettingMutation,
    EditMachineGameSettingMutationVariables
>;
