import { DealerStatus, GranProSubscriptionPlan, MachineStatus, ProKitStatus, ShopStatus } from 'types/graphql';
import { isAfter, parse } from 'date-fns';
import { TFunction } from 'i18next';

export const resolverDealerStatusToStr = (dealerStatus: DealerStatus, t: TFunction) => {
    switch (dealerStatus) {
        case DealerStatus.Deleted:
            return `${t('statusOption3', { ns: 'common' })}`;
        case DealerStatus.Enabled:
            return `${t('statusOption2', { ns: 'common' })}`;
        case DealerStatus.OutOfService:
            return `${t('disable', { ns: 'common' })}`;
        default:
            return '-';
    }
};

export const resolverShopStatusToStr = (shopStatus: ShopStatus, t: TFunction) => {
    switch (shopStatus) {
        case ShopStatus.Deleted:
            return `${t('statusOption3', { ns: 'common' })}`;
        case ShopStatus.Enabled:
            return `${t('statusOption2', { ns: 'common' })}`;
        case ShopStatus.OutOfService:
            return `${t('statusOption4', { ns: 'common' })}`;
        default:
            return '-';
    }
};

export const resolverShopStatusToStrWithDisable = (shopStatus: ShopStatus, t: TFunction) => {
    switch (shopStatus) {
        case ShopStatus.Deleted:
            return `${t('statusOption3', { ns: 'common' })}`;
        case ShopStatus.Enabled:
            return `${t('statusOption2', { ns: 'common' })}`;
        case ShopStatus.OutOfService:
            return `${t('disable', { ns: 'common' })}`;
        default:
            return '-';
    }
};

export const resolverProKitStatusToStr = (proKitStatus: ProKitStatus, t: TFunction) => {
    switch (proKitStatus) {
        case ProKitStatus.Deleted:
            return `${t('statusOption3', { ns: 'common' })}`;
        case ProKitStatus.InService:
            return `${t('statusOption2', { ns: 'common' })}`;
        case ProKitStatus.OutOfService:
            return `${t('statusOption4', { ns: 'common' })}`;
        default:
            return '-';
    }
};

export const resolverMachineStatusToStr = (machineStatus: MachineStatus, t: TFunction) => {
    switch (machineStatus) {
        case MachineStatus.Deleted:
            return `${t('statusOption3', { ns: 'common' })}`;
        case MachineStatus.Enabled:
            return `${t('statusOption2', { ns: 'common' })}`;
        case MachineStatus.OutOfService:
            return `${t('disable', { ns: 'common' })}`;
        default:
            return '-';
    }
};

export const resolverSubscriptionPlanToStr = (plan: GranProSubscriptionPlan, t: TFunction) => {
    switch (plan) {
        case GranProSubscriptionPlan.Basic:
            return `${t('planOption2', { ns: 'common' })}`;
        case GranProSubscriptionPlan.Enterprise:
            return `${t('planOption3', { ns: 'common' })}`;
        default:
            return `${t('planOption2', { ns: 'common' })}`;
    }
};

export const revertSubscriptionPlanToStr = (plan: GranProSubscriptionPlan, t: TFunction) => {
    switch (plan) {
        case GranProSubscriptionPlan.Enterprise:
            return `${t('planOption2', { ns: 'common' })}`;
        case GranProSubscriptionPlan.Basic:
            return `${t('planOption3', { ns: 'common' })}`;
        default:
            return `${t('planOption2', { ns: 'common' })}`;
    }
};

export const currentDateOfMonth = () => {
    const date = new Date();
    return String(date.getDate()).padStart(2, '0');
};

export const currentMonth = () => {
    const date = new Date();
    return String(date.getMonth() + 1).padStart(2, '0');
};
export const currentYear = () => {
    const date = new Date();
    return date.getFullYear();
};

export const isCurrentDateGreaterThanPeriodEnd = (periodEndTime: Date): boolean => {
    const currentDateTime = new Date();
    return isAfter(currentDateTime, periodEndTime);
};
