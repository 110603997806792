import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDealerByIdQueryVariables = Types.Exact<{
    input: Types.DealerInput;
}>;

export type GetDealerByIdQuery = {
    __typename?: 'Query';
    dealer: {
        __typename?: 'Dealer';
        id: number;
        name: string;
        iconUrl?: string | null;
        status: Types.DealerStatus;
        email: string;
        languageCode: string;
        countryCode: string;
        zipCode: string;
        province: string;
        address: string;
        phoneNumber: string;
        lastInformationEditedAt?: Date | null;
        lastInformationEditedBy?: Types.DealerInformationEditor | null;
        isActivated: boolean;
        dealerType: Types.DealerType;
        partnerDealer?: {
            __typename?: 'Dealer';
            dealerType: Types.DealerType;
            id: number;
            email: string;
            name: string;
        } | null;
        shops: Array<{
            __typename?: 'Shop';
            id: number;
            name: string;
            email: string;
            iconUrl?: string | null;
            beaconId?: string | null;
            status: Types.ShopStatus;
            languageCode: string;
            countryCode: string;
            zipCode: string;
            province: string;
            address: string;
            phoneNumber: string;
            lastInformationEditedAt?: Date | null;
            lastInformationEditedBy?: Types.ShopInformationEditor | null;
            isActivated: boolean;
            machines: Array<{
                __typename?: 'Machine';
                id: number;
                machineName: string;
                dummyPlayerId?: number | null;
                serviceMode: Types.ProKitServiceModeOption;
                freeModeStart?: Date | null;
                freeModeEnd?: Date | null;
                status: Types.MachineStatus;
                lastLoginAt?: Date | null;
                isPasswordRequired: boolean;
                isOnline: boolean;
                playerEntries: Array<{
                    __typename?: 'Player';
                    id: number;
                    type: Types.PlayerType;
                    gran_id: string;
                    player_name: string;
                    icon_url?: string | null;
                    card_bg_index: number;
                    card_bg_url?: string | null;
                    card_bg_color: Types.CardBgColor;
                } | null>;
                proKit?: {
                    __typename?: 'ProKit';
                    id: number;
                    status: Types.ProKitStatus;
                    cpuUsage?: number | null;
                    cpuTemperature?: number | null;
                    memoryUsage?: number | null;
                    modelName: string;
                    totalActivateCount: number;
                    subscriptionPlan?: Types.GranProSubscriptionPlan | null;
                } | null;
            }>;
        }>;
        distributor: {
            __typename?: 'Distributor';
            id: number;
            name: string;
            status: Types.DistributorStatus;
            address: string;
            countryCode: string;
            languageCode: string;
            phoneNumber: string;
            province: string;
            zipCode: string;
        };
    };
};

export const GetDealerByIdDocument = gql`
    query getDealerById($input: DealerInput!) {
        dealer(input: $input) {
            id
            name
            iconUrl
            status
            email
            languageCode
            countryCode
            zipCode
            province
            address
            phoneNumber
            lastInformationEditedAt
            lastInformationEditedBy
            isActivated
            dealerType
            partnerDealer {
                dealerType
                id
                email
                name
            }
            shops {
                id
                name
                email
                iconUrl
                beaconId
                status
                languageCode
                countryCode
                zipCode
                province
                address
                phoneNumber
                lastInformationEditedAt
                lastInformationEditedBy
                isActivated
                machines {
                    id
                    machineName
                    dummyPlayerId
                    serviceMode
                    freeModeStart
                    freeModeEnd
                    status
                    lastLoginAt
                    isPasswordRequired
                    isOnline
                    playerEntries {
                        id
                        type
                        gran_id
                        player_name
                        icon_url
                        card_bg_index
                        card_bg_url
                        card_bg_color
                    }
                    proKit {
                        id
                        status
                        cpuUsage
                        cpuTemperature
                        memoryUsage
                        modelName
                        totalActivateCount
                        subscriptionPlan
                    }
                }
            }
            distributor {
                id
                name
                status
                address
                countryCode
                languageCode
                phoneNumber
                province
                zipCode
            }
        }
    }
`;

/**
 * __useGetDealerByIdQuery__
 *
 * To run a query within a React component, call `useGetDealerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealerByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDealerByIdQuery(
    baseOptions: Apollo.QueryHookOptions<GetDealerByIdQuery, GetDealerByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetDealerByIdQuery, GetDealerByIdQueryVariables>(GetDealerByIdDocument, options);
}
export function useGetDealerByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDealerByIdQuery, GetDealerByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetDealerByIdQuery, GetDealerByIdQueryVariables>(GetDealerByIdDocument, options);
}
export type GetDealerByIdQueryHookResult = ReturnType<typeof useGetDealerByIdQuery>;
export type GetDealerByIdLazyQueryHookResult = ReturnType<typeof useGetDealerByIdLazyQuery>;
export type GetDealerByIdQueryResult = Apollo.QueryResult<GetDealerByIdQuery, GetDealerByIdQueryVariables>;
