import { createContext, Dispatch, SetStateAction } from 'react';

type SidebarContextType = {
    heading?: string;
    onChange?: () => void;
    toggleSidebar?: boolean;
    setToggleSidebar?: Dispatch<SetStateAction<boolean>>;
    sidebarWidth?: any;
};

export const SidebarContext = createContext<SidebarContextType>({});
