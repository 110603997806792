import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LiftShopLoginProhibitionMutationVariables = Types.Exact<{
    input: Types.LiftShopLoginProhibitionInput;
}>;

export type LiftShopLoginProhibitionMutation = {
    __typename?: 'Mutation';
    lift_shop_login_prohibition: Types.SuccessResponse;
};

export const LiftShopLoginProhibitionDocument = gql`
    mutation liftShopLoginProhibition($input: LiftShopLoginProhibitionInput!) {
        lift_shop_login_prohibition(input: $input)
    }
`;
export type LiftShopLoginProhibitionMutationFn = Apollo.MutationFunction<
    LiftShopLoginProhibitionMutation,
    LiftShopLoginProhibitionMutationVariables
>;

/**
 * __useLiftShopLoginProhibitionMutation__
 *
 * To run a mutation, you first call `useLiftShopLoginProhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLiftShopLoginProhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [liftShopLoginProhibitionMutation, { data, loading, error }] = useLiftShopLoginProhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLiftShopLoginProhibitionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LiftShopLoginProhibitionMutation,
        LiftShopLoginProhibitionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LiftShopLoginProhibitionMutation, LiftShopLoginProhibitionMutationVariables>(
        LiftShopLoginProhibitionDocument,
        options,
    );
}
export type LiftShopLoginProhibitionMutationHookResult = ReturnType<typeof useLiftShopLoginProhibitionMutation>;
export type LiftShopLoginProhibitionMutationResult = Apollo.MutationResult<LiftShopLoginProhibitionMutation>;
export type LiftShopLoginProhibitionMutationOptions = Apollo.BaseMutationOptions<
    LiftShopLoginProhibitionMutation,
    LiftShopLoginProhibitionMutationVariables
>;
