import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import MachineReceiptTable from './MachineReceiptTable';
import CollectionDateReceiptTable from './CollectionDateReceiptTable';
import { AccountType } from 'types/user';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import SkeletonTableRow from 'components/skeleton/SkeletonTableRow';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { useGetMachinesWithIncomeOfShopQuery } from 'graphql/queries/shop/__generated__/getMachinesWithIncomeOfShop.generated';
import { IncomeDetailParams } from '../index';
import { useGetShopIncomeCollectionEventListQuery } from 'graphql/queries/shop/__generated__/getShopIncomeCollectionEventList.generated';
import { useTranslation } from 'react-i18next';
import { PaginationState } from '@tanstack/react-table';
import { DefaultPageSize } from '../../../../../../constant/defaultValues';

export type IncomeTabState = 'machine-detail' | 'collection-date';

type ReceiptProps = IncomeDetailParams & {
    [key: string]: any;
};
export default function Receipt(props: ReceiptProps) {
    const { id, tab, ...rest } = props;
    const { accountType } = useAuthContext();
    const [tabState, setTabState] = useState<IncomeTabState>(
        tab === 'collection-date' ? 'collection-date' : 'machine-detail',
    );

    const [rate, setRate] = useState<number>(1);
    const [previousPageIndex, setPreviousPageIndex] = useState<number>(0);
    const [paginationState, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: DefaultPageSize,
    });

    const [endCursor, setEndCursor] = useState<string | null>(null);

    const textColor = useColorModeValue('#4318FF', 'white');
    const textColorTertiary = useColorModeValue('secondaryGray.600', 'secondaryGray.500');
    const { t } = useTranslation(['common']);
    const {
        isOpen: isOpenMachineWithIncomeOfShopResult,
        onOpen: onOpenMachineWithIncomeOfShopResult,
        onClose: onCloseMachineWithIncomeOfShopResult,
    } = useDisclosure();

    const {
        isOpen: isOpenHistoryCollectionResult,
        onOpen: onOpenHistoryCollectionResult,
        onClose: onCloseHistoryCollectionResult,
    } = useDisclosure();

    const getMachinesWithIncomeOfShopResult = useGetMachinesWithIncomeOfShopQuery({
        variables: {
            input: {
                shopId: Number(id),
            },
        },
    });

    const getShopIncomeCollectionEventListResult = useGetShopIncomeCollectionEventListQuery({
        variables: {
            input: {
                shopId: Number(id),
                pagination:
                    paginationState.pageIndex >= previousPageIndex
                        ? {
                              first: paginationState.pageSize,
                              after: endCursor,
                              // first: 200,
                          }
                        : {
                              last: paginationState.pageSize,
                              before: endCursor,
                          },
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    useEffect(() => {
        if (
            getMachinesWithIncomeOfShopResult.data &&
            getMachinesWithIncomeOfShopResult.data?.get_machines_with_income_of_shop &&
            getMachinesWithIncomeOfShopResult.data?.get_machines_with_income_of_shop?.length > 0 &&
            getMachinesWithIncomeOfShopResult.data?.get_machines_with_income_of_shop[0].machine?.shop
                ?.incomeConversionRate
        ) {
            setRate(
                getMachinesWithIncomeOfShopResult.data?.get_machines_with_income_of_shop[0].machine?.shop
                    ?.incomeConversionRate,
            );
        }
    }, [getMachinesWithIncomeOfShopResult]);

    useEffect(() => {
        // setPagination({
        //     pageIndex: 0,
        //     pageSize: paginationState.pageSize,
        // });
        console.log('paginationState.pageIndex: ', paginationState.pageIndex);
        console.log('previousPageIndex: ', previousPageIndex);

        // const after =
        //     getShopIncomeCollectionEventListResult.data &&
        //     getShopIncomeCollectionEventListResult.data.get_shop_income_collection_event_list?.edges?.length > 0
        //         ? getShopIncomeCollectionEventListResult.data.get_shop_income_collection_event_list?.pageInfo.endCursor
        //         : null;
        //
        // const before =
        //     getShopIncomeCollectionEventListResult.data &&
        //     getShopIncomeCollectionEventListResult.data.get_shop_income_collection_event_list?.edges?.length > 0 &&
        //     getShopIncomeCollectionEventListResult.data.get_shop_income_collection_event_list?.edges[0]
        //         ? getShopIncomeCollectionEventListResult.data.get_shop_income_collection_event_list?.edges[0].cursor
        //         : null;
        console.log('endCursor: ', endCursor);
        const pageOption =
            paginationState.pageIndex >= previousPageIndex
                ? {
                      first: paginationState.pageSize,
                      after: endCursor,
                  }
                : {
                      last: paginationState.pageSize,
                      before: endCursor,
                  };
        console.log('pageOption: ', pageOption);
        getShopIncomeCollectionEventListResult
            .fetchMore({
                variables: {
                    input: {
                        shopId: Number(id),
                        pagination: pageOption,
                    },
                },
                updateQuery: (previousQueryResult, options) => {
                    const { fetchMoreResult } = options;
                    if (!fetchMoreResult) {
                        return previousQueryResult;
                    }

                    // const previousEdges = previousQueryResult.get_shop_income_collection_event_list.edges;
                    const fetchMoreEdges = fetchMoreResult.get_shop_income_collection_event_list.edges;

                    return {
                        ...previousQueryResult,
                        get_shop_income_collection_event_list: {
                            ...previousQueryResult.get_shop_income_collection_event_list,
                            // edges: [...previousEdges, ...fetchMoreEdges],
                            edges: [...fetchMoreEdges],
                            pageInfo: fetchMoreResult.get_shop_income_collection_event_list.pageInfo,
                            totalCount: fetchMoreResult.get_shop_income_collection_event_list.totalCount,
                        },
                    };
                },
            })
            .then((r) => {
                // setPagination({
                //     pageIndex: paginationState.pageIndex,
                //     pageSize: paginationState.pageSize,
                // });
                return r;
            })
            .catch((e) => {
                console.error(e);
            });
    }, [paginationState.pageIndex]);

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: paginationState.pageSize,
        });
    }, [paginationState.pageSize]);

    return (
        <Card justifySelf="center" alignSelf="center" py="30px" px={{ base: '15px', md: '30px' }} {...rest}>
            {accountType === AccountType.dealer && (
                <Flex direction="column" pb={{ base: '20px', md: '34px' }} px={{ base: '0px', md: '0px' }}>
                    <Box>
                        <Tabs
                            variant="soft-rounded"
                            colorScheme="brandTabs"
                            mb="60px"
                            defaultIndex={tabState === 'collection-date' ? 1 : 0}
                        >
                            <TabList overflowX={{ sm: 'scroll', lg: 'unset' }}>
                                <Flex>
                                    <Tab
                                        pl="5px"
                                        pb="0px"
                                        flexDirection="column"
                                        onClick={function () {
                                            setTabState('machine-detail');
                                        }}
                                        bg="unset"
                                        _selected={{
                                            bg: 'none',
                                        }}
                                        _focus={{ border: 'none' }}
                                        minW="max-content"
                                    >
                                        <Flex align="center">
                                            <Text
                                                color={tabState === 'machine-detail' ? textColor : textColorTertiary}
                                                fontSize="16px"
                                                fontWeight="500"
                                            >
                                                {t('machines', { ns: 'common' })}
                                            </Text>
                                        </Flex>
                                        <Box
                                            height="4px"
                                            w="100%"
                                            transition="0.1s linear"
                                            bg={tabState === 'machine-detail' ? 'brand.500' : 'transparent'}
                                            mt="5px"
                                            borderRadius="30px"
                                        />
                                    </Tab>
                                    <Tab
                                        pb="0px"
                                        flexDirection="column"
                                        onClick={function () {
                                            setTabState('collection-date');
                                        }}
                                        bg="unset"
                                        _selected={{
                                            bg: 'none',
                                        }}
                                        _focus={{ border: 'none' }}
                                        minW="max-content"
                                    >
                                        <Flex align="center">
                                            <Text
                                                color={tabState === 'collection-date' ? textColor : textColorTertiary}
                                                fontSize="16px"
                                                fontWeight="500"
                                            >
                                                {t('collectionDateLabel', { ns: 'common' })}
                                            </Text>
                                        </Flex>
                                        <Box
                                            height="4px"
                                            w="100%"
                                            transition="0.1s linear"
                                            bg={tabState === 'collection-date' ? 'brand.500' : 'transparent'}
                                            mt="5px"
                                            borderRadius="30px"
                                        />
                                    </Tab>
                                </Flex>
                            </TabList>
                            <TabPanels pt="30px" height={'50vh'}>
                                <TabPanel px="0px">
                                    <ApolloQueryBoundary
                                        queryResult={getMachinesWithIncomeOfShopResult}
                                        isOpen={isOpenMachineWithIncomeOfShopResult}
                                        onClose={onCloseMachineWithIncomeOfShopResult}
                                        onOpen={onOpenMachineWithIncomeOfShopResult}
                                        loadingComponent={<SkeletonTableRow />}
                                        render={({ data: { get_machines_with_income_of_shop } }) => {
                                            const totalIncome = get_machines_with_income_of_shop.reduce(
                                                (total, obj) =>
                                                    obj.machine?.shop?.incomeConversionRate
                                                        ? total + obj.income * obj.machine?.shop?.incomeConversionRate
                                                        : total + obj.income,
                                                0,
                                            );
                                            return (
                                                <MachineReceiptTable
                                                    machineWithIncomeList={get_machines_with_income_of_shop}
                                                    shopId={Number(id)}
                                                    totalIncome={totalIncome}
                                                />
                                            );
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel px="0px">
                                    <ApolloQueryBoundary
                                        queryResult={getShopIncomeCollectionEventListResult}
                                        isOpen={isOpenHistoryCollectionResult}
                                        onClose={onCloseHistoryCollectionResult}
                                        onOpen={onOpenHistoryCollectionResult}
                                        loadingComponent={<SkeletonTableRow />}
                                        render={({
                                            data: {
                                                get_shop_income_collection_event_list: { edges, totalCount, pageInfo },
                                            },
                                        }) => (
                                            <CollectionDateReceiptTable
                                                pageInfo={pageInfo}
                                                totalCount={totalCount}
                                                edges={edges}
                                                rate={rate}
                                                paginationState={paginationState}
                                                setPagination={setPagination}
                                                previousPageIndex={previousPageIndex}
                                                setPreviousPageIndex={setPreviousPageIndex}
                                                endCursor={endCursor}
                                                setEndCursor={setEndCursor}
                                            />
                                        )}
                                    />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Flex>
            )}
        </Card>
    );
}
