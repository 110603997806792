import { Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import LanguagePopover from '../../../components/navbar/LanguagePopover';
import * as React from 'react';
import FixedLanguagePlugin from '../../../components/fixedPlugin/FixedLanguagePlugin';

function AuthIllustration(props: { children: JSX.Element; illustrationBackground: string }) {
    const { children } = props;
    return (
        <Flex position="relative" h="max-content">
            <FixedLanguagePlugin />
            <Flex
                h={{
                    sm: 'initial',
                    md: 'unset',
                    lg: '100vh',
                    xl: '97vh',
                }}
                w="100%"
                // maxW={false}
                // maxW={{ md: '66%', lg: '1313px' }}
                maxW={{ md: '100%' }}
                // background={'#000000'}
                mx="auto"
                // pt={{ sm: '50px', md: '0px' }}
                // px={{ lg: '30px', xl: '0px' }}
                ps={{ xl: '70px' }}
                justifyContent="center"
                direction="column"
            >
                {children}
            </Flex>
            <FixedPlugin />
        </Flex>
    );
}
// PROPS

AuthIllustration.propTypes = {
    illustrationBackground: PropTypes.string,
    image: PropTypes.any,
};

export default AuthIllustration;
