import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SwitchPartnerDealerStatusMutationVariables = Types.Exact<{
    input: Types.SwitchPartnerDealerStatusInput;
}>;

export type SwitchPartnerDealerStatusMutation = {
    __typename?: 'Mutation';
    switch_partner_dealer_status: Types.SuccessResponse;
};

export const SwitchPartnerDealerStatusDocument = gql`
    mutation switchPartnerDealerStatus($input: SwitchPartnerDealerStatusInput!) {
        switch_partner_dealer_status(input: $input)
    }
`;
export type SwitchPartnerDealerStatusMutationFn = Apollo.MutationFunction<
    SwitchPartnerDealerStatusMutation,
    SwitchPartnerDealerStatusMutationVariables
>;

/**
 * __useSwitchPartnerDealerStatusMutation__
 *
 * To run a mutation, you first call `useSwitchPartnerDealerStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchPartnerDealerStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchPartnerDealerStatusMutation, { data, loading, error }] = useSwitchPartnerDealerStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwitchPartnerDealerStatusMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SwitchPartnerDealerStatusMutation,
        SwitchPartnerDealerStatusMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SwitchPartnerDealerStatusMutation, SwitchPartnerDealerStatusMutationVariables>(
        SwitchPartnerDealerStatusDocument,
        options,
    );
}
export type SwitchPartnerDealerStatusMutationHookResult = ReturnType<typeof useSwitchPartnerDealerStatusMutation>;
export type SwitchPartnerDealerStatusMutationResult = Apollo.MutationResult<SwitchPartnerDealerStatusMutation>;
export type SwitchPartnerDealerStatusMutationOptions = Apollo.BaseMutationOptions<
    SwitchPartnerDealerStatusMutation,
    SwitchPartnerDealerStatusMutationVariables
>;
