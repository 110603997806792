import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegisterShopMutationVariables = Types.Exact<{
    input: Types.RegisterShopInput;
}>;

export type RegisterShopMutation = {
    __typename?: 'Mutation';
    register_shop: {
        __typename?: 'Shop';
        id: number;
        name: string;
        beaconId?: string | null;
        status: Types.ShopStatus;
        email: string;
        languageCode: string;
        countryCode: string;
        zipCode: string;
        province: string;
        address: string;
        phoneNumber: string;
        dealer: {
            __typename?: 'Dealer';
            id: number;
            name: string;
            email: string;
            languageCode: string;
            countryCode: string;
            address: string;
            phoneNumber: string;
        };
        machines: Array<{
            __typename?: 'Machine';
            id: number;
            dummyPlayerId?: number | null;
            serviceMode: Types.ProKitServiceModeOption;
            freeModeStart?: Date | null;
            freeModeEnd?: Date | null;
            status: Types.MachineStatus;
            lastLoginAt?: Date | null;
        }>;
    };
};

export const RegisterShopDocument = gql`
    mutation RegisterShop($input: RegisterShopInput!) {
        register_shop(input: $input) {
            id
            name
            beaconId
            status
            email
            languageCode
            countryCode
            zipCode
            province
            address
            phoneNumber
            dealer {
                id
                name
                email
                languageCode
                countryCode
                address
                phoneNumber
            }
            machines {
                id
                dummyPlayerId
                serviceMode
                freeModeStart
                freeModeEnd
                status
                lastLoginAt
            }
        }
    }
`;
export type RegisterShopMutationFn = Apollo.MutationFunction<RegisterShopMutation, RegisterShopMutationVariables>;

/**
 * __useRegisterShopMutation__
 *
 * To run a mutation, you first call `useRegisterShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerShopMutation, { data, loading, error }] = useRegisterShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterShopMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterShopMutation, RegisterShopMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RegisterShopMutation, RegisterShopMutationVariables>(RegisterShopDocument, options);
}
export type RegisterShopMutationHookResult = ReturnType<typeof useRegisterShopMutation>;
export type RegisterShopMutationResult = Apollo.MutationResult<RegisterShopMutation>;
export type RegisterShopMutationOptions = Apollo.BaseMutationOptions<
    RegisterShopMutation,
    RegisterShopMutationVariables
>;
