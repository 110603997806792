import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveProKitFromMachineMutationVariables = Types.Exact<{
    input: Types.RemoveProKitFromMachineInput;
}>;

export type RemoveProKitFromMachineMutation = {
    __typename?: 'Mutation';
    remove_pro_kit_from_machine: {
        __typename?: 'Machine';
        id: number;
        machineName: string;
        status: Types.MachineStatus;
        shop: { __typename?: 'Shop'; id: number; name: string; iconUrl?: string | null };
        proKit?: { __typename?: 'ProKit'; id: number; serialCode: string; modelName: string } | null;
    };
};

export const RemoveProKitFromMachineDocument = gql`
    mutation removeProKitFromMachine($input: RemoveProKitFromMachineInput!) {
        remove_pro_kit_from_machine(input: $input) {
            id
            machineName
            shop {
                id
                name
                iconUrl
            }
            proKit {
                id
                serialCode
                modelName
            }
            status
        }
    }
`;
export type RemoveProKitFromMachineMutationFn = Apollo.MutationFunction<
    RemoveProKitFromMachineMutation,
    RemoveProKitFromMachineMutationVariables
>;

/**
 * __useRemoveProKitFromMachineMutation__
 *
 * To run a mutation, you first call `useRemoveProKitFromMachineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProKitFromMachineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProKitFromMachineMutation, { data, loading, error }] = useRemoveProKitFromMachineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveProKitFromMachineMutation(
    baseOptions?: Apollo.MutationHookOptions<RemoveProKitFromMachineMutation, RemoveProKitFromMachineMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemoveProKitFromMachineMutation, RemoveProKitFromMachineMutationVariables>(
        RemoveProKitFromMachineDocument,
        options,
    );
}
export type RemoveProKitFromMachineMutationHookResult = ReturnType<typeof useRemoveProKitFromMachineMutation>;
export type RemoveProKitFromMachineMutationResult = Apollo.MutationResult<RemoveProKitFromMachineMutation>;
export type RemoveProKitFromMachineMutationOptions = Apollo.BaseMutationOptions<
    RemoveProKitFromMachineMutation,
    RemoveProKitFromMachineMutationVariables
>;
