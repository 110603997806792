import {
    Button,
    Flex,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AlertIcon } from '../../../../../../components/icons/Icons';
import React from 'react';

export type ConfirmDistributorChangeShopPasswordModalProps = UseDisclosureProps & {
    onHandleConfirm: () => void;
    onCancel: () => void;
    isSubmitting: boolean;
};

export default function ConfirmDistributorChangeShopPasswordModal(
    props: ConfirmDistributorChangeShopPasswordModalProps,
) {
    const { isOpen, onClose, onHandleConfirm, onCancel, isSubmitting } = props;
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common', 'machine', 'dealer', 'shop']);
    const stopBtnColor = '#D11828';
    const cancelBtnColor = '#A3AED0';
    const textHover = useColorModeValue(
        { color: 'secondaryGray.900', bg: '#A3AED2' },
        { color: '#000000', bg: '#A3AED2' },
    );
    const closeModalHandler = () => {
        onClose();
        if (onCancel) {
            onCancel();
        }
    };
    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModalHandler} size="2xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody w="100%" minH={'300px'}>
                    <Flex align="center" direction="column" justifyContent="center" w="100%" minH={'300px'} gap={12}>
                        <VStack gap={1}>
                            <Icon as={AlertIcon} w="50px" h="42px" color={'red.550'} mb={2} />
                            <Text
                                color={textColor}
                                fontSize={{ base: '14px', lg: '16px' }}
                                fontWeight="500"
                                lineHeight="100%"
                            >
                                {t('distributorForceChangeShopPasswordConfirmMessage', { ns: 'shop' })}
                            </Text>
                        </VStack>

                        <HStack gap={7}>
                            <Button
                                backgroundColor={cancelBtnColor}
                                variant="ghost"
                                color="white"
                                fontSize="sm"
                                borderRadius={10}
                                fontWeight="500"
                                minW="140px"
                                onClick={closeModalHandler}
                                _hover={textHover}
                            >
                                {t('cancelBtn', { ns: 'common' })}
                            </Button>

                            <Button
                                backgroundColor={stopBtnColor}
                                variant="ghost"
                                color="white"
                                fontSize="sm"
                                borderRadius="10px"
                                fontWeight="700"
                                minW="140px"
                                _hover={textHover}
                                isLoading={isSubmitting}
                                onClick={onHandleConfirm}
                            >
                                {t('changePasswordLabel', { ns: 'common' })}
                            </Button>
                        </HStack>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
