import { Badge, Box, Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';
import { resolverSubscriptionPlanToStr } from '../../../../../../utils/resolver-enum';
import { GranProSubscriptionPlan } from '../../../../../../types/graphql';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { MachineDetailType } from '../MachineDetailType';
import { FaPlay } from 'react-icons/fa';

type BasicPlanSessionProps = MachineDetailType & {
    isEdit: boolean;
    onUpgradeProKitSubscriptionPlan: () => void;
};
export default function BasicPlanSession(props: BasicPlanSessionProps) {
    const { isEdit, onUpgradeProKitSubscriptionPlan } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSubTitle = useColorModeValue('#707EAE', 'white');
    const planScheme = useColorModeValue('#5193F5', 'brand');
    const enterprisePlanScheme = useColorModeValue('#9F7AEA', 'brand');
    const btnColor = useColorModeValue('purple.500', 'white');
    const whitePlanColor = useColorModeValue('white', 'white');

    const { t } = useTranslation(['common', 'machine']);
    const [isChangePlanToEnterprise, setIsChangePlanToEnterprise] = useState<boolean>(false);

    const onClickSaveChangePlan = () => {
        onUpgradeProKitSubscriptionPlan();
    };

    return (
        <>
            {isEdit && (
                <Flex direction="column" gap={5}>
                    <Flex
                        direction={{ base: 'column', md: 'row' }}
                        justifyContent="center"
                        alignItems={{ base: 'center', md: 'flex-end' }}
                        alignContent="center"
                        w="100%"
                        // gap={15}
                        gap={{ base: 5, md: 10, '2xl': 20 }}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-end"
                            flexDirection="row"
                            gap={{ base: 4, md: 2, xl: 7, '2xl': 6 }}
                        >
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems={'start'}
                                flexDirection="column"
                                gap={1}
                            >
                                <Text color={textColorSubTitle} fontSize="sm" fontWeight="500">
                                    現在のプラン
                                </Text>
                                <Badge
                                    justifyContent="center"
                                    alignItems="center"
                                    alignSelf="center"
                                    textAlign="center"
                                    h="28px"
                                    minW="100px"
                                    backgroundColor={planScheme}
                                    color={whitePlanColor}
                                    fontSize="sm"
                                    borderRadius="6px"
                                    textTransform="capitalize"
                                >
                                    {`${t('planOption2', { ns: 'common' })}`}
                                </Badge>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Icon as={FaPlay} color="#D9D9D9" width="22px" height="22px" mb={1} />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems={'start'}
                                flexDirection="column"
                                gap={1}
                            >
                                <Box display="flex" alignItems="center" flexDirection="row">
                                    {isChangePlanToEnterprise && (
                                        <Icon
                                            as={IoMdCheckmarkCircle}
                                            color="#008CFF"
                                            width="22px"
                                            height="22px"
                                            mr={1}
                                        />
                                    )}
                                    {isChangePlanToEnterprise && (
                                        <Text color={textColorSubTitle} fontSize="sm" fontWeight="500">
                                            新しいプラン
                                        </Text>
                                    )}
                                </Box>
                                <Badge
                                    justifyContent="center"
                                    alignItems="center"
                                    alignSelf="center"
                                    textAlign="center"
                                    h="28px"
                                    minW="100px"
                                    backgroundColor={enterprisePlanScheme}
                                    color={whitePlanColor}
                                    fontSize="sm"
                                    borderRadius="6px"
                                    textTransform="capitalize"
                                >
                                    {`${t('planOption3', { ns: 'common' })}`}
                                </Badge>
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                py="0px"
                                px="4px"
                                onClick={() => setIsChangePlanToEnterprise(!isChangePlanToEnterprise)}
                                variant="outline"
                                fontWeight="500"
                                minW={'65px'}
                                h="38px"
                                borderRadius="10px"
                                borderWidth="2px"
                                borderColor={btnColor}
                                // disabled={isChangePlanToBasic}
                                mb={-1}
                            >
                                <Text fontSize="14px" color={btnColor}>
                                    Enterpriseプランに変更する
                                </Text>
                            </Button>
                        </Box>
                    </Flex>

                    {isChangePlanToEnterprise ? (
                        <Flex direction="row" justifyContent="center" alignItems="center" w="100%" mt={6}>
                            <Button
                                py="0px"
                                px="8px"
                                variant="brand"
                                fontWeight="500"
                                minW="140px"
                                maxH="35px"
                                borderRadius="10px"
                                backgroundColor="#4318FF"
                                mb={1}
                                onClick={onClickSaveChangePlan}
                            >
                                <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                            </Button>
                        </Flex>
                    ) : (
                        <Box px={{ base: 1, md: 15, xl: 1, '2xl': 15 }}>
                            <Box
                                display="flex"
                                borderColor="#A3AED0"
                                borderWidth="1px"
                                px={1}
                                py={4}
                                justifyContent="flex-start"
                                mt={2}
                            >
                                <Text color={textColorSubTitle} fontSize="14px" fontWeight="500" letterSpacing={1}>
                                    <Text as="cite" color={textColorPrimary}>
                                        本日
                                    </Text>
                                    から
                                    <Text as="cite" color={textColorPrimary}>
                                        {resolverSubscriptionPlanToStr(GranProSubscriptionPlan.Enterprise, t)}
                                    </Text>
                                    プランに切り替わります。
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            )}
        </>
    );
}
