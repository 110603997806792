import React from 'react';
import { Box, Flex, Grid, GridItem, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ShopDetailProps } from '../shopDetailTypes';
import { useTranslation } from 'react-i18next';
import MachineStatusElement from 'components/elements/MachineStatusElement';
import { Scrollbars } from 'react-custom-scrollbars-2';

export default function MachineListCard(props: ShopDetailProps & { [x: string]: any }) {
    const { shop, ...rest } = props;
    const textColorPrimary = useColorModeValue('navy.650', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Card py={8} px={{ base: '20px', lg: '30px', '2xl': '50px' }} borderRadius="30px" gap={6} {...rest}>
            <Flex direction="row" justifyContent="space-between" alignItems="center" w={'100%'}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                    {t('machineLabel', { ns: 'common' })}
                </Text>
            </Flex>
            <Flex direction="column" align="center" w="100%" justifyContent="space-between">
                {shop.machines.length < 1 && (
                    <Box w="100%">
                        <Text color={textColorPrimary} fontSize={{ base: '14px', xl: '16px' }} fontWeight="500">
                            {t('noMachineLabel', { ns: 'common' })}
                        </Text>
                    </Box>
                )}
                {shop.machines.length > 0 && (
                    <Scrollbars style={{ height: 160 }}>
                        {shop.machines.map((machine, idx) => (
                            <MachineRow
                                key={idx}
                                name={machine.machineName}
                                isOnline={machine.isOnline}
                                updateTime={new Date(machine.lastLoginAt)}
                            />
                        ))}
                    </Scrollbars>
                )}
            </Flex>
        </Card>
    );
}

function MachineRow(props: { name: string; isOnline: boolean; updateTime: Date | number }) {
    const { name, isOnline } = props;
    const textColorPrimary = useColorModeValue('navy.650', 'white');
    return (
        <Grid templateColumns="repeat(3, 1fr)" py={1.5}>
            <GridItem w="100%" colSpan={2}>
                <Text color={textColorPrimary} fontWeight="500" fontSize={{ base: '14px', xl: '16px' }}>
                    {name}
                </Text>
            </GridItem>
            <GridItem w="100%">
                <MachineStatusElement isOnline={isOnline} isActivated={true} />
            </GridItem>
        </Grid>
    );
}
