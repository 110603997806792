import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ForceSetDealerPasswordByDistributorMutationVariables = Types.Exact<{
    input: Types.ForceSetDealerPasswordByDistributorInput;
}>;

export type ForceSetDealerPasswordByDistributorMutation = {
    __typename?: 'Mutation';
    force_set_dealer_password_by_distributor: Types.SuccessResponse;
};

export const ForceSetDealerPasswordByDistributorDocument = gql`
    mutation forceSetDealerPasswordByDistributor($input: ForceSetDealerPasswordByDistributorInput!) {
        force_set_dealer_password_by_distributor(input: $input)
    }
`;
export type ForceSetDealerPasswordByDistributorMutationFn = Apollo.MutationFunction<
    ForceSetDealerPasswordByDistributorMutation,
    ForceSetDealerPasswordByDistributorMutationVariables
>;

/**
 * __useForceSetDealerPasswordByDistributorMutation__
 *
 * To run a mutation, you first call `useForceSetDealerPasswordByDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceSetDealerPasswordByDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceSetDealerPasswordByDistributorMutation, { data, loading, error }] = useForceSetDealerPasswordByDistributorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForceSetDealerPasswordByDistributorMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ForceSetDealerPasswordByDistributorMutation,
        ForceSetDealerPasswordByDistributorMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ForceSetDealerPasswordByDistributorMutation,
        ForceSetDealerPasswordByDistributorMutationVariables
    >(ForceSetDealerPasswordByDistributorDocument, options);
}
export type ForceSetDealerPasswordByDistributorMutationHookResult = ReturnType<
    typeof useForceSetDealerPasswordByDistributorMutation
>;
export type ForceSetDealerPasswordByDistributorMutationResult =
    Apollo.MutationResult<ForceSetDealerPasswordByDistributorMutation>;
export type ForceSetDealerPasswordByDistributorMutationOptions = Apollo.BaseMutationOptions<
    ForceSetDealerPasswordByDistributorMutation,
    ForceSetDealerPasswordByDistributorMutationVariables
>;
