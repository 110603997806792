import { Button, Flex, GridItem, SimpleGrid, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useTranslation } from 'react-i18next';
import FormProvider, { RHFTextField } from 'components/hook-form';
import * as React from 'react';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ShopDetailProps } from '../shopDetailTypes';
import ConfirmDistributorChangeShopPasswordModal from './ConfirmDistributorChangeShopPasswordModal';
import { useForceSetShopPasswordByDistributorMutation } from 'graphql/mutations/distributor/__generated__/forceSetShopPasswordByDistributor.generated';

type DistributorChangeShopPasswordControlFeatureProps = ShopDetailProps & {
    onEditDisable: () => void;
};

export default function DistributorChangeShopPasswordControlFeature(
    props: DistributorChangeShopPasswordControlFeatureProps,
) {
    const {
        isOpen: isOpenConfirmModalChangeShopPassword,
        onOpen: onOpenConfirmModalChangeShopPassword,
        onClose: onCloseConfirmModalChangeShopPassword,
    } = useDisclosure();
    const { shop, onEditDisable } = props;
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const btnColorScheme = useColorModeValue('#4318FF', 'white');
    const { t } = useTranslation(['common', 'machine', 'dealer', 'shop']);
    const {
        isOpen: isOpenDistributorChangeShopPasswordMutationResult,
        onOpen: onOpenDistributorChangeShopPasswordMutationResult,
        onClose: onCloseDistributorChangeShopPasswordMutationResult,
    } = useDisclosure();

    const [forceSetShopPasswordByDistributorMutationFunc, forceSetShopPasswordByDistributorMutationResult] =
        useForceSetShopPasswordByDistributorMutation();

    const ChangeShopPasswordSchema = Yup.object().shape({
        newPassword: Yup.string().required(`${t('newPasswordRequiredMessage', { ns: 'common' })}`),
    });

    const defaultValues = useMemo(
        () => ({
            newPassword: '',
        }),
        [],
    );

    const methods = useForm({
        mode: 'onBlur',
        resolver: yupResolver(ChangeShopPasswordSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        reset,
        watch,
    } = methods;

    const values = watch();
    const onSubmitDistributorChangeShopPassword = async () => {
        try {
            onCloseConfirmModalChangeShopPassword();
            await forceSetShopPasswordByDistributorMutationFunc({
                variables: {
                    input: {
                        shopId: shop.id,
                        email: shop.email,
                        newPassword: values.newPassword,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenDistributorChangeShopPasswordMutationResult();
                    }
                },
                onError: async () => {
                    onOpenDistributorChangeShopPasswordMutationResult();
                },
            });
        } catch (error) {
            onCloseConfirmModalChangeShopPassword();
            console.error(error);
        }
    };

    const handleSuccessClose = () => {
        reset();
        onEditDisable();
    };

    return (
        <div style={{ width: '100%' }}>
            <ApolloMutationBoundary
                isOpen={isOpenDistributorChangeShopPasswordMutationResult}
                onClose={onCloseDistributorChangeShopPasswordMutationResult}
                onOpen={onOpenDistributorChangeShopPasswordMutationResult}
                onExtraAfterSuccessHandler={handleSuccessClose}
                mutationResult={forceSetShopPasswordByDistributorMutationResult}
                toastSuccessTitle={`${t('distributorForceChangeShopPasswordSuccessTitle', { ns: 'shop' })}`}
                toastSuccessDescription={`${t('distributorForceChangeShopPasswordSuccessMessage', { ns: 'shop' })}`}
            >
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitDistributorChangeShopPassword)}>
                    <SimpleGrid w="100%" columns={{ sm: 1, md: 3 }} alignItems="center" justifyContent="space-between">
                        <GridItem alignItems="center" colSpan={{ sm: 1, md: 2 }}>
                            <Flex
                                direction="row"
                                w={'100%'}
                                alignItems="center"
                                justifyContent={{ sm: 'space-between', md: 'flex-start' }}
                            >
                                <Text
                                    color={textColorSecondary}
                                    fontSize="14px"
                                    fontWeight="400"
                                    alignItems="center"
                                    mr={3}
                                >
                                    {t('passwordLabel', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="newPassword"
                                    isRequired={false}
                                    borderRadius={'12px !important'}
                                    fontWeight="500"
                                    variant="main"
                                    maxW={'200px'}
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </Flex>
                        </GridItem>

                        <GridItem alignItems="center" colSpan={1}>
                            <Flex
                                direction="row"
                                w={'100%'}
                                alignItems="center"
                                justifyContent={{ sm: 'center', md: 'flex-end' }}
                            >
                                <Button
                                    variant="outline"
                                    p="15px 16px"
                                    fontSize="sm"
                                    borderRadius={10}
                                    fontWeight="500"
                                    onClick={onOpenConfirmModalChangeShopPassword}
                                    isDisabled={values.newPassword === ''}
                                    borderColor={btnColorScheme}
                                    h="38px"
                                    minW="160px"
                                    borderWidth="2px"
                                    color={btnColorScheme}
                                >
                                    {t('changePasswordLabel', { ns: 'common' })}
                                </Button>
                            </Flex>
                        </GridItem>
                    </SimpleGrid>

                    <ConfirmDistributorChangeShopPasswordModal
                        onHandleConfirm={onSubmitDistributorChangeShopPassword}
                        onClose={onCloseConfirmModalChangeShopPassword}
                        onOpen={onOpenConfirmModalChangeShopPassword}
                        isOpen={isOpenConfirmModalChangeShopPassword}
                        onCancel={reset}
                        isSubmitting={isSubmitting}
                    />
                </FormProvider>
            </ApolloMutationBoundary>
        </div>
    );
}
