import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDistributorByIdQueryVariables = Types.Exact<{
    input: Types.DistributorInput;
}>;

export type GetDistributorByIdQuery = {
    __typename?: 'Query';
    distributor: {
        __typename?: 'Distributor';
        id: number;
        name: string;
        status: Types.DistributorStatus;
        email: string;
        languageCode: string;
        countryCode: string;
        zipCode: string;
        province: string;
        address: string;
        phoneNumber: string;
        dealers: Array<{
            __typename?: 'Dealer';
            id: number;
            name: string;
            iconUrl?: string | null;
            status: Types.DealerStatus;
            email: string;
            languageCode: string;
            countryCode: string;
            zipCode: string;
            province: string;
            address: string;
            phoneNumber: string;
        }>;
    };
};

export const GetDistributorByIdDocument = gql`
    query getDistributorById($input: DistributorInput!) {
        distributor(input: $input) {
            id
            name
            status
            email
            languageCode
            countryCode
            zipCode
            province
            address
            phoneNumber
            dealers {
                id
                name
                iconUrl
                status
                email
                languageCode
                countryCode
                zipCode
                province
                address
                phoneNumber
            }
        }
    }
`;

/**
 * __useGetDistributorByIdQuery__
 *
 * To run a query within a React component, call `useGetDistributorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributorByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDistributorByIdQuery(
    baseOptions: Apollo.QueryHookOptions<GetDistributorByIdQuery, GetDistributorByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetDistributorByIdQuery, GetDistributorByIdQueryVariables>(
        GetDistributorByIdDocument,
        options,
    );
}
export function useGetDistributorByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDistributorByIdQuery, GetDistributorByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetDistributorByIdQuery, GetDistributorByIdQueryVariables>(
        GetDistributorByIdDocument,
        options,
    );
}
export type GetDistributorByIdQueryHookResult = ReturnType<typeof useGetDistributorByIdQuery>;
export type GetDistributorByIdLazyQueryHookResult = ReturnType<typeof useGetDistributorByIdLazyQuery>;
export type GetDistributorByIdQueryResult = Apollo.QueryResult<
    GetDistributorByIdQuery,
    GetDistributorByIdQueryVariables
>;
