import { Box, Flex, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import ProkitControlCard from './components/ProkitControlCard';
import ProkitActivationHistoryCard, { dummyActivationHistoryTableData } from './components/ProkitActivationHistoryCard';
import ProkitInfoCard from './components/ProkitInfoCard';
import { useHistory, useParams } from 'react-router-dom';
import { isIntegerNumeric } from '../../../../../utils/format-number';
import { PATH_DASHBOARD } from '../../../../../paths';
import { useGetProKitByIdQuery } from 'graphql/queries/prokit/__generated__/getProKitById.generated';
import { ApolloQueryBoundary } from '../../../../../components/graphql/ApolloQueryBoundary';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useAuthContext } from '../../../../../layouts/auth/useAuthContext';
import { AccountType } from '../../../../../types/user';

type ProKitDetailParams = {
    id: string;
};

export default function ProkitDetail() {
    const { id } = useParams<ProKitDetailParams>();
    const { accountType } = useAuthContext();
    const history = useHistory();
    if (!isIntegerNumeric(id)) {
        history.push(PATH_DASHBOARD.prokit.overview);
    }
    const queryProKitDetailResult = useGetProKitByIdQuery({
        variables: {
            input: {
                proKitId: Number(id),
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });
    return (
        <Box pt={{ base: '40px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '20px', xl: '50px' }}>
            {accountType === AccountType.distributor ? (
                <ApolloQueryBoundary
                    queryResult={queryProKitDetailResult}
                    render={({ data: { pro_kit } }) => {
                        return (
                            <SimpleGrid
                                mb="20px"
                                columns={{ sm: 1, md: 1, '2xl': 2 }}
                                spacing={{ base: '20px', xl: '20px' }}
                            >
                                <Flex direction="column">
                                    <ProkitInfoCard prokit={pro_kit} mb="20px" />
                                    <ProkitActivationHistoryCard
                                        tableData={dummyActivationHistoryTableData}
                                        mb="20px"
                                    />
                                    <ProkitControlCard />
                                </Flex>
                                <Flex direction="column"></Flex>
                            </SimpleGrid>
                        );
                    }}
                />
            ) : (
                <RestrictionProkitComponent />
            )}
        </Box>
    );
}

function RestrictionProkitComponent() {
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Flex align="center" justifyContent="center" w="100%">
            <Text color={textColor} fontSize={{ base: '14px', lg: '16px' }} fontWeight="700" lineHeight="100%">
                {t('onlyDistributorPropertyLabel', { ns: 'common' })}
            </Text>
        </Flex>
    );
}
