import { Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from 'react';
import { useState } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { TfiClose } from 'react-icons/tfi';

export default function ProkitControlCard(props: { [x: string]: any }) {
    const { ...rest } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const bgColorSecondary = useColorModeValue('secondaryGray.300', 'brandScheme.700');
    const textColor = useColorModeValue('#4318FF', 'white');
    const [isEdit, setIsEdit] = useState(false);

    const onClickChangeEditable = () => {
        setIsEdit(!isEdit);
    };
    return (
        <Card py="20px" px={{ base: '20px', md: '35px', xl: '50px' }} borderRadius={'30px'} {...rest}>
            <Flex direction={'row'} justifyContent="space-between" alignItems="stretch" w={'100%'}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
                    Control
                </Text>
                {isEdit ? (
                    <Flex direction={'row'} w="35%" mt={0}>
                        <Button
                            p="0px"
                            ms="auto"
                            onClick={onClickChangeEditable}
                            variant="no-hover"
                            bg="transparent"
                            mt={1}
                        >
                            <Icon as={TfiClose} w="22px" h="22px" color={textColorSecondary} mr={1} />
                        </Button>
                    </Flex>
                ) : (
                    <Button
                        p="0px"
                        ms="auto"
                        onClick={onClickChangeEditable}
                        variant="no-hover"
                        bg="transparent"
                        mt={1}
                    >
                        <Icon as={MdModeEdit} w="16px" h="16px" color={textColor} mr={1} />
                        <Text fontSize="16px" color={textColor} fontWeight="bold">
                            Edit
                        </Text>
                    </Button>
                )}
            </Flex>
            {isEdit ? (
                <Flex
                    alignItems="center"
                    direction="column"
                    mb={{ base: '20px' }}
                    gap={5}
                    justifyContent="center"
                    w="100%"
                    mt={5}
                >
                    <ControlFeature featureName="Disable ProKit" btnName="Disable" />
                </Flex>
            ) : (
                <Flex
                    alignItems="center"
                    mb={{ base: '20px' }}
                    p={{ base: '20px' }}
                    justifyContent="center"
                    w="100%"
                    mt={4}
                    backgroundColor={bgColorSecondary}
                >
                    <Text color={textColorPrimary} fontSize="md" fontWeight="400">
                        Remotely control machine reboots, data resets and disables.
                    </Text>
                </Flex>
            )}
        </Card>
    );
}

function ControlFeature(props: { featureName: string; btnName: string; [x: string]: any }) {
    const { featureName, btnName, ...rest } = props;

    const textColor = useColorModeValue('secondaryGray.700', 'white');
    return (
        <Flex justifyContent="center" alignItems="center" w="100%" {...rest}>
            <Flex direction="column" align="start" me="auto">
                <Text color={textColor} fontSize="md" me="6px" fontWeight="500">
                    {featureName}
                </Text>
            </Flex>
            <Button
                colorScheme="red"
                variant="outline"
                p="15px 40px"
                fontSize="sm"
                h="40px"
                w="120px"
                borderRadius={10}
                fontWeight="500"
                ms="auto"
            >
                {btnName}
            </Button>
        </Flex>
    );
}
