import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

export default function OnlineStatus() {
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Flex align="center">
            <Icon as={IoMdCheckmarkCircle} color="blue.400" width="20px" height="20px" mr={1} />
            <Text color={textColor} fontSize="14px" fontWeight="700">
                {t('onlineLabel', { ns: 'common' })}
            </Text>
        </Flex>
    );
}
