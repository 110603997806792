// noinspection DuplicatedCode

import { Box, Button, Flex, Icon, Switch, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { MachineDetailType } from '../MachineDetailType';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { useProhibitShopMachineMutation } from 'graphql/mutations/shop/__generated__/prohibitShopMachine.generated';
import { useLiftShopMachineProhibitionMutation } from 'graphql/mutations/shop/__generated__/liftShopMachineProhibition.generated';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { AccountType } from 'types/user';
import { useTranslation } from 'react-i18next';
import { MachineStatus } from 'types/graphql';
import ResetMachineNotificationControlFeature from './ResetMachineNotificationControlFeature';
import RebootMachineNotificationControlFeature from './RebootMachineNotificationControlFeature';
import LaunchTeamViewerNotificationControlFeature from './LaunchTeamViewerNotificationControlFeature';
import { useEditable } from '../../../../../../components/hooks/useEditable';
import MachineDisableConfirmModal from './MachineDisableConfirmModal';
import { MachineStatusElementWithDisable } from '../../../../../../components/elements/MachineStatusElement';
import React, { useMemo } from 'react';
import { useDeleteMachineMutation } from '../../../../../../graphql/mutations/dealer/__generated__/deleteMachine.generated';
import { GetMachineListOfShopDocument } from '../../../../../../graphql/queries/shop/__generated__/getMachineListOfShop.generated';
import { PATH_DASHBOARD } from '../../../../../../paths';
import { useHistory } from 'react-router-dom';
import DeleteMachineConfirmModal from './DeleteMachineConfirmModal';
import FormProvider from '../../../../../../components/hook-form';
import { useEditMachineInformationMutation } from '../../../../../../graphql/mutations/machine/__generated__/editMachineInformation.generated';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GetMachineByIdDocument } from '../../../../../../graphql/queries/machine/__generated__/getMachineById.generated';
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';
import RemoveProKitFromMachineControlFeature from './RemoveProKitFromMachineControlFeature';
import GranProEditBtn from '../../../../../../components/button/GranProEditBtn';
import UpdateBeaconConfirmModal from './UpdateBeaconConfirmModal';

type EditMachineFormValuesProps = {
    machineName: string;
    isEmittingShopBeacon?: boolean;
    teamViewerId: string;
};
export default function ControlCard(props: MachineDetailType) {
    const { machine } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.700', 'white');
    const { t } = useTranslation(['common', 'machine']);
    const { accountType } = useAuthContext();
    const { isEdit, onChangeEditable, onEditDisable, ...other } = useEditable(false);

    const {
        isOpen: isOpenEditMachineMutationResult,
        onOpen: onOpenEditMachineMutationResult,
        onClose: onCloseEditMachineMutationResult,
    } = useDisclosure();

    const {
        isOpen: isOpenUpdateBeaconModal,
        onOpen: onOpenUpdateBeaconModal,
        onClose: onCloseUpdateBeaconModal,
    } = useDisclosure();

    const [editMachineInformationFunc, editMachineInformationMutationResult] = useEditMachineInformationMutation();

    const EditMachineSchema = Yup.object().shape({
        machineName: Yup.string().required(`${t('machineRequiredMessage', { ns: 'machine' })}`),
        isEmittingShopBeacon: Yup.boolean().notRequired(),
        teamViewerId: Yup.string()
            .notRequired()
            .max(10, `${t('teamviewerMaxLengthMessage', { ns: 'machine' })}`),
    });
    const defaultValues = useMemo(
        () => ({
            machineName: machine.machineName || '',
            isEmittingShopBeacon: machine.isEmittingShopBeacon,
            teamViewerId: machine.teamViewerId || '',
        }),
        [machine],
    );
    const methods = useForm({
        resolver: yupResolver(EditMachineSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        watch,
        reset,
        setValue,
    } = methods;

    const values = watch();
    const onHandleEditMachine = async () => {
        try {
            await editMachineInformationFunc({
                variables: {
                    input: {
                        machineId: machine.id,
                        name: undefined,
                        isEmittingShopBeacon:
                            (typeof values.isEmittingShopBeacon === 'boolean' &&
                                values.isEmittingShopBeacon === machine.isEmittingShopBeacon) ||
                            typeof values.isEmittingShopBeacon !== 'boolean'
                                ? undefined
                                : values.isEmittingShopBeacon,
                        teamViewerId: undefined,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onChangeEditable();
                        onCloseUpdateBeaconModal();
                        onOpenEditMachineMutationResult();
                    }
                },
                onError: async () => {
                    onCloseUpdateBeaconModal();
                    onOpenEditMachineMutationResult();
                },
                refetchQueries: () => [
                    {
                        query: GetMachineByIdDocument,
                        variables: {
                            input: {
                                machineId: machine.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnChangeBeaconId = () => {
        setValue('isEmittingShopBeacon', !values.isEmittingShopBeacon);
        onOpenUpdateBeaconModal();
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenEditMachineMutationResult}
            onClose={onCloseEditMachineMutationResult}
            onOpen={onOpenEditMachineMutationResult}
            mutationResult={editMachineInformationMutationResult}
            toastSuccessTitle={t('editMachineSuccessTitle', { ns: 'machine' })}
            toastSuccessDescription={t('editMachineSuccessMessage', { ns: 'machine' })}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onHandleEditMachine)}>
                <Card py={8} px={{ base: '20px', lg: '30px', '2xl': '30px' }}>
                    <Flex direction="row" justifyContent="space-between" alignItems="stretch" w={'100%'}>
                        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                            {t('control', { ns: 'common' })}
                        </Text>
                        <GranProEditBtn
                            isEdit={isEdit}
                            onChangeEditable={onChangeEditable}
                            disabled={accountType !== AccountType.dealer}
                            onEditDisable={onEditDisable}
                            onExtraHandleCancel={reset}
                            {...other}
                        />
                    </Flex>
                    <Flex alignItems="center" direction="column" gap={5} justifyContent="center" w="100%" mt={5}>
                        {accountType === AccountType.dealer &&
                            (isEdit ? (
                                <Flex direction="row" alignItems="center" w="100%">
                                    <Text color={textColorSecondary} fontSize="md" fontWeight="500" mr={3}>
                                        {t('beacon', { ns: 'common' })}
                                    </Text>
                                    <Switch
                                        checked={values.isEmittingShopBeacon}
                                        onChange={handleOnChangeBeaconId}
                                        colorScheme="brand"
                                        isChecked={values.isEmittingShopBeacon}
                                    />
                                </Flex>
                            ) : (
                                <Flex direction="row" alignItems="center" w="100%">
                                    <Text color={textColorSecondary} fontSize="md" fontWeight="500" mr={3}>
                                        {t('beacon', { ns: 'common' })}
                                    </Text>
                                    <Flex align="center">
                                        {machine.isEmittingShopBeacon ? (
                                            <Icon
                                                as={IoMdCheckmarkCircle}
                                                color="#01B574"
                                                width="22px"
                                                height="22px"
                                                mr={1}
                                            />
                                        ) : (
                                            <Icon
                                                as={IoMdCloseCircle}
                                                color="#EE5D50"
                                                width="22px"
                                                height="22px"
                                                mr={1}
                                            />
                                        )}
                                        <Text color={textColorPrimary} fontSize="14px" fontWeight="700">
                                            {machine.isEmittingShopBeacon
                                                ? `${t('enable', { ns: 'common' })}`
                                                : `${t('disable', { ns: 'common' })}`}
                                        </Text>
                                    </Flex>
                                </Flex>
                            ))}
                        {accountType === AccountType.dealer && (
                            <RebootMachineNotificationControlFeature
                                featureName={t('rebootMachineLabel', { ns: 'machine' })}
                                btnName={t('rebootLabel', { ns: 'common' })}
                                machine={machine}
                                isDisabled={!isEdit}
                                btnColorScheme="#D11828"
                            />
                        )}

                        {accountType === AccountType.dealer && (
                            <LaunchTeamViewerNotificationControlFeature
                                featureName={t('startTeamViewerLabel', { ns: 'machine' })}
                                btnName={t('teamViewerLabel', { ns: 'common' })}
                                machine={machine}
                                isDisabled={!isEdit}
                                btnColorScheme="#D11828"
                            />
                        )}

                        {accountType === AccountType.dealer && (
                            <ResetMachineNotificationControlFeature
                                featureName={t('resetMachineHeadingLabel', { ns: 'machine' })}
                                btnName={t('resetOnlyBtnLabel', { ns: 'common' })}
                                machine={machine}
                                isDisabled={!isEdit}
                                btnColorScheme="#D11828"
                            />
                        )}

                        {accountType === AccountType.dealer && (
                            <StatusMachineControlFeature
                                featureName={
                                    machine.status === MachineStatus.Enabled
                                        ? t('disableMachineLabel', { ns: 'machine' })
                                        : t('enableMachineLabel', { ns: 'machine' })
                                }
                                btnName={
                                    machine.status === MachineStatus.Enabled
                                        ? t('disable', { ns: 'common' })
                                        : t('enable', { ns: 'common' })
                                }
                                machine={machine}
                                btnColorScheme={machine.status === MachineStatus.Enabled ? '#D11828' : '#01B574'}
                                isDisabled={!isEdit}
                            />
                        )}

                        {accountType === AccountType.dealer && (
                            <RemoveProKitFromMachineControlFeature
                                featureName={t('removeProKitFeatureLabel', { ns: 'machine' })}
                                btnName={t('removeProKitFeatureBtn', { ns: 'machine' })}
                                machine={machine}
                                isDisabled={!isEdit}
                                onEditDisable={onEditDisable}
                            />
                        )}
                    </Flex>
                </Card>
                <UpdateBeaconConfirmModal
                    isOpen={isOpenUpdateBeaconModal}
                    onOpen={onOpenUpdateBeaconModal}
                    onClose={onCloseUpdateBeaconModal}
                    onHandleUpdateBeacon={onHandleEditMachine}
                    onCancel={reset}
                    isSubmitting={isSubmitting}
                />
            </FormProvider>
        </ApolloMutationBoundary>
    );
}

type StatusMachineControlFeatureProps = MachineDetailType & {
    featureName: string;
    btnName: string;
    btnColorScheme?: string;
    isDisabled: boolean;
    [x: string]: any;
};
function StatusMachineControlFeature(props: StatusMachineControlFeatureProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { accountType, userId } = useAuthContext();
    const { machine, featureName, btnName, btnColorScheme = 'red', isDisabled, ...rest } = props;
    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const { t } = useTranslation(['common', 'machine']);
    const history = useHistory();
    const {
        isOpen: isOpenMutationBoundaryResult,
        onOpen: onOpenMutationBoundaryResult,
        onClose: onCloseMutationBoundaryResult,
    } = useDisclosure();

    const {
        isOpen: isOpenConfirmDeleteMachine,
        onOpen: onOpenConfirmDeleteMachine,
        onClose: onCloseConfirmDeleteMachine,
    } = useDisclosure();

    const {
        isOpen: isOpenDeleteMachineResult,
        onOpen: onOpenDeleteMachineResult,
        onClose: onCloseDeleteMachineResult,
    } = useDisclosure();

    const [prohibitShopMachineFunc, prohibitShopMachineMutationResult] = useProhibitShopMachineMutation();
    const [liftShopMachineProhibitionFunc, liftShopMachineProhibitionMutationResult] =
        useLiftShopMachineProhibitionMutation();

    const onHandleChangeMachineState = async () => {
        try {
            onClose();
            if (machine.status === MachineStatus.OutOfService) {
                await liftShopMachineProhibitionFunc({
                    variables: {
                        input: {
                            machineId: machine.id,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onClose();
                            onOpenMutationBoundaryResult();
                        }
                    },
                    onError: async () => {
                        onClose();
                        onOpenMutationBoundaryResult();
                    },
                    // refetchQueries: () => [
                    //     {
                    //         query: GetMachineByIdDocument,
                    //         variables: {
                    //             input: {
                    //                 machineId: machine.id,
                    //             },
                    //         },
                    //     },
                    // ],
                });
            } else if (machine.status === MachineStatus.Enabled) {
                await prohibitShopMachineFunc({
                    variables: {
                        input: {
                            machineId: machine.id,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onClose();
                            onOpenMutationBoundaryResult();
                        }
                    },
                    onError: async () => {
                        onClose();
                        onOpenMutationBoundaryResult();
                    },
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onHandleSuccess = () => {
        window.location.reload();
    };

    const [deleteMachineMutationFunc, deleteMachineMutationResult] = useDeleteMachineMutation();

    const onHandleDeleteMachine = async () => {
        try {
            onCloseConfirmDeleteMachine();
            if (accountType === AccountType.shop) {
                await deleteMachineMutationFunc({
                    variables: {
                        input: {
                            machineId: machine.id,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onOpenDeleteMachineResult();
                        }
                    },
                    onError: async () => {
                        onOpenDeleteMachineResult();
                    },
                    refetchQueries: () => [
                        {
                            query: GetMachineListOfShopDocument,
                            variables: {
                                shopId: userId,
                                filterOption: {
                                    plan: undefined,
                                    status: undefined,
                                },
                                args: {
                                    first: 200,
                                },
                            },
                        },
                    ],
                });
            } else if (accountType === AccountType.dealer) {
                await deleteMachineMutationFunc({
                    variables: {
                        input: {
                            machineId: machine.id,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onOpenDeleteMachineResult();
                        }
                    },
                    onError: async () => {
                        onOpenDeleteMachineResult();
                    },
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onHandleDeleteMachineSuccess = () => {
        history.push(PATH_DASHBOARD.machine.overview);
    };

    return (
        <div style={{ width: '100%' }}>
            <ApolloMutationBoundary
                isOpen={isOpenMutationBoundaryResult}
                onClose={onCloseMutationBoundaryResult}
                onOpen={onOpenMutationBoundaryResult}
                onExtraAfterSuccessHandler={onHandleSuccess}
                mutationResult={
                    machine.status === MachineStatus.OutOfService
                        ? liftShopMachineProhibitionMutationResult
                        : prohibitShopMachineMutationResult
                }
                toastSuccessDescription={
                    machine.status === MachineStatus.OutOfService
                        ? t('liftedMachineSuccessfully', { ns: 'machine' })
                        : t('prohibitMachineSuccessfully', { ns: 'machine' })
                }
                toastSuccessTitle={
                    machine.status === MachineStatus.OutOfService
                        ? t('machineEnabledTitle', { ns: 'machine' })
                        : t('machineDisableTitle', { ns: 'machine' })
                }
            >
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    w="100%"
                    direction={{ base: 'column', md: 'row' }}
                    gap={{ base: 5, md: 5 }}
                    {...rest}
                >
                    <Flex direction="row" align="start" gap={2} me="auto">
                        <Text color={textColor} fontSize="md" me="6px" fontWeight="500">
                            Machine Status
                        </Text>
                        <MachineStatusElementWithDisable status={machine.status} isActivated={true} />
                    </Flex>
                    {machine.status === MachineStatus.Enabled && !isDisabled && (
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="stretch"
                            flexDirection={{ base: 'row', md: 'column' }}
                            gap={5}
                            w={{ base: '100%', md: 'auto' }}
                        >
                            <Button
                                // colorScheme={btnColorScheme}
                                variant="outline"
                                p="15px 40px"
                                fontSize="sm"
                                borderRadius={10}
                                fontWeight="500"
                                onClick={onOpen}
                                isDisabled={isDisabled}
                                borderColor={btnColorScheme}
                                h="38px"
                                w="140px"
                                borderWidth="2px"
                                color={btnColorScheme}
                            >
                                {btnName}
                            </Button>
                        </Box>
                    )}
                    {machine.status === MachineStatus.OutOfService && !isDisabled && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="stretch"
                            flexDirection={{ base: 'row', md: 'column' }}
                            gap={1.5}
                            w={{ base: '100%', md: 'auto' }}
                        >
                            <Box>
                                <Button
                                    variant="outline"
                                    p="15px 40px"
                                    fontSize="sm"
                                    borderRadius={10}
                                    fontWeight="500"
                                    onClick={onHandleChangeMachineState}
                                    isDisabled={isDisabled}
                                    borderColor={btnColorScheme}
                                    h="38px"
                                    w="140px"
                                    borderWidth="2px"
                                    color={btnColorScheme}
                                >
                                    {btnName}
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    variant="solid"
                                    p="15px 40px"
                                    fontSize="sm"
                                    borderRadius={10}
                                    fontWeight="500"
                                    isDisabled={isDisabled}
                                    onClick={onOpenConfirmDeleteMachine}
                                    borderColor="#D11828"
                                    h="38px"
                                    w="140px"
                                    borderWidth="2px"
                                    color="#D11828"
                                >
                                    {t('delete', { ns: 'common' })}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Flex>
                <MachineDisableConfirmModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onHandleChangeMachineState={onHandleChangeMachineState}
                />
            </ApolloMutationBoundary>

            <ApolloMutationBoundary
                isOpen={isOpenDeleteMachineResult}
                onClose={onCloseDeleteMachineResult}
                onOpen={onOpenDeleteMachineResult}
                mutationResult={deleteMachineMutationResult}
                onExtraAfterSuccessHandler={onHandleDeleteMachineSuccess}
                toastSuccessTitle={t('deleteMachineSuccessTitle', { ns: 'machine' })}
                toastSuccessDescription={t('deleteMachineSuccessMessage', { ns: 'machine' })}
            >
                <DeleteMachineConfirmModal
                    isOpen={isOpenConfirmDeleteMachine}
                    onClose={onCloseConfirmDeleteMachine}
                    onHandleDeleteMachine={onHandleDeleteMachine}
                />
            </ApolloMutationBoundary>
        </div>
    );
}
