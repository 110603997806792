import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, Input, InputProps } from '@chakra-ui/react';

// ----------------------------------------------------------------------

type Props = InputProps & {
    name: string;
    isRequired: boolean;
    formLabel?: JSX.Element;
};

export default function RHFTextField({ name, isRequired = false, formLabel, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl isRequired={isRequired} isInvalid={!!error?.message}>
                    {formLabel ?? null}
                    <Input
                        {...field}
                        value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                        {...other}
                    />
                    <FormErrorMessage sx={{ marginBottom: 2 }}>{error?.message}</FormErrorMessage>
                </FormControl>
            )}
        />
    );
}
