import * as Yup from 'yup';
import { Box, Button, Flex, FormLabel, SimpleGrid, Stack, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Country } from '../../../../../types/graphql';
import FormProvider, { RHFTextField } from 'components/hook-form';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useHistory } from 'react-router-dom';
import { PATH_DASHBOARD } from 'paths';
import { RHFCountrySelect } from 'components/hook-form/RHFCountrySelect';
import { RHFLanguageSelect } from 'components/hook-form/RHFLanguageSelect';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import * as React from 'react';
import { memo, useMemo, useState } from 'react';
import { useRegisterShopMutation } from 'graphql/mutations/shop/__generated__/registerShop.generated';
import { ApolloError } from '@apollo/client';
import CreateNewResultModalCommon from 'components/modal/CreateNewResultModalCommon';
import { GetShopsListOfDealerDocument } from 'graphql/queries/dealer/__generated__/getShopsListOfDealer.generated';
import { useTranslation } from 'react-i18next';

type NewShopFormValuesProps = {
    address: string;
    countryCode: Country;
    dealerId?: number;
    email: string;
    languageCode: string;
    name: string;
    phoneNumber: string;
    province: string;
    zipCode: string;
    afterSubmit?: string;
};

export default function AddNewShopTab() {
    const history = useHistory();
    const { userId } = useAuthContext();

    const { isOpen: isOpenSuccessResult, onOpen: onOpenSuccessResult, onClose: onCloseSuccessResult } = useDisclosure();
    const { isOpen: isOpenFailResult, onOpen: onOpenFailResult, onClose: onCloseFailResult } = useDisclosure();
    const { t } = useTranslation(['common']);

    const [errorMessages, setErrorMessages] = useState({
        message1: '',
        message2: '',
        message3: '',
    });

    const [registerShopMutation, mutationResult] = useRegisterShopMutation();
    const AddNewShopSchema = Yup.object().shape({
        name: Yup.string().required('Shop name is required'),
        countryCode: Yup.string().required('Country is required'),
        languageCode: Yup.string().required('Language is required'),
        zipCode: Yup.string().required('Zip Code is required'),
        province: Yup.string().required('Province is required'),
        address: Yup.string().required('Address is required'),
        phoneNumber: Yup.string().required('Phone Number is required'),
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    });
    const defaultValues = useMemo(
        () => ({
            email: '',
            address: '',
            countryCode: Country.Japan,
            dealerId: userId,
            languageCode: 'ja',
            name: '',
            phoneNumber: '',
            province: '',
            zipCode: '',
        }),
        [userId],
    );

    const methods = useForm({
        resolver: yupResolver(AddNewShopSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;
    const onSubmit = async (data: NewShopFormValuesProps) => {
        try {
            await registerShopMutation({
                variables: {
                    input: {
                        dealerId: data.dealerId,
                        email: data.email,
                        name: data.name,
                        address: data.address,
                        countryCode: data.countryCode,
                        languageCode: data.languageCode,
                        phoneNumber: data.phoneNumber,
                        province: data.province,
                        zipCode: data.zipCode,
                    },
                },
                refetchQueries: () => [
                    {
                        query: GetShopsListOfDealerDocument,
                        variables: {
                            input: {
                                dealerId: userId,
                                filterOption: {
                                    province: null,
                                    status: null,
                                },
                                args: {
                                    first: 100,
                                },
                            },
                        },
                    },
                ],
                onCompleted: async (res) => {
                    if (res) {
                        onOpenSuccessResult();
                    }
                },
                onError: async (error) => {
                    if (error.message.includes('An account with the given email already exists')) {
                        setErrorMessages &&
                            setErrorMessages({
                                message1: `${t('createShopErrorModalMessage1', { ns: 'common' })}`,
                                message2: `${t('createShopErrorModalMessage2', { ns: 'common' })}`,
                                message3: data.email,
                            });
                    } else {
                        setErrorMessages({
                            message1: error.message,
                            message2: '',
                            message3: '',
                        });
                    }
                    onOpenFailResult();
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    const onHandleSuccess = () => {
        history.push(PATH_DASHBOARD.shop.overview);
    };
    const onHandleFailed = () => {
        return;
    };

    return (
        <ApolloMutationBoundary
            mutationResult={mutationResult}
            toastComponent={<></>}
            errorHandler={(error) => {
                return <OnErrorAddNewShopMutationResultLoaded {...error} />;
            }}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Card p="30px">
                    <Flex direction="column" w="100%">
                        <SimpleGrid columns={{ base: 1 }} gap="20px">
                            <Stack direction="column" gap="20px">
                                <RHFTextField
                                    name="name"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewShopTabFormLabel label={t('shopName', { ns: 'common' })} />}
                                />
                            </Stack>
                        </SimpleGrid>

                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt={5} mb={2.5}>
                            <Stack direction="column" gap="20px">
                                <RHFCountrySelect name="countryCode" />
                            </Stack>
                            <Stack direction="column" gap="20px">
                                <RHFLanguageSelect name="languageCode" />
                            </Stack>
                        </SimpleGrid>

                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                            <Stack direction="column" gap="20px">
                                <RHFTextField
                                    name="zipCode"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewShopTabFormLabel label={t('zipCode', { ns: 'common' })} />}
                                />
                                <RHFTextField
                                    name="address"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewShopTabFormLabel label={t('address', { ns: 'common' })} />}
                                />
                                <RHFTextField
                                    name="email"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewShopTabFormLabel label={t('emailLabel', { ns: 'common' })} />}
                                />
                            </Stack>

                            <Stack direction="column" gap="20px">
                                <RHFTextField
                                    name="province"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewShopTabFormLabel label={t('province', { ns: 'common' })} />}
                                />

                                <RHFTextField
                                    name="phoneNumber"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewShopTabFormLabel label={t('phoneNumber', { ns: 'common' })} />}
                                />
                            </Stack>
                        </SimpleGrid>

                        <Flex justify="center" mt="24px">
                            <Box>
                                <Button
                                    variant="darkBrand"
                                    fontSize="sm"
                                    borderRadius="16px"
                                    minW={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    ms="auto"
                                    type="submit"
                                    isLoading={isSubmitting}
                                >
                                    {t('addNewShopLabel', { ns: 'common' })}
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>
                </Card>
            </FormProvider>
            <CreateNewResultModalCommon
                isOpen={isOpenSuccessResult}
                onClose={onCloseSuccessResult}
                onOpen={onOpenSuccessResult}
                onHandleConfirm={onHandleSuccess}
                modalLabel={{
                    modalBodyMessage1: `${t('createShopSucceedModalMessage1', { ns: 'common' })}`,
                    modalBodyMessage2: `${t('createShopSucceedModalMessage2', { ns: 'common' })}`,
                    modalBodyMessage3: `${t('createShopSucceedModalMessage3', { ns: 'common' })}`,
                }}
            />
            <CreateNewResultModalCommon
                isOpen={isOpenFailResult}
                onClose={onCloseFailResult}
                onOpen={onOpenFailResult}
                isSuccess={false}
                onHandleConfirm={onHandleFailed}
                modalLabel={{
                    modalBodyMessage1: errorMessages.message1,
                    modalBodyMessage2: errorMessages.message2 === '' ? null : errorMessages.message2,
                    modalBodyMessage3: errorMessages.message3 === '' ? null : errorMessages.message3,
                }}
            />
        </ApolloMutationBoundary>
    );
}

type AddNewShopTabFormLabelProps = {
    label: string;
};

function AddNewShopTabFormLabel({ label }: AddNewShopTabFormLabelProps) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    return (
        <FormLabel
            display="flex"
            ms="10px"
            fontSize="sm"
            color={textColorPrimary}
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
        >
            {label}
        </FormLabel>
    );
}

const OnErrorAddNewShopMutationResultLoaded = memo((error: ApolloError) => {
    return null;
});

OnErrorAddNewShopMutationResultLoaded.displayName = 'OnErrorAddNewShopMutationResultLoaded';
