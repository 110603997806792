import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { useMemo } from 'react';
import AddNewTab from './AddNewTab';
import { useAuthContext } from '../../../../../layouts/auth/useAuthContext';
import localStorageAvailable from '../../../../../utils/localStorageAvailable';
import { KEY } from '../../../../../constant/key';
import { DealerType } from '../../../../../types/graphql';
import { AccountType } from '../../../../../types/user';
import { useTranslation } from 'react-i18next';

export default function AddNewDealer() {
    const { accountType } = useAuthContext();
    const storageAvailable = localStorageAvailable();
    const dealerType = useMemo(() => {
        return storageAvailable ? (localStorage.getItem(KEY.dealerType) as DealerType) : null;
    }, []);
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common', 'machine']);
    return (
        <Flex direction="column" minH="100vh" align="center" pt={{ sm: '125px', lg: '75px' }} position="relative">
            {accountType === AccountType.distributor ||
            (accountType === AccountType.dealer && dealerType === DealerType.Partner) ? (
                <AddNewTab />
            ) : (
                <Flex direction="row" justifyContent="center" alignItems="center">
                    <Text color={textColor} fontSize="md" fontWeight="700">
                        {`${t('apiControlByDistributorPartnerDealerMessage', { ns: 'common' })}`}
                    </Text>
                </Flex>
            )}
        </Flex>
    );
}
