import { createContext, ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react';
import { loader } from 'graphql.macro';
import localStorageAvailable from '../../utils/localStorageAvailable';
import { isValidToken, jwtDecode, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types';
import { useMutation } from '@apollo/client';
import { AccountType } from '../../types/user';
import { KEY } from '../../constant/key';
import { DealerType } from '../../types/graphql';

// ----------------------------------------------------------------------
const DISTRIBUTOR_LOGIN = loader('../../graphql/mutations/auth/distributorLogin.graphql');
const DEALER_LOGIN = loader('../../graphql/mutations/auth/dealerLogin.graphql');
const SHOP_LOGIN = loader('../../graphql/mutations/auth/shopLogin.graphql');

// ----------------------------------------------------------------------

enum Types {
    INITIAL = 'INITIAL',
    LOGIN = 'LOGIN',
    REGISTER = 'REGISTER',
    LOGOUT = 'LOGOUT',
}

type Payload = {
    [Types.INITIAL]: {
        accountType: AccountType | null;
        dealerType: DealerType | null;
        isAuthenticated: boolean;
        userId: number;
        user: AuthUserType;
    };
    [Types.LOGIN]: {
        accountType: AccountType | null;
        dealerType: DealerType | null;
        isAuthenticated: boolean;
        userId: number;
        user: AuthUserType;
    };
    [Types.REGISTER]: {
        user: AuthUserType;
    };
    [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
    isInitialized: false,
    isAuthenticated: false,
    accountType: null,
    dealerType: null,
    userId: null,
    user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
    if (action.type === Types.INITIAL) {
        return {
            ...state,
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            accountType: action.payload.accountType,
            dealerType: action.payload.dealerType,
            userId: action.payload.userId,
            user: action.payload.user,
        };
    }
    if (action.type === Types.LOGIN) {
        return {
            ...state,
            isAuthenticated: true,
            accountType: action.payload.accountType,
            userId: action.payload.userId,
            user: action.payload.user,
        };
    }
    if (action.type === Types.REGISTER) {
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload.user,
        };
    }
    if (action.type === Types.LOGOUT) {
        return {
            ...state,
            isAuthenticated: false,
            accountType: null,
            dealerType: null,
            user: null,
        };
    }
    return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
    children: ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const storageAvailable = localStorageAvailable();

    const [distributorLoginFunc] = useMutation(DISTRIBUTOR_LOGIN, {
        onCompleted: async (res) => {
            if (res) {
                return res;
            }
            return null;
        },
    });
    const [dealerLoginFunc] = useMutation(DEALER_LOGIN, {
        onCompleted: async (res) => {
            if (res) {
                return res;
            }
            return null;
        },
    });
    const [shopLoginFunc] = useMutation(SHOP_LOGIN, {
        onCompleted: async (res) => {
            if (res) {
                return res;
            }
            return null;
        },
    });

    const initialize = useCallback(async () => {
        try {
            const accessToken = storageAvailable ? localStorage.getItem(KEY.accessToken) : '';
            const emailAccount = storageAvailable ? localStorage.getItem(KEY.userEmailAccount) : '';
            const userNameAccount = storageAvailable ? localStorage.getItem(KEY.userName) : '';
            const userAvatarUrlAccount = storageAvailable ? localStorage.getItem(KEY.userAvatarUrl) : '';
            const dealerType = storageAvailable ? localStorage.getItem(KEY.dealerType) : null;

            if (accessToken && isValidToken(accessToken)) {
                const decoded = jwtDecode(accessToken);
                let accType = null;
                if (decoded && decoded['cognito:groups']) {
                    if (decoded['cognito:groups'].includes('Distributors')) {
                        accType = AccountType.distributor;
                    } else if (decoded['cognito:groups'].includes('Dealers')) {
                        accType = AccountType.dealer;
                    } else if (decoded['cognito:groups'].includes('Shops')) {
                        accType = AccountType.shop;
                    }
                }
                const userIdToken = storageAvailable ? localStorage.getItem(KEY.userId) : '';
                setSession(accessToken);
                const user = decoded ? { ...decoded, emailAccount, userNameAccount, userAvatarUrlAccount } : null;
                dispatch({
                    type: Types.INITIAL,
                    payload: {
                        userId: userIdToken ? Number(userIdToken) : null,
                        accountType: accType,
                        dealerType: dealerType === null ? null : (dealerType as DealerType),
                        isAuthenticated: true,
                        user,
                    },
                });
            } else {
                dispatch({
                    type: Types.INITIAL,
                    payload: {
                        userId: null,
                        accountType: null,
                        dealerType: null,
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        } catch (error) {
            console.error(error);
            setSession(null);
            dispatch({
                type: Types.INITIAL,
                payload: {
                    userId: null,
                    accountType: null,
                    dealerType: null,
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    }, [storageAvailable]);

    useEffect(() => {
        initialize().catch((e) => {
            console.error(e);
        });
    }, [initialize]);

    // LOGIN
    const login = useCallback(async (email: string, password: string, accountType: AccountType) => {
        let response = null;
        if (accountType === AccountType.distributor) {
            response = await distributorLoginFunc({
                variables: {
                    input: {
                        email,
                        password,
                    },
                },
                fetchPolicy: 'network-only',
            });
        }

        if (accountType === AccountType.dealer) {
            response = await dealerLoginFunc({
                variables: {
                    input: {
                        email,
                        password,
                    },
                },
                fetchPolicy: 'network-only',
            });
        }

        if (accountType === AccountType.shop) {
            response = await shopLoginFunc({
                variables: {
                    input: {
                        email,
                        password,
                    },
                },
                fetchPolicy: 'network-only',
            });
        }

        if (response && response.data) {
            let user = null;
            let userId = null;
            let accessToken = undefined;
            let dealerType = null;
            switch (accountType) {
                case AccountType.distributor:
                    user = response.data.web_distributor_login?.distributor
                        ? { ...response.data.web_distributor_login?.distributor, emailAccount: email }
                        : null;
                    userId = response.data.web_distributor_login?.distributor?.id;
                    localStorage.setItem(KEY.userId, userId);
                    localStorage.setItem(KEY.userEmailAccount, email);
                    localStorage.setItem(KEY.userName, response.data.web_distributor_login?.distributor.name);
                    localStorage.setItem(KEY.userAvatarUrl, response.data.web_distributor_login?.distributor.iconUrl);
                    accessToken = response.data.web_distributor_login?.session?.accessToken?.jwtToken;
                    break;
                case AccountType.dealer:
                    user = response.data.web_dealer_login.dealer
                        ? { ...response.data.web_dealer_login.dealer, emailAccount: email }
                        : null;
                    userId = response.data.web_dealer_login?.dealer?.id;
                    dealerType = response.data.web_dealer_login?.dealer?.dealerType;
                    accessToken = response.data.web_dealer_login?.session?.accessToken?.jwtToken;
                    localStorage.setItem(KEY.userId, userId);
                    localStorage.setItem(KEY.userEmailAccount, email);
                    localStorage.setItem(KEY.userName, response.data.web_dealer_login.dealer?.name);
                    localStorage.setItem(KEY.userAvatarUrl, response.data.web_dealer_login.dealer?.iconUrl);
                    localStorage.setItem(KEY.dealerType, dealerType);
                    break;
                case AccountType.shop:
                    user = response.data.web_shop_login?.shop
                        ? { ...response.data.web_shop_login?.shop, emailAccount: email }
                        : null;
                    userId = response.data.web_shop_login.shop?.id;
                    localStorage.setItem(KEY.userId, userId);
                    localStorage.setItem(KEY.userEmailAccount, email);
                    localStorage.setItem(KEY.userName, response.data.web_shop_login?.shop.name);
                    localStorage.setItem(KEY.userAvatarUrl, response.data.web_shop_login?.shop.iconUrl);
                    accessToken = response.data.web_shop_login.session.accessToken.jwtToken;
                    break;
            }
            setSession(accessToken);

            dispatch({
                type: Types.LOGIN,
                payload: {
                    accountType,
                    isAuthenticated: !!(accessToken && user),
                    dealerType,
                    userId,
                    user,
                },
            });
        } else {
            setSession(null);
            localStorage.removeItem(KEY.userId);
            dispatch({
                type: Types.LOGIN,
                payload: {
                    accountType: null,
                    isAuthenticated: false,
                    dealerType: null,
                    userId: null,
                    user: null,
                },
            });
        }
    }, []);

    // LOGOUT
    const logout = useCallback(() => {
        setSession(null);
        localStorage.removeItem(KEY.userId);
        localStorage.removeItem(KEY.userEmailAccount);
        localStorage.removeItem(KEY.userName);
        localStorage.removeItem(KEY.userAvatarUrl);
        localStorage.removeItem(KEY.dealerType);
        dispatch({
            type: Types.LOGOUT,
        });
    }, []);

    const memoizedValue = useMemo(
        () => ({
            isInitialized: state.isInitialized,
            isAuthenticated: state.isAuthenticated,
            accountType: state.accountType,
            dealerType: state.dealerType,
            userId: state.userId,
            user: state.user,
            method: 'jwt',
            login,
            logout,
        }),
        [state.isAuthenticated, state.isInitialized, state.user, state.dealerType, state.accountType, login, logout],
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
