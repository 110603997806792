import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from 'react';
import { fYyyyMmDdHhMm } from 'utils/format-time';
import { MachineDetailType } from '../MachineDetailType';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const bgFromStatus = (isOnline: boolean) => {
    if (isOnline) {
        return 'linear-gradient(135deg, #86B6FF 0%, #184BFF 100%)';
    } else {
        return 'linear-gradient(135deg, #E5EFFC 0%, #9BA7B7 100%)';
    }
    // switch (status) {
    //     case MachineStatus.Enabled:
    //         return 'linear-gradient(135deg, #86B6FF 0%, #184BFF 100%)';
    //     case MachineStatus.Deleted:
    //         return 'linear-gradient(135deg, #FEA6A6 0%, #D50702 100%)';
    //     case MachineStatus.OutOfService:
    //         return 'linear-gradient(135deg, #E5EFFC 0%, #9BA7B7 100%)';
    //     default:
    //         return 'linear-gradient(135deg, #E5EFFC 0%, #9BA7B7 100%)';
    // }
};

const headingFromStatus = (isOnline: boolean, t: TFunction) => {
    if (isOnline) {
        return t('onlineLabel', { ns: 'common' });
    } else {
        return t('offlineLabel', { ns: 'common' });
    }
};

export default function MachineStatusInfo(props: MachineDetailType) {
    const { machine } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');

    return (
        <Card py={8} px={{ base: '20px', lg: '30px', '2xl': '30px' }}>
            <Flex w="100%" align="center" justify="space-between" direction="column" borderRadius="12px">
                <MachineOnlineInfoCard machine={machine} />

                <Flex w="100%" align="center" justify="center" my={8}>
                    <Flex direction="column" align="center" me="60px">
                        <Text color={textColorPrimary} fontSize={{ base: '20px' }} fontWeight="700">
                            {machine.proKit?.cpuTemperature ? `${machine.proKit?.cpuTemperature}°C` : '--°C'}
                        </Text>
                        <Text color="secondaryGray.600" fontSize="14px" fontWeight="400">
                            CPU
                        </Text>
                    </Flex>
                    <Flex direction="column" align="center" me="60px">
                        <Text color={textColorPrimary} fontSize={{ base: '20px' }} fontWeight="700">
                            {machine.proKit?.cpuUsage ? `${machine.proKit?.cpuUsage}%` : '--%'}
                        </Text>
                        <Text color="secondaryGray.600" fontSize="14px" fontWeight="400">
                            CPU
                        </Text>
                    </Flex>
                    <Flex direction="column" align="center">
                        <Text color={textColorPrimary} fontSize={{ base: '20px' }} fontWeight="700">
                            {machine.proKit?.memoryUsage ? `${machine.proKit?.memoryUsage}%` : '--%'}
                        </Text>
                        <Text color="secondaryGray.600" fontSize="14px" fontWeight="400">
                            Memory
                        </Text>
                    </Flex>
                </Flex>

                <Flex direction={'row'} justifyContent="space-between">
                    <Text color={textColorSecondary} fontWeight="500" fontSize="sm">
                        {machine.proKit?.usageReportedAt ? `${fYyyyMmDdHhMm(machine.proKit?.usageReportedAt)}` : '--'}
                    </Text>
                </Flex>
            </Flex>
        </Card>
    );
}

function MachineOnlineInfoCard(props: MachineDetailType) {
    const { machine } = props;
    const { t } = useTranslation(['common', 'machine']);
    return (
        <Card minH={'90px'} flexDirection="row" bg={bgFromStatus(machine.isOnline)} borderRadius="12px">
            <Flex direction="row" justifyContent="center" alignItems="stretch" w="100%">
                <Flex direction="column" color="white" h="100%" w="100%" justifyContent="center" alignItems="center">
                    <Text lineHeight="100%" fontSize={{ sm: 'lg', md: '32px' }} fontWeight="700">
                        {headingFromStatus(machine.isOnline, t)}
                    </Text>
                </Flex>
            </Flex>
        </Card>
    );
}
