import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivateDealerMutationVariables = Types.Exact<{
    input: Types.ActivateDealerInput;
}>;

export type ActivateDealerMutation = { __typename?: 'Mutation'; activate_dealer: Types.SuccessResponse };

export const ActivateDealerDocument = gql`
    mutation ActivateDealer($input: ActivateDealerInput!) {
        activate_dealer(input: $input)
    }
`;
export type ActivateDealerMutationFn = Apollo.MutationFunction<ActivateDealerMutation, ActivateDealerMutationVariables>;

/**
 * __useActivateDealerMutation__
 *
 * To run a mutation, you first call `useActivateDealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateDealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateDealerMutation, { data, loading, error }] = useActivateDealerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateDealerMutation(
    baseOptions?: Apollo.MutationHookOptions<ActivateDealerMutation, ActivateDealerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ActivateDealerMutation, ActivateDealerMutationVariables>(ActivateDealerDocument, options);
}
export type ActivateDealerMutationHookResult = ReturnType<typeof useActivateDealerMutation>;
export type ActivateDealerMutationResult = Apollo.MutationResult<ActivateDealerMutation>;
export type ActivateDealerMutationOptions = Apollo.BaseMutationOptions<
    ActivateDealerMutation,
    ActivateDealerMutationVariables
>;
