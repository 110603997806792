import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditDealerInformationMutationVariables = Types.Exact<{
    input: Types.EditDealerInformationInput;
}>;

export type EditDealerInformationMutation = {
    __typename?: 'Mutation';
    edit_dealer_information: { __typename?: 'Dealer'; id: number; name: string; iconUrl?: string | null };
};

export const EditDealerInformationDocument = gql`
    mutation editDealerInformation($input: EditDealerInformationInput!) {
        edit_dealer_information(input: $input) {
            id
            name
            iconUrl
        }
    }
`;
export type EditDealerInformationMutationFn = Apollo.MutationFunction<
    EditDealerInformationMutation,
    EditDealerInformationMutationVariables
>;

/**
 * __useEditDealerInformationMutation__
 *
 * To run a mutation, you first call `useEditDealerInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDealerInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDealerInformationMutation, { data, loading, error }] = useEditDealerInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDealerInformationMutation(
    baseOptions?: Apollo.MutationHookOptions<EditDealerInformationMutation, EditDealerInformationMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<EditDealerInformationMutation, EditDealerInformationMutationVariables>(
        EditDealerInformationDocument,
        options,
    );
}
export type EditDealerInformationMutationHookResult = ReturnType<typeof useEditDealerInformationMutation>;
export type EditDealerInformationMutationResult = Apollo.MutationResult<EditDealerInformationMutation>;
export type EditDealerInformationMutationOptions = Apollo.BaseMutationOptions<
    EditDealerInformationMutation,
    EditDealerInformationMutationVariables
>;
