import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DistributorGetShopsListOfDealerQueryVariables = Types.Exact<{
    input: Types.GetShopsListOfDealerInput;
}>;

export type DistributorGetShopsListOfDealerQuery = {
    __typename?: 'Query';
    get_shops_list_of_dealer: {
        __typename?: 'ShopConnection';
        totalCount: number;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
        edges: Array<{
            __typename?: 'ShopEdge';
            cursor: string;
            node?: {
                __typename?: 'Shop';
                id: number;
                name: string;
                beaconId?: string | null;
                status: Types.ShopStatus;
                email: string;
                iconUrl?: string | null;
                languageCode: string;
                countryCode: string;
                zipCode: string;
                province: string;
                address: string;
                phoneNumber: string;
                isActivated: boolean;
                lastInformationEditedAt?: Date | null;
                dealer: { __typename?: 'Dealer'; id: number; name: string };
                machines: Array<{
                    __typename?: 'Machine';
                    id: number;
                    dummyPlayerId?: number | null;
                    serviceMode: Types.ProKitServiceModeOption;
                    freeModeStart?: Date | null;
                    freeModeEnd?: Date | null;
                    status: Types.MachineStatus;
                    lastLoginAt?: Date | null;
                    isPasswordRequired: boolean;
                    playerEntries: Array<{ __typename?: 'Player'; id: number; player_name: string } | null>;
                    proKit?: {
                        __typename?: 'ProKit';
                        id: number;
                        subscriptionPlan?: Types.GranProSubscriptionPlan | null;
                        status: Types.ProKitStatus;
                    } | null;
                }>;
            } | null;
        } | null>;
    };
};

export const DistributorGetShopsListOfDealerDocument = gql`
    query distributorGetShopsListOfDealer($input: GetShopsListOfDealerInput!) {
        get_shops_list_of_dealer(input: $input) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    name
                    beaconId
                    status
                    email
                    iconUrl
                    languageCode
                    countryCode
                    zipCode
                    province
                    address
                    phoneNumber
                    isActivated
                    lastInformationEditedAt
                    dealer {
                        id
                        name
                    }
                    machines {
                        id
                        dummyPlayerId
                        serviceMode
                        freeModeStart
                        freeModeEnd
                        status
                        lastLoginAt
                        isPasswordRequired
                        playerEntries {
                            id
                            player_name
                        }
                        proKit {
                            id
                            subscriptionPlan
                            status
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useDistributorGetShopsListOfDealerQuery__
 *
 * To run a query within a React component, call `useDistributorGetShopsListOfDealerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorGetShopsListOfDealerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorGetShopsListOfDealerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDistributorGetShopsListOfDealerQuery(
    baseOptions: Apollo.QueryHookOptions<
        DistributorGetShopsListOfDealerQuery,
        DistributorGetShopsListOfDealerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DistributorGetShopsListOfDealerQuery, DistributorGetShopsListOfDealerQueryVariables>(
        DistributorGetShopsListOfDealerDocument,
        options,
    );
}
export function useDistributorGetShopsListOfDealerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DistributorGetShopsListOfDealerQuery,
        DistributorGetShopsListOfDealerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DistributorGetShopsListOfDealerQuery, DistributorGetShopsListOfDealerQueryVariables>(
        DistributorGetShopsListOfDealerDocument,
        options,
    );
}
export type DistributorGetShopsListOfDealerQueryHookResult = ReturnType<typeof useDistributorGetShopsListOfDealerQuery>;
export type DistributorGetShopsListOfDealerLazyQueryHookResult = ReturnType<
    typeof useDistributorGetShopsListOfDealerLazyQuery
>;
export type DistributorGetShopsListOfDealerQueryResult = Apollo.QueryResult<
    DistributorGetShopsListOfDealerQuery,
    DistributorGetShopsListOfDealerQueryVariables
>;
