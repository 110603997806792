import { Flex, useDisclosure } from '@chakra-ui/react';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { useGetDealerByIdQuery } from 'graphql/queries/dealer/__generated__/getDealerById.generated';
import AvatarProfile from './AvatarProfile';
import DealerAccountInfoCard from './dealer/DealerAccountInfoCard';
import DealerAccountSignInCard from './dealer/DealerAccountSignInCard';

export default function DealerUserAccountSetting() {
    const { userId } = useAuthContext();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const getDealerByIdResult = useGetDealerByIdQuery({
        variables: {
            input: {
                dealerId: userId,
            },
        },
        onError: async () => {
            onOpen();
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });
    return (
        <ApolloQueryBoundary
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            queryResult={getDealerByIdResult}
            render={({ data: { dealer } }) => (
                <Flex direction="column">
                    <AvatarProfile userName={dealer.name} avatarUrl={dealer.iconUrl} />
                    <DealerAccountInfoCard dealer={dealer} mb="20px" />
                    <DealerAccountSignInCard dealer={dealer} mb="20px" />
                </Flex>
            )}
        />
    );
}
