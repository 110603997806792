// noinspection DuplicatedCode

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { MachineIncomeRowObj } from '../variables';
import { CreateMachineReceiptTblColum } from './CreateMachineReceiptTblColum';
import { PickValueOf } from 'types/common';
import { GetMachinesWithIncomeOfShopQuery } from 'graphql/queries/shop/__generated__/getMachinesWithIncomeOfShop.generated';
import { useTranslation } from 'react-i18next';
import ResetBtn from './ResetBtn';

type MachineReceiptTableProps = {
    machineWithIncomeList: PickValueOf<GetMachinesWithIncomeOfShopQuery, 'get_machines_with_income_of_shop'>;
    shopId: number;
    totalIncome: number;
};
export default function MachineReceiptTable(props: MachineReceiptTableProps) {
    const { machineWithIncomeList, shopId, totalIncome } = props;
    const [isLargerThan960] = useMediaQuery('(min-width: 960px)');
    const [isLargerThan460] = useMediaQuery('(min-width: 460px)');
    const [sorting, setSorting] = useState<SortingState>([]);
    const textColor = useColorModeValue('navy.650', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const { t } = useTranslation(['common']);
    const defaultData = useMemo(() => {
        const result: MachineIncomeRowObj[] = machineWithIncomeList.map((machineWithIncome) => ({
            amount: machineWithIncome.machine?.shop?.incomeConversionRate
                ? machineWithIncome.income * machineWithIncome.machine?.shop?.incomeConversionRate
                : machineWithIncome.income,
            machineName: machineWithIncome.machine.machineName,
            common: [`${machineWithIncome.machine.machineName}`, `${machineWithIncome.income}`],
            // TODO: Need to update specific when getting from API
            coinPlay: 99,
            freePlay: 99,
            timePlay: 99,
            btn: (
                <ResetBtn
                    shopId={shopId}
                    machineId={machineWithIncome.machine.id}
                    income={machineWithIncome.income}
                    machineName={machineWithIncome.machine.machineName}
                    shopName={machineWithIncome.machine.shop?.name}
                />
            ),
        }));
        return result;
    }, []);
    const columnHelper = createColumnHelper<MachineIncomeRowObj>();
    const columns = CreateMachineReceiptTblColum(isLargerThan960, isLargerThan460, columnHelper, textColor, t);
    const [data] = useState(() => [...defaultData]);
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });
    return (
        // <Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Flex direction="column">
            <Box>
                <Table variant="simple" color="gray.500">
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            pe="10px"
                                            borderColor={borderColor}
                                            cursor="pointer"
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                align="start"
                                                fontSize={{ sm: '10px', lg: '12px' }}
                                                color="gray.400"
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </Flex>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <Td
                                                key={cell.id}
                                                fontSize={{ sm: '14px' }}
                                                // minW={{ sm: '170px', md: '200px', lg: 'auto' }}
                                                borderColor={borderColor}
                                                // mt="20px !important"
                                                // py="22px !important"
                                                paddingX={{ base: '"5px !important"', md: '25px !important' }}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                        <Tr>
                            <Td
                                fontSize={{ sm: '14px' }}
                                borderColor={borderColor}
                                paddingX={{ base: '"5px !important"', md: '25px !important' }}
                            >
                                <Text color={textColor} fontSize="16px" fontWeight="500">
                                    {t('totalLabel', { ns: 'common' })}
                                </Text>
                            </Td>
                            <Td
                                fontSize={{ sm: '14px' }}
                                borderColor={borderColor}
                                paddingX={{ base: '"5px !important"', md: '25px !important' }}
                            >
                                <Text color={textColor} fontSize="16px" fontWeight="500">
                                    {totalIncome}
                                </Text>
                            </Td>

                            <Td
                                fontSize={{ sm: '14px' }}
                                borderColor={borderColor}
                                paddingX={{ base: '"5px !important"', md: '25px !important' }}
                            ></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Box>
        </Flex>
    );
}
