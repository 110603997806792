import { Button, Flex, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { MachineDetailType } from '../MachineDetailType';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useTranslation } from 'react-i18next';
import { useRemoveProKitFromMachineMutation } from '../../../../../../graphql/mutations/machine/__generated__/removeProKitFromMachine.generated';
import React from 'react';
import RemoveProkitConfirmModal from './RemoveProkitConfirmModal';

type RemoveProKitFromMachineControlFeatureProps = MachineDetailType & {
    featureName: string;
    btnName: string;
    btnColorScheme?: string;
    isDisabled: boolean;
    onEditDisable: () => void;
    [key: string]: any;
};

export default function RemoveProKitFromMachineControlFeature(props: RemoveProKitFromMachineControlFeatureProps) {
    const { isOpen: isOpenConfirmModal, onOpen: onOpenConfirmModal, onClose: onCloseConfirmModal } = useDisclosure();
    const { machine, featureName, btnName, btnColorScheme = 'red', isDisabled, onEditDisable, ...rest } = props;
    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const { t } = useTranslation(['common', 'machine']);
    const {
        isOpen: isOpenRemoveProKitFromMachineMutationResult,
        onOpen: onOpenRemoveProKitFromMachineMutationResult,
        onClose: onCloseRemoveProKitFromMachineMutationResult,
    } = useDisclosure();

    const [removeProKitFromMachineFunc, removeProKitFromMachineResult] = useRemoveProKitFromMachineMutation();

    const onHandleRemoveProKitFromMachine = async () => {
        try {
            onCloseConfirmModal();
            await removeProKitFromMachineFunc({
                variables: {
                    input: {
                        machineId: machine.id,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenRemoveProKitFromMachineMutationResult();
                    }
                },
                onError: async () => {
                    onOpenRemoveProKitFromMachineMutationResult();
                },
            });
        } catch (error) {
            onCloseConfirmModal();
            console.error(error);
        }
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenRemoveProKitFromMachineMutationResult}
            onClose={onCloseRemoveProKitFromMachineMutationResult}
            onOpen={onOpenRemoveProKitFromMachineMutationResult}
            onExtraAfterSuccessHandler={onEditDisable}
            mutationResult={removeProKitFromMachineResult}
            toastSuccessTitle={`${t('removeProKitSuccessTitle', { ns: 'machine' })}`}
            toastSuccessDescription={`${t('removeProKitSuccessMessage', { ns: 'machine' })}`}
        >
            <Flex justifyContent="center" alignItems="center" w="100%" {...rest}>
                <Flex direction="column" align="start" me="auto">
                    <Text color={textColor} fontSize="md" me="6px" fontWeight="500">
                        {featureName}
                    </Text>
                </Flex>
                {!isDisabled && (
                    <Button
                        colorScheme={btnColorScheme}
                        variant="outline"
                        p="15px 40px"
                        fontSize="sm"
                        borderRadius={10}
                        fontWeight="500"
                        onClick={onOpenConfirmModal}
                        isDisabled={machine.proKit === null || machine.proKit === undefined}
                        borderColor="#D11828"
                        h="38px"
                        w="140px"
                        borderWidth="2px"
                        color="#D11828"
                    >
                        {btnName}
                    </Button>
                )}
            </Flex>
            <RemoveProkitConfirmModal
                isOpen={isOpenConfirmModal}
                onClose={onCloseConfirmModal}
                onOpen={onOpenConfirmModal}
                onHandleRemoveProkit={onHandleRemoveProKitFromMachine}
            />
        </ApolloMutationBoundary>
    );
}
