import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DistributorGetShopByIdQueryVariables = Types.Exact<{
    input: Types.ShopInput;
}>;

export type DistributorGetShopByIdQuery = {
    __typename?: 'Query';
    shop: {
        __typename?: 'Shop';
        id: number;
        name: string;
        email: string;
        iconUrl?: string | null;
        beaconId?: string | null;
        status: Types.ShopStatus;
        languageCode: string;
        countryCode: string;
        zipCode: string;
        province: string;
        address: string;
        phoneNumber: string;
        lastInformationEditedAt?: Date | null;
        lastInformationEditedBy?: Types.ShopInformationEditor | null;
        isActivated: boolean;
        incomeConversionRate: number;
        dealer: {
            __typename?: 'Dealer';
            id: number;
            name: string;
            status: Types.DealerStatus;
            zipCode: string;
            province: string;
            phoneNumber: string;
            languageCode: string;
            countryCode: string;
            address: string;
            distributor: { __typename?: 'Distributor'; id: number; name: string };
        };
        machines: Array<{
            __typename?: 'Machine';
            id: number;
            machineName: string;
            status: Types.MachineStatus;
            inGameStatus: Types.InGameStatus;
            serviceMode: Types.ProKitServiceModeOption;
            gameSetting: any;
            dummyPlayerId?: number | null;
            freeModeStart?: Date | null;
            freeModeEnd?: Date | null;
            lastLoginAt?: Date | null;
            isOnline: boolean;
            playerEntries: Array<{
                __typename?: 'Player';
                id: number;
                player_name: string;
                status: Types.PlayerStatus;
                icon_url?: string | null;
                type: Types.PlayerType;
                user?: { __typename?: 'User'; id: number } | null;
            } | null>;
            proKit?: {
                __typename?: 'ProKit';
                id: number;
                status: Types.ProKitStatus;
                modelName: string;
                totalActivateCount: number;
                memoryUsage?: number | null;
                cpuUsage?: number | null;
                cpuTemperature?: number | null;
                serialCode: string;
                subscriptionPlan?: Types.GranProSubscriptionPlan | null;
            } | null;
        }>;
    };
};

export const DistributorGetShopByIdDocument = gql`
    query distributorGetShopById($input: ShopInput!) {
        shop(input: $input) {
            id
            name
            email
            iconUrl
            beaconId
            status
            languageCode
            countryCode
            zipCode
            province
            address
            phoneNumber
            lastInformationEditedAt
            lastInformationEditedBy
            isActivated
            incomeConversionRate
            dealer {
                id
                name
                status
                zipCode
                province
                phoneNumber
                languageCode
                countryCode
                address
                distributor {
                    id
                    name
                }
            }
            machines {
                id
                machineName
                status
                inGameStatus
                serviceMode
                gameSetting
                dummyPlayerId
                freeModeStart
                freeModeEnd
                lastLoginAt
                isOnline
                playerEntries {
                    id
                    player_name
                    status
                    icon_url
                    type
                    user {
                        id
                    }
                }
                proKit {
                    id
                    status
                    modelName
                    totalActivateCount
                    memoryUsage
                    cpuUsage
                    cpuTemperature
                    serialCode
                    subscriptionPlan
                }
            }
        }
    }
`;

/**
 * __useDistributorGetShopByIdQuery__
 *
 * To run a query within a React component, call `useDistributorGetShopByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorGetShopByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorGetShopByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDistributorGetShopByIdQuery(
    baseOptions: Apollo.QueryHookOptions<DistributorGetShopByIdQuery, DistributorGetShopByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<DistributorGetShopByIdQuery, DistributorGetShopByIdQueryVariables>(
        DistributorGetShopByIdDocument,
        options,
    );
}
export function useDistributorGetShopByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<DistributorGetShopByIdQuery, DistributorGetShopByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<DistributorGetShopByIdQuery, DistributorGetShopByIdQueryVariables>(
        DistributorGetShopByIdDocument,
        options,
    );
}
export type DistributorGetShopByIdQueryHookResult = ReturnType<typeof useDistributorGetShopByIdQuery>;
export type DistributorGetShopByIdLazyQueryHookResult = ReturnType<typeof useDistributorGetShopByIdLazyQuery>;
export type DistributorGetShopByIdQueryResult = Apollo.QueryResult<
    DistributorGetShopByIdQuery,
    DistributorGetShopByIdQueryVariables
>;
