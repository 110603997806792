import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { APOLLO_SRV_ENDPOINT } from './config-global';

const httpLink = createUploadLink({
    uri: APOLLO_SRV_ENDPOINT,
    headers: {
        'Apollo-Require-Preflight': 'true',
        'Access-Control-Allow-Origin': '*',
        'X-Apollo-Operation-Name': '',
        'Access-Control-Request-Method': 'OPTIONS',
        'Content-Type': 'application/json',
    },
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('accessToken');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const splitLink = split(
    (op) => {
        const definition = getMainDefinition(op.query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    httpLink,
    httpLink,
);

export const client = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
});
