import { Icon } from '@chakra-ui/react';
import { MdHome, MdLock, MdStore } from 'react-icons/md';
import DashboardsDefault from 'views/admin/dashboards/default';
import { HiOfficeBuilding } from 'react-icons/hi';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import DealerOverview from './views/admin/main/dealer/overview';
import ShopOverview from './views/admin/main/shop/overview';
import MachineOverview from './views/admin/main/machine/overview';
import IncomeOverview from './views/admin/main/income/overview';
import ProkitOverview from './views/admin/main/prokit/overview';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
import ResetPasswordDefault from './views/auth/resetPassword/ResetPasswordDefault';
import VerificationDefault from './views/auth/verification/VerificationDefault';
import { ROUTE_PATH_DASHBOARD } from './paths';
import { AccountType } from './types/user';
import ActivateAccount from 'views/auth/activateAccount/ActivateAccount';
import { MachineIconMenu } from './components/icons/Icons';
import { DealerType } from './types/graphql';

const dashBoardRoute = () => ({
    name: 'Dashboard',
    path: '/dashboard',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: DashboardsDefault,
    layout: '/admin',
});
const shopRoute = () => ({
    name: 'Shop',
    layout: '/admin',
    path: ROUTE_PATH_DASHBOARD.shop.overview,
    icon: <Icon as={MdStore} width="20px" height="20px" color="inherit" />,
    component: ShopOverview,
});

const dealerRoute = () => ({
    name: 'Dealer',
    layout: '/admin',
    path: ROUTE_PATH_DASHBOARD.dealer.overview,
    icon: <Icon as={HiOfficeBuilding} width="20px" height="20px" color="inherit" />,
    component: DealerOverview,
});

const machineRoute = () => ({
    name: 'Machine',
    layout: '/admin',
    path: ROUTE_PATH_DASHBOARD.machine.overview,
    icon: <MachineIconMenu width="20px" height="18px" color="inherit" mt={-2} />,
    component: MachineOverview,
});
const incomeRoute = () => ({
    name: 'Income',
    layout: '/admin',
    path: ROUTE_PATH_DASHBOARD.income.overview,
    icon: <Icon as={RiMoneyDollarCircleLine} width="20px" height="20px" color="inherit" />,
    component: IncomeOverview,
});

const prokitRoute = () => ({
    name: 'Prokit',
    layout: '/admin',
    path: ROUTE_PATH_DASHBOARD.prokit.overview,
    icon: <Icon as={MdStore} width="20px" height="20px" color="inherit" />,
    component: ProkitOverview,
});

const routes = (accountType: AccountType, dealerType: DealerType | null) => {
    switch (accountType) {
        case AccountType.distributor:
            return [
                {
                    ...dashBoardRoute(),
                },
                {
                    ...dealerRoute(),
                },
                {
                    ...shopRoute(),
                },
                {
                    ...machineRoute(),
                },
                {
                    ...incomeRoute(),
                },
                {
                    ...prokitRoute(),
                },
            ];
        case AccountType.dealer:
            if (dealerType === DealerType.Partner) {
                return [
                    {
                        ...dashBoardRoute(),
                    },
                    {
                        ...dealerRoute(),
                    },
                    {
                        ...shopRoute(),
                    },
                    {
                        ...machineRoute(),
                    },
                    {
                        ...incomeRoute(),
                    },
                ];
            } else {
                return [
                    {
                        ...dashBoardRoute(),
                    },
                    {
                        ...shopRoute(),
                    },
                    {
                        ...machineRoute(),
                    },
                    {
                        ...incomeRoute(),
                    },
                ];
            }

        case AccountType.shop:
            return [
                {
                    ...dashBoardRoute(),
                },
                {
                    ...machineRoute(),
                },
            ];
        default:
            return [
                {
                    name: 'Authentication',
                    path: '/auth',
                    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
                    collapse: true,
                    items: [
                        {
                            name: 'Sign In',
                            layout: '/auth',
                            path: '/sign-in',
                            component: SignInDefault,
                        },
                        {
                            name: 'Activate Account',
                            layout: '/auth',
                            path: '/activate-account',
                            component: ActivateAccount,
                        },
                        {
                            name: 'Sign Up',
                            layout: '/auth',
                            path: '/sign-up',
                            component: SignUpDefault,
                        },
                        {
                            name: 'Forgot Password',
                            layout: '/auth',
                            path: '/forgot-password',
                            component: ForgotPasswordDefault,
                        },
                        {
                            name: 'Reset Password',
                            layout: '/auth',
                            path: '/reset-password',
                            component: ResetPasswordDefault,
                        },
                        {
                            name: 'Verification',
                            layout: '/auth',
                            path: '/verification',
                            component: VerificationDefault,
                        },
                    ],
                },
            ];
    }
};

export default routes;
