import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLastFiveShopIncomeCollectionEventsQueryVariables = Types.Exact<{
    input: Types.GetLastFiveShopIncomeCollectionEventsInput;
}>;

export type GetLastFiveShopIncomeCollectionEventsQuery = {
    __typename?: 'Query';
    get_last_five_shop_income_collection_events: Array<{
        __typename?: 'ProShopIncomeCollectionEvent';
        id: string;
        eventName: string;
        automaticallyCollectedTotalCount: number;
        manuallyInputTotalCount: number;
        lastMachineIncomeCollectionEventDate?: Date | null;
    }>;
};

export const GetLastFiveShopIncomeCollectionEventsDocument = gql`
    query getLastFiveShopIncomeCollectionEvents($input: GetLastFiveShopIncomeCollectionEventsInput!) {
        get_last_five_shop_income_collection_events(input: $input) {
            id
            eventName
            automaticallyCollectedTotalCount
            manuallyInputTotalCount
            lastMachineIncomeCollectionEventDate
        }
    }
`;

/**
 * __useGetLastFiveShopIncomeCollectionEventsQuery__
 *
 * To run a query within a React component, call `useGetLastFiveShopIncomeCollectionEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastFiveShopIncomeCollectionEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastFiveShopIncomeCollectionEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLastFiveShopIncomeCollectionEventsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetLastFiveShopIncomeCollectionEventsQuery,
        GetLastFiveShopIncomeCollectionEventsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetLastFiveShopIncomeCollectionEventsQuery,
        GetLastFiveShopIncomeCollectionEventsQueryVariables
    >(GetLastFiveShopIncomeCollectionEventsDocument, options);
}
export function useGetLastFiveShopIncomeCollectionEventsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLastFiveShopIncomeCollectionEventsQuery,
        GetLastFiveShopIncomeCollectionEventsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetLastFiveShopIncomeCollectionEventsQuery,
        GetLastFiveShopIncomeCollectionEventsQueryVariables
    >(GetLastFiveShopIncomeCollectionEventsDocument, options);
}
export type GetLastFiveShopIncomeCollectionEventsQueryHookResult = ReturnType<
    typeof useGetLastFiveShopIncomeCollectionEventsQuery
>;
export type GetLastFiveShopIncomeCollectionEventsLazyQueryHookResult = ReturnType<
    typeof useGetLastFiveShopIncomeCollectionEventsLazyQuery
>;
export type GetLastFiveShopIncomeCollectionEventsQueryResult = Apollo.QueryResult<
    GetLastFiveShopIncomeCollectionEventsQuery,
    GetLastFiveShopIncomeCollectionEventsQueryVariables
>;
