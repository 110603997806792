import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import ProkitList from './components/ProkitList';
import { AccountType } from '../../../../../types/user';
import { useAuthContext } from '../../../../../layouts/auth/useAuthContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function ProkitOverview() {
    const { accountType } = useAuthContext();
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Box pt={{ base: '30px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '50px', xl: '50px' }}>
            <Card py="20px" px={{ base: '0px' }}>
                {accountType === AccountType.distributor ? (
                    <ProkitList />
                ) : (
                    <Flex align="center" height="40vh" justifyContent="center" w="100%">
                        <Text
                            color={textColor}
                            fontSize={{ base: '14px', lg: '16px' }}
                            fontWeight="700"
                            lineHeight="100%"
                        >
                            {t('onlyDistributorPropertyLabel', { ns: 'common' })}
                        </Text>
                    </Flex>
                )}
            </Card>
        </Box>
    );
}
