import * as Yup from 'yup';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    useDisclosure,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import { ThemeTypings } from '@chakra-ui/styled-system';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import React, { useMemo, useState } from 'react';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import FormProvider, { RHFTextField } from 'components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { KEY } from 'constant/key';
import { useVerifyDealerEmailMutation } from 'graphql/mutations/dealer/__generated__/verifyDealerEmail.generated';
import { GetDealerByIdDocument } from 'graphql/queries/dealer/__generated__/getDealerById.generated';
import { useTranslation } from 'react-i18next';

type FormValueVerifyNewDealerEmailProps = {
    newEmail: string;
    password: string;
    code: string;
};

type VerifyNewDealerEmailModalProps = UseDisclosureProps & {
    btnColorScheme?: ThemeTypings['colorSchemes'];
    modalLabel: {
        modalHeader: string;
    };
};

export default function VerifyNewDealerEmailModal(props: VerifyNewDealerEmailModalProps) {
    const { isOpen, onClose, btnColorScheme = 'red', modalLabel } = props;
    const { userId } = useAuthContext();
    const accessToken = localStorage.getItem(KEY.accessToken);
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const [show, setShow] = useState(false);
    const { t } = useTranslation(['common']);
    const {
        isOpen: isOpenMutationBoundaryResult,
        onOpen: onOpenMutationBoundaryResult,
        onClose: onCloseMutationBoundaryResult,
    } = useDisclosure();

    const [verifyDealerEmailMutationFunc, verifyDealerEmailMutationResult] = useVerifyDealerEmailMutation();

    const handleClickChangePwState = () => setShow(!show);

    const ChangeDealerEmailInfoSchema = Yup.object().shape({
        newEmail: Yup.string().required('Email is required').email('Email format is required'),
        password: Yup.string().required('Password is required'),
        code: Yup.string().required('Verify code is required'),
    });

    const defaultValues = useMemo(
        () => ({
            newEmail: '',
            code: '',
            password: '',
        }),
        [],
    );

    const methods = useForm({
        resolver: yupResolver(ChangeDealerEmailInfoSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting, errors },
        register,
    } = methods;
    const onVerifyDealerEmail = async (data: FormValueVerifyNewDealerEmailProps) => {
        try {
            await verifyDealerEmailMutationFunc({
                variables: {
                    input: {
                        dealerId: userId,
                        accessToken,
                        newEmail: data.newEmail,
                        code: data.code,
                        password: data.password,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        localStorage.setItem(KEY.accessToken, res.verify_dealer_email.session.accessToken.jwtToken);
                        localStorage.setItem(KEY.userEmailAccount, data.newEmail);
                        onOpenMutationBoundaryResult();
                    }
                },
                onError: async () => {
                    onOpenMutationBoundaryResult();
                },
                refetchQueries: () => [
                    {
                        query: GetDealerByIdDocument,
                        variables: {
                            input: {
                                dealerId: userId,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };
    const handleSuccessClose = () => {
        window.location.reload();
    };

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ApolloMutationBoundary
                isOpen={isOpenMutationBoundaryResult}
                onClose={onCloseMutationBoundaryResult}
                onOpen={onOpenMutationBoundaryResult}
                onExtraAfterSuccessHandler={handleSuccessClose}
                mutationResult={verifyDealerEmailMutationResult}
                toastSuccessTitle={t('verifyChangeEmailLabel', { ns: 'common' })}
                toastSuccessDescription="Verify change email successfully"
            >
                <FormProvider methods={methods} onSubmit={handleSubmit(onVerifyDealerEmail)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{modalLabel.modalHeader}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody display="flex" alignItems="center" justifyContent="center" py={4}>
                            <VStack alignItems="center" width="80%">
                                <HStack width="100%" justifyContent="space-between" alignItems="center" mt={1}>
                                    <Text
                                        color={textColorSecondary}
                                        fontSize="13px"
                                        fontWeight="400"
                                        mr={5}
                                        // alignItems="center"
                                        minW={20}
                                    >
                                        {t('newEmailLabel', { ns: 'common' })}
                                    </Text>
                                    <RHFTextField
                                        name="newEmail"
                                        isRequired={false}
                                        borderRadius={'10px !important'}
                                        fontWeight="500"
                                        variant="main"
                                        // maxW={'240px'}
                                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                        maxH={'40px'}
                                    />
                                </HStack>

                                <HStack width="100%" justifyContent="space-between" alignItems="center" mt={1}>
                                    <Text
                                        color={textColorSecondary}
                                        fontSize="13px"
                                        fontWeight="400"
                                        mr={1}
                                        alignItems="center"
                                        minW={20}
                                    >
                                        {t('verifyCodeLabel', { ns: 'common' })}
                                    </Text>
                                    <RHFTextField
                                        name="code"
                                        isRequired={false}
                                        borderRadius={'10px !important'}
                                        fontWeight="500"
                                        variant="main"
                                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                        maxH={'40px'}
                                    />
                                </HStack>

                                <FormControl isRequired isInvalid={!!errors?.password?.message}>
                                    <VStack>
                                        <HStack width="100%" justifyContent="space-between" alignItems="center" mt={1}>
                                            <FormLabel
                                                // ms="4px"
                                                fontSize="13px"
                                                fontWeight="500"
                                                color={textColorSecondary}
                                                display="flex"
                                                alignItems="center"
                                                minW={30}
                                                mr={8}
                                            >
                                                {t('passwordLabel', { ns: 'common' })}
                                            </FormLabel>
                                            <InputGroup size="sm" maxH={'44px'}>
                                                <Input
                                                    fontSize="sm"
                                                    variant="main"
                                                    size="md"
                                                    type={show ? 'text' : 'password'}
                                                    borderRadius={'10px !important'}
                                                    name="password"
                                                    {...register('password')}
                                                />
                                                <InputRightElement display="flex" alignItems="center" mt="4px">
                                                    <Icon
                                                        color={textColorSecondary}
                                                        _hover={{ cursor: 'pointer' }}
                                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                        onClick={handleClickChangePwState}
                                                    />
                                                </InputRightElement>
                                            </InputGroup>
                                        </HStack>
                                        {errors?.password?.message && (
                                            <HStack justifyContent="flex-start" alignItems="center">
                                                <FormErrorMessage sx={{ marginBottom: 2 }}>
                                                    {errors?.password?.message}
                                                </FormErrorMessage>
                                            </HStack>
                                        )}
                                    </VStack>
                                </FormControl>
                            </VStack>
                        </ModalBody>

                        <ModalFooter display="flex" alignItems="center" justifyContent="center" pb={10}>
                            <HStack>
                                <Button
                                    colorScheme="gray.500"
                                    variant="outline"
                                    fontSize="sm"
                                    color={'gray.500'}
                                    w="120px"
                                    borderRadius={10}
                                    fontWeight="500"
                                    ms="auto"
                                    onClick={onClose}
                                >
                                    {t('cancelBtn', { ns: 'common' })}
                                </Button>

                                <Button
                                    colorScheme={btnColorScheme}
                                    variant="solid"
                                    fontSize="sm"
                                    w="120px"
                                    borderRadius={10}
                                    fontWeight="500"
                                    type="submit"
                                    isLoading={isSubmitting}
                                    ms="auto"
                                >
                                    {t('confirmBtn', { ns: 'common' })}
                                </Button>
                            </HStack>
                        </ModalFooter>
                    </ModalContent>
                </FormProvider>
            </ApolloMutationBoundary>
        </Modal>
    );
}
