import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMachineByIdQueryVariables = Types.Exact<{
    input: Types.MachineInput;
}>;

export type GetMachineByIdQuery = {
    __typename?: 'Query';
    machine: {
        __typename?: 'Machine';
        id: number;
        machineName: string;
        dummyPlayerId?: number | null;
        freeModeStart?: Date | null;
        freeModeEnd?: Date | null;
        status: Types.MachineStatus;
        inGameStatus: Types.InGameStatus;
        lastLoginAt?: Date | null;
        isPasswordRequired: boolean;
        serviceMode: Types.ProKitServiceModeOption;
        gameSetting: any;
        isEmittingShopBeacon: boolean;
        isOnline: boolean;
        teamViewerId?: string | null;
        proKit?: {
            __typename?: 'ProKit';
            id: number;
            serialCode: string;
            cpuTemperature?: number | null;
            cpuUsage?: number | null;
            memoryUsage?: number | null;
            totalActivateCount: number;
            modelName: string;
            usageReportedAt?: Date | null;
            status: Types.ProKitStatus;
            lastActiveAt?: Date | null;
            isActivated: boolean;
            subscriptionPlan?: Types.GranProSubscriptionPlan | null;
            demonstrationPeriodEndAt?: Date | null;
            dealer?: { __typename?: 'Dealer'; id: number; name: string } | null;
            subscriptionPlanSchedule?: {
                __typename?: 'GranProSubscriptionPlanSchedule';
                subscriptionPlan: Types.GranProSubscriptionPlan;
                validFrom: Date;
            } | null;
        } | null;
        shop: {
            __typename?: 'Shop';
            id: number;
            name: string;
            iconUrl?: string | null;
            status: Types.ShopStatus;
            dealer: { __typename?: 'Dealer'; id: number; name: string };
        };
        playerEntries: Array<{
            __typename?: 'Player';
            id: number;
            player_name: string;
            type: Types.PlayerType;
            gran_id: string;
            icon_url?: string | null;
            status: Types.PlayerStatus;
            rank: string;
            board_size?: Types.BoardSize | null;
        } | null>;
    };
};

export const GetMachineByIdDocument = gql`
    query getMachineById($input: MachineInput!) {
        machine(input: $input) {
            id
            machineName
            dummyPlayerId
            freeModeStart
            freeModeEnd
            status
            inGameStatus
            lastLoginAt
            isPasswordRequired
            serviceMode
            gameSetting
            isPasswordRequired
            isEmittingShopBeacon
            isOnline
            teamViewerId
            freeModeStart
            freeModeEnd
            proKit {
                id
                dealer {
                    id
                    name
                }
                serialCode
                cpuTemperature
                cpuUsage
                memoryUsage
                totalActivateCount
                modelName
                usageReportedAt
                status
                lastActiveAt
                isActivated
                subscriptionPlan
                demonstrationPeriodEndAt
                subscriptionPlanSchedule {
                    subscriptionPlan
                    validFrom
                }
            }
            shop {
                id
                name
                iconUrl
                status
                dealer {
                    id
                    name
                }
            }
            playerEntries {
                id
                player_name
                type
                gran_id
                icon_url
                status
                rank
                board_size
            }
        }
    }
`;

/**
 * __useGetMachineByIdQuery__
 *
 * To run a query within a React component, call `useGetMachineByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMachineByIdQuery(
    baseOptions: Apollo.QueryHookOptions<GetMachineByIdQuery, GetMachineByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMachineByIdQuery, GetMachineByIdQueryVariables>(GetMachineByIdDocument, options);
}
export function useGetMachineByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetMachineByIdQuery, GetMachineByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMachineByIdQuery, GetMachineByIdQueryVariables>(GetMachineByIdDocument, options);
}
export type GetMachineByIdQueryHookResult = ReturnType<typeof useGetMachineByIdQuery>;
export type GetMachineByIdLazyQueryHookResult = ReturnType<typeof useGetMachineByIdLazyQuery>;
export type GetMachineByIdQueryResult = Apollo.QueryResult<GetMachineByIdQuery, GetMachineByIdQueryVariables>;
