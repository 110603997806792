import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LiftShopMachineProhibitionMutationVariables = Types.Exact<{
    input: Types.LiftShopMachineProhibitionInput;
}>;

export type LiftShopMachineProhibitionMutation = {
    __typename?: 'Mutation';
    lift_shop_machine_prohibition: Types.SuccessResponse;
};

export const LiftShopMachineProhibitionDocument = gql`
    mutation liftShopMachineProhibition($input: LiftShopMachineProhibitionInput!) {
        lift_shop_machine_prohibition(input: $input)
    }
`;
export type LiftShopMachineProhibitionMutationFn = Apollo.MutationFunction<
    LiftShopMachineProhibitionMutation,
    LiftShopMachineProhibitionMutationVariables
>;

/**
 * __useLiftShopMachineProhibitionMutation__
 *
 * To run a mutation, you first call `useLiftShopMachineProhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLiftShopMachineProhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [liftShopMachineProhibitionMutation, { data, loading, error }] = useLiftShopMachineProhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLiftShopMachineProhibitionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LiftShopMachineProhibitionMutation,
        LiftShopMachineProhibitionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LiftShopMachineProhibitionMutation, LiftShopMachineProhibitionMutationVariables>(
        LiftShopMachineProhibitionDocument,
        options,
    );
}
export type LiftShopMachineProhibitionMutationHookResult = ReturnType<typeof useLiftShopMachineProhibitionMutation>;
export type LiftShopMachineProhibitionMutationResult = Apollo.MutationResult<LiftShopMachineProhibitionMutation>;
export type LiftShopMachineProhibitionMutationOptions = Apollo.BaseMutationOptions<
    LiftShopMachineProhibitionMutation,
    LiftShopMachineProhibitionMutationVariables
>;
