import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMachineListOfDealerQueryVariables = Types.Exact<{
    input: Types.GetMachineListOfDealerInput;
}>;

export type GetMachineListOfDealerQuery = {
    __typename?: 'Query';
    get_machine_list_of_dealer: {
        __typename?: 'MachineConnection';
        totalCount: number;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
        edges: Array<{
            __typename?: 'MachineEdge';
            cursor: string;
            node?: {
                __typename?: 'Machine';
                id: number;
                machineName: string;
                dummyPlayerId?: number | null;
                freeModeStart?: Date | null;
                freeModeEnd?: Date | null;
                status: Types.MachineStatus;
                inGameStatus: Types.InGameStatus;
                lastLoginAt?: Date | null;
                isPasswordRequired: boolean;
                serviceMode: Types.ProKitServiceModeOption;
                gameSetting: any;
                isEmittingShopBeacon: boolean;
                isOnline: boolean;
                teamViewerId?: string | null;
                lastInformationEditedAt?: Date | null;
                proKit?: {
                    __typename?: 'ProKit';
                    id: number;
                    serialCode: string;
                    cpuTemperature?: number | null;
                    cpuUsage?: number | null;
                    memoryUsage?: number | null;
                    totalActivateCount: number;
                    modelName: string;
                    usageReportedAt?: Date | null;
                    status: Types.ProKitStatus;
                    lastActiveAt?: Date | null;
                    isActivated: boolean;
                    subscriptionPlan?: Types.GranProSubscriptionPlan | null;
                    demonstrationPeriodEndAt?: Date | null;
                    dealer?: { __typename?: 'Dealer'; id: number; name: string } | null;
                    subscriptionPlanSchedule?: {
                        __typename?: 'GranProSubscriptionPlanSchedule';
                        subscriptionPlan: Types.GranProSubscriptionPlan;
                        validFrom: Date;
                    } | null;
                } | null;
                shop: {
                    __typename?: 'Shop';
                    id: number;
                    name: string;
                    iconUrl?: string | null;
                    status: Types.ShopStatus;
                    dealer: { __typename?: 'Dealer'; id: number; name: string };
                };
                playerEntries: Array<{
                    __typename?: 'Player';
                    id: number;
                    player_name: string;
                    type: Types.PlayerType;
                    gran_id: string;
                    icon_url?: string | null;
                    status: Types.PlayerStatus;
                    rank: string;
                    board_size?: Types.BoardSize | null;
                } | null>;
            } | null;
        } | null>;
    };
};

export const GetMachineListOfDealerDocument = gql`
    query getMachineListOfDealer($input: GetMachineListOfDealerInput!) {
        get_machine_list_of_dealer(input: $input) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    machineName
                    dummyPlayerId
                    freeModeStart
                    freeModeEnd
                    status
                    inGameStatus
                    lastLoginAt
                    isPasswordRequired
                    serviceMode
                    gameSetting
                    isPasswordRequired
                    isEmittingShopBeacon
                    isOnline
                    teamViewerId
                    lastInformationEditedAt
                    proKit {
                        id
                        dealer {
                            id
                            name
                        }
                        serialCode
                        cpuTemperature
                        cpuUsage
                        memoryUsage
                        totalActivateCount
                        modelName
                        usageReportedAt
                        status
                        lastActiveAt
                        isActivated
                        subscriptionPlan
                        demonstrationPeriodEndAt
                        subscriptionPlanSchedule {
                            subscriptionPlan
                            validFrom
                        }
                    }
                    shop {
                        id
                        name
                        iconUrl
                        status
                        dealer {
                            id
                            name
                        }
                    }
                    playerEntries {
                        id
                        player_name
                        type
                        gran_id
                        icon_url
                        status
                        rank
                        board_size
                    }
                }
            }
        }
    }
`;

/**
 * __useGetMachineListOfDealerQuery__
 *
 * To run a query within a React component, call `useGetMachineListOfDealerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineListOfDealerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineListOfDealerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMachineListOfDealerQuery(
    baseOptions: Apollo.QueryHookOptions<GetMachineListOfDealerQuery, GetMachineListOfDealerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMachineListOfDealerQuery, GetMachineListOfDealerQueryVariables>(
        GetMachineListOfDealerDocument,
        options,
    );
}
export function useGetMachineListOfDealerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetMachineListOfDealerQuery, GetMachineListOfDealerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMachineListOfDealerQuery, GetMachineListOfDealerQueryVariables>(
        GetMachineListOfDealerDocument,
        options,
    );
}
export type GetMachineListOfDealerQueryHookResult = ReturnType<typeof useGetMachineListOfDealerQuery>;
export type GetMachineListOfDealerLazyQueryHookResult = ReturnType<typeof useGetMachineListOfDealerLazyQuery>;
export type GetMachineListOfDealerQueryResult = Apollo.QueryResult<
    GetMachineListOfDealerQuery,
    GetMachineListOfDealerQueryVariables
>;
