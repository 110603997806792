import _mock from '../../../../../utils/_mock';
import { now, random, sample } from 'lodash';
import { ShopStatus } from '../../../../../types/graphql';

export type ShopObj = {
    id: number;
    name: string[];
    avatar: string;
    dealerName: string;
    province: string;
    shop?: number; // TODO: what happen with this value
    machine: number;
    registrationDate: string | number | Date;
    status: ShopStatus;
    statusMix: [ShopStatus, boolean];
};

export const dummyShopTableData: ShopObj[] = [...Array(12)].map((_, index) => ({
    id: index,
    name: [_mock.name.fullName(index), _mock.avatar()],
    dealerName: _mock.name.firstName(index),
    avatar: _mock.avatar(),
    province: _mock.province(),
    shop: random(2, 99),
    machine: random(2, 10),
    registrationDate: now(),
    status: sample([ShopStatus.Deleted, ShopStatus.Enabled]),
    statusMix: [ShopStatus.Enabled, true],
}));

export default dummyShopTableData;
