import { Flex, FormControl, FormErrorMessage, FormLabel, Select, useColorModeValue } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectProps } from '@chakra-ui/select/src/select';
import { Country } from '../../types/graphql';
import { useTranslation } from 'react-i18next';

type RHFCountrySelectProps = SelectProps & {
    name: string;
};

export function RHFCountrySelect({ name, ...other }: RHFCountrySelectProps) {
    const { control } = useFormContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl isInvalid={!!error}>
                    <Flex direction="column" mb="20px">
                        <FormLabel
                            ms="10px"
                            htmlFor="country"
                            fontSize="sm"
                            color={textColorPrimary}
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                        >
                            {t('country', { ns: 'common' })}
                        </FormLabel>
                        <Select
                            {...field}
                            {...other}
                            fontSize="sm"
                            id="currency"
                            variant="main"
                            h="44px"
                            maxH="44px"
                            defaultValue={Country.Japan}
                        >
                            {/*<option value="denmark">Denmark</option>*/}
                            {/*<option value="france">France</option>*/}
                            {/*<option value="germany">Germany</option>*/}
                            {/*<option value="hongKong">HongKong</option>*/}
                            <option value={Country.Japan}>{t('countryOption1', { ns: 'common' })}</option>
                            {/*<option value="korea">Korea</option>*/}
                            {/*<option value="spain">Spain</option>*/}
                            {/*<option value="taiwan">Taiwan</option>*/}
                            <option value={Country.Us}>{t('countryOption2', { ns: 'common' })}</option>
                        </Select>
                        <FormErrorMessage>{error && error.message}</FormErrorMessage>
                    </Flex>
                </FormControl>
            )}
        />
    );
}
