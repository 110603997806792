import { useEffect, useRef } from 'react';
import { useToast, UseToastOptions } from '@chakra-ui/react';

export const useToastOnce = (options?: UseToastOptions) => {
    const errorToastRef = useRef(false);
    const toast = useToast();

    useEffect(() => {
        if (!errorToastRef.current) {
            toast(options);
            errorToastRef.current = true;
        }
    });
};
