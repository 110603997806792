import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import IncomeListByDealer from './components/IncomeListByDealer';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import IncomeListByDistributor from './components/IncomeListByDistributor';

export default function IncomeOverview() {
    const { accountType } = useAuthContext();
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Box pt={{ base: '30px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '50px', xl: '50px' }}>
            <Card py="20px" px={{ base: '0px', lg: '30px' }}>
                {accountType === AccountType.dealer ? (
                    <IncomeListByDealer />
                ) : accountType === AccountType.distributor ? (
                    <IncomeListByDistributor />
                ) : (
                    <Flex align="center" justifyContent="center" w="100%">
                        <Text
                            color={textColor}
                            fontSize={{ base: '14px', lg: '16px' }}
                            fontWeight="700"
                            lineHeight="100%"
                        >
                            {t('onlyDealerPropertyLabel', { ns: 'common' })}
                        </Text>
                    </Flex>
                )}
            </Card>
        </Box>
    );
}
