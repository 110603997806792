import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendLaunchTeamViewerNotificationMutationVariables = Types.Exact<{
    input: Types.SendLaunchTeamViewerNotificationInput;
}>;

export type SendLaunchTeamViewerNotificationMutation = {
    __typename?: 'Mutation';
    send_launch_team_viewer_notification: Types.SuccessResponse;
};

export const SendLaunchTeamViewerNotificationDocument = gql`
    mutation sendLaunchTeamViewerNotification($input: SendLaunchTeamViewerNotificationInput!) {
        send_launch_team_viewer_notification(input: $input)
    }
`;
export type SendLaunchTeamViewerNotificationMutationFn = Apollo.MutationFunction<
    SendLaunchTeamViewerNotificationMutation,
    SendLaunchTeamViewerNotificationMutationVariables
>;

/**
 * __useSendLaunchTeamViewerNotificationMutation__
 *
 * To run a mutation, you first call `useSendLaunchTeamViewerNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLaunchTeamViewerNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLaunchTeamViewerNotificationMutation, { data, loading, error }] = useSendLaunchTeamViewerNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendLaunchTeamViewerNotificationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SendLaunchTeamViewerNotificationMutation,
        SendLaunchTeamViewerNotificationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        SendLaunchTeamViewerNotificationMutation,
        SendLaunchTeamViewerNotificationMutationVariables
    >(SendLaunchTeamViewerNotificationDocument, options);
}
export type SendLaunchTeamViewerNotificationMutationHookResult = ReturnType<
    typeof useSendLaunchTeamViewerNotificationMutation
>;
export type SendLaunchTeamViewerNotificationMutationResult =
    Apollo.MutationResult<SendLaunchTeamViewerNotificationMutation>;
export type SendLaunchTeamViewerNotificationMutationOptions = Apollo.BaseMutationOptions<
    SendLaunchTeamViewerNotificationMutation,
    SendLaunchTeamViewerNotificationMutationVariables
>;
