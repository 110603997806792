import { CustomFile } from '../types';
import { Avatar, Flex } from '@chakra-ui/react';
import { DefaultAvatarSrc } from '../../../constant/defaultValues';

// ----------------------------------------------------------------------

type Props = {
    file: CustomFile | string | null;
};

export default function AvatarPreview({ file }: Props) {
    if (!file) {
        return (
            <Flex direction="column" height="100%" alignItems="center" mr={5}>
                <Avatar
                    justifyContent="flex-start"
                    alignSelf="flex-start"
                    alignContent="flex-start"
                    src={DefaultAvatarSrc}
                    h={{ base: '57px', xl: '57px' }}
                    w={{ base: '57px', xl: '57px' }}
                    border="0px solid"
                    my={1}
                />
            </Flex>
        );
    }

    const imgUrl = typeof file === 'string' ? file : file.preview;

    return (
        <Flex direction="column" height="100%" alignItems="center" mr={5}>
            <Avatar
                justifyContent="flex-start"
                alignSelf="flex-start"
                alignContent="flex-start"
                src={imgUrl}
                h={{ base: '57px', xl: '57px' }}
                w={{ base: '57px', xl: '57px' }}
                border="0px solid"
                my={1}
            />
        </Flex>
    );
}
