import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RentMachineByHourMutationVariables = Types.Exact<{
    input: Types.RentMachineByHourInput;
}>;

export type RentMachineByHourMutation = {
    __typename?: 'Mutation';
    rent_machine_by_hour: {
        __typename?: 'Machine';
        id: number;
        machineName: string;
        dummyPlayerId?: number | null;
        freeModeStart?: Date | null;
        freeModeEnd?: Date | null;
        status: Types.MachineStatus;
        inGameStatus: Types.InGameStatus;
        lastLoginAt?: Date | null;
        isPasswordRequired: boolean;
        serviceMode: Types.ProKitServiceModeOption;
        gameSetting: any;
        isEmittingShopBeacon: boolean;
        isOnline: boolean;
        teamViewerId?: string | null;
        shop: { __typename?: 'Shop'; id: number; name: string; iconUrl?: string | null };
        proKit?: { __typename?: 'ProKit'; id: number; modelName: string } | null;
    };
};

export const RentMachineByHourDocument = gql`
    mutation rentMachineByHour($input: RentMachineByHourInput!) {
        rent_machine_by_hour(input: $input) {
            id
            machineName
            shop {
                id
                name
                iconUrl
            }
            proKit {
                id
                modelName
            }
            dummyPlayerId
            freeModeStart
            freeModeEnd
            status
            inGameStatus
            lastLoginAt
            isPasswordRequired
            serviceMode
            gameSetting
            isPasswordRequired
            isEmittingShopBeacon
            isOnline
            teamViewerId
            freeModeStart
            freeModeEnd
        }
    }
`;
export type RentMachineByHourMutationFn = Apollo.MutationFunction<
    RentMachineByHourMutation,
    RentMachineByHourMutationVariables
>;

/**
 * __useRentMachineByHourMutation__
 *
 * To run a mutation, you first call `useRentMachineByHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRentMachineByHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rentMachineByHourMutation, { data, loading, error }] = useRentMachineByHourMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRentMachineByHourMutation(
    baseOptions?: Apollo.MutationHookOptions<RentMachineByHourMutation, RentMachineByHourMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RentMachineByHourMutation, RentMachineByHourMutationVariables>(
        RentMachineByHourDocument,
        options,
    );
}
export type RentMachineByHourMutationHookResult = ReturnType<typeof useRentMachineByHourMutation>;
export type RentMachineByHourMutationResult = Apollo.MutationResult<RentMachineByHourMutation>;
export type RentMachineByHourMutationOptions = Apollo.BaseMutationOptions<
    RentMachineByHourMutation,
    RentMachineByHourMutationVariables
>;
