import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from 'react';
import { fYyyyMmDdHhMm } from '../../../../../../utils/format-time';
import { ProkitDetailType } from '../prokitType';
import { useTranslation } from 'react-i18next';

type ProkitInfoCardProps = ProkitDetailType & {
    [key: string]: any;
};

export default function ProkitInfoCard(props: ProkitInfoCardProps) {
    const { prokit, ...rest } = props;
    const textColorPrimary = useColorModeValue('navy.650', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const { t } = useTranslation(['common', 'machine']);

    return (
        <Card py="20px" px={{ base: '20px', md: '35px', xl: '50px' }} {...rest}>
            <Flex direction={'row'} justify="space-between" align="center">
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
                    {t('proKitDetailLabel', { ns: 'common' })}
                </Text>
            </Flex>

            <Flex direction={'column'} mb={{ base: '20px' }} gap={'10px'}>
                <Flex direction="row" mt={5} alignItems="center">
                    <Text color={textColorSecondary} fontSize="14px" fontWeight="500" mr={3}>
                        {t('model', { ns: 'common' })}
                    </Text>
                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                        {prokit.modelName}
                    </Text>
                </Flex>

                <Flex direction="row" mt={3} alignItems="center">
                    <Text
                        color={textColorSecondary}
                        fontSize="14px"
                        fontWeight="500"
                        mr={3}
                        minW={{ sm: '80px', md: 'auto' }}
                    >
                        {t('serialCodeLabel', { ns: 'common' })}
                    </Text>
                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                        {prokit.serialCode}
                    </Text>
                </Flex>

                <Flex direction="row" mt={3} alignItems="center">
                    <Text color={textColorSecondary} fontSize="14px" fontWeight="500" mr={3}>
                        {t('lastActivateLabel', { ns: 'common' })}
                    </Text>
                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                        {prokit.lastActiveAt ? fYyyyMmDdHhMm(prokit.lastActiveAt) : '-'}
                    </Text>
                </Flex>

                <Flex direction="row" mt={3} alignItems="center">
                    <Text color={textColorSecondary} fontSize="14px" fontWeight="500" mr={3}>
                        {t('totalActivateLabel', { ns: 'common' })}
                    </Text>
                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                        {prokit.totalActivateCount}
                    </Text>
                </Flex>

                <Flex direction="row" mt={3} alignItems="center">
                    <Text color={textColorSecondary} fontSize="14px" fontWeight="500" mr={3}>
                        {t('dealerName', { ns: 'common' })}
                    </Text>
                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                        {prokit.dealer.name}
                    </Text>
                </Flex>

                <Flex direction="row" mt={3} alignItems="center">
                    <Text color={textColorSecondary} fontSize="14px" fontWeight="500" mr={3}>
                        {t('shopName', { ns: 'common' })}
                    </Text>
                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                        {prokit.shop && prokit.shop.name ? prokit.shop.name : '-'}
                    </Text>
                </Flex>

                <Flex direction="row" mt={3} alignItems="center">
                    <Text color={textColorSecondary} fontSize="14px" fontWeight="500" mr={3}>
                        {t('machineNameLabel', { ns: 'common' })}
                    </Text>
                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                        {prokit.machine && prokit.machine.machineName ? prokit.machine.machineName : '-'}
                    </Text>
                </Flex>

                <Flex direction="row" mt={3} alignItems="center">
                    <Text color={textColorSecondary} fontSize="14px" fontWeight="500" mr={3}>
                        {t('totalUptimeLabel', { ns: 'common' })}
                    </Text>
                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                        158h 39min
                    </Text>
                </Flex>
            </Flex>
        </Card>
    );
}
