import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMachineMutationVariables = Types.Exact<{
    input: Types.DeleteMachineInput;
}>;

export type DeleteMachineMutation = { __typename?: 'Mutation'; delete_machine: Types.SuccessResponse };

export const DeleteMachineDocument = gql`
    mutation deleteMachine($input: DeleteMachineInput!) {
        delete_machine(input: $input)
    }
`;
export type DeleteMachineMutationFn = Apollo.MutationFunction<DeleteMachineMutation, DeleteMachineMutationVariables>;

/**
 * __useDeleteMachineMutation__
 *
 * To run a mutation, you first call `useDeleteMachineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMachineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMachineMutation, { data, loading, error }] = useDeleteMachineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMachineMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteMachineMutation, DeleteMachineMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteMachineMutation, DeleteMachineMutationVariables>(DeleteMachineDocument, options);
}
export type DeleteMachineMutationHookResult = ReturnType<typeof useDeleteMachineMutation>;
export type DeleteMachineMutationResult = Apollo.MutationResult<DeleteMachineMutation>;
export type DeleteMachineMutationOptions = Apollo.BaseMutationOptions<
    DeleteMachineMutation,
    DeleteMachineMutationVariables
>;
