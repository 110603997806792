import { Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';

import Card from 'components/card/Card';
import EntryPlayer from './EntryPlayer';
import { MdModeEdit } from 'react-icons/md';
import * as React from 'react';
import { useState } from 'react';
import { TfiClose } from 'react-icons/tfi';
import { PickValueOf } from '../../../../../../types/common';
import { MachineDetailType } from '../MachineDetailType';
import { DefaultAvatarSrc } from '../../../../../../constant/defaultValues';
import { useTranslation } from 'react-i18next';

type EntryPlayerListProps = {
    entryPlayers: PickValueOf<PickValueOf<MachineDetailType, 'machine'>, 'playerEntries'>;
    machineId: number;
    [key: string]: any;
};

export default function EntryPlayerList(props: EntryPlayerListProps) {
    const { machineId, entryPlayers, ...rest } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const textColor = useColorModeValue('#4318FF', 'white');
    const { t } = useTranslation(['common', 'machine']);
    const [isEdit, setIsEdit] = useState(false);

    const onClickChangeEditable = () => {
        setIsEdit(!isEdit);
    };
    return (
        <Card
            flexDirection="column"
            w="100%"
            py={8}
            px={{ base: '20px', lg: '30px', '2xl': '30px' }}
            // pb="20px !important"
            {...rest}
        >
            <Flex direction={'row'} justifyContent="space-between" mb={10}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="20px">
                    {t('entryPlayersLabel', { ns: 'machine' })}
                </Text>
                {isEdit ? (
                    <Flex direction={'row'} w="35%" mt={-1}>
                        <Button
                            p="0px"
                            ms="auto"
                            onClick={onClickChangeEditable}
                            variant="no-hover"
                            bg="transparent"
                            mt={1}
                        >
                            <Icon as={TfiClose} w="22px" h="22px" color={textColorSecondary} mr={1} />
                        </Button>
                    </Flex>
                ) : (
                    <Button
                        p="0px"
                        ms="auto"
                        onClick={onClickChangeEditable}
                        variant="no-hover"
                        bg="transparent"
                        // mt={-1}
                    >
                        <Icon as={MdModeEdit} w="16px" h="16px" color={textColor} mr={1} />
                        <Text fontSize="16px" color={textColor} fontWeight="bold">
                            {t('editLabel', { ns: 'common' })}
                        </Text>
                    </Button>
                )}
            </Flex>
            {!entryPlayers ||
                (entryPlayers.length < 1 && (
                    <Text fontSize="16px" color={textColor} fontWeight="bold">
                        {t('noEntryPlayersLabel', { ns: 'machine' })}
                    </Text>
                ))}
            {entryPlayers.map((ePlayer) => {
                return (
                    <EntryPlayer
                        key={ePlayer.id}
                        mb="30px"
                        name={ePlayer.player_name}
                        avatar={ePlayer.icon_url ?? DefaultAvatarSrc}
                        rank={ePlayer.rank as Rank}
                        isEdit={isEdit}
                        machineId={machineId}
                        entryPlayerId={ePlayer.id}
                    />
                );
            })}
        </Card>
    );
}
