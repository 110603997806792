import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProhibitShopMachineMutationVariables = Types.Exact<{
    input: Types.ProhibitShopMachineInput;
}>;

export type ProhibitShopMachineMutation = { __typename?: 'Mutation'; prohibit_shop_machine: Types.SuccessResponse };

export const ProhibitShopMachineDocument = gql`
    mutation prohibitShopMachine($input: ProhibitShopMachineInput!) {
        prohibit_shop_machine(input: $input)
    }
`;
export type ProhibitShopMachineMutationFn = Apollo.MutationFunction<
    ProhibitShopMachineMutation,
    ProhibitShopMachineMutationVariables
>;

/**
 * __useProhibitShopMachineMutation__
 *
 * To run a mutation, you first call `useProhibitShopMachineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProhibitShopMachineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prohibitShopMachineMutation, { data, loading, error }] = useProhibitShopMachineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProhibitShopMachineMutation(
    baseOptions?: Apollo.MutationHookOptions<ProhibitShopMachineMutation, ProhibitShopMachineMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ProhibitShopMachineMutation, ProhibitShopMachineMutationVariables>(
        ProhibitShopMachineDocument,
        options,
    );
}
export type ProhibitShopMachineMutationHookResult = ReturnType<typeof useProhibitShopMachineMutation>;
export type ProhibitShopMachineMutationResult = Apollo.MutationResult<ProhibitShopMachineMutation>;
export type ProhibitShopMachineMutationOptions = Apollo.BaseMutationOptions<
    ProhibitShopMachineMutation,
    ProhibitShopMachineMutationVariables
>;
