import { Flex, FlexProps, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type TableNoDataProps = FlexProps;

export default function TableNoData(props: TableNoDataProps) {
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Flex align="center" justifyContent="center" w="100%" {...props}>
            <Text color={textColor} fontSize={{ base: '14px', lg: '20px' }} fontWeight="700" lineHeight="100%">
                {t('noDataMessage', { ns: 'common' })}
            </Text>
        </Flex>
    );
}
