import { Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import DealerList from './components/DealerList';
import { useAuthContext } from '../../../../../layouts/auth/useAuthContext';
import { AccountType } from '../../../../../types/user';
import { DealerType } from '../../../../../types/graphql';
import SubordinateDealerList from './components/SubordinateDealerList';
import localStorageAvailable from '../../../../../utils/localStorageAvailable';
import { useMemo } from 'react';
import { KEY } from '../../../../../constant/key';

export default function DealerOverview() {
    const { accountType } = useAuthContext();
    const storageAvailable = localStorageAvailable();
    const dealerType = useMemo(() => {
        return storageAvailable ? (localStorage.getItem(KEY.dealerType) as DealerType) : null;
    }, []);
    return (
        <Box pt={{ base: '40px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '50px', xl: '50px' }}>
            <Card py="20px" px={{ base: '0px', lg: '30px' }}>
                {accountType === AccountType.distributor && <DealerList />}

                {accountType === AccountType.dealer && dealerType === DealerType.Partner && <SubordinateDealerList />}
            </Card>
        </Box>
    );
}
