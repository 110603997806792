import { Flex } from '@chakra-ui/react';

export default function Default() {
    // const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
    return (
        <Flex direction={{ base: 'column', xl: 'row' }} pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Flex direction="column" width="stretch"></Flex>
        </Flex>
    );
}
