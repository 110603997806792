// noinspection DuplicatedCode

import {
    Avatar,
    Button,
    Flex,
    HStack,
    Icon,
    SimpleGrid,
    Text,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import Card from 'components/card/Card';
import { MdModeEdit } from 'react-icons/md';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import { ShopDetailProps } from '../shopDetailTypes';
import { resolverLanguageCodeToString } from 'utils/formatEnumTypes';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useEditShopInformationMutation } from 'graphql/mutations/dealer/__generated__/editShopInformation.generated';
import FormProvider, { RHFTextField } from 'components/hook-form';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFCountrySelectWithoutLabel } from 'components/hook-form/RHFCountrySelectWithoutLabel';
import { RHFLanguageSelectWithoutLabel } from 'components/hook-form/RHFLanguageSelectWithoutLabel';
import { GetShopByIdDocument } from 'graphql/queries/shop/__generated__/getShopById.generated';
import { useTranslation } from 'react-i18next';

type ShopInfoUpdateProps = {
    shopName: string;
    countryCode: string;
    languageCode: string;
    zipCode: string;
    province: string;
    address: string;
    phoneNumber: string;
    afterSubmit?: string;
};

export default function ShopInfoCard(props: ShopDetailProps & { [x: string]: any }) {
    const { shop, ...rest } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const textColor = useColorModeValue('#4318FF', 'white');
    const [isEdit, setIsEdit] = useState(false);
    const { t } = useTranslation(['common']);
    const {
        isOpen: isOpenMutationBoundaryResult,
        onOpen: onOpenMutationBoundaryResult,
        onClose: onCloseMutationBoundaryResult,
    } = useDisclosure();

    const [editShopInformationMutationFunc, editShopInformationMutationResult] = useEditShopInformationMutation();

    const ShopInfoUpdateSchema = Yup.object().shape({
        shopName: Yup.string().required('Shop Name is required'),
        countryCode: Yup.string().required('Country is required'),
        languageCode: Yup.string().required('Language is required'),
        zipCode: Yup.string().required('Zip Code is required'),
        province: Yup.string().required('Province is required'),
        address: Yup.string().required('Address is required'),
        phoneNumber: Yup.string().required('Phone Number is required'),
    });

    const defaultValues = useMemo(
        () => ({
            shopName: shop.name,
            countryCode: shop.countryCode,
            languageCode: shop.languageCode,
            zipCode: shop.zipCode,
            province: shop.province,
            address: shop.address,
            phoneNumber: shop.phoneNumber,
        }),
        [shop],
    );

    const methods = useForm({
        resolver: yupResolver(ShopInfoUpdateSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        reset,
    } = methods;

    const onClickChangeEditable = () => {
        setIsEdit(!isEdit);
    };

    const onHandleCancel = () => {
        setIsEdit(!isEdit);
        reset();
    };

    const onHandleEditShop = async (data: ShopInfoUpdateProps) => {
        try {
            await editShopInformationMutationFunc({
                variables: {
                    input: {
                        shopId: shop.id,
                        name: data.shopName === shop.name ? undefined : data.shopName,
                        countryCode: data.countryCode === shop.countryCode ? undefined : data.countryCode,
                        languageCode: data.languageCode === shop.languageCode ? undefined : data.languageCode,
                        zipCode: data.zipCode === shop.zipCode ? undefined : data.zipCode,
                        province: data.province === shop.province ? undefined : data.province,
                        address: data.address === shop.address ? undefined : data.address,
                        phoneNumber: data.phoneNumber === shop.phoneNumber ? undefined : data.phoneNumber,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenMutationBoundaryResult();
                    }
                },
                onError: async () => {
                    onOpenMutationBoundaryResult();
                },
                refetchQueries: () => [
                    {
                        query: GetShopByIdDocument,
                        variables: {
                            input: {
                                shopId: shop.id,
                            },
                        },
                    },
                ],
            });
            setIsEdit(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenMutationBoundaryResult}
            onClose={onCloseMutationBoundaryResult}
            onOpen={onOpenMutationBoundaryResult}
            mutationResult={editShopInformationMutationResult}
            toastSuccessTitle="Shop updated"
            toastSuccessDescription="Updated Shop successfully"
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onHandleEditShop)}>
                <Card py="20px" px={{ base: '20px', xl: '50px' }} {...rest}>
                    <Flex direction={'row'} justify="space-between" align="center">
                        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
                            {t('shopInfoLabel', { ns: 'common' })}
                        </Text>
                        {isEdit ? (
                            <Flex
                                direction={'row'}
                                w={{ base: '45%', '2xl': '25%' }}
                                mt={1}
                                justifyContent="flex-end"
                                alignSelf="flex-end"
                            >
                                <Button
                                    p="0px"
                                    ms="auto"
                                    type="submit"
                                    variant="brand"
                                    fontWeight="500"
                                    minW={'70px'}
                                    maxH={35}
                                    borderRadius="10"
                                    mt={1}
                                    backgroundColor="#4318FF"
                                    isLoading={isSubmitting}
                                >
                                    <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                                </Button>
                                <Button
                                    p="0px"
                                    ms="auto"
                                    onClick={onHandleCancel}
                                    variant="no-hover"
                                    bg="transparent"
                                    mt={1}
                                >
                                    <Text fontSize="16px" color={textColorSecondary} fontWeight="bold">
                                        {t('cancelBtn', { ns: 'common' })}
                                    </Text>
                                </Button>
                            </Flex>
                        ) : (
                            <Button
                                p="0px"
                                ms="auto"
                                // disabled={!(accountType === AccountType.dealer && userId === shop.dealer.id)}
                                disabled
                                onClick={onClickChangeEditable}
                                variant="no-hover"
                                bg="transparent"
                                mt={1}
                            >
                                <Icon as={MdModeEdit} w="16px" h="16px" color={textColor} mr={1} />
                                <Text fontSize="16px" color={textColor} fontWeight="bold">
                                    {t('editLabel', { ns: 'common' })}
                                </Text>
                            </Button>
                        )}
                    </Flex>

                    {isEdit && (
                        <Flex w={'100%'} direction="row" alignItems="center" mt={5} mb={1}>
                            <Flex direction="column" height="100%" alignItems="center" mr={5}>
                                <Avatar
                                    justifyContent="flex-start"
                                    alignSelf="flex-start"
                                    alignContent="flex-start"
                                    src={Avatar4}
                                    h={{ base: '57px', xl: '57px' }}
                                    w={{ base: '57px', xl: '57px' }}
                                    border="0px solid"
                                    my={1}
                                />
                            </Flex>
                            <Flex direction="column" mr={5}>
                                <Button
                                    colorScheme="navy"
                                    variant="outline"
                                    p="15px 40px"
                                    fontSize="sm"
                                    h="40px"
                                    w="120px"
                                    borderRadius={10}
                                    fontWeight="500"
                                    ms="auto"
                                >
                                    Upload photo
                                </Button>
                            </Flex>
                            <Flex direction="column" mr={5}>
                                <Button
                                    colorScheme="red"
                                    variant="outline"
                                    p="15px 40px"
                                    fontSize="sm"
                                    h="40px"
                                    w="120px"
                                    borderRadius={10}
                                    fontWeight="500"
                                    ms="auto"
                                >
                                    Delete photo
                                </Button>
                            </Flex>
                        </Flex>
                    )}

                    <SimpleGrid
                        columns={{ sm: 1 }}
                        spacing={{ base: '6px', xl: '6px' }}
                        mt={{ base: '20px', xl: '20px' }}
                        gap={{ base: '20px', xl: '20px' }}
                    >
                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text
                                    color={textColorSecondary}
                                    fontSize="14px"
                                    fontWeight="400"
                                    mr={1}
                                    alignItems="center"
                                    minW={20}
                                    // mb="25px"
                                >
                                    {t('shopName', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="shopName"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    fontWeight="500"
                                    variant="main"
                                    maxW={'240px'}
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('shopName', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {shop.name}
                                </Text>
                            </HStack>
                        )}

                        <HStack justifyContent="flex-start" alignItems="center" mt={isEdit ? 3 : 0}>
                            <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                {t('dealerName', { ns: 'common' })}
                            </Text>
                            <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                {shop.dealer.name}
                            </Text>
                        </HStack>
                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('country', { ns: 'common' })}
                                </Text>
                                <RHFCountrySelectWithoutLabel
                                    name="countryCode"
                                    borderRadius={'10px !important'}
                                    fontSize="16px"
                                    h="40px"
                                    maxH="40px"
                                    maxW={'240px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('country', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {shop.countryCode}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('language', { ns: 'common' })}
                                </Text>
                                <RHFLanguageSelectWithoutLabel
                                    name="languageCode"
                                    fontSize="16px"
                                    h="40px"
                                    maxH="40px"
                                    maxW={'240px'}
                                    borderRadius={'10px !important'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('language', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {resolverLanguageCodeToString(shop.languageCode)}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('zipCode', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="zipCode"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('zipCode', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {shop.zipCode}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('province', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="province"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('province', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {shop.province}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('address', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="address"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('address', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {shop.address}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <HStack justifyContent="flex-start" w="120px">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400">
                                        {t('phoneNumber', { ns: 'common' })}
                                    </Text>
                                </HStack>

                                <RHFTextField
                                    name="phoneNumber"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('phoneNumber', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {shop.phoneNumber}
                                </Text>
                            </HStack>
                        )}
                    </SimpleGrid>
                </Card>
            </FormProvider>
        </ApolloMutationBoundary>
    );
}
