import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Select, useDisclosure } from '@chakra-ui/react';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import { ApolloQueryBoundary } from '../graphql/ApolloQueryBoundary';
import { useTranslation } from 'react-i18next';
import { useGetDealerByIdQuery } from 'graphql/queries/dealer/__generated__/getDealerById.generated';

export type ShopsSelectListByDistributorProp = {
    dealerId: number;
    shopId: number | null;
    setShopId: Dispatch<SetStateAction<number | null>>;
};
export default function ShopsSelectListByDistributor(props: ShopsSelectListByDistributorProp) {
    const { userId, accountType } = useAuthContext();
    const { setShopId, dealerId } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation(['common']);

    const queryShopSelectListByDistributorResult = useGetDealerByIdQuery({
        variables: {
            input: {
                dealerId,
            },
        },
        onError: async () => {
            onOpen();
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    const handleFilterShop = (event: ChangeEvent<HTMLSelectElement>) => {
        const shopIdValue = event.target.value === 'all' ? null : event.target.value;
        setShopId && setShopId(Number(shopIdValue));
    };
    return accountType === AccountType.distributor ? (
        <ApolloQueryBoundary
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            queryResult={queryShopSelectListByDistributorResult}
            render={({
                data: {
                    dealer: { shops },
                },
            }) => (
                <Select
                    fontSize="14px"
                    variant="main"
                    borderRadius="10px"
                    h="40px"
                    maxH="44px"
                    maxW={150}
                    me={{ base: '10px', md: '20px' }}
                    onChange={(e) => handleFilterShop(e)}
                >
                    <option value="all">{t('accountTypeOption4', { ns: 'common' })}</option>
                    {shops.map((shop) => (
                        <option key={shop.id} value={shop.id}>
                            {shop.name}
                        </option>
                    ))}
                </Select>
            )}
        />
    ) : null;
}
