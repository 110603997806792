import { Flex, useColorModeValue } from '@chakra-ui/react';
import { HorizonGranProLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
    const logoColor = useColorModeValue('navy.700', 'white');

    return (
        <Flex alignItems="center" flexDirection="column">
            <HorizonGranProLogo h="26px" w="175px" my="32px" color={logoColor} />
            <HSeparator mb="20px" />
        </Flex>
    );
}

export default SidebarBrand;
