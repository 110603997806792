import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useResetMachineIncomeMutation } from 'graphql/mutations/shop/__generated__/resetMachineIncome.generated';
import { useTranslation } from 'react-i18next';

type FinalResetButtonProps = {
    machineId: number;
    shopIncomeCollectionEventId: string;
    [key: string]: any;
};
export default function FinalResetButton(props: FinalResetButtonProps) {
    const { machineId, shopIncomeCollectionEventId } = props;
    const btnColorScheme = 'navy';
    const { t } = useTranslation(['common', 'machine']);
    const {
        isOpen: isOpenResetMachineIncomeResult,
        onOpen: onOpenResetMachineIncomeResult,
        onClose: onCloseResetMachineIncomeResult,
    } = useDisclosure();
    const [resetMachineIncomeMutationFunc, resetMachineIncomeMutationResult] = useResetMachineIncomeMutation();
    const onHandleResetMachineIncome = async () => {
        try {
            await resetMachineIncomeMutationFunc({
                variables: {
                    input: {
                        machineId,
                        shopIncomeCollectionEventId,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenResetMachineIncomeResult();
                    }
                },
                onError: async () => {
                    onOpenResetMachineIncomeResult();
                },
            });
        } catch (error) {
            console.error(error);
        }
    };
    const onHandleSuccess = () => {
        window.location.reload();
    };
    return (
        <ApolloMutationBoundary
            isOpen={isOpenResetMachineIncomeResult}
            onClose={onCloseResetMachineIncomeResult}
            onOpen={onOpenResetMachineIncomeResult}
            onExtraAfterSuccessHandler={onHandleSuccess}
            mutationResult={resetMachineIncomeMutationResult}
            toastSuccessTitle={t('resetIncomeSuccessfullyTitle', { ns: 'machine' })}
            toastSuccessDescription={t('resetIncomeSuccessfullyDescription', { ns: 'machine' })}
        >
            <Button
                variant="outline"
                minWidth="80%"
                borderWidth={2}
                colorScheme={btnColorScheme}
                onClick={onHandleResetMachineIncome}
            >
                {t('addIncomeBtn', { ns: 'common' })}
            </Button>
        </ApolloMutationBoundary>
    );
}
