import * as React from 'react';
import { Avatar, Badge, Button, Flex, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { useCancelProEntryMutation } from '../../../../../../graphql/mutations/machine/__generated__/cancelProEntry.generated';
import { ApolloMutationBoundary } from '../../../../../../components/graphql/ApolloMutationBoundary';
import { GetMachineByIdDocument } from '../../../../../../graphql/queries/machine/__generated__/getMachineById.generated';

export default function EntryPlayer(props: {
    entryPlayerId: number;
    machineId: number;
    rank: Rank;
    avatar: string;
    name: string;
    isEdit?: boolean;
    [x: string]: any;
}) {
    const { entryPlayerId, machineId, rank, avatar, name, isEdit, ...rest } = props;
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const {
        isOpen: isOpenMutationBoundaryResult,
        onOpen: onOpenMutationBoundaryResult,
        onClose: onCloseMutationBoundaryResult,
    } = useDisclosure();
    const [cancelProEntryFunc, cancelProEntryMutationResult] = useCancelProEntryMutation();

    const onHandleDeleteProEntry = async () => {
        try {
            await cancelProEntryFunc({
                variables: {
                    input: {
                        entryPlayerId,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenMutationBoundaryResult();
                    }
                },
                onError: async () => {
                    onOpenMutationBoundaryResult();
                },
                refetchQueries: () => [
                    {
                        query: GetMachineByIdDocument,
                        variables: {
                            input: {
                                machineId: machineId,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenMutationBoundaryResult}
            onClose={onCloseMutationBoundaryResult}
            onOpen={onOpenMutationBoundaryResult}
            mutationResult={cancelProEntryMutationResult}
            toastSuccessTitle="Cancel Entry Player"
            toastSuccessDescription="Remove Entry Player successfully"
        >
            <Flex justifyContent="center" alignItems="center" w="100%" {...rest}>
                <Avatar h="40px" w="40px" src={avatar} me="14px" />
                <Flex direction="column" align="start" me="auto">
                    <Text color={textColor} fontSize="md" me="6px" fontWeight="700">
                        {name}
                    </Text>
                </Flex>
                {isEdit ? (
                    <Button
                        colorScheme="red"
                        variant="outline"
                        p="15px 40px"
                        fontSize="sm"
                        h="36px"
                        w="140px"
                        borderRadius={10}
                        fontWeight="500"
                        ms="auto"
                        onClick={onHandleDeleteProEntry}
                    >
                        Remove
                    </Button>
                ) : (
                    <Badge
                        textAlign="center"
                        ms="auto"
                        minW="60px"
                        borderRadius="6px"
                        colorScheme={colorSchemeFromRank(rank)}
                        color={colorFromRank(rank)}
                        fontSize="sm"
                    >
                        {rank}
                    </Badge>
                )}
            </Flex>
        </ApolloMutationBoundary>
    );
}

const colorSchemeFromRank = (rank: Rank) => {
    switch (rank) {
        case 'A':
            return 'red';
        case 'AA':
            return 'red';
        case 'B':
            return 'green';
        case 'BB':
            return 'green';
        case 'C':
            return 'blue';
        case 'CC':
            return 'blue';
        case 'S':
            return 'green';
        case 'SS':
            return 'green';
        case 'Master':
            return 'green';
        case 'GranMaster':
            return 'green';
        default:
            return 'green';
    }
};
const colorFromRank = (rank: Rank) => {
    switch (rank) {
        case 'A':
            return 'red.600';
        case 'AA':
            return 'red.600';
        case 'B':
            return 'green.500';
        case 'BB':
            return 'green.500';
        case 'C':
            return 'blue.500';
        case 'CC':
            return 'blue.500';
        case 'S':
            return 'green.500';
        case 'SS':
            return 'green.500';
        case 'Master':
            return 'green.500';
        case 'GranMaster':
            return 'green.500';
        default:
            return 'green.500';
    }
};
