enum RawLangCode {
    de = 'Deutsch',
    en = 'English',
    es = 'Español',
    fr = 'Français',
    ja = '日本語',
    cn = '中文',
}
export const resolverLanguageCodeToString = (langCode: string) => {
    switch (langCode) {
        case 'ja':
            return RawLangCode.ja;
        case 'us':
            return RawLangCode.en;
        default:
            return RawLangCode.en;
    }
};
