import {
    Button,
    Flex,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertIcon } from '../../../../../../components/icons/Icons';

type ConfirmChangeShopDetailModalProps = UseDisclosureProps & {
    onHandleChangeShopState: () => void;
};
export default function ConfirmDisableShopDetailModal(props: ConfirmChangeShopDetailModalProps) {
    const textColor = useColorModeValue('#2B3674', 'white');
    const cancelBgColor = '#A3AED0';
    const disableBgColor = '#D11828';
    const { t } = useTranslation(['common']);
    const { isOpen, onClose, onHandleChangeShopState } = props;
    const handleCloseModal = () => {
        onClose();
    };
    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleCloseModal} size="2xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody w="100%" pt={4} pb={5} minH={'320px'}>
                    <Flex align="center" direction="column" justifyContent="center" w="100%" minH={'320px'} gap={12}>
                        <VStack gap={1}>
                            <Icon as={AlertIcon} w="50px" h="42px" color={'red.550'} mb={2} />
                            <Text
                                color={textColor}
                                fontSize={{ base: '14px', lg: '16px' }}
                                fontWeight="500"
                                lineHeight="100%"
                            >
                                {t('disableShopModalMessage1', { ns: 'common' })}
                            </Text>
                            <Text
                                color={textColor}
                                fontSize={{ base: '14px', lg: '16px' }}
                                fontWeight="500"
                                lineHeight="100%"
                            >
                                {t('disableShopModalMessage2', { ns: 'common' })}
                            </Text>
                        </VStack>

                        <HStack gap={7}>
                            <Button
                                // colorScheme={cancelBgColor}
                                backgroundColor={cancelBgColor}
                                color="white"
                                variant="ghost"
                                fontSize="sm"
                                borderRadius="10px"
                                borderWidth={1}
                                fontWeight="700"
                                ms="auto"
                                minW="140px"
                                onClick={handleCloseModal}
                            >
                                {t('cancelBtn', { ns: 'common' })}
                            </Button>

                            <Button
                                // colorScheme="red"
                                backgroundColor={disableBgColor}
                                color="white"
                                variant="ghost"
                                fontSize="sm"
                                borderRadius="10px"
                                borderWidth={1}
                                fontWeight="700"
                                ms="auto"
                                minW="140px"
                                onClick={onHandleChangeShopState}
                            >
                                {t('changeDisableLabel', { ns: 'common' })}
                            </Button>
                        </HStack>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
