import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelProEntryMutationVariables = Types.Exact<{
    input: Types.CancelProEntryInput;
}>;

export type CancelProEntryMutation = { __typename?: 'Mutation'; cancel_pro_entry: Types.SuccessResponse };

export const CancelProEntryDocument = gql`
    mutation cancelProEntry($input: CancelProEntryInput!) {
        cancel_pro_entry(input: $input)
    }
`;
export type CancelProEntryMutationFn = Apollo.MutationFunction<CancelProEntryMutation, CancelProEntryMutationVariables>;

/**
 * __useCancelProEntryMutation__
 *
 * To run a mutation, you first call `useCancelProEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelProEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelProEntryMutation, { data, loading, error }] = useCancelProEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelProEntryMutation(
    baseOptions?: Apollo.MutationHookOptions<CancelProEntryMutation, CancelProEntryMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelProEntryMutation, CancelProEntryMutationVariables>(CancelProEntryDocument, options);
}
export type CancelProEntryMutationHookResult = ReturnType<typeof useCancelProEntryMutation>;
export type CancelProEntryMutationResult = Apollo.MutationResult<CancelProEntryMutation>;
export type CancelProEntryMutationOptions = Apollo.BaseMutationOptions<
    CancelProEntryMutation,
    CancelProEntryMutationVariables
>;
