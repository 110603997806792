import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMachineListOfShopQueryVariables = Types.Exact<{
    input: Types.GetMachineListOfShopInput;
}>;

export type GetMachineListOfShopQuery = {
    __typename?: 'Query';
    get_machine_list_of_shop: {
        __typename?: 'MachineConnection';
        totalCount: number;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
        edges: Array<{
            __typename?: 'MachineEdge';
            cursor: string;
            node?: {
                __typename?: 'Machine';
                id: number;
                machineName: string;
                inGameStatus: Types.InGameStatus;
                serviceMode: Types.ProKitServiceModeOption;
                lastInformationEditedAt?: Date | null;
                isEmittingShopBeacon: boolean;
                isOnline: boolean;
                status: Types.MachineStatus;
                shop: { __typename?: 'Shop'; name: string; dealer: { __typename?: 'Dealer'; name: string } };
                playerEntries: Array<{ __typename?: 'Player'; id: number; player_name: string } | null>;
                proKit?: {
                    __typename?: 'ProKit';
                    id: number;
                    isActivated: boolean;
                    status: Types.ProKitStatus;
                    subscriptionPlan?: Types.GranProSubscriptionPlan | null;
                } | null;
            } | null;
        } | null>;
    };
};

export const GetMachineListOfShopDocument = gql`
    query getMachineListOfShop($input: GetMachineListOfShopInput!) {
        get_machine_list_of_shop(input: $input) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    machineName
                    shop {
                        name
                        dealer {
                            name
                        }
                    }
                    inGameStatus
                    serviceMode
                    playerEntries {
                        id
                        player_name
                    }
                    lastInformationEditedAt
                    isEmittingShopBeacon
                    isOnline
                    status
                    proKit {
                        id
                        isActivated
                        status
                        subscriptionPlan
                    }
                }
            }
        }
    }
`;

/**
 * __useGetMachineListOfShopQuery__
 *
 * To run a query within a React component, call `useGetMachineListOfShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineListOfShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineListOfShopQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMachineListOfShopQuery(
    baseOptions: Apollo.QueryHookOptions<GetMachineListOfShopQuery, GetMachineListOfShopQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMachineListOfShopQuery, GetMachineListOfShopQueryVariables>(
        GetMachineListOfShopDocument,
        options,
    );
}
export function useGetMachineListOfShopLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetMachineListOfShopQuery, GetMachineListOfShopQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMachineListOfShopQuery, GetMachineListOfShopQueryVariables>(
        GetMachineListOfShopDocument,
        options,
    );
}
export type GetMachineListOfShopQueryHookResult = ReturnType<typeof useGetMachineListOfShopQuery>;
export type GetMachineListOfShopLazyQueryHookResult = ReturnType<typeof useGetMachineListOfShopLazyQuery>;
export type GetMachineListOfShopQueryResult = Apollo.QueryResult<
    GetMachineListOfShopQuery,
    GetMachineListOfShopQueryVariables
>;
