import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyDealerEmailMutationVariables = Types.Exact<{
    input: Types.VerifyDealerEmailInput;
}>;

export type VerifyDealerEmailMutation = {
    __typename?: 'Mutation';
    verify_dealer_email: {
        __typename?: 'DealerWithSession';
        dealer: {
            __typename?: 'Dealer';
            id: number;
            name: string;
            iconUrl?: string | null;
            status: Types.DealerStatus;
            email: string;
            languageCode: string;
            countryCode: string;
            zipCode: string;
            province: string;
            address: string;
            phoneNumber: string;
            lastInformationEditedAt?: Date | null;
            lastInformationEditedBy?: Types.DealerInformationEditor | null;
            shops: Array<{
                __typename?: 'Shop';
                id: number;
                name: string;
                email: string;
                iconUrl?: string | null;
                beaconId?: string | null;
                status: Types.ShopStatus;
                languageCode: string;
                countryCode: string;
                zipCode: string;
                province: string;
                address: string;
                phoneNumber: string;
                lastInformationEditedAt?: Date | null;
                lastInformationEditedBy?: Types.ShopInformationEditor | null;
            }>;
        };
        session: {
            __typename?: 'DealerSession';
            accessToken?: {
                __typename?: 'CognitoAccessToken';
                jwtToken?: string | null;
                payload?: { __typename?: 'CognitoAccessTokenPayload'; exp?: number | null } | null;
            } | null;
        };
    };
};

export const VerifyDealerEmailDocument = gql`
    mutation verifyDealerEmail($input: VerifyDealerEmailInput!) {
        verify_dealer_email(input: $input) {
            dealer {
                id
                name
                iconUrl
                status
                email
                languageCode
                countryCode
                zipCode
                province
                address
                phoneNumber
                lastInformationEditedAt
                lastInformationEditedBy
                shops {
                    id
                    name
                    email
                    iconUrl
                    beaconId
                    status
                    languageCode
                    countryCode
                    zipCode
                    province
                    address
                    phoneNumber
                    lastInformationEditedAt
                    lastInformationEditedBy
                }
            }
            session {
                accessToken {
                    jwtToken
                    payload {
                        exp
                    }
                }
            }
        }
    }
`;
export type VerifyDealerEmailMutationFn = Apollo.MutationFunction<
    VerifyDealerEmailMutation,
    VerifyDealerEmailMutationVariables
>;

/**
 * __useVerifyDealerEmailMutation__
 *
 * To run a mutation, you first call `useVerifyDealerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyDealerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyDealerEmailMutation, { data, loading, error }] = useVerifyDealerEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyDealerEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<VerifyDealerEmailMutation, VerifyDealerEmailMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<VerifyDealerEmailMutation, VerifyDealerEmailMutationVariables>(
        VerifyDealerEmailDocument,
        options,
    );
}
export type VerifyDealerEmailMutationHookResult = ReturnType<typeof useVerifyDealerEmailMutation>;
export type VerifyDealerEmailMutationResult = Apollo.MutationResult<VerifyDealerEmailMutation>;
export type VerifyDealerEmailMutationOptions = Apollo.BaseMutationOptions<
    VerifyDealerEmailMutation,
    VerifyDealerEmailMutationVariables
>;
