import { Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import ShopListByDealer from './components/ShopListByDealer';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import ShopListByDistributor from './components/ShopListByDistributor';

export default function ShopOverview() {
    const { accountType } = useAuthContext();
    return (
        <Box pt={{ base: '40px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '0px', xl: '50px' }}>
            <Card py="20px" px={{ base: '0px', lg: '30px' }}>
                {accountType === AccountType.distributor && <ShopListByDistributor />}
                {accountType === AccountType.dealer && <ShopListByDealer />}
            </Card>
        </Box>
    );
}
