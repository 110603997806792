import {
    Button,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { WarningIcon } from '../../../components/icons/Icons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type SignInErrorModalProps = UseDisclosureProps & {
    onAfterErrorHandler?: () => void;
    errorMessage: string;
};
function SignInErrorModal(props: SignInErrorModalProps) {
    const { isOpen, onClose, onAfterErrorHandler, errorMessage } = props;
    const textPrimaryColor = useColorModeValue('black', 'white');
    const { t } = useTranslation(['common', 'error']);
    const handleOnCloseModal = () => {
        onClose();
        if (onAfterErrorHandler) {
            onAfterErrorHandler();
        }
    };
    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleOnCloseModal} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent style={{ paddingTop: '8px', paddingBottom: '8px', minHeight: '300px' }}>
                <ModalCloseButton />
                <ModalBody display="flex" alignItems="center" justifyContent="center" py={4}>
                    <VStack spacing={5}>
                        <Icon as={WarningIcon} w="80px" h="80px" color="#DCDCDC" />
                        <VStack px={2} spacing={0}>
                            <Text color={textPrimaryColor} fontSize="16px" fontWeight="500" textAlign="center">
                                {signInErrorMessageHandler(errorMessage, t)}
                            </Text>
                        </VStack>
                    </VStack>
                </ModalBody>

                <ModalFooter display="flex" alignItems="center" justifyContent="center">
                    <HStack>
                        <Button
                            // colorScheme="red"
                            variant="solid"
                            fontSize="sm"
                            color={textPrimaryColor}
                            w="86px"
                            // backgroundColor="secondaryGray.300"
                            borderRadius={10}
                            fontWeight="500"
                            ms="auto"
                            onClick={handleOnCloseModal}
                        >
                            {t('closeBtnLabel', { ns: 'common' })}
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
export default SignInErrorModal;

const signInErrorMessageHandler = (message: string | null | undefined, t: TFunction) => {
    if (!message) {
        return t('UserNotFoundError', { ns: 'error' });
    }
    if (message.includes('Incorrect email or password')) {
        return t('NotAuthorizedError', { ns: 'error' });
    }
    if (message.includes('Unchanged password user to login')) {
        return t('UnchangedPasswordLoginError', { ns: 'error' });
    }
    if (message.includes('Could not find distributor corresponding with Cognito from DB')) {
        return t('DistributorNotFoundError', { ns: 'error' });
    }
    if (message.includes('Could not find dealer corresponding with Cognito from DB')) {
        return t('DealerNotFoundError', { ns: 'error' });
    }
    if (message.includes('Could not find shop corresponding with userID from DB')) {
        return t('ShopNotFoundError', { ns: 'error' });
    }
    if (message.includes('Could not find user corresponding with Cognito from DB')) {
        return t('UserNotFoundError', { ns: 'error' });
    }
    if (message.includes('Execution of the service is restricted by')) {
        return t('ApiExecuteProhibitedError', { ns: 'error' });
    }
    return message;
};
