import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateShopIncomeCollectionEventMutationVariables = Types.Exact<{
    input: Types.CreateShopIncomeCollectionEventInput;
}>;

export type CreateShopIncomeCollectionEventMutation = {
    __typename?: 'Mutation';
    create_shop_income_collection_event: {
        __typename?: 'ProShopIncomeCollectionEvent';
        id: string;
        eventName: string;
        automaticallyCollectedTotalCount: number;
        manuallyInputTotalCount: number;
        lastMachineIncomeCollectionEventDate?: Date | null;
    };
};

export const CreateShopIncomeCollectionEventDocument = gql`
    mutation createShopIncomeCollectionEvent($input: CreateShopIncomeCollectionEventInput!) {
        create_shop_income_collection_event(input: $input) {
            id
            eventName
            automaticallyCollectedTotalCount
            manuallyInputTotalCount
            lastMachineIncomeCollectionEventDate
        }
    }
`;
export type CreateShopIncomeCollectionEventMutationFn = Apollo.MutationFunction<
    CreateShopIncomeCollectionEventMutation,
    CreateShopIncomeCollectionEventMutationVariables
>;

/**
 * __useCreateShopIncomeCollectionEventMutation__
 *
 * To run a mutation, you first call `useCreateShopIncomeCollectionEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopIncomeCollectionEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopIncomeCollectionEventMutation, { data, loading, error }] = useCreateShopIncomeCollectionEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShopIncomeCollectionEventMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateShopIncomeCollectionEventMutation,
        CreateShopIncomeCollectionEventMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateShopIncomeCollectionEventMutation,
        CreateShopIncomeCollectionEventMutationVariables
    >(CreateShopIncomeCollectionEventDocument, options);
}
export type CreateShopIncomeCollectionEventMutationHookResult = ReturnType<
    typeof useCreateShopIncomeCollectionEventMutation
>;
export type CreateShopIncomeCollectionEventMutationResult =
    Apollo.MutationResult<CreateShopIncomeCollectionEventMutation>;
export type CreateShopIncomeCollectionEventMutationOptions = Apollo.BaseMutationOptions<
    CreateShopIncomeCollectionEventMutation,
    CreateShopIncomeCollectionEventMutationVariables
>;
