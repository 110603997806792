// noinspection DuplicatedCode

import { ColumnHelper } from '@tanstack/react-table';
import { ShopObj } from '../variables';
import { Avatar, Flex, Link, Text } from '@chakra-ui/react';
import { ShopStatus } from 'types/graphql';
import { ROUTE_PATH_DASHBOARD } from 'paths';
import { resolverShopStatusToStr } from 'utils/resolver-enum';
import { TFunction } from 'i18next';
import NotActivatedStatus from '../../../../../../components/elements/NotActivatedStatus';
import EnableStatus from '../../../../../../components/elements/EnableStatus';
import DisableStatus from '../../../../../../components/elements/DisableStatus';
import OutOfServiceStatus from '../../../../../../components/elements/OutOfServiceStatus';

export const createShopListColumn = (
    isMobile: boolean,
    columnHelper: ColumnHelper<ShopObj>,
    textColor: string,
    t: TFunction,
) =>
    isMobile
        ? [
              columnHelper.accessor('name', {
                  id: 'name',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '16px', lg: '14px' }}
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('shop', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex align="center" minH="70px">
                          <Text color={textColor} fontSize="md" fontWeight="500">
                              <Link color={textColor} href={ROUTE_PATH_DASHBOARD.shop.detail(info.getValue()[2])}>
                                  {info.getValue()[0]}
                              </Link>
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('statusMix', {
                  id: 'statusMix',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '16px', lg: '14px' }}
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('statusOption1', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      if (typeof info.getValue()[1] === 'boolean' && info.getValue()[1] === false) {
                          return <NotActivatedStatus />;
                      } else {
                          return (
                              <>
                                  {info.getValue()[0] === ShopStatus.Enabled && (
                                      <EnableStatus enableTextValue={resolverShopStatusToStr(info.getValue()[0], t)} />
                                  )}
                                  {info.getValue()[0] === ShopStatus.Deleted && (
                                      <DisableStatus
                                          disableTextValue={resolverShopStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                                  {info.getValue()[0] === ShopStatus.OutOfService && (
                                      <OutOfServiceStatus
                                          outOfServiceTextValue={resolverShopStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                              </>
                          );
                      }
                  },
              }),
          ]
        : [
              columnHelper.accessor('name', {
                  id: 'name',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '14px' }}
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('shopName', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex align="center" minW={{ sm: '200px', md: '100px', lg: 'auto' }}>
                          <Avatar src={info.getValue()[1]} h="60px" w="60px" me="10px" />
                          <Text color={textColor} fontSize="md" fontWeight="500">
                              <Link color={textColor} href={ROUTE_PATH_DASHBOARD.shop.detail(info.getValue()[2])}>
                                  {info.getValue()[0]}
                              </Link>
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('province', {
                  id: 'province',
                  header: () => (
                      <Text
                          display={{ sm: 'none', md: 'none', lg: 'flex' }}
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '14px' }}
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('province', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      return (
                          <Text
                              color={textColor}
                              fontSize="md"
                              fontWeight="500"
                              display={{ sm: 'none', md: 'none', lg: 'flex' }}
                          >
                              {info.getValue()}
                          </Text>
                      );
                  },
              }),
              columnHelper.accessor('dealerName', {
                  id: 'dealerName',
                  header: () => (
                      <Text
                          display={{ sm: 'none', md: 'none', lg: 'flex' }}
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '14px' }}
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('dealerName', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text
                          color={textColor}
                          fontSize="md"
                          fontWeight="500"
                          display={{ sm: 'none', md: 'none', lg: 'flex' }}
                      >
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('machine', {
                  id: 'machine',
                  header: () => (
                      <Text
                          display={{ sm: 'none', md: 'flex', lg: 'flex' }}
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '14px' }}
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('machines', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text
                          color={textColor}
                          fontSize="md"
                          fontWeight="500"
                          display={{ sm: 'none', md: 'flex', lg: 'flex' }}
                      >
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('statusMix', {
                  id: 'statusMix',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '14px' }}
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('status', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      if (typeof info.getValue()[1] === 'boolean' && info.getValue()[1] === false) {
                          return <NotActivatedStatus />;
                      } else {
                          return (
                              <>
                                  {info.getValue()[0] === ShopStatus.Enabled && (
                                      <EnableStatus enableTextValue={resolverShopStatusToStr(info.getValue()[0], t)} />
                                  )}
                                  {info.getValue()[0] === ShopStatus.Deleted && (
                                      <DisableStatus
                                          disableTextValue={resolverShopStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                                  {info.getValue()[0] === ShopStatus.OutOfService && (
                                      <OutOfServiceStatus
                                          outOfServiceTextValue={resolverShopStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                              </>
                          );
                      }
                  },
              }),
          ];
