import { memo } from 'react';
import { ApolloError } from '@apollo/client';
import { useToastOnce } from '../hooks/useToastOnce';
import {
    Button,
    Center,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import { WarningIcon } from '../icons/Icons';
import { useTranslation } from 'react-i18next';
import { errorMessageHandler } from './ErrorMessageHandler';

export const QueryErrorLoaded = memo((error: ApolloError) => {
    useToastOnce({
        title: 'Something went wrong',
        // description: JSON.stringify(error),
        description: error.message,
        position: 'top-right',
        status: 'error',
        duration: 9000,
        variant: 'solid',
        isClosable: true,
    });

    return (
        <Center my={'24px'}>
            <VStack spacing={'24px'}>
                <div>Something went wrong please try refresh screen</div>
                <Button variant={'gray'} onClick={() => window.location.reload()}>
                    Refresh screen
                </Button>
            </VStack>
        </Center>
    );
});

QueryErrorLoaded.displayName = 'QueryErrorLoaded';

export const QueryErrorModalLoaded = memo((props: ApolloError & UseDisclosureProps) => {
    const textPrimaryColor = useColorModeValue('black', 'white');
    const { isOpen, onClose } = props;
    const { t } = useTranslation(['common', 'error']);

    const errorCode =
        props.graphQLErrors && props.graphQLErrors.length > 0 ? (props.graphQLErrors[0].extensions.code as any) : null;
    const errorMessage = errorMessageHandler(props.message, errorCode, t);

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent style={{ paddingTop: '2px', paddingBottom: '8px', minHeight: '320px' }}>
                <ModalHeader>Error</ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" alignItems="center" justifyContent="center" py={4}>
                    <VStack spacing={0}>
                        <Icon as={WarningIcon} w="70px" h="70px" color="#DCDCDC" />
                        <VStack spacing={0} align="center">
                            <Text textAlign="center" color={textPrimaryColor} fontSize="16px" fontWeight="500">
                                {errorMessage}
                            </Text>
                        </VStack>
                    </VStack>
                </ModalBody>

                <ModalFooter display="flex" alignItems="center" justifyContent="center">
                    <HStack>
                        <Button
                            variant="solid"
                            fontSize="lg"
                            color={textPrimaryColor}
                            w="86px"
                            borderRadius={10}
                            fontWeight="900"
                            ms="auto"
                            onClick={onClose}
                        >
                            {t('closeBtnLabel', { ns: 'common' })}
                        </Button>
                        <Button
                            variant="solid"
                            fontSize="lg"
                            color={textPrimaryColor}
                            w="86px"
                            borderRadius={10}
                            fontWeight="900"
                            ms="auto"
                            onClick={() => window.location.reload()}
                        >
                            {t('refreshBtnLabel', { ns: 'common' })}
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
});

QueryErrorModalLoaded.displayName = 'QueryErrorModalLoaded';
