import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyDealerPasswordResetMutationVariables = Types.Exact<{
    input: Types.VerifyDealerPasswordResetInput;
}>;

export type VerifyDealerPasswordResetMutation = {
    __typename?: 'Mutation';
    verify_dealer_password_reset: Types.SuccessResponse;
};

export const VerifyDealerPasswordResetDocument = gql`
    mutation verifyDealerPasswordReset($input: VerifyDealerPasswordResetInput!) {
        verify_dealer_password_reset(input: $input)
    }
`;
export type VerifyDealerPasswordResetMutationFn = Apollo.MutationFunction<
    VerifyDealerPasswordResetMutation,
    VerifyDealerPasswordResetMutationVariables
>;

/**
 * __useVerifyDealerPasswordResetMutation__
 *
 * To run a mutation, you first call `useVerifyDealerPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyDealerPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyDealerPasswordResetMutation, { data, loading, error }] = useVerifyDealerPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyDealerPasswordResetMutation(
    baseOptions?: Apollo.MutationHookOptions<
        VerifyDealerPasswordResetMutation,
        VerifyDealerPasswordResetMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<VerifyDealerPasswordResetMutation, VerifyDealerPasswordResetMutationVariables>(
        VerifyDealerPasswordResetDocument,
        options,
    );
}
export type VerifyDealerPasswordResetMutationHookResult = ReturnType<typeof useVerifyDealerPasswordResetMutation>;
export type VerifyDealerPasswordResetMutationResult = Apollo.MutationResult<VerifyDealerPasswordResetMutation>;
export type VerifyDealerPasswordResetMutationOptions = Apollo.BaseMutationOptions<
    VerifyDealerPasswordResetMutation,
    VerifyDealerPasswordResetMutationVariables
>;
