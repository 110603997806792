import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import ShopUserAccountSetting from './components/ShopUserAccountSetting';
import DistributorUserAccountSetting from './components/DistributorUserAccountSetting';
import DealerUserAccountSetting from './components/DealerUserAccountSetting';

export default function AccountSetting() {
    const { accountType } = useAuthContext();
    return (
        <Box pt={{ base: '40px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '50px', xl: '50px' }}>
            <SimpleGrid mb="20px" columns={{ sm: 1, md: 1, lg: 2 }} spacing={{ base: '20px', xl: '20px' }}>
                {accountType === AccountType.shop && <ShopUserAccountSetting />}
                {accountType === AccountType.dealer && <DealerUserAccountSetting />}
                {accountType === AccountType.distributor && <DistributorUserAccountSetting />}
                <Flex direction="column"></Flex>
            </SimpleGrid>
        </Box>
    );
}
