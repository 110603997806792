import { Box, Button, Center, Flex, SimpleGrid, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { isIntegerNumeric } from 'utils/format-number';
import { PATH_DASHBOARD } from 'paths';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from '../../../../../types/user';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import Profile from './components/Profile';
import Information from './components/Information';
import DealerSignInCard from './components/DealerSignInCard';
import ShopDetailCard from './components/ShopDetailCard';
import MachineDetailCard from './components/MachineDetailCard';
import DealerControlCard from './components/DealerControlCard';
import { useGetDealerByIdQuery } from 'graphql/queries/dealer/__generated__/getDealerById.generated';
import { memo, useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { DealerType } from '../../../../../types/graphql';
import { KEY } from '../../../../../constant/key';
import localStorageAvailable from '../../../../../utils/localStorageAvailable';
import PermissionDenied from '../../../../../components/elements/PermissionDenied';

type DealerDetailParams = {
    id: string;
};

export default function DealerDetail() {
    const { id } = useParams<DealerDetailParams>();
    const { accountType, userId } = useAuthContext();
    const history = useHistory();
    const storageAvailable = localStorageAvailable();
    const dealerType = useMemo(() => {
        return storageAvailable ? (localStorage.getItem(KEY.dealerType) as DealerType) : null;
    }, []);
    if (!isIntegerNumeric(id)) {
        history.push(PATH_DASHBOARD.dealer.overview);
    }
    const queryDealerDetailByIdResult = useGetDealerByIdQuery({
        variables: {
            input: {
                dealerId: Number(id),
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    if (
        !(
            (accountType === AccountType.dealer && dealerType === DealerType.Partner) ||
            accountType === AccountType.distributor
        )
    ) {
        return (
            <Box pt={{ base: '40px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '50px', xl: '50px' }}>
                <PermissionDenied />
            </Box>
        );
    } else {
        return (
            <Box pt={{ base: '40px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '50px', xl: '50px' }}>
                {((accountType === AccountType.dealer && dealerType === DealerType.Partner) ||
                    accountType === AccountType.distributor) && (
                    <ApolloQueryBoundary
                        queryResult={queryDealerDetailByIdResult}
                        errorHandler={({
                            clientErrors,
                            extraInfo,
                            message,
                            graphQLErrors,
                            protocolErrors,
                            networkError,
                            name,
                        }) => {
                            return (
                                <QueryErrorDealerDetailLoaded
                                    clientErrors={clientErrors}
                                    extraInfo={extraInfo}
                                    message={message}
                                    graphQLErrors={graphQLErrors}
                                    networkError={networkError}
                                    protocolErrors={protocolErrors}
                                    name={name}
                                />
                            );
                        }}
                        render={({ data: { dealer } }) => {
                            if (
                                (accountType === AccountType.dealer &&
                                    dealerType === DealerType.Partner &&
                                    userId === dealer.partnerDealer?.id) ||
                                (accountType === AccountType.distributor && userId === dealer.distributor?.id)
                            ) {
                                return (
                                    <SimpleGrid
                                        mb="20px"
                                        columns={{ sm: 1, md: 1, lg: 2 }}
                                        spacing={{ base: '20px', xl: '20px' }}
                                    >
                                        <Flex direction="column" gap={5}>
                                            <Profile dealer={dealer} />
                                            <Information dealer={dealer} />
                                            {accountType === AccountType.distributor && (
                                                <DealerSignInCard dealer={dealer} />
                                            )}
                                            {accountType === AccountType.distributor && (
                                                <DealerControlCard dealer={dealer} />
                                            )}
                                        </Flex>
                                        <Flex direction="column" gap={5}>
                                            <ShopDetailCard dealer={dealer} />
                                            <MachineDetailCard dealer={dealer} />
                                        </Flex>
                                    </SimpleGrid>
                                );
                            } else {
                                return <PermissionDenied />;
                            }
                        }}
                    />
                )}
            </Box>
        );
    }
}

const QueryErrorDealerDetailLoaded = memo((error: ApolloError) => {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { t } = useTranslation(['common', 'dealer']);
    return (
        <Center my={'24px'}>
            <VStack spacing={'24px'}>
                {error.message.includes('Could not find dealer with') ? (
                    <Text fontSize="20px" lineHeight={'24px'} color={textColorPrimary} fontWeight="bold">
                        {t('dealerLoadedErrorMessage', { ns: 'dealer' })}
                    </Text>
                ) : (
                    <>
                        <div>{t('queryLoadErrorMessage', { ns: 'common' })}</div>
                        <Button variant={'gray'} onClick={() => window.location.reload()}>
                            {t('queryLoadErrorBtnLabel', { ns: 'common' })}
                        </Button>
                    </>
                )}
            </VStack>
        </Center>
    );
});

QueryErrorDealerDetailLoaded.displayName = 'QueryErrorDealerDetailLoaded';
