import {
    Button,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { addSpecificDays, fDateWithSlash } from '../../../../../../utils/format-time';
import { currentMonth, currentYear } from '../../../../../../utils/resolver-enum';

type DownGradeProkitConfirmModalProps = UseDisclosureProps & {
    validFrom: Date | string | number | null | undefined;
};
export default function DownGradeProkitSuccessModal(props: DownGradeProkitConfirmModalProps) {
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    const { isOpen, onClose, validFrom } = props;
    const startOfNextMonth = validFrom
        ? new Date(validFrom)
        : new Date(`${fDateWithSlash(`${currentYear()}/${Number(currentMonth()) + 1}/01`)}`);
    const closeModalHandler = () => {
        onClose();
    };
    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModalHandler} size="2xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody w="100%" pt={4} pb={1} minH={'200px'}>
                    <Flex align="center" direction="column" justifyContent="center" w="100%" minH={'200px'} gap={12}>
                        <VStack gap={4}>
                            <Text
                                color={textColor}
                                fontSize={{ base: '12px', lg: '14px' }}
                                fontWeight="500"
                                lineHeight="100%"
                            >
                                {`${fDateWithSlash(startOfNextMonth)}からBasicプランに切り替わります。`}
                            </Text>
                            <Text
                                color={textColor}
                                fontSize={{ base: '12px', lg: '14px' }}
                                fontWeight="500"
                                lineHeight="100%"
                            >
                                {`${fDateWithSlash(
                                    addSpecificDays(startOfNextMonth, -1),
                                )} まではEnterpriseプランをご利用いただけます。`}
                            </Text>
                        </VStack>
                    </Flex>
                </ModalBody>
                <ModalFooter display="flex" alignItems="center" justifyContent="center" pb={8}>
                    <HStack>
                        <Button
                            variant="solid"
                            fontSize="sm"
                            color="white"
                            minW="140px"
                            backgroundColor="#A3AED0"
                            borderRadius={10}
                            fontWeight="500"
                            onClick={closeModalHandler}
                        >
                            {t('closeBtnLabel', { ns: 'common' })}
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
