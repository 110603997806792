import { useState } from 'react';

export const useEditable = (defaultChecked?: boolean) => {
    const [isEdit, setEditable] = useState(defaultChecked || false);

    return {
        isEdit,
        onChangeEditable: () => setEditable(!isEdit),
        onEditable: () => setEditable(true),
        onEditDisable: () => setEditable(false),
        setEditable: setEditable,
    };
};
