import { GameSettingObj } from './GameSettingTable';
import {
    defaultAiGameSettingValue,
    defaultCricketGameSettingValue,
    defaultPartyGameSettingValue,
    defaultPracticeGameSettingValue,
    defaultZeroOneGameSettingValue,
} from '../variables';

export function isArrayOfGameSettingObj(parsedData: any): boolean {
    if (Array.isArray(parsedData)) {
        return parsedData.every((item) => {
            return (
                typeof item === 'object' && item !== null && 'gameName' in item && 'round' in item && 'credit' in item
            );
        });
    }
    return false;
}

const checkAndPushGame = (result: GameSettingObj[], gameObj: GameSettingObj, gameName: number, gameType: Game) => {
    if (gameObj.gameName === gameName) {
        result.push({ ...gameObj, gameType: gameType });
    }
};
export const getZeroOneSettingFromParsedData = (data: GameSettingObj[]): GameSettingObj[] => {
    const result: GameSettingObj[] = [];
    data.forEach((gameObj) => {
        defaultZeroOneGameSettingValue.forEach((defaultZero) => {
            checkAndPushGame(result, gameObj, defaultZero.gameName, defaultZero.gameType);
        });
    });
    return result;
};

export const getCricketSettingFromParsedData = (data: GameSettingObj[]): GameSettingObj[] => {
    const result: GameSettingObj[] = [];
    data.forEach((gameObj) => {
        defaultCricketGameSettingValue.forEach((defaultZero) => {
            checkAndPushGame(result, gameObj, defaultZero.gameName, defaultZero.gameType);
        });
    });
    return result;
};

export const getPracticeSettingFromParsedData = (data: GameSettingObj[]): GameSettingObj[] => {
    const result: GameSettingObj[] = [];
    data.forEach((gameObj) => {
        defaultPracticeGameSettingValue.forEach((defaultZero) => {
            checkAndPushGame(result, gameObj, defaultZero.gameName, defaultZero.gameType);
        });
    });
    return result;
};

export const getAiSettingFromParsedData = (data: GameSettingObj[]): GameSettingObj[] => {
    const result: GameSettingObj[] = [];
    data.forEach((gameObj) => {
        defaultAiGameSettingValue.forEach((defaultZero) => {
            checkAndPushGame(result, gameObj, defaultZero.gameName, defaultZero.gameType);
        });
    });
    return result;
};

export const getPartySettingFromParsedData = (data: GameSettingObj[]): GameSettingObj[] => {
    const result: GameSettingObj[] = [];
    data.forEach((gameObj) => {
        defaultPartyGameSettingValue.forEach((defaultZero) => {
            checkAndPushGame(result, gameObj, defaultZero.gameName, defaultZero.gameType);
        });
    });
    return result;
};

export const transferGameSettingObjToOriginalJsonSetting = (input: GameSettingObj[]) => {
    return input.map((value) => {
        const { gameName, credit, round } = value;
        return { gameName, credit, round };
    });
};

export const resolverMaxRound = (gameSettingValue: string | null | undefined) => {
    switch (gameSettingValue) {
        case 'practiceGameSettingValue.0':
            return 8;
        case 'practiceGameSettingValue.1':
            return 8;
        case 'practiceGameSettingValue.2':
            return 9;
        case 'practiceGameSettingValue.3':
            return 8;
        case 'practiceGameSettingValue.4':
            return 16;
        case 'practiceGameSettingValue.5':
            return 10;
        case 'practiceGameSettingValue.6':
            return 15;
        case 'practiceGameSettingValue.7':
            return 12;
        case 'practiceGameSettingValue.8':
            return 8;
        case 'practiceGameSettingValue.9':
            return 10;
        case 'practiceGameSettingValue.10':
            return 8;
        case 'practiceGameSettingValue.11':
            return 10;
        // Party game setting
        case 'partyGameSettingValue.0':
            return 10;
        case 'partyGameSettingValue.1':
            return 8;
        case 'partyGameSettingValue.2':
            return 10;
        case 'partyGameSettingValue.3':
            return 10;
        case 'partyGameSettingValue.4':
            return 10;
        case 'partyGameSettingValue.5':
            return 10;
        case 'partyGameSettingValue.6':
            return 12;
        case 'partyGameSettingValue.7':
            return 15;
        default:
            return 20;
    }
};

export const resolverMinRound = (gameSettingValue: string | null | undefined) => {
    switch (gameSettingValue) {
        // Zero game setting
        case 'zeroOneGameSettingValue.0':
            return 7;
        case 'zeroOneGameSettingValue.1':
            return 10;
        case 'zeroOneGameSettingValue.2':
            return 10;
        case 'zeroOneGameSettingValue.3':
            return 10;
        case 'zeroOneGameSettingValue.4':
            return 10;
        case 'zeroOneGameSettingValue.5':
            return 10;
        case 'zeroOneGameSettingValue.6':
            return 10;
        case 'zeroOneGameSettingValue.7':
            return 10;
        case 'zeroOneGameSettingValue.8':
            return 10;
        case 'zeroOneGameSettingValue.9':
            return 10;
        case 'zeroOneGameSettingValue.10':
            return 10;
        case 'zeroOneGameSettingValue.11':
            return 10;
        // cricket game setting
        case 'cricketGameSettingValue.0':
            return 10;
        case 'cricketGameSettingValue.1':
            return 10;
        case 'cricketGameSettingValue.2':
            return 10;
        case 'cricketGameSettingValue.3':
            return 10;
        case 'cricketGameSettingValue.4':
            return 10;
        case 'cricketGameSettingValue.5':
            return 10;
        // practice game setting
        case 'practiceGameSettingValue.0':
            return 8;
        case 'practiceGameSettingValue.1':
            return 8;
        case 'practiceGameSettingValue.2':
            return 9;
        case 'practiceGameSettingValue.3':
            return 8;
        case 'practiceGameSettingValue.4':
            return 16;
        case 'practiceGameSettingValue.5':
            return 10;
        case 'practiceGameSettingValue.6':
            return 15;
        case 'practiceGameSettingValue.7':
            return 12;
        case 'practiceGameSettingValue.8':
            return 8;
        case 'practiceGameSettingValue.9':
            return 10;
        case 'practiceGameSettingValue.10':
            return 8;
        case 'practiceGameSettingValue.11':
            return 10;
        // AI game setting
        case 'aiGameSettingValue.0':
            return 15;
        case 'aiGameSettingValue.1':
            return 15;
        case 'aiGameSettingValue.2':
            return 15;
        case 'aiGameSettingValue.3':
            return 15;
        case 'aiGameSettingValue.4':
            return 15;
        case 'aiGameSettingValue.5':
            return 15;
        case 'aiGameSettingValue.6':
            return 15;
        // Party game setting
        case 'partyGameSettingValue.0':
            return 10;
        case 'partyGameSettingValue.1':
            return 8;
        case 'partyGameSettingValue.2':
            return 10;
        case 'partyGameSettingValue.3':
            return 10;
        case 'partyGameSettingValue.4':
            return 10;
        case 'partyGameSettingValue.5':
            return 10;
        case 'partyGameSettingValue.6':
            return 12;
        case 'partyGameSettingValue.7':
            return 15;
        default:
            return 3;
    }
};
