// Chakra imports
import { Avatar, Box, Flex, Grid, GridItem, Text, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { DealerDetailProps } from './dealerDetailTypes';
import { DefaultAvatarSrc } from 'constant/defaultValues';
import { ShopStatus } from 'types/graphql';
import { useTranslation } from 'react-i18next';
import ShopStatusElement from '../../../../../../components/elements/ShopStatusElement';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import MachineStatusElement from '../../../../../../components/elements/MachineStatusElement';

export default function ShopDetailCard(
    props: DealerDetailProps & {
        [x: string]: any;
    },
) {
    const { dealer, ...rest } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { t } = useTranslation(['common']);
    // Chakra Color Mode
    return (
        <Card py={8} px={{ base: '20px', lg: '30px', xl: '40px', '2xl': '50px' }} borderRadius="30px" gap={6} {...rest}>
            <Flex direction="row" justifyContent="space-between" alignItems="center" w={'100%'}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                    {t('shop', { ns: 'common' })}
                </Text>
            </Flex>

            <Flex direction="column" align="center" w="100%" justifyContent="space-between">
                {dealer.shops.length < 1 && (
                    <Box w="100%">
                        <Text color={textColorPrimary} fontSize={{ base: '14px', xl: '16px' }} fontWeight="500">
                            {t('noShopLabel', { ns: 'common' })}
                        </Text>
                    </Box>
                )}
                {dealer.shops.length > 0 && (
                    <Scrollbars style={{ height: '35vh' }}>
                        {dealer.shops.map((shop, idx) => (
                            <ShopDetailRow
                                key={idx}
                                iconURL={shop.iconUrl}
                                name={shop.name}
                                status={shop.status}
                                isActivated={shop.isActivated}
                                noOfEntry={1}
                                province={shop.province}
                            />
                        ))}
                    </Scrollbars>
                )}
            </Flex>
        </Card>
    );
}

type ShopDetailRowProp = {
    name: string;
    iconURL?: string;
    status: ShopStatus;
    isActivated: boolean;
    noOfEntry: number;
    province: string;
    [key: string]: any;
};

function ShopDetailRow(props: ShopDetailRowProp) {
    const { name, noOfEntry, status, iconURL, isActivated, province, ...rest } = props;
    const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
    const textColorPrimary = useColorModeValue('navy.650', 'white');
    return (
        <Grid templateColumns="repeat(6, 1fr)" py={2}>
            <GridItem w="100%" colSpan={!isSmallScreen ? 3 : 5}>
                <Flex align="center" justify="flex-start">
                    <Avatar src={iconURL ?? DefaultAvatarSrc} h="40px" w="40px" me="10px" />
                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                        {name}
                    </Text>
                </Flex>
            </GridItem>
            {!isSmallScreen && (
                <GridItem w="100%" colSpan={2}>
                    <Flex align="center" minH={10}>
                        <Text color={textColorPrimary} fontWeight="500" fontSize={{ base: '14px', xl: '16px' }}>
                            {province}
                        </Text>
                    </Flex>
                </GridItem>
            )}

            <GridItem w="100%">
                <Flex align="center" minH={10} justifyContent="center">
                    <Text color={textColorPrimary} fontWeight="500" fontSize={{ base: '14px', xl: '16px' }}>
                        {noOfEntry}
                    </Text>
                </Flex>
            </GridItem>
        </Grid>
    );
}
