import { memo } from 'react';
import {
    Button,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import { IoCheckmarkDoneCircleSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

export const DefaultApolloMutationSuccess = memo(
    (props: UseDisclosureProps & { title: string; description: string; onAfterSuccessHandler?: () => void }) => {
        const { isOpen, onClose, title, description, onAfterSuccessHandler } = props;
        const textPrimaryColor = useColorModeValue('black', 'white');
        const { t } = useTranslation(['common']);

        const handleOnClickClose = () => {
            onClose();
            if (onAfterSuccessHandler) {
                onAfterSuccessHandler();
            }
        };

        return (
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleOnClickClose} size="xl" isCentered>
                <ModalOverlay />
                <ModalContent style={{ paddingTop: '4px', paddingBottom: '8px', minHeight: '300px' }}>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody display="flex" alignItems="center" justifyContent="center" py={4}>
                        <VStack spacing={5}>
                            <Icon as={IoCheckmarkDoneCircleSharp} w="80px" h="80px" color="green.500" />
                            <VStack px={2} spacing={0}>
                                <Text color={textPrimaryColor} fontSize="16px" fontWeight="500" textAlign="center">
                                    {description}
                                </Text>
                            </VStack>
                        </VStack>
                    </ModalBody>

                    <ModalFooter display="flex" alignItems="center" justifyContent="flex-end">
                        <HStack>
                            <Button
                                // colorScheme="gray.700"
                                variant="ghost"
                                fontSize="lg"
                                color={textPrimaryColor}
                                w="86px"
                                // backgroundColor="secondaryGray.300"
                                borderRadius={10}
                                fontWeight="900"
                                ms="auto"
                                onClick={handleOnClickClose}
                            >
                                {t('closeBtnLabel', { ns: 'common' })}
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    },
);

DefaultApolloMutationSuccess.displayName = 'DefaultApolloMutationSuccess';
