// noinspection DuplicatedCode

import { Avatar, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ShopDetailProps } from '../shopDetailTypes';
import { DefaultAvatarSrc } from 'constant/defaultValues';

export default function ShopProfile(props: ShopDetailProps & { [x: string]: any }) {
    const { shop, ...rest } = props;
    const borderColor = useColorModeValue('white !important', '#0b1437 !important');
    // const { t } = useTranslation(['common']);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    return (
        <Card py={8} px={{ base: '20px', lg: '30px', '2xl': '30px' }} {...rest}>
            <Flex justifyContent="center" alignItems="center" w="100%">
                <Flex direction="row" justifyContent="center" alignItems="center">
                    <Avatar
                        src={shop.iconUrl ?? DefaultAvatarSrc}
                        h={{ base: '65px', md: '87px' }}
                        w={{ base: '65px', md: '87px' }}
                        border="4px solid"
                        borderColor={borderColor}
                    />
                    <Text
                        color={textColor}
                        fontSize={{ base: '16px', lg: '24px' }}
                        fontWeight="700"
                        lineHeight="100%"
                        textAlign="center"
                        ml="10px"
                    >
                        {shop.name}
                    </Text>
                </Flex>
            </Flex>
        </Card>
    );
}
