export enum AccountType {
    shop,
    dealer,
    distributor,
}

export type ICurrentUser = {
    userId: string;
    name: string;
    email: string;
    accountType: AccountType;
};

export type IUserLogin = {
    name: string;
    email: string;
    accountType: AccountType;
};
