import { Flex, useDisclosure } from '@chakra-ui/react';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { useGetDistributorByIdQuery } from 'graphql/queries/distributor/__generated__/getDistributorById.generated';
import AvatarProfile from './AvatarProfile';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import DistributorAccountInfoCard from './distributor/DistributorAccountInfoCard';

export default function DistributorUserAccountSetting() {
    const { userId } = useAuthContext();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const getDistributorByIdQueryResult = useGetDistributorByIdQuery({
        variables: {
            input: {
                distributorId: userId,
            },
        },
        onError: async () => {
            onOpen();
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });
    return (
        <ApolloQueryBoundary
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            queryResult={getDistributorByIdQueryResult}
            render={({ data: { distributor } }) => (
                <Flex direction="column">
                    <AvatarProfile userName={distributor.name} />
                    <DistributorAccountInfoCard distributor={distributor} mb="20px" />
                    {/*<DistributorSignInCard mb="20px" />*/}
                </Flex>
            )}
        />
    );
}
