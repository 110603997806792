// noinspection DuplicatedCode

import * as Yup from 'yup';
import { Button, Flex, HStack, Icon, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { MdModeEdit } from 'react-icons/md';
import { useCallback, useMemo, useState } from 'react';
import { PickValueOf } from 'types/common';
import { useEditShopInformationMutation } from 'graphql/mutations/dealer/__generated__/editShopInformation.generated';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'components/hook-form';
import { RHFCountrySelectWithoutLabel } from 'components/hook-form/RHFCountrySelectWithoutLabel';
import { RHFLanguageSelectWithoutLabel } from 'components/hook-form/RHFLanguageSelectWithoutLabel';
import { resolverLanguageCodeToString } from 'utils/formatEnumTypes';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import RHFUploadAvatar from 'components/hook-form/RHFUploadAvatar';
import { CustomFile } from 'components/upload/types';
import { GetDistributorByIdQuery } from 'graphql/queries/distributor/__generated__/getDistributorById.generated';
import { useTranslation } from 'react-i18next';

type DistributorAccountInfoUpdateProps = {
    distributorName: string;
    countryCode: string;
    languageCode: string;
    zipCode: string;
    province: string;
    address: string;
    phoneNumber: string;
    avatarUrl: CustomFile | string | null;
};

interface FormValuesProps extends Omit<DistributorAccountInfoUpdateProps, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type DistributorAccountInfoCardProps = {
    distributor: PickValueOf<GetDistributorByIdQuery, 'distributor'>;
    [key: string]: any;
};

export default function DistributorAccountInfoCard(props: DistributorAccountInfoCardProps) {
    const { accType, distributor, ...rest } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const textColor = useColorModeValue('#4318FF', 'white');
    const [isEdit, setIsEdit] = useState(false);
    const { t } = useTranslation(['common']);

    // TODO: update mutation edit distributor info once API exist
    const [updateShopAccInformationMutationFunc, editShopInformationMutationResult] = useEditShopInformationMutation();

    const DistributorAccountUpdateSchema = Yup.object().shape({
        distributorName: Yup.string().required('Distributor Name is required'),
        countryCode: Yup.string().required('Country is required'),
        languageCode: Yup.string().required('Language is required'),
        zipCode: Yup.string().required('Zip Code is required'),
        province: Yup.string().required('Province is required'),
        address: Yup.string().required('Address is required'),
        phoneNumber: Yup.string().required('Phone Number is required'),
    });

    const defaultValues = useMemo(
        () => ({
            distributorName: distributor.name,
            countryCode: distributor.countryCode,
            languageCode: distributor.languageCode,
            zipCode: distributor.zipCode,
            province: distributor.province,
            address: distributor.address,
            phoneNumber: distributor.phoneNumber,
            // avatarUrl: distributor.iconUrl || null,
            avatarUrl: null,
        }),
        [distributor],
    );

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(DistributorAccountUpdateSchema) as any,
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        reset,
        setValue,
    } = methods;

    const onClickChangeEditable = () => {
        setIsEdit(!isEdit);
    };

    const onHandleCancel = () => {
        setIsEdit(!isEdit);
        reset();
    };

    const onHandleDeletePhoto = () => {
        setValue('avatarUrl', null, { shouldValidate: true });
    };
    const onHandleUpdateDistributorAccount = async (data: DistributorAccountInfoUpdateProps) => {
        try {
            // TODO: handle update distributor HERE
            // await updateShopAccInformationMutationFunc({
            //     variables: {
            //         input: {
            //             shopId: userId,
            //             name: data.distributorName === distributor.name ? undefined : data.distributorName,
            //             countryCode: data.countryCode === distributor.countryCode ? undefined : data.countryCode,
            //             languageCode: data.languageCode === distributor.languageCode ? undefined : data.languageCode,
            //             zipCode: data.zipCode === distributor.zipCode ? undefined : data.zipCode,
            //             province: data.province === distributor.province ? undefined : data.province,
            //             address: data.address === distributor.address ? undefined : data.address,
            //             phoneNumber: data.phoneNumber === distributor.phoneNumber ? undefined : data.phoneNumber,
            //             iconImage: typeof data.avatarUrl === 'string' ? undefined : data.avatarUrl,
            //         },
            //     },
            //     refetchQueries: () => [
            //         {
            //             query: GetShopByIdDocument,
            //             variables: {
            //                 input: {
            //                     shopId: userId,
            //                 },
            //             },
            //         },
            //     ],
            // });
            setIsEdit(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDropDistributorAccAvatar = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('avatarUrl', newFile, { shouldValidate: true });
            }
        },
        [setValue],
    );
    return (
        <ApolloMutationBoundary
            mutationResult={editShopInformationMutationResult}
            toastSuccessTitle="Account updated"
            toastSuccessDescription="Your account info has been updated successfully"
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onHandleUpdateDistributorAccount)}>
                <Card py="20px" px={{ base: '20px', xl: '50px' }} {...rest}>
                    <Flex direction={'row'} justify="space-between" align="center">
                        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
                            {t('distributorInfoLabel', { ns: 'common' })}
                        </Text>
                        {isEdit ? (
                            <Flex
                                direction={'row'}
                                w={{ base: '45%', '2xl': '25%' }}
                                mt={1}
                                justifyContent="flex-end"
                                alignSelf="flex-end"
                            >
                                <Button
                                    p="0px"
                                    ms="auto"
                                    type="submit"
                                    variant="brand"
                                    fontWeight="500"
                                    minW={'70px'}
                                    maxH={35}
                                    borderRadius="10"
                                    mt={1}
                                    backgroundColor="#4318FF"
                                    isLoading={isSubmitting}
                                >
                                    <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                                </Button>
                                <Button
                                    p="0px"
                                    ms="auto"
                                    onClick={onHandleCancel}
                                    variant="no-hover"
                                    bg="transparent"
                                    mt={1}
                                >
                                    <Text fontSize="16px" color={textColorSecondary} fontWeight="bold">
                                        {t('cancelBtn', { ns: 'common' })}
                                    </Text>
                                </Button>
                            </Flex>
                        ) : (
                            <Button
                                p="0px"
                                ms="auto"
                                disabled
                                // disabled={!(accountType === AccountType.distributor)}
                                onClick={onClickChangeEditable}
                                variant="no-hover"
                                bg="transparent"
                                mt={1}
                            >
                                <Icon as={MdModeEdit} w="16px" h="16px" color={textColor} mr={1} />
                                <Text fontSize="16px" color={textColor} fontWeight="bold">
                                    {t('editLabel', { ns: 'common' })}
                                </Text>
                            </Button>
                        )}
                    </Flex>

                    {isEdit && (
                        <Flex w={'100%'} direction="row" alignItems="center" mb={1}>
                            <Flex direction="column" mr={5}>
                                <RHFUploadAvatar name="avatarUrl" onDrop={handleDropDistributorAccAvatar} />
                            </Flex>
                            <Flex direction="column" mr={5}>
                                <Button
                                    colorScheme="red"
                                    variant="outline"
                                    p="15px 40px"
                                    fontSize="sm"
                                    h="40px"
                                    w="120px"
                                    onClick={onHandleDeletePhoto}
                                    borderRadius={10}
                                    fontWeight="500"
                                    ms="auto"
                                >
                                    {t('deletePhotoLabel', { ns: 'common' })}
                                </Button>
                            </Flex>
                        </Flex>
                    )}

                    <SimpleGrid
                        columns={{ sm: 1 }}
                        spacing={{ base: '6px', xl: '6px' }}
                        mt={{ base: '20px', xl: '20px' }}
                    >
                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text
                                    color={textColorSecondary}
                                    fontSize="14px"
                                    fontWeight="400"
                                    mr={1}
                                    alignItems="center"
                                    minW={20}
                                    // mb="25px"
                                >
                                    {t('distributorName', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="shopName"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    fontWeight="500"
                                    variant="main"
                                    maxW={'240px'}
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('distributorName', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {distributor.name}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('country', { ns: 'common' })}
                                </Text>
                                <RHFCountrySelectWithoutLabel
                                    name="countryCode"
                                    borderRadius={'10px !important'}
                                    fontSize="16px"
                                    h="40px"
                                    maxH="40px"
                                    maxW={'240px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('country', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {distributor.countryCode}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('language', { ns: 'common' })}
                                </Text>
                                <RHFLanguageSelectWithoutLabel
                                    name="languageCode"
                                    fontSize="16px"
                                    h="40px"
                                    maxH="40px"
                                    maxW={'240px'}
                                    borderRadius={'10px !important'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('language', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {resolverLanguageCodeToString(distributor.languageCode)}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('zipCode', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="zipCode"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('zipCode', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {distributor.zipCode}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('province', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="province"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('province', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {distributor.province}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('address', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="address"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('address', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {distributor.address}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <HStack justifyContent="flex-start" w="120px">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400">
                                        {t('phoneNumber', { ns: 'common' })}
                                    </Text>
                                </HStack>

                                <RHFTextField
                                    name="phoneNumber"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('phoneNumber', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {distributor.phoneNumber}
                                </Text>
                            </HStack>
                        )}
                    </SimpleGrid>
                </Card>
            </FormProvider>
        </ApolloMutationBoundary>
    );
}
