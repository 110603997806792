import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import i18n from './i18n/i18n';
import { ApolloProvider } from '@apollo/client';
import { client } from './apolloServer';
import AuthGuard from './layouts/auth/AuthGuard';
import { AuthProvider } from './layouts/auth/JwtContext';
import GuestGuard from './layouts/auth/GuestGuard';

ReactDOM.render(
    <ApolloProvider client={client}>
        <AuthProvider>
            <I18nextProvider i18n={i18n}>
                <ChakraProvider theme={theme}>
                    <React.StrictMode>
                        <BrowserRouter>
                            <Switch>
                                <Route
                                    path={`/auth`}
                                    children={
                                        <GuestGuard>
                                            <AuthLayout />
                                        </GuestGuard>
                                    }
                                />
                                <Route
                                    path={`/admin`}
                                    children={
                                        <AuthGuard>
                                            <AdminLayout />
                                        </AuthGuard>
                                    }
                                />
                                <Redirect from="/" to="/admin" />
                            </Switch>
                        </BrowserRouter>
                    </React.StrictMode>
                </ChakraProvider>
            </I18nextProvider>
        </AuthProvider>
    </ApolloProvider>,
    document.getElementById('root'),
);
