import React, { memo } from 'react';
import { ApolloError } from '@apollo/client';
import {
    Button,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import { AlertIcon } from '../icons/Icons';
import { useTranslation } from 'react-i18next';
import { errorMessageHandler } from './ErrorMessageHandler';

export const ModalApolloMutationError = memo(
    (
        props: ApolloError &
            UseDisclosureProps & {
                modalHeader?: string;
                errorMessage?: string;
                onAfterErrorHandler?: () => void;
            },
    ) => {
        const textPrimaryColor = useColorModeValue('black', 'white');
        const { isOpen, onClose, onAfterErrorHandler } = props;
        const { t } = useTranslation(['common', 'error']);
        const errorCode =
            props.graphQLErrors && props.graphQLErrors.length > 0
                ? (props.graphQLErrors[0].extensions.code as any)
                : null;
        const errorMessage = errorMessageHandler(props.message, errorCode, t);

        const handleOnClickClose = () => {
            onClose();
            if (onAfterErrorHandler) {
                onAfterErrorHandler();
            }
        };

        return (
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleOnClickClose} size="xl" isCentered>
                <ModalOverlay />
                <ModalContent style={{ paddingTop: '8px', paddingBottom: '8px', minHeight: '300px' }}>
                    <ModalCloseButton />
                    <ModalBody display="flex" alignItems="center" justifyContent="center" py={4}>
                        <VStack spacing={5}>
                            <Icon as={AlertIcon} w="50px" h="42px" color={'red.550'} mb={2} />
                            <VStack px={2} spacing={0}>
                                <Text color={textPrimaryColor} fontSize="16px" fontWeight="500" textAlign="center">
                                    {errorMessage ?? props.errorMessage ?? props.message}
                                </Text>
                            </VStack>
                        </VStack>
                    </ModalBody>

                    <ModalFooter display="flex" alignItems="center" justifyContent="center">
                        <HStack>
                            <Button
                                // colorScheme="red"
                                variant="solid"
                                fontSize="sm"
                                color={textPrimaryColor}
                                w="86px"
                                // backgroundColor="secondaryGray.300"
                                borderRadius={10}
                                fontWeight="500"
                                ms="auto"
                                onClick={handleOnClickClose}
                            >
                                {t('closeBtnLabel', { ns: 'common' })}
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    },
);

ModalApolloMutationError.displayName = 'ModalApolloMutationError';
