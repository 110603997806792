// noinspection DuplicatedCode

import { ColumnHelper } from '@tanstack/react-table';
import { MachineObj } from '../variables';
import { Badge, Flex, Link, Text } from '@chakra-ui/react';
import { MachineStatus, GranProSubscriptionPlan } from 'types/graphql';
import { ROUTE_PATH_DASHBOARD } from 'paths';
import { TFunction } from 'i18next';
import NotActivatedStatus from 'components/elements/NotActivatedStatus';
import OnlineStatus from 'components/elements/OnlineStatus';
import DisableStatus from 'components/elements/DisableStatus';
import OffLineStatus from '../../../../../../components/elements/OffLineStatus';

export const createMachineListColumn = (
    isLargerThan960: boolean,
    isLargerThan320: boolean,
    columnHelper: ColumnHelper<MachineObj>,
    textColor: string,
    planColor: string,
    enterprisePlanScheme: string,
    planScheme: string,
    t: TFunction,
) =>
    isLargerThan960
        ? [
              columnHelper.accessor('deviceName', {
                  id: 'deviceName',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('machineNameLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex align="center">
                          <Text color={textColor} fontSize="md" fontWeight="500">
                              <Link color={textColor} href={ROUTE_PATH_DASHBOARD.machine.detail(info.getValue()[1])}>
                                  {info.getValue()[0]}
                              </Link>
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('shopName', {
                  id: 'shopName',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('shopName', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      return (
                          <Text color={textColor} fontSize="md" fontWeight="500">
                              {info.getValue()}
                          </Text>
                      );
                  },
              }),
              columnHelper.accessor('plan', {
                  id: 'plan',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('planOption1', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) =>
                      info.getValue() === GranProSubscriptionPlan.Enterprise ? (
                          <Badge
                              ms="auto"
                              borderRadius={'8px'}
                              justifyContent="center"
                              alignItems="center"
                              alignSelf="center"
                              textAlign="center"
                              h="28px"
                              w={{ base: '100px', xl: '90px' }}
                              colorScheme={enterprisePlanScheme}
                              color={'purple.600'}
                              fontSize="14px"
                              textTransform="capitalize"
                          >
                              Enterprise
                          </Badge>
                      ) : info.getValue() === GranProSubscriptionPlan.Basic ? (
                          <Badge
                              ms="auto"
                              borderRadius={'8px'}
                              justifyContent="center"
                              alignItems="center"
                              alignSelf="center"
                              textAlign="center"
                              h="28px"
                              w={{ base: '100px', xl: '90px' }}
                              colorScheme={planScheme}
                              color={planColor}
                              fontSize="14px"
                              textTransform="capitalize"
                          >
                              Basic
                          </Badge>
                      ) : (
                          <Badge
                              ms="auto"
                              borderRadius={'8px'}
                              justifyContent="center"
                              alignItems="center"
                              alignSelf="center"
                              textAlign="center"
                              h="28px"
                              w={{ base: '100px', xl: '90px' }}
                              colorScheme={planScheme}
                              color={planColor}
                              fontSize="14px"
                              textTransform="capitalize"
                          >
                              -
                          </Badge>
                      ),
              }),
              columnHelper.accessor('statusMix', {
                  id: 'statusMix',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('statusOption1', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      if (typeof info.getValue()[1] === 'boolean' && info.getValue()[1] === false) {
                          return <NotActivatedStatus />;
                      } else {
                          return (
                              <Flex align="center">
                                  {info.getValue()[0] === true && <OnlineStatus />}
                                  {info.getValue()[0] === false && <OffLineStatus />}
                              </Flex>
                          );
                      }
                  },
              }),
          ]
        : isLargerThan320
        ? [
              columnHelper.accessor('deviceName', {
                  id: 'deviceName',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('machineNameLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex align="center" minW="350px">
                          <Text color={textColor} fontSize="md" fontWeight="500">
                              <Link color={textColor} href={ROUTE_PATH_DASHBOARD.machine.detail(info.getValue()[1])}>
                                  {info.getValue()[0]}
                              </Link>
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('statusMix', {
                  id: 'statusMix',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('statusOption1', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      if (typeof info.getValue()[1] === 'boolean' && info.getValue()[1] === false) {
                          return <NotActivatedStatus />;
                      } else {
                          return (
                              <Flex align="center">
                                  {info.getValue()[0] === true && <OnlineStatus />}
                                  {info.getValue()[0] === false && <OffLineStatus />}
                              </Flex>
                          );
                      }
                  },
              }),
              columnHelper.accessor('plan', {
                  id: 'plan',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('planOption1', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) =>
                      info.getValue() === GranProSubscriptionPlan.Enterprise ? (
                          <Badge
                              ms="auto"
                              borderRadius={'8px'}
                              justifyContent="center"
                              alignItems="center"
                              alignSelf="center"
                              textAlign="center"
                              h="28px"
                              w={{ base: '100px', xl: '90px' }}
                              colorScheme={enterprisePlanScheme}
                              color={'purple.600'}
                              fontSize="14px"
                              textTransform="capitalize"
                          >
                              Enterprise
                          </Badge>
                      ) : info.getValue() === GranProSubscriptionPlan.Basic ? (
                          <Badge
                              ms="auto"
                              borderRadius={'8px'}
                              justifyContent="center"
                              alignItems="center"
                              alignSelf="center"
                              textAlign="center"
                              h="28px"
                              w={{ base: '100px', xl: '90px' }}
                              colorScheme={planScheme}
                              color={planColor}
                              fontSize="14px"
                              textTransform="capitalize"
                          >
                              Basic
                          </Badge>
                      ) : (
                          <Badge
                              ms="auto"
                              borderRadius={'8px'}
                              justifyContent="center"
                              alignItems="center"
                              alignSelf="center"
                              textAlign="center"
                              h="28px"
                              w={{ base: '100px', xl: '90px' }}
                              colorScheme={planScheme}
                              color={planColor}
                              fontSize="14px"
                              textTransform="capitalize"
                          >
                              -
                          </Badge>
                      ),
              }),
          ]
        : [
              columnHelper.accessor('deviceName', {
                  id: 'deviceName',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="16px"
                          fontWeight="500"
                          color={textColor}
                          textTransform="capitalize"
                      >
                          {t('machineNameLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex align="center" minH="78px">
                          <Text color={textColor} fontSize="md" fontWeight="500">
                              <Link color={textColor} href={ROUTE_PATH_DASHBOARD.machine.detail(info.getValue()[1])}>
                                  {info.getValue()[0]}
                              </Link>
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('statusMix', {
                  id: 'statusMix',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="16px"
                          fontWeight="500"
                          color={textColor}
                          textTransform="capitalize"
                      >
                          {t('statusOption1', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      if (typeof info.getValue()[1] === 'boolean' && info.getValue()[1] === false) {
                          return <NotActivatedStatus />;
                      } else {
                          return (
                              <Flex align="center">
                                  {info.getValue()[0] === true && <OnlineStatus />}
                                  {info.getValue()[0] === false && <OffLineStatus />}
                              </Flex>
                          );
                      }
                  },
              }),
          ];
