import { Button, ButtonProps, HStack, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { MdModeEdit } from 'react-icons/md';
import { TfiClose } from 'react-icons/tfi';
import { useTranslation } from 'react-i18next';

type GranProEditBtnProps = ButtonProps & {
    isEdit: boolean;
    onEditable: () => void;
    onEditDisable: () => void;
    onChangeEditable?: () => void;
    setEditable?: (val: boolean) => void;
    onExtraHandleCancel?: () => void;
};

export default function GranProEditBtn(props: GranProEditBtnProps) {
    const { isEdit, onEditable, onEditDisable, onChangeEditable, setEditable, onExtraHandleCancel, ...rest } = props;
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const textColor = useColorModeValue('#4318FF', 'white');
    const { t } = useTranslation(['common']);

    const onHandleCancel = () => {
        onEditDisable();
        if (onExtraHandleCancel) {
            onExtraHandleCancel();
        }
    };

    return isEdit ? (
        <HStack alignItems="center">
            <Button p="0px" ms="auto" onClick={() => onHandleCancel()} variant="no-hover" bg="transparent" {...rest}>
                <Icon as={TfiClose} w="22px" h="22px" color={textColorSecondary} mr={1} />
            </Button>
        </HStack>
    ) : (
        <Button p="0px" ms="auto" onClick={() => onEditable()} variant="no-hover" bg="transparent" {...rest}>
            <Icon as={MdModeEdit} w="16px" h="16px" color={textColor} mr={1} />
            <Text fontSize="16px" color={textColor} fontWeight="bold">
                {t('editLabel', { ns: 'common' })}
            </Text>
        </Button>
    );
}
