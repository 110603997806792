import { ReactNode } from 'react';
import { ApolloError, MutationResult } from '@apollo/client';
import BackDrop from '../backdrop/Backdrop';
import { ModalApolloMutationError } from './DefaultApolloMutationError';
import { DefaultApolloMutationSuccess } from './DefaultApolloMutationSuccess';
import { UseDisclosureProps } from '@chakra-ui/react';

export interface ApolloMutationBoundaryProps<T extends MutationResult> {
    children: ReactNode;
    mutationResult: T;
    loadingComponent?: JSX.Element;
    toastComponent?: JSX.Element;
    toastSuccessDescription?: string;
    toastSuccessTitle?: string;
    onSuccess?: (props: NonNullable<Pick<T, 'data'>>) => JSX.Element | null;
    errorHandler?: (error: ApolloError) => JSX.Element | null;
    onExtraAfterErrorHandler?: () => void;
    onExtraAfterSuccessHandler?: () => void;
}

export const ApolloMutationBoundary = <T extends MutationResult>(
    props: UseDisclosureProps & ApolloMutationBoundaryProps<T>,
) => {
    const { loading, error, data } = props.mutationResult;
    const { isOpen, onOpen, onClose, onExtraAfterErrorHandler, onExtraAfterSuccessHandler } = props;

    return (
        <>
            {loading && (props.loadingComponent ?? <BackDrop />)}
            {error &&
                (props.errorHandler ? (
                    props.errorHandler(error)
                ) : (
                    <ModalApolloMutationError
                        name={error.name}
                        message={error.message}
                        graphQLErrors={error.graphQLErrors}
                        protocolErrors={error.protocolErrors}
                        clientErrors={error.clientErrors}
                        networkError={error.networkError}
                        extraInfo={error.extraInfo}
                        {...{
                            error,
                            isOpen: isOpen,
                            onOpen: onOpen,
                            onClose: onClose,
                            onAfterErrorHandler: onExtraAfterErrorHandler,
                        }}
                    />
                ))}
            {data &&
                (props.toastComponent ?? (
                    <DefaultApolloMutationSuccess
                        {...{
                            title: props.toastSuccessTitle,
                            description: props.toastSuccessDescription,
                            isOpen: isOpen,
                            onOpen: onOpen,
                            onClose: onClose,
                            onAfterSuccessHandler: onExtraAfterSuccessHandler,
                        }}
                    />
                ))}
            {props.children}
        </>
    );
};
