import { Flex, FlexProps, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export type PermissionDeniedProps = FlexProps & {
    [key: string]: any;
};

export default function PermissionDenied(props: PermissionDeniedProps) {
    const { ...rest } = props;
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Flex align="center" justifyContent="center" {...rest}>
            <Text color={textColor} fontSize="md" fontWeight="700">
                {t('permissionDeniedMessage', { ns: 'common' })}
            </Text>
        </Flex>
    );
}
