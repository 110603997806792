import {
    Button,
    Flex,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertIcon } from '../../../../../../components/icons/Icons';

type UpdateBeaconConfirmModalProps = UseDisclosureProps & {
    onHandleUpdateBeacon: () => void;
    onCancel?: () => void;
    isSubmitting: boolean;
};
export default function UpdateBeaconConfirmModal(props: UpdateBeaconConfirmModalProps) {
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common', 'machine']);
    const { isOpen, onClose, onHandleUpdateBeacon, isSubmitting, onCancel } = props;
    const stopBtnColor = '#D11828';
    const cancelBtnColor = '#A3AED0';
    const closeModalHandler = () => {
        onClose();
        if (onCancel) {
            onCancel();
        }
    };

    const onClickOkHandler = () => {
        onClose();
        onHandleUpdateBeacon();
    };
    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModalHandler} size="2xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody w="100%" pt={4} pb={5} minH={'320px'}>
                    <Flex align="center" direction="column" justifyContent="center" w="100%" minH={'320px'} gap={12}>
                        <VStack gap={1}>
                            <Icon as={AlertIcon} w="50px" h="42px" color={'red.550'} mb={2} />
                            <Text
                                color={textColor}
                                fontSize={{ base: '14px', lg: '16px' }}
                                fontWeight="500"
                                lineHeight="100%"
                            >
                                {t('updateBeaconConfirmMessage', { ns: 'machine' })}
                            </Text>
                        </VStack>

                        <HStack gap={7}>
                            <Button
                                backgroundColor={cancelBtnColor}
                                variant="ghost"
                                color="white"
                                fontSize="sm"
                                borderRadius={10}
                                fontWeight="500"
                                minW="140px"
                                onClick={closeModalHandler}
                            >
                                {t('cancelBtn', { ns: 'common' })}
                            </Button>

                            <Button
                                backgroundColor={stopBtnColor}
                                variant="ghost"
                                color="white"
                                fontSize="sm"
                                borderRadius="10px"
                                fontWeight="700"
                                minW="140px"
                                disabled={isSubmitting}
                                onClick={onClickOkHandler}
                            >
                                OK
                            </Button>
                        </HStack>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
