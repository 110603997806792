import { Badge, Box, Button, Flex, HStack, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';
import {
    currentMonth,
    currentYear,
    isCurrentDateGreaterThanPeriodEnd,
    resolverSubscriptionPlanToStr,
} from '../../../../../../utils/resolver-enum';
import { GranProSubscriptionPlan } from '../../../../../../types/graphql';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { MachineDetailType } from '../MachineDetailType';
import { fDateWithSlash } from '../../../../../../utils/format-time';
import { FaPlay } from 'react-icons/fa';

type EnterprisePlanSessionProps = MachineDetailType & {
    isEdit: boolean;
    // isChangePlanToBasic: boolean;
    // changeToBasicHandle: () => void;
    onSetProKitSubscriptionPlanAfterDemo: (subscriptionPlan: GranProSubscriptionPlan) => void;
    onDowngradeProKitSubscriptionPlan: () => void;
    onUpgradeProKitSubscriptionPlan: () => void;
};
export default function EnterprisePlanSession(props: EnterprisePlanSessionProps) {
    const {
        machine,
        isEdit,
        onSetProKitSubscriptionPlanAfterDemo,
        onDowngradeProKitSubscriptionPlan,
        onUpgradeProKitSubscriptionPlan,
    } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const planScheme = useColorModeValue('#5193F5', 'brand');
    const enterprisePlanScheme = useColorModeValue('#9F7AEA', 'brand');
    const btnColor = useColorModeValue('purple.500', 'white');
    const whitePlanColor = useColorModeValue('white', 'white');

    const textColorSecondary = useColorModeValue('black', 'white');
    const textColorSubTitle = useColorModeValue('#707EAE', 'white');
    const { t, i18n } = useTranslation(['common', 'machine']);
    const [isPlanChosen, setIsPlanChosen] = useState<boolean>(false);
    const [planChosen, setPlanChosen] = useState<'enterprise' | 'basic' | null>(null);
    const [isChangePlanToBasic, setisChangePlanToBasic] = useState<boolean>(false);

    const handleChoosePlan = (plan: 'enterprise' | 'basic') => {
        setIsPlanChosen(true);
        setPlanChosen(plan);
    };

    const onClickSaveBtn = () => {
        if (
            machine.proKit?.demonstrationPeriodEndAt &&
            !isCurrentDateGreaterThanPeriodEnd(new Date(machine.proKit.demonstrationPeriodEndAt))
        ) {
            if (planChosen === 'basic') {
                onSetProKitSubscriptionPlanAfterDemo(GranProSubscriptionPlan.Basic);
            } else if (planChosen === 'enterprise') {
                onSetProKitSubscriptionPlanAfterDemo(GranProSubscriptionPlan.Enterprise);
            }
        } else {
            if (machine.proKit?.subscriptionPlan === GranProSubscriptionPlan.Enterprise) {
                onDowngradeProKitSubscriptionPlan();
            } else {
                onUpgradeProKitSubscriptionPlan();
            }
        }
    };

    return (
        <>
            {!isEdit ? (
                machine.proKit?.demonstrationPeriodEndAt &&
                !isCurrentDateGreaterThanPeriodEnd(new Date(machine.proKit.demonstrationPeriodEndAt)) ? (
                    machine.proKit?.subscriptionPlanSchedule?.subscriptionPlan ? (
                        <HStack w="100%" pt={1}>
                            <Box>
                                <Text color={textColorSubTitle} fontSize="14px" fontWeight="500" letterSpacing={1}>
                                    現在、2週間の無料期間中です。
                                    <Text as="cite" color={textColorPrimary}>
                                        {`${fDateWithSlash(machine.proKit?.demonstrationPeriodEndAt)} `}
                                    </Text>
                                    から
                                    <Text as="cite" color={textColorPrimary}>
                                        {resolverSubscriptionPlanToStr(
                                            machine.proKit?.subscriptionPlanSchedule?.subscriptionPlan,
                                            t,
                                        )}
                                    </Text>
                                    プランが開始されます。
                                </Text>
                            </Box>
                        </HStack>
                    ) : (
                        <HStack w="100%" pt={1}>
                            {i18n.language === 'ja' ? (
                                <Box>
                                    <Text
                                        color={textColorSubTitle}
                                        fontSize="14px"
                                        fontWeight="500"
                                        letterSpacing={1.5}
                                    >
                                        {t('freePlanMessage1', { ns: 'machine' })}
                                        <Text as="cite" color={textColorPrimary}>
                                            {`${fDateWithSlash(machine.proKit?.demonstrationPeriodEndAt)} `}
                                        </Text>
                                        {t('freePlanMessage2', { ns: 'machine' })}
                                        <Text as="cite" color={textColorPrimary}>
                                            {resolverSubscriptionPlanToStr(GranProSubscriptionPlan.Enterprise, t)}
                                        </Text>
                                        {t('freePlanMessage3', { ns: 'machine' })}
                                    </Text>
                                </Box>
                            ) : (
                                <Box>
                                    <Text
                                        color={textColorSubTitle}
                                        fontSize="14px"
                                        fontWeight="500"
                                        letterSpacing={1.5}
                                    >
                                        {t('freePlanMessage1', { ns: 'machine' })}
                                        <Text as="cite" color={textColorPrimary}>
                                            {resolverSubscriptionPlanToStr(GranProSubscriptionPlan.Enterprise, t)}
                                        </Text>
                                        {` ${t('freePlanMessage2', { ns: 'machine' })} `}
                                        <Text as="cite" color={textColorPrimary}>
                                            {`${fDateWithSlash(machine.proKit?.demonstrationPeriodEndAt)} `}
                                        </Text>
                                        {t('freePlanMessage3', { ns: 'machine' })}
                                    </Text>
                                </Box>
                            )}
                        </HStack>
                    )
                ) : (
                    machine.proKit?.subscriptionPlanSchedule?.subscriptionPlan &&
                    machine.proKit?.subscriptionPlanSchedule?.validFrom &&
                    (i18n.language === 'ja' ? (
                        <Box
                            display="flex"
                            borderColor="#A3AED0"
                            borderWidth="1px"
                            px={2}
                            py={4}
                            justifyContent="flex-start"
                            mt={2}
                        >
                            <Text color={textColorSubTitle} fontSize="14px" fontWeight="500" letterSpacing={1}>
                                <Text as="cite" color={textColorPrimary}>
                                    {`${fDateWithSlash(machine.proKit?.subscriptionPlanSchedule?.validFrom)} `}
                                </Text>
                                {t('freePlanMessage4', { ns: 'machine' })}
                                <Text as="cite" color={textColorPrimary}>
                                    {resolverSubscriptionPlanToStr(
                                        machine.proKit?.subscriptionPlanSchedule?.subscriptionPlan,
                                        t,
                                    )}
                                </Text>
                                {t('freePlanMessage5', { ns: 'machine' })}
                            </Text>
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            borderColor="#A3AED0"
                            borderWidth="1px"
                            px={2}
                            py={4}
                            justifyContent="flex-start"
                            mt={2}
                        >
                            <Text color={textColorSubTitle} fontSize="14px" fontWeight="500" letterSpacing={1}>
                                {`${t('freePlanMessage4', { ns: 'machine' })} `}
                                <Text as="cite" color={textColorPrimary}>
                                    {`${fDateWithSlash(machine.proKit?.subscriptionPlanSchedule?.validFrom)} `}
                                </Text>
                                {` ${t('freePlanMessage5', { ns: 'machine' })} `}
                                <Text as="cite" color={textColorPrimary}>
                                    {resolverSubscriptionPlanToStr(
                                        machine.proKit?.subscriptionPlanSchedule?.subscriptionPlan,
                                        t,
                                    )}
                                </Text>
                            </Text>
                        </Box>
                    ))
                )
            ) : machine.proKit?.demonstrationPeriodEndAt &&
              !isCurrentDateGreaterThanPeriodEnd(new Date(machine.proKit.demonstrationPeriodEndAt)) ? (
                <Flex direction="column" gap={5}>
                    <Flex direction="row" justifyContent="space-between" alignItems="center">
                        {!isPlanChosen && (
                            <Text color={textColorSecondary} fontSize="md" fontWeight="400">
                                無料期間終了後のプランを選んでください。
                            </Text>
                        )}
                    </Flex>

                    <Flex
                        direction={{ base: 'column', md: 'row' }}
                        justifyContent="flex-start"
                        alignItems="center"
                        w="100%"
                        gap={{ base: 3, md: 20, lg: 1, xl: 5, '2xl': 20 }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            minW={{
                                base: 'auto',
                                xl: `${isPlanChosen ? '125px' : 'auto'}`,
                                '2xl': `${isPlanChosen ? '150px' : 'auto'}`,
                                // xl: '125px',
                                // '2xl': '150px',
                            }}
                        >
                            {isPlanChosen && (
                                <Box display="flex" alignItems="center">
                                    {planChosen === 'enterprise' && (
                                        <Icon
                                            as={IoMdCheckmarkCircle}
                                            color="#008CFF"
                                            width="22px"
                                            height="22px"
                                            mr={1}
                                        />
                                    )}
                                </Box>
                            )}

                            <Badge
                                ms="auto"
                                justifyContent="center"
                                alignItems="center"
                                alignSelf="center"
                                textAlign="center"
                                h="28px"
                                minW="100px"
                                backgroundColor={enterprisePlanScheme}
                                color={whitePlanColor}
                                fontSize="sm"
                                borderRadius="6px"
                                textTransform="capitalize"
                            >
                                {`${t('planOption3', { ns: 'common' })}`}
                            </Badge>
                        </Box>

                        <Box minW="165px" display="flex" alignItems="center" alignContent="center">
                            <Text color={textColorSubTitle} fontSize="14px" fontWeight="500" letterSpacing={1}>
                                GranProフル機能版
                                <br />
                                オンラインサービス対応
                            </Text>
                        </Box>

                        <Box>
                            <Button
                                py="0px"
                                px="4px"
                                onClick={() => handleChoosePlan('enterprise')}
                                variant="outline"
                                fontWeight="500"
                                minW={'65px'}
                                h="38px"
                                borderRadius="10px"
                                disabled={planChosen === 'enterprise'}
                                borderWidth="2px"
                                borderColor={btnColor}
                            >
                                <Text fontSize="14px" color={btnColor}>
                                    このプランに設定する
                                </Text>
                            </Button>
                        </Box>
                    </Flex>

                    <Flex
                        direction={{ base: 'column', md: 'row' }}
                        justifyContent="flex-start"
                        alignItems="center"
                        w="100%"
                        gap={{ base: 3, md: 20, lg: 1, xl: 5, '2xl': 20 }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            minW={{
                                base: 'auto',
                                xl: `${isPlanChosen ? '125px' : 'auto'}`,
                                '2xl': `${isPlanChosen ? '150px' : 'auto'}`,
                                // xl: '125px',
                                // '2xl': '150px',
                            }}
                        >
                            {isPlanChosen && (
                                <Box display="flex" alignItems="center">
                                    {planChosen === 'basic' && (
                                        <Icon
                                            as={IoMdCheckmarkCircle}
                                            color="#008CFF"
                                            width="22px"
                                            height="22px"
                                            mr={1}
                                        />
                                    )}
                                </Box>
                            )}

                            <Badge
                                ms="auto"
                                justifyContent="center"
                                alignItems="center"
                                alignSelf="center"
                                textAlign="center"
                                h="28px"
                                minW="100px"
                                backgroundColor={planScheme}
                                color={whitePlanColor}
                                fontSize="sm"
                                borderRadius="6px"
                                textTransform="capitalize"
                            >
                                {`${t('planOption2', { ns: 'common' })}`}
                            </Badge>
                        </Box>

                        <Box display="flex" alignItems="center" alignContent="center">
                            <Text color={textColorSubTitle} fontSize="14px" fontWeight="500" letterSpacing={1}>
                                GranPro機能限定版
                                <br />
                                オンラインサービス非対応
                            </Text>
                        </Box>

                        <Box>
                            <Button
                                py="0px"
                                px="4px"
                                onClick={() => handleChoosePlan('basic')}
                                variant="outline"
                                fontWeight="500"
                                minW={'65px'}
                                h="38px"
                                borderRadius="10px"
                                disabled={planChosen === 'basic'}
                                borderWidth="2px"
                                borderColor={btnColor}
                            >
                                <Text fontSize="14px" color={btnColor}>
                                    このプランに設定する
                                </Text>
                            </Button>
                        </Box>
                    </Flex>

                    {isPlanChosen && (
                        <Flex direction="row" justifyContent="center" alignItems="center" w="100%" mt={6}>
                            <Button
                                py="0px"
                                px="8px"
                                variant="brand"
                                fontWeight="500"
                                minW="140px"
                                maxH="35px"
                                borderRadius="10px"
                                backgroundColor="#4318FF"
                                mb={1}
                                onClick={onClickSaveBtn}
                            >
                                <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                            </Button>
                        </Flex>
                    )}
                </Flex>
            ) : (
                <Flex direction="column" gap={5}>
                    <Flex
                        direction={{ base: 'column', md: 'row' }}
                        justifyContent="center"
                        alignItems={{ base: 'center', md: 'flex-end' }}
                        alignContent="center"
                        w="100%"
                        // gap={15}
                        gap={{ base: 5, md: 10, '2xl': 20 }}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-end"
                            flexDirection="row"
                            gap={{ base: 4, md: 2, xl: 7, '2xl': 6 }}
                        >
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems={'start'}
                                flexDirection="column"
                                gap={1}
                            >
                                <Text color={textColorSubTitle} fontSize="sm" fontWeight="500">
                                    現在のプラン
                                </Text>
                                <Badge
                                    justifyContent="center"
                                    alignItems="center"
                                    alignSelf="center"
                                    textAlign="center"
                                    h="28px"
                                    minW="100px"
                                    backgroundColor={enterprisePlanScheme}
                                    color={whitePlanColor}
                                    fontSize="sm"
                                    borderRadius="6px"
                                    textTransform="capitalize"
                                >
                                    {`${t('planOption3', { ns: 'common' })}`}
                                </Badge>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Icon as={FaPlay} color="#D9D9D9" width="22px" height="22px" mb={1} />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems={'start'}
                                flexDirection="column"
                                gap={1}
                            >
                                <Box display="flex" alignItems="center" flexDirection="row">
                                    {isChangePlanToBasic && (
                                        <Icon
                                            as={IoMdCheckmarkCircle}
                                            color="#008CFF"
                                            width="22px"
                                            height="22px"
                                            mr={1}
                                        />
                                    )}
                                    {isChangePlanToBasic && (
                                        <Text color={textColorSubTitle} fontSize="sm" fontWeight="500">
                                            新しいプラン
                                        </Text>
                                    )}
                                </Box>

                                <Badge
                                    justifyContent="center"
                                    alignItems="center"
                                    alignSelf="center"
                                    textAlign="center"
                                    h="28px"
                                    minW="100px"
                                    backgroundColor={planScheme}
                                    color={whitePlanColor}
                                    fontSize="sm"
                                    borderRadius="6px"
                                    textTransform="capitalize"
                                >
                                    {`${t('planOption2', { ns: 'common' })}`}
                                </Badge>
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                py="0px"
                                px="4px"
                                onClick={() => setisChangePlanToBasic(!isChangePlanToBasic)}
                                variant="outline"
                                fontWeight="500"
                                minW={'65px'}
                                h="38px"
                                borderRadius="10px"
                                borderWidth="2px"
                                borderColor={btnColor}
                                // disabled={isChangePlanToBasic}
                                mb={-1}
                            >
                                <Text fontSize="14px" color={btnColor}>
                                    Basicプランに変更する
                                </Text>
                            </Button>
                        </Box>
                    </Flex>

                    {isChangePlanToBasic ? (
                        <Flex direction="row" justifyContent="center" alignItems="center" w="100%" mt={6}>
                            <Button
                                py="0px"
                                px="8px"
                                variant="brand"
                                fontWeight="500"
                                minW="140px"
                                maxH="35px"
                                borderRadius="10px"
                                backgroundColor="#4318FF"
                                mb={1}
                                onClick={onClickSaveBtn}
                            >
                                <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                            </Button>
                        </Flex>
                    ) : (
                        <Box px={{ base: 1, md: 20, xl: 1, '2xl': 20 }}>
                            <Box
                                display="flex"
                                borderColor="#A3AED0"
                                borderWidth="1px"
                                px={2}
                                py={4}
                                justifyContent="flex-start"
                                mt={2}
                            >
                                <Text color={textColorSubTitle} fontSize="14px" fontWeight="500" letterSpacing={1}>
                                    <Text as="cite" color={textColorPrimary}>
                                        {`${fDateWithSlash(`${currentYear()}/${Number(currentMonth()) + 1}/01`)}`}
                                    </Text>
                                    から
                                    <Text as="cite" color={textColorPrimary}>
                                        {resolverSubscriptionPlanToStr(GranProSubscriptionPlan.Basic, t)}
                                    </Text>
                                    プランに切り替わります。
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            )}
        </>
    );
}
