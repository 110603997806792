import { Flex, useDisclosure } from '@chakra-ui/react';
import { useAuthContext } from '../../../../../../layouts/auth/useAuthContext';
import AvatarProfile from './AvatarProfile';
import { ApolloQueryBoundary } from '../../../../../../components/graphql/ApolloQueryBoundary';
import ShopAccountInfoCard from './shop/ShopAccountInfoCard';
import ShopAccountSignInCard from './shop/ShopAccountSignInCard';
import { useShopAccountByIdQuery } from 'graphql/queries/shop/__generated__/shopAccountById.generated';

export default function ShopUserAccountSetting() {
    const { userId } = useAuthContext();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const getShopByIdQueryResult = useShopAccountByIdQuery({
        variables: {
            input: {
                shopId: userId,
            },
        },
        onError: async () => {
            onOpen();
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    return (
        <ApolloQueryBoundary
            queryResult={getShopByIdQueryResult}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            render={({ data: { shop } }) => (
                <Flex direction="column">
                    <AvatarProfile userName={shop.name} avatarUrl={shop.iconUrl} />
                    <ShopAccountInfoCard shop={shop} mb="20px" />
                    <ShopAccountSignInCard shop={shop} mb="20px" />
                    {/*<DistributorSignInCard mb="20px" />*/}
                </Flex>
            )}
        />
    );
}
