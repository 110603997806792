import * as Yup from 'yup';
import {
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    HStack,
    Switch,
    Text,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useCallback, useMemo } from 'react';
import { DealerDetailProps } from './dealerDetailTypes';
import { resolverLanguageCodeToString } from 'utils/formatEnumTypes';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import FormProvider, { RHFTextField } from 'components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEditDealerInformationMutation } from 'graphql/mutations/distributor/__generated__/editDealerInformation.generated';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import { RHFCountrySelectWithoutLabel } from 'components/hook-form/RHFCountrySelectWithoutLabel';
import { RHFLanguageSelectWithoutLabel } from 'components/hook-form/RHFLanguageSelectWithoutLabel';
import RHFUploadAvatar from '../../../../../../components/hook-form/RHFUploadAvatar';
import { CustomFile } from '../../../../../../components/upload/types';
import { useTranslation } from 'react-i18next';
import { DealerType } from '../../../../../../types/graphql';
import { useSwitchPartnerDealerStatusMutation } from '../../../../../../graphql/mutations/dealer/__generated__/switchPartnerDealerStatus.generated';
import ConfirmModalSwitchPartnerDealerStatus from './ConfirmModalSwitchPartnerDealerStatus';
import { GetDealerByIdDocument } from '../../../../../../graphql/queries/dealer/__generated__/getDealerById.generated';
import GranProEditBtn from '../../../../../../components/button/GranProEditBtn';
import { useEditable } from '../../../../../../components/hooks/useEditable';
import ConfirmModalRemoveSubordinate from './ConfirmModalRemoveSubordinate';
import { useRemoveSubordinateDealerFromPartnerMutation } from 'graphql/mutations/dealer/__generated__/removeSubordinateDealerFromPartner.generated';

type DealerInfoUpdateProps = {
    dealerName: string;
    countryCode: string;
    languageCode: string;
    zipCode: string;
    province: string;
    address: string;
    phoneNumber: string;
    isPartnerDealer: boolean;
    avatarUrl: CustomFile | string | null;
};

interface FormValuesProps extends Omit<DealerInfoUpdateProps, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

export default function Information(props: DealerDetailProps) {
    const { dealer } = props;
    const { accountType } = useAuthContext();
    const { isEdit, onChangeEditable, onEditDisable, ...other } = useEditable(false);
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const labelTextColor = useColorModeValue('#A3AED0', 'white');
    const { t } = useTranslation(['common', 'dealer']);
    const {
        isOpen: isOpenMutationBoundaryResult,
        onOpen: onOpenMutationBoundaryResult,
        onClose: onCloseMutationBoundaryResult,
    } = useDisclosure();

    const {
        isOpen: isOpenSwitchPartnerDealerStatusMutationResult,
        onOpen: onOpenSwitchPartnerDealerStatusMutationResult,
        onClose: onCloseSwitchPartnerDealerStatusMutationResult,
    } = useDisclosure();

    const {
        isOpen: isOpenModalSwitchPartnerDealerStatus,
        onOpen: onOpenModalSwitchPartnerDealerStatus,
        onClose: onCloseModalSwitchPartnerDealerStatus,
    } = useDisclosure();

    const {
        isOpen: isOpenRemoveDealerFromPartnerMutationResult,
        onOpen: onOpenRemoveDealerFromPartnerMutationResult,
        onClose: onCloseRemoveDealerFromPartnerMutationResult,
    } = useDisclosure();

    const {
        isOpen: isOpenRemoveDealerFromPartnerModal,
        onOpen: onOpenRemoveDealerFromPartnerModal,
        onClose: onCloseRemoveDealerFromPartnerModal,
    } = useDisclosure();

    const [editDealerInformationMutationFunc, editDealerInformationMutationResult] = useEditDealerInformationMutation();

    const [switchPartnerDealerStatusMutationFunc, switchPartnerDealerStatusMutationResult] =
        useSwitchPartnerDealerStatusMutation();

    const [removeSubordinateDealerFromPartnerMutationFunc, removeSubordinateDealerFromPartnerMutationResult] =
        useRemoveSubordinateDealerFromPartnerMutation();

    const DealerInfoUpdateSchema = Yup.object().shape({
        dealerName: Yup.string().required('Dealer Name is required'),
        countryCode: Yup.string().required('Country is required'),
        languageCode: Yup.string().required('Language is required'),
        zipCode: Yup.string().required('Zip Code is required'),
        province: Yup.string().required('Province is required'),
        address: Yup.string().required('Address is required'),
        phoneNumber: Yup.string().required('Phone Number is required'),
        isPartnerDealer: Yup.boolean().notRequired(),
    });

    const defaultValues = useMemo(
        () => ({
            dealerName: dealer.name,
            countryCode: dealer.countryCode,
            languageCode: dealer.languageCode,
            zipCode: dealer.zipCode,
            province: dealer.province,
            address: dealer.address,
            phoneNumber: dealer.phoneNumber,
            avatarUrl: dealer.iconUrl || null,
            isPartnerDealer: dealer.dealerType && dealer.dealerType === DealerType.Partner,
        }),
        [dealer],
    );

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(DealerInfoUpdateSchema) as any,
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        reset,
        setValue,
        watch,
    } = methods;

    const values = watch();

    const onHandleCancel = () => {
        onChangeEditable();
        reset();
    };
    const handleSuccessClose = () => {
        window.location.reload();
    };
    const onHandleDeletePhoto = () => {
        setValue('avatarUrl', null, { shouldValidate: true });
    };
    const onHandleEditDealer = async (data: DealerInfoUpdateProps) => {
        try {
            await editDealerInformationMutationFunc({
                variables: {
                    input: {
                        dealerId: dealer.id,
                        name: data.dealerName === dealer.name ? undefined : data.dealerName,
                        countryCode: data.countryCode === dealer.countryCode ? undefined : data.countryCode,
                        languageCode: data.languageCode === dealer.languageCode ? undefined : data.languageCode,
                        zipCode: data.zipCode === dealer.zipCode ? undefined : data.zipCode,
                        province: data.province === dealer.province ? undefined : data.province,
                        address: data.address === dealer.address ? undefined : data.address,
                        phoneNumber: data.phoneNumber === dealer.phoneNumber ? undefined : data.phoneNumber,
                        iconImage: typeof data.avatarUrl === 'string' ? undefined : data.avatarUrl,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenMutationBoundaryResult();
                    }
                },
                onError: async () => {
                    onOpenMutationBoundaryResult();
                },
            });
            onEditDisable();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('avatarUrl', newFile, { shouldValidate: true });
            }
        },
        [setValue],
    );

    const onHandleSwitchPartnerDealerStatus = async () => {
        try {
            await switchPartnerDealerStatusMutationFunc({
                variables: {
                    input: {
                        dealerId: dealer.id,
                        isPartnerDealer: values.isPartnerDealer,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenSwitchPartnerDealerStatusMutationResult();
                    }
                },
                onError: async () => {
                    onOpenSwitchPartnerDealerStatusMutationResult();
                },
                refetchQueries: () => [
                    {
                        query: GetDealerByIdDocument,
                        variables: {
                            input: {
                                dealerId: dealer.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const removeSubordinateDealerFromPartnerHandler = async () => {
        try {
            await removeSubordinateDealerFromPartnerMutationFunc({
                variables: {
                    input: {
                        subordinateDealerId: dealer.id,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenRemoveDealerFromPartnerMutationResult();
                    }
                },
                onError: async () => {
                    onOpenRemoveDealerFromPartnerMutationResult();
                },
                refetchQueries: () => [
                    {
                        query: GetDealerByIdDocument,
                        variables: {
                            input: {
                                dealerId: dealer.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnChangePartnerDealerStatus = () => {
        setValue('isPartnerDealer', !values.isPartnerDealer);
        onOpenModalSwitchPartnerDealerStatus();
    };

    const handleGoingToRemoveDealerFromPartner = () => {
        onOpenRemoveDealerFromPartnerModal();
    };

    const isDisplayRemoveSubordinateDealerBtn = useMemo(() => {
        return dealer.partnerDealer && dealer.partnerDealer?.id && accountType === AccountType.distributor;
    }, [dealer.partnerDealer, accountType]);

    return (
        <ApolloMutationBoundary
            isOpen={isOpenMutationBoundaryResult}
            onClose={onCloseMutationBoundaryResult}
            onOpen={onOpenMutationBoundaryResult}
            mutationResult={editDealerInformationMutationResult}
            onExtraAfterSuccessHandler={handleSuccessClose}
            toastSuccessTitle="Dealer updated"
            toastSuccessDescription="Updated Dealer successfully"
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onHandleEditDealer)}>
                <Card py={8} px={{ base: '20px', lg: '30px', xl: '40px', '2xl': '50px' }}>
                    <Flex direction="row" justifyContent="space-between">
                        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mb="4px">
                            {t('dealerInfoLabel', { ns: 'common' })}
                        </Text>
                        {accountType === AccountType.distributor && (
                            <GranProEditBtn
                                isEdit={isEdit}
                                onChangeEditable={onChangeEditable}
                                disabled={accountType !== AccountType.distributor}
                                onEditDisable={onEditDisable}
                                onExtraHandleCancel={onHandleCancel}
                                {...other}
                            />
                        )}
                    </Flex>
                    <Grid
                        templateColumns={{
                            base: '1fr',
                            lg: '1fr',
                        }}
                        templateRows={{
                            base: '1fr',
                        }}
                        gap={{ base: '20px', xl: '20px' }}
                        mt={5}
                    >
                        <Flex direction={'column'} gap={4} my={3}>
                            {isEdit && (
                                <Flex w={'100%'} direction="row" alignItems="center" mb={1}>
                                    <Flex direction="column" mr={5}>
                                        <RHFUploadAvatar name="avatarUrl" onDrop={handleDrop} />
                                    </Flex>
                                    <Flex direction="column" mr={5}>
                                        <Button
                                            colorScheme="red"
                                            variant="outline"
                                            p="15px 40px"
                                            fontSize="sm"
                                            h="40px"
                                            w="120px"
                                            onClick={onHandleDeletePhoto}
                                            borderRadius={10}
                                            fontWeight="500"
                                            ms="auto"
                                        >
                                            {t('deletePhotoLabel', { ns: 'common' })}
                                        </Button>
                                    </Flex>
                                </Flex>
                            )}

                            {isEdit ? (
                                <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                    <Text
                                        color={textColorSecondary}
                                        fontSize="14px"
                                        fontWeight="400"
                                        mr={1}
                                        alignItems="center"
                                        minW={20}
                                    >
                                        {t('dealerName', { ns: 'common' })}
                                    </Text>
                                    <RHFTextField
                                        name="dealerName"
                                        isRequired={false}
                                        borderRadius={'10px !important'}
                                        fontWeight="500"
                                        variant="main"
                                        maxW={'240px'}
                                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                        maxH={'40px'}
                                    />
                                </HStack>
                            ) : (
                                <HStack justifyContent="flex-start" alignItems="center">
                                    <Text color={labelTextColor} fontSize="14px" me="20px" fontWeight="500">
                                        {t('dealerName', { ns: 'common' })}
                                    </Text>
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="500">
                                        {dealer.name}
                                    </Text>
                                </HStack>
                            )}

                            {isEdit ? (
                                <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                        {t('country', { ns: 'common' })}
                                    </Text>
                                    <RHFCountrySelectWithoutLabel
                                        name="countryCode"
                                        borderRadius={'10px !important'}
                                        fontSize="16px"
                                        h="40px"
                                        maxH="40px"
                                        maxW={'240px'}
                                    />
                                </HStack>
                            ) : (
                                <HStack justifyContent="flex-start" alignItems="center">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" me="20px">
                                        {t('country', { ns: 'common' })}
                                    </Text>
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="500">
                                        {dealer.countryCode}
                                    </Text>
                                </HStack>
                            )}

                            {isEdit ? (
                                <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                        {t('language', { ns: 'common' })}
                                    </Text>
                                    <RHFLanguageSelectWithoutLabel
                                        name="languageCode"
                                        fontSize="16px"
                                        h="40px"
                                        maxH="40px"
                                        maxW={'240px'}
                                        borderRadius={'10px !important'}
                                    />
                                </HStack>
                            ) : (
                                <HStack justifyContent="flex-start" alignItems="center">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" me="20px">
                                        {t('language', { ns: 'common' })}
                                    </Text>
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="500">
                                        {resolverLanguageCodeToString(dealer.languageCode)}
                                    </Text>
                                </HStack>
                            )}

                            {isEdit ? (
                                <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                        {t('zipCode', { ns: 'common' })}
                                    </Text>
                                    <RHFTextField
                                        name="zipCode"
                                        isRequired={false}
                                        borderRadius={'10px !important'}
                                        maxW={'240px'}
                                        fontWeight="500"
                                        variant="main"
                                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                        maxH={'40px'}
                                    />
                                </HStack>
                            ) : (
                                <HStack justifyContent="flex-start" alignItems="center">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" me="20px">
                                        {t('zipCode', { ns: 'common' })}
                                    </Text>
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="500">
                                        {dealer.zipCode}
                                    </Text>
                                </HStack>
                            )}

                            {isEdit ? (
                                <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                        {t('province', { ns: 'common' })}
                                    </Text>
                                    <RHFTextField
                                        name="province"
                                        isRequired={false}
                                        borderRadius={'10px !important'}
                                        maxW={'240px'}
                                        fontWeight="500"
                                        variant="main"
                                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                        maxH={'40px'}
                                    />
                                </HStack>
                            ) : (
                                <HStack justifyContent="flex-start" alignItems="center">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" me="20px">
                                        {t('province', { ns: 'common' })}
                                    </Text>
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="500">
                                        {dealer.province}
                                    </Text>
                                </HStack>
                            )}

                            {isEdit ? (
                                <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                        {t('address', { ns: 'common' })}
                                    </Text>
                                    <RHFTextField
                                        name="address"
                                        isRequired={false}
                                        borderRadius={'10px !important'}
                                        maxW={'240px'}
                                        fontWeight="500"
                                        variant="main"
                                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                        maxH={'40px'}
                                    />
                                </HStack>
                            ) : (
                                <HStack justifyContent="flex-start" alignItems="center">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" me="20px">
                                        {t('address', { ns: 'common' })}
                                    </Text>
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="500">
                                        {dealer.address}
                                    </Text>
                                </HStack>
                            )}

                            {isEdit ? (
                                <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                    <HStack justifyContent="flex-start" w="120px">
                                        <Text color={textColorSecondary} fontSize="14px" fontWeight="400">
                                            {t('phoneNumber', { ns: 'common' })}
                                        </Text>
                                    </HStack>

                                    <RHFTextField
                                        name="phoneNumber"
                                        isRequired={false}
                                        borderRadius={'10px !important'}
                                        maxW={'240px'}
                                        fontWeight="500"
                                        variant="main"
                                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                        maxH={'40px'}
                                    />
                                </HStack>
                            ) : (
                                <HStack justifyContent="flex-start" alignItems="center">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" me="20px">
                                        {t('phoneNumber', { ns: 'common' })}
                                    </Text>
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="500">
                                        {dealer.phoneNumber}
                                    </Text>
                                </HStack>
                            )}
                            {!isEdit && dealer.dealerType && dealer.dealerType !== DealerType.Subordinate && (
                                <ApolloMutationBoundary
                                    isOpen={isOpenSwitchPartnerDealerStatusMutationResult}
                                    onClose={onCloseSwitchPartnerDealerStatusMutationResult}
                                    onOpen={onOpenSwitchPartnerDealerStatusMutationResult}
                                    mutationResult={switchPartnerDealerStatusMutationResult}
                                    onExtraAfterSuccessHandler={handleSuccessClose}
                                    toastSuccessTitle={t('switchPartnerDealerStatusSuccessTitle', { ns: 'dealer' })}
                                    toastSuccessDescription={t('switchPartnerDealerStatusSuccessMessage', {
                                        ns: 'dealer',
                                    })}
                                >
                                    <HStack justifyContent="flex-start" alignItems="center">
                                        <Text color={textColorSecondary} fontSize="14px" fontWeight="400" me="20px">
                                            Partner Dealer
                                        </Text>
                                        <Switch
                                            checked={values.isPartnerDealer}
                                            onChange={handleOnChangePartnerDealerStatus}
                                            colorScheme="brand"
                                            isChecked={values.isPartnerDealer}
                                            disabled={accountType !== AccountType.distributor}
                                        />
                                    </HStack>
                                </ApolloMutationBoundary>
                            )}

                            {!isEdit &&
                                (dealer.dealerType === DealerType.Subordinate ? (
                                    <ApolloMutationBoundary
                                        isOpen={isOpenRemoveDealerFromPartnerMutationResult}
                                        onClose={onCloseRemoveDealerFromPartnerMutationResult}
                                        onOpen={onOpenRemoveDealerFromPartnerMutationResult}
                                        mutationResult={removeSubordinateDealerFromPartnerMutationResult}
                                        onExtraAfterSuccessHandler={handleSuccessClose}
                                        toastSuccessTitle={t('removeDealerFromPartnerSuccessTitle', { ns: 'dealer' })}
                                        toastSuccessDescription={t('removeDealerFromPartnerSuccessMessage', {
                                            ns: 'dealer',
                                        })}
                                    >
                                        <Grid templateColumns="repeat(7, 1fr)" gap={2}>
                                            <GridItem colSpan={isDisplayRemoveSubordinateDealerBtn ? 5 : 7}>
                                                <HStack justifyContent="flex-start" alignItems="center" h="38px">
                                                    <Text
                                                        color={textColorSecondary}
                                                        fontSize="14px"
                                                        fontWeight="400"
                                                        me="20px"
                                                    >
                                                        Partner Dealer ID
                                                    </Text>
                                                    <Text
                                                        color={textColorPrimary}
                                                        fontSize="16px"
                                                        fontWeight="500"
                                                        me="20px"
                                                    >
                                                        {dealer.partnerDealer?.id ?? '-'}
                                                    </Text>
                                                    {dealer.partnerDealer?.name && (
                                                        <Text
                                                            color={textColorPrimary}
                                                            fontSize="16px"
                                                            fontWeight="500"
                                                            me="20px"
                                                        >
                                                            {dealer.partnerDealer?.name}
                                                        </Text>
                                                    )}
                                                </HStack>
                                            </GridItem>
                                            {isDisplayRemoveSubordinateDealerBtn && (
                                                <GridItem colSpan={2}>
                                                    <Flex justifyContent="flex-end" alignItems="center" w="100%">
                                                        <Button
                                                            // colorScheme={btnColorScheme}
                                                            variant="outline"
                                                            p="15px 40px"
                                                            fontSize="sm"
                                                            borderRadius={10}
                                                            fontWeight="500"
                                                            onClick={handleGoingToRemoveDealerFromPartner}
                                                            // isDisabled={isDisabled}
                                                            borderColor="#D11828"
                                                            h="38px"
                                                            w="140px"
                                                            borderWidth="2px"
                                                            color="#D11828"
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Flex>
                                                </GridItem>
                                            )}
                                        </Grid>
                                    </ApolloMutationBoundary>
                                ) : (
                                    <HStack justifyContent="flex-start" alignItems="center" mt={1}>
                                        <Text color={textColorSecondary} fontSize="14px" fontWeight="400" me="20px">
                                            Partner Dealer ID
                                        </Text>
                                        <Text color={textColorPrimary} fontSize="16px" fontWeight="500" me="20px">
                                            {dealer.partnerDealer?.id ?? '-'}
                                        </Text>
                                        {dealer.partnerDealer?.name && (
                                            <Text color={textColorPrimary} fontSize="16px" fontWeight="500" me="20px">
                                                {dealer.partnerDealer?.name}
                                            </Text>
                                        )}
                                    </HStack>
                                ))}

                            {isEdit && (
                                <Box
                                    display="flex"
                                    flexDirection={{ base: 'row' }}
                                    // gap={{ base: 5, md: 5 }}
                                    justifyContent="center"
                                    w="100%"
                                    mt={5}
                                    py={0}
                                >
                                    <Button
                                        py="0px"
                                        px="8px"
                                        variant="brand"
                                        fontWeight="500"
                                        minW="140px"
                                        maxH="40px"
                                        borderRadius="10px"
                                        backgroundColor="#4318FF"
                                        type="submit"
                                        isLoading={isSubmitting}
                                        disabled={
                                            values.dealerName === dealer.name &&
                                            values.countryCode === dealer.countryCode &&
                                            values.languageCode === dealer.languageCode &&
                                            values.zipCode === dealer.zipCode &&
                                            values.province === dealer.province &&
                                            values.address === dealer.address &&
                                            values.phoneNumber === dealer.phoneNumber &&
                                            values.province === dealer.province &&
                                            (typeof values.avatarUrl === 'string' || values.avatarUrl === null)
                                        }
                                    >
                                        <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                                    </Button>
                                </Box>
                            )}
                        </Flex>
                    </Grid>
                </Card>
            </FormProvider>
            <ConfirmModalSwitchPartnerDealerStatus
                onHandleSwitchPartnerDealerStatus={onHandleSwitchPartnerDealerStatus}
                isOpen={isOpenModalSwitchPartnerDealerStatus}
                onOpen={onOpenModalSwitchPartnerDealerStatus}
                onClose={onCloseModalSwitchPartnerDealerStatus}
                onCancel={reset}
            />
            <ConfirmModalRemoveSubordinate
                onHandleRemoveSubordinateDealerFromPartner={removeSubordinateDealerFromPartnerHandler}
                isOpen={isOpenRemoveDealerFromPartnerModal}
                onOpen={onOpenRemoveDealerFromPartnerModal}
                onClose={onCloseRemoveDealerFromPartnerModal}
                onCancel={reset}
            />
        </ApolloMutationBoundary>
    );
}
