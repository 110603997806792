export const createPagesFromTotal = (totalCount: number, pageSize: number) => {
    const pageCount = pageSize && pageSize > 0 ? Math.ceil(totalCount / pageSize) : 0;
    const arrPageCount = [];

    for (let i = 1; i <= pageCount; i++) {
        arrPageCount.push(i);
    }

    return arrPageCount;
};
