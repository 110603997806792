import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivateShopMutationVariables = Types.Exact<{
    input: Types.ActivateShopInput;
}>;

export type ActivateShopMutation = { __typename?: 'Mutation'; activate_shop: Types.SuccessResponse };

export const ActivateShopDocument = gql`
    mutation ActivateShop($input: ActivateShopInput!) {
        activate_shop(input: $input)
    }
`;
export type ActivateShopMutationFn = Apollo.MutationFunction<ActivateShopMutation, ActivateShopMutationVariables>;

/**
 * __useActivateShopMutation__
 *
 * To run a mutation, you first call `useActivateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateShopMutation, { data, loading, error }] = useActivateShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateShopMutation(
    baseOptions?: Apollo.MutationHookOptions<ActivateShopMutation, ActivateShopMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ActivateShopMutation, ActivateShopMutationVariables>(ActivateShopDocument, options);
}
export type ActivateShopMutationHookResult = ReturnType<typeof useActivateShopMutation>;
export type ActivateShopMutationResult = Apollo.MutationResult<ActivateShopMutation>;
export type ActivateShopMutationOptions = Apollo.BaseMutationOptions<
    ActivateShopMutation,
    ActivateShopMutationVariables
>;
