// noinspection DuplicatedCode

import { Avatar, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import Card from 'components/card/Card';
import profileAvatar from 'assets/img/avatars/avatar7.png';
import { DefaultAvatarSrc } from 'constant/defaultValues';

type AccountAvatarProfileProps = {
    userName: string;
    avatarUrl?: string;
};

export default function AvatarProfile(props: AccountAvatarProfileProps) {
    const { userName, avatarUrl, ...rest } = props;
    const borderColor = useColorModeValue('white !important', '#0b1437 !important');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    return (
        <Card mb="20px" {...rest}>
            <Flex justifyContent="center" alignItems="center" w="100%">
                <Flex direction="row" justifyContent="center" alignItems="center">
                    <Avatar
                        src={avatarUrl ?? DefaultAvatarSrc}
                        h={{ base: '87px', xl: '87px' }}
                        w={{ base: '87px', xl: '87px' }}
                        border="4px solid"
                        borderColor={borderColor}
                    />
                    <Text
                        color={textColor}
                        fontSize={{ base: '16px', lg: '24px' }}
                        fontWeight="700"
                        lineHeight="100%"
                        textAlign="center"
                        ml="10px"
                    >
                        {userName}
                    </Text>
                </Flex>
            </Flex>
        </Card>
    );
}
