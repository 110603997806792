import { Avatar, Flex, Grid, GridItem, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import MachineStatusElement from '../../../../../../components/elements/MachineStatusElement';

export default function EntryPlayer(props: { avatar: string; name: string; noOfEntry: number; [x: string]: any }) {
    const { avatar, name, noOfEntry, ...rest } = props;
    const textColorPrimary = useColorModeValue('navy.650', 'white');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    return (
        <Grid templateColumns="repeat(3, 1fr)" py={1.5}>
            <GridItem w="100%" colSpan={2}>
                <Flex direction="row" align="center" me="auto">
                    <Avatar h="40px" w="40px" src={avatar} me="10px" />
                    <Text color={textColor} fontSize="16px" me="6px" fontWeight="700">
                        {name}
                    </Text>
                </Flex>
            </GridItem>
            <GridItem w="100%">
                <Flex align="center" justifyContent="flex-end">
                    <Text color={textColorPrimary} fontWeight="500" fontSize="16px">
                        {noOfEntry}
                    </Text>
                </Flex>
            </GridItem>
        </Grid>
    );
}
