import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import AddNewShopTab from './AddNewShopTab';

export default function AddNewShop() {
    return (
        <Flex direction="column" minH="100vh" align="center" pt={{ sm: '125px', lg: '75px' }} position="relative">
            <AddNewShopTab />
        </Flex>
    );
}
