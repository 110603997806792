import { Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import MachineListByDistributor from './components/MachineListByDistributor';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import MachineListByShop from './components/MachineListByShop';
import MachineListByDealer from './components/MachineListByDealer';

export default function MachineOverview() {
    const { accountType } = useAuthContext();
    return (
        <Box pt={{ base: '40px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '0px', xl: '50px' }}>
            <Card py="20px" px={{ base: '0px', lg: '30px' }}>
                {accountType === AccountType.distributor && <MachineListByDistributor />}
                {accountType === AccountType.dealer && <MachineListByDealer />}
                {accountType === AccountType.shop && <MachineListByShop />}
            </Card>
        </Box>
    );
}
