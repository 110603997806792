import numeral from 'numeral';
export function fCurrencyWithoutLabel(num: number) {
    return `${numeral(num).format('0,0')}`;
}

export function isIntegerNumeric(str: string) {
    str = str.trim();
    if (!str) {
        return false;
    }
    str = str.replace(/^0+/, '') || '0';
    const n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}
