import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelHourlyRentalOfMachineMutationVariables = Types.Exact<{
    input: Types.CancelHourlyRentalOfMachineInput;
}>;

export type CancelHourlyRentalOfMachineMutation = {
    __typename?: 'Mutation';
    cancel_hourly_rental_of_machine: {
        __typename?: 'Machine';
        id: number;
        machineName: string;
        dummyPlayerId?: number | null;
        freeModeStart?: Date | null;
        freeModeEnd?: Date | null;
        status: Types.MachineStatus;
        inGameStatus: Types.InGameStatus;
        lastLoginAt?: Date | null;
        isPasswordRequired: boolean;
        serviceMode: Types.ProKitServiceModeOption;
        gameSetting: any;
        isEmittingShopBeacon: boolean;
        isOnline: boolean;
        teamViewerId?: string | null;
        shop: { __typename?: 'Shop'; id: number; name: string; iconUrl?: string | null };
        proKit?: { __typename?: 'ProKit'; id: number; modelName: string } | null;
    };
};

export const CancelHourlyRentalOfMachineDocument = gql`
    mutation cancelHourlyRentalOfMachine($input: CancelHourlyRentalOfMachineInput!) {
        cancel_hourly_rental_of_machine(input: $input) {
            id
            machineName
            shop {
                id
                name
                iconUrl
            }
            proKit {
                id
                modelName
            }
            dummyPlayerId
            freeModeStart
            freeModeEnd
            status
            inGameStatus
            lastLoginAt
            isPasswordRequired
            serviceMode
            gameSetting
            isPasswordRequired
            isEmittingShopBeacon
            isOnline
            teamViewerId
            freeModeStart
            freeModeEnd
        }
    }
`;
export type CancelHourlyRentalOfMachineMutationFn = Apollo.MutationFunction<
    CancelHourlyRentalOfMachineMutation,
    CancelHourlyRentalOfMachineMutationVariables
>;

/**
 * __useCancelHourlyRentalOfMachineMutation__
 *
 * To run a mutation, you first call `useCancelHourlyRentalOfMachineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelHourlyRentalOfMachineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelHourlyRentalOfMachineMutation, { data, loading, error }] = useCancelHourlyRentalOfMachineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelHourlyRentalOfMachineMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CancelHourlyRentalOfMachineMutation,
        CancelHourlyRentalOfMachineMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelHourlyRentalOfMachineMutation, CancelHourlyRentalOfMachineMutationVariables>(
        CancelHourlyRentalOfMachineDocument,
        options,
    );
}
export type CancelHourlyRentalOfMachineMutationHookResult = ReturnType<typeof useCancelHourlyRentalOfMachineMutation>;
export type CancelHourlyRentalOfMachineMutationResult = Apollo.MutationResult<CancelHourlyRentalOfMachineMutation>;
export type CancelHourlyRentalOfMachineMutationOptions = Apollo.BaseMutationOptions<
    CancelHourlyRentalOfMachineMutation,
    CancelHourlyRentalOfMachineMutationVariables
>;
