import {
    Button,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import { TiWarningOutline } from 'react-icons/ti';
import { ThemeTypings } from '@chakra-ui/styled-system';
import { useTranslation } from 'react-i18next';

export type CommonFeatureConfirmModalProps = UseDisclosureProps & {
    onHandleConfirm: () => void;
    btnColorScheme?: ThemeTypings['colorSchemes'];
    confirmBtnLabel: string;
    modalLabel: {
        modalHeader: string;
        modalBodyMessage1: string;
        modalBodyMessage2?: string;
    };
};

export default function CommonFeatureConfirmModal(props: CommonFeatureConfirmModalProps) {
    const { isOpen, onClose, onHandleConfirm, btnColorScheme = 'red', confirmBtnLabel, modalLabel } = props;
    const textPrimaryColor = useColorModeValue('navy.650', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{modalLabel.modalHeader}</ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" alignItems="center" justifyContent="center" py={4}>
                    <HStack>
                        <Icon as={TiWarningOutline} w="54px" h="50px" color={'red.550'} mr={1} />
                        <VStack justify="flex-start" alignItems="flex-start">
                            <Text color={textPrimaryColor} fontSize="16px" me="6px" fontWeight="500">
                                {modalLabel.modalBodyMessage1}
                            </Text>
                            {modalLabel.modalBodyMessage2 && (
                                <Text color={textPrimaryColor} fontSize="16px" me="6px" fontWeight="500">
                                    {modalLabel.modalBodyMessage2}
                                </Text>
                            )}
                        </VStack>
                    </HStack>
                </ModalBody>

                <ModalFooter display="flex" alignItems="center" justifyContent="center" pb={10}>
                    <HStack>
                        <Button
                            colorScheme="gray.500"
                            variant="outline"
                            fontSize="sm"
                            color={'gray.500'}
                            w="120px"
                            borderRadius={10}
                            fontWeight="500"
                            ms="auto"
                            onClick={onClose}
                        >
                            {t('cancelBtn', { ns: 'common' })}
                        </Button>

                        <Button
                            colorScheme={btnColorScheme}
                            variant="solid"
                            fontSize="sm"
                            w="120px"
                            borderRadius={10}
                            fontWeight="500"
                            onClick={onHandleConfirm}
                            ms="auto"
                        >
                            {confirmBtnLabel}
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
