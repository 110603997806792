// noinspection DuplicatedCode

import { ColumnHelper } from '@tanstack/react-table';
import { ProkitObj } from '../variables';
import { Flex, Link, Text } from '@chakra-ui/react';
import { ROUTE_PATH_DASHBOARD } from 'paths';
import { TFunction } from 'i18next';
import { fYyyyMmDd } from 'utils/format-time';
import * as React from 'react';
import { ProKitStatus } from '../../../../../../types/graphql';
import EnableStatus from '../../../../../../components/elements/EnableStatus';
import { resolverProKitStatusToStr } from '../../../../../../utils/resolver-enum';
import DisableStatus from '../../../../../../components/elements/DisableStatus';
import OutOfServiceStatus from '../../../../../../components/elements/OutOfServiceStatus';

export const createProKitListColumn = (
    isLargerThan960: boolean,
    isLargerThan460: boolean,
    columnHelper: ColumnHelper<ProkitObj>,
    textColor: string,
    t: TFunction,
) =>
    isLargerThan960
        ? [
              columnHelper.accessor('id', {
                  id: 'id',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          ID
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('model', {
                  id: 'model',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('model', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500" minW="200px">
                          <Link color={textColor} href={ROUTE_PATH_DASHBOARD.prokit.detail(info.getValue().id)}>
                              {info.getValue().modelName}
                          </Link>
                      </Text>
                  ),
              }),
              columnHelper.accessor('dealer', {
                  id: 'dealer',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('dealerName', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500" minW="250px">
                          {info.getValue().name}
                      </Text>
                  ),
              }),
              columnHelper.accessor('lastActive', {
                  id: 'lastActive',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('lastActive', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue() ? fYyyyMmDd(info.getValue()) : '-'}
                      </Text>
                  ),
              }),
              columnHelper.accessor('counter', {
                  id: 'counter',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('count', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('status', {
                  id: 'status',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          Status
                      </Text>
                  ),
                  cell: (info) => (
                      <>
                          {info.getValue() === ProKitStatus.InService && (
                              <EnableStatus enableTextValue={resolverProKitStatusToStr(info.getValue(), t)} />
                          )}
                          {info.getValue() === ProKitStatus.Deleted && (
                              <DisableStatus disableTextValue={resolverProKitStatusToStr(info.getValue(), t)} />
                          )}
                          {info.getValue()[0] === ProKitStatus.OutOfService && (
                              <OutOfServiceStatus
                                  outOfServiceTextValue={resolverProKitStatusToStr(info.getValue(), t)}
                              />
                          )}
                      </>
                  ),
              }),
          ]
        : isLargerThan460
        ? [
              columnHelper.accessor('id', {
                  id: 'id',
                  header: () => (
                      <Flex align="center" maxW="30px">
                          <Text
                              justifyContent="space-between"
                              align="center"
                              fontSize="14px"
                              fontWeight="500"
                              textTransform="capitalize"
                              color="secondaryGray.600"
                              maxW="30px"
                          >
                              ID
                          </Text>
                      </Flex>
                  ),
                  cell: (info) => (
                      <Flex align="center" maxW="10px">
                          <Text color={textColor} fontSize="16px" fontWeight="500">
                              <Link color={textColor} href={ROUTE_PATH_DASHBOARD.prokit.detail(1)}>
                                  {info.getValue()}
                              </Link>
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('model', {
                  id: 'model',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          Model
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500" minW="200px">
                          <Link color={textColor} href={ROUTE_PATH_DASHBOARD.prokit.detail(info.getValue().id)}>
                              {info.getValue().modelName}
                          </Link>
                      </Text>
                  ),
              }),
              columnHelper.accessor('lastActive', {
                  id: 'lastActive',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('lastActive', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue() ? fYyyyMmDd(info.getValue()) : '-'}
                      </Text>
                  ),
              }),
              columnHelper.accessor('status', {
                  id: 'status',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          Status
                      </Text>
                  ),
                  cell: (info) => (
                      <>
                          {info.getValue() === ProKitStatus.InService && (
                              <EnableStatus enableTextValue={resolverProKitStatusToStr(info.getValue(), t)} />
                          )}
                          {info.getValue() === ProKitStatus.Deleted && (
                              <DisableStatus disableTextValue={resolverProKitStatusToStr(info.getValue(), t)} />
                          )}
                          {info.getValue()[0] === ProKitStatus.OutOfService && (
                              <OutOfServiceStatus
                                  outOfServiceTextValue={resolverProKitStatusToStr(info.getValue(), t)}
                              />
                          )}
                      </>
                  ),
              }),
          ]
        : [
              columnHelper.accessor('model', {
                  id: 'model',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('model', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500" minW="200px">
                          <Link color={textColor} href={ROUTE_PATH_DASHBOARD.prokit.detail(info.getValue().id)}>
                              {info.getValue().modelName}
                          </Link>
                      </Text>
                  ),
              }),
              columnHelper.accessor('status', {
                  id: 'status',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          Status
                      </Text>
                  ),
                  cell: (info) => (
                      <>
                          {info.getValue() === ProKitStatus.InService && (
                              <EnableStatus enableTextValue={resolverProKitStatusToStr(info.getValue(), t)} />
                          )}
                          {info.getValue() === ProKitStatus.Deleted && (
                              <DisableStatus disableTextValue={resolverProKitStatusToStr(info.getValue(), t)} />
                          )}
                          {info.getValue()[0] === ProKitStatus.OutOfService && (
                              <OutOfServiceStatus
                                  outOfServiceTextValue={resolverProKitStatusToStr(info.getValue(), t)}
                              />
                          )}
                      </>
                  ),
              }),
          ];
