import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditShopInformationMutationVariables = Types.Exact<{
    input: Types.EditShopInformationInput;
}>;

export type EditShopInformationMutation = {
    __typename?: 'Mutation';
    edit_shop_information: {
        __typename?: 'Shop';
        id: number;
        name: string;
        beaconId?: string | null;
        iconUrl?: string | null;
        status: Types.ShopStatus;
        email: string;
        languageCode: string;
        countryCode: string;
        zipCode: string;
        province: string;
        address: string;
        phoneNumber: string;
        lastInformationEditedAt?: Date | null;
        lastInformationEditedBy?: Types.ShopInformationEditor | null;
        dealer: { __typename?: 'Dealer'; id: number; name: string };
    };
};

export const EditShopInformationDocument = gql`
    mutation editShopInformation($input: EditShopInformationInput!) {
        edit_shop_information(input: $input) {
            id
            name
            beaconId
            iconUrl
            status
            email
            languageCode
            countryCode
            zipCode
            province
            address
            phoneNumber
            lastInformationEditedAt
            lastInformationEditedBy
            dealer {
                id
                name
            }
        }
    }
`;
export type EditShopInformationMutationFn = Apollo.MutationFunction<
    EditShopInformationMutation,
    EditShopInformationMutationVariables
>;

/**
 * __useEditShopInformationMutation__
 *
 * To run a mutation, you first call `useEditShopInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopInformationMutation, { data, loading, error }] = useEditShopInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShopInformationMutation(
    baseOptions?: Apollo.MutationHookOptions<EditShopInformationMutation, EditShopInformationMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<EditShopInformationMutation, EditShopInformationMutationVariables>(
        EditShopInformationDocument,
        options,
    );
}
export type EditShopInformationMutationHookResult = ReturnType<typeof useEditShopInformationMutation>;
export type EditShopInformationMutationResult = Apollo.MutationResult<EditShopInformationMutation>;
export type EditShopInformationMutationOptions = Apollo.BaseMutationOptions<
    EditShopInformationMutation,
    EditShopInformationMutationVariables
>;
