import React, { ChangeEvent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Text,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import DefaultAuth from 'layouts/auth/variants/Default';
import illustration from 'assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import AdminConsoleLogo from '../../../components/logo/AdminConsoleLogo';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import { AccountType } from '../../../types/user';
import { useAuthContext } from '../../../layouts/auth/useAuthContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import SignInErrorModal from './SignInErrorModal';

type FormValuesProps = {
    email: string;
    password: string;
    accountType?: AccountType;
    afterSubmit?: string;
};

function SignIn() {
    const { isOpen: isOpenErrorModal, onOpen: onOpenErrorModal, onClose: onCloseErrorModal } = useDisclosure();
    const textColor = useColorModeValue('navy.650', 'white');
    const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
    const textColorSecondary = 'gray.400';
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const [show, setShow] = useState(false);
    const [accType, setAccType] = useState<AccountType | null>(null);
    const { t } = useTranslation(['common', 'signIn']);

    const { login } = useAuthContext();

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .required(`${t('emailRequiredMessage')}`)
            .email('Email must be a valid email address'),
        password: Yup.string()
            .required(`${t('passwordRequiredMessage')}`)
            .min(6, `${t('passwordHolderMess')}`),
    });

    const defaultValues = {
        email: '',
        password: '',
        accountType: AccountType.distributor,
    };

    const methods = useForm<FormValuesProps>({
        mode: 'onBlur',
        resolver: yupResolver(LoginSchema) as any,
        defaultValues,
    });

    const {
        register,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;
    const onSubmit = async (data: FormValuesProps) => {
        try {
            await login(data.email, data.password, accType);
        } catch (error: any) {
            setError('afterSubmit', {
                ...error,
                message: error.message,
            });
        }
    };

    const handleClick = () => setShow(!show);
    const handleChangeAccType = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === 'distributor') {
            setAccType(AccountType.distributor);
        }
        if (event.target.value === 'dealer') {
            setAccType(AccountType.dealer);
        }
        if (event.target.value === 'shop') {
            setAccType(AccountType.shop);
        }
        if (event.target.value === 'default') {
            setAccType && setAccType(null);
        }
    };

    useEffect(() => {
        if (errors?.afterSubmit?.message) {
            // const errorMessage = errorMessageHandler(errors?.afterSubmit?.message, errorCode, t);
            onOpenErrorModal();
        }
    }, [errors?.afterSubmit?.message]);
    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <DefaultAuth illustrationBackground={illustration} image={illustration}>
                <Flex
                    maxW={{ base: '100%' }}
                    w="100%"
                    mx={{ base: 'auto', lg: '0px' }}
                    me="auto"
                    h="100%"
                    alignItems="center"
                    justifyContent="center"
                    mb={{ base: '30px', md: '60px' }}
                    // px={{ base: '25px', md: '0px' }}
                    mt={{ base: '40px', md: '14vh' }}
                    flexDirection="column"
                >
                    <Flex
                        zIndex="2"
                        direction="column"
                        w={{ base: '75%', md: '460px' }}
                        maxW="100%"
                        borderRadius="15px"
                        mx={{ base: 'auto', lg: 'unset' }}
                        me="auto"
                        mb={{ base: '20px', md: 'auto' }}
                    >
                        <AdminConsoleLogo />

                        <Box me="auto" justifyContent="center">
                            <Heading color={textColor} fontSize="36px" mb="10px" justifyContent="center">
                                {t('headingLabel', { ns: 'signIn' })}
                            </Heading>
                            <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
                                {t('headingMess', { ns: 'signIn' })}
                            </Text>
                        </Box>

                        <FormControl isRequired>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                {t('accountTypeLabel')}
                            </FormLabel>
                            <Select
                                variant="main"
                                borderRadius="10px"
                                h="40px"
                                maxH="44px"
                                w="250px"
                                fontSize="16px"
                                fontWeight="500"
                                mb="20px"
                                onChange={(event) => handleChangeAccType(event)}
                            >
                                <option value="default">{t('accountTypeOption1')}</option>
                                <option value="distributor">{t('accountTypeOption2')}</option>
                                <option value="dealer">{t('accountTypeOption3')}</option>
                                <option value="shop">{t('accountTypeOption4')}</option>
                            </Select>
                        </FormControl>
                        <RHFTextField
                            name="email"
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: '0px', md: '0px' }}
                            type="email"
                            mb="8px"
                            fontWeight="500"
                            size="lg"
                            borderRadius="12px"
                            placeholder="Email"
                            formLabel={
                                <FormLabel
                                    display="flex"
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textColor}
                                    mb="8px"
                                >
                                    {t('emailLabel', { ns: 'common' })}
                                    <Text color={brandStars}></Text>
                                </FormLabel>
                            }
                        />

                        <FormControl isRequired={false} isInvalid={!!errors?.password?.message}>
                            <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                                {t('passwordLabel', { ns: 'common' })}
                                <Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size="md">
                                <Input
                                    isRequired={false}
                                    fontSize="sm"
                                    placeholder={t('passwordHolderMess', { ns: 'common' })}
                                    mb="8px"
                                    size="lg"
                                    type={show ? 'text' : 'password'}
                                    variant="auth"
                                    borderRadius="12px"
                                    name="password"
                                    {...register('password')}
                                />
                                <InputRightElement display="flex" alignItems="center" mt="4px">
                                    <Icon
                                        color={textColorSecondary}
                                        _hover={{ cursor: 'pointer' }}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        onClick={handleClick}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage sx={{ marginBottom: 2 }}>{errors?.password?.message}</FormErrorMessage>
                        </FormControl>

                        <Flex justifyContent="space-between" align="center" mb="24px">
                            <FormControl display="flex" alignItems="center">
                                <Checkbox id="remember-login" colorScheme="brandScheme" me="10px" />
                                <FormLabel
                                    htmlFor="remember-login"
                                    mb="0"
                                    fontWeight="normal"
                                    color={textColor}
                                    fontSize="sm"
                                >
                                    {t('keepOption', { ns: 'signIn' })}
                                </FormLabel>
                            </FormControl>
                            <NavLink to="/auth/forgot-password">
                                <Text color={textColorBrand} fontSize="sm" w="124px" fontWeight="500">
                                    {t('ForgotPasswordLabel', { ns: 'signIn' })}
                                </Text>
                            </NavLink>
                        </Flex>
                        <Button
                            fontSize="sm"
                            type="submit"
                            variant="brand"
                            fontWeight="500"
                            w="100%"
                            h="50"
                            mb="24px"
                            disabled={accType === null}
                            isLoading={isSubmitting}
                        >
                            {t('signInBtn', { ns: 'signIn' })}
                        </Button>
                        <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
                            <Text color={textColorDetails} fontWeight="400" fontSize="sm">
                                {t('needActivateAccountMessage', { ns: 'signIn' })}
                                <NavLink to="/auth/activate-account">
                                    <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                                        {t('activateAccountMessage', { ns: 'signIn' })}
                                    </Text>
                                </NavLink>
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </DefaultAuth>
            <SignInErrorModal
                errorMessage={errors?.afterSubmit?.message}
                onClose={onCloseErrorModal}
                isOpen={isOpenErrorModal}
                onOpen={onOpenErrorModal}
            />
        </FormProvider>
    );
}

export default SignIn;
