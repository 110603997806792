import { Flex, Heading, Link, useColorModeValue } from '@chakra-ui/react';
import { HorizonGranProLogo } from '../icons/Icons';

function AdminConsoleLogo() {
    const textColor = useColorModeValue('navy.700', 'white');
    const logoColor = useColorModeValue('navy.700', 'white');
    return (
        <Link href="/">
            <Flex alignItems="center" flexDirection="column" mb="50px" cursor="pointer">
                <HorizonGranProLogo h="26px" w="100%" ml={'50px'} my="8px" color={logoColor} />
                <Heading
                    color={textColor}
                    fontSize="18px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    textAlign={{ base: 'center' }}
                >
                    Admin Console
                </Heading>
            </Flex>
        </Link>
    );
}

export default AdminConsoleLogo;
