import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditIncomeConversionRateMutationVariables = Types.Exact<{
    input: Types.EditIncomeConversionRateInput;
}>;

export type EditIncomeConversionRateMutation = {
    __typename?: 'Mutation';
    edit_income_conversion_rate: Types.SuccessResponse;
};

export const EditIncomeConversionRateDocument = gql`
    mutation editIncomeConversionRate($input: EditIncomeConversionRateInput!) {
        edit_income_conversion_rate(input: $input)
    }
`;
export type EditIncomeConversionRateMutationFn = Apollo.MutationFunction<
    EditIncomeConversionRateMutation,
    EditIncomeConversionRateMutationVariables
>;

/**
 * __useEditIncomeConversionRateMutation__
 *
 * To run a mutation, you first call `useEditIncomeConversionRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditIncomeConversionRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editIncomeConversionRateMutation, { data, loading, error }] = useEditIncomeConversionRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditIncomeConversionRateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        EditIncomeConversionRateMutation,
        EditIncomeConversionRateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<EditIncomeConversionRateMutation, EditIncomeConversionRateMutationVariables>(
        EditIncomeConversionRateDocument,
        options,
    );
}
export type EditIncomeConversionRateMutationHookResult = ReturnType<typeof useEditIncomeConversionRateMutation>;
export type EditIncomeConversionRateMutationResult = Apollo.MutationResult<EditIncomeConversionRateMutation>;
export type EditIncomeConversionRateMutationOptions = Apollo.BaseMutationOptions<
    EditIncomeConversionRateMutation,
    EditIncomeConversionRateMutationVariables
>;
