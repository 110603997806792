// noinspection DuplicatedCode

import { ColumnHelper } from '@tanstack/react-table';
import { DealerObj } from '../variables';
import { Avatar, Flex, Link, Text } from '@chakra-ui/react';
import { DealerStatus } from 'types/graphql';
import { ROUTE_PATH_DASHBOARD } from 'paths';
import { resolverDealerStatusToStr } from 'utils/resolver-enum';
import { TFunction } from 'i18next';
import NotActivatedStatus from '../../../../../../components/elements/NotActivatedStatus';
import EnableStatus from '../../../../../../components/elements/EnableStatus';
import DisableStatus from '../../../../../../components/elements/DisableStatus';
import OutOfServiceStatus from '../../../../../../components/elements/OutOfServiceStatus';

export const createDealerTableColum = (
    isLargerThan960: boolean,
    isLargerThan320: boolean,
    columnHelper: ColumnHelper<DealerObj>,
    textColor: string,
    t: TFunction,
) =>
    isLargerThan960
        ? [
              columnHelper.accessor('name', {
                  id: 'name',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('dealerName', { ns: 'common' })}
                      </Text>
                  ),
                  cell: ({ cell }) => {
                      return (
                          <Flex align="center">
                              <Avatar src={cell.getValue()[1]} h="60px" w="60px" me="10px" />
                              <Text color={textColor} fontSize="16px" fontWeight="500">
                                  <Link color={textColor} href={ROUTE_PATH_DASHBOARD.dealer.detail(cell.getValue()[2])}>
                                      {cell.getValue()[0]}
                                  </Link>
                              </Text>
                          </Flex>
                      );
                  },
              }),
              columnHelper.accessor('province', {
                  id: 'province',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('province', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      return (
                          <Text color={textColor} fontSize="16px" fontWeight="500">
                              {info.getValue()}
                          </Text>
                      );
                  },
              }),
              columnHelper.accessor('shop', {
                  id: 'shop',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('shop', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('machine', {
                  id: 'machine',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('machines', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('statusMix', {
                  id: 'statusMix',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('status', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      if (typeof info.getValue()[1] === 'boolean' && info.getValue()[1] === false) {
                          return <NotActivatedStatus />;
                      } else {
                          return (
                              <>
                                  {info.getValue()[0] === DealerStatus.Enabled && (
                                      <EnableStatus
                                          enableTextValue={resolverDealerStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                                  {info.getValue()[0] === DealerStatus.Deleted && (
                                      <DisableStatus
                                          disableTextValue={resolverDealerStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                                  {info.getValue()[0] === DealerStatus.OutOfService && (
                                      <OutOfServiceStatus
                                          outOfServiceTextValue={resolverDealerStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                              </>
                          );
                      }
                  },
              }),
          ]
        : isLargerThan320
        ? [
              columnHelper.accessor('name', {
                  id: 'name',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('dealerName', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex align="center" minW="200px">
                          <Avatar src={info.getValue()[1]} h="60px" w="60px" me="10px" />
                          <Text color={textColor} fontSize="16px" fontWeight="500">
                              {info.getValue()[0]}
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('shop', {
                  id: 'shop',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          maxW="50px"
                          color="gray.400"
                      >
                          {t('shop', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500" maxW="50px">
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('machine', {
                  id: 'machine',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('machines', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('statusMix', {
                  id: 'statusMix',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('status', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      if (typeof info.getValue()[1] === 'boolean' && info.getValue()[1] === false) {
                          return <NotActivatedStatus />;
                      } else {
                          return (
                              <>
                                  {info.getValue()[0] === DealerStatus.Enabled && (
                                      <EnableStatus
                                          enableTextValue={resolverDealerStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                                  {info.getValue()[0] === DealerStatus.Deleted && (
                                      <DisableStatus
                                          disableTextValue={resolverDealerStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                                  {info.getValue()[0] === DealerStatus.OutOfService && (
                                      <OutOfServiceStatus
                                          outOfServiceTextValue={resolverDealerStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                              </>
                          );
                      }
                  },
              }),
          ]
        : [
              columnHelper.accessor('name', {
                  id: 'name',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="16px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('accountTypeOption3', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex align="center" minH="70px">
                          <Text color={textColor} fontSize="16px" fontWeight="500">
                              {info.getValue()[0]}
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('statusMix', {
                  id: 'statusMix',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="gray.400"
                      >
                          {t('status', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => {
                      if (typeof info.getValue()[1] === 'boolean' && info.getValue()[1] === false) {
                          return <NotActivatedStatus isMinW />;
                      } else {
                          return (
                              <>
                                  {info.getValue()[0] === DealerStatus.Enabled && (
                                      <EnableStatus
                                          enableTextValue={resolverDealerStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                                  {info.getValue()[0] === DealerStatus.Deleted && (
                                      <DisableStatus
                                          disableTextValue={resolverDealerStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                                  {info.getValue()[0] === DealerStatus.OutOfService && (
                                      <OutOfServiceStatus
                                          outOfServiceTextValue={resolverDealerStatusToStr(info.getValue()[0], t)}
                                      />
                                  )}
                              </>
                          );
                      }
                  },
              }),
          ];
