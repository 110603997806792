import { ReactNode, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuthContext } from './useAuthContext';
import { Text } from '@chakra-ui/react';
import SignInDefault from '../../views/auth/signIn/SignInDefault';

// ----------------------------------------------------------------------

type AuthGuardProps = {
    children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
    const { isAuthenticated, isInitialized } = useAuthContext();

    const { pathname } = useLocation();

    const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

    if (!isInitialized) {
        return <Text>Loading...</Text>;
    }

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <SignInDefault />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        // return <Navigate to={requestedLocation} />;
        return <Redirect to={requestedLocation} />;
    }

    return <> {children} </>;
}
