import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { IoMdCheckmarkCircle } from 'react-icons/io';

export type EnableStatusProps = {
    enableTextValue: string;
};

export default function EnableStatus(props: EnableStatusProps) {
    const { enableTextValue } = props;
    const textColor = useColorModeValue('#2B3674', 'white');
    return (
        <Flex align="center">
            <Icon as={IoMdCheckmarkCircle} color="#01B574" width="24px" height="24px" mr={1} />
            <Text color={textColor} fontSize="14px" fontWeight="700">
                {enableTextValue}
            </Text>
        </Flex>
    );
}
