import common from './common.json';
import dashboard from './dashboard.json';
import dealer from './dealer.json';
import signIn from './signIn.json';
import activation from './activation.json';
import machine from './machine.json';
import shop from './shop.json';
import error from './error.json';

const de = {
    common,
    dashboard,
    dealer,
    signIn,
    activation,
    machine,
    shop,
    error,
};
export default de;
