import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InviteSubordinateDealerMutationVariables = Types.Exact<{
    input: Types.InviteSubordinateDealerInput;
}>;

export type InviteSubordinateDealerMutation = {
    __typename?: 'Mutation';
    invite_subordinate_dealer: Types.SuccessResponse;
};

export const InviteSubordinateDealerDocument = gql`
    mutation inviteSubordinateDealer($input: InviteSubordinateDealerInput!) {
        invite_subordinate_dealer(input: $input)
    }
`;
export type InviteSubordinateDealerMutationFn = Apollo.MutationFunction<
    InviteSubordinateDealerMutation,
    InviteSubordinateDealerMutationVariables
>;

/**
 * __useInviteSubordinateDealerMutation__
 *
 * To run a mutation, you first call `useInviteSubordinateDealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteSubordinateDealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteSubordinateDealerMutation, { data, loading, error }] = useInviteSubordinateDealerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteSubordinateDealerMutation(
    baseOptions?: Apollo.MutationHookOptions<InviteSubordinateDealerMutation, InviteSubordinateDealerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<InviteSubordinateDealerMutation, InviteSubordinateDealerMutationVariables>(
        InviteSubordinateDealerDocument,
        options,
    );
}
export type InviteSubordinateDealerMutationHookResult = ReturnType<typeof useInviteSubordinateDealerMutation>;
export type InviteSubordinateDealerMutationResult = Apollo.MutationResult<InviteSubordinateDealerMutation>;
export type InviteSubordinateDealerMutationOptions = Apollo.BaseMutationOptions<
    InviteSubordinateDealerMutation,
    InviteSubordinateDealerMutationVariables
>;
