// noinspection DuplicatedCode

import {
    Button,
    Flex,
    Icon,
    Select,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import {
    ColumnFiltersState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    useReactTable,
} from '@tanstack/react-table';
import { CursorPaginationPageInfo } from 'graphql/types/other';
import { PickValueOf } from 'types/common';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import TableNoData from 'components/table/TableNoData';
import { useMemo, useState } from 'react';
import { MachineObj } from '../variables';
import { createMachineListColumn } from './CreateMachineListColumn';
import { GetMachineListOfShopQuery } from 'graphql/queries/shop/__generated__/getMachineListOfShop.generated';
import { useTranslation } from 'react-i18next';
import { GetMachineListOfDealerQuery } from '../../../../../../graphql/queries/machine/__generated__/getMachineListOfDealer.generated';
import { OnChangeFn } from '@tanstack/table-core/src/types';
import { createPagesFromTotal } from 'utils/tables';

export type MachineTableContainerProps = {
    totalCount: number;
    pageInfo: CursorPaginationPageInfo;
    edges:
        | PickValueOf<PickValueOf<GetMachineListOfShopQuery, 'get_machine_list_of_shop'>, 'edges'>
        | PickValueOf<PickValueOf<GetMachineListOfDealerQuery, 'get_machine_list_of_dealer'>, 'edges'>;
    paginationState: PaginationState;
    setPagination: (paginationState: PaginationState) => void;
};

export default function MachineTableContainer(props: MachineTableContainerProps) {
    const {
        edges,
        totalCount,
        paginationState: { pageSize, pageIndex },
        setPagination,
    } = props;
    // Style
    // ----------------------------------------------------------------------
    const [isLargerThan960] = useMediaQuery('(min-width: 960px)');
    const [isLargerThan320] = useMediaQuery('(min-width: 460px)');
    const textColor = useColorModeValue('navy.650', 'white');
    const borderColor = useColorModeValue('gray.200', 'white');
    const brandColor = useColorModeValue('brand.500', 'brand.400');
    const planScheme = useColorModeValue('navy', 'brand');
    const planColor = useColorModeValue('navy.500', 'brand.100');
    const enterprisePlanScheme = useColorModeValue('brandScheme', 'brand');
    const { t } = useTranslation(['common', 'machine']);
    // ----------------------------------------------------------------------
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const machineListDefaultData = useMemo(() => {
        const result: MachineObj[] = edges.map((machine) => ({
            plan: machine.node.proKit.subscriptionPlan,
            id: machine.node.id,
            deviceName: [machine.node.machineName, `${machine.node.id}`],
            shopName: machine.node.shop.name,
            isActive: machine.node.proKit.isActivated,
            registrationDate: machine.node.lastInformationEditedAt,
            status: machine.node.status,
            statusMix: [machine.node.isOnline, machine.node.proKit.isActivated],
        }));
        return result;
    }, []);
    const [globalFilter, setGlobalFilter] = useState('');
    const columnHelper = createColumnHelper<MachineObj>();
    const columns = createMachineListColumn(
        isLargerThan960,
        isLargerThan320,
        columnHelper,
        textColor,
        planColor,
        enterprisePlanScheme,
        planScheme,
        t,
    );
    const [data] = useState(() => [...machineListDefaultData]);
    // const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    //     pageIndex: 0,
    //     pageSize: 6,
    // });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const machineTable = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination as OnChangeFn<PaginationState>,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    });

    return totalCount > 0 ? (
        <>
            <Table color="gray.500" variant="simple" mb="24px">
                <Thead>
                    {machineTable.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Th pe="10px" borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <Flex
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : '',
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}
                                                justify="space-between"
                                                align="center"
                                                fontSize={{ sm: '10px', lg: '12px' }}
                                                color="gray.400"
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {{
                                                    asc: '',
                                                    desc: '',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </Flex>
                                        )}
                                    </Th>
                                );
                            })}
                        </Tr>
                    ))}
                </Thead>

                <Tbody>
                    {machineTable.getCoreRowModel().rows.map((row) => {
                        return (
                            <Tr px="20px" key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <Td
                                            key={cell.id}
                                            fontSize={{ sm: '14px' }}
                                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                            borderColor={borderColor}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>

            <Flex
                w="100%"
                justify={{ sm: 'flex-end', md: 'space-between' }}
                px="20px"
                pt="10px"
                pb="5px"
                direction={{ sm: 'column', md: 'row' }}
            >
                <Flex align="center" justifyContent="center">
                    <Text me="10px" minW="max-content" fontSize="sm" color="gray.500" fontWeight="normal">
                        {t('rowsPerPage', { ns: 'common' })}
                    </Text>
                    <Select
                        w="80px"
                        fontSize="sm"
                        variant="main"
                        value={pageSize}
                        onChange={(e) => machineTable.setPageSize(Number(e.target.value))}
                    >
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </Select>
                </Flex>
                <div className="flex items-center gap-2">
                    <Stack
                        direction="row"
                        alignSelf="flex-end"
                        spacing="4px"
                        ms="auto"
                        mt={{ sm: '20px', md: '0px' }}
                        justifyContent="center"
                    >
                        <Button
                            variant="no-effects"
                            onClick={() => machineTable.previousPage()}
                            disabled={!machineTable.getCanPreviousPage()}
                            transition="all .5s ease"
                            w="40px"
                            h="40px"
                            borderRadius="50%"
                            bg="transparent"
                            border="1px solid"
                            borderColor={borderColor}
                            display={pageSize === 5 ? 'none' : machineTable.getCanPreviousPage() ? 'flex' : 'none'}
                            _hover={{
                                bg: 'whiteAlpha.100',
                                opacity: '0.7',
                            }}
                        >
                            <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
                        </Button>
                        {createPagesFromTotal(totalCount, pageSize).map((pageNumber, index) => {
                            return (
                                <Button
                                    variant="no-effects"
                                    transition="all .5s ease"
                                    onClick={() => machineTable.setPageIndex(pageNumber - 1)}
                                    w="40px"
                                    h="40px"
                                    borderRadius="50%"
                                    bg={pageNumber === pageIndex + 1 ? brandColor : 'transparent'}
                                    border={pageNumber === pageIndex + 1 ? 'none' : '1px solid lightgray'}
                                    _hover={
                                        pageNumber === pageIndex + 1
                                            ? {
                                                  opacity: '0.75',
                                              }
                                            : {
                                                  bg: 'whiteAlpha.100',
                                              }
                                    }
                                    key={index}
                                >
                                    <Text fontSize="sm" color={pageNumber === pageIndex + 1 ? '#fff' : textColor}>
                                        {pageNumber}
                                    </Text>
                                </Button>
                            );
                        })}
                        <Button
                            variant="no-effects"
                            onClick={() => machineTable.nextPage()}
                            disabled={!machineTable.getCanNextPage()}
                            transition="all .5s ease"
                            w="40px"
                            h="40px"
                            borderRadius="50%"
                            bg="transparent"
                            border="1px solid"
                            borderColor={borderColor}
                            display={pageSize === 5 ? 'none' : machineTable.getCanNextPage() ? 'flex' : 'none'}
                            _hover={{
                                bg: 'whiteAlpha.100',
                                opacity: '0.7',
                            }}
                        >
                            <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
                        </Button>
                    </Stack>
                </div>
            </Flex>
        </>
    ) : (
        <TableNoData />
    );
}
