// noinspection JSDeprecatedSymbols

import { ApolloError, LazyQueryResult, QueryResult } from '@apollo/client';
import BackDrop from '../backdrop/Backdrop';
import { QueryErrorModalLoaded } from './QueryErrorLoaded';
import { PickValueOf } from '../../types/common';
import { UseDisclosureProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export interface ApolloQueryBoundaryProps<T, K> {
    queryResult: QueryResult<T, K> | LazyQueryResult<T, K>;
    render: (
        props:
            | (Omit<QueryResult<T, K>, 'loading' | 'error' | 'data'> & {
                  data: NonNullable<PickValueOf<QueryResult<T, K>, 'data'>>;
              })
            | (Omit<LazyQueryResult<T, K>, 'loading' | 'error' | 'data'> & {
                  data: NonNullable<PickValueOf<QueryResult<T, K>, 'data'>>;
              }),
    ) => JSX.Element;
    loadingComponent?: JSX.Element;
    noDataComponent?: JSX.Element;
    errorHandler?: (error: ApolloError, onToggle: UseDisclosureProps) => JSX.Element | null;
}

export const ApolloQueryBoundary = <T, K>(props: UseDisclosureProps & ApolloQueryBoundaryProps<T, K>) => {
    const { loading, error, data, ...rest } = props.queryResult;
    const { isOpen, onOpen, onClose } = props;
    const { t } = useTranslation(['common']);

    if (loading) {
        return props.loadingComponent ?? <BackDrop />;
    }

    if (error) {
        return props.errorHandler ? (
            props.errorHandler(error, { isOpen, onOpen, onClose })
        ) : (
            <QueryErrorModalLoaded
                name={error.name}
                message={error.message}
                graphQLErrors={error.graphQLErrors}
                protocolErrors={error.protocolErrors}
                clientErrors={error.clientErrors}
                networkError={error.networkError}
                extraInfo={error.extraInfo}
                {...{ error, isOpen: isOpen, onOpen: onOpen, onClose: onClose }}
            />
        );
    }

    if (!data) {
        return props.noDataComponent ?? <div>{t('noDataMessage', { ns: 'common' })}</div>;
    }

    return props.render({ data: data as NonNullable<T>, ...rest });
};
