import { Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { GameSettingObj } from './GameSettingTable';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';
import { useState } from 'react';
import { CreateGameTableColumn } from './CreateGameColum';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<GameSettingObj>();

type CommonGameSettingTableProps = {
    isEdit: boolean;
    setIsEdit: (isEdit: boolean) => void;
    defaultData: GameSettingObj[];
    tabValue: string;
};
export default function CommonGameSettingTable(props: CommonGameSettingTableProps) {
    const { isEdit, defaultData, tabValue } = props;
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { t } = useTranslation(['common', 'machine']);

    const [data] = useState(() => [...defaultData]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const columns = CreateGameTableColumn(columnHelper, textColorPrimary, isEdit, tabValue, t);
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });
    return (
        <Table variant="simple" color="gray.500">
            <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                            return (
                                <Th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    pe="10px"
                                    borderColor={borderColor}
                                    cursor="pointer"
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    <Flex
                                        justifyContent="space-between"
                                        align={{ base: 'start', lg: 'center', '2xl': 'center' }}
                                        fontSize={{ sm: '10px', lg: '12px' }}
                                        color="gray.400"
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: '',
                                            desc: '',
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </Flex>
                                </Th>
                            );
                        })}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map((row) => {
                    return (
                        <Tr key={row.id}>
                            {row.getVisibleCells().map((cell, idx) => {
                                return (
                                    <Td
                                        key={idx}
                                        fontSize={{ sm: '14px' }}
                                        // minW={{ sm: '150px', md: '200px', lg: '150px' }}
                                        minW={{ base: '90px', lg: '90px', '2xl': '150px' }}
                                        h={'50px'}
                                        py={0}
                                        borderBottom="1px solid #E2E8F0"
                                        borderColor="#E2E8F0"
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                );
                            })}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
}
