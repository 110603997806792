import { Box, Portal, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes';
import { PATH_AFTER_LOGIN, PATH_DASHBOARD } from '../../paths';
import { useAuthContext } from '../auth/useAuthContext';
import DealerDetail from '../../views/admin/main/dealer/detail';
import AddNewDealer from '../../views/admin/main/dealer/addnew';
import ShopDetail from '../../views/admin/main/shop/detail';
import AddNewShop from '../../views/admin/main/shop/addnew';
import MachineDetail from '../../views/admin/main/machine/detail';
import IncomeDetail from '../../views/admin/main/income/detail';
import ProkitDetail from '../../views/admin/main/prokit/detail';
import AccountSetting from '../../views/admin/main/account/setting';
import { KEY } from '../../constant/key';
import localStorageAvailable from '../../utils/localStorageAvailable';
import { DealerType } from '../../types/graphql';

export default function Dashboard(props: { [x: string]: any }) {
    const { ...rest } = props;
    const storageAvailable = localStorageAvailable();
    const { accountType } = useAuthContext();
    const [fixed] = useState(false);
    const [isLargerThan2Sm] = useMediaQuery(`(min-width: 460px)`);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== '/admin/full-screen-maps';
    };

    const dealerType = useMemo(() => {
        return storageAvailable ? (localStorage.getItem(KEY.dealerType) as DealerType) : null;
    }, []);

    const getActiveRoute = (routes: RoutesType[], isTabletMode: boolean): string => {
        const activeRoute = 'GranPro Admin';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                const collapseActiveRoute = getActiveRoute(routes[i].items, isTabletMode);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return isTabletMode
                        ? routes[i].name
                        : routes[i].secondaryName
                        ? routes[i].secondaryName
                        : routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    const getActiveNavbar = (routes: RoutesType[]): boolean => {
        const activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                const collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].secondary;
                }
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((route: RoutesType, key: number) => {
            if (route.layout === '/admin') {
                return <Route path={route.layout + route.path} component={route.component} key={key} />;
            }
            if (route.collapse) {
                return getRoutes(route.items);
            } else {
                return null;
            }
        });
    };

    document.documentElement.dir = 'ltr';
    const { onOpen } = useDisclosure();
    return (
        <Box>
            <SidebarContext.Provider
                value={{
                    toggleSidebar,
                    setToggleSidebar,
                }}
            >
                <Sidebar routes={routes(accountType, dealerType)} display="none" {...rest} />
                <Box
                    float="right"
                    minHeight="100vh"
                    height="100%"
                    overflow="auto"
                    position="relative"
                    maxHeight="100%"
                    w={{ base: '100%', '2xl': 'calc( 100% - 290px )' }}
                    maxWidth={{ base: '100%', '2xl': 'calc( 100% - 290px )' }}
                    transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                    transitionDuration=".2s, .2s, .35s"
                    transitionProperty="top, bottom, width"
                    transitionTimingFunction="linear, linear, ease"
                >
                    <Portal>
                        <Box>
                            <Navbar
                                onOpen={onOpen}
                                logoText={'GranPro Admin'}
                                brandText={getActiveRoute(routes(accountType, dealerType), isLargerThan2Sm)}
                                secondary={getActiveNavbar(routes(accountType, dealerType))}
                                fixed={fixed}
                                {...rest}
                            />
                        </Box>
                    </Portal>

                    {getRoute() ? (
                        <Box mx="auto" p={{ base: '20px', md: '30px' }} pe="20px" minH="100vh" pt="50px">
                            <Switch>
                                {getRoutes(routes(accountType, dealerType))}
                                <Route path={PATH_DASHBOARD.dealer.createNew} children={<AddNewDealer />} />
                                <Route path={PATH_DASHBOARD.dealer.detail} children={<DealerDetail />} />
                                <Route path={PATH_DASHBOARD.shop.createNew} children={<AddNewShop />} />
                                <Route path={PATH_DASHBOARD.shop.detail} children={<ShopDetail />} />
                                <Route path={PATH_DASHBOARD.machine.detail} children={<MachineDetail />} />
                                <Route path={PATH_DASHBOARD.income.detail} children={<IncomeDetail />} />
                                <Route path={PATH_DASHBOARD.prokit.detail} children={<ProkitDetail />} />
                                <Route path={PATH_DASHBOARD.account.setting} children={<AccountSetting />} />
                                <Redirect from="/" to={PATH_AFTER_LOGIN} />
                            </Switch>
                        </Box>
                    ) : null}
                </Box>
            </SidebarContext.Provider>
        </Box>
    );
}
