import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Text, useColorModeValue } from '@chakra-ui/react';
import DefaultAuth from 'layouts/auth/variants/Default';

import illustration from 'assets/img/auth/auth.png';
import React from 'react';
import AdminConsoleLogo from '../../../components/logo/AdminConsoleLogo';

function ResetPasswordDefault() {
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                h="100%"
                alignItems="center"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                mt={{ base: '40px', md: '14vh' }}
                flexDirection="column"
            >
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', lg: '456px' }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                    align="center"
                >
                    <AdminConsoleLogo />

                    <Box me="auto" mb="34px" justifyContent="center" alignItems="center">
                        <Heading
                            color={textColor}
                            fontSize={{ base: '3xl', md: '36px' }}
                            mb="16px"
                            justifyContent="center"
                            textAlign="center"
                        >
                            Reset password
                        </Heading>
                        <Text
                            color={textColorSecondary}
                            fontSize="md"
                            w={{ base: '100%', lg: '456px' }}
                            maxW="100%"
                            textAlign="center"
                        >
                            Enter new password of your account
                        </Text>
                    </Box>
                    <FormControl>
                        <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Password<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            type="email"
                            placeholder="Your account password"
                            mb="24px"
                            size="lg"
                        />
                        <Button fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px">
                            Reset Password
                        </Button>
                    </FormControl>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default ResetPasswordDefault;
