import { GameSettingObj } from './components/GameSettingTable';

export const defaultZeroOneGameSettingValue: GameSettingObj[] = [...Array(12)].map((_, index) => ({
    gameName: [1, 2, 3, 4, 5, 6, 54, 55, 56, 57, 58, 59][index],
    gameType: [
        '301',
        '501',
        '701',
        '901',
        '1101',
        '1501',
        'Freeze301',
        'Freeze501',
        'Freeze701',
        'Freeze901',
        'Freeze1101',
        'Freeze1501',
    ][index] as Game,
    round: 10,
    credit: 1,
}));
export const defaultCricketGameSettingValue: GameSettingObj[] = [...Array(6)].map((_, index) => ({
    gameName: [7, 23, 21, 24, 60, 61][index],
    gameType: ['Standard CR.', 'Hidden CR.', 'Cut throat CR.', 'Hidden cut throat CR.', 'Team CR.', 'Wild card CR.'][
        index
    ] as Game,
    round: 10,
    credit: 1,
}));
export const defaultPracticeGameSettingValue: GameSettingObj[] = [...Array(12)].map((_, index) => ({
    gameName: [8, 16, 17, 29, 53, 25, 31, 32, 35, 19, 33, 26][index],
    gameType: [
        'Count up',
        'CR Count up',
        'Half it',
        'Shoot force',
        'Delta shoot',
        'Target Bull',
        'Spider',
        'Pirates',
        'Rotation',
        'Oniren',
        'Multiple CR.',
        'Target 20',
    ][index] as Game,
    round: 10,
    credit: 1,
}));

export const defaultAiGameSettingValue: GameSettingObj[] = [...Array(7)].map((_, index) => ({
    gameName: [9, 10, 11, 12, 13, 14, 15][index],
    gameType: ['Animal301', 'Animal501', 'Animal701', 'Animal901', 'Animal1101', 'Animal1301', 'AnimalCR'][
        index
    ] as Game,
    round: [15, 15, 15, 15, 15, 15, 15][index],
    credit: 1,
}));

export const defaultPartyGameSettingValue: GameSettingObj[] = [...Array(8)].map((_, index) => ({
    gameName: [18, 36, 37, 20, 22, 34, 38, 62][index],
    gameType: [
        'Beyond top',
        'Hyper bull',
        'Treasure hunt',
        'Two line',
        'Hide and Seek',
        'Fun mission',
        'Tic tac toe',
        'Kick down',
    ][index] as Game,
    round: [10, 8, 10, 10, 10, 10, 12, 15][index],
    credit: 1,
}));
