import { Flex, FormControl, FormErrorMessage, Select, useColorModeValue } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectProps } from '@chakra-ui/select/src/select';
import { Country } from '../../types/graphql';

type RHFCountrySelectWithoutLabelProps = SelectProps & {
    name: string;
};

export function RHFCountrySelectWithoutLabel({ name, ...other }: RHFCountrySelectWithoutLabelProps) {
    const { control } = useFormContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl isInvalid={!!error}>
                    <Flex direction="column">
                        <Select
                            {...field}
                            {...other}
                            fontSize="sm"
                            id="currency"
                            variant="main"
                            h="44px"
                            maxH="44px"
                            defaultValue={Country.Japan}
                        >
                            {/*<option value="denmark">Denmark</option>*/}
                            {/*<option value="france">France</option>*/}
                            {/*<option value="germany">Germany</option>*/}
                            {/*<option value="hongKong">HongKong</option>*/}
                            <option value={Country.Japan}>Japan</option>
                            {/*<option value="korea">Korea</option>*/}
                            {/*<option value="spain">Spain</option>*/}
                            {/*<option value="taiwan">Taiwan</option>*/}
                            <option value={Country.Us}>USA</option>
                        </Select>
                        <FormErrorMessage>{error && error.message}</FormErrorMessage>
                    </Flex>
                </FormControl>
            )}
        />
    );
}
