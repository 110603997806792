import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSubordinateDealersListOfPartnerQueryVariables = Types.Exact<{
    input: Types.GetSubordinateDealersListOfPartnerInput;
}>;

export type GetSubordinateDealersListOfPartnerQuery = {
    __typename?: 'Query';
    get_subordinate_dealers_list_of_partner: {
        __typename?: 'DealerConnection';
        totalCount: number;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
        edges: Array<{
            __typename?: 'DealerEdge';
            cursor: string;
            node?: {
                __typename?: 'Dealer';
                id: number;
                name: string;
                iconUrl?: string | null;
                status: Types.DealerStatus;
                email: string;
                languageCode: string;
                countryCode: string;
                zipCode: string;
                province: string;
                address: string;
                phoneNumber: string;
                isActivated: boolean;
                dealerType: Types.DealerType;
                partnerDealer?: { __typename?: 'Dealer'; id: number; name: string; iconUrl?: string | null } | null;
                distributor: {
                    __typename?: 'Distributor';
                    id: number;
                    name: string;
                    status: Types.DistributorStatus;
                    email: string;
                    languageCode: string;
                    countryCode: string;
                    zipCode: string;
                    province: string;
                    address: string;
                    phoneNumber: string;
                };
                shops: Array<{
                    __typename?: 'Shop';
                    id: number;
                    name: string;
                    beaconId?: string | null;
                    status: Types.ShopStatus;
                    email: string;
                    phoneNumber: string;
                    languageCode: string;
                    countryCode: string;
                    zipCode: string;
                    province: string;
                    address: string;
                    user?: {
                        __typename?: 'User';
                        id: number;
                        cognito_id?: string | null;
                        last_login_at?: Date | null;
                    } | null;
                    machines: Array<{
                        __typename?: 'Machine';
                        id: number;
                        dummyPlayerId?: number | null;
                        serviceMode: Types.ProKitServiceModeOption;
                        freeModeStart?: Date | null;
                        freeModeEnd?: Date | null;
                        status: Types.MachineStatus;
                        lastLoginAt?: Date | null;
                        isPasswordRequired: boolean;
                        playerEntries: Array<{
                            __typename?: 'Player';
                            id: number;
                            type: Types.PlayerType;
                            gran_id: string;
                            player_name: string;
                            icon_url?: string | null;
                            card_bg_index: number;
                            card_bg_url?: string | null;
                            card_bg_color: Types.CardBgColor;
                        } | null>;
                        proKit?: {
                            __typename?: 'ProKit';
                            id: number;
                            status: Types.ProKitStatus;
                            subscriptionPlan?: Types.GranProSubscriptionPlan | null;
                            isActivated: boolean;
                        } | null;
                    }>;
                }>;
            } | null;
        } | null>;
    };
};

export const GetSubordinateDealersListOfPartnerDocument = gql`
    query getSubordinateDealersListOfPartner($input: GetSubordinateDealersListOfPartnerInput!) {
        get_subordinate_dealers_list_of_partner(input: $input) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    name
                    iconUrl
                    status
                    email
                    languageCode
                    countryCode
                    zipCode
                    province
                    address
                    phoneNumber
                    isActivated
                    dealerType
                    partnerDealer {
                        id
                        name
                        iconUrl
                    }
                    distributor {
                        id
                        name
                        status
                        email
                        languageCode
                        countryCode
                        zipCode
                        province
                        address
                        phoneNumber
                    }
                    shops {
                        id
                        name
                        beaconId
                        status
                        email
                        phoneNumber
                        languageCode
                        countryCode
                        zipCode
                        province
                        address
                        user {
                            id
                            cognito_id
                            last_login_at
                        }
                        machines {
                            id
                            dummyPlayerId
                            serviceMode
                            freeModeStart
                            freeModeEnd
                            status
                            lastLoginAt
                            isPasswordRequired
                            playerEntries {
                                id
                                type
                                gran_id
                                player_name
                                icon_url
                                card_bg_index
                                card_bg_url
                                card_bg_color
                            }
                            proKit {
                                id
                                status
                                subscriptionPlan
                                isActivated
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useGetSubordinateDealersListOfPartnerQuery__
 *
 * To run a query within a React component, call `useGetSubordinateDealersListOfPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubordinateDealersListOfPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubordinateDealersListOfPartnerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSubordinateDealersListOfPartnerQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetSubordinateDealersListOfPartnerQuery,
        GetSubordinateDealersListOfPartnerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetSubordinateDealersListOfPartnerQuery, GetSubordinateDealersListOfPartnerQueryVariables>(
        GetSubordinateDealersListOfPartnerDocument,
        options,
    );
}
export function useGetSubordinateDealersListOfPartnerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetSubordinateDealersListOfPartnerQuery,
        GetSubordinateDealersListOfPartnerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetSubordinateDealersListOfPartnerQuery,
        GetSubordinateDealersListOfPartnerQueryVariables
    >(GetSubordinateDealersListOfPartnerDocument, options);
}
export type GetSubordinateDealersListOfPartnerQueryHookResult = ReturnType<
    typeof useGetSubordinateDealersListOfPartnerQuery
>;
export type GetSubordinateDealersListOfPartnerLazyQueryHookResult = ReturnType<
    typeof useGetSubordinateDealersListOfPartnerLazyQuery
>;
export type GetSubordinateDealersListOfPartnerQueryResult = Apollo.QueryResult<
    GetSubordinateDealersListOfPartnerQuery,
    GetSubordinateDealersListOfPartnerQueryVariables
>;
