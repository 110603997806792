import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import NotActivatedStatus from './NotActivatedStatus';
import OnlineStatus from './OnlineStatus';
import OffLineStatus from './OffLineStatus';
import { useTranslation } from 'react-i18next';
import { MachineStatus } from '../../types/graphql';
import EnableStatus from './EnableStatus';
import { resolverMachineStatusToStr } from '../../utils/resolver-enum';
import DisableStatus from './DisableStatus';
import { IoMdCloseCircle } from 'react-icons/io';

export type MachineStatusElementProps = {
    isOnline: boolean;
    isActivated: boolean;
    [key: string]: any;
};

export default function MachineStatusElement(props: MachineStatusElementProps) {
    const { isOnline, isActivated, ...rest } = props;
    return isActivated === false ? (
        <NotActivatedStatus />
    ) : (
        <Flex align="center" justifyContent="flex-end" style={{ ...rest }}>
            {isOnline === true && <OnlineStatus />}
            {isOnline === false && <OffLineStatus />}
        </Flex>
    );
}

export type MachineStatusElementWithDisableProps = {
    status: MachineStatus;
    isActivated: boolean;
    [key: string]: any;
};

export function MachineStatusElementWithDisable(props: MachineStatusElementWithDisableProps) {
    const { status, isActivated, ...rest } = props;
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    return isActivated === false ? (
        <NotActivatedStatus />
    ) : (
        <Flex align="center" justifyContent="center" style={{ ...rest }}>
            {status === MachineStatus.Enabled && (
                <EnableStatus enableTextValue={resolverMachineStatusToStr(status, t)} />
            )}
            {status === MachineStatus.Deleted && (
                <DisableStatus disableTextValue={resolverMachineStatusToStr(status, t)} />
            )}
            {status === MachineStatus.OutOfService && (
                <Flex align="center">
                    <Icon as={IoMdCloseCircle} color="#EE5D50" width="22px" height="22px" mr={1} />
                    <Text color={textColor} fontSize="14px" fontWeight="700">
                        {resolverMachineStatusToStr(status, t)}
                    </Text>
                </Flex>
            )}
        </Flex>
    );
}
