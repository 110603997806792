import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LiftDealerLoginProhibitionMutationVariables = Types.Exact<{
    input: Types.LiftDealerLoginProhibitionInput;
}>;

export type LiftDealerLoginProhibitionMutation = {
    __typename?: 'Mutation';
    lift_dealer_login_prohibition: Types.SuccessResponse;
};

export const LiftDealerLoginProhibitionDocument = gql`
    mutation liftDealerLoginProhibition($input: LiftDealerLoginProhibitionInput!) {
        lift_dealer_login_prohibition(input: $input)
    }
`;
export type LiftDealerLoginProhibitionMutationFn = Apollo.MutationFunction<
    LiftDealerLoginProhibitionMutation,
    LiftDealerLoginProhibitionMutationVariables
>;

/**
 * __useLiftDealerLoginProhibitionMutation__
 *
 * To run a mutation, you first call `useLiftDealerLoginProhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLiftDealerLoginProhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [liftDealerLoginProhibitionMutation, { data, loading, error }] = useLiftDealerLoginProhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLiftDealerLoginProhibitionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LiftDealerLoginProhibitionMutation,
        LiftDealerLoginProhibitionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LiftDealerLoginProhibitionMutation, LiftDealerLoginProhibitionMutationVariables>(
        LiftDealerLoginProhibitionDocument,
        options,
    );
}
export type LiftDealerLoginProhibitionMutationHookResult = ReturnType<typeof useLiftDealerLoginProhibitionMutation>;
export type LiftDealerLoginProhibitionMutationResult = Apollo.MutationResult<LiftDealerLoginProhibitionMutation>;
export type LiftDealerLoginProhibitionMutationOptions = Apollo.BaseMutationOptions<
    LiftDealerLoginProhibitionMutation,
    LiftDealerLoginProhibitionMutationVariables
>;
