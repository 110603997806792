// noinspection DuplicatedCode

import * as Yup from 'yup';
import { Box, Button, Flex, HStack, SimpleGrid, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from '../../../../../../../components/card/Card';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { PickValueOf } from '../../../../../../../types/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from '../../../../../../../components/hook-form';
import { RHFCountrySelectWithoutLabel } from '../../../../../../../components/hook-form/RHFCountrySelectWithoutLabel';
import { RHFLanguageSelectWithoutLabel } from '../../../../../../../components/hook-form/RHFLanguageSelectWithoutLabel';
import { resolverLanguageCodeToString } from '../../../../../../../utils/formatEnumTypes';
import { ApolloMutationBoundary } from '../../../../../../../components/graphql/ApolloMutationBoundary';
import { useAuthContext } from '../../../../../../../layouts/auth/useAuthContext';
import RHFUploadAvatar from '../../../../../../../components/hook-form/RHFUploadAvatar';
import { CustomFile } from '../../../../../../../components/upload/types';
import {
    GetDealerByIdDocument,
    GetDealerByIdQuery,
} from '../../../../../../../graphql/queries/dealer/__generated__/getDealerById.generated';
import { useEditDealerInformationMutation } from 'graphql/mutations/distributor/__generated__/editDealerInformation.generated';
import { KEY } from '../../../../../../../constant/key';
import { useTranslation } from 'react-i18next';
import GranProEditBtn from '../../../../../../../components/button/GranProEditBtn';
import { useEditable } from '../../../../../../../components/hooks/useEditable';

type DealerAccountInfoUpdateProps = {
    dealerName: string;
    countryCode: string;
    languageCode: string;
    zipCode: string;
    province: string;
    address: string;
    phoneNumber: string;
    avatarUrl: CustomFile | string | null;
};

interface FormValuesProps extends Omit<DealerAccountInfoUpdateProps, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}

type DealerAccountInfoCardProps = {
    dealer: PickValueOf<GetDealerByIdQuery, 'dealer'>;
    [key: string]: any;
};

export default function DealerAccountInfoCard(props: DealerAccountInfoCardProps) {
    const { accType, dealer, ...rest } = props;
    const { userId } = useAuthContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const { isEdit, onChangeEditable, onEditDisable, ...other } = useEditable(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation(['common']);

    const [updateDealerAccInformationMutationFunc, editDealerInformationMutationResult] =
        useEditDealerInformationMutation();

    const DealerAccountUpdateSchema = Yup.object().shape({
        dealerName: Yup.string().required('Dealer Name is required'),
        countryCode: Yup.string().required('Country is required'),
        languageCode: Yup.string().required('Language is required'),
        zipCode: Yup.string().required('Zip Code is required'),
        province: Yup.string().required('Province is required'),
        address: Yup.string().required('Address is required'),
        phoneNumber: Yup.string().required('Phone Number is required'),
    });

    const defaultValues = useMemo(
        () => ({
            dealerName: dealer.name,
            countryCode: dealer.countryCode,
            languageCode: dealer.languageCode,
            zipCode: dealer.zipCode,
            province: dealer.province,
            address: dealer.address,
            phoneNumber: dealer.phoneNumber,
            avatarUrl: dealer.iconUrl || null,
        }),
        [dealer],
    );

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(DealerAccountUpdateSchema) as any,
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        reset,
        setValue,
    } = methods;

    const onHandleDeletePhoto = () => {
        setValue('avatarUrl', null, { shouldValidate: true });
    };
    const onHandleUpdateDealerAccount = async (data: DealerAccountInfoUpdateProps) => {
        try {
            await updateDealerAccInformationMutationFunc({
                variables: {
                    input: {
                        dealerId: userId,
                        name: data.dealerName === dealer.name ? undefined : data.dealerName,
                        countryCode: data.countryCode === dealer.countryCode ? undefined : data.countryCode,
                        languageCode: data.languageCode === dealer.languageCode ? undefined : data.languageCode,
                        zipCode: data.zipCode === dealer.zipCode ? undefined : data.zipCode,
                        province: data.province === dealer.province ? undefined : data.province,
                        address: data.address === dealer.address ? undefined : data.address,
                        phoneNumber: data.phoneNumber === dealer.phoneNumber ? undefined : data.phoneNumber,
                        iconImage: typeof data.avatarUrl === 'string' ? undefined : data.avatarUrl,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        localStorage.setItem(KEY.userAvatarUrl, res?.edit_dealer_information?.iconUrl);
                        onOpen();
                        onEditDisable();
                    }
                },
                onError: async () => {
                    onOpen();
                },
                refetchQueries: () => [
                    {
                        query: GetDealerByIdDocument,
                        variables: {
                            input: {
                                dealerId: userId,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleDropDealerAccAvatar = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('avatarUrl', newFile, { shouldValidate: true });
            }
        },
        [setValue],
    );
    return (
        <ApolloMutationBoundary
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            mutationResult={editDealerInformationMutationResult}
            toastSuccessTitle="Account updated"
            toastSuccessDescription="Your account info has been updated successfully"
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onHandleUpdateDealerAccount)}>
                <Card py={8} px={{ base: '20px', lg: '30px', xl: '40px', '2xl': '50px' }} borderRadius="30px" {...rest}>
                    <Flex direction="row" justifyContent="space-between" alignItems="stretch" w={'100%'}>
                        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                            {t('signInInfoLabel', { ns: 'common' })}
                        </Text>
                        <GranProEditBtn
                            isEdit={isEdit}
                            onChangeEditable={onChangeEditable}
                            onEditDisable={onEditDisable}
                            onExtraHandleCancel={reset}
                            {...other}
                        />
                    </Flex>

                    {isEdit && (
                        <Flex w={'100%'} direction="row" alignItems="center" mt={6} mb={1}>
                            <Flex direction="column" mr={5}>
                                <RHFUploadAvatar name="avatarUrl" onDrop={handleDropDealerAccAvatar} />
                            </Flex>
                            <Flex direction="column" mr={5}>
                                <Button
                                    colorScheme="red"
                                    variant="outline"
                                    p="15px 40px"
                                    fontSize="sm"
                                    h="40px"
                                    w="120px"
                                    onClick={onHandleDeletePhoto}
                                    borderRadius={10}
                                    fontWeight="500"
                                    ms="auto"
                                >
                                    {t('deletePhotoLabel', { ns: 'common' })}
                                </Button>
                            </Flex>
                        </Flex>
                    )}

                    <SimpleGrid
                        columns={{ sm: 1 }}
                        spacing={{ base: '6px', xl: '6px' }}
                        mt={{ base: '20px', xl: '20px' }}
                        gap={isEdit ? 3 : 5}
                    >
                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text
                                    color={textColorSecondary}
                                    fontSize="14px"
                                    fontWeight="400"
                                    mr={1}
                                    alignItems="center"
                                    minW={20}
                                    // mb="25px"
                                >
                                    {t('dealerName', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="dealerName"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    fontWeight="500"
                                    variant="main"
                                    maxW={'240px'}
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('dealerName', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {dealer.name}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('country', { ns: 'common' })}
                                </Text>
                                <RHFCountrySelectWithoutLabel
                                    name="countryCode"
                                    borderRadius={'10px !important'}
                                    fontSize="16px"
                                    h="40px"
                                    maxH="40px"
                                    maxW={'240px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('country', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {dealer.countryCode}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('language', { ns: 'common' })}
                                </Text>
                                <RHFLanguageSelectWithoutLabel
                                    name="languageCode"
                                    fontSize="16px"
                                    h="40px"
                                    maxH="40px"
                                    maxW={'240px'}
                                    borderRadius={'10px !important'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('language', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {resolverLanguageCodeToString(dealer.languageCode)}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('zipCode', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="zipCode"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('zipCode', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {dealer.zipCode}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('province', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="province"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('province', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {dealer.province}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={1} minW={20}>
                                    {t('address', { ns: 'common' })}
                                </Text>
                                <RHFTextField
                                    name="address"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('address', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {dealer.address}
                                </Text>
                            </HStack>
                        )}

                        {isEdit ? (
                            <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                <HStack justifyContent="flex-start" w="120px">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400">
                                        {t('phoneNumber', { ns: 'common' })}
                                    </Text>
                                </HStack>

                                <RHFTextField
                                    name="phoneNumber"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    maxW={'240px'}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>
                        ) : (
                            <HStack justifyContent="flex-start" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('phoneNumber', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {dealer.phoneNumber}
                                </Text>
                            </HStack>
                        )}
                        {isEdit && (
                            <Box
                                display="flex"
                                flexDirection={{ base: 'row' }}
                                justifyContent="center"
                                w="100%"
                                mt={4}
                                py={1}
                            >
                                <Button
                                    py="10px"
                                    px="8px"
                                    variant="brand"
                                    fontWeight="500"
                                    minW="140px"
                                    maxH="40px"
                                    borderRadius="10px"
                                    backgroundColor="#4318FF"
                                    type="submit"
                                    isLoading={isSubmitting}
                                >
                                    <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                                </Button>
                            </Box>
                        )}
                    </SimpleGrid>
                </Card>
            </FormProvider>
        </ApolloMutationBoundary>
    );
}
