import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetMachineIncomeMutationVariables = Types.Exact<{
    input: Types.ResetMachineIncomeInput;
}>;

export type ResetMachineIncomeMutation = { __typename?: 'Mutation'; reset_machine_income: Types.SuccessResponse };

export const ResetMachineIncomeDocument = gql`
    mutation resetMachineIncome($input: ResetMachineIncomeInput!) {
        reset_machine_income(input: $input)
    }
`;
export type ResetMachineIncomeMutationFn = Apollo.MutationFunction<
    ResetMachineIncomeMutation,
    ResetMachineIncomeMutationVariables
>;

/**
 * __useResetMachineIncomeMutation__
 *
 * To run a mutation, you first call `useResetMachineIncomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetMachineIncomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetMachineIncomeMutation, { data, loading, error }] = useResetMachineIncomeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetMachineIncomeMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetMachineIncomeMutation, ResetMachineIncomeMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResetMachineIncomeMutation, ResetMachineIncomeMutationVariables>(
        ResetMachineIncomeDocument,
        options,
    );
}
export type ResetMachineIncomeMutationHookResult = ReturnType<typeof useResetMachineIncomeMutation>;
export type ResetMachineIncomeMutationResult = Apollo.MutationResult<ResetMachineIncomeMutation>;
export type ResetMachineIncomeMutationOptions = Apollo.BaseMutationOptions<
    ResetMachineIncomeMutation,
    ResetMachineIncomeMutationVariables
>;
