import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

export type NotActivatedStatusProps = {
    isMinW?: boolean;
};
export default function NotActivatedStatus(props: NotActivatedStatusProps) {
    const { isMinW = false } = props;
    const { t } = useTranslation(['common']);
    const textColor = useColorModeValue('#2B3674', 'white');
    const minWStyle = isMinW ? { minWidth: '150px' } : {};
    return (
        <Flex align="center" justifyContent="flex-start" style={{ ...minWStyle }}>
            <Icon as={IoMdCheckmarkCircle} color="gray.500" width="24px" height="24px" mr={1} />
            <Text color={textColor} fontSize="14px" fontWeight="700">
                {t('notActivatedLabel', { ns: 'common' })}
            </Text>
        </Flex>
    );
}
