import { Button, Flex, useColorModeValue } from '@chakra-ui/react';

import { useDropzone } from 'react-dropzone';
import { UploadProps } from './types';
import AvatarPreview from './preview/AvatarPreview';
import { useTranslation } from 'react-i18next';

function UploadAvatar({ error, file, disabled, helperText, ...rest }: UploadProps) {
    const { t } = useTranslation(['common']);
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        disabled,
        ...rest,
    });
    const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
    return (
        <Flex
            align="center"
            justify="center"
            borderColor={borderColor}
            borderRadius="16px"
            w="100%"
            h="max-content"
            minH="100%"
            cursor="pointer"
            {...getRootProps({ className: 'dropzone' })}
        >
            <input {...getInputProps()} />
            <AvatarPreview file={file} />
            <Button
                colorScheme="navy"
                variant="outline"
                p="15px 40px"
                fontSize="sm"
                h="40px"
                w="120px"
                borderRadius={10}
                fontWeight="500"
                ms="auto"
            >
                {t('uploadPhotoLabel', { ns: 'common' })}
            </Button>
        </Flex>
    );
}

export default UploadAvatar;
