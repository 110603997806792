// noinspection DuplicatedCode

import {
    Box,
    Button,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { useMemo, useState } from 'react';
import Card from '../../../../../../components/card/Card';
import { useTranslation } from 'react-i18next';
import { MachineDetailType } from '../MachineDetailType';
import { isEmpty } from 'lodash';
import {
    defaultAiGameSettingValue,
    defaultCricketGameSettingValue,
    defaultPartyGameSettingValue,
    defaultPracticeGameSettingValue,
    defaultZeroOneGameSettingValue,
} from '../variables';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useEditMachineGameSettingMutation } from 'graphql/mutations/dealer/__generated__/editMachineGameSetting.generated';
import FormProvider from 'components/hook-form';
import { useForm } from 'react-hook-form';
import { GetMachineByIdDocument } from 'graphql/queries/machine/__generated__/getMachineById.generated';
import CommonGameSettingTable from './CommonGameSettingTable';
import {
    getAiSettingFromParsedData,
    getCricketSettingFromParsedData,
    getPartySettingFromParsedData,
    getPracticeSettingFromParsedData,
    getZeroOneSettingFromParsedData,
    isArrayOfGameSettingObj,
    transferGameSettingObjToOriginalJsonSetting,
} from './ganeUtil';
import RHFSwitch from '../../../../../../components/hook-form/RHFSwitch';
import { useAuthContext } from '../../../../../../layouts/auth/useAuthContext';
import { AccountType } from '../../../../../../types/user';
import GranProEditBtn from '../../../../../../components/button/GranProEditBtn';
import { useEditable } from '../../../../../../components/hooks/useEditable';

export type GameSettingObj = {
    gameType: Game;
    gameName: number;
    round: number;
    credit: number;
};

export default function GameSettingTable(props: MachineDetailType) {
    const [tabState, setTabState] = useState('01');
    const { accountType } = useAuthContext();
    const { machine } = props;
    const textColor = useColorModeValue('#4318FF', 'white');
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const textColorTertiary = useColorModeValue('secondaryGray.600', 'secondaryGray.500');
    const textColorMode = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common', 'machine']);
    const {
        isOpen: isOpenEditGameSettingMutationResult,
        onOpen: onOpenEditGameSettingMutationResult,
        onClose: onCloseEditGameSettingMutationResult,
    } = useDisclosure();

    const defaultValues = useMemo(() => {
        const jsonObj = isEmpty(machine.gameSetting) ? {} : machine.gameSetting;
        let freePlay = false;
        let zeroOneGameSettingValue = defaultZeroOneGameSettingValue as GameSettingObj[];
        let cricketGameSettingValue = defaultCricketGameSettingValue as GameSettingObj[];
        let practiceGameSettingValue = defaultPracticeGameSettingValue as GameSettingObj[];
        let aiGameSettingValue = defaultAiGameSettingValue as GameSettingObj[];
        let partyGameSettingValue = defaultPartyGameSettingValue as GameSettingObj[];

        if (isEmpty(jsonObj)) {
            return {
                freePlay,
                zeroOneGameSettingValue,
                cricketGameSettingValue,
                practiceGameSettingValue,
                aiGameSettingValue,
                partyGameSettingValue,
            };
        } else {
            freePlay = jsonObj?.freePlay && typeof jsonObj?.freePlay === 'boolean' ? jsonObj?.freePlay : false;

            const parsedCreditAndRoundSettingData = jsonObj?.creditAndRoundSetting || null;
            if (isArrayOfGameSettingObj(parsedCreditAndRoundSettingData)) {
                zeroOneGameSettingValue = getZeroOneSettingFromParsedData(parsedCreditAndRoundSettingData);
                cricketGameSettingValue = getCricketSettingFromParsedData(parsedCreditAndRoundSettingData);
                practiceGameSettingValue = getPracticeSettingFromParsedData(parsedCreditAndRoundSettingData);
                aiGameSettingValue = getAiSettingFromParsedData(parsedCreditAndRoundSettingData);
                partyGameSettingValue = getPartySettingFromParsedData(parsedCreditAndRoundSettingData);
            } else {
                console.log('NOT RIGHT FORMAT');
            }
            return {
                freePlay,
                zeroOneGameSettingValue,
                cricketGameSettingValue,
                practiceGameSettingValue,
                aiGameSettingValue,
                partyGameSettingValue,
            };
        }
    }, [machine]);

    const { isEdit, onChangeEditable, onEditDisable, ...other } = useEditable(false);

    const [editMachineGameSettingFunc, editMachineGameSettingMutationResult] = useEditMachineGameSettingMutation();

    const methods = useForm({
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        watch,
        reset,
    } = methods;
    const values = watch();

    const onHandleEditMachineGameSetting = async () => {
        const transferSetting = {
            freePlay: values.freePlay,
            creditAndRoundSetting: transferGameSettingObjToOriginalJsonSetting([
                ...values.zeroOneGameSettingValue,
                ...values.cricketGameSettingValue,
                ...values.practiceGameSettingValue,
                ...values.aiGameSettingValue,
                ...values.partyGameSettingValue,
            ]),
        };
        try {
            await editMachineGameSettingFunc({
                variables: {
                    input: {
                        machineIds: [machine.id],
                        setting: transferSetting,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onEditDisable();
                        onOpenEditGameSettingMutationResult();
                    }
                },
                onError: async () => {
                    onOpenEditGameSettingMutationResult();
                },
                refetchQueries: () => [
                    {
                        query: GetMachineByIdDocument,
                        variables: {
                            input: {
                                machineId: machine.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };
    const handleSuccessClose = () => {
        window.location.reload();
    };

    const onHandleCancel = () => {
        onEditDisable();
        reset();
    };
    return (
        <ApolloMutationBoundary
            isOpen={isOpenEditGameSettingMutationResult}
            onClose={onCloseEditGameSettingMutationResult}
            onOpen={onOpenEditGameSettingMutationResult}
            onExtraAfterSuccessHandler={handleSuccessClose}
            mutationResult={editMachineGameSettingMutationResult}
            toastSuccessTitle={t('editMachineSuccessTitle', { ns: 'machine' })}
            toastSuccessDescription={t('editMachineSuccessMessage', { ns: 'machine' })}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onHandleEditMachineGameSetting)}>
                <Card pt={8} pb={1} h={'100%'} px={{ base: '20px', lg: '30px', '2xl': '30px' }}>
                    <Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                        <Flex
                            align={{ sm: 'flex-start', lg: 'center' }}
                            justify="space-between"
                            w="100%"
                            boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
                        >
                            <Text color={textColorPrimary} fontSize="20px" fontWeight="600">
                                {t('gameSettingLabel', { ns: 'machine' })}
                            </Text>
                            {accountType === AccountType.dealer && (
                                <GranProEditBtn
                                    isEdit={isEdit}
                                    onChangeEditable={onChangeEditable}
                                    disabled={accountType !== AccountType.dealer}
                                    onEditDisable={onEditDisable}
                                    onExtraHandleCancel={onHandleCancel}
                                    {...other}
                                />
                            )}
                        </Flex>
                        <Box pb={3}>
                            <Flex direction="row" justifyContent="flex-start" alignItems="center" my={2}>
                                <Box minW="100px">
                                    <Text fontSize="14px" color={textColorSecondary} fontWeight={500} mr={3}>
                                        {t('freePlay', { ns: 'common' })}
                                    </Text>
                                </Box>
                                {isEdit ? (
                                    <RHFSwitch
                                        colorScheme="brand"
                                        isChecked={values.freePlay}
                                        name="freePlay"
                                        isRequired={false}
                                    />
                                ) : (
                                    <Text color={textColorMode} fontSize="16px" fontWeight="500">
                                        {values.freePlay ? 'ON' : 'OFF'}
                                    </Text>
                                )}
                            </Flex>
                            <Tabs
                                variant="soft-rounded"
                                colorScheme="brandTabs"
                                mb={isEdit ? 1 : { base: 3, md: 3, '2xl': 4 }}
                            >
                                <TabList overflowX={{ base: 'scroll', lg: 'unset' }}>
                                    <Flex gap={{ base: 0, md: 4, xl: 5, '2xl': 10 }}>
                                        <Tab
                                            pb="0px"
                                            flexDirection="column"
                                            onClick={function () {
                                                setTabState('01');
                                            }}
                                            bg="unset"
                                            _selected={{
                                                bg: 'none',
                                            }}
                                            _focus={{ border: 'none' }}
                                            minW="max-content"
                                        >
                                            <Flex align="center">
                                                <Text
                                                    color={tabState === '01' ? textColor : textColorTertiary}
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                >
                                                    01
                                                </Text>
                                            </Flex>
                                            <Box
                                                height="4px"
                                                w="100%"
                                                transition="0.1s linear"
                                                bg={tabState === '01' ? 'brand.500' : 'transparent'}
                                                mt="5px"
                                                borderRadius="30px"
                                            />
                                        </Tab>
                                        <Tab
                                            pb="0px"
                                            flexDirection="column"
                                            onClick={function () {
                                                setTabState('cricket');
                                            }}
                                            bg="unset"
                                            _selected={{
                                                bg: 'none',
                                            }}
                                            _focus={{ border: 'none' }}
                                            minW="max-content"
                                        >
                                            <Flex align="center">
                                                <Text
                                                    color={tabState === 'cricket' ? textColor : textColorTertiary}
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                >
                                                    Cricket
                                                </Text>
                                            </Flex>
                                            <Box
                                                height="4px"
                                                w="100%"
                                                transition="0.1s linear"
                                                bg={tabState === 'cricket' ? 'brand.500' : 'transparent'}
                                                mt="5px"
                                                borderRadius="30px"
                                            />
                                        </Tab>

                                        <Tab
                                            pb="0px"
                                            flexDirection="column"
                                            onClick={function () {
                                                setTabState('practice');
                                            }}
                                            bg="unset"
                                            _selected={{
                                                bg: 'none',
                                            }}
                                            _focus={{ border: 'none' }}
                                            minW="max-content"
                                        >
                                            <Flex align="center">
                                                <Text
                                                    color={tabState === 'practice' ? textColor : textColorTertiary}
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                >
                                                    Practice
                                                </Text>
                                            </Flex>
                                            <Box
                                                height="4px"
                                                w="100%"
                                                transition="0.1s linear"
                                                bg={tabState === 'practice' ? 'brand.500' : 'transparent'}
                                                mt="5px"
                                                borderRadius="30px"
                                            />
                                        </Tab>
                                        <Tab
                                            onClick={function () {
                                                setTabState('ai');
                                            }}
                                            pb="0px"
                                            bg="unset"
                                            _selected={{
                                                bg: 'none',
                                            }}
                                            _focus={{ border: 'none' }}
                                            minW="max-content"
                                            flexDirection="column"
                                        >
                                            <Flex align="center">
                                                <Text
                                                    color={tabState === 'ai' ? textColor : textColorTertiary}
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                >
                                                    AI
                                                </Text>
                                            </Flex>
                                            <Box
                                                height="4px"
                                                w="100%"
                                                transition="0.1s linear"
                                                bg={tabState === 'ai' ? 'brand.500' : 'transparent'}
                                                mt="5px"
                                                borderRadius="30px"
                                            />
                                        </Tab>
                                        <Tab
                                            pb="0px"
                                            flexDirection="column"
                                            onClick={function () {
                                                setTabState('party');
                                            }}
                                            bg="unset"
                                            _selected={{
                                                bg: 'none',
                                            }}
                                            _focus={{ border: 'none' }}
                                            minW="max-content"
                                        >
                                            <Flex align="center">
                                                <Text
                                                    color={tabState === 'party' ? textColor : textColorTertiary}
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                >
                                                    Party
                                                </Text>
                                            </Flex>
                                            <Box
                                                height="4px"
                                                w="100%"
                                                transition="0.1s linear"
                                                bg={tabState === 'party' ? 'brand.500' : 'transparent'}
                                                mt="5px"
                                                borderRadius="30px"
                                            />
                                        </Tab>
                                    </Flex>
                                </TabList>

                                <TabPanels pt={0}>
                                    <TabPanel px="0px">
                                        <CommonGameSettingTable
                                            isEdit={isEdit}
                                            setIsEdit={onChangeEditable}
                                            defaultData={defaultValues.zeroOneGameSettingValue}
                                            tabValue="zeroOneGameSettingValue"
                                        />
                                    </TabPanel>
                                    <TabPanel px="0px">
                                        <CommonGameSettingTable
                                            isEdit={isEdit}
                                            setIsEdit={onChangeEditable}
                                            defaultData={defaultValues.cricketGameSettingValue}
                                            tabValue="cricketGameSettingValue"
                                        />
                                    </TabPanel>
                                    <TabPanel px="0px">
                                        <CommonGameSettingTable
                                            isEdit={isEdit}
                                            setIsEdit={onChangeEditable}
                                            defaultData={defaultValues.practiceGameSettingValue}
                                            tabValue="practiceGameSettingValue"
                                        />
                                    </TabPanel>
                                    <TabPanel px="0px">
                                        <CommonGameSettingTable
                                            isEdit={isEdit}
                                            setIsEdit={onChangeEditable}
                                            defaultData={defaultValues.aiGameSettingValue}
                                            tabValue="aiGameSettingValue"
                                        />
                                    </TabPanel>
                                    <TabPanel px="0px">
                                        <CommonGameSettingTable
                                            isEdit={isEdit}
                                            setIsEdit={onChangeEditable}
                                            defaultData={defaultValues.partyGameSettingValue}
                                            tabValue="partyGameSettingValue"
                                        />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>

                            {isEdit && (
                                <Box
                                    display="flex"
                                    flexDirection={{ base: 'row' }}
                                    justifyContent="center"
                                    w="100%"
                                    py={5}
                                >
                                    <Button
                                        py="10px"
                                        px="8px"
                                        variant="brand"
                                        fontWeight="500"
                                        minW="140px"
                                        maxH="40px"
                                        borderRadius="10px"
                                        backgroundColor="#4318FF"
                                        type="submit"
                                        isLoading={isSubmitting}
                                    >
                                        <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Flex>
                </Card>
            </FormProvider>
        </ApolloMutationBoundary>
    );
}
