import { Flex } from '@chakra-ui/react';
import GameSettingTable from './GameSettingTable';
import { MachineDetailType } from '../MachineDetailType';
import EntryPlayerList from './EntryPlayerList';
import TimeChargeCard from './TimeChargeCard';

export default function GameInfo(props: MachineDetailType) {
    const { machine } = props;
    return (
        <Flex direction="column" gap={5}>
            <GameSettingTable machine={machine} />
            <EntryPlayerList entryPlayers={machine.playerEntries} machineId={machine.id} />
            <TimeChargeCard machine={machine} />
        </Flex>
    );
}
