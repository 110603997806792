import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../localization/en';
import ja from '../localization/ja';
import de from '../localization/de';

const nsList: string[] = ['common', 'dashboard', 'dealer', 'signIn', 'activation', 'machine', 'shop', 'error'];

const resources = {
    en,
    ja,
    de,
};

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
        resources,
        ns: nsList,
        interpolation: {
            escapeValue: false,
        },
        debug: false,
        fallbackLng: 'en',
        saveMissing: true,
        react: {
            useSuspense: true,
        },
    })
    .catch((err) => console.error(`i18n initialize failed: ${err}`));

export default i18next;
