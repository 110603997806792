import {
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    useColorModeValue,
    useDisclosure,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useTranslation } from 'react-i18next';
import { useCreateShopIncomeCollectionEventMutation } from 'graphql/mutations/shop/__generated__/createShopIncomeCollectionEvent.generated';
import { PickValueOf } from 'types/common';
import { fYyyyMm } from '../../../../../../utils/format-time';
import FinalResetButton from './FinalResetButton';
import { ApolloQueryBoundary } from '../../../../../../components/graphql/ApolloQueryBoundary';
import {
    GetLastFiveShopIncomeCollectionEventsQuery,
    useGetLastFiveShopIncomeCollectionEventsLazyQuery,
} from '../../../../../../graphql/queries/shop/__generated__/getLastFiveShopIncomeCollectionEvents.generated';
import FormProvider, { RHFTextField } from '../../../../../../components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useResetMachineIncomeMutation } from '../../../../../../graphql/mutations/shop/__generated__/resetMachineIncome.generated';
import { ModalApolloMutationError } from '../../../../../../components/graphql/DefaultApolloMutationError';
import { ApolloError } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH_DASHBOARD } from '../../../../../../paths';

type ShopIncomeCollectionEventModalProps = UseDisclosureProps & {
    shopId: number;
    shopName: string;
    machineId: number;
    machineName: string;
    [key: string]: any;
};

type createCollectionValueProps = {
    collectionEventName: string;
};

export default function ShopIncomeCollectionEventModal(props: ShopIncomeCollectionEventModalProps) {
    const { isOpen, onClose, btnColorScheme = 'navy', shopId, shopName, machineId, machineName } = props;
    const textColor = useColorModeValue('#000000', 'white');
    const [collectionMode, setCollectionMode] = useState<'create' | 'getLast5' | null>(null);
    const [mutationError, setMutationError] = useState<ApolloError | null>(null);
    const { t } = useTranslation(['common']);
    const {
        isOpen: isOpenGetLast5CollectionResult,
        onOpen: onOpenGetLast5CollectionResult,
        onClose: onCloseGetLast5CollectionResult,
    } = useDisclosure();

    const {
        isOpen: isOpenCreateNewCollectionResult,
        onOpen: onOpenCreateNewCollectionResult,
        onClose: onCloseCreateNewCollectionResult,
    } = useDisclosure();

    const {
        isOpen: isOpenSpecificErrorMutation,
        onOpen: onOpenSpecificErrorMutation,
        onClose: onCloseSpecificErrorMutation,
    } = useDisclosure();

    const {
        isOpen: isOpenAfterCreateNewCollectionAndResetResult,
        onOpen: onOpenAfterCreateNewCollectionAndResetResult,
        onClose: onCloseAfterCreateNewCollectionAndResetResult,
    } = useDisclosure();

    const [createShopIncomeCollectionEventMutationFunc, createShopIncomeCollectionEventMutationResult] =
        useCreateShopIncomeCollectionEventMutation();

    const [getLastFiveShopIncomeCollectionEventsLazyQueryFunc, getLastFiveShopIncomeCollectionEventsLazyQueryResult] =
        useGetLastFiveShopIncomeCollectionEventsLazyQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
        });

    const [resetMachineIncomeMutationFunc, resetMachineIncomeMutationResult] = useResetMachineIncomeMutation();
    const onHandleGetLast5Collections = async () => {
        try {
            await getLastFiveShopIncomeCollectionEventsLazyQueryFunc({
                variables: {
                    input: {
                        shopId,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenGetLast5CollectionResult();
                    }
                },
                onError: async () => {
                    onOpenGetLast5CollectionResult();
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickGetLast5Collections = async () => {
        setCollectionMode && setCollectionMode('getLast5');
        await onHandleGetLast5Collections();
    };

    const closeModalHandler = () => {
        setCollectionMode(null);
        onClose();
    };

    const CreateCollectionSchema = Yup.object().shape({
        collectionEventName: Yup.string().required(`${t('collectionEventNameRequiredMessage', { ns: 'common' })}`),
    });

    const defaultValues = useMemo(
        () => ({
            collectionEventName: `${fYyyyMm(new Date())} ${shopName}`,
        }),
        [machineName],
    );
    const methods = useForm({
        resolver: yupResolver(CreateCollectionSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        watch,
        reset,
    } = methods;
    const values = watch();
    const onCancelCreateCollectionModalHandler = () => {
        setCollectionMode(null);
        onClose();
        reset();
    };
    const onHandleCreateNewCollectionAndReset = async (data: createCollectionValueProps) => {
        try {
            await createShopIncomeCollectionEventMutationFunc({
                variables: {
                    input: {
                        shopId,
                        eventName: data.collectionEventName,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        await resetMachineIncomeMutationFunc({
                            variables: {
                                input: {
                                    machineId,
                                    shopIncomeCollectionEventId: res.create_shop_income_collection_event.id,
                                },
                            },
                            onCompleted: async (res) => {
                                if (res) {
                                    onOpenAfterCreateNewCollectionAndResetResult();
                                }
                            },
                            onError: async () => {
                                onOpenCreateNewCollectionResult();
                            },
                        });
                    }
                },
                onError: async (error) => {
                    setMutationError(error);
                    onOpenSpecificErrorMutation();
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickCreateNewCollection = async () => {
        setCollectionMode && setCollectionMode('create');
        // await onHandleCreateShopIncomeCollectionEvent();
    };

    return (
        <div>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModalHandler} size="3xl" isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody w="100%" pt={4} pb={5} minH={'360px'} backgroundColor="#00054">
                        {!collectionMode && (
                            <Flex
                                align="center"
                                direction="column"
                                justifyContent="center"
                                w="100%"
                                minH={'340px'}
                                gap="60px"
                            >
                                <VStack gap={2}>
                                    <Text
                                        color={textColor}
                                        fontSize={{ base: '14px', lg: '16px' }}
                                        fontWeight="500"
                                        lineHeight="100%"
                                    >
                                        {t('getCollectionMessage1', { ns: 'common' })}
                                    </Text>
                                    <Text
                                        color={textColor}
                                        fontSize={{ base: '14px', lg: '16px' }}
                                        fontWeight="500"
                                        lineHeight="100%"
                                    >
                                        {t('getCollectionMessage2', { ns: 'common' })}
                                    </Text>
                                </VStack>

                                <HStack gap="30px">
                                    <Button
                                        colorScheme={btnColorScheme}
                                        variant="outline"
                                        fontSize="sm"
                                        borderRadius="10px"
                                        borderWidth={2}
                                        fontWeight="700"
                                        ms="auto"
                                        minW="160px"
                                        onClick={handleClickGetLast5Collections}
                                    >
                                        {t('getLast5CollectionsBtn', { ns: 'common' })}
                                    </Button>

                                    <Button
                                        colorScheme={btnColorScheme}
                                        variant="outline"
                                        fontSize="sm"
                                        borderRadius="10px"
                                        borderWidth={2}
                                        fontWeight="700"
                                        ms="auto"
                                        minW="160px"
                                        onClick={handleClickCreateNewCollection}
                                    >
                                        {t('createNewCollectionBtn', { ns: 'common' })}
                                    </Button>
                                </HStack>
                            </Flex>
                        )}
                        {collectionMode === 'create' && (
                            <div>
                                <ApolloMutationBoundary
                                    isOpen={isOpenCreateNewCollectionResult}
                                    onClose={onCloseCreateNewCollectionResult}
                                    onOpen={onOpenCreateNewCollectionResult}
                                    // onExtraAfterSuccessHandler={handleSuccessClose}
                                    mutationResult={
                                        resetMachineIncomeMutationResult ??
                                        createShopIncomeCollectionEventMutationResult
                                    }
                                    toastSuccessTitle="Create new collection and reset"
                                    toastSuccessDescription={`Collection ${values.collectionEventName} is created and reset successfully`}
                                >
                                    <FormProvider
                                        methods={methods}
                                        onSubmit={handleSubmit(onHandleCreateNewCollectionAndReset)}
                                    >
                                        <Flex
                                            align="center"
                                            direction="column"
                                            justifyContent="center"
                                            w="100%"
                                            minH={'340px'}
                                            gap="60px"
                                        >
                                            <VStack w="100%" gap="18px">
                                                <Text
                                                    color={textColor}
                                                    fontSize={{ base: '14px', lg: '16px' }}
                                                    fontWeight="500"
                                                    lineHeight="100%"
                                                >
                                                    {t('createCollectionAndAddIncomeMessage', { ns: 'common' })}
                                                </Text>
                                                <HStack>
                                                    <Box>
                                                        <Text
                                                            color={textColor}
                                                            fontSize={{ base: '14px', lg: '16px' }}
                                                            fontWeight="500"
                                                            lineHeight="100%"
                                                        >
                                                            {t('collectionNameLabel', { ns: 'common' })}
                                                        </Text>
                                                    </Box>
                                                    <Box>
                                                        <RHFTextField
                                                            name="collectionEventName"
                                                            isRequired={false}
                                                            borderRadius={'10px !important'}
                                                            fontWeight="500"
                                                            variant="main"
                                                            // maxW={'240px'}
                                                            _placeholder={{
                                                                fontWeight: '400',
                                                                color: 'secondaryGray.600',
                                                            }}
                                                            maxH={'40px'}
                                                        />
                                                    </Box>
                                                </HStack>
                                            </VStack>

                                            <HStack gap="30px">
                                                <Button
                                                    colorScheme={btnColorScheme}
                                                    variant="outline"
                                                    fontSize="sm"
                                                    borderRadius="10px"
                                                    borderWidth={2}
                                                    fontWeight="700"
                                                    ms="auto"
                                                    minW="160px"
                                                    onClick={onCancelCreateCollectionModalHandler}
                                                >
                                                    {t('cancelBtn', { ns: 'common' })}
                                                </Button>

                                                <Button
                                                    colorScheme={btnColorScheme}
                                                    variant="solid"
                                                    fontSize="sm"
                                                    borderRadius="10px"
                                                    // borderWidth={2}
                                                    fontWeight="700"
                                                    ms="auto"
                                                    minW="160px"
                                                    type="submit"
                                                    isLoading={isSubmitting}
                                                >
                                                    OK
                                                </Button>
                                            </HStack>
                                        </Flex>
                                    </FormProvider>
                                </ApolloMutationBoundary>
                            </div>
                        )}
                        {collectionMode === 'getLast5' && (
                            <div>
                                <ApolloQueryBoundary
                                    isOpen={isOpenGetLast5CollectionResult}
                                    onClose={onCloseGetLast5CollectionResult}
                                    onOpen={onOpenGetLast5CollectionResult}
                                    queryResult={getLastFiveShopIncomeCollectionEventsLazyQueryResult}
                                    render={({ data: { get_last_five_shop_income_collection_events } }) => (
                                        <Flex
                                            direction="column"
                                            align="center"
                                            // justifyContent="space-between"
                                            gap={10}
                                            w="100%"
                                            paddingY={8}
                                        >
                                            <HStack>
                                                <Text
                                                    color={textColor}
                                                    fontSize={{ base: '14px', lg: '18px' }}
                                                    fontWeight="500"
                                                    lineHeight="100%"
                                                >
                                                    {t('last5CollectionsHeading', { ns: 'common' })}
                                                </Text>
                                            </HStack>
                                            <MultipleCollectionCard
                                                machineId={machineId}
                                                last5Collections={get_last_five_shop_income_collection_events}
                                            />
                                        </Flex>
                                    )}
                                />
                            </div>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
            <AfterCreateAndResetCollectionModal
                handleClickGetLast5Collections={handleClickGetLast5Collections}
                isOpen={isOpenAfterCreateNewCollectionAndResetResult}
                onOpen={onOpenAfterCreateNewCollectionAndResetResult}
                onClose={onCloseAfterCreateNewCollectionAndResetResult}
                onCloseMainModal={onClose}
                onResetCollectionMode={setCollectionMode}
                shopId={shopId}
            />
            {mutationError !== null && (
                <ModalApolloMutationError
                    name={mutationError.name}
                    message={mutationError.message}
                    graphQLErrors={mutationError.graphQLErrors}
                    protocolErrors={mutationError.protocolErrors}
                    clientErrors={mutationError.clientErrors}
                    networkError={mutationError.networkError}
                    extraInfo={mutationError.extraInfo}
                    {...{
                        error: mutationError,
                        isOpen: isOpenSpecificErrorMutation,
                        onOpen: onOpenSpecificErrorMutation,
                        onClose: onCloseSpecificErrorMutation,
                    }}
                />
            )}
        </div>
    );
}

type AfterCreateAndResetCollectionModalProps = UseDisclosureProps & {
    handleClickGetLast5Collections: () => void;
    onCloseMainModal: () => void;
    onResetCollectionMode: (val: 'create' | 'getLast5' | null) => void;
    shopId: number;
};
const AfterCreateAndResetCollectionModal = (props: AfterCreateAndResetCollectionModalProps) => {
    const history = useHistory();
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    const { isOpen, onClose, onCloseMainModal, onResetCollectionMode, shopId } = props;
    const closeAfterCreateAndResetCollectionModalHandler = () => {
        onClose();
        onCloseMainModal();
        onResetCollectionMode(null);
    };

    const onCloseAndGetLast5Collections = () => {
        onClose();
        onCloseMainModal();
        onResetCollectionMode(null);
        history.push(ROUTE_PATH_DASHBOARD.income.detail(shopId, 'collection-date'));
        window.location.reload();
    };
    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={closeAfterCreateAndResetCollectionModalHandler}
            size="3xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody w="100%" pt={4} pb={5} minH={'360px'}>
                    <Flex align="center" direction="column" justifyContent="center" w="100%" minH={'340px'} gap={10}>
                        <VStack>
                            <Text
                                color={textColor}
                                fontSize={{ base: '14px', lg: '16px' }}
                                fontWeight="500"
                                lineHeight="100%"
                            >
                                {t('createCollectionAndResetFinishMessage', { ns: 'common' })}
                            </Text>
                        </VStack>

                        <HStack>
                            <Button
                                colorScheme="navy"
                                variant="outline"
                                fontSize="sm"
                                borderRadius="10px"
                                borderWidth={2}
                                fontWeight="700"
                                ms="auto"
                                minW="160px"
                                onClick={closeAfterCreateAndResetCollectionModalHandler}
                            >
                                {t('closeBtnLabel', { ns: 'common' })}
                            </Button>

                            <Button
                                colorScheme="navy"
                                variant="outline"
                                fontSize="sm"
                                borderRadius="10px"
                                borderWidth={2}
                                fontWeight="700"
                                ms="auto"
                                minW="160px"
                                onClick={onCloseAndGetLast5Collections}
                            >
                                {t('viewCollectionHistoryBtnLabel', { ns: 'common' })}
                            </Button>
                        </HStack>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

type MultipleCollectionCardProps = {
    machineId: number;
    last5Collections: PickValueOf<
        GetLastFiveShopIncomeCollectionEventsQuery,
        'get_last_five_shop_income_collection_events'
    >;
};
const MultipleCollectionCard = (props: MultipleCollectionCardProps) => {
    const textColor = useColorModeValue('#2B3674', 'white');
    const { last5Collections, machineId } = props;
    return (
        <Flex direction="column" gap={5} align="center" justifyContent="space-between" w="100%" paddingX={2}>
            {last5Collections.map((collection, idx) => (
                <Grid
                    key={idx}
                    height="40px"
                    w="100%"
                    templateColumns="repeat(5, 1fr)"
                    gap={3}
                    alignItems="center"
                    gridGap={5}
                >
                    <GridItem colSpan={2} w="100%" h="10" display="flex" alignItems="center">
                        <Text
                            color={textColor}
                            fontSize={{ base: '14px', lg: '16px' }}
                            fontWeight="700"
                            lineHeight="100%"
                        >
                            {collection.eventName}
                        </Text>
                    </GridItem>
                    <GridItem w="100%" h="10" display="flex" alignItems="center" justifyContent="flex-end">
                        <Text
                            color={textColor}
                            fontSize={{ base: '14px', lg: '16px' }}
                            fontWeight="500"
                            lineHeight="100%"
                        >
                            {collection.automaticallyCollectedTotalCount}
                        </Text>
                    </GridItem>
                    <GridItem w="100%" h="10" display="flex" alignItems="center" justifyContent="flex-end" />
                    <GridItem w="100%" h="10" display="flex" alignItems="center" justifyContent="flex-end">
                        <FinalResetButton shopIncomeCollectionEventId={collection.id} machineId={machineId} />
                    </GridItem>
                </Grid>
            ))}
        </Flex>
    );
};
