// Chakra imports
import { Badge, Box, Flex, Grid, GridItem, Text, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useMemo } from 'react';
import { DealerDetailProps } from './dealerDetailTypes';
import { GranProSubscriptionPlan, MachineStatus } from 'types/graphql';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import * as Types from '../../../../../../types/graphql';

export type MachineShortFieldProps = {
    name: string;
    status: MachineStatus;
    plan: Types.GranProSubscriptionPlan | null;
    isOnline: boolean;
    shopName: string;
};

export default function MachineDetailCard(props: DealerDetailProps & { [x: string]: any }) {
    const { dealer, ...rest } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const { t } = useTranslation(['common']);

    const machineList = useMemo(() => {
        const arr: MachineShortFieldProps[] = [];
        // TODO: dealer info changed. update later by API
        dealer.shops.forEach((shop) => {
            shop.machines.forEach((machine) => {
                arr.push({
                    name: machine.machineName,
                    plan: machine.proKit?.subscriptionPlan,
                    status: machine.status,
                    isOnline: machine.isOnline,
                    shopName: shop.name,
                });
            });
        });
        return arr;
    }, [dealer]);
    return (
        <Card py={8} px={{ base: '20px', lg: '30px', xl: '40px', '2xl': '50px' }} borderRadius="30px" gap={6} {...rest}>
            <Flex direction="row" justifyContent="space-between" alignItems="center" w={'100%'}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                    {t('machines', { ns: 'common' })}
                </Text>
            </Flex>

            <Flex direction="column" align="center" w="100%" justifyContent="space-between">
                {machineList.length < 1 && (
                    <Box w="100%">
                        <Text color={textColorPrimary} fontSize={{ base: '14px', xl: '16px' }} fontWeight="500">
                            {t('noMachineLabel', { ns: 'common' })}
                        </Text>
                    </Box>
                )}
                {machineList.length > 0 && (
                    <Scrollbars style={{ height: '35vh' }}>
                        {machineList.map((machine, index) => (
                            <MachineDetailRow
                                key={index}
                                name={machine.name}
                                isOnline={machine.isOnline}
                                shopName={machine.shopName}
                                plan={machine.plan}
                            />
                        ))}
                    </Scrollbars>
                )}
            </Flex>
        </Card>
    );
}

function MachineDetailRow(props: {
    name: string;
    shopName: string;
    // status: MachineStatus;
    isOnline: boolean;
    plan: Types.GranProSubscriptionPlan | null;
    [key: string]: any;
}) {
    const { name, isOnline, plan, shopName, ...rest } = props;
    const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
    const textColorPrimary = useColorModeValue('navy.650', 'white');
    const planColor = useColorModeValue('navy.500', 'brand.100');
    const enterprisePlanScheme = useColorModeValue('purple', 'brand');
    const planScheme = useColorModeValue('navy', 'brand');
    const { t } = useTranslation(['common']);

    return (
        <Grid templateColumns="repeat(7, 1fr)" py={2}>
            <GridItem w="100%" colSpan={!isSmallScreen ? 3 : 4}>
                <Flex align="center" justify="flex-start">
                    <Text color={textColorPrimary} fontWeight="700" fontSize={{ base: '14px', xl: '16px' }}>
                        {name}
                    </Text>
                </Flex>
            </GridItem>
            {!isSmallScreen && (
                <GridItem w="100%" colSpan={3}>
                    <Flex align="center" justify="flex-start">
                        <Text color={textColorPrimary} fontWeight="700" fontSize={{ base: '14px', xl: '16px' }}>
                            {shopName}
                        </Text>
                    </Flex>
                </GridItem>
            )}

            <GridItem w="100%" colSpan={!isSmallScreen ? 1 : 3}>
                <Flex align="center" minH={10} justifyContent="center">
                    <Flex align="center">
                        {plan === GranProSubscriptionPlan.Enterprise ? (
                            <Badge
                                ms="auto"
                                borderRadius={'8px'}
                                justifyContent="center"
                                alignItems="center"
                                alignSelf="center"
                                textAlign="center"
                                h="28px"
                                w={{ base: '100px', xl: '90px' }}
                                colorScheme={enterprisePlanScheme}
                                color={'purple.600'}
                                fontSize="14px"
                                textTransform="capitalize"
                            >
                                {t('planOption3', { ns: 'common' })}
                            </Badge>
                        ) : (
                            <Badge
                                ms="auto"
                                borderRadius={'8px'}
                                justifyContent="center"
                                alignItems="center"
                                alignSelf="center"
                                textAlign="center"
                                h="28px"
                                w={{ base: '100px', xl: '90px' }}
                                colorScheme={planScheme}
                                color={planColor}
                                fontSize="14px"
                                textTransform="capitalize"
                            >
                                {t('planOption2', { ns: 'common' })}
                            </Badge>
                        )}
                    </Flex>
                </Flex>
            </GridItem>
        </Grid>
    );
}
