import { Controller, useFormContext } from 'react-hook-form';
import { UploadProps } from '../upload/types';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import UploadAvatar from '../upload/UploadAvatar';

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
    name: string;
    multiple?: boolean;
    isRequired?: boolean;
}

// ----------------------------------------------------------------------

function RHFUploadAvatar({ name, isRequired, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl isRequired={isRequired} isInvalid={!!error?.message}>
                    <UploadAvatar error={!!error} file={field.value} {...other} />

                    <FormErrorMessage sx={{ marginBottom: 2 }}>{error?.message}</FormErrorMessage>
                </FormControl>
            )}
        />
    );
}
export default RHFUploadAvatar;
