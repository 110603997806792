import { Box, Button, Center, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { isIntegerNumeric } from 'utils/format-number';
import { PATH_DASHBOARD } from 'paths';
import { AccountType } from '../../../../../types/user';
import { memo } from 'react';
import { ApolloError } from '@apollo/client';
import DealerShopDetail from './components/DealerShopDetail';
import DistributorShopDetail from './components/DistributorShopDetail';

export type ShopDetailParams = {
    id: string;
};

export default function ShopDetail() {
    const { id } = useParams<ShopDetailParams>();
    const { accountType } = useAuthContext();
    const history = useHistory();
    if (!isIntegerNumeric(id)) {
        history.push(PATH_DASHBOARD.shop.overview);
    }
    return (
        <Box pt={{ base: '40px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '50px', xl: '50px' }}>
            {accountType === AccountType.dealer && <DealerShopDetail />}
            {accountType === AccountType.distributor && <DistributorShopDetail />}
        </Box>
    );
}

const ShopDetailQueryErrorLoaded = memo((error: ApolloError) => {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    return (
        <Center my={'24px'}>
            <VStack spacing={'24px'}>
                {error.message.includes('Could not find shop with id') ? (
                    <Text fontSize="20px" lineHeight={'24px'} color={textColorPrimary} fontWeight="bold">
                        The Shop does not exist
                    </Text>
                ) : (
                    <>
                        <div>Something went wrong please try refresh screen</div>
                        <Button variant={'gray'} onClick={() => window.location.reload()}>
                            Refresh screen
                        </Button>
                    </>
                )}
            </VStack>
        </Center>
    );
});

ShopDetailQueryErrorLoaded.displayName = 'ShopDetailQueryErrorLoaded';
