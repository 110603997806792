import { Flex, FlexProps, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { ShopStatus } from '../../types/graphql';
import NotActivatedStatus from './NotActivatedStatus';
import EnableStatus from './EnableStatus';
import { resolverShopStatusToStr, resolverShopStatusToStrWithDisable } from '../../utils/resolver-enum';
import DisableStatus from './DisableStatus';
import OutOfServiceStatus from './OutOfServiceStatus';
import { useTranslation } from 'react-i18next';
import { MdOutlineStopScreenShare } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';

export type ShopStatusElementProps = {
    status: ShopStatus;
    isActivated: boolean;
    [key: string]: any;
};

export default function ShopStatusElement(props: ShopStatusElementProps) {
    const { status, isActivated, ...rest } = props;
    const { t } = useTranslation(['common']);
    return isActivated === false ? (
        <NotActivatedStatus />
    ) : (
        <Flex align="center" justifyContent="center" style={{ ...rest }}>
            {status === ShopStatus.Enabled && <EnableStatus enableTextValue={resolverShopStatusToStr(status, t)} />}
            {status === ShopStatus.Deleted && <DisableStatus disableTextValue={resolverShopStatusToStr(status, t)} />}
            {status === ShopStatus.OutOfService && (
                <OutOfServiceStatus outOfServiceTextValue={resolverShopStatusToStr(status, t)} />
            )}
        </Flex>
    );
}

export function ShopStatusElementWithDisable(props: ShopStatusElementProps) {
    const { status, isActivated, ...rest } = props;
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    return isActivated === false ? (
        <NotActivatedStatus />
    ) : (
        <Flex align="center" justifyContent="center" style={{ ...rest }}>
            {status === ShopStatus.Enabled && (
                <EnableStatus enableTextValue={resolverShopStatusToStrWithDisable(status, t)} />
            )}
            {status === ShopStatus.Deleted && (
                <DisableStatus disableTextValue={resolverShopStatusToStrWithDisable(status, t)} />
            )}
            {status === ShopStatus.OutOfService && (
                <Flex align="center">
                    <Icon as={IoMdCloseCircle} color="#EE5D50" width="20px" height="20px" mr={1} />
                    <Text color={textColor} fontSize="14px" fontWeight="500">
                        {resolverShopStatusToStrWithDisable(status, t)}
                    </Text>
                </Flex>
            )}
        </Flex>
    );
}
