import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProhibitShopLoginMutationVariables = Types.Exact<{
    input: Types.ProhibitShopLoginInput;
}>;

export type ProhibitShopLoginMutation = { __typename?: 'Mutation'; prohibit_shop_login: Types.SuccessResponse };

export const ProhibitShopLoginDocument = gql`
    mutation prohibitShopLogin($input: ProhibitShopLoginInput!) {
        prohibit_shop_login(input: $input)
    }
`;
export type ProhibitShopLoginMutationFn = Apollo.MutationFunction<
    ProhibitShopLoginMutation,
    ProhibitShopLoginMutationVariables
>;

/**
 * __useProhibitShopLoginMutation__
 *
 * To run a mutation, you first call `useProhibitShopLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProhibitShopLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prohibitShopLoginMutation, { data, loading, error }] = useProhibitShopLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProhibitShopLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<ProhibitShopLoginMutation, ProhibitShopLoginMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ProhibitShopLoginMutation, ProhibitShopLoginMutationVariables>(
        ProhibitShopLoginDocument,
        options,
    );
}
export type ProhibitShopLoginMutationHookResult = ReturnType<typeof useProhibitShopLoginMutation>;
export type ProhibitShopLoginMutationResult = Apollo.MutationResult<ProhibitShopLoginMutation>;
export type ProhibitShopLoginMutationOptions = Apollo.BaseMutationOptions<
    ProhibitShopLoginMutation,
    ProhibitShopLoginMutationVariables
>;
