import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ForceSetShopPasswordByDistributorMutationVariables = Types.Exact<{
    input: Types.ForceSetShopPasswordByDistributorInput;
}>;

export type ForceSetShopPasswordByDistributorMutation = {
    __typename?: 'Mutation';
    force_set_shop_password_by_distributor: Types.SuccessResponse;
};

export const ForceSetShopPasswordByDistributorDocument = gql`
    mutation forceSetShopPasswordByDistributor($input: ForceSetShopPasswordByDistributorInput!) {
        force_set_shop_password_by_distributor(input: $input)
    }
`;
export type ForceSetShopPasswordByDistributorMutationFn = Apollo.MutationFunction<
    ForceSetShopPasswordByDistributorMutation,
    ForceSetShopPasswordByDistributorMutationVariables
>;

/**
 * __useForceSetShopPasswordByDistributorMutation__
 *
 * To run a mutation, you first call `useForceSetShopPasswordByDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceSetShopPasswordByDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceSetShopPasswordByDistributorMutation, { data, loading, error }] = useForceSetShopPasswordByDistributorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForceSetShopPasswordByDistributorMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ForceSetShopPasswordByDistributorMutation,
        ForceSetShopPasswordByDistributorMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ForceSetShopPasswordByDistributorMutation,
        ForceSetShopPasswordByDistributorMutationVariables
    >(ForceSetShopPasswordByDistributorDocument, options);
}
export type ForceSetShopPasswordByDistributorMutationHookResult = ReturnType<
    typeof useForceSetShopPasswordByDistributorMutation
>;
export type ForceSetShopPasswordByDistributorMutationResult =
    Apollo.MutationResult<ForceSetShopPasswordByDistributorMutation>;
export type ForceSetShopPasswordByDistributorMutationOptions = Apollo.BaseMutationOptions<
    ForceSetShopPasswordByDistributorMutation,
    ForceSetShopPasswordByDistributorMutationVariables
>;
