import { Box, Button, Flex, HStack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ShopDetailProps } from '../shopDetailTypes';
import { ShopStatus } from 'types/graphql';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useLiftShopLoginProhibitionMutation } from 'graphql/mutations/dealer/__generated__/liftShopLoginProhibition.generated';
import { useProhibitShopLoginMutation } from 'graphql/mutations/dealer/__generated__/prohibitShopLogin.generated';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import { useTranslation } from 'react-i18next';
import { useEditable } from '../../../../../../components/hooks/useEditable';
import { ShopStatusElementWithDisable } from '../../../../../../components/elements/ShopStatusElement';
import ConfirmDisableShopDetailModal from './ConfirmDisableShopDetailModal';
import ConfirmDeleteShopDetailModal from './ConfirmDeleteShopDetailModal';
import { useDeleteShopMutation } from '../../../../../../graphql/mutations/dealer/__generated__/deleteShop.generated';
import { PATH_DASHBOARD } from '../../../../../../paths';
import { useHistory } from 'react-router-dom';
import GranProEditBtn from '../../../../../../components/button/GranProEditBtn';
import ChangePasswordProMachineControlFeature from './ChangePasswordProMachineControlFeature';
import UpdateIncomeConversionRateControlFeature from './UpdateIncomeConversionRateControlFeature';

export default function ShopControlCard(props: ShopDetailProps & { [x: string]: any }) {
    const { shop, ...rest } = props;
    const { accountType } = useAuthContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { t } = useTranslation(['common']);
    const { isEdit, onChangeEditable, onEditDisable, ...other } = useEditable(false);

    return (
        <Card py="30px" px={{ base: '20px', xl: '50px' }} borderRadius={'30px'} {...rest}>
            <Flex direction={'row'} justifyContent="space-between" alignItems="stretch" w={'100%'}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                    {t('control', { ns: 'common' })}
                </Text>
                <GranProEditBtn
                    isEdit={isEdit}
                    onChangeEditable={onChangeEditable}
                    disabled={accountType !== AccountType.dealer}
                    onEditDisable={onEditDisable}
                    {...other}
                />
            </Flex>
            <Flex
                alignItems="stretch"
                direction="column"
                mb={{ base: '20px' }}
                gap={5}
                justifyContent="center"
                w="100%"
                mt={5}
            >
                <ChangePasswordProMachineControlFeature shop={shop} isEdit={isEdit} onEditDisable={onEditDisable} />

                <UpdateIncomeConversionRateControlFeature shop={shop} isEdit={isEdit} onEditDisable={onEditDisable} />

                {accountType === AccountType.dealer && (
                    <ControlFeature
                        shop={shop}
                        isDisabled={!isEdit}
                        btnName={
                            shop.status === ShopStatus.Enabled
                                ? `${t('disable', { ns: 'common' })}`
                                : `${t('enable', { ns: 'common' })}`
                        }
                        btnColorScheme={shop.status === ShopStatus.Enabled ? 'red' : 'green'}
                    />
                )}
            </Flex>
        </Card>
    );
}

type ControlFeatureProps = ShopDetailProps & {
    btnName: string;
    btnColorScheme?: string;
    isDisabled: boolean;
    [key: string]: any;
};
function ControlFeature(props: ControlFeatureProps) {
    const { shop, btnName, isDisabled } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const disableBtnColorPrimary = '#D11828';
    const enableBtnColorPrimary = '#01B574';
    const history = useHistory();
    const {
        isOpen: isOpenConfirmDeleteShop,
        onOpen: onOpenConfirmDeleteShop,
        onClose: onCloseConfirmDeleteShop,
    } = useDisclosure();
    const textColor = useColorModeValue('secondaryGray.600', 'white');
    const { t } = useTranslation(['common', 'shop']);
    const {
        isOpen: isOpenMutationBoundaryResult,
        onOpen: onOpenMutationBoundaryResult,
        onClose: onCloseMutationBoundaryResult,
    } = useDisclosure();
    const [liftShopLoginFunc, liftShopLoginProhibitionMutationResult] = useLiftShopLoginProhibitionMutation();
    const [prohibitShopLoginFunc, prohibitShopLoginResult] = useProhibitShopLoginMutation();

    const onHandleChangeShopState = async () => {
        try {
            onClose();
            if (shop.status === ShopStatus.OutOfService) {
                await liftShopLoginFunc({
                    variables: {
                        input: {
                            shopId: shop.id,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onClose();
                            onOpenMutationBoundaryResult();
                        }
                    },
                    onError: async () => {
                        onClose();
                        onOpenMutationBoundaryResult();
                    },
                    // refetchQueries: () => [
                    //     {
                    //         query: GetShopByIdDocument,
                    //         variables: {
                    //             input: {
                    //                 shopId: shop.id,
                    //             },
                    //         },
                    //     },
                    // ],
                });
            } else if (shop.status === ShopStatus.Enabled) {
                await prohibitShopLoginFunc({
                    variables: {
                        input: {
                            shopId: shop.id,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onClose();
                            onOpenMutationBoundaryResult();
                        }
                    },
                    onError: async () => {
                        onClose();
                        onOpenMutationBoundaryResult();
                    },
                    // refetchQueries: () => [
                    //     {
                    //         query: GetShopByIdDocument,
                    //         variables: {
                    //             input: {
                    //                 shopId: shop.id,
                    //             },
                    //         },
                    //     },
                    // ],
                });
            }
        } catch (error) {
            console.error(error);
        }
    };
    const onHandleSuccess = () => {
        window.location.reload();
    };

    const {
        isOpen: isOpenDeleteShopResult,
        onOpen: onOpenDeleteShopResult,
        onClose: onCloseDeleteShopResult,
    } = useDisclosure();
    const [deleteShopMutationFunc, deleteShopMutationResult] = useDeleteShopMutation();

    const onHandleDeleteShop = async () => {
        try {
            await deleteShopMutationFunc({
                variables: {
                    input: {
                        shopId: shop.id,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onCloseConfirmDeleteShop();
                        onOpenDeleteShopResult();
                    }
                },
                onError: async () => {
                    onCloseConfirmDeleteShop();
                    onOpenDeleteShopResult();
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    const onHandleDeleteShopSuccess = () => {
        history.push(PATH_DASHBOARD.shop.overview);
    };

    return (
        <div style={{ width: '100%' }}>
            <ApolloMutationBoundary
                isOpen={isOpenMutationBoundaryResult}
                onClose={onCloseMutationBoundaryResult}
                onOpen={onOpenMutationBoundaryResult}
                onExtraAfterSuccessHandler={onHandleSuccess}
                mutationResult={
                    shop.status === ShopStatus.OutOfService
                        ? liftShopLoginProhibitionMutationResult
                        : prohibitShopLoginResult
                }
                toastSuccessDescription={
                    shop.status === ShopStatus.OutOfService
                        ? t('liftedShopSuccessfully', { ns: 'shop' })
                        : t('prohibitShopSuccessfully', { ns: 'shop' })
                }
                toastSuccessTitle={
                    shop.status === ShopStatus.OutOfService
                        ? t('shopEnabledTitle', { ns: 'shop' })
                        : t('shopDisableTitle', { ns: 'shop' })
                }
            >
                <Box
                    display="flex"
                    flexDirection={{ base: 'column', md: 'row' }}
                    gap={{ base: 5, md: 5 }}
                    justifyContent="flex-start"
                    w="100%"
                >
                    <HStack>
                        <Box minW={{ base: '125px', md: '95px' }}>
                            <Text color={textColor} fontSize="14px" me="4px" fontWeight="400">
                                Shop status
                            </Text>
                        </Box>

                        <ShopStatusElementWithDisable status={shop.status} isActivated={shop.isActivated} />
                    </HStack>
                    <Box gap={5} display="flex" alignItems="flex-start">
                        {shop.status === ShopStatus.Enabled && !isDisabled && (
                            <Button
                                // colorScheme={btnColorScheme}
                                borderColor={disableBtnColorPrimary}
                                color={disableBtnColorPrimary}
                                variant="outline"
                                // p="15px 40px"
                                fontSize="sm"
                                h="40px"
                                minW="125px"
                                borderRadius={10}
                                fontWeight="500"
                                onClick={onOpen}
                                disabled={isDisabled}
                                borderWidth="2px"
                            >
                                {btnName}
                            </Button>
                        )}

                        {shop.status === ShopStatus.OutOfService && !isDisabled && (
                            <Flex
                                direction={{ base: 'row', md: 'column' }}
                                justifyContent={{ base: 'center', md: 'center' }}
                                gap={1}
                                w="100%"
                            >
                                <Button
                                    // colorScheme={btnColorScheme}
                                    variant="outline"
                                    borderColor={enableBtnColorPrimary}
                                    color={enableBtnColorPrimary}
                                    fontSize="sm"
                                    h="40px"
                                    minW="125px"
                                    borderRadius={10}
                                    borderWidth="2px"
                                    fontWeight="500"
                                    onClick={onHandleChangeShopState}
                                    disabled={isDisabled}
                                >
                                    {btnName}
                                </Button>

                                <Button
                                    variant="outline"
                                    borderColor={disableBtnColorPrimary}
                                    color={disableBtnColorPrimary}
                                    fontSize="sm"
                                    h="40px"
                                    minW="125px"
                                    borderRadius={10}
                                    borderWidth="2px"
                                    fontWeight="500"
                                    onClick={onOpenConfirmDeleteShop}
                                    disabled={isDisabled}
                                >
                                    {t('delete', { ns: 'common' })}
                                </Button>
                            </Flex>
                        )}
                    </Box>
                </Box>
                <ConfirmDisableShopDetailModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onHandleChangeShopState={onHandleChangeShopState}
                />
            </ApolloMutationBoundary>
            <ApolloMutationBoundary
                isOpen={isOpenDeleteShopResult}
                onClose={onCloseDeleteShopResult}
                onOpen={onOpenDeleteShopResult}
                onExtraAfterSuccessHandler={onHandleDeleteShopSuccess}
                mutationResult={deleteShopMutationResult}
                toastSuccessTitle={t('deleteShopSuccessTitle', { ns: 'shop' })}
                toastSuccessDescription={t('deleteShopSuccessMessage', { ns: 'shop' })}
            >
                <ConfirmDeleteShopDetailModal
                    isOpen={isOpenConfirmDeleteShop}
                    onClose={onCloseConfirmDeleteShop}
                    onHandleDeleteShop={onHandleDeleteShop}
                />
            </ApolloMutationBoundary>
        </div>
    );
}
