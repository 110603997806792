import { Box, Flex, Select, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import EntryPlayer from './EntryPlayer';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ShopDetailProps } from '../shopDetailTypes';
import { ApolloQueryBoundary } from '../../../../../../components/graphql/ApolloQueryBoundary';
import { useGetVisitingPlayerStatisticsOfShopQuery } from 'graphql/queries/shop/__generated__/getVisitingPlayerStatisticsOfShop.generated';
import { AggregationPeriod } from '../../../../../../types/graphql';
import { DefaultAvatarSrc } from '../../../../../../constant/defaultValues';

type VisitingPlayerListCardProps = ShopDetailProps & {
    [key: string]: any;
};

export default function VisitingPlayerListCard(props: VisitingPlayerListCardProps) {
    const { shop, ...rest } = props;
    const textColorPrimary = useColorModeValue('navy.650', 'white');
    const { t } = useTranslation(['common']);
    const [periodTime, setPeriodTime] = useState<AggregationPeriod>(AggregationPeriod.ThisMonth);

    const {
        isOpen: isOpenGetVisitingPlayer,
        onOpen: onOpenGetVisitingPlayer,
        onClose: onCloseGetVisitingPlayer,
    } = useDisclosure();

    const getVisitingPlayerStatisticsOfShopResult = useGetVisitingPlayerStatisticsOfShopQuery({
        variables: {
            input: {
                shopId: shop.id,
                aggregationPeriod: periodTime,
                args: {
                    first: 200,
                },
            },
        },
        onError: async () => {
            onOpenGetVisitingPlayer();
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    const onChangeAggregationPeriod = (event: ChangeEvent<HTMLSelectElement>) => {
        setPeriodTime && setPeriodTime(event.target.value as AggregationPeriod);
    };

    return (
        <Card py={8} px={{ base: '20px', lg: '30px', '2xl': '50px' }} borderRadius="30px" gap={6} {...rest}>
            <Flex direction="row" justifyContent="space-between" alignItems="stretch" w={'100%'}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                    {t('guest', { ns: 'common' })}
                </Text>

                <Flex align="center" justifyContent="flex-end">
                    <Select
                        fontSize="16px"
                        color={textColorPrimary}
                        fontWeight={500}
                        borderRadius={'10px'}
                        h="40px"
                        maxH="40px"
                        defaultValue={AggregationPeriod.ThisMonth}
                        onChange={(e) => onChangeAggregationPeriod(e)}
                    >
                        <option value={AggregationPeriod.ThisMonth}>{t('monthOption1', { ns: 'common' })}</option>
                        <option value={AggregationPeriod.LastMonth}>{t('monthOption2', { ns: 'common' })}</option>
                        <option value={AggregationPeriod.MonthBeforeLast}>{t('monthOption4', { ns: 'common' })}</option>
                    </Select>
                </Flex>
            </Flex>

            <Flex direction="column" align="center" w="100%" justifyContent="space-between">
                <ApolloQueryBoundary
                    queryResult={getVisitingPlayerStatisticsOfShopResult}
                    isOpen={isOpenGetVisitingPlayer}
                    onClose={onCloseGetVisitingPlayer}
                    onOpen={onOpenGetVisitingPlayer}
                    render={({ data: { get_visiting_player_statistics_of_shop } }) => {
                        if (
                            (get_visiting_player_statistics_of_shop?.totalCount &&
                                get_visiting_player_statistics_of_shop?.totalCount < 1) ||
                            (get_visiting_player_statistics_of_shop?.edges &&
                                get_visiting_player_statistics_of_shop?.edges?.length < 1)
                        ) {
                            return (
                                <Box w="100%">
                                    <Text
                                        color={textColorPrimary}
                                        fontSize={{ base: '14px', xl: '16px' }}
                                        fontWeight="700"
                                    >
                                        {t('noVisitingPlayerLabel', { ns: 'common' })}
                                    </Text>
                                </Box>
                            );
                        } else {
                            return (
                                <Scrollbars style={{ height: 350 }}>
                                    {get_visiting_player_statistics_of_shop?.edges.map((player) => (
                                        <EntryPlayer
                                            mb="20px"
                                            name={player?.node?.name}
                                            avatar={player.node?.iconUrl ?? DefaultAvatarSrc}
                                            noOfEntry={player.node?.visitedCount}
                                        />
                                    ))}
                                </Scrollbars>
                            );
                        }
                    }}
                />
            </Flex>
        </Card>
    );
}

export function RepeatEserRateCircProgress(props: { title: string; percentage: number }) {
    const { percentage } = props;
    const textColorPrimary = useColorModeValue('navy.650', 'white');
    const stylesColorMode = useColorModeValue(
        {
            textSize: '0px',
            textColor: 'transparent',
            pathTransitionDuration: 0.5,
            pathColor: `#4318FF`,
            trailColor: '#E9EDF7',
            backgroundColor: '#3e98c7',
        },
        {
            textSize: '0px',
            pathTransitionDuration: 0.5,
            pathColor: `#7551FF`,
            textColor: 'transparent',
            trailColor: '#1B254B',
        },
    );
    return (
        <>
            <CircularProgressbarWithChildren
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles(stylesColorMode)}
            >
                <Box>
                    <Text fontSize="20px" color={textColorPrimary} fontWeight="700">
                        {percentage}%
                    </Text>
                </Box>
            </CircularProgressbarWithChildren>
        </>
    );
}
