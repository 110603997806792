import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditMachineInformationMutationVariables = Types.Exact<{
    input: Types.EditMachineInformationInput;
}>;

export type EditMachineInformationMutation = {
    __typename?: 'Mutation';
    edit_machine_information: {
        __typename?: 'Machine';
        id: number;
        machineName: string;
        dummyPlayerId?: number | null;
        freeModeStart?: Date | null;
        freeModeEnd?: Date | null;
        status: Types.MachineStatus;
        inGameStatus: Types.InGameStatus;
        lastLoginAt?: Date | null;
        isPasswordRequired: boolean;
        isEmittingShopBeacon: boolean;
        serviceMode: Types.ProKitServiceModeOption;
        lastInformationEditedAt?: Date | null;
        shop: { __typename?: 'Shop'; id: number; name: string; iconUrl?: string | null; beaconId?: string | null };
        proKit?: {
            __typename?: 'ProKit';
            id: number;
            serialCode: string;
            modelName: string;
            demonstrationPeriodEndAt?: Date | null;
            status: Types.ProKitStatus;
            isActivated: boolean;
            lastActiveAt?: Date | null;
            totalActivateCount: number;
            cpuTemperature?: number | null;
            cpuUsage?: number | null;
            memoryUsage?: number | null;
            usageReportedAt?: Date | null;
            subscriptionPlan?: Types.GranProSubscriptionPlan | null;
        } | null;
        playerEntries: Array<{
            __typename?: 'Player';
            id: number;
            player_name: string;
            icon_url?: string | null;
        } | null>;
    };
};

export const EditMachineInformationDocument = gql`
    mutation editMachineInformation($input: EditMachineInformationInput!) {
        edit_machine_information(input: $input) {
            id
            machineName
            shop {
                id
                name
                iconUrl
                beaconId
            }
            proKit {
                id
                serialCode
                modelName
                demonstrationPeriodEndAt
                status
                isActivated
                lastActiveAt
                totalActivateCount
                cpuTemperature
                cpuUsage
                memoryUsage
                usageReportedAt
                subscriptionPlan
            }
            dummyPlayerId
            freeModeStart
            freeModeEnd
            status
            inGameStatus
            lastLoginAt
            playerEntries {
                id
                player_name
                icon_url
            }
            isPasswordRequired
            isEmittingShopBeacon
            serviceMode
            lastInformationEditedAt
        }
    }
`;
export type EditMachineInformationMutationFn = Apollo.MutationFunction<
    EditMachineInformationMutation,
    EditMachineInformationMutationVariables
>;

/**
 * __useEditMachineInformationMutation__
 *
 * To run a mutation, you first call `useEditMachineInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMachineInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMachineInformationMutation, { data, loading, error }] = useEditMachineInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMachineInformationMutation(
    baseOptions?: Apollo.MutationHookOptions<EditMachineInformationMutation, EditMachineInformationMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<EditMachineInformationMutation, EditMachineInformationMutationVariables>(
        EditMachineInformationDocument,
        options,
    );
}
export type EditMachineInformationMutationHookResult = ReturnType<typeof useEditMachineInformationMutation>;
export type EditMachineInformationMutationResult = Apollo.MutationResult<EditMachineInformationMutation>;
export type EditMachineInformationMutationOptions = Apollo.BaseMutationOptions<
    EditMachineInformationMutation,
    EditMachineInformationMutationVariables
>;
