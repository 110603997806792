// noinspection DuplicatedCode

import { Button, Flex, HStack, Icon, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ChangeEvent, memo, useState } from 'react';
import { MdModeEdit } from 'react-icons/md';
import InputField from 'components/fields/InputField';
import { TfiClose } from 'react-icons/tfi';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { PickValueOf } from 'types/common';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import VerifyNewDealerEmailModal from './VerifyNewDealerEmailModal';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { useToastOnce } from 'components/hooks/useToastOnce';
import { GetDealerByIdQuery } from 'graphql/queries/dealer/__generated__/getDealerById.generated';
import { useRequestDealerPasswordResetCodeLazyQuery } from 'graphql/queries/dealer/__generated__/requestDealerPasswordResetCode.generated';
import { useEditDealerEmailMutation } from 'graphql/mutations/dealer/__generated__/editDealerEmail.generated';
import VerifyChangeDealerPasswordModal from './VerifyChangeDealerPasswordModal';
import { useTranslation } from 'react-i18next';
import SuccessChangePwModal from '../SuccessChangePwModal';

type DealerAccountSignInCardProps = {
    dealer: PickValueOf<GetDealerByIdQuery, 'dealer'>;
    [key: string]: any;
};

export default function DealerAccountSignInCard(props: DealerAccountSignInCardProps) {
    const { dealer, ...rest } = props;
    const { user, userId } = useAuthContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const textColor = useColorModeValue('#4318FF', 'white');
    const [isEdit, setIsEdit] = useState(false);
    const [newEmail, setNewEmail] = useState(user.emailAccount);
    const { t } = useTranslation(['common']);
    const {
        isOpen: isOpenConfirmChangeEmail,
        onOpen: onOpenConfirmChangeEmail,
        onClose: onCloseConfirmChangeEmail,
    } = useDisclosure();
    const {
        isOpen: isOpenVerifyChangePassword,
        onOpen: onOpenVerifyChangePassword,
        onClose: onCloseVerifyChangePassword,
    } = useDisclosure();

    const {
        isOpen: isOpenSendRequestEmailMutationBoundaryResult,
        onOpen: onOpenSendRequestEmailMutationBoundaryResult,
        onClose: onCloseSendRequestEmailMutationBoundaryResult,
    } = useDisclosure();

    const {
        isOpen: isOpenSuccessChangePW,
        onOpen: onOpenSuccessChangePW,
        onClose: onCloseSuccessChangePW,
    } = useDisclosure();

    const [editDealerEmailMutationFunc, useEditDealerEmailMutationResult] = useEditDealerEmailMutation();
    const [requestDealerPasswordResetCodeLazyQueryFunc, requestDealerPasswordResetCodeLazyQueryResult] =
        useRequestDealerPasswordResetCodeLazyQuery();

    const onEditDealerEmail = async () => {
        try {
            await editDealerEmailMutationFunc({
                variables: {
                    input: {
                        dealerId: userId,
                        email: user.emailAccount,
                        newEmail,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenSendRequestEmailMutationBoundaryResult();
                        setNewEmail('');
                    }
                },
                onError: async () => {
                    onOpenSendRequestEmailMutationBoundaryResult();
                },
            });
        } catch (error) {
            console.error(error);
        }
    };
    const onHandleRequestChangePassword = async () => {
        try {
            await requestDealerPasswordResetCodeLazyQueryFunc({
                onCompleted: async (res) => {
                    if (res) {
                        onOpenSuccessChangePW();
                    }
                },
                onError: async () => {
                    onOpen();
                },
            });
        } catch (error) {
            console.error(error);
        }
    };
    const onClickChangeEditable = () => {
        setIsEdit(!isEdit);
    };

    return (
        <Card py="20px" px={{ base: '20px', xl: '50px' }} borderRadius={'30px'} {...rest}>
            <HStack justifyContent="space-between" alignItems="center" mt={3}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                    {t('signInInfoLabel', { ns: 'common' })}
                </Text>
                {isEdit ? (
                    <HStack alignItems="center">
                        <Button p="0px" ms="auto" onClick={onClickChangeEditable} variant="no-hover" bg="transparent">
                            <Icon as={TfiClose} w="22px" h="22px" color={textColorSecondary} mr={1} />
                        </Button>
                    </HStack>
                ) : (
                    <Button
                        p="0px"
                        ms="auto"
                        onClick={onClickChangeEditable}
                        variant="no-hover"
                        bg="transparent"
                        // mt={1}
                    >
                        <Icon as={MdModeEdit} w="16px" h="16px" color={textColor} mr={1} />
                        <Text fontSize="16px" color={textColor} fontWeight="bold">
                            {t('editLabel', { ns: 'common' })}
                        </Text>
                    </Button>
                )}
            </HStack>

            <Flex direction={'column'} gap={isEdit ? 2 : 5} mb={{ base: '10px' }}>
                {isEdit ? (
                    <ApolloMutationBoundary
                        isOpen={isOpenSendRequestEmailMutationBoundaryResult}
                        onClose={onCloseSendRequestEmailMutationBoundaryResult}
                        onOpen={onOpenSendRequestEmailMutationBoundaryResult}
                        onExtraAfterSuccessHandler={onOpenConfirmChangeEmail}
                        mutationResult={useEditDealerEmailMutationResult}
                        toastSuccessTitle={t('requestChangeEmailTitle', { ns: 'common' })}
                        toastSuccessDescription={t('requestChangeEmailMessage', { ns: 'common' })}
                    >
                        <Flex
                            direction={{ sm: 'column', md: 'row' }}
                            justifyContent={{ sm: 'flex-start', md: 'space-between' }}
                            alignItems="center"
                        >
                            <HStack alignItems="center" minH={'80px'}>
                                <Text
                                    color={textColorSecondary}
                                    fontSize="14px"
                                    fontWeight="400"
                                    mr={3}
                                    alignItems="center"
                                >
                                    {t('emailLabel', { ns: 'common' })}
                                </Text>
                                <InputField
                                    id="emailAddress"
                                    value={newEmail}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setNewEmail(e.target.value);
                                    }}
                                />
                            </HStack>
                            <HStack direction="row" justifyContent="flex-start" minH={'80px'} alignItems="center">
                                <Button
                                    colorScheme="red"
                                    variant="outline"
                                    fontSize="sm"
                                    disabled={!newEmail || newEmail === user.emailAccount}
                                    mt={{ base: 0, md: 3 }}
                                    h="40px"
                                    w="140px"
                                    onClick={onEditDealerEmail}
                                    borderRadius={10}
                                    fontWeight="500"
                                    ms="auto"
                                >
                                    {t('changeEmailLabel', { ns: 'common' })}
                                </Button>
                            </HStack>
                        </Flex>
                    </ApolloMutationBoundary>
                ) : (
                    <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                        <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                            {t('emailLabel', { ns: 'common' })}
                        </Text>
                        <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                            {user.emailAccount}
                        </Text>
                    </HStack>
                )}

                {isEdit ? (
                    <Flex
                        direction={{ sm: 'column', md: 'row' }}
                        justifyContent={{ sm: 'flex-start', md: 'space-between' }}
                        alignItems="center"
                    >
                        <ApolloQueryBoundary
                            isOpen={isOpen}
                            onClose={onClose}
                            onOpen={onOpen}
                            queryResult={requestDealerPasswordResetCodeLazyQueryResult}
                            noDataComponent={
                                <NoPasswordDataComponent
                                    onChangePassword={onHandleRequestChangePassword}
                                    onOpenVerifyChangePassword={onOpenVerifyChangePassword}
                                    onOpenVerifyEmailChange={onOpenConfirmChangeEmail}
                                />
                            }
                            render={() => (
                                <NoPasswordDataComponent
                                    onChangePassword={onHandleRequestChangePassword}
                                    onOpenVerifyChangePassword={onOpenVerifyChangePassword}
                                    onOpenVerifyEmailChange={onOpenConfirmChangeEmail}
                                />
                            )}
                        />
                    </Flex>
                ) : (
                    <HStack alignItems="center">
                        <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                            {t('passwordLabel', { ns: 'common' })}
                        </Text>
                        <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                            *********
                        </Text>
                    </HStack>
                )}
            </Flex>

            <VerifyNewDealerEmailModal
                isOpen={isOpenConfirmChangeEmail}
                onClose={onCloseConfirmChangeEmail}
                onOpen={onOpenConfirmChangeEmail}
                modalLabel={{
                    modalHeader: `${t('verifyChangeEmailLabel', { ns: 'common' })}`,
                }}
            />

            <VerifyChangeDealerPasswordModal
                isOpen={isOpenVerifyChangePassword}
                onClose={onCloseVerifyChangePassword}
                onOpen={onOpenVerifyChangePassword}
                confirmBtnLabel="Change"
                modalLabel={{
                    modalHeader: `${t('verifyChangePasswordLabel', { ns: 'common' })}`,
                }}
            />
            <SuccessChangePwModal
                isOpen={isOpenSuccessChangePW}
                onClose={onCloseSuccessChangePW}
                onOpen={onOpenSuccessChangePW}
                onExtraHandle={onOpenVerifyChangePassword}
            />
        </Card>
    );
}

type NoPasswordDataComponentProp = {
    onChangePassword: () => void;
    onOpenVerifyChangePassword: () => void;
    onOpenVerifyEmailChange: () => void;
};

function NoPasswordDataComponent(props: NoPasswordDataComponentProp) {
    const { onChangePassword, onOpenVerifyChangePassword, onOpenVerifyEmailChange } = props;
    const { t } = useTranslation(['common']);
    return (
        <>
            <Flex
                direction={{ base: 'column', '2xl': 'row' }}
                gap="5px"
                justifyContent="center"
                minH={'80px'}
                alignItems="center"
                w="100%"
            >
                <Button
                    colorScheme="red"
                    variant="outline"
                    onClick={onChangePassword}
                    fontSize="sm"
                    h="40px"
                    w={{ base: '100%', '2xl': 'auto' }}
                    borderRadius={10}
                    fontWeight="500"
                >
                    {t('changePasswordLabel', { ns: 'common' })}
                </Button>
                <Button
                    colorScheme="green"
                    variant="outline"
                    fontSize="sm"
                    h="40px"
                    w={{ base: '100%', '2xl': 'auto' }}
                    onClick={onOpenVerifyChangePassword}
                    borderRadius={10}
                    fontWeight="500"
                >
                    {t('verifyChangePasswordLabel', { ns: 'common' })}
                </Button>
                <Button
                    colorScheme="green"
                    variant="outline"
                    fontSize="sm"
                    h="40px"
                    w={{ base: '100%', '2xl': 'auto' }}
                    onClick={onOpenVerifyEmailChange}
                    borderRadius={10}
                    fontWeight="500"
                    // ms="auto"
                >
                    {t('verifyChangeEmailLabel', { ns: 'common' })}
                </Button>
            </Flex>
        </>
    );
}

const RequestChangePwSuccessToast = memo(() => {
    useToastOnce({
        title: 'Request change password',
        description: "You've just made requested change password successfully",
        position: 'top-right',
        status: 'success',
        duration: 9000,
        variant: 'solid',
        isClosable: true,
    });

    return null;
});

RequestChangePwSuccessToast.displayName = 'RequestChangePwSuccessToast';
