import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestShopPasswordResetCodeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RequestShopPasswordResetCodeQuery = {
    __typename?: 'Query';
    request_shop_password_reset_code: Types.SuccessResponse;
};

export const RequestShopPasswordResetCodeDocument = gql`
    query requestShopPasswordResetCode {
        request_shop_password_reset_code
    }
`;

/**
 * __useRequestShopPasswordResetCodeQuery__
 *
 * To run a query within a React component, call `useRequestShopPasswordResetCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestShopPasswordResetCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestShopPasswordResetCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestShopPasswordResetCodeQuery(
    baseOptions?: Apollo.QueryHookOptions<
        RequestShopPasswordResetCodeQuery,
        RequestShopPasswordResetCodeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RequestShopPasswordResetCodeQuery, RequestShopPasswordResetCodeQueryVariables>(
        RequestShopPasswordResetCodeDocument,
        options,
    );
}
export function useRequestShopPasswordResetCodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RequestShopPasswordResetCodeQuery,
        RequestShopPasswordResetCodeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RequestShopPasswordResetCodeQuery, RequestShopPasswordResetCodeQueryVariables>(
        RequestShopPasswordResetCodeDocument,
        options,
    );
}
export type RequestShopPasswordResetCodeQueryHookResult = ReturnType<typeof useRequestShopPasswordResetCodeQuery>;
export type RequestShopPasswordResetCodeLazyQueryHookResult = ReturnType<
    typeof useRequestShopPasswordResetCodeLazyQuery
>;
export type RequestShopPasswordResetCodeQueryResult = Apollo.QueryResult<
    RequestShopPasswordResetCodeQuery,
    RequestShopPasswordResetCodeQueryVariables
>;
