import * as Yup from 'yup';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Text,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import illustration from 'assets/img/auth/auth.png';
import DefaultAuth from 'layouts/auth/variants/Default';
import { NavLink, useHistory } from 'react-router-dom';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import AdminConsoleLogo from '../../../components/logo/AdminConsoleLogo';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useActivateDealerMutation } from '../../../graphql/mutations/auth/__generated__/activateDealer.generated';
import { useActivateShopMutation } from '../../../graphql/mutations/auth/__generated__/activateShop.generated';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import { PATH_AUTH } from '../../../paths';
import { AccountType } from '../../../types/user';
import { useTranslation } from 'react-i18next';

type ActivateAccountFormValuesProps = {
    email: string;
    tempPassword: string;
    newPassword: string;
    accType?: AccountType;
};

function ActivateAccount() {
    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const [show, setShow] = useState(false);
    const [accTypeActivated, setAccTypeActivated] = useState<AccountType | null>(null);
    const history = useHistory();
    const { t } = useTranslation(['common', 'activation']);
    const {
        isOpen: isOpenMutationBoundaryResult,
        onOpen: onOpenMutationBoundaryResult,
        onClose: onCloseMutationBoundaryResult,
    } = useDisclosure();

    const [activateDealerMutation, dealerActivateResult] = useActivateDealerMutation();
    const [activateShopMutation, shopActivateResult] = useActivateShopMutation();

    const handleClick = () => setShow(!show);
    const handleSuccessClose = () => {
        history.push(PATH_AUTH.login);
    };

    const ActivateAccountSchema = Yup.object().shape({
        email: Yup.string()
            .required(`${t('emailRequiredMessage', { ns: 'common' })}`)
            .email('Email must be a valid email address'),
        tempPassword: Yup.string().required(`${t('passwordRequiredMessage', { ns: 'common' })}`),
        newPassword: Yup.string().required(`${t('passwordRequiredMessage', { ns: 'common' })}`),
    });

    const defaultValues = useMemo(
        () => ({
            email: '',
            tempPassword: '',
            newPassword: '',
        }),
        [],
    );

    const methods = useForm({
        resolver: yupResolver(ActivateAccountSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        register,
        formState: { isSubmitting, errors },
    } = methods;

    const onSubmit = async (data: ActivateAccountFormValuesProps) => {
        try {
            if (accTypeActivated === AccountType.dealer) {
                await activateDealerMutation({
                    variables: {
                        input: {
                            email: data.email,
                            tempPassword: data.tempPassword,
                            newPassword: data.newPassword,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onOpenMutationBoundaryResult();
                        }
                    },
                    onError: async () => {
                        onOpenMutationBoundaryResult();
                    },
                });
            }
            if (accTypeActivated === AccountType.shop) {
                await activateShopMutation({
                    variables: {
                        input: {
                            email: data.email,
                            tempPassword: data.tempPassword,
                            newPassword: data.newPassword,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onOpenMutationBoundaryResult();
                        }
                    },
                    onError: async () => {
                        onOpenMutationBoundaryResult();
                    },
                });
            }
            // history.push(PATH_AUTH.login);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChangeAccTypeActivated = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === 'dealer') {
            setAccTypeActivated(AccountType.dealer);
        }
        if (event.target.value === 'shop') {
            setAccTypeActivated(AccountType.shop);
        }
        if (event.target.value === 'default') {
            setAccTypeActivated(null);
        }
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenMutationBoundaryResult}
            onClose={onCloseMutationBoundaryResult}
            onOpen={onOpenMutationBoundaryResult}
            onExtraAfterSuccessHandler={handleSuccessClose}
            mutationResult={accTypeActivated === AccountType.dealer ? dealerActivateResult : shopActivateResult}
            toastSuccessDescription={t('activatedAccSuccessMessage', { ns: 'activation' })}
            toastSuccessTitle={t('activatedAccSuccessTitle', { ns: 'activation' })}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DefaultAuth illustrationBackground={illustration} image={illustration}>
                    <Flex
                        maxW={{ base: '100%' }}
                        w="100%"
                        mx={{ base: 'auto', lg: '0px' }}
                        me="auto"
                        h="100%"
                        alignItems="center"
                        justifyContent="center"
                        mb={{ base: '30px', md: '60px' }}
                        mt={{ base: '40px', md: '14vh' }}
                        flexDirection="column"
                    >
                        <Flex
                            zIndex="2"
                            direction="column"
                            w={{ base: '100%', md: '460px' }}
                            maxW="100%"
                            borderRadius="15px"
                            mx={{ base: 'auto', lg: 'unset' }}
                            me="auto"
                            mb={{ base: '20px', md: 'auto' }}
                        >
                            <AdminConsoleLogo />

                            <Box me="auto" justifyContent="center">
                                <Heading color={textColor} fontSize={{ base: '34px', lg: '36px' }} mb="10px">
                                    {t('headingLabel', { ns: 'activation' })}
                                </Heading>
                                <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
                                    {t('headingMess', { ns: 'activation' })}
                                </Text>
                            </Box>

                            <FormControl isRequired>
                                <FormLabel
                                    display="flex"
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textColor}
                                    mb="8px"
                                >
                                    {t('accountTypeLabel', { ns: 'common' })}
                                </FormLabel>
                                <Select
                                    variant="main"
                                    borderRadius="10px"
                                    h="40px"
                                    maxH="44px"
                                    w="250px"
                                    fontSize="16px"
                                    fontWeight="500"
                                    mb="20px"
                                    onChange={(event) => handleChangeAccTypeActivated(event)}
                                >
                                    <option value="default">{t('accountTypeOption1', { ns: 'common' })}</option>
                                    <option value="dealer">{t('accountTypeOption3', { ns: 'common' })}</option>
                                    <option value="shop">{t('accountTypeOption4', { ns: 'common' })}</option>
                                </Select>
                            </FormControl>

                            <RHFTextField
                                isRequired={false}
                                name="email"
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                type="email"
                                mb="8px"
                                fontWeight="500"
                                size="lg"
                                borderRadius="12px"
                                placeholder="Email"
                                formLabel={
                                    <FormLabel
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                    >
                                        {t('emailLabel', { ns: 'common' })}
                                        <Text color={brandStars}>*</Text>
                                    </FormLabel>
                                }
                            />

                            <RHFTextField
                                isRequired={false}
                                name="tempPassword"
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                mb="8px"
                                fontWeight="500"
                                size="lg"
                                borderRadius="12px"
                                placeholder={t('temporaryPasswordHolder', { ns: 'activation' })}
                                formLabel={
                                    <FormLabel
                                        display="flex"
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColor}
                                        mb="8px"
                                    >
                                        {t('temporaryPasswordLabel', { ns: 'activation' })}
                                        <Text color={brandStars}>*</Text>
                                    </FormLabel>
                                }
                            />

                            <FormControl isRequired={false} isInvalid={!!errors?.newPassword?.message}>
                                <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                                    {t('newPasswordLabel', { ns: 'common' })}
                                    <Text color={brandStars}>*</Text>
                                </FormLabel>
                                <InputGroup size="md">
                                    <Input
                                        isRequired={false}
                                        fontSize="sm"
                                        placeholder={t('passwordHolderMess', { ns: 'common' })}
                                        mb="8px"
                                        size="lg"
                                        type={show ? 'text' : 'password'}
                                        variant="auth"
                                        borderRadius="12px"
                                        name="password"
                                        {...register('newPassword')}
                                    />
                                    <InputRightElement display="flex" alignItems="center" mt="4px">
                                        <Icon
                                            color={textColorSecondary}
                                            _hover={{ cursor: 'pointer' }}
                                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                            onClick={handleClick}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>{errors?.newPassword?.message}</FormErrorMessage>
                            </FormControl>

                            <FormControl>
                                <Button
                                    variant="brand"
                                    fontSize="14px"
                                    fontWeight="500"
                                    w="100%"
                                    h="50"
                                    my="28px"
                                    type="submit"
                                    disabled={accTypeActivated === null}
                                    isLoading={isSubmitting}
                                >
                                    {t('activateAccBtn', { ns: 'activation' })}
                                </Button>
                            </FormControl>
                            <Flex
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="start"
                                maxW="100%"
                                mt="0px"
                            >
                                <Text color={textColorDetails} fontWeight="400" fontSize="sm">
                                    {t('toSignInMessage', { ns: 'activation' })}
                                    <NavLink to="/auth/sign-in">
                                        <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                                            {t('toSignInLink', { ns: 'activation' })}
                                        </Text>
                                    </NavLink>
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </DefaultAuth>
            </FormProvider>
        </ApolloMutationBoundary>
    );
}

export default ActivateAccount;
