// Chakra Imports
import { Button, Flex, Icon, useColorMode } from '@chakra-ui/react';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import LanguagePopover from '../navbar/LanguagePopover';
import * as React from 'react';

export default function FixedLanguagePlugin(props: { [x: string]: any }) {
    const { ...rest } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    const bgButton = 'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)';

    return (
        <Flex
            direction="column"
            zIndex="99"
            position="fixed"
            left={document.documentElement.dir === 'rtl' ? '35px' : ''}
            right={document.documentElement.dir === 'rtl' ? '' : '35px'}
            top="30px"
        >
            <LanguagePopover />
        </Flex>
    );
}
