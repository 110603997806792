import { Button, Flex, Input, Select, useColorModeValue, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { ShopStatusFilter } from '../../../../../../types/graphql';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from '../../../../../../types/user';
import SkeletonTableRow from 'components/skeleton/SkeletonTableRow';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import ShopTableContainer from './ShopTableContainer';
import { useHistory } from 'react-router-dom';
import { PATH_DASHBOARD } from 'paths';
import { useTranslation } from 'react-i18next';
import { useGetShopsListOfDealerQuery } from 'graphql/queries/dealer/__generated__/getShopsListOfDealer.generated';

export default function ShopListByDealer() {
    const history = useHistory();
    const { userId, accountType } = useAuthContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isMobileScreen] = useMediaQuery('(max-width: 768px)');
    const focusColor = useColorModeValue('#2B3674', 'white');
    const [shopStatus, setShopStatus] = useState('default');
    const [provinceFilter, setProvinceFilter] = useState('');
    const { t } = useTranslation(['common']);

    const queryShopListResult = useGetShopsListOfDealerQuery({
        variables: {
            input: {
                dealerId: userId,
                filterOption: {
                    province: provinceFilter === '' ? null : provinceFilter,
                    status: shopStatus === 'default' ? null : (shopStatus as ShopStatusFilter),
                },
                args: {
                    first: 100,
                },
            },
        },
        onError: async () => {
            onOpen();
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    const handleChangeDealerStatus = (event: ChangeEvent<HTMLSelectElement>) => {
        const status =
            event.target.value === 'enable'
                ? ShopStatusFilter.Enabled
                : event.target.value === 'outOfService'
                ? ShopStatusFilter.OutOfService
                : null;

        setShopStatus && setShopStatus(status);
    };

    const handleClickToNewShop = () => {
        history.push(PATH_DASHBOARD.shop.createNew);
    };

    return (
        <Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }} minH="30vh">
            <Flex
                w="100%"
                align={{ sm: 'flex-start', lg: 'stretch' }}
                pt={{ sm: '20px', lg: '30px' }}
                flexDirection={{ sm: 'column', lg: 'row' }}
                px={{ sm: '0px', lg: '50px' }}
            >
                <Flex
                    justifyContent={{ sm: 'space-around', lg: 'flex-start' }}
                    w="100%"
                    mb={{ sm: '20px', lg: '30px' }}
                    px={{ sm: '10px', lg: '0px' }}
                >
                    <Select
                        fontSize="sm"
                        variant="main"
                        borderRadius="10px"
                        h="40px"
                        maxH="44px"
                        maxW={150}
                        onChange={(event) => handleChangeDealerStatus(event)}
                    >
                        <option value="default">{t('statusOption1', { ns: 'common' })}</option>
                        <option value="enable">{t('statusOption2', { ns: 'common' })}</option>
                        <option value="outOfService">{t('statusOption4', { ns: 'common' })}</option>
                    </Select>

                    {!isMobileScreen && (
                        <Input
                            w={{ base: '100%', lg: '320px' }}
                            placeholder={t('searchProvinceHolder', { ns: 'common' })}
                            borderRadius="10px"
                            marginLeft={{ sm: '8px', md: '16px' }}
                            h="40px"
                            maxH="44px"
                            padding="16px"
                            _focus={{ borderColor: focusColor, borderWidth: '0.5px' }}
                            fontSize={'13px'}
                            value={provinceFilter}
                            onChange={(e) => setProvinceFilter(e.target.value)}
                        />
                    )}
                </Flex>
                {accountType === AccountType.dealer && (
                    <Flex alignSelf={{ sm: 'flex-end', lg: 'flex-start' }} display={{ sm: 'none', lg: 'flex' }}>
                        <Button
                            fontSize="14px"
                            variant="brand"
                            fontWeight="500"
                            w={120}
                            maxH={40}
                            alignItems="center"
                            borderRadius="10"
                            backgroundColor="#4318FF"
                            onClick={handleClickToNewShop}
                        >
                            {t('newShop', { ns: 'common' })}
                        </Button>
                    </Flex>
                )}
            </Flex>

            {accountType === AccountType.dealer && (
                <ApolloQueryBoundary
                    queryResult={queryShopListResult}
                    isOpen={isOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    loadingComponent={<SkeletonTableRow />}
                    render={({
                        data: {
                            get_shops_list_of_dealer: { pageInfo, edges, totalCount },
                        },
                    }) => <ShopTableContainer totalCount={totalCount} edges={edges} pageInfo={pageInfo} />}
                />
            )}
        </Flex>
    );
}
