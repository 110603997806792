import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DowngradeProKitSubscriptionPlanMutationVariables = Types.Exact<{
    input: Types.DowngradeProKitSubscriptionPlanInput;
}>;

export type DowngradeProKitSubscriptionPlanMutation = {
    __typename?: 'Mutation';
    downgrade_pro_kit_subscription_plan: {
        __typename?: 'ProKit';
        id: number;
        serialCode: string;
        modelName: string;
        demonstrationPeriodEndAt?: Date | null;
        subscriptionPlan?: Types.GranProSubscriptionPlan | null;
        status: Types.ProKitStatus;
        isActivated: boolean;
        lastActiveAt?: Date | null;
        machine?: { __typename?: 'Machine'; id: number; machineName: string; status: Types.MachineStatus } | null;
        subscriptionPlanSchedule?: {
            __typename?: 'GranProSubscriptionPlanSchedule';
            validFrom: Date;
            subscriptionPlan: Types.GranProSubscriptionPlan;
        } | null;
    };
};

export const DowngradeProKitSubscriptionPlanDocument = gql`
    mutation downgradeProKitSubscriptionPlan($input: DowngradeProKitSubscriptionPlanInput!) {
        downgrade_pro_kit_subscription_plan(input: $input) {
            id
            machine {
                id
                machineName
                status
            }
            subscriptionPlanSchedule {
                validFrom
                subscriptionPlan
            }
            serialCode
            modelName
            demonstrationPeriodEndAt
            subscriptionPlan
            status
            isActivated
            lastActiveAt
        }
    }
`;
export type DowngradeProKitSubscriptionPlanMutationFn = Apollo.MutationFunction<
    DowngradeProKitSubscriptionPlanMutation,
    DowngradeProKitSubscriptionPlanMutationVariables
>;

/**
 * __useDowngradeProKitSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useDowngradeProKitSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDowngradeProKitSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downgradeProKitSubscriptionPlanMutation, { data, loading, error }] = useDowngradeProKitSubscriptionPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDowngradeProKitSubscriptionPlanMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DowngradeProKitSubscriptionPlanMutation,
        DowngradeProKitSubscriptionPlanMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DowngradeProKitSubscriptionPlanMutation,
        DowngradeProKitSubscriptionPlanMutationVariables
    >(DowngradeProKitSubscriptionPlanDocument, options);
}
export type DowngradeProKitSubscriptionPlanMutationHookResult = ReturnType<
    typeof useDowngradeProKitSubscriptionPlanMutation
>;
export type DowngradeProKitSubscriptionPlanMutationResult =
    Apollo.MutationResult<DowngradeProKitSubscriptionPlanMutation>;
export type DowngradeProKitSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<
    DowngradeProKitSubscriptionPlanMutation,
    DowngradeProKitSubscriptionPlanMutationVariables
>;
