import { Flex, Input, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ShopStatusFilter } from 'types/graphql';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import SkeletonTableRow from 'components/skeleton/SkeletonTableRow';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import { useTranslation } from 'react-i18next';
import { useGetShopsListOfDealerQuery } from 'graphql/queries/dealer/__generated__/getShopsListOfDealer.generated';
import IncomeTableContainer from './IncomeTableContainer';
import { PaginationState } from '@tanstack/react-table';
import { DefaultPageSize } from 'constant/defaultValues';

export default function IncomeListByDealer() {
    const { userId, accountType } = useAuthContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const focusColor = useColorModeValue('#2B3674', 'white');
    const [shopStatus] = useState('default');
    const [provinceFilter, setProvinceFilter] = useState('');
    const { t } = useTranslation(['common']);
    const [paginationState, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: DefaultPageSize,
    });

    const queryShopListResult = useGetShopsListOfDealerQuery({
        variables: {
            input: {
                dealerId: userId,
                filterOption: {
                    province: provinceFilter === '' ? null : provinceFilter,
                    status: shopStatus === 'default' ? null : (shopStatus as ShopStatusFilter),
                },
                args: {
                    first: paginationState.pageSize,
                    after: `${Number(paginationState.pageSize * paginationState.pageIndex)}`,
                },
            },
        },
        onError: async () => {
            onOpen();
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: paginationState.pageSize,
        });
    }, [provinceFilter, userId, shopStatus, paginationState.pageSize]);
    return (
        <Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }} minH="30vh">
            <Flex
                w="100%"
                align={{ sm: 'flex-start', lg: 'stretch' }}
                pt={{ sm: '20px', lg: '30px' }}
                flexDirection={{ sm: 'column', lg: 'row' }}
                // px={{ sm: '0px', lg: '50px' }}
            >
                <Flex
                    justifyContent={{ sm: 'space-around', lg: 'flex-start' }}
                    w="100%"
                    mb={{ sm: '20px', lg: '30px' }}
                    // px={{ sm: '10px', lg: '0px' }}
                >
                    <Input
                        w={{ base: '100%', lg: '320px' }}
                        placeholder={t('searchProvinceHolder', { ns: 'common' })}
                        borderRadius="10px"
                        // marginLeft={{ sm: '8px', md: '16px' }}
                        h="40px"
                        maxH="44px"
                        padding="16px"
                        _focus={{ borderColor: focusColor, borderWidth: '0.5px' }}
                        fontSize={'13px'}
                        value={provinceFilter}
                        onChange={(e) => setProvinceFilter(e.target.value)}
                    />
                </Flex>
            </Flex>

            {accountType === AccountType.dealer && (
                <ApolloQueryBoundary
                    queryResult={queryShopListResult}
                    isOpen={isOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    loadingComponent={<SkeletonTableRow />}
                    render={({
                        data: {
                            get_shops_list_of_dealer: { pageInfo, edges, totalCount },
                        },
                    }) => (
                        <IncomeTableContainer
                            totalCount={totalCount}
                            edges={edges}
                            pageInfo={pageInfo}
                            paginationState={paginationState}
                            setPagination={setPagination}
                        />
                    )}
                />
            )}
        </Flex>
    );
}
