import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDealerMutationVariables = Types.Exact<{
    input: Types.DeleteDealerInput;
}>;

export type DeleteDealerMutation = { __typename?: 'Mutation'; delete_dealer: Types.SuccessResponse };

export const DeleteDealerDocument = gql`
    mutation deleteDealer($input: DeleteDealerInput!) {
        delete_dealer(input: $input)
    }
`;
export type DeleteDealerMutationFn = Apollo.MutationFunction<DeleteDealerMutation, DeleteDealerMutationVariables>;

/**
 * __useDeleteDealerMutation__
 *
 * To run a mutation, you first call `useDeleteDealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealerMutation, { data, loading, error }] = useDeleteDealerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDealerMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteDealerMutation, DeleteDealerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteDealerMutation, DeleteDealerMutationVariables>(DeleteDealerDocument, options);
}
export type DeleteDealerMutationHookResult = ReturnType<typeof useDeleteDealerMutation>;
export type DeleteDealerMutationResult = Apollo.MutationResult<DeleteDealerMutation>;
export type DeleteDealerMutationOptions = Apollo.BaseMutationOptions<
    DeleteDealerMutation,
    DeleteDealerMutationVariables
>;
