import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMachinesWithIncomeOfShopQueryVariables = Types.Exact<{
    input: Types.GetMachinesWithIncomeOfShopInput;
}>;

export type GetMachinesWithIncomeOfShopQuery = {
    __typename?: 'Query';
    get_machines_with_income_of_shop: Array<{
        __typename?: 'MachineWithIncome';
        income: number;
        machine: {
            __typename?: 'Machine';
            id: number;
            machineName: string;
            dummyPlayerId?: number | null;
            freeModeStart?: Date | null;
            freeModeEnd?: Date | null;
            status: Types.MachineStatus;
            inGameStatus: Types.InGameStatus;
            lastLoginAt?: Date | null;
            isPasswordRequired: boolean;
            serviceMode: Types.ProKitServiceModeOption;
            lastInformationEditedAt?: Date | null;
            proKit?: { __typename?: 'ProKit'; id: number; modelName: string; totalActivateCount: number } | null;
            shop: { __typename?: 'Shop'; id: number; name: string; incomeConversionRate: number };
        };
    }>;
};

export const GetMachinesWithIncomeOfShopDocument = gql`
    query getMachinesWithIncomeOfShop($input: GetMachinesWithIncomeOfShopInput!) {
        get_machines_with_income_of_shop(input: $input) {
            income
            machine {
                id
                machineName
                dummyPlayerId
                freeModeStart
                freeModeEnd
                status
                inGameStatus
                lastLoginAt
                isPasswordRequired
                serviceMode
                lastInformationEditedAt
                proKit {
                    id
                    modelName
                    totalActivateCount
                }
                shop {
                    id
                    name
                    incomeConversionRate
                }
            }
        }
    }
`;

/**
 * __useGetMachinesWithIncomeOfShopQuery__
 *
 * To run a query within a React component, call `useGetMachinesWithIncomeOfShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesWithIncomeOfShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesWithIncomeOfShopQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMachinesWithIncomeOfShopQuery(
    baseOptions: Apollo.QueryHookOptions<GetMachinesWithIncomeOfShopQuery, GetMachinesWithIncomeOfShopQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMachinesWithIncomeOfShopQuery, GetMachinesWithIncomeOfShopQueryVariables>(
        GetMachinesWithIncomeOfShopDocument,
        options,
    );
}
export function useGetMachinesWithIncomeOfShopLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetMachinesWithIncomeOfShopQuery,
        GetMachinesWithIncomeOfShopQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMachinesWithIncomeOfShopQuery, GetMachinesWithIncomeOfShopQueryVariables>(
        GetMachinesWithIncomeOfShopDocument,
        options,
    );
}
export type GetMachinesWithIncomeOfShopQueryHookResult = ReturnType<typeof useGetMachinesWithIncomeOfShopQuery>;
export type GetMachinesWithIncomeOfShopLazyQueryHookResult = ReturnType<typeof useGetMachinesWithIncomeOfShopLazyQuery>;
export type GetMachinesWithIncomeOfShopQueryResult = Apollo.QueryResult<
    GetMachinesWithIncomeOfShopQuery,
    GetMachinesWithIncomeOfShopQueryVariables
>;
