import { IncomeTabState } from './views/admin/main/income/detail/components/Receipt';

export const ROOTS_AUTH = '/auth';
export const ROOTS_DASHBOARD = '/admin';

function path(root: string, subLink: string) {
    return `${root}${subLink}`;
}

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/sign-in'),
};

export const ROUTE_PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    dealer: {
        root: '/dealer',
        overview: '/dealer/overview',
        detail: (id: number | string) => `/admin/dealer/detail/${id}`,
    },
    shop: {
        root: '/shop',
        overview: '/shop/overview',
        detail: (id: number | string) => `/admin/shop/detail/${id}`,
    },
    machine: {
        root: '/machine',
        overview: '/machine/overview',
        detail: (id: number | string) => `/admin/machine/detail/${id}`,
    },
    income: {
        root: '/income',
        overview: '/income/overview',
        detail: (id: number | string, tab: IncomeTabState) => `/admin/income/detail/${id}/${tab}`,
    },
    guest: {
        root: '/guest',
        overview: '/guest/overview',
    },
    prokit: {
        root: '/prokit',
        overview: '/prokit/overview',
        detail: (id: number | string) => `/admin/prokit/detail/${id}`,
    },
    account: {
        root: '/account',
        setting: '/account/settings',
    },
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    dealer: {
        root: path(ROOTS_DASHBOARD, '/dealer'),
        overview: path(ROOTS_DASHBOARD, '/dealer/overview'),
        createNew: path(ROOTS_DASHBOARD, '/dealer/add-new'),
        detail: path(ROOTS_DASHBOARD, '/dealer/detail/:id'),
        // detail: (id: number | string) => `/admin/dealer/detail/${id}`,
    },
    shop: {
        root: path(ROOTS_DASHBOARD, '/shop'),
        overview: path(ROOTS_DASHBOARD, '/shop/overview'),
        createNew: path(ROOTS_DASHBOARD, '/shop/add-new'),
        detail: path(ROOTS_DASHBOARD, '/shop/detail/:id'),
    },
    machine: {
        root: path(ROOTS_DASHBOARD, '/machine'),
        overview: path(ROOTS_DASHBOARD, '/machine/overview'),
        detail: path(ROOTS_DASHBOARD, '/machine/detail/:id'),
    },
    income: {
        root: path(ROOTS_DASHBOARD, '/income'),
        overview: path(ROOTS_DASHBOARD, '/income/overview'),
        detail: path(ROOTS_DASHBOARD, '/income/detail/:id/:tab'),
    },
    prokit: {
        root: path(ROOTS_DASHBOARD, '/prokit'),
        overview: path(ROOTS_DASHBOARD, '/prokit/overview'),
        detail: path(ROOTS_DASHBOARD, '/prokit/detail/:id'),
    },
    account: {
        root: path(ROOTS_DASHBOARD, '/account'),
        setting: path(ROOTS_DASHBOARD, '/account/settings'),
    },
};
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root;
