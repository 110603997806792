import * as Yup from 'yup';
import { Box, Button, Flex, FormLabel, SimpleGrid, Stack, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRegisterDealerMutation } from '../../../../../graphql/mutations/dealer/__generated__/registerDealer.generated';
import { Country, DealerType } from '../../../../../types/graphql';
import FormProvider, { RHFTextField } from 'components/hook-form';
import { ApolloMutationBoundary } from '../../../../../components/graphql/ApolloMutationBoundary';
import { useHistory } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../../paths';
import { RHFCountrySelect } from '../../../../../components/hook-form/RHFCountrySelect';
import { RHFLanguageSelect } from '../../../../../components/hook-form/RHFLanguageSelect';
import { useAuthContext } from '../../../../../layouts/auth/useAuthContext';
import * as React from 'react';
import { memo, useMemo, useState } from 'react';
import CreateNewResultModalCommon from '../../../../../components/modal/CreateNewResultModalCommon';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import RHFSwitch from '../../../../../components/hook-form/RHFSwitch';
import localStorageAvailable from '../../../../../utils/localStorageAvailable';
import { KEY } from '../../../../../constant/key';
import { AccountType } from '../../../../../types/user';
import { useInviteSubordinateDealerMutation } from 'graphql/mutations/dealer/__generated__/inviteSubordinateDealer.generated';

type NewDealerFormValuesProps = {
    address: string;
    countryCode: Country;
    distributorId: number;
    email: string;
    languageCode: string;
    name: string;
    phoneNumber: string;
    province: string;
    zipCode: string;
    isPartnerDealer: boolean;
    afterSubmit?: string;
};

export default function AddNewTab() {
    const history = useHistory();
    const { userId, accountType } = useAuthContext();
    const storageAvailable = localStorageAvailable();
    const dealerType = useMemo(() => {
        return storageAvailable ? (localStorage.getItem(KEY.dealerType) as DealerType) : null;
    }, []);
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { isOpen: isOpenSuccessResult, onOpen: onOpenSuccessResult, onClose: onCloseSuccessResult } = useDisclosure();
    const { isOpen: isOpenFailResult, onOpen: onOpenFailResult, onClose: onCloseFailResult } = useDisclosure();
    const { t } = useTranslation(['common']);

    const [registerDealerMutation, mutationResult] = useRegisterDealerMutation();

    const [inviteSubordinateDealerMutationFunc, inviteSubordinateDealerMutationResult] =
        useInviteSubordinateDealerMutation();
    const AddNewDealerSchema = Yup.object().shape({
        name: Yup.string().required('Dealer Name is required'),
        countryCode: Yup.string().required('Country is required'),
        languageCode: Yup.string().required('Language is required'),
        zipCode: Yup.string().required('Zip Code is required'),
        province: Yup.string().required('Province is required'),
        address: Yup.string().required('Address is required'),
        phoneNumber: Yup.string().required('Phone Number is required'),
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        isPartnerDealer: Yup.boolean().required('Partner Dealer option is required'),
    });
    const defaultValues = useMemo(
        () => ({
            email: '',
            address: '',
            countryCode: Country.Japan,
            distributorId: userId,
            languageCode: 'ja',
            name: '',
            phoneNumber: '',
            province: '',
            zipCode: '',
            isPartnerDealer: false,
        }),
        [userId],
    );
    const [errorMessages, setErrorMessages] = useState({
        message1: '',
        message2: '',
        message3: '',
    });

    const methods = useForm({
        resolver: yupResolver(AddNewDealerSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        watch,
    } = methods;
    const values = watch();
    const onSubmit = async (data: NewDealerFormValuesProps) => {
        try {
            if (accountType === AccountType.distributor) {
                await registerDealerMutation({
                    variables: {
                        input: {
                            email: data.email,
                            name: data.name,
                            address: data.address,
                            countryCode: data.countryCode,
                            distributorId: data.distributorId,
                            languageCode: data.languageCode,
                            phoneNumber: data.phoneNumber,
                            province: data.province,
                            zipCode: data.zipCode,
                            isPartnerDealer: data.isPartnerDealer,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onOpenSuccessResult();
                        }
                    },
                    onError: async (error) => {
                        if (error.message.includes('An account with the given email already exists')) {
                            setErrorMessages &&
                                setErrorMessages({
                                    message1: 'このメールアドレスはすでに使用されているため利用できません。',
                                    message2: '他のメールアドレスをご入力ください。',
                                    message3: data.email,
                                });
                        } else {
                            setErrorMessages({
                                message1: error.message,
                                message2: '',
                                message3: '',
                            });
                        }
                        onOpenFailResult();
                    },
                });
            } else if (accountType === AccountType.dealer && dealerType === DealerType.Partner) {
                await inviteSubordinateDealerMutationFunc({
                    variables: {
                        input: {
                            email: data.email,
                            name: data.name,
                            address: data.address,
                            countryCode: data.countryCode,
                            languageCode: data.languageCode,
                            phoneNumber: data.phoneNumber,
                            province: data.province,
                            zipCode: data.zipCode,
                            ownDealerId: userId,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onOpenSuccessResult();
                        }
                    },
                    onError: async (error) => {
                        if (error.message.includes('An account with the given email already exists')) {
                            setErrorMessages &&
                                setErrorMessages({
                                    message1: 'このメールアドレスはすでに使用されているため利用できません。',
                                    message2: '他のメールアドレスをご入力ください。',
                                    message3: data.email,
                                });
                        } else {
                            setErrorMessages({
                                message1: error.message,
                                message2: '',
                                message3: '',
                            });
                        }
                        onOpenFailResult();
                    },
                });
            }
            // history.push(PATH_DASHBOARD.dealer.overview);
        } catch (error) {
            console.error(error);
        }
    };

    const onHandleSuccess = () => {
        history.push(PATH_DASHBOARD.dealer.overview);
    };
    const onHandleFailed = () => {
        return;
    };

    return (
        <ApolloMutationBoundary
            mutationResult={
                accountType === AccountType.distributor ? mutationResult : inviteSubordinateDealerMutationResult
            }
            toastComponent={<></>}
            errorHandler={(error) => {
                return <OnErrorAddDealerMutationResultLoaded {...error} />;
            }}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Card p="30px">
                    <Flex direction="column" w="100%">
                        <SimpleGrid columns={{ base: 1 }} gap="20px">
                            <Stack direction="column" gap="20px">
                                <RHFTextField
                                    name="name"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewTabFormLabel label={t('dealerName', { ns: 'common' })} />}
                                />
                            </Stack>
                        </SimpleGrid>

                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt={5} mb={2.5}>
                            <Stack direction="column" gap="20px">
                                <RHFCountrySelect name="countryCode" />
                            </Stack>
                            <Stack direction="column" gap="20px">
                                <RHFLanguageSelect name="languageCode" />
                            </Stack>
                        </SimpleGrid>

                        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                            <Stack direction="column" gap="20px">
                                <RHFTextField
                                    name="zipCode"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewTabFormLabel label={t('zipCode', { ns: 'common' })} />}
                                />
                                <RHFTextField
                                    name="address"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewTabFormLabel label={t('address', { ns: 'common' })} />}
                                />
                                <RHFTextField
                                    name="email"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewTabFormLabel label={t('emailLabel', { ns: 'common' })} />}
                                />
                            </Stack>

                            <Stack direction="column" gap="20px">
                                <RHFTextField
                                    name="province"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewTabFormLabel label={t('province', { ns: 'common' })} />}
                                />

                                <RHFTextField
                                    name="phoneNumber"
                                    isRequired={false}
                                    fontWeight="500"
                                    variant="main"
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    h="44px"
                                    maxH="44px"
                                    formLabel={<AddNewTabFormLabel label={t('phoneNumber', { ns: 'common' })} />}
                                />

                                {accountType === AccountType.distributor && (
                                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                                        <FormLabel
                                            display="flex"
                                            fontSize="sm"
                                            color={textColorPrimary}
                                            fontWeight="bold"
                                            _hover={{ cursor: 'pointer' }}
                                        >
                                            Partner Dealer
                                        </FormLabel>
                                        <Stack direction="row" minH="45px" alignItems="center">
                                            <RHFSwitch
                                                size="md"
                                                spacing={1}
                                                orientation="vertical"
                                                colorScheme="brand"
                                                isChecked={values.isPartnerDealer}
                                                name="isPartnerDealer"
                                                isRequired={false}
                                            />
                                        </Stack>
                                    </Box>
                                )}
                            </Stack>
                        </SimpleGrid>
                    </Flex>
                    <Flex direction="row" justifyContent="center" w="100%" mt="24px">
                        <Box>
                            <Button
                                variant="darkBrand"
                                fontSize="sm"
                                borderRadius="16px"
                                minW={{ base: '128px', md: '148px' }}
                                h="46px"
                                ms="auto"
                                type="submit"
                                isLoading={isSubmitting}
                            >
                                {t('addNewDealer', { ns: 'common' })}
                            </Button>
                        </Box>
                    </Flex>
                </Card>
            </FormProvider>
            <CreateNewResultModalCommon
                isOpen={isOpenSuccessResult}
                onClose={onCloseSuccessResult}
                onOpen={onOpenSuccessResult}
                onHandleConfirm={onHandleSuccess}
                modalLabel={{
                    modalBodyMessage1: '招待メールを送信しました。',
                    modalBodyMessage2: 'ディーラーの登録を完了するためには、',
                    modalBodyMessage3: 'ディーラーがログインしてアクティベートする必要があります。',
                }}
            />
            <CreateNewResultModalCommon
                isOpen={isOpenFailResult}
                onClose={onCloseFailResult}
                onOpen={onOpenFailResult}
                isSuccess={false}
                onHandleConfirm={onHandleFailed}
                modalLabel={{
                    modalBodyMessage1: errorMessages.message1,
                    modalBodyMessage2: errorMessages.message2 === '' ? null : errorMessages.message2,
                    modalBodyMessage3: errorMessages.message3 === '' ? null : errorMessages.message3,
                }}
            />
        </ApolloMutationBoundary>
    );
}

type AddNewTabFormLabelProps = {
    label: string;
};

function AddNewTabFormLabel({ label }: AddNewTabFormLabelProps) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    return (
        <FormLabel
            display="flex"
            ms="10px"
            fontSize="sm"
            color={textColorPrimary}
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
        >
            {label}
        </FormLabel>
    );
}

const OnErrorAddDealerMutationResultLoaded = memo((error: ApolloError) => {
    return null;
});

OnErrorAddDealerMutationResultLoaded.displayName = 'OnErrorAddDealerMutationResultLoaded';
