import { ChangeEvent } from 'react';
import { MachineStatus, MachineStatusFilter } from '../../types/graphql';
import { Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SelectProps } from '@chakra-ui/select/src/select';

// ----------------------------------------------------------------------

type Props = SelectProps & {
    machineStatus: MachineStatusFilter | 'all';
    setMachineStatus: (status: MachineStatusFilter | 'all') => void;
    isDefaultStyle?: boolean;
};

export default function StatusMachineSelectList({
    machineStatus,
    setMachineStatus,
    isDefaultStyle = true,
    ...rest
}: Props) {
    const { t } = useTranslation(['common']);
    const handleChangeMachineStatusOption = (event: ChangeEvent<HTMLSelectElement>) => {
        setMachineStatus && setMachineStatus(event.target.value as MachineStatusFilter | 'all');
    };
    return (
        <Select
            fontSize="14px"
            variant="main"
            borderRadius="10px"
            h="40px"
            maxH="44px"
            maxW={150}
            fontWeight="500"
            onChange={(e) => handleChangeMachineStatusOption(e)}
            me={{ base: '10px', md: '20px' }}
            {...rest}
        >
            <option value="all">{t('statusOption1', { ns: 'common' })}</option>
            <option value={MachineStatus.Enabled}>{t('statusOption2', { ns: 'common' })}</option>
            <option value={MachineStatus.OutOfService}>{t('statusOption4', { ns: 'common' })}</option>
        </Select>
    );
}
