// noinspection DuplicatedCode

import {
    Button,
    Flex,
    Icon,
    Select,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import {
    ColumnFiltersState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    useReactTable,
} from '@tanstack/react-table';
import { CursorPaginationPageInfo } from 'graphql/types/other';
import { PickValueOf } from 'types/common';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { ProkitObj } from '../variables';
import TableNoData from 'components/table/TableNoData';
import { useTranslation } from 'react-i18next';
import { GetProKitListOfDealerQuery } from 'graphql/queries/prokit/__generated__/getProKitListOfDealer.generated';
import { createProKitListColumn } from './CreateProKitCommonHelper';
import { OnChangeFn } from '@tanstack/table-core/src/types';
import { createPagesFromTotal } from '../../../../../../utils/tables';

export type ProkitTableContainerProps = {
    totalCount: number;
    pageInfo: CursorPaginationPageInfo;
    edges: PickValueOf<PickValueOf<GetProKitListOfDealerQuery, 'get_pro_kit_list_of_dealer'>, 'edges'>;
    paginationState: PaginationState;
    setPagination: (paginationState: PaginationState) => void;
};

export default function ProkitTableContainer(props: ProkitTableContainerProps) {
    const {
        edges,
        totalCount,
        paginationState: { pageSize, pageIndex },
        setPagination,
    } = props;
    // Style
    // ----------------------------------------------------------------------
    const textColor = useColorModeValue('#2B3674', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'brand.400');
    const { t } = useTranslation(['common']);
    const [isLargerThan960] = useMediaQuery('(min-width: 960px)');
    const [isLargerThan460] = useMediaQuery('(min-width: 460px)');
    // ----------------------------------------------------------------------
    const defaultData = useMemo(() => {
        const result: ProkitObj[] = edges.map((proKit) => ({
            id: proKit.node.id,
            lastActive: proKit.node.lastActiveAt,
            counter: proKit.node.totalActivateCount,
            model: {
                modelName: proKit.node.modelName,
                id: proKit.node.id,
            },
            serialCode: proKit.node.serialCode,
            status: proKit.node.status,
            dealer: {
                id: proKit.node.dealer.id,
                name: proKit.node.dealer.name,
                iconUrl: proKit.node.dealer.iconUrl,
            },
        }));
        return result;
    }, []);

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const columnHelper = createColumnHelper<ProkitObj>();
    const columns = createProKitListColumn(isLargerThan960, isLargerThan460, columnHelper, textColor, t);
    const [data] = useState(() => [...defaultData]);

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination as OnChangeFn<PaginationState>,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });
    // ----------------------------------------------------------------------

    return totalCount > 0 ? (
        <>
            <Table variant="simple" color="gray.500" mb="24px">
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Th pe="10px" borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <Flex
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : '',
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}
                                                justify="space-between"
                                                align="center"
                                                fontSize={{ sm: '10px', lg: '12px' }}
                                                color="gray.400"
                                                maxW={header.id === 'id' ? '50px' : 'auto'}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {{
                                                    asc: '',
                                                    desc: '',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </Flex>
                                        )}
                                    </Th>
                                );
                            })}
                        </Tr>
                    ))}
                </Thead>

                <Tbody>
                    {table.getCoreRowModel().rows.map((row) => {
                        return (
                            <Tr px="20px" key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <Td
                                            key={cell.id}
                                            fontSize={{ sm: '14px' }}
                                            borderColor={borderColor}
                                            maxW={cell.id === 'id' ? '50px' : 'auto'}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>

            <Flex
                w="100%"
                justify={{ sm: 'flex-end', md: 'space-between' }}
                px="20px"
                pt="10px"
                pb="5px"
                direction={{ sm: 'column', md: 'row' }}
            >
                <Flex align="center" justifyContent="center">
                    <Text me="10px" minW="max-content" fontSize="sm" color="gray.500" fontWeight="normal">
                        {t('rowsPerPage', { ns: 'common' })}
                    </Text>
                    <Select
                        w="80px"
                        fontSize="sm"
                        variant="main"
                        value={pageSize}
                        onChange={(e) => table.setPageSize(Number(e.target.value))}
                    >
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </Select>
                </Flex>
                <div className="flex items-center gap-2">
                    <Stack
                        direction="row"
                        alignSelf="flex-end"
                        spacing="4px"
                        ms="auto"
                        mt={{ sm: '20px', md: '0px' }}
                        justifyContent="center"
                    >
                        <Button
                            variant="no-effects"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                            transition="all .5s ease"
                            w="40px"
                            h="40px"
                            borderRadius="50%"
                            bg="transparent"
                            border="1px solid"
                            borderColor={borderColor}
                            display={pageSize === 5 ? 'none' : table.getCanPreviousPage() ? 'flex' : 'none'}
                            _hover={{
                                bg: 'whiteAlpha.100',
                                opacity: '0.7',
                            }}
                        >
                            <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
                        </Button>
                        {createPagesFromTotal(totalCount, pageSize).map((pageNumber, index) => {
                            return (
                                <Button
                                    variant="no-effects"
                                    transition="all .5s ease"
                                    onClick={() => table.setPageIndex(pageNumber - 1)}
                                    w="40px"
                                    h="40px"
                                    borderRadius="50%"
                                    bg={pageNumber === pageIndex + 1 ? brandColor : 'transparent'}
                                    border={pageNumber === pageIndex + 1 ? 'none' : '1px solid lightgray'}
                                    _hover={
                                        pageNumber === pageIndex + 1
                                            ? {
                                                  opacity: '0.7',
                                              }
                                            : {
                                                  bg: 'whiteAlpha.100',
                                              }
                                    }
                                    key={index}
                                >
                                    <Text fontSize="sm" color={pageNumber === pageIndex + 1 ? '#fff' : textColor}>
                                        {pageNumber}
                                    </Text>
                                </Button>
                            );
                        })}
                        <Button
                            variant="no-effects"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                            transition="all .5s ease"
                            w="40px"
                            h="40px"
                            borderRadius="50%"
                            bg="transparent"
                            border="1px solid"
                            borderColor={borderColor}
                            display={pageSize === 5 ? 'none' : table.getCanNextPage() ? 'flex' : 'none'}
                            _hover={{
                                bg: 'whiteAlpha.100',
                                opacity: '0.7',
                            }}
                        >
                            <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
                        </Button>
                    </Stack>
                </div>
            </Flex>
        </>
    ) : (
        <TableNoData />
    );
}
