import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVisitingPlayerStatisticsOfShopQueryVariables = Types.Exact<{
    input: Types.GetVisitingPlayerStatisticsOfShopInput;
}>;

export type GetVisitingPlayerStatisticsOfShopQuery = {
    __typename?: 'Query';
    get_visiting_player_statistics_of_shop: {
        __typename?: 'ProShopVisitorStatisticsConnection';
        totalCount: number;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
        edges: Array<{
            __typename?: 'ProShopVisitorStatisticsEdge';
            cursor: string;
            node: {
                __typename?: 'ProShopVisitorStatistics';
                id: number;
                name: string;
                iconUrl?: string | null;
                countryCode?: string | null;
                visitedCount: number;
            };
        } | null>;
    };
};

export const GetVisitingPlayerStatisticsOfShopDocument = gql`
    query getVisitingPlayerStatisticsOfShop($input: GetVisitingPlayerStatisticsOfShopInput!) {
        get_visiting_player_statistics_of_shop(input: $input) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    name
                    iconUrl
                    countryCode
                    visitedCount
                }
            }
        }
    }
`;

/**
 * __useGetVisitingPlayerStatisticsOfShopQuery__
 *
 * To run a query within a React component, call `useGetVisitingPlayerStatisticsOfShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitingPlayerStatisticsOfShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitingPlayerStatisticsOfShopQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVisitingPlayerStatisticsOfShopQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetVisitingPlayerStatisticsOfShopQuery,
        GetVisitingPlayerStatisticsOfShopQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetVisitingPlayerStatisticsOfShopQuery, GetVisitingPlayerStatisticsOfShopQueryVariables>(
        GetVisitingPlayerStatisticsOfShopDocument,
        options,
    );
}
export function useGetVisitingPlayerStatisticsOfShopLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetVisitingPlayerStatisticsOfShopQuery,
        GetVisitingPlayerStatisticsOfShopQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetVisitingPlayerStatisticsOfShopQuery, GetVisitingPlayerStatisticsOfShopQueryVariables>(
        GetVisitingPlayerStatisticsOfShopDocument,
        options,
    );
}
export type GetVisitingPlayerStatisticsOfShopQueryHookResult = ReturnType<
    typeof useGetVisitingPlayerStatisticsOfShopQuery
>;
export type GetVisitingPlayerStatisticsOfShopLazyQueryHookResult = ReturnType<
    typeof useGetVisitingPlayerStatisticsOfShopLazyQuery
>;
export type GetVisitingPlayerStatisticsOfShopQueryResult = Apollo.QueryResult<
    GetVisitingPlayerStatisticsOfShopQuery,
    GetVisitingPlayerStatisticsOfShopQueryVariables
>;
