import {
    addDays,
    differenceInHours,
    format,
    formatDistanceToNow,
    getTime,
    isAfter,
    isBefore,
    isEqual,
    isSameDay,
    parseISO,
} from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: string | number | Date) {
    return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateWithSlash(date: string | number | Date) {
    return format(new Date(date), 'yyyy/MM/dd');
}

export function fYyyyMmDd(date: string | number | Date | null | undefined) {
    if (!date) {
        return '-';
    }
    return format(new Date(date), 'yyyy-MM-dd');
}

export function fYyyyMm(date: string | number | Date | null | undefined) {
    if (!date) {
        return '-';
    }
    return format(new Date(date), 'yyyy-MM');
}

export function fYyyyMmDdWithSlash(date: string | number | Date | null | undefined) {
    if (!date) {
        return '-';
    }
    return format(new Date(date), 'yyyyMMdd');
}

export function fYyyyMmDdHhMm(date: string | number | Date | null | undefined) {
    if (!date) {
        return '-';
    }
    return format(new Date(date), 'yyyy-MM-dd HH:mm');
}

export function fDateTime(date: string | number | Date) {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date: string | number | Date) {
    return getTime(new Date(date));
}

export function fDateTimeSuffix(date: string | number | Date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: string | number | Date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true,
    });
}

export function randomDate(start = new Date(), end: Date) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export function fHhMm(date: string | number | Date | null | undefined) {
    if (!date) {
        return '-';
    }
    return format(new Date(date), 'HH:mm');
}

export function addSpecificDays(inputDate: Date, numberOfDays = 1): string {
    const newDay = new Date(addDays(inputDate, numberOfDays));
    return fDateWithSlash(newDay);
}

export function isCurrentTimeBetweenDates(startDate: string, endDate: string, currentTime: string): boolean {
    const parsedStartDate = parseISO(startDate);
    const parsedEndDate = parseISO(endDate);
    const parsedCurrentTime = parseISO(currentTime);

    // Check if the current date is equal to or after the start date
    const isAfterStartDate = isEqual(parsedCurrentTime, parsedStartDate) || isAfter(parsedCurrentTime, parsedStartDate);

    // Check if the current date is equal to or before the end date
    const isBeforeEndDate = isEqual(parsedCurrentTime, parsedEndDate) || isBefore(parsedCurrentTime, parsedEndDate);

    // Check if the current date is on the same day as the start or end date
    const isSameDayStart = isSameDay(parsedCurrentTime, parsedStartDate);
    const isSameDayEnd = isSameDay(parsedCurrentTime, parsedEndDate);

    // Check if the current time is between the given dates
    return (isAfterStartDate || isSameDayStart) && (isBeforeEndDate || isSameDayEnd);
}

export function calculateDateTimeDistanceInHours(dateTime1: string, dateTime2: string): number {
    const parsedDateTime1 = parseISO(dateTime1);
    const parsedDateTime2 = parseISO(dateTime2);

    return differenceInHours(parsedDateTime2, parsedDateTime1);
}
