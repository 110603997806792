import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyShopPasswordResetMutationVariables = Types.Exact<{
    input: Types.VerifyShopPasswordResetInput;
}>;

export type VerifyShopPasswordResetMutation = {
    __typename?: 'Mutation';
    verify_shop_password_reset: Types.SuccessResponse;
};

export const VerifyShopPasswordResetDocument = gql`
    mutation verifyShopPasswordReset($input: VerifyShopPasswordResetInput!) {
        verify_shop_password_reset(input: $input)
    }
`;
export type VerifyShopPasswordResetMutationFn = Apollo.MutationFunction<
    VerifyShopPasswordResetMutation,
    VerifyShopPasswordResetMutationVariables
>;

/**
 * __useVerifyShopPasswordResetMutation__
 *
 * To run a mutation, you first call `useVerifyShopPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyShopPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyShopPasswordResetMutation, { data, loading, error }] = useVerifyShopPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyShopPasswordResetMutation(
    baseOptions?: Apollo.MutationHookOptions<VerifyShopPasswordResetMutation, VerifyShopPasswordResetMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<VerifyShopPasswordResetMutation, VerifyShopPasswordResetMutationVariables>(
        VerifyShopPasswordResetDocument,
        options,
    );
}
export type VerifyShopPasswordResetMutationHookResult = ReturnType<typeof useVerifyShopPasswordResetMutation>;
export type VerifyShopPasswordResetMutationResult = Apollo.MutationResult<VerifyShopPasswordResetMutation>;
export type VerifyShopPasswordResetMutationOptions = Apollo.BaseMutationOptions<
    VerifyShopPasswordResetMutation,
    VerifyShopPasswordResetMutationVariables
>;
