import { Flex, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import ShopProfile from './ShopProfile';
import ShopInfoCard from './ShopInfoCard';
import ShopControlCard from './ShopControlCard';
import MachineListCard from './MachineListCard';
import VisitingPlayerListCard from './VisitingPlayerListCard';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import { useHistory, useParams } from 'react-router-dom';
import { isIntegerNumeric } from 'utils/format-number';
import { PATH_DASHBOARD } from 'paths';
import { useGetShopByIdQuery } from 'graphql/queries/shop/__generated__/getShopById.generated';
import { ShopDetailParams } from '../index';
import { useAuthContext } from '../../../../../../layouts/auth/useAuthContext';
import PermissionDenied from '../../../../../../components/elements/PermissionDenied';

export default function DealerShopDetail() {
    const { userId } = useAuthContext();
    const { id } = useParams<ShopDetailParams>();
    const history = useHistory();
    const { isOpen: isOpenGetShopById, onOpen: onOpenGetShopById, onClose: onCloseGetShopById } = useDisclosure();

    if (!isIntegerNumeric(id)) {
        history.push(PATH_DASHBOARD.shop.overview);
    }

    const getShopByIdQueryResult = useGetShopByIdQuery({
        variables: {
            input: {
                shopId: Number(id),
            },
        },
        onError: async () => {
            onOpenGetShopById();
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });
    return (
        <ApolloQueryBoundary
            queryResult={getShopByIdQueryResult}
            isOpen={isOpenGetShopById}
            onClose={onCloseGetShopById}
            onOpen={onOpenGetShopById}
            render={({ data: { shop } }) => {
                if (shop?.dealer?.id === userId) {
                    return (
                        <SimpleGrid mb="20px" columns={{ sm: 1, md: 1, lg: 2 }} spacing={{ base: '20px', xl: '20px' }}>
                            <Flex direction="column" gap={5}>
                                <ShopProfile shop={shop} />
                                <ShopInfoCard shop={shop} />
                                <ShopControlCard shop={shop} />
                            </Flex>
                            <Flex direction="column" gap={5}>
                                <MachineListCard shop={shop} />
                                <VisitingPlayerListCard shop={shop} />
                            </Flex>
                        </SimpleGrid>
                    );
                } else {
                    return <PermissionDenied />;
                }
            }}
        />
    );
}
