// noinspection DuplicatedCode

import { Box, Flex, Link, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import routes from '../../routes';
import { SidebarResponsive } from '../sidebar/Sidebar';
import { useAuthContext } from '../../layouts/auth/useAuthContext';
import { useLocation } from 'react-router-dom';

const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export default function AdminNavbar(props: {
    secondary: boolean;
    brandText: string;
    logoText: string;
    fixed: boolean;
    onOpen: (...args: any[]) => any;
}) {
    const { secondary, brandText } = props;
    const [, setScrolled] = useState(false);
    const { accountType, dealerType } = useAuthContext();
    const location = useLocation();
    const [heading, setHeading] = useState(brandText ?? 'GranPro Admin');

    useEffect(() => {
        const splitString = location.pathname.split('/');
        const adminIndex = splitString.indexOf('admin');
        const mainPath = splitString[adminIndex + 1];
        const subPath =
            splitString[adminIndex + 2] &&
            (splitString[adminIndex + 2] === 'detail' ||
                splitString[adminIndex + 2] === 'overview' ||
                splitString[adminIndex + 2] === 'add-new')
                ? splitString[adminIndex + 2]
                : '';
        const head = mainPath
            ? subPath === 'add-new'
                ? `Add New ${capitalizeFirstLetter(mainPath)}`
                : `${mainPath} ${subPath}`
            : 'GranPro Admin';
        setHeading(head);
    }, [location]);

    useEffect(() => {
        window.addEventListener('scroll', changeNavbar);

        return () => {
            window.removeEventListener('scroll', changeNavbar);
        };
    });

    const mainText = useColorModeValue('navy.700', 'white');
    const navbarPosition = 'fixed' as const;
    const navbarFilter = 'none';
    const navbarBackdrop = 'blur(20px)';
    const navbarShadow = 'none';
    const navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
    const navbarBorder = 'transparent';
    const secondaryMargin = '0px';
    const paddingX = '15px';
    const gap = '0px';
    const changeNavbar = () => {
        if (window.scrollY > 1) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    return (
        <Box
            position={navbarPosition}
            boxShadow={navbarShadow}
            bg={navbarBg}
            borderColor={navbarBorder}
            filter={navbarFilter}
            backdropFilter={navbarBackdrop}
            backgroundPosition="center"
            backgroundSize="cover"
            borderRadius="16px"
            borderWidth="1.5px"
            borderStyle="solid"
            transitionDelay="0s, 0s, 0s, 0s"
            transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
            transition-property="box-shadow, background-color, filter, border"
            transitionTimingFunction="linear, linear, linear, linear"
            alignItems={{ xl: 'center' }}
            display={secondary ? 'block' : 'flex'}
            minH="75px"
            justifyContent={{ xl: 'center' }}
            lineHeight="25.6px"
            mx="auto"
            mt={secondaryMargin}
            pb="8px"
            right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
            px={{
                sm: paddingX,
                md: '10px',
            }}
            ps={{
                xl: '12px',
            }}
            pt="8px"
            top={{ base: '12px', md: '16px', xl: '18px' }}
            w={{
                base: 'calc(100vw - 4%)',
                md: 'calc(100vw - 8%)',
                lg: 'calc(100vw - 6%)',
                // xl: 'calc(100vw - 350px)',
                '2xl': 'calc(100vw - 365px)',
            }}
        >
            <Flex
                w="100%"
                flexDirection={{
                    sm: 'row',
                    md: 'row',
                }}
                alignItems={{ sm: 'center', xl: 'center' }}
                mb={gap}
            >
                <SidebarResponsive routes={routes(accountType, dealerType)} mb={{ sm: '8px', md: '0px' }} />
                <Box mb={{ sm: '5px', md: '0px' }} minW="50%" px={{ sm: '0px', xl: '30px' }}>
                    <Link
                        color={mainText}
                        bg="inherit"
                        borderRadius="inherit"
                        fontWeight="bold"
                        fontSize={{ sm: '24px', xl: '34px' }}
                        _hover={{ color: { mainText } }}
                        _active={{
                            bg: 'inherit',
                            transform: 'none',
                            borderColor: 'transparent',
                        }}
                        _focus={{
                            boxShadow: 'none',
                        }}
                    >
                        {capitalizeFirstLetter(heading)}
                    </Link>
                </Box>
                <Box
                    ms="auto"
                    w={{ sm: '100%', md: 'unset' }}
                    alignSelf="self-end"
                    alignItems="end"
                    justifyContent="flex-end"
                >
                    <AdminNavbarLinks secondary={props.secondary} />
                </Box>
            </Flex>
        </Box>
    );
}
