import { Box, Grid, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import Receipt from './components/Receipt';
import { useHistory, useParams } from 'react-router-dom';
import { isIntegerNumeric } from 'utils/format-number';
import { PATH_DASHBOARD } from 'paths';

export type IncomeDetailParams = {
    id: string;
    tab?: string;
};

export default function IncomeDetail() {
    const textColor = useColorModeValue('gray.700', 'white');
    const { id, tab } = useParams<IncomeDetailParams>();
    const history = useHistory();
    if (!isIntegerNumeric(id)) {
        history.push(PATH_DASHBOARD.income.overview);
    }

    return (
        <Box px={{ base: '0px', md: '20px', xl: '50px' }}>
            <Grid
                mb="20px"
                mr="10px"
                // TODO: Maybe implement in the future for game income chart
                // templateColumns={{ base: '1fr', xl: '3fr 1fr' }}
                templateColumns={{ base: '1fr' }}
                flexDirection="column"
                pt={{ base: '30px', md: '80px', xl: '80px' }}
            >
                <Receipt
                    gridArea={{ base: '1 / 1 / 2 / 3', lg: '1 / 1 / 2 / 2' }}
                    height={'86vh'}
                    textColor={textColor}
                    id={id}
                    tab={tab}
                />
                {/*TODO: Maybe implement in the future for game income chart*/}
                {/*<GameIncomeChart*/}
                {/*    ms={{ base: '0px', lg: '20px' }}*/}
                {/*    mt={{ base: '20px', lg: '0px' }}*/}
                {/*    zIndex="0"*/}
                {/*    gridArea={{ base: '2 / 1 / 3 / 3', lg: '1 / 2 / 2 / 3' }}*/}
                {/*/>*/}
            </Grid>
        </Box>
    );
}
