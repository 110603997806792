import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditShopEmailMutationVariables = Types.Exact<{
    input: Types.EditShopEmailInput;
}>;

export type EditShopEmailMutation = { __typename?: 'Mutation'; edit_shop_email: Types.SuccessResponse };

export const EditShopEmailDocument = gql`
    mutation editShopEmail($input: EditShopEmailInput!) {
        edit_shop_email(input: $input)
    }
`;
export type EditShopEmailMutationFn = Apollo.MutationFunction<EditShopEmailMutation, EditShopEmailMutationVariables>;

/**
 * __useEditShopEmailMutation__
 *
 * To run a mutation, you first call `useEditShopEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopEmailMutation, { data, loading, error }] = useEditShopEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShopEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<EditShopEmailMutation, EditShopEmailMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<EditShopEmailMutation, EditShopEmailMutationVariables>(EditShopEmailDocument, options);
}
export type EditShopEmailMutationHookResult = ReturnType<typeof useEditShopEmailMutation>;
export type EditShopEmailMutationResult = Apollo.MutationResult<EditShopEmailMutation>;
export type EditShopEmailMutationOptions = Apollo.BaseMutationOptions<
    EditShopEmailMutation,
    EditShopEmailMutationVariables
>;
