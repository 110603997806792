import { Redirect } from 'react-router-dom';
import { useAuthContext } from './useAuthContext';
import { ReactNode } from 'react';
import { Text } from '@chakra-ui/react';
import { PATH_DASHBOARD } from '../../paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
    children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
    const { isAuthenticated, isInitialized } = useAuthContext();
    if (isAuthenticated) {
        return <Redirect to={PATH_DASHBOARD.root} />;
    }

    if (!isInitialized) {
        return <Text>Loading...</Text>;
    }

    return <> {children} </>;
}
