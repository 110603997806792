import { Flex, FormControl, FormErrorMessage, FormLabel, Select, useColorModeValue } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectProps } from '@chakra-ui/select/src/select';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type RHFLanguageSelectProps = SelectProps & {
    name: string;
};

export function RHFLanguageSelect({ name, ...other }: RHFLanguageSelectProps) {
    const { control, setValue } = useFormContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { t } = useTranslation(['common']);

    const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setValue(name, event.target.value);
    };
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl isInvalid={!!error}>
                    <Flex direction="column" mb="20px">
                        <FormLabel
                            ms="10px"
                            htmlFor="language"
                            fontSize="sm"
                            color={textColorPrimary}
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                        >
                            {t('language', { ns: 'common' })}
                        </FormLabel>
                        <Select
                            {...field}
                            {...other}
                            onChange={(event) => handleOnChange(event)}
                            fontSize="sm"
                            id="currency"
                            variant="main"
                            h="44px"
                            maxH="44px"
                            defaultValue="ja"
                        >
                            <option value="de">Deutsch</option>
                            <option value="en">English</option>
                            <option value="es">Español</option>
                            <option value="fr">Français</option>
                            <option value="ja">日本語</option>
                            <option value="zh">中文</option>
                        </Select>
                        <FormErrorMessage>{error && error.message}</FormErrorMessage>
                    </Flex>
                </FormControl>
            )}
        />
    );
}
