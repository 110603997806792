import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { IoMdCloseCircle } from 'react-icons/io';

export type DisableStatusProps = {
    disableTextValue: string;
};

export default function DisableStatus(props: DisableStatusProps) {
    const { disableTextValue } = props;
    const textColor = useColorModeValue('#2B3674', 'white');
    return (
        <Flex align="center">
            <Icon as={IoMdCloseCircle} color="#EE5D50" width="24px" height="24px" mr={1} />
            <Text color={textColor} fontSize="14px" fontWeight="700">
                {disableTextValue}
            </Text>
        </Flex>
    );
}
