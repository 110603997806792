import * as Yup from 'yup';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import { ThemeTypings } from '@chakra-ui/styled-system';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import React, { useMemo, useState } from 'react';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import FormProvider, { RHFTextField } from 'components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { GetShopByIdDocument } from 'graphql/queries/shop/__generated__/getShopById.generated';
import { useVerifyShopPasswordResetMutation } from 'graphql/mutations/shop/__generated__/verifyShopPasswordReset.generated';
import { useHistory } from 'react-router-dom';
import { PATH_AUTH } from '../../../../../../../paths';

type FormValueVerifyChangePasswordModalProps = {
    password: string;
    code: string;
};

type VerifyChangePasswordModalProps = UseDisclosureProps & {
    btnColorScheme?: ThemeTypings['colorSchemes'];
    confirmBtnLabel: string;
    modalLabel: {
        modalHeader: string;
    };
};

export default function VerifyChangePasswordModal(props: VerifyChangePasswordModalProps) {
    const { isOpen, onClose, btnColorScheme = 'red', modalLabel } = props;
    const history = useHistory();
    const { userId, logout } = useAuthContext();
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const [show, setShow] = useState(false);

    const [verifyShopPasswordResetMutationFunc, verifyShopPasswordResetMutationResult] =
        useVerifyShopPasswordResetMutation();

    const handleClick = () => setShow(!show);

    const ChangePasswordInfoSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        code: Yup.string().required('Verify code is required'),
    });

    const defaultValues = useMemo(
        () => ({
            code: '',
            password: '',
        }),
        [],
    );

    const methods = useForm({
        resolver: yupResolver(ChangePasswordInfoSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting, errors },
        register,
        reset,
    } = methods;
    const onVerifyChangePassword = async (data: FormValueVerifyChangePasswordModalProps) => {
        try {
            await verifyShopPasswordResetMutationFunc({
                variables: {
                    input: {
                        code: data.code,
                        newPassword: data.password,
                    },
                },
                refetchQueries: () => [
                    {
                        query: GetShopByIdDocument,
                        variables: {
                            input: {
                                shopId: userId,
                            },
                        },
                    },
                ],
            });
            logout();
            history.push(PATH_AUTH.login);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ApolloMutationBoundary
                mutationResult={verifyShopPasswordResetMutationResult}
                toastSuccessTitle="Verify Change Password"
                toastSuccessDescription="Change password successfully. Need to login again"
            >
                <FormProvider methods={methods} onSubmit={handleSubmit(onVerifyChangePassword)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{modalLabel.modalHeader}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody display="flex" alignItems="center" justifyContent="center" py={4}>
                            <VStack alignItems="stretch">
                                <FormControl isRequired={false} isInvalid={!!errors?.password?.message}>
                                    <VStack>
                                        <HStack justifyContent="flex-start" alignItems="center" mt={1}>
                                            <FormLabel
                                                // ms="4px"
                                                fontSize="sm"
                                                fontWeight="500"
                                                color={textColorSecondary}
                                                display="flex"
                                                alignItems="center"
                                            >
                                                New password
                                            </FormLabel>
                                            <InputGroup size="sm" maxW={'240px'} maxH={'44px'}>
                                                <Input
                                                    fontSize="sm"
                                                    variant="main"
                                                    size="md"
                                                    type={show ? 'text' : 'password'}
                                                    borderRadius={'10px !important'}
                                                    name="password"
                                                    {...register('password')}
                                                />
                                                <InputRightElement display="flex" alignItems="center" mt="4px">
                                                    <Icon
                                                        color={textColorSecondary}
                                                        _hover={{ cursor: 'pointer' }}
                                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                        onClick={handleClick}
                                                    />
                                                </InputRightElement>
                                            </InputGroup>
                                        </HStack>
                                        {errors?.password?.message && (
                                            <HStack justifyContent="flex-start" alignItems="center">
                                                <FormErrorMessage sx={{ marginBottom: 2 }}>
                                                    {errors?.password?.message}
                                                </FormErrorMessage>
                                            </HStack>
                                        )}
                                    </VStack>
                                </FormControl>
                                <HStack justifyContent="space-between" alignItems="center" mt={1}>
                                    <Text
                                        color={textColorSecondary}
                                        fontSize="14px"
                                        fontWeight="400"
                                        mr={1}
                                        alignItems="center"
                                        minW={20}
                                    >
                                        Code
                                    </Text>
                                    <RHFTextField
                                        name="code"
                                        isRequired={false}
                                        borderRadius={'10px !important'}
                                        fontWeight="500"
                                        variant="main"
                                        // maxW={'250px'}
                                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                        maxH={'40px'}
                                    />
                                </HStack>
                            </VStack>
                        </ModalBody>

                        <ModalFooter display="flex" alignItems="center" justifyContent="center" pb={10}>
                            <HStack>
                                <Button
                                    colorScheme="gray.500"
                                    variant="outline"
                                    fontSize="sm"
                                    color={'gray.500'}
                                    w="120px"
                                    borderRadius={10}
                                    fontWeight="500"
                                    ms="auto"
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    colorScheme={btnColorScheme}
                                    variant="solid"
                                    fontSize="sm"
                                    w="120px"
                                    borderRadius={10}
                                    fontWeight="500"
                                    type="submit"
                                    isLoading={isSubmitting}
                                    ms="auto"
                                >
                                    Confirm
                                </Button>
                            </HStack>
                        </ModalFooter>
                    </ModalContent>
                </FormProvider>
            </ApolloMutationBoundary>
        </Modal>
    );
}
