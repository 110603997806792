import {
    Avatar,
    Button,
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react';
import { MdNotificationsNone } from 'react-icons/md';
import * as React from 'react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { HSeparator } from 'components/separator/Separator';
import { useAuthContext } from '../../layouts/auth/useAuthContext';
import { PATH_AUTH, PATH_DASHBOARD } from '../../paths';
import { KEY } from '../../constant/key';
import localStorageAvailable from '../../utils/localStorageAvailable';
import { DefaultAvatarSrc } from '../../constant/defaultValues';
import LanguagePopover from './LanguagePopover';
import { useTranslation } from 'react-i18next';

export default function HeaderLinks(props: { secondary: boolean }) {
    const { secondary } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    const storageAvailable = localStorageAvailable();
    const navbarIcon = useColorModeValue('gray.400', 'white');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const separatorColor = useColorModeValue('#E9EDF7', '#E9EDF7');
    const borderColor = useColorModeValue('white !important', '#0b1437 !important');
    const menuBg = useColorModeValue('white', 'navy.800');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
    );

    const { logout, user } = useAuthContext();
    const { t } = useTranslation(['common']);

    const userAvatarUrlAccount = useMemo(() => {
        return storageAvailable
            ? localStorage.getItem(KEY.userAvatarUrl)
                ? localStorage.getItem(KEY.userAvatarUrl)
                : user.userAvatarUrlAccount
            : DefaultAvatarSrc;
    }, [localStorage.getItem(KEY.userAvatarUrl), user.userAvatarUrlAccount]);

    const history = useHistory();
    const handleLogout = async () => {
        try {
            logout();
            history.push(PATH_AUTH.login);
        } catch (error) {
            console.error('Unable to logout!: ', error);
        }
    };
    const handleGotoAccountSetting = async () => {
        try {
            history.push(PATH_DASHBOARD.account.setting);
        } catch (error) {
            console.error('Unexpected error!: ', error);
        }
    };
    return (
        <Flex
            w={{ sm: '100%', md: 'auto' }}
            alignItems="center"
            flexDirection="row"
            justifyContent="flex-end"
            flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
            px="0px"
            mb="15px"
            borderRadius="30px"
        >
            <Menu>
                <LanguagePopover />
                <MenuButton p="0px">
                    <Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="24px" h="24px" me="10px" />
                </MenuButton>
                <MenuList
                    boxShadow={shadow}
                    p="20px"
                    borderRadius="20px"
                    // bg={menuBg}
                    border="none"
                    mt="22px"
                    me={{ base: '30px', md: 'unset' }}
                    minW={{ base: 'unset', md: '400px', xl: '450px' }}
                    maxW={{ base: '360px', md: 'unset' }}
                >
                    <Flex w="100%" mb="20px">
                        <Text fontSize="md" fontWeight="600" color={textColor}>
                            {t('notificationMenuLabel', { ns: 'common' })}
                        </Text>
                    </Flex>
                </MenuList>
            </Menu>

            <Menu>
                <MenuButton p="0px">
                    <Avatar
                        _hover={{ cursor: 'pointer' }}
                        color="white"
                        src={userAvatarUrlAccount}
                        borderColor={borderColor}
                        size="sm"
                        w="40px"
                        h="40px"
                    />
                </MenuButton>
                <MenuList
                    boxShadow={shadow}
                    mt="10px"
                    py="20px"
                    borderRadius="20px"
                    bg={menuBg}
                    border="none"
                    minW="240px"
                >
                    <Flex flexDirection="column" mb="20px" px="30px">
                        <MenuItem
                            _hover={{ bg: 'none' }}
                            _focus={{ bg: 'none' }}
                            borderRadius="8px"
                            onClick={handleGotoAccountSetting}
                        >
                            <Text fontSize="14px" color={textColor}>
                                {t('accSettingMenuLabel', { ns: 'common' })}
                            </Text>
                        </MenuItem>
                        <MenuItem
                            onClick={handleLogout}
                            _hover={{ bg: 'none' }}
                            _focus={{ bg: 'none' }}
                            color={textColor}
                            borderRadius="8px"
                        >
                            <Text fontSize="14px">{t('logOutMenuLabel', { ns: 'common' })}</Text>
                        </MenuItem>
                    </Flex>
                    <HSeparator display={'flex'} bg={separatorColor} />
                    <Flex w="100%" mt="20px" mb="16px" px="30px">
                        <Button
                            variant="no-hover"
                            bg="transparent"
                            p="0px"
                            minW="unset"
                            minH="unset"
                            h="18px"
                            w="max-content"
                            onClick={toggleColorMode}
                        >
                            <Icon
                                me="10px"
                                h="20px"
                                w="20px"
                                color={navbarIcon}
                                as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
                            />
                            <Text fontSize="14px" textColor={textColor} fontWeight="normal">
                                {colorMode === 'light'
                                    ? `${t('toDarkModeMenuLabel', { ns: 'common' })}`
                                    : `${t('toLightModeMenuLabel', { ns: 'common' })}`}
                            </Text>
                        </Button>
                    </Flex>
                </MenuList>
            </Menu>
        </Flex>
    );
}
