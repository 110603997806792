import { Flex, FormControl, FormErrorMessage, Select, useColorModeValue } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectProps } from '@chakra-ui/select/src/select';
import * as React from 'react';
import { ChangeEvent } from 'react';

type RHFNumberSelectProps = SelectProps & {
    name: string;
    min?: number;
    max?: number;
    flexKey: string | number;
};

function createNumberArray(min: number, max: number): number[] {
    const result: number[] = [];
    if (min > max) {
        throw new Error('Minimum value cannot be greater than the maximum value.');
    }
    for (let i = min; i <= max; i++) {
        result.push(i);
    }
    return result;
}

export function RHFNumberSelect({ name, min = 1, max = 30, flexKey, ...other }: RHFNumberSelectProps) {
    const { control, setValue } = useFormContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const optionNumber = createNumberArray(min, max);
    const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setValue(name, Number(event.target.value));
    };
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl isInvalid={!!error} key={flexKey}>
                    <Flex direction="column">
                        <Select
                            {...field}
                            {...other}
                            onChange={(event) => handleOnChange(event)}
                            fontSize="sm"
                            variant="outline"
                            borderRadius={'10px'}
                            w={'80px'}
                            h={'36px'}
                            py="0px"
                            my="0px"
                            color={textColorPrimary}
                            textColor={textColorPrimary}
                            fontWeight={700}
                            // defaultValue={currentValue}
                        >
                            {optionNumber.map((number) => (
                                <option value={number}>{number}</option>
                            ))}
                        </Select>
                        <FormErrorMessage>{error && error.message}</FormErrorMessage>
                    </Flex>
                </FormControl>
            )}
        />
    );
}
