import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpgradeProKitSubscriptionPlanMutationVariables = Types.Exact<{
    input: Types.UpgradeProKitSubscriptionPlanInput;
}>;

export type UpgradeProKitSubscriptionPlanMutation = {
    __typename?: 'Mutation';
    upgrade_pro_kit_subscription_plan: {
        __typename?: 'ProKit';
        id: number;
        serialCode: string;
        modelName: string;
        demonstrationPeriodEndAt?: Date | null;
        subscriptionPlan?: Types.GranProSubscriptionPlan | null;
        status: Types.ProKitStatus;
        isActivated: boolean;
        lastActiveAt?: Date | null;
        machine?: { __typename?: 'Machine'; id: number; machineName: string; status: Types.MachineStatus } | null;
        subscriptionPlanSchedule?: {
            __typename?: 'GranProSubscriptionPlanSchedule';
            validFrom: Date;
            subscriptionPlan: Types.GranProSubscriptionPlan;
        } | null;
    };
};

export const UpgradeProKitSubscriptionPlanDocument = gql`
    mutation upgradeProKitSubscriptionPlan($input: UpgradeProKitSubscriptionPlanInput!) {
        upgrade_pro_kit_subscription_plan(input: $input) {
            id
            machine {
                id
                machineName
                status
            }
            subscriptionPlanSchedule {
                validFrom
                subscriptionPlan
            }
            serialCode
            modelName
            demonstrationPeriodEndAt
            subscriptionPlan
            status
            isActivated
            lastActiveAt
        }
    }
`;
export type UpgradeProKitSubscriptionPlanMutationFn = Apollo.MutationFunction<
    UpgradeProKitSubscriptionPlanMutation,
    UpgradeProKitSubscriptionPlanMutationVariables
>;

/**
 * __useUpgradeProKitSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useUpgradeProKitSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeProKitSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeProKitSubscriptionPlanMutation, { data, loading, error }] = useUpgradeProKitSubscriptionPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpgradeProKitSubscriptionPlanMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpgradeProKitSubscriptionPlanMutation,
        UpgradeProKitSubscriptionPlanMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpgradeProKitSubscriptionPlanMutation, UpgradeProKitSubscriptionPlanMutationVariables>(
        UpgradeProKitSubscriptionPlanDocument,
        options,
    );
}
export type UpgradeProKitSubscriptionPlanMutationHookResult = ReturnType<
    typeof useUpgradeProKitSubscriptionPlanMutation
>;
export type UpgradeProKitSubscriptionPlanMutationResult = Apollo.MutationResult<UpgradeProKitSubscriptionPlanMutation>;
export type UpgradeProKitSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<
    UpgradeProKitSubscriptionPlanMutation,
    UpgradeProKitSubscriptionPlanMutationVariables
>;
