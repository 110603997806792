import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { isIntegerNumeric } from 'utils/format-number';
import { PATH_DASHBOARD } from 'paths';
import { useGetMachineByIdQuery } from 'graphql/queries/machine/__generated__/getMachineById.generated';
import { ApolloQueryBoundary } from '../../../../../components/graphql/ApolloQueryBoundary';
import MachineStatus from './components/MachineStatus';
import ControlCard from './components/ControlCard';
import GameInfo from './components/GameInfo';
import PlanCard from './components/PlanCard';
import MachineDetailInfo from './components/MachineDetailInfo';

type MachineDetailParams = {
    id: string;
};

export default function MachineDetail() {
    const { id } = useParams<MachineDetailParams>();
    const history = useHistory();
    if (!isIntegerNumeric(id)) {
        history.push(PATH_DASHBOARD.machine.overview);
    }
    const queryMachineDetailResult = useGetMachineByIdQuery({
        variables: {
            input: {
                machineId: Number(id),
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });
    return (
        <Box pt={{ base: '40px', md: '80px', xl: '80px' }} px={{ base: '0px', md: '50px', xl: '50px' }}>
            <ApolloQueryBoundary
                queryResult={queryMachineDetailResult}
                render={({ data: { machine } }) => {
                    return (
                        <SimpleGrid mb="20px" columns={{ sm: 1, lg: 2 }} spacing={{ base: '20px', lg: '20px' }}>
                            <Flex direction="column" gap={5}>
                                <MachineStatus machine={machine} />
                                <MachineDetailInfo machine={machine} />
                                <PlanCard machine={machine} />
                                <ControlCard machine={machine} />
                            </Flex>
                            <GameInfo machine={machine} />
                        </SimpleGrid>
                    );
                }}
            />
        </Box>
    );
}
