// noinspection DuplicatedCode

import { ColumnHelper } from '@tanstack/react-table';
import { IncomeObj } from '../variables';
import { Avatar, Flex, Link, Text } from '@chakra-ui/react';
import { ROUTE_PATH_DASHBOARD } from 'paths';
import { fCurrencyWithoutLabel } from 'utils/format-number';
import { fYyyyMmDd } from 'utils/format-time';
import * as React from 'react';
import { TFunction } from 'i18next';
import { AccountType } from '../../../../../../types/user';

export const CreateIncomeListColumn = (
    isLargerThan960: boolean,
    isLargerThan320: boolean,
    columnHelper: ColumnHelper<IncomeObj>,
    textColor: string,
    t: TFunction,
    accountType: AccountType,
) =>
    isLargerThan960
        ? [
              columnHelper.accessor('shop', {
                  id: 'shop',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('shop', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex align="center">
                          <Avatar src={info.getValue().shopAvatar} h="60px" w="60px" me="10px" />
                          {accountType === AccountType.dealer ? (
                              <Flex direction="column">
                                  <Text color={textColor} fontSize="16px" fontWeight="500">
                                      <Link
                                          color={textColor}
                                          href={ROUTE_PATH_DASHBOARD.income.detail(
                                              info.getValue().shopId,
                                              'machine-detail',
                                          )}
                                      >
                                          {info.getValue().name}
                                      </Link>
                                  </Text>
                              </Flex>
                          ) : (
                              <Flex direction="column">
                                  <Text color={textColor} fontSize="16px" fontWeight="500">
                                      {info.getValue().name}
                                  </Text>
                              </Flex>
                          )}
                      </Flex>
                  ),
              }),
              columnHelper.accessor('province', {
                  id: 'province',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('province', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('dealerName', {
                  id: 'dealerName',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('dealerName', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('noOfMachine', {
                  id: 'noOfMachine',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('machines', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('uncollected', {
                  id: 'uncollected',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('balanceLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {fCurrencyWithoutLabel(info.getValue())}
                      </Text>
                  ),
              }),
              columnHelper.accessor('lastCollection', {
                  id: 'lastCollection',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('lastCollectionLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex direction="column">
                          <Text color={textColor} fontSize="16px" fontWeight="500">
                              {fYyyyMmDd(info.getValue())}
                          </Text>
                      </Flex>
                  ),
              }),
          ]
        : isLargerThan320
        ? [
              columnHelper.accessor('shop', {
                  id: 'shop',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('shop', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex align="center">
                          <Avatar src={info.getValue().shopAvatar} h="60px" w="60px" me="10px" />
                          {accountType === AccountType.dealer && (
                              <Flex direction="column">
                                  <Text color={textColor} fontSize="16px" fontWeight="500">
                                      <Link
                                          color={textColor}
                                          href={ROUTE_PATH_DASHBOARD.income.detail(
                                              info.getValue().shopId,
                                              'machine-detail',
                                          )}
                                      >
                                          {info.getValue().name}
                                      </Link>
                                  </Text>
                              </Flex>
                          )}
                          {accountType === AccountType.distributor && (
                              <Flex direction="column">
                                  <Text color={textColor} fontSize="16px" fontWeight="500">
                                      {info.getValue().name}
                                  </Text>
                              </Flex>
                          )}
                      </Flex>
                  ),
              }),
              columnHelper.accessor('noOfMachine', {
                  id: 'noOfMachine',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          Machine
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {info.getValue()}
                      </Text>
                  ),
              }),
              columnHelper.accessor('uncollected', {
                  id: 'uncollected',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('balanceLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {fCurrencyWithoutLabel(info.getValue())}
                      </Text>
                  ),
              }),
          ]
        : [
              columnHelper.accessor('shop', {
                  id: 'shop',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('shop', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex align="center">
                          <Avatar src={info.getValue().shopAvatar} h="60px" w="60px" me="10px" />
                          {accountType === AccountType.dealer && (
                              <Flex direction="column">
                                  <Text color={textColor} fontSize="16px" fontWeight="500">
                                      <Link
                                          color={textColor}
                                          href={ROUTE_PATH_DASHBOARD.income.detail(
                                              info.getValue().shopId,
                                              'machine-detail',
                                          )}
                                      >
                                          {info.getValue().name}
                                      </Link>
                                  </Text>
                              </Flex>
                          )}
                          {accountType === AccountType.distributor && (
                              <Flex direction="column">
                                  <Text color={textColor} fontSize="16px" fontWeight="500">
                                      {info.getValue().name}
                                  </Text>
                              </Flex>
                          )}
                      </Flex>
                  ),
              }),
              columnHelper.accessor('uncollected', {
                  id: 'uncollected',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="center"
                          fontSize="14px"
                          fontWeight="500"
                          textTransform="capitalize"
                          color="secondaryGray.600"
                      >
                          {t('balanceLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Text color={textColor} fontSize="16px" fontWeight="500">
                          {fCurrencyWithoutLabel(info.getValue())}
                      </Text>
                  ),
              }),
          ];
