import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlProps, FormErrorMessage, Switch, SwitchProps } from '@chakra-ui/react';

// ----------------------------------------------------------------------

type Props = SwitchProps & {
    formControlProps?: FormControlProps;
    name: string;
    isRequired: boolean;
    formLabel?: JSX.Element;
};

export default function RHFSwitch({ formControlProps, name, isRequired = false, formLabel, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value, ...restOfField }, fieldState: { error } }) => (
                <FormControl isRequired={isRequired} isInvalid={!!error?.message} {...formControlProps}>
                    {formLabel ?? null}
                    <Switch {...restOfField} checked={value} onChange={onChange} {...other} />
                    <FormErrorMessage sx={{ marginBottom: 2 }}>{error?.message}</FormErrorMessage>
                </FormControl>
            )}
        />
    );
}
