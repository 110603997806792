import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
    colors: {
        brand: {
            100: '#E9E3FF',
            200: '#422AFB',
            300: '#422AFB',
            400: '#7551FF',
            500: '#422AFB',
            600: '#3311DB',
            700: '#02044A',
            800: '#190793',
            900: '#11047A',
        },
        brandScheme: {
            100: '#E9E3FF',
            200: '#7551FF',
            300: '#7551FF',
            400: '#7551FF',
            500: '#422AFB',
            600: '#3311DB',
            700: '#02044A',
            800: '#190793',
            900: '#02044A',
        },
        brandTabs: {
            100: '#E9E3FF',
            200: '#422AFB',
            300: '#422AFB',
            400: '#422AFB',
            500: '#422AFB',
            600: '#3311DB',
            700: '#02044A',
            800: '#190793',
            900: '#02044A',
        },
        secondaryGray: {
            100: '#E0E5F2',
            200: '#E1E9F8',
            300: '#F4F7FE',
            400: '#E9EDF7',
            500: '#8F9BBA',
            600: '#A3AED0',
            700: '#707EAE',
            800: '#707EAE',
            900: '#1B2559',
        },
        red: {
            100: '#FED7D7',
            500: '#EE5D50',
            550: '#C53030',
            600: '#E31A1A',
            650: '#C53030',
        },
        blue: {
            50: '#EFF4FB',
            400: '#4299E1',
            500: '#3965FF',
        },
        orange: {
            100: '#FFF6DA',
            500: '#FFB547',
        },
        green: {
            // 100: '#E6FAF5',
            100: '#C6F6D5',
            400: '#48BB78',
            500: '#01B574',
        },
        navy: {
            50: '#d0dcfb',
            100: '#aac0fe',
            200: '#a3b9f8',
            300: '#728fea',
            400: '#3652ba',
            500: '#1b3bbb',
            600: '#24388a',
            650: '#2B3674',
            700: '#1B254B',
            800: '#111c44',
            900: '#0b1437',
        },
        gray: {
            100: '#FAFCFE',
            150: '#E2E8F0',
            400: '#A0AEC0',
            500: '#707EAE',
        },
        purple: {
            100: '#D6BCFA',
            500: '#4318FF',
            600: '#6B46C1',
        },
    },
    styles: {
        global: (props: any) => ({
            body: {
                overflowX: 'hidden',
                bg: mode('#fdfeff', 'navy.900')(props),
                fontFamily: 'DM Sans',
                letterSpacing: '-0.5px',
            },
            input: {
                color: 'gray.700',
            },
            html: {
                fontFamily: 'DM Sans',
            },
        }),
    },
};
