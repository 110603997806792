import { Box, Button, Flex, Grid, HStack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from 'react';
import { useMemo } from 'react';
import { fYyyyMmDdHhMm } from 'utils/format-time';
import { MachineDetailType } from '../MachineDetailType';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import FormProvider, { RHFTextField } from 'components/hook-form';
import { useEditMachineInformationMutation } from 'graphql/mutations/machine/__generated__/editMachineInformation.generated';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { GetMachineByIdDocument } from 'graphql/queries/machine/__generated__/getMachineById.generated';
import GranProEditBtn from '../../../../../../components/button/GranProEditBtn';
import { useEditable } from '../../../../../../components/hooks/useEditable';

type EditMachineFormValuesProps = {
    machineName: string;
    isEmittingShopBeacon?: boolean;
    teamViewerId: string;
};
export default function MachineDetailInfo(props: MachineDetailType) {
    const { machine } = props;
    const { accountType } = useAuthContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const { t } = useTranslation(['common', 'machine']);
    const {
        isOpen: isOpenEditMachineMutationResult,
        onOpen: onOpenEditMachineMutationResult,
        onClose: onCloseEditMachineMutationResult,
    } = useDisclosure();

    const [editMachineInformationFunc, editMachineInformationMutationResult] = useEditMachineInformationMutation();
    const { isEdit, onChangeEditable, onEditDisable, ...other } = useEditable(false);

    const EditMachineSchema = Yup.object().shape({
        machineName: Yup.string().required(`${t('machineRequiredMessage', { ns: 'machine' })}`),
        teamViewerId: Yup.string()
            .notRequired()
            .max(10, `${t('teamviewerMaxLengthMessage', { ns: 'machine' })}`),
    });
    const defaultValues = useMemo(
        () => ({
            machineName: machine.machineName || '',
            isEmittingShopBeacon: machine.isEmittingShopBeacon,
            teamViewerId: machine.teamViewerId || '',
        }),
        [machine],
    );
    const methods = useForm({
        resolver: yupResolver(EditMachineSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        reset,
        watch,
    } = methods;

    const values = watch();

    const onHandleEditMachine = async (data: EditMachineFormValuesProps) => {
        try {
            await editMachineInformationFunc({
                variables: {
                    input: {
                        machineId: machine.id,
                        name: data.machineName === machine.machineName ? undefined : data.machineName,
                        isEmittingShopBeacon:
                            (typeof data.isEmittingShopBeacon === 'boolean' &&
                                data.isEmittingShopBeacon === machine.isEmittingShopBeacon) ||
                            typeof data.isEmittingShopBeacon !== 'boolean'
                                ? undefined
                                : data.isEmittingShopBeacon,
                        teamViewerId:
                            machine.teamViewerId && data.teamViewerId && data.teamViewerId === machine.teamViewerId
                                ? undefined
                                : data.teamViewerId,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onEditDisable();
                        onOpenEditMachineMutationResult();
                    }
                },
                onError: async () => {
                    onOpenEditMachineMutationResult();
                },
                refetchQueries: () => [
                    {
                        query: GetMachineByIdDocument,
                        variables: {
                            input: {
                                machineId: machine.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const onHandleCancel = () => {
        onEditDisable();
        reset();
    };

    const handleSuccessClose = () => {
        window.location.reload();
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenEditMachineMutationResult}
            onClose={onCloseEditMachineMutationResult}
            onOpen={onOpenEditMachineMutationResult}
            onExtraAfterSuccessHandler={handleSuccessClose}
            mutationResult={editMachineInformationMutationResult}
            toastSuccessTitle={t('editMachineSuccessTitle', { ns: 'machine' })}
            toastSuccessDescription={t('editMachineSuccessMessage', { ns: 'machine' })}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onHandleEditMachine)}>
                <Card py={8} px={{ base: '20px', lg: '30px', '2xl': '30px' }}>
                    <Flex direction={'row'} justifyContent="space-between">
                        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
                            {t('machineInfoLabel', { ns: 'common' })}
                        </Text>
                        {accountType === AccountType.dealer && (
                            <GranProEditBtn
                                isEdit={isEdit}
                                onChangeEditable={onChangeEditable}
                                disabled={accountType !== AccountType.dealer}
                                onEditDisable={onEditDisable}
                                onExtraHandleCancel={onHandleCancel}
                                {...other}
                            />
                        )}
                    </Flex>
                    <Grid
                        templateColumns={{
                            base: '1fr',
                            lg: '1fr',
                        }}
                        templateRows={{
                            base: '1fr',
                            // lg: 'repeat(2, 1fr)',
                        }}
                        gap={{ base: '20px', xl: '20px' }}
                    >
                        <Flex direction={'column'} gap={'10px'}>
                            {isEdit ? (
                                <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                    <Box minW="105px">
                                        <Text
                                            color={textColorSecondary}
                                            fontSize="14px"
                                            fontWeight="400"
                                            mr={1}
                                            alignItems="center"
                                            minW={25}
                                        >
                                            {t('machineNameLabel', { ns: 'common' })}
                                        </Text>
                                    </Box>

                                    <RHFTextField
                                        name="machineName"
                                        isRequired={false}
                                        borderRadius={'10px !important'}
                                        fontWeight="500"
                                        variant="main"
                                        maxW={'240px'}
                                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                        maxH="36px"
                                    />
                                </HStack>
                            ) : (
                                <Flex direction="row" mt={5} alignItems="stretch">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                        {t('machineNameLabel', { ns: 'common' })}
                                    </Text>
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                        {machine.machineName}
                                    </Text>
                                </Flex>
                            )}
                            <Flex direction="row" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('shopName', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {machine.shop.name}
                                </Text>
                            </Flex>

                            <Flex direction="row" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('dealerName', { ns: 'common' })}
                                </Text>
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {machine.shop.dealer.name}
                                </Text>
                            </Flex>

                            <Flex direction="row" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('prokit', { ns: 'common' })}
                                </Text>
                                {machine.proKit?.serialCode ? (
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                        {machine.proKit.serialCode}
                                    </Text>
                                ) : (
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700"></Text>
                                )}
                            </Flex>

                            <Flex direction="row" alignItems="center">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('activateLabel', { ns: 'common' })}
                                </Text>
                                {machine.proKit?.lastActiveAt ? (
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                        {fYyyyMmDdHhMm(machine.proKit.lastActiveAt)}
                                    </Text>
                                ) : (
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700"></Text>
                                )}
                            </Flex>

                            {isEdit ? (
                                <HStack justifyContent="flex-start" alignItems="center" mt={3}>
                                    <Box minW="105px">
                                        <Text
                                            color={textColorSecondary}
                                            fontSize="14px"
                                            fontWeight="400"
                                            mr={1}
                                            alignItems="center"
                                            minW={25}
                                        >
                                            {t('teamViewerID', { ns: 'common' })}
                                        </Text>
                                    </Box>

                                    <RHFTextField
                                        name="teamViewerId"
                                        isRequired={false}
                                        borderRadius={'10px !important'}
                                        fontWeight="500"
                                        variant="main"
                                        maxW={'240px'}
                                        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                        maxH="36px"
                                    />
                                </HStack>
                            ) : (
                                <Flex direction="row" alignItems="center">
                                    <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                        {t('teamViewerID', { ns: 'common' })}
                                    </Text>
                                    <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                        {machine.teamViewerId}
                                    </Text>
                                </Flex>
                            )}

                            {isEdit && (
                                <Box
                                    display="flex"
                                    flexDirection={{ base: 'row' }}
                                    // gap={{ base: 5, md: 5 }}
                                    justifyContent="center"
                                    w="100%"
                                    mt={6}
                                    py={1}
                                >
                                    <Button
                                        py="0px"
                                        px="8px"
                                        variant="brand"
                                        fontWeight="500"
                                        minW="140px"
                                        maxH="40px"
                                        borderRadius="10px"
                                        backgroundColor="#4318FF"
                                        type="submit"
                                        isLoading={isSubmitting}
                                        disabled={
                                            values.machineName === machine.machineName &&
                                            values.teamViewerId === machine.teamViewerId
                                        }
                                    >
                                        <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                                    </Button>
                                </Box>
                            )}
                        </Flex>
                    </Grid>
                </Card>
            </FormProvider>
        </ApolloMutationBoundary>
    );
}
