import { Flex, Input, Select, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import SkeletonTableRow from 'components/skeleton/SkeletonTableRow';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import { useTranslation } from 'react-i18next';
import { useGetDistributorByIdQuery } from 'graphql/queries/distributor/__generated__/getDistributorById.generated';
import { useDistributorGetShopsListOfDealerLazyQuery } from '../../../../../../graphql/queries/dealer/__generated__/distributorGetShopsListOfDealer.generated';
import DistributorIncomeTableContainer from './DistributorIncomeTableContainer';
import { PaginationState } from '@tanstack/react-table';
import { DefaultPageSize } from 'constant/defaultValues';

export default function IncomeListByDistributor() {
    const { userId, accountType } = useAuthContext();
    const focusColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    const [provinceFilter, setProvinceFilter] = useState('');
    const [dealerId, setDealerId] = useState<number | null>(null);

    const [paginationState, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: DefaultPageSize,
    });

    const queryDealerListByDistributorResult = useGetDistributorByIdQuery({
        variables: {
            input: {
                distributorId: userId,
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    const [getShopsListByDistributorIdLazyFunc, queryShopListByDistributorResult] =
        useDistributorGetShopsListOfDealerLazyQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
        });

    const handleFilterDealer = async (event: ChangeEvent<HTMLSelectElement>) => {
        const dealerIdValue = event.target.value === 'all' ? null : event.target.value;
        if (dealerIdValue) {
            await getShopsListByDistributorIdLazyFunc({
                variables: {
                    input: {
                        dealerId: Number(dealerIdValue),
                        filterOption: {
                            province: provinceFilter === '' ? null : provinceFilter,
                            status: undefined,
                        },
                        args: {
                            first: paginationState.pageSize,
                            after: `${Number(paginationState.pageSize * paginationState.pageIndex)}`,
                        },
                    },
                },
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-first',
            });
        }
        setDealerId && setDealerId(Number(dealerIdValue));
    };
    useEffect(() => {
        const getShopList = async () => {
            if (dealerId) {
                await getShopsListByDistributorIdLazyFunc({
                    variables: {
                        input: {
                            dealerId: Number(dealerId),
                            filterOption: {
                                province: provinceFilter === '' ? null : provinceFilter,
                                status: undefined,
                            },
                            args: {
                                first: paginationState.pageSize,
                                after: `${Number(paginationState.pageSize * paginationState.pageIndex)}`,
                            },
                        },
                    },
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'cache-first',
                });
            }
        };
        getShopList().catch((e) => {
            console.error(e);
        });
    }, [provinceFilter, dealerId, paginationState.pageSize, paginationState.pageIndex]);

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: paginationState.pageSize,
        });
    }, [provinceFilter, dealerId, paginationState.pageSize]);

    return (
        <Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }} minH="30vh">
            <Flex
                w="100%"
                align={{ sm: 'flex-start', lg: 'stretch' }}
                pt={{ sm: '20px', lg: '30px' }}
                flexDirection={{ sm: 'column', lg: 'row' }}
                px={{ sm: '0px', lg: '50px' }}
            >
                <Flex
                    justifyContent={{ sm: 'space-around', lg: 'flex-start' }}
                    w="100%"
                    mb={{ sm: '20px', lg: '30px' }}
                    px={{ sm: '10px', lg: '0px' }}
                >
                    {accountType === AccountType.distributor && (
                        <ApolloQueryBoundary
                            queryResult={queryDealerListByDistributorResult}
                            render={({
                                data: {
                                    distributor: { dealers },
                                },
                            }) => (
                                <Select
                                    fontSize="14px"
                                    variant="main"
                                    borderRadius="10px"
                                    h="40px"
                                    maxH="44px"
                                    maxW={150}
                                    me={{ base: '10px', md: '20px' }}
                                    onChange={(e) => handleFilterDealer(e)}
                                >
                                    <option value="all">{t('accountTypeOption3', { ns: 'common' })}</option>
                                    {dealers.map((dealer) => (
                                        <option key={dealer.id} value={dealer.id}>
                                            {dealer.name}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        />
                    )}

                    <Input
                        w={{ base: '100%', lg: '320px' }}
                        placeholder={t('searchProvinceHolder', { ns: 'common' })}
                        borderRadius="10px"
                        marginLeft={{ sm: '8px', md: '16px' }}
                        h="40px"
                        maxH="44px"
                        padding="16px"
                        _focus={{ borderColor: focusColor, borderWidth: '0.5px' }}
                        fontSize={'13px'}
                        value={provinceFilter}
                        onChange={(e) => setProvinceFilter(e.target.value)}
                    />
                </Flex>
            </Flex>

            {accountType === AccountType.distributor && (
                <ApolloQueryBoundary
                    queryResult={queryShopListByDistributorResult}
                    loadingComponent={<SkeletonTableRow />}
                    noDataComponent={<NoDataComponent />}
                    render={({
                        data: {
                            get_shops_list_of_dealer: { totalCount, edges, pageInfo },
                        },
                    }) => (
                        <DistributorIncomeTableContainer
                            totalCount={dealerId ? totalCount : 0}
                            edges={edges}
                            pageInfo={pageInfo}
                            paginationState={paginationState}
                            setPagination={setPagination}
                        />
                    )}
                />
            )}
        </Flex>
    );
}

function NoDataComponent() {
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Flex align="center" justifyContent="center" w="100%">
            <Text color={textColor} fontSize={{ base: '14px', lg: '16px' }} fontWeight="700" lineHeight="100%">
                {t('displayIncomeListHeading', { ns: 'common' })}
            </Text>
        </Flex>
    );
}
