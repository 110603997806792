import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetProKitSubscriptionPlanAfterDemoMutationVariables = Types.Exact<{
    input: Types.SetProKitSubscriptionPlanAfterDemoInput;
}>;

export type SetProKitSubscriptionPlanAfterDemoMutation = {
    __typename?: 'Mutation';
    set_pro_kit_subscription_plan_after_demo: {
        __typename?: 'ProKit';
        id: number;
        demonstrationPeriodEndAt?: Date | null;
        subscriptionPlan?: Types.GranProSubscriptionPlan | null;
        distributor?: { __typename?: 'Distributor'; id: number; name: string } | null;
        machine?: { __typename?: 'Machine'; id: number; machineName: string } | null;
        subscriptionPlanSchedule?: {
            __typename?: 'GranProSubscriptionPlanSchedule';
            subscriptionPlan: Types.GranProSubscriptionPlan;
            validFrom: Date;
        } | null;
    };
};

export const SetProKitSubscriptionPlanAfterDemoDocument = gql`
    mutation setProKitSubscriptionPlanAfterDemo($input: SetProKitSubscriptionPlanAfterDemoInput!) {
        set_pro_kit_subscription_plan_after_demo(input: $input) {
            id
            distributor {
                id
                name
            }
            machine {
                id
                machineName
            }
            demonstrationPeriodEndAt
            subscriptionPlan
            subscriptionPlanSchedule {
                subscriptionPlan
                validFrom
            }
        }
    }
`;
export type SetProKitSubscriptionPlanAfterDemoMutationFn = Apollo.MutationFunction<
    SetProKitSubscriptionPlanAfterDemoMutation,
    SetProKitSubscriptionPlanAfterDemoMutationVariables
>;

/**
 * __useSetProKitSubscriptionPlanAfterDemoMutation__
 *
 * To run a mutation, you first call `useSetProKitSubscriptionPlanAfterDemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProKitSubscriptionPlanAfterDemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProKitSubscriptionPlanAfterDemoMutation, { data, loading, error }] = useSetProKitSubscriptionPlanAfterDemoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetProKitSubscriptionPlanAfterDemoMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SetProKitSubscriptionPlanAfterDemoMutation,
        SetProKitSubscriptionPlanAfterDemoMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        SetProKitSubscriptionPlanAfterDemoMutation,
        SetProKitSubscriptionPlanAfterDemoMutationVariables
    >(SetProKitSubscriptionPlanAfterDemoDocument, options);
}
export type SetProKitSubscriptionPlanAfterDemoMutationHookResult = ReturnType<
    typeof useSetProKitSubscriptionPlanAfterDemoMutation
>;
export type SetProKitSubscriptionPlanAfterDemoMutationResult =
    Apollo.MutationResult<SetProKitSubscriptionPlanAfterDemoMutation>;
export type SetProKitSubscriptionPlanAfterDemoMutationOptions = Apollo.BaseMutationOptions<
    SetProKitSubscriptionPlanAfterDemoMutation,
    SetProKitSubscriptionPlanAfterDemoMutationVariables
>;
