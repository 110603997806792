import { DealerDetailProps } from './dealerDetailTypes';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { useDisclosure, UseDisclosureProps } from '@chakra-ui/react';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useDeleteDealerMutation } from 'graphql/mutations/distributor/__generated__/deleteDealer.generated';
import { PATH_DASHBOARD } from 'paths';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GetDealersListOfDistributorDocument } from 'graphql/queries/distributor/__generated__/getDealersListOfDistributor.generated';
import ConfirmDeleteDealerModal from './ConfirmDeleteDealerModal';

type DeleteDealerControlFeatureProps = DealerDetailProps &
    UseDisclosureProps & {
        btnColorScheme?: string;
    };
export default function DeleteDealerControlFeature(props: DeleteDealerControlFeatureProps) {
    const { userId } = useAuthContext();
    const history = useHistory();
    const { dealer, isOpen, onOpen, onClose } = props;
    const {
        isOpen: isOpenMutationBoundaryResult,
        onOpen: onOpenMutationBoundaryResult,
        onClose: onCloseMutationBoundaryResult,
    } = useDisclosure();
    const { t } = useTranslation(['common', 'dealer']);
    const [deleteDealerMutationFunc, deleteDealerMutationResult] = useDeleteDealerMutation();

    const onHandleDeleteDealer = async () => {
        try {
            onClose();
            await deleteDealerMutationFunc({
                variables: {
                    input: {
                        dealerId: dealer.id,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenMutationBoundaryResult();
                    }
                },
                onError: async () => {
                    onOpenMutationBoundaryResult();
                },
                refetchQueries: () => [
                    {
                        query: GetDealersListOfDistributorDocument,
                        variables: {
                            input: {
                                distributorId: userId,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            onClose();
            console.error(error);
        }
    };

    const onHandleDeleteDealerSuccess = () => {
        history.push(PATH_DASHBOARD.dealer.overview);
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenMutationBoundaryResult}
            onClose={onCloseMutationBoundaryResult}
            onOpen={onOpenMutationBoundaryResult}
            onExtraAfterSuccessHandler={onHandleDeleteDealerSuccess}
            mutationResult={deleteDealerMutationResult}
            toastSuccessDescription={t('deleteDealerSuccessMessage', { ns: 'dealer' })}
            toastSuccessTitle={t('deleteDealerSuccessTitle', { ns: 'dealer' })}
        >
            <ConfirmDeleteDealerModal
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                onHandleConfirm={onHandleDeleteDealer}
            />
        </ApolloMutationBoundary>
    );
}
