// noinspection DuplicatedCode

import {
    Button,
    Flex,
    Icon,
    Select,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import {
    ColumnFiltersState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    OnChangeFn,
    PaginationState,
    useReactTable,
} from '@tanstack/react-table';
import { CursorPaginationPageInfo } from 'graphql/types/other';
import { PickValueOf } from 'types/common';
import { useMemo, useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { IncomeObj } from '../variables';
import { DefaultAvatarSrc } from 'constant/defaultValues';
import TableNoData from 'components/table/TableNoData';
import { useTranslation } from 'react-i18next';
import { CreateIncomeListColumn } from './CreateIncomeListColumn';
import { DistributorGetShopsListOfDealerQuery } from '../../../../../../graphql/queries/dealer/__generated__/distributorGetShopsListOfDealer.generated';
import { useAuthContext } from '../../../../../../layouts/auth/useAuthContext';
import { createPagesFromTotal } from 'utils/tables';

export type DistributorIncomeTableContainerProp = {
    totalCount: number;
    pageInfo: CursorPaginationPageInfo;
    paginationState: PaginationState;
    setPagination: (paginationState: PaginationState) => void;
    edges: PickValueOf<PickValueOf<DistributorGetShopsListOfDealerQuery, 'get_shops_list_of_dealer'>, 'edges'>;
};
export default function DistributorIncomeTableContainer(props: DistributorIncomeTableContainerProp) {
    const { accountType } = useAuthContext();
    const {
        edges,
        totalCount,
        paginationState: { pageSize, pageIndex },
        setPagination,
    } = props;
    // ----------------------------------------------------------------------
    const [isLargerThan960] = useMediaQuery('(min-width: 960px)');
    const [isLargerThan320] = useMediaQuery('(min-width: 460px)');
    const textColor = useColorModeValue('#2B3674', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const brandColor = useColorModeValue('brand.500', 'brand.400');
    const { t } = useTranslation(['common']);
    // ----------------------------------------------------------------------
    const defaultData = useMemo(() => {
        const result: IncomeObj[] = edges.map((shop) => ({
            id: shop.node.id,
            shop: {
                name: shop.node.name,
                shopAvatar: shop.node.iconUrl ?? DefaultAvatarSrc,
                shopId: shop.node.id,
            },
            province: shop.node.province,
            dealerName: shop.node.dealer.name,
            noOfMachine: shop.node.machines.length,
            // uncollected: shop.node?.incomeConversionRate
            //     ? shop.node.totalUncollectedIncome * shop.node?.incomeConversionRate
            //     : shop.node.totalUncollectedIncome,
            // lastCollection: shop.node.lastIncomeCollectedAt,
            // TODO: dummy data https://luxzajp.atlassian.net/browse/GPR-769?focusedCommentId=12164
            uncollected: 99999,
            lastCollection: new Date(),
        }));
        return result;
    }, []);

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const columnHelper = createColumnHelper<IncomeObj>();
    const columns = CreateIncomeListColumn(isLargerThan960, isLargerThan320, columnHelper, textColor, t, accountType);
    const [data] = useState(() => [...defaultData]);

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination as OnChangeFn<PaginationState>,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    // ----------------------------------------------------------------------

    return totalCount > 0 ? (
        <>
            <Table variant="simple" color="gray.500" mb="24px">
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Th pe="10px" borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <Flex
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : '',
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}
                                                justify="space-between"
                                                align="center"
                                                fontSize={{ sm: '10px', lg: '12px' }}
                                                color="gray.400"
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {{
                                                    asc: '',
                                                    desc: '',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </Flex>
                                        )}
                                    </Th>
                                );
                            })}
                        </Tr>
                    ))}
                </Thead>

                <Tbody>
                    {table.getCoreRowModel().rows.map((row) => {
                        return (
                            <Tr px="20px" key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <Td
                                            key={cell.id}
                                            fontSize={{ sm: '14px' }}
                                            minW={{
                                                sm: `${
                                                    cell.id === 'province' ||
                                                    cell.id === 'dealerName' ||
                                                    cell.id === 'machine'
                                                        ? '100px'
                                                        : '0px'
                                                }`,
                                                md: `${
                                                    cell.id === 'province' || cell.id === 'dealerName' ? '150px' : '0px'
                                                }`,
                                                lg: 'auto',
                                            }}
                                            borderColor={borderColor}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>

            <Flex
                w="100%"
                justify={{ sm: 'flex-end', md: 'space-between' }}
                px="20px"
                pt="10px"
                pb="5px"
                direction={{ sm: 'column', md: 'row' }}
            >
                <Flex align="center" justifyContent="center">
                    <Text me="10px" minW="max-content" fontSize="sm" color="gray.500" fontWeight="normal">
                        {t('rowsPerPage', { ns: 'common' })}
                    </Text>
                    <Select
                        w="80px"
                        fontSize="sm"
                        variant="main"
                        value={pageSize}
                        onChange={(e) => table.setPageSize(Number(e.target.value))}
                    >
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </Select>
                </Flex>
                <div className="flex items-center gap-2">
                    <Stack
                        direction="row"
                        alignSelf="flex-end"
                        spacing="4px"
                        ms="auto"
                        mt={{ sm: '20px', md: '0px' }}
                        justifyContent="center"
                    >
                        <Button
                            variant="no-effects"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                            transition="all .5s ease"
                            w="40px"
                            h="40px"
                            borderRadius="50%"
                            bg="transparent"
                            border="1px solid"
                            borderColor={borderColor}
                            display={pageSize === 5 ? 'none' : table.getCanPreviousPage() ? 'flex' : 'none'}
                            _hover={{
                                bg: 'whiteAlpha.100',
                                opacity: '0.7',
                            }}
                        >
                            <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
                        </Button>
                        {createPagesFromTotal(totalCount, pageSize).map((pageNumber, index) => {
                            return (
                                <Button
                                    variant="no-effects"
                                    transition="all .5s ease"
                                    onClick={() => table.setPageIndex(pageNumber - 1)}
                                    w="40px"
                                    h="40px"
                                    borderRadius="50%"
                                    bg={pageNumber === pageIndex + 1 ? brandColor : 'transparent'}
                                    border={pageNumber === pageIndex + 1 ? 'none' : '1px solid lightgray'}
                                    _hover={
                                        pageNumber === pageIndex + 1
                                            ? {
                                                  opacity: '0.7',
                                              }
                                            : {
                                                  bg: 'whiteAlpha.100',
                                              }
                                    }
                                    key={index}
                                >
                                    <Text fontSize="sm" color={pageNumber === pageIndex + 1 ? '#fff' : textColor}>
                                        {pageNumber}
                                    </Text>
                                </Button>
                            );
                        })}
                        <Button
                            variant="no-effects"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                            transition="all .5s ease"
                            w="40px"
                            h="40px"
                            borderRadius="50%"
                            bg="transparent"
                            border="1px solid"
                            borderColor={borderColor}
                            display={pageSize === 5 ? 'none' : table.getCanNextPage() ? 'flex' : 'none'}
                            _hover={{
                                bg: 'whiteAlpha.100',
                                opacity: '0.7',
                            }}
                        >
                            <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
                        </Button>
                    </Stack>
                </div>
            </Flex>
        </>
    ) : (
        <TableNoData />
    );
}
