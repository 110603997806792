import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import ShopIncomeCollectionEventModal from './ShopIncomeCollectionEventModal';

type ResetBtnProps = {
    shopId: number;
    shopName: string;
    machineId: number;
    income: number;
    machineName: string;
};
export default function ResetBtn(props: ResetBtnProps) {
    const { shopId, shopName, machineId, machineName, income } = props;
    const {
        isOpen: isOpenGetShopCollection,
        onOpen: onOpenGetShopCollection,
        onClose: onCloseGetShopCollection,
    } = useDisclosure();
    return (
        <div>
            <Flex direction="column">
                <Button
                    colorScheme="red"
                    variant="outline"
                    // p="15px 40px"
                    p={{ base: 'auto', '2xl': '15px 40px' }}
                    fontSize="sm"
                    h="36px"
                    w={{ base: 'auto', '2xl': '140px' }}
                    borderRadius={10}
                    fontWeight="500"
                    ms="auto"
                    disabled={income < 1}
                    onClick={onOpenGetShopCollection}
                >
                    Reset
                </Button>
            </Flex>
            <ShopIncomeCollectionEventModal
                isOpen={isOpenGetShopCollection}
                onClose={onCloseGetShopCollection}
                onOpen={onOpenGetShopCollection}
                shopId={shopId}
                shopName={shopName}
                machineId={machineId}
                machineName={machineName}
            />
        </div>
    );
}
