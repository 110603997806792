// noinspection DuplicatedCode

import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { now } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import dummyShopTableData, { ShopObj } from '../../../shop/overview/variables';
import { fYyyyMmDdHhMm } from '../../../../../../utils/format-time';

export type ActivationHistoryObj = {
    id: number;
    activationDate: number | string | Date;
    shop: ShopObj;
};

export const dummyActivationHistoryTableData: ActivationHistoryObj[] = [...Array(3)].map((_, index) => ({
    id: index,
    activationDate: now(),
    shop: dummyShopTableData[index],
}));

export default function ProkitActivationHistoryCard(props: { tableData: ActivationHistoryObj[]; [x: string]: any }) {
    const { tableData, ...rest } = props;
    const textColorPrimary = useColorModeValue('navy.650', 'white');

    const [sorting, setSorting] = useState<SortingState>([]);
    const defaultData = tableData;

    const [data] = useState(() => [...defaultData]);
    const columnHelper = createColumnHelper<ActivationHistoryObj>();
    const columns = [
        columnHelper.accessor('activationDate', {
            id: 'activationDate',
            header: () => (
                <Text
                    justifyContent="space-between"
                    align={{ base: 'start', lg: 'center', '2xl': 'center' }}
                    fontSize={{ sm: '16px' }}
                    fontWeight="500"
                    textTransform="capitalize"
                    color="secondaryGray.600"
                >
                    Date
                </Text>
            ),
            cell: (info: any) => (
                <Text color={textColorPrimary} fontSize={{ sm: '16px' }} fontWeight="600">
                    {fYyyyMmDdHhMm(info.getValue())}
                </Text>
            ),
        }),
        columnHelper.accessor('shop', {
            id: 'shop',
            header: () => (
                <Text
                    justifyContent="space-between"
                    align={{ base: 'start', lg: 'center', '2xl': 'center' }}
                    fontSize={{ sm: '16px' }}
                    fontWeight="500"
                    textTransform="capitalize"
                    color="secondaryGray.600"
                >
                    Shop
                </Text>
            ),
            cell: (info) => (
                <Text color={textColorPrimary} fontSize={{ sm: '16px' }} fontWeight="500">
                    {info.getValue().name[0]}
                </Text>
            ),
        }),
    ];
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    return (
        <Card py="20px" px={{ base: '20px', md: '35px', xl: '50px' }} borderRadius="30px" {...rest}>
            <Flex direction={'row'} justifyContent="space-between" mt={5}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mb="4px">
                    Activation history
                </Text>
            </Flex>

            <Flex direction={'column'} gap={'5px'} mb={{ base: '20px' }} pb={{ base: '20px' }}>
                <Table variant="simple" color="gray.500">
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            pe="10px"
                                            pl="0px"
                                            borderColor="gray.150"
                                            cursor="pointer"
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            <Flex
                                                justifyContent="space-between"
                                                align={{ base: 'start', lg: 'center', '2xl': 'center' }}
                                                fontSize={{ sm: '10px', lg: '12px' }}
                                                color="gray.400"
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {{
                                                    asc: '',
                                                    desc: '',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </Flex>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table
                            .getRowModel()
                            .rows.slice(0, 11)
                            .map((row) => {
                                return (
                                    <Tr key={row.id}>
                                        {row.getVisibleCells().map((cell, idx) => {
                                            return (
                                                <Td
                                                    key={idx}
                                                    fontSize={{ sm: '14px' }}
                                                    minW={{ base: '90px', lg: '90px', '2xl': '150px' }}
                                                    minH={'45px'}
                                                    h={'50px'}
                                                    pl="0px"
                                                    borderBottom="1px solid #E2E8F0"
                                                    borderColor="#E2E8F0"
                                                >
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </Td>
                                            );
                                        })}
                                    </Tr>
                                );
                            })}
                    </Tbody>
                </Table>
            </Flex>
        </Card>
    );
}
