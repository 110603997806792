import {
    Button,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type UpgradeProkitSuccessModalProps = UseDisclosureProps & {
    [key: string]: any;
};
export default function UpgradeProkitSuccessModal(props: UpgradeProkitSuccessModalProps) {
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    const { isOpen, onClose } = props;
    const closeModalHandler = () => {
        onClose();
    };
    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModalHandler} size="2xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody w="100%" pt={4} pb={1} minH={'200px'}>
                    <Flex align="center" direction="column" justifyContent="center" w="100%" minH={'200px'} gap={12}>
                        <VStack gap={5}>
                            <Text
                                color={textColor}
                                fontSize={{ base: '12px', lg: '14px' }}
                                fontWeight="500"
                                lineHeight="100%"
                            >
                                本日からEnterpriseプランに切り替わります。
                            </Text>
                            <VStack gap={0}>
                                <Text
                                    color={textColor}
                                    fontSize={{ base: '12px', lg: '14px' }}
                                    fontWeight="500"
                                    lineHeight="100%"
                                >
                                    マシンにプラン変更を反映するために、
                                </Text>
                                <Text
                                    color={textColor}
                                    fontSize={{ base: '12px', lg: '14px' }}
                                    fontWeight="500"
                                    lineHeight="100%"
                                >
                                    マシンを再起動してください。
                                </Text>
                            </VStack>
                        </VStack>
                    </Flex>
                </ModalBody>
                <ModalFooter display="flex" alignItems="center" justifyContent="center" pb={8}>
                    <HStack>
                        <Button
                            variant="solid"
                            fontSize="sm"
                            color="white"
                            minW="140px"
                            backgroundColor="#A3AED0"
                            borderRadius={10}
                            fontWeight="500"
                            onClick={closeModalHandler}
                        >
                            {t('closeBtnLabel', { ns: 'common' })}
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
