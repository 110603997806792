// noinspection DuplicatedCode

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { Flex } from '@chakra-ui/react';
import { MachineStatusFilter } from 'types/graphql';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import SkeletonTableRow from 'components/skeleton/SkeletonTableRow';
import MachineTableContainer from './MachineTableContainer';
import { AccountType } from 'types/user';
import StatusMachineSelectList from 'components/hook-form/StatusMachineSelectList';
import { PaginationState } from '@tanstack/react-table';
import { DefaultPageSize } from '../../../../../../constant/defaultValues';
import { useGetMachineListOfDealerQuery } from '../../../../../../graphql/queries/machine/__generated__/getMachineListOfDealer.generated';

export default function MachineListByDealer() {
    const { accountType, userId } = useAuthContext();
    const [machineStatus, setMachineStatus] = useState<MachineStatusFilter | 'all'>('all');

    const [paginationState, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: DefaultPageSize,
    });

    const getMachineListOfDealerResult = useGetMachineListOfDealerQuery({
        variables: {
            input: {
                dealerId: userId,
                filterOption: {
                    status: machineStatus === 'all' ? null : machineStatus,
                },
                args: {
                    first: paginationState.pageSize,
                    after: `${Number(paginationState.pageSize * paginationState.pageIndex)}`,
                },
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: paginationState.pageSize,
        });
    }, [machineStatus]);

    return (
        <Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }} minH="75vh">
            <Flex
                w="100%"
                align={{ sm: 'flex-start', lg: 'stretch' }}
                pt={{ sm: '20px', lg: '30px' }}
                flexDirection={{ sm: 'column', lg: 'row' }}
                px={{ sm: '0px', lg: '20px' }}
            >
                <Flex
                    justifyContent={{ sm: 'space-around', lg: 'flex-start' }}
                    w="100%"
                    mb={{ sm: '20px', lg: '30px' }}
                    px={{ sm: '10px', lg: '0px' }}
                >
                    <StatusMachineSelectList machineStatus={machineStatus} setMachineStatus={setMachineStatus} />
                </Flex>
            </Flex>

            {accountType === AccountType.dealer && (
                <ApolloQueryBoundary
                    queryResult={getMachineListOfDealerResult}
                    // noDataComponent={<NoMachineDataComponent />}
                    loadingComponent={<SkeletonTableRow />}
                    render={({
                        data: {
                            get_machine_list_of_dealer: { totalCount, edges, pageInfo },
                        },
                    }) => (
                        <MachineTableContainer
                            totalCount={totalCount}
                            edges={edges}
                            pageInfo={pageInfo}
                            paginationState={paginationState}
                            setPagination={setPagination}
                        />
                    )}
                />
            )}
        </Flex>
    );
}
