import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShopsListOfDealerQueryVariables = Types.Exact<{
    input: Types.GetShopsListOfDealerInput;
}>;

export type GetShopsListOfDealerQuery = {
    __typename?: 'Query';
    get_shops_list_of_dealer: {
        __typename?: 'ShopConnection';
        totalCount: number;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
        edges: Array<{
            __typename?: 'ShopEdge';
            cursor: string;
            node?: {
                __typename?: 'Shop';
                id: number;
                name: string;
                beaconId?: string | null;
                status: Types.ShopStatus;
                email: string;
                iconUrl?: string | null;
                languageCode: string;
                countryCode: string;
                zipCode: string;
                province: string;
                address: string;
                phoneNumber: string;
                isActivated: boolean;
                lastInformationEditedAt?: Date | null;
                lastIncomeCollectedAt?: Date | null;
                totalUncollectedIncome: number;
                incomeConversionRate: number;
                dealer: { __typename?: 'Dealer'; id: number; name: string };
                machines: Array<{
                    __typename?: 'Machine';
                    id: number;
                    dummyPlayerId?: number | null;
                    serviceMode: Types.ProKitServiceModeOption;
                    freeModeStart?: Date | null;
                    freeModeEnd?: Date | null;
                    status: Types.MachineStatus;
                    lastLoginAt?: Date | null;
                    isPasswordRequired: boolean;
                    playerEntries: Array<{ __typename?: 'Player'; id: number; player_name: string } | null>;
                    proKit?: {
                        __typename?: 'ProKit';
                        id: number;
                        subscriptionPlan?: Types.GranProSubscriptionPlan | null;
                        status: Types.ProKitStatus;
                    } | null;
                }>;
            } | null;
        } | null>;
    };
};

export const GetShopsListOfDealerDocument = gql`
    query getShopsListOfDealer($input: GetShopsListOfDealerInput!) {
        get_shops_list_of_dealer(input: $input) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    name
                    beaconId
                    status
                    email
                    iconUrl
                    languageCode
                    countryCode
                    zipCode
                    province
                    address
                    phoneNumber
                    isActivated
                    lastInformationEditedAt
                    lastIncomeCollectedAt
                    totalUncollectedIncome
                    incomeConversionRate
                    dealer {
                        id
                        name
                    }
                    machines {
                        id
                        dummyPlayerId
                        serviceMode
                        freeModeStart
                        freeModeEnd
                        status
                        lastLoginAt
                        isPasswordRequired
                        playerEntries {
                            id
                            player_name
                        }
                        proKit {
                            id
                            subscriptionPlan
                            status
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useGetShopsListOfDealerQuery__
 *
 * To run a query within a React component, call `useGetShopsListOfDealerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsListOfDealerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsListOfDealerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShopsListOfDealerQuery(
    baseOptions: Apollo.QueryHookOptions<GetShopsListOfDealerQuery, GetShopsListOfDealerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetShopsListOfDealerQuery, GetShopsListOfDealerQueryVariables>(
        GetShopsListOfDealerDocument,
        options,
    );
}
export function useGetShopsListOfDealerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetShopsListOfDealerQuery, GetShopsListOfDealerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetShopsListOfDealerQuery, GetShopsListOfDealerQueryVariables>(
        GetShopsListOfDealerDocument,
        options,
    );
}
export type GetShopsListOfDealerQueryHookResult = ReturnType<typeof useGetShopsListOfDealerQuery>;
export type GetShopsListOfDealerLazyQueryHookResult = ReturnType<typeof useGetShopsListOfDealerLazyQuery>;
export type GetShopsListOfDealerQueryResult = Apollo.QueryResult<
    GetShopsListOfDealerQuery,
    GetShopsListOfDealerQueryVariables
>;
