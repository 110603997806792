import { Button, Flex, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { MachineDetailType } from '../MachineDetailType';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import CommonFeatureConfirmModal from 'components/modal/CommonFeatureConfirmModal';
import { useTranslation } from 'react-i18next';
import { useSendLaunchTeamViewerNotificationMutation } from 'graphql/mutations/machine/__generated__/sendLaunchTeamViewerNotification.generated';

type LaunchTeamViewerNotificationControlFeatureProps = MachineDetailType & {
    featureName: string;
    btnName: string;
    btnColorScheme?: string;
    isDisabled: boolean;
    [key: string]: any;
};

export default function LaunchTeamViewerNotificationControlFeature(
    props: LaunchTeamViewerNotificationControlFeatureProps,
) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { machine, featureName, btnName, btnColorScheme = 'red', isDisabled, ...rest } = props;
    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const { t } = useTranslation(['common', 'machine']);
    const {
        isOpen: isOpenSendLaunchTeamViewerNotificationMutationResult,
        onOpen: onOpenSendLaunchTeamViewerNotificationMutationResult,
        onClose: onCloseSendLaunchTeamViewerNotificationMutationResult,
    } = useDisclosure();

    const [sendLaunchTeamViewerNotificationMutationFunc, sendLaunchTeamViewerNotificationMutationResult] =
        useSendLaunchTeamViewerNotificationMutation();

    const onHandleSendLaunchTeamViewerNotificationMutation = async () => {
        try {
            onClose();
            await sendLaunchTeamViewerNotificationMutationFunc({
                variables: {
                    input: {
                        machineId: machine.id,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenSendLaunchTeamViewerNotificationMutationResult();
                    }
                },
                onError: async () => {
                    onOpenSendLaunchTeamViewerNotificationMutationResult();
                },
            });
        } catch (error) {
            onClose();
            console.error(error);
        }
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenSendLaunchTeamViewerNotificationMutationResult}
            onClose={onCloseSendLaunchTeamViewerNotificationMutationResult}
            onOpen={onOpenSendLaunchTeamViewerNotificationMutationResult}
            mutationResult={sendLaunchTeamViewerNotificationMutationResult}
            toastSuccessTitle={`${t('startTeamViewerLabel', { ns: 'machine' })}`}
            toastSuccessDescription={`${t('launchTeamViewerNotificationMachineSuccessMessage', { ns: 'machine' })}`}
        >
            <Flex justifyContent="center" alignItems="center" w="100%" {...rest}>
                <Flex direction="column" align="start" me="auto">
                    <Text color={textColor} fontSize="md" me="6px" fontWeight="500">
                        {featureName}
                    </Text>
                </Flex>
                {!isDisabled && (
                    <Button
                        // colorScheme={btnColorScheme}
                        variant="outline"
                        p="15px 40px"
                        fontSize="sm"
                        borderRadius={10}
                        fontWeight="500"
                        onClick={onOpen}
                        isDisabled={isDisabled}
                        borderColor={btnColorScheme}
                        h="38px"
                        w="140px"
                        borderWidth="2px"
                        color={btnColorScheme}
                    >
                        {btnName}
                    </Button>
                )}
            </Flex>
            <CommonFeatureConfirmModal
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
                confirmBtnLabel={t('teamViewerLabel', { ns: 'common' })}
                btnColorScheme={btnColorScheme}
                onHandleConfirm={onHandleSendLaunchTeamViewerNotificationMutation}
                modalLabel={{
                    modalHeader: `${t('startTeamViewerLabel', { ns: 'machine' })}`,
                    modalBodyMessage1: `${t('launchTeamViewerConfirmMessage', { ns: 'machine' })}`,
                }}
            />
        </ApolloMutationBoundary>
    );
}
