import { ShopDetailProps } from '../shopDetailTypes';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { Box, Button, Flex, HStack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useTranslation } from 'react-i18next';
import { AccountType } from '../../../../../../types/user';
import FormProvider, { RHFTextField } from '../../../../../../components/hook-form';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateProShopPasswordMachineMutation } from '../../../../../../graphql/mutations/shop/__generated__/updateProShopPasswordMachine.generated';
import { GetShopByIdDocument } from '../../../../../../graphql/queries/shop/__generated__/getShopById.generated';

type ChangePasswordProMachineControlFeatureProps = ShopDetailProps & {
    isEdit: boolean;
    onEditDisable: () => void;
    [key: string]: any;
};
export default function ChangePasswordProMachineControlFeature(props: ChangePasswordProMachineControlFeatureProps) {
    const { shop, isEdit, onEditDisable } = props;
    const { accountType } = useAuthContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const { t } = useTranslation(['common', 'shop']);

    const {
        isOpen: isOpenUpdateProShopPasswordResult,
        onOpen: onOpenUpdateProShopPasswordResult,
        onClose: onCloseUpdateProShopPasswordResult,
    } = useDisclosure();

    const [updateProShopPasswordMachineFunc, updateProShopPasswordMachineMutationResult] =
        useUpdateProShopPasswordMachineMutation();

    const MachinePasswordSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
    });

    const defaultValues = useMemo(
        () => ({
            password: '',
        }),
        [],
    );

    const methods = useForm({
        resolver: yupResolver(MachinePasswordSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        reset,
        watch,
    } = methods;

    const values = watch();
    const onHandleUpdateProShopPasswordMachine = async (data: { password: string }) => {
        try {
            await updateProShopPasswordMachineFunc({
                variables: {
                    input: {
                        shopId: shop.id,
                        password: data.password,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        reset();
                        onEditDisable();
                        onOpenUpdateProShopPasswordResult();
                    }
                },
                onError: async () => {
                    onOpenUpdateProShopPasswordResult();
                },
                refetchQueries: () => [
                    {
                        query: GetShopByIdDocument,
                        variables: {
                            input: {
                                shopId: shop.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenUpdateProShopPasswordResult}
            onClose={onCloseUpdateProShopPasswordResult}
            onOpen={onOpenUpdateProShopPasswordResult}
            mutationResult={updateProShopPasswordMachineMutationResult}
            toastSuccessTitle={t('updateProShopPasswordMachineSuccessTitle', { ns: 'shop' })}
            toastSuccessDescription={t('updateProShopPasswordMachineDescription', { ns: 'shop' })}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onHandleUpdateProShopPasswordMachine)}>
                {accountType === AccountType.dealer &&
                    (isEdit ? (
                        <Flex
                            direction={{ base: 'column', md: 'row' }}
                            justifyContent="space-between"
                            // alignItems="flex-start"
                            // alignItems={{ base: 'column', md: 'row' }}
                            w="100%"
                            gap={1}
                        >
                            <HStack justifyContent="flex-start" alignItems="center" w="100%">
                                <Box minW="125px">
                                    <Text
                                        color={textColorSecondary}
                                        fontSize="14px"
                                        fontWeight="400"
                                        mr={3}
                                        alignItems="center"
                                    >
                                        {t('singleMachineLabel', { ns: 'common' })}
                                    </Text>
                                </Box>

                                <RHFTextField
                                    name="password"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    fontWeight="500"
                                    variant="main"
                                    maxW={'240px'}
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                />
                            </HStack>

                            <HStack
                                justifyContent={{ base: 'flex-start', sm: 'flex-start', md: 'flex-end' }}
                                alignItems="center"
                                w="100%"
                            >
                                <Box display="flex" flexDirection="row">
                                    <Button
                                        py="0px"
                                        px="8px"
                                        ms="auto"
                                        variant="brand"
                                        fontWeight="500"
                                        minW="125px"
                                        maxH="35px"
                                        borderRadius="10px"
                                        backgroundColor="#4318FF"
                                        type="submit"
                                        isLoading={isSubmitting}
                                        isDisabled={!values.password}
                                    >
                                        <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                                    </Button>
                                </Box>
                            </HStack>
                        </Flex>
                    ) : (
                        <HStack justifyContent="flex-start" alignItems="center" w="100%">
                            <Box minW="125px">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('singleMachineLabel', { ns: 'common' })}
                                </Text>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    ********
                                </Text>
                            </Box>
                        </HStack>
                    ))}
            </FormProvider>
        </ApolloMutationBoundary>
    );
}
