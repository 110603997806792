import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegisterDealerMutationVariables = Types.Exact<{
    input: Types.RegisterDealerInput;
}>;

export type RegisterDealerMutation = {
    __typename?: 'Mutation';
    register_dealer: {
        __typename?: 'Dealer';
        id: number;
        name: string;
        iconUrl?: string | null;
        status: Types.DealerStatus;
        email: string;
        languageCode: string;
        countryCode: string;
        zipCode: string;
        province: string;
        address: string;
        phoneNumber: string;
        distributor: {
            __typename?: 'Distributor';
            id: number;
            name: string;
            status: Types.DistributorStatus;
            email: string;
            languageCode: string;
            countryCode: string;
            zipCode: string;
            province: string;
            address: string;
            phoneNumber: string;
        };
        shops: Array<{
            __typename?: 'Shop';
            id: number;
            name: string;
            beaconId?: string | null;
            status: Types.ShopStatus;
            email: string;
            phoneNumber: string;
            machines: Array<{ __typename?: 'Machine'; id: number; machineName: string; status: Types.MachineStatus }>;
        }>;
    };
};

export const RegisterDealerDocument = gql`
    mutation RegisterDealer($input: RegisterDealerInput!) {
        register_dealer(input: $input) {
            id
            name
            iconUrl
            status
            email
            languageCode
            countryCode
            zipCode
            province
            address
            phoneNumber
            distributor {
                id
                name
                status
                email
                languageCode
                countryCode
                zipCode
                province
                address
                phoneNumber
            }
            shops {
                id
                name
                beaconId
                status
                machines {
                    id
                    machineName
                    status
                }
                email
                phoneNumber
            }
        }
    }
`;
export type RegisterDealerMutationFn = Apollo.MutationFunction<RegisterDealerMutation, RegisterDealerMutationVariables>;

/**
 * __useRegisterDealerMutation__
 *
 * To run a mutation, you first call `useRegisterDealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDealerMutation, { data, loading, error }] = useRegisterDealerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterDealerMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterDealerMutation, RegisterDealerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RegisterDealerMutation, RegisterDealerMutationVariables>(RegisterDealerDocument, options);
}
export type RegisterDealerMutationHookResult = ReturnType<typeof useRegisterDealerMutation>;
export type RegisterDealerMutationResult = Apollo.MutationResult<RegisterDealerMutation>;
export type RegisterDealerMutationOptions = Apollo.BaseMutationOptions<
    RegisterDealerMutation,
    RegisterDealerMutationVariables
>;
