import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestDealerPasswordResetCodeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RequestDealerPasswordResetCodeQuery = {
    __typename?: 'Query';
    request_dealer_password_reset_code: Types.SuccessResponse;
};

export const RequestDealerPasswordResetCodeDocument = gql`
    query requestDealerPasswordResetCode {
        request_dealer_password_reset_code
    }
`;

/**
 * __useRequestDealerPasswordResetCodeQuery__
 *
 * To run a query within a React component, call `useRequestDealerPasswordResetCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestDealerPasswordResetCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestDealerPasswordResetCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestDealerPasswordResetCodeQuery(
    baseOptions?: Apollo.QueryHookOptions<
        RequestDealerPasswordResetCodeQuery,
        RequestDealerPasswordResetCodeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RequestDealerPasswordResetCodeQuery, RequestDealerPasswordResetCodeQueryVariables>(
        RequestDealerPasswordResetCodeDocument,
        options,
    );
}
export function useRequestDealerPasswordResetCodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        RequestDealerPasswordResetCodeQuery,
        RequestDealerPasswordResetCodeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RequestDealerPasswordResetCodeQuery, RequestDealerPasswordResetCodeQueryVariables>(
        RequestDealerPasswordResetCodeDocument,
        options,
    );
}
export type RequestDealerPasswordResetCodeQueryHookResult = ReturnType<typeof useRequestDealerPasswordResetCodeQuery>;
export type RequestDealerPasswordResetCodeLazyQueryHookResult = ReturnType<
    typeof useRequestDealerPasswordResetCodeLazyQuery
>;
export type RequestDealerPasswordResetCodeQueryResult = Apollo.QueryResult<
    RequestDealerPasswordResetCodeQuery,
    RequestDealerPasswordResetCodeQueryVariables
>;
