import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateProShopPasswordMachineMutationVariables = Types.Exact<{
    input: Types.UpdateProShopPasswordOfMachineInput;
}>;

export type UpdateProShopPasswordMachineMutation = {
    __typename?: 'Mutation';
    update_pro_shop_password_of_machine: Types.SuccessResponse;
};

export const UpdateProShopPasswordMachineDocument = gql`
    mutation updateProShopPasswordMachine($input: UpdateProShopPasswordOfMachineInput!) {
        update_pro_shop_password_of_machine(input: $input)
    }
`;
export type UpdateProShopPasswordMachineMutationFn = Apollo.MutationFunction<
    UpdateProShopPasswordMachineMutation,
    UpdateProShopPasswordMachineMutationVariables
>;

/**
 * __useUpdateProShopPasswordMachineMutation__
 *
 * To run a mutation, you first call `useUpdateProShopPasswordMachineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProShopPasswordMachineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProShopPasswordMachineMutation, { data, loading, error }] = useUpdateProShopPasswordMachineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProShopPasswordMachineMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateProShopPasswordMachineMutation,
        UpdateProShopPasswordMachineMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateProShopPasswordMachineMutation, UpdateProShopPasswordMachineMutationVariables>(
        UpdateProShopPasswordMachineDocument,
        options,
    );
}
export type UpdateProShopPasswordMachineMutationHookResult = ReturnType<typeof useUpdateProShopPasswordMachineMutation>;
export type UpdateProShopPasswordMachineMutationResult = Apollo.MutationResult<UpdateProShopPasswordMachineMutation>;
export type UpdateProShopPasswordMachineMutationOptions = Apollo.BaseMutationOptions<
    UpdateProShopPasswordMachineMutation,
    UpdateProShopPasswordMachineMutationVariables
>;
