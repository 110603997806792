import { TFunction } from 'i18next';

export const errorMessageHandler = (message: string, errorCode: string | null | undefined, t: TFunction) => {
    if (errorCode === 'NotAuthorizedException') {
        return t('NotAuthorizedError', { ns: 'error' });
    }
    if (errorCode === 'UnchangedPasswordLogin') {
        return t('UnchangedPasswordLoginError', { ns: 'error' });
    }
    if (errorCode === 'DistributorNotFound') {
        return t('DistributorNotFoundError', { ns: 'error' });
    }
    if (errorCode === 'DealerNotFound') {
        return t('DealerNotFoundError', { ns: 'error' });
    }
    if (errorCode === 'ShopNotFound') {
        return t('ShopNotFoundError', { ns: 'error' });
    }
    if (errorCode === 'ShopMachineNotFound') {
        return t('ShopMachineNotFoundError', { ns: 'error' });
    }
    if (errorCode === 'ShopProKitNotFound') {
        return t('ShopProKitNotFoundError', { ns: 'error' });
    }
    if (errorCode === 'ProKitPlanNotFound') {
        return t('ProKitPlanNotFoundError', { ns: 'error' });
    }
    if (errorCode === 'UserNotFound') {
        return t('UserNotFoundError', { ns: 'error' });
    }
    if (errorCode === 'NotPermitted') {
        return t('NotPermittedError', { ns: 'error' });
    }
    if (errorCode === 'ApiExecuteProhibited') {
        return t('ApiExecuteProhibitedError', { ns: 'error' });
    }
    if (errorCode === 'DealerAlreadyActivated') {
        return t('DealerAlreadyActivatedError', { ns: 'error' });
    }
    if (errorCode === 'ShopAlreadyActivated') {
        return t('ShopAlreadyActivatedError', { ns: 'error' });
    }
    if (errorCode === 'ShopIncomeCollectionEventNotFound') {
        return t('ShopIncomeCollectionEventNotFoundError', { ns: 'error' });
    }
    if (errorCode === 'FailedToDeleteProUser') {
        return t('FailedToDeleteProUserError', { ns: 'error' });
    }
    if (errorCode === 'RunningMachineExist') {
        return t('RunningMachineExistError', { ns: 'error' });
    }
    if (errorCode === 'FailedToDeleteMachine') {
        return t('FailedToDeleteMachineError', { ns: 'error' });
    }
    if (errorCode === 'ReceiverNotFound') {
        return t('ReceiverNotFoundError', { ns: 'error' });
    }
    if (errorCode === 'FailedFileUpload') {
        return t('FileUploadError', { ns: 'error' });
    }
    return message;
};
