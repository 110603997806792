import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyShopEmailMutationVariables = Types.Exact<{
    input: Types.VerifyShopEmailInput;
}>;

export type VerifyShopEmailMutation = {
    __typename?: 'Mutation';
    verify_shop_email: {
        __typename?: 'ShopWithSession';
        shop: {
            __typename?: 'Shop';
            id: number;
            name: string;
            email: string;
            iconUrl?: string | null;
            beaconId?: string | null;
            status: Types.ShopStatus;
            languageCode: string;
            countryCode: string;
            zipCode: string;
            province: string;
            address: string;
            phoneNumber: string;
            lastInformationEditedAt?: Date | null;
            lastInformationEditedBy?: Types.ShopInformationEditor | null;
            dealer: { __typename?: 'Dealer'; id: number; name: string; iconUrl?: string | null; email: string };
        };
        session: {
            __typename?: 'ShopSession';
            accessToken?: {
                __typename?: 'CognitoAccessToken';
                jwtToken?: string | null;
                payload?: { __typename?: 'CognitoAccessTokenPayload'; exp?: number | null } | null;
            } | null;
        };
    };
};

export const VerifyShopEmailDocument = gql`
    mutation verifyShopEmail($input: VerifyShopEmailInput!) {
        verify_shop_email(input: $input) {
            shop {
                id
                name
                email
                iconUrl
                beaconId
                status
                languageCode
                countryCode
                zipCode
                province
                address
                phoneNumber
                lastInformationEditedAt
                lastInformationEditedBy
                dealer {
                    id
                    name
                    iconUrl
                    email
                }
            }
            session {
                accessToken {
                    jwtToken
                    payload {
                        exp
                    }
                }
            }
        }
    }
`;
export type VerifyShopEmailMutationFn = Apollo.MutationFunction<
    VerifyShopEmailMutation,
    VerifyShopEmailMutationVariables
>;

/**
 * __useVerifyShopEmailMutation__
 *
 * To run a mutation, you first call `useVerifyShopEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyShopEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyShopEmailMutation, { data, loading, error }] = useVerifyShopEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyShopEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<VerifyShopEmailMutation, VerifyShopEmailMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<VerifyShopEmailMutation, VerifyShopEmailMutationVariables>(
        VerifyShopEmailDocument,
        options,
    );
}
export type VerifyShopEmailMutationHookResult = ReturnType<typeof useVerifyShopEmailMutation>;
export type VerifyShopEmailMutationResult = Apollo.MutationResult<VerifyShopEmailMutation>;
export type VerifyShopEmailMutationOptions = Apollo.BaseMutationOptions<
    VerifyShopEmailMutation,
    VerifyShopEmailMutationVariables
>;
