import { Center, CircularProgress } from '@chakra-ui/react';

const BackDrop = () => {
    return (
        <Center
            pos={'fixed'}
            left={'0'}
            top={'0'}
            w={'100vw'}
            h={'100vh'}
            opacity={0.5}
            bgColor={'black'}
            zIndex={'overlay'}
        >
            <CircularProgress isIndeterminate />
        </Center>
    );
};

export default BackDrop;
