// noinspection DuplicatedCode

import { AccountType } from 'types/user';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { Flex, Select, Text, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import { MachineStatusFilter } from 'types/graphql';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import ShopsSelectListByDistributor from 'components/select/ShopsSelectListByDistributor';
import { useTranslation } from 'react-i18next';
import StatusMachineSelectList from 'components/hook-form/StatusMachineSelectList';
import { useGetMachineListOfShopLazyQuery } from 'graphql/queries/shop/__generated__/getMachineListOfShop.generated';
import SkeletonTableRow from 'components/skeleton/SkeletonTableRow';
import MachineTableContainer from './MachineTableContainer';
import { useGetDistributorByIdQuery } from 'graphql/queries/distributor/__generated__/getDistributorById.generated';
import { DefaultPageSize } from '../../../../../../constant/defaultValues';
import { PaginationState } from '@tanstack/react-table';

export default function MachineListByDistributor() {
    const { userId, accountType } = useAuthContext();
    const [isLargerThan320] = useMediaQuery('(min-width: 460px)');
    const [dealerId, setDealerId] = useState<number | null>(null);
    const [machineStatus, setMachineStatus] = useState<MachineStatusFilter | 'all'>('all');
    const [shopId, setShopId] = useState<number | null>(null);
    const { t } = useTranslation(['common']);

    const [paginationState, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: DefaultPageSize,
    });

    const queryDealerListByDistributorResult = useGetDistributorByIdQuery({
        variables: {
            input: {
                distributorId: userId,
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });
    const [getMachineListByShopIdLazyQueryFunc, useGetMachineListByShopIdLazyQueryResult] =
        useGetMachineListOfShopLazyQuery();
    const handleFilterDealer = (event: ChangeEvent<HTMLSelectElement>) => {
        const dealerIdValue = event.target.value === 'all' ? null : event.target.value;
        setDealerId && setDealerId(Number(dealerIdValue));
    };

    useEffect(() => {
        const getMachineList = async () => {
            if (shopId) {
                await getMachineListByShopIdLazyQueryFunc({
                    variables: {
                        input: {
                            shopId,
                            filterOption: {
                                status: machineStatus === 'all' ? undefined : machineStatus,
                            },
                            args: {
                                first: paginationState.pageSize,
                                after: `${Number(paginationState.pageSize * paginationState.pageIndex)}`,
                            },
                        },
                    },
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'cache-first',
                });
            }
        };
        getMachineList().catch((e) => {
            console.error(e);
        });
    }, [shopId, machineStatus, paginationState.pageSize, paginationState.pageIndex]);

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: paginationState.pageSize,
        });
    }, [machineStatus, shopId]);

    return (
        <Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }} minH="30vh">
            <Flex
                w="100%"
                align={{ sm: 'flex-start', lg: 'stretch' }}
                pt={{ sm: '20px', lg: '30px' }}
                flexDirection={{ sm: 'column', lg: 'row' }}
                px={{ sm: '0px', lg: '50px' }}
            >
                <Flex
                    justifyContent={{ sm: 'space-around', lg: 'flex-start' }}
                    w="100%"
                    mb={{ sm: '20px', lg: '30px' }}
                    px={{ sm: '10px', lg: '0px' }}
                >
                    {accountType === AccountType.distributor && (
                        <ApolloQueryBoundary
                            queryResult={queryDealerListByDistributorResult}
                            render={({
                                data: {
                                    distributor: { dealers },
                                },
                            }) => {
                                return (
                                    <Select
                                        fontSize="14px"
                                        variant="main"
                                        borderRadius="10px"
                                        h="40px"
                                        maxH="44px"
                                        maxW={150}
                                        me={{ base: '10px', md: '20px' }}
                                        onChange={(e) => handleFilterDealer(e)}
                                    >
                                        <option value="all">{t('accountTypeOption3', { ns: 'common' })}</option>
                                        {dealers.map((dealer) => (
                                            <option key={dealer.id} value={dealer.id}>
                                                {dealer.name}
                                            </option>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    )}

                    {dealerId ? (
                        <ShopsSelectListByDistributor shopId={shopId} setShopId={setShopId} dealerId={dealerId} />
                    ) : (
                        <EmptyShopSelectListComponent />
                    )}

                    {isLargerThan320 && (
                        <StatusMachineSelectList machineStatus={machineStatus} setMachineStatus={setMachineStatus} />
                    )}
                </Flex>
            </Flex>
            {accountType === AccountType.distributor && (
                <ApolloQueryBoundary
                    queryResult={useGetMachineListByShopIdLazyQueryResult}
                    noDataComponent={<NoMachineDataByDistributorComponent />}
                    loadingComponent={<SkeletonTableRow />}
                    render={({
                        data: {
                            get_machine_list_of_shop: { totalCount, edges, pageInfo },
                        },
                    }) => (
                        <MachineTableContainer
                            totalCount={shopId ? totalCount : 0}
                            edges={edges}
                            pageInfo={pageInfo}
                            paginationState={paginationState}
                            setPagination={setPagination}
                        />
                    )}
                />
            )}
        </Flex>
    );
}

function NoMachineDataByDistributorComponent() {
    const textColor = useColorModeValue('#2B3674', 'white');
    const { t } = useTranslation(['common']);
    return (
        <Flex align="center" justifyContent="center" w="100%">
            <Text color={textColor} fontSize={{ base: '14px', lg: '16px' }} fontWeight="700" lineHeight="100%">
                {t('displayMachineListHeading', { ns: 'common' })}
            </Text>
        </Flex>
    );
}

function EmptyShopSelectListComponent() {
    const { t } = useTranslation(['common']);
    return (
        <Select
            fontSize="14px"
            variant="main"
            borderRadius="10px"
            h="40px"
            maxH="44px"
            maxW={150}
            disabled={true}
            me={{ base: '10px', md: '20px' }}
        >
            <option value="all">{t('accountTypeOption4', { ns: 'common' })}</option>
        </Select>
    );
}
