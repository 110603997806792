export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
  JSON: any;
  Upload: any;
};

export type ActivateDealerInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  tempPassword: Scalars['String'];
};

export type ActivateKitInput = {
  proKitSerialCode: Scalars['String'];
  shopId: Scalars['Int'];
};

export type ActivateProKitInput = {
  deviceId: Scalars['String'];
  proKitSerialCode: Scalars['String'];
};

export type ActivateShopInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  tempPassword: Scalars['String'];
};

export type AddChatMemberInput = {
  members: Array<ChatMemberInput>;
  roomId: Scalars['Int'];
};

export type AddNewAppVersionInput = {
  description?: InputMaybe<Scalars['String']>;
  dev: Scalars['Boolean'];
  onlineAvailable: Scalars['Boolean'];
  platform: Platform;
  version: Scalars['String'];
};

export type AddNewGranPlayerAppVersionInput = {
  description?: InputMaybe<Scalars['String']>;
  dev: Scalars['Boolean'];
  platform: Platform;
  version: Scalars['String'];
};

export type AddNewProAppVersionInput = {
  description?: InputMaybe<Scalars['String']>;
  dev: Scalars['Boolean'];
  downloadUrl: Scalars['String'];
  onlineAvailable: Scalars['Boolean'];
  version: Scalars['String'];
};

export type AddPlayerInput = {
  boardSize: BoardSize;
  cardBgColor?: InputMaybe<CardBgColor>;
  cardBgImg?: InputMaybe<Scalars['Upload']>;
  cardBgIndex?: InputMaybe<Scalars['Int']>;
  granId: Scalars['ID'];
  iconImg?: InputMaybe<Scalars['Upload']>;
  playerName: Scalars['String'];
  userId: Scalars['Int'];
};

export type AddPublicDummyInput = {
  boardSize: BoardSize;
  userId: Scalars['Int'];
};

export type AddShopInput = {
  address: Scalars['String'];
  country: Country;
  dealerId: Scalars['Int'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  province: Scalars['String'];
  zipCode: Scalars['String'];
};

export enum AggregationPeriod {
  LastMonth = 'LastMonth',
  MonthBeforeLast = 'MonthBeforeLast',
  ThisMonth = 'ThisMonth'
}

export type AnimalWinCount = {
  __typename?: 'AnimalWinCount';
  animal: Animals;
  cricketLoses: Scalars['Int'];
  cricketWins: Scalars['Int'];
  zeroOneLoses: Scalars['Int'];
  zeroOneWins: Scalars['Int'];
};

export enum Animals {
  BlackLion = 'BlackLion',
  Crocodile = 'Crocodile',
  Elephant = 'Elephant',
  Giraffe = 'Giraffe',
  Hippo = 'Hippo',
  Lion = 'Lion',
  PinkElephant = 'PinkElephant',
  Tiger = 'Tiger',
  WhiteTiger = 'WhiteTiger'
}

export enum App {
  GranBoard = 'GranBoard',
  GranPlayer = 'GranPlayer',
  GranPro = 'GranPro'
}

export enum AppLogCategory {
  AccountRegistration = 'AccountRegistration',
  AppCrash = 'AppCrash',
  GranBoard = 'GranBoard',
  GranCam = 'GranCam',
  InappropriateBehaviorOfOpponent = 'InappropriateBehaviorOfOpponent',
  Localize = 'Localize',
  MyPage = 'MyPage',
  OfflineGame = 'OfflineGame',
  Online01 = 'Online01',
  OnlineCricket = 'OnlineCricket',
  OnlineDoubles = 'OnlineDoubles',
  OnlineGameRematch = 'OnlineGameRematch',
  OnlineLobby = 'OnlineLobby',
  OnlineMedley = 'OnlineMedley',
  OnlineResults = 'OnlineResults',
  Other = 'Other',
  VideoDuringOnlineGame = 'VideoDuringOnlineGame'
}

export type AppVersion = {
  __typename?: 'AppVersion';
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  dev: Scalars['Boolean'];
  onlineAvailable: Scalars['Boolean'];
  platform: Platform;
  status: AppVersionStatus;
  updatedAt?: Maybe<Scalars['Date']>;
  version: Scalars['String'];
};

export type AppVersionConnection = {
  __typename?: 'AppVersionConnection';
  edges?: Maybe<Array<Maybe<AppVersionEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AppVersionEdge = {
  __typename?: 'AppVersionEdge';
  cursor: Scalars['String'];
  node?: Maybe<AppVersion>;
};

export type AppVersionFilterOption = {
  dev?: InputMaybe<Scalars['Boolean']>;
  onlineAvailable?: InputMaybe<Scalars['Boolean']>;
  platform?: InputMaybe<Platform>;
  status?: InputMaybe<AppVersionStatus>;
};

export type AppVersionInput = {
  platform?: InputMaybe<Platform>;
  version: Scalars['String'];
};

export enum AppVersionStatus {
  Current = 'Current',
  Latest = 'Latest',
  OutDated = 'OutDated'
}

export type ApplePurchaseInput = {
  receipt: Scalars['String'];
  userId: Scalars['Int'];
};

export type AuthBoardInput = {
  serialCode: Scalars['String'];
  userId: Scalars['Int'];
};

export type AuthBoardResult = {
  __typename?: 'AuthBoardResult';
  activatedBoard: Scalars['Int'];
  remainingBoard: Scalars['Int'];
};

export type Award = {
  __typename?: 'Award';
  bed: Scalars['Int'];
  black: Scalars['Int'];
  doubleBull: Scalars['Int'];
  hat: Scalars['Int'];
  highton: Scalars['Int'];
  lowton: Scalars['Int'];
  mark5: Scalars['Int'];
  mark6: Scalars['Int'];
  mark7: Scalars['Int'];
  mark8: Scalars['Int'];
  mark9: Scalars['Int'];
  singleBull: Scalars['Int'];
  ton80: Scalars['Int'];
  triple20: Scalars['Int'];
  whitehorse: Scalars['Int'];
};

export type AwardDateRangeInput = {
  endAt: Scalars['Date'];
  startAt: Scalars['Date'];
};

export type AwardInput = {
  bed?: InputMaybe<Scalars['Int']>;
  black?: InputMaybe<Scalars['Int']>;
  doubleBull?: InputMaybe<Scalars['Int']>;
  hat?: InputMaybe<Scalars['Int']>;
  highton?: InputMaybe<Scalars['Int']>;
  lowton?: InputMaybe<Scalars['Int']>;
  mark5?: InputMaybe<Scalars['Int']>;
  mark6?: InputMaybe<Scalars['Int']>;
  mark7?: InputMaybe<Scalars['Int']>;
  mark8?: InputMaybe<Scalars['Int']>;
  mark9?: InputMaybe<Scalars['Int']>;
  singleBull?: InputMaybe<Scalars['Int']>;
  ton80?: InputMaybe<Scalars['Int']>;
  triple20?: InputMaybe<Scalars['Int']>;
  whitehorse?: InputMaybe<Scalars['Int']>;
};

export enum AwardKey {
  DoubleBull = 'DoubleBull',
  HatTrick = 'HatTrick',
  HighTon = 'HighTon',
  LowTon = 'LowTon',
  Mark5 = 'Mark5',
  Mark6 = 'Mark6',
  Mark7 = 'Mark7',
  Mark8 = 'Mark8',
  Mark9 = 'Mark9',
  SingleBull = 'SingleBull',
  ThreeInABed = 'ThreeInABed',
  ThreeInTheBlack = 'ThreeInTheBlack',
  Ton80 = 'Ton80',
  Triple20 = 'Triple20',
  WhiteHorse = 'WhiteHorse'
}

export type AwardMovieItem = {
  __typename?: 'AwardMovieItem';
  awardType: AwardKey;
  contentMovieUrl: Scalars['String'];
  customizeItemType: CustomizeItemType;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  previewMovieUrl: Scalars['String'];
};

export type AwardMovieSetting = {
  __typename?: 'AwardMovieSetting';
  awardMovieItem: AwardMovieItem;
  awardType: AwardKey;
};

export type AwardRanking = PlayerIdentity & {
  __typename?: 'AwardRanking';
  countryCode?: Maybe<Scalars['String']>;
  granId: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  rank80: Scalars['String'];
  rank100: Scalars['String'];
  score: Scalars['Int'];
};

export type AwardRankingConnection = {
  __typename?: 'AwardRankingConnection';
  edges?: Maybe<Array<Maybe<AwardRankingEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AwardRankingEdge = {
  __typename?: 'AwardRankingEdge';
  cursor: Scalars['String'];
  node?: Maybe<AwardRanking>;
};

export type AwardRankingFilterInput = {
  boardType?: InputMaybe<BoardType>;
  countryCode?: InputMaybe<Scalars['String']>;
};

export type AwardRankingInput = {
  filter?: InputMaybe<AwardRankingFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type AwardRankingPlayerPositionAndScoreInput = {
  filter?: InputMaybe<AwardRankingFilterInput>;
  playerId: Scalars['Int'];
};

export type BanUserInput = {
  userId: Scalars['Int'];
};

export type BeyondTopPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type BeyondTopUnitDataInput = {
  playerDatas: Array<BeyondTopPlayerDataInput>;
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export enum BillingType {
  Full = 'Full',
  Prorate = 'Prorate'
}

export enum BoardCamLotateOption {
  Down = 'Down',
  Left = 'Left',
  Right = 'Right',
  Up = 'Up'
}

export enum BoardCamOption {
  CameraApp = 'CameraApp',
  GranCam = 'GranCam',
  NoCamera = 'NoCamera'
}

export enum BoardSize {
  SoftTip132 = 'SoftTip132',
  SoftTip155 = 'SoftTip155',
  SteelTip = 'SteelTip'
}

export enum BoardType {
  SoftTip132 = 'SoftTip132',
  SoftTip155 = 'SoftTip155',
  SteelTip = 'SteelTip'
}

export type BreakUpRemoteInput = {
  unitId: Scalars['Int'];
};

export type BrokenUpRemoteEvent = RemoteOperationEvent & {
  __typename?: 'BrokenUpRemoteEvent';
  code: RemoteOperationEventCode;
  unit: OnlineUnit;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CancelChangeEmailInput = {
  newEmail: Scalars['String'];
};

export type CancelHourlyRentalOfMachineInput = {
  machineId: Scalars['Int'];
};

export type CancelProEntryInput = {
  entryPlayerId: Scalars['Int'];
};

export enum CardBgColor {
  Black = 'Black',
  Blue = 'Blue',
  Gray = 'Gray',
  Green = 'Green',
  Pink = 'Pink',
  Purple = 'Purple',
  Red = 'Red',
  Yellow = 'Yellow'
}

export type CardSwitchedInput = {
  userId: Scalars['Int'];
};

export type ChangedLobbyStatusEvent = RemoteOperationEvent & {
  __typename?: 'ChangedLobbyStatusEvent';
  code: RemoteOperationEventCode;
  status: LobbyStatus;
};

export type ChangedThrowOrderEvent = RemoteOperationEvent & {
  __typename?: 'ChangedThrowOrderEvent';
  code: RemoteOperationEventCode;
  unit: OnlineUnit;
};

export type ChatMember = {
  __typename?: 'ChatMember';
  is_admin: Scalars['Boolean'];
  player: Player;
};

export type ChatMemberInput = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  playerId: Scalars['Int'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  content: Scalars['String'];
  id: Scalars['ID'];
  posted_at: Scalars['Date'];
  posted_by: Player;
  read_count?: Maybe<Scalars['Int']>;
  room_id: Scalars['Int'];
};

export type ChatMessageConnection = {
  __typename?: 'ChatMessageConnection';
  edges?: Maybe<Array<Maybe<ChatMessageEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ChatMessageEdge = {
  __typename?: 'ChatMessageEdge';
  cursor: Scalars['String'];
  node?: Maybe<ChatMessage>;
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  id: Scalars['Int'];
  members: Array<ChatMember>;
  messages: ChatMessageConnection;
  name?: Maybe<Scalars['String']>;
  type: ChatRoomType;
  unread_count?: Maybe<Scalars['Int']>;
};


export type ChatRoomMessagesArgs = {
  input?: InputMaybe<PaginationInput>;
};

export type ChatRoomConnection = {
  __typename?: 'ChatRoomConnection';
  edges?: Maybe<Array<Maybe<ChatRoomEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ChatRoomEdge = {
  __typename?: 'ChatRoomEdge';
  cursor: Scalars['String'];
  node?: Maybe<ChatRoom>;
};

export type ChatRoomListInput = {
  args?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
  type?: InputMaybe<ChatRoomType>;
};

export enum ChatRoomType {
  Club = 'club',
  Group = 'group',
  Private = 'private'
}

export type CheckFollowingInput = {
  playerId: Scalars['Int'];
  targetPlayerIds: Array<Scalars['Int']>;
};

export type CheckFriendshipInput = {
  playerId: Scalars['Int'];
  targetPlayerIds: Array<Scalars['Int']>;
};

export type CheckGranIdDuplicationInput = {
  gran_id: Scalars['String'];
};

export type CloseChatRoomInput = {
  playerId: Scalars['Int'];
  roomId: Scalars['Int'];
};

export type Club = {
  __typename?: 'Club';
  active: Scalars['Boolean'];
  boardCam: Scalars['Boolean'];
  boardSize: ClubBoardSizeOption;
  clubImgUrl: Scalars['String'];
  clubType: ClubTypeOption;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  expireAt: Scalars['Date'];
  hashedPassword?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  onlinePlayersCount: Scalars['Int'];
  owner: Player;
  participateMethod: ParticipateMethodOption;
  totalPlayersCount: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum ClubBoardSizeOption {
  Any = 'Any',
  Only132 = 'Only132',
  Only155 = 'Only155'
}

export type ClubConnection = {
  __typename?: 'ClubConnection';
  edges?: Maybe<Array<Maybe<ClubEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ClubEdge = {
  __typename?: 'ClubEdge';
  cursor: Scalars['String'];
  node?: Maybe<Club>;
};

export type ClubFilterOption = {
  boardCam?: InputMaybe<Scalars['Boolean']>;
  boardSize?: InputMaybe<ClubBoardSizeOption>;
  countryCode?: InputMaybe<Scalars['String']>;
  friendsClub?: InputMaybe<Scalars['Boolean']>;
  participateMethod?: InputMaybe<ParticipateMethodOption>;
};

export type ClubInput = {
  id: Scalars['Int'];
};

export type ClubInvitableFriendsInput = {
  clubId: Scalars['Int'];
  playerId: Scalars['Int'];
};

export enum ClubJoinResponse {
  Accept = 'Accept',
  Refuse = 'Refuse'
}

export type ClubMember = {
  __typename?: 'ClubMember';
  banned: Scalars['Boolean'];
  club: Club;
  createdAt?: Maybe<Scalars['Date']>;
  player: Player;
  status: ClubMemberStatus;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ClubMemberConnection = {
  __typename?: 'ClubMemberConnection';
  edges?: Maybe<Array<Maybe<ClubMemberEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ClubMemberEdge = {
  __typename?: 'ClubMemberEdge';
  cursor: Scalars['String'];
  node?: Maybe<ClubMember>;
};

export enum ClubMemberStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Invited = 'Invited',
  JoinRequesting = 'JoinRequesting'
}

export enum ClubTypeOption {
  EnjoyBeginner = 'EnjoyBeginner',
  EnjoyDoubles = 'EnjoyDoubles',
  EnjoyGlobal = 'EnjoyGlobal',
  EnjoyMatch = 'EnjoyMatch',
  EnjoySingles = 'EnjoySingles',
  FamilyDoubles = 'FamilyDoubles',
  FamilyEnjoy = 'FamilyEnjoy',
  FriendlyMatch = 'FriendlyMatch',
  SeriousDoubles = 'SeriousDoubles',
  SeriousGlobal = 'SeriousGlobal',
  SeriousSingles = 'SeriousSingles'
}

export type CognitoAccessToken = {
  __typename?: 'CognitoAccessToken';
  jwtToken?: Maybe<Scalars['String']>;
  payload?: Maybe<CognitoAccessTokenPayload>;
};

export type CognitoAccessTokenPayload = {
  __typename?: 'CognitoAccessTokenPayload';
  exp?: Maybe<Scalars['Int']>;
};

export type CognitoIdToken = {
  __typename?: 'CognitoIdToken';
  jwtToken?: Maybe<Scalars['String']>;
  payload?: Maybe<CognitoIdTokenPayload>;
};

export type CognitoIdTokenPayload = {
  __typename?: 'CognitoIdTokenPayload';
  exp?: Maybe<Scalars['Int']>;
};

export type CognitoRefreshToken = {
  __typename?: 'CognitoRefreshToken';
  token?: Maybe<Scalars['String']>;
};

export type CognitoUser = {
  __typename?: 'CognitoUser';
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
  userCreateDate?: Maybe<Scalars['Date']>;
  userLastModifiedDate?: Maybe<Scalars['Date']>;
  userStatus?: Maybe<Scalars['String']>;
};

export type Confirmation = {
  __typename?: 'Confirmation';
  isSuccess: Scalars['Boolean'];
};

export type CountUpAnalysisDataInput = {
  bullRate?: InputMaybe<Scalars['Float']>;
  hatRate?: InputMaybe<Scalars['Float']>;
  hitDouble1Rate?: InputMaybe<Scalars['Float']>;
  hitDouble2Rate?: InputMaybe<Scalars['Float']>;
  hitDouble3Rate?: InputMaybe<Scalars['Float']>;
  hitDouble4Rate?: InputMaybe<Scalars['Float']>;
  hitDouble5Rate?: InputMaybe<Scalars['Float']>;
  hitDouble6Rate?: InputMaybe<Scalars['Float']>;
  hitDouble7Rate?: InputMaybe<Scalars['Float']>;
  hitDouble8Rate?: InputMaybe<Scalars['Float']>;
  hitDouble9Rate?: InputMaybe<Scalars['Float']>;
  hitDouble10Rate?: InputMaybe<Scalars['Float']>;
  hitDouble11Rate?: InputMaybe<Scalars['Float']>;
  hitDouble12Rate?: InputMaybe<Scalars['Float']>;
  hitDouble13Rate?: InputMaybe<Scalars['Float']>;
  hitDouble14Rate?: InputMaybe<Scalars['Float']>;
  hitDouble15Rate?: InputMaybe<Scalars['Float']>;
  hitDouble16Rate?: InputMaybe<Scalars['Float']>;
  hitDouble17Rate?: InputMaybe<Scalars['Float']>;
  hitDouble18Rate?: InputMaybe<Scalars['Float']>;
  hitDouble19Rate?: InputMaybe<Scalars['Float']>;
  hitDouble20Rate?: InputMaybe<Scalars['Float']>;
  hitInnerBullRate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle1Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle2Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle3Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle4Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle5Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle6Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle7Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle8Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle9Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle10Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle11Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle12Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle13Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle14Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle15Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle16Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle17Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle18Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle19Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle20Rate?: InputMaybe<Scalars['Float']>;
  hitOuterBullRate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle1Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle2Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle3Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle4Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle5Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle6Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle7Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle8Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle9Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle10Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle11Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle12Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle13Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle14Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle15Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle16Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle17Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle18Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle19Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle20Rate?: InputMaybe<Scalars['Float']>;
  hitTriple1Rate?: InputMaybe<Scalars['Float']>;
  hitTriple2Rate?: InputMaybe<Scalars['Float']>;
  hitTriple3Rate?: InputMaybe<Scalars['Float']>;
  hitTriple4Rate?: InputMaybe<Scalars['Float']>;
  hitTriple5Rate?: InputMaybe<Scalars['Float']>;
  hitTriple6Rate?: InputMaybe<Scalars['Float']>;
  hitTriple7Rate?: InputMaybe<Scalars['Float']>;
  hitTriple8Rate?: InputMaybe<Scalars['Float']>;
  hitTriple9Rate?: InputMaybe<Scalars['Float']>;
  hitTriple10Rate?: InputMaybe<Scalars['Float']>;
  hitTriple11Rate?: InputMaybe<Scalars['Float']>;
  hitTriple12Rate?: InputMaybe<Scalars['Float']>;
  hitTriple13Rate?: InputMaybe<Scalars['Float']>;
  hitTriple14Rate?: InputMaybe<Scalars['Float']>;
  hitTriple15Rate?: InputMaybe<Scalars['Float']>;
  hitTriple16Rate?: InputMaybe<Scalars['Float']>;
  hitTriple17Rate?: InputMaybe<Scalars['Float']>;
  hitTriple18Rate?: InputMaybe<Scalars['Float']>;
  hitTriple19Rate?: InputMaybe<Scalars['Float']>;
  hitTriple20Rate?: InputMaybe<Scalars['Float']>;
  target20Rate?: InputMaybe<Scalars['Float']>;
  target20TripleRate?: InputMaybe<Scalars['Float']>;
  ton80Rate?: InputMaybe<Scalars['Float']>;
};

export type CountUpAnalysisResult = {
  __typename?: 'CountUpAnalysisResult';
  bullRateAverage?: Maybe<Scalars['Float']>;
  hatRateAverage?: Maybe<Scalars['Float']>;
  hitDouble1RateAverage: Scalars['Float'];
  hitDouble2RateAverage: Scalars['Float'];
  hitDouble3RateAverage: Scalars['Float'];
  hitDouble4RateAverage: Scalars['Float'];
  hitDouble5RateAverage: Scalars['Float'];
  hitDouble6RateAverage: Scalars['Float'];
  hitDouble7RateAverage: Scalars['Float'];
  hitDouble8RateAverage: Scalars['Float'];
  hitDouble9RateAverage: Scalars['Float'];
  hitDouble10RateAverage: Scalars['Float'];
  hitDouble11RateAverage: Scalars['Float'];
  hitDouble12RateAverage: Scalars['Float'];
  hitDouble13RateAverage: Scalars['Float'];
  hitDouble14RateAverage: Scalars['Float'];
  hitDouble15RateAverage: Scalars['Float'];
  hitDouble16RateAverage: Scalars['Float'];
  hitDouble17RateAverage: Scalars['Float'];
  hitDouble18RateAverage: Scalars['Float'];
  hitDouble19RateAverage: Scalars['Float'];
  hitDouble20RateAverage: Scalars['Float'];
  hitInnerBullRateAverage?: Maybe<Scalars['Float']>;
  hitInnerSingle1RateAverage: Scalars['Float'];
  hitInnerSingle2RateAverage: Scalars['Float'];
  hitInnerSingle3RateAverage: Scalars['Float'];
  hitInnerSingle4RateAverage: Scalars['Float'];
  hitInnerSingle5RateAverage: Scalars['Float'];
  hitInnerSingle6RateAverage: Scalars['Float'];
  hitInnerSingle7RateAverage: Scalars['Float'];
  hitInnerSingle8RateAverage: Scalars['Float'];
  hitInnerSingle9RateAverage: Scalars['Float'];
  hitInnerSingle10RateAverage: Scalars['Float'];
  hitInnerSingle11RateAverage: Scalars['Float'];
  hitInnerSingle12RateAverage: Scalars['Float'];
  hitInnerSingle13RateAverage: Scalars['Float'];
  hitInnerSingle14RateAverage: Scalars['Float'];
  hitInnerSingle15RateAverage: Scalars['Float'];
  hitInnerSingle16RateAverage: Scalars['Float'];
  hitInnerSingle17RateAverage: Scalars['Float'];
  hitInnerSingle18RateAverage: Scalars['Float'];
  hitInnerSingle19RateAverage: Scalars['Float'];
  hitInnerSingle20RateAverage: Scalars['Float'];
  hitOuterBullRateAverage?: Maybe<Scalars['Float']>;
  hitOuterSingle1RateAverage: Scalars['Float'];
  hitOuterSingle2RateAverage: Scalars['Float'];
  hitOuterSingle3RateAverage: Scalars['Float'];
  hitOuterSingle4RateAverage: Scalars['Float'];
  hitOuterSingle5RateAverage: Scalars['Float'];
  hitOuterSingle6RateAverage: Scalars['Float'];
  hitOuterSingle7RateAverage: Scalars['Float'];
  hitOuterSingle8RateAverage: Scalars['Float'];
  hitOuterSingle9RateAverage: Scalars['Float'];
  hitOuterSingle10RateAverage: Scalars['Float'];
  hitOuterSingle11RateAverage: Scalars['Float'];
  hitOuterSingle12RateAverage: Scalars['Float'];
  hitOuterSingle13RateAverage: Scalars['Float'];
  hitOuterSingle14RateAverage: Scalars['Float'];
  hitOuterSingle15RateAverage: Scalars['Float'];
  hitOuterSingle16RateAverage: Scalars['Float'];
  hitOuterSingle17RateAverage: Scalars['Float'];
  hitOuterSingle18RateAverage: Scalars['Float'];
  hitOuterSingle19RateAverage: Scalars['Float'];
  hitOuterSingle20RateAverage: Scalars['Float'];
  hitTriple1RateAverage: Scalars['Float'];
  hitTriple2RateAverage: Scalars['Float'];
  hitTriple3RateAverage: Scalars['Float'];
  hitTriple4RateAverage: Scalars['Float'];
  hitTriple5RateAverage: Scalars['Float'];
  hitTriple6RateAverage: Scalars['Float'];
  hitTriple7RateAverage: Scalars['Float'];
  hitTriple8RateAverage: Scalars['Float'];
  hitTriple9RateAverage: Scalars['Float'];
  hitTriple10RateAverage: Scalars['Float'];
  hitTriple11RateAverage: Scalars['Float'];
  hitTriple12RateAverage: Scalars['Float'];
  hitTriple13RateAverage: Scalars['Float'];
  hitTriple14RateAverage: Scalars['Float'];
  hitTriple15RateAverage: Scalars['Float'];
  hitTriple16RateAverage: Scalars['Float'];
  hitTriple17RateAverage: Scalars['Float'];
  hitTriple18RateAverage: Scalars['Float'];
  hitTriple19RateAverage: Scalars['Float'];
  hitTriple20RateAverage?: Maybe<Scalars['Float']>;
  target20RateAverage?: Maybe<Scalars['Float']>;
  target20TripleRateAverage?: Maybe<Scalars['Float']>;
  ton80RateAverage?: Maybe<Scalars['Float']>;
};

export type CountUpOption = {
  __typename?: 'CountUpOption';
  separatedBull: Scalars['Boolean'];
};

export type CountUpOptionInput = {
  separatedBull?: InputMaybe<Scalars['Boolean']>;
};

export type CountUpPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  analysisData?: InputMaybe<CountUpAnalysisDataInput>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type CountUpUnitDataInput = {
  playerDatas: Array<CountUpPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export enum Country {
  Japan = 'Japan',
  Us = 'US'
}

export type CreateChatRoomInput = {
  members: Array<ChatMemberInput>;
  name?: InputMaybe<Scalars['String']>;
  type: ChatRoomType;
};

export type CreateClubInput = {
  boardCamRestriction?: InputMaybe<Scalars['Boolean']>;
  boardSizeRestriction?: InputMaybe<ClubBoardSizeOption>;
  clubImg?: InputMaybe<Scalars['Upload']>;
  clubType?: InputMaybe<ClubTypeOption>;
  comment: Scalars['String'];
  name: Scalars['String'];
  ownerId: Scalars['Int'];
  participateMethod: ParticipateMethodOption;
  password?: InputMaybe<Scalars['String']>;
};

export type CreateMachineForProKitInput = {
  proKitId: Scalars['Int'];
};

export type CreateShopIncomeCollectionEventInput = {
  eventName: Scalars['String'];
  shopId: Scalars['Int'];
};

export type CricketActivity = {
  __typename?: 'CricketActivity';
  playedGamesStatsList: Array<CricketStats>;
  statsOfPreviousDay: CricketStats;
};

export type CricketAnalysisDataInput = {
  bedRate: Scalars['Float'];
  dartCountToGet3Marks15: Scalars['Int'];
  dartCountToGet3Marks16: Scalars['Int'];
  dartCountToGet3Marks17: Scalars['Int'];
  dartCountToGet3Marks18: Scalars['Int'];
  dartCountToGet3Marks19: Scalars['Int'];
  dartCountToGet3Marks20: Scalars['Int'];
  dartCountToGet3MarksBull: Scalars['Int'];
  firstRoundMarkCount: Scalars['Int'];
  hasClosed15BeforeOpponentScored: Scalars['Boolean'];
  hasClosed16BeforeOpponentScored: Scalars['Boolean'];
  hasClosed17BeforeOpponentScored: Scalars['Boolean'];
  hasClosed18BeforeOpponentScored: Scalars['Boolean'];
  hasClosed19BeforeOpponentScored: Scalars['Boolean'];
  hasClosed20BeforeOpponentScored: Scalars['Boolean'];
  hasClosedBullBeforeOpponentScored: Scalars['Boolean'];
  hasOpened15: Scalars['Boolean'];
  hasOpened16: Scalars['Boolean'];
  hasOpened17: Scalars['Boolean'];
  hasOpened18: Scalars['Boolean'];
  hasOpened19: Scalars['Boolean'];
  hasOpened20: Scalars['Boolean'];
  hasOpenedBull: Scalars['Boolean'];
  isComeback: Scalars['Boolean'];
  isFirstRoundOpen: Scalars['Boolean'];
  isHavingControl: Scalars['Boolean'];
  keepRate: Scalars['Float'];
  mark0Rate: Scalars['Float'];
  mark1Rate: Scalars['Float'];
  mark2Rate: Scalars['Float'];
  mark3Rate: Scalars['Float'];
  mark4Rate: Scalars['Float'];
  mark5Rate: Scalars['Float'];
  mark6Rate: Scalars['Float'];
  mark7Rate: Scalars['Float'];
  mark8Rate: Scalars['Float'];
  mark9Rate: Scalars['Float'];
  shootRate: Scalars['Float'];
  tripleRate: Scalars['Float'];
  whiteHorseRate: Scalars['Float'];
};

export type CricketAnalysisResult = {
  __typename?: 'CricketAnalysisResult';
  bedRateAverage: Scalars['Float'];
  closed15BeforeOpponentScoredRate: Scalars['Float'];
  closed16BeforeOpponentScoredRate: Scalars['Float'];
  closed17BeforeOpponentScoredRate: Scalars['Float'];
  closed18BeforeOpponentScoredRate: Scalars['Float'];
  closed19BeforeOpponentScoredRate: Scalars['Float'];
  closed20BeforeOpponentScoredRate: Scalars['Float'];
  closedBullBeforeOpponentScoredRate: Scalars['Float'];
  comebackRate: Scalars['Float'];
  dartCountToGet3Marks15Average: Scalars['Float'];
  dartCountToGet3Marks16Average: Scalars['Float'];
  dartCountToGet3Marks17Average: Scalars['Float'];
  dartCountToGet3Marks18Average: Scalars['Float'];
  dartCountToGet3Marks19Average: Scalars['Float'];
  dartCountToGet3Marks20Average: Scalars['Float'];
  dartCountToGet3MarksBullAverage: Scalars['Float'];
  firstRoundMarkCountAverage: Scalars['Float'];
  firstRoundOpenRate: Scalars['Float'];
  havingControlRate: Scalars['Float'];
  keepRateAverage: Scalars['Float'];
  mark0RateAverage: Scalars['Float'];
  mark1RateAverage: Scalars['Float'];
  mark2RateAverage: Scalars['Float'];
  mark3RateAverage: Scalars['Float'];
  mark4RateAverage: Scalars['Float'];
  mark5RateAverage: Scalars['Float'];
  mark6RateAverage: Scalars['Float'];
  mark7RateAverage: Scalars['Float'];
  mark8RateAverage: Scalars['Float'];
  mark9RateAverage: Scalars['Float'];
  mpr80Average: Scalars['Float'];
  mpr80Max: Scalars['Float'];
  mpr100Average: Scalars['Float'];
  mpr100Max: Scalars['Float'];
  opened15Rate: Scalars['Float'];
  opened16Rate: Scalars['Float'];
  opened17Rate: Scalars['Float'];
  opened18Rate: Scalars['Float'];
  opened19Rate: Scalars['Float'];
  opened20Rate: Scalars['Float'];
  openedBullRate: Scalars['Float'];
  shootRateAverage: Scalars['Float'];
  tripleRateAverage: Scalars['Float'];
  whiteHorseRateAverage: Scalars['Float'];
  winRoundAverage: Scalars['Float'];
};

export type CricketAnalysisResultOptionInput = {
  handicap?: InputMaybe<Scalars['Boolean']>;
  maxRound?: InputMaybe<Scalars['Int']>;
};

export type CricketCountUpAnalysisDataInput = {
  mpr100: Scalars['Float'];
  target15MarkCount: Scalars['Int'];
  target15TripleRate: Scalars['Float'];
  target16MarkCount: Scalars['Int'];
  target16TripleRate: Scalars['Float'];
  target17MarkCount: Scalars['Int'];
  target17TripleRate: Scalars['Float'];
  target18MarkCount: Scalars['Int'];
  target18TripleRate: Scalars['Float'];
  target19MarkCount: Scalars['Int'];
  target19TripleRate: Scalars['Float'];
  target20MarkCount: Scalars['Int'];
  target20TripleRate: Scalars['Float'];
  targetBullMarkCount: Scalars['Int'];
  tripleRate: Scalars['Float'];
};

export type CricketCountUpAnalysisResult = {
  __typename?: 'CricketCountUpAnalysisResult';
  mpr100Average: Scalars['Float'];
  target15MarkCountAverage: Scalars['Float'];
  target15TripleRateAverage: Scalars['Float'];
  target16MarkCountAverage: Scalars['Float'];
  target16TripleRateAverage: Scalars['Float'];
  target17MarkCountAverage: Scalars['Float'];
  target17TripleRateAverage: Scalars['Float'];
  target18MarkCountAverage: Scalars['Float'];
  target18TripleRateAverage: Scalars['Float'];
  target19MarkCountAverage: Scalars['Float'];
  target19TripleRateAverage: Scalars['Float'];
  target20MarkCountAverage: Scalars['Float'];
  target20TripleRateAverage: Scalars['Float'];
  targetBullMarkCountAverage: Scalars['Float'];
  tripleRateAverage: Scalars['Float'];
};

export type CricketCountUpPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  analysisData?: InputMaybe<CricketCountUpAnalysisDataInput>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type CricketCountUpUnitDataInput = {
  playerDatas: Array<CricketCountUpPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type CricketOption = {
  __typename?: 'CricketOption';
  handicap?: Maybe<Scalars['Boolean']>;
  maxRound: Scalars['Int'];
};

export type CricketOptionInput = {
  handicap?: InputMaybe<Scalars['Boolean']>;
  maxRound: Scalars['Int'];
};

export type CricketPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  analysisData?: InputMaybe<CricketAnalysisDataInput>;
  award: AwardInput;
  playerId: Scalars['Int'];
  stats: CricketStatsInput;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type CricketStats = {
  __typename?: 'CricketStats';
  mpr80: Scalars['Float'];
  mpr100: Scalars['Float'];
};

export type CricketStatsInput = {
  mpr80: Scalars['Float'];
  mpr100: Scalars['Float'];
};

export type CricketUnitDataInput = {
  playerDatas: Array<CricketPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export enum Currency {
  Jpy = 'JPY'
}

export enum CustomizeItemManagementType {
  Store = 'Store',
  UploadedByPlayer = 'UploadedByPlayer'
}

export enum CustomizeItemType {
  AwardMovie = 'AwardMovie',
  GameBackground = 'GameBackground',
  GameHitSound = 'GameHitSound',
  HitEffect = 'HitEffect',
  PlayerChangeSound = 'PlayerChangeSound',
  Sound = 'Sound'
}

export type CutThroatCricketOptionInput = {
  maxRound: Scalars['Int'];
};

export type CutThroatCricketPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  stats: CricketStatsInput;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type CutThroatCricketUnitDataInput = {
  playerDatas: Array<CutThroatCricketPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type DbPlayer = {
  __typename?: 'DbPlayer';
  granId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DbUser = {
  __typename?: 'DbUser';
  cognitoId?: Maybe<Scalars['String']>;
  coinBalance?: Maybe<Scalars['Int']>;
  connectionWithV2?: Maybe<UserConnectionWithV2>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  isMigratedFromV2?: Maybe<Scalars['Boolean']>;
  lastLoginAt?: Maybe<Scalars['Date']>;
  players?: Maybe<Array<Maybe<DbPlayer>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type Dealer = {
  __typename?: 'Dealer';
  address: Scalars['String'];
  countryCode: Scalars['String'];
  dealerType: DealerType;
  distributor: Distributor;
  email: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isActivated: Scalars['Boolean'];
  languageCode: Scalars['String'];
  lastInformationEditedAt?: Maybe<Scalars['Date']>;
  lastInformationEditedBy?: Maybe<DealerInformationEditor>;
  name: Scalars['String'];
  partnerDealer?: Maybe<Dealer>;
  phoneNumber: Scalars['String'];
  province: Scalars['String'];
  shops: Array<Shop>;
  status: DealerStatus;
  zipCode: Scalars['String'];
};

export type DealerConnection = {
  __typename?: 'DealerConnection';
  edges: Array<Maybe<DealerEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DealerEdge = {
  __typename?: 'DealerEdge';
  cursor: Scalars['String'];
  node?: Maybe<Dealer>;
};

export type DealerFilterOption = {
  province?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DealerStatusFilter>;
};

export enum DealerInformationEditor {
  Dealer = 'Dealer',
  Distributor = 'Distributor'
}

export type DealerInput = {
  dealerId: Scalars['Int'];
};

export type DealerName = {
  __typename?: 'DealerName';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type DealerSession = {
  __typename?: 'DealerSession';
  accessToken?: Maybe<CognitoAccessToken>;
  clockDrift?: Maybe<Scalars['Int']>;
  idToken?: Maybe<CognitoIdToken>;
  refreshToken?: Maybe<CognitoRefreshToken>;
};

export enum DealerStatus {
  Deleted = 'Deleted',
  Enabled = 'Enabled',
  OutOfService = 'OutOfService'
}

export enum DealerStatusFilter {
  Enabled = 'Enabled',
  OutOfService = 'OutOfService'
}

export enum DealerType {
  Normal = 'Normal',
  Partner = 'Partner',
  Subordinate = 'Subordinate'
}

export type DealerWithSession = {
  __typename?: 'DealerWithSession';
  dealer: Dealer;
  session: DealerSession;
};

export type DeleteAppVersionInput = {
  platform: Platform;
  version: Scalars['String'];
};

export type DeleteChatMessageInput = {
  messageId: Scalars['ID'];
  playerId: Scalars['Int'];
};

export type DeleteDealerInput = {
  dealerId: Scalars['Int'];
};

export type DeleteGranPlayerAppVersionInput = {
  platform: Platform;
  version: Scalars['String'];
};

export type DeleteMachineInput = {
  machineId: Scalars['Int'];
};

export type DeleteMyClubInput = {
  clubId: Scalars['Int'];
  playerId: Scalars['Int'];
};

export type DeleteOwnClubInput = {
  clubId: Scalars['Int'];
  playerId: Scalars['Int'];
};

export type DeleteProAppVersionInput = {
  version: Scalars['String'];
};

export type DeleteShopInput = {
  shopId: Scalars['Int'];
};

export type DeleteUserInput = {
  userId: Scalars['Int'];
};

export type DeltaShootAnalysisDataInput = {
  bedRate: Scalars['Float'];
  isBothRingBust: Scalars['Boolean'];
  isBothRingKeep: Scalars['Boolean'];
  isDoubleRingBust: Scalars['Boolean'];
  isDoubleRingKeep: Scalars['Boolean'];
  isInnerBullBust: Scalars['Boolean'];
  isInnerBullKeep: Scalars['Boolean'];
  isNoBust: Scalars['Boolean'];
  isTarget10Bust: Scalars['Boolean'];
  isTarget10Keep: Scalars['Boolean'];
  isTarget11Bust: Scalars['Boolean'];
  isTarget11Keep: Scalars['Boolean'];
  isTarget12Bust: Scalars['Boolean'];
  isTarget12Keep: Scalars['Boolean'];
  isTarget13Bust: Scalars['Boolean'];
  isTarget13Keep: Scalars['Boolean'];
  isTarget14Bust: Scalars['Boolean'];
  isTarget14Keep: Scalars['Boolean'];
  isTarget15Bust: Scalars['Boolean'];
  isTarget15Keep: Scalars['Boolean'];
  isTarget16Bust: Scalars['Boolean'];
  isTarget16Keep: Scalars['Boolean'];
  isTarget17Bust: Scalars['Boolean'];
  isTarget17Keep: Scalars['Boolean'];
  isTarget18Bust: Scalars['Boolean'];
  isTarget18Keep: Scalars['Boolean'];
  isTarget19Bust: Scalars['Boolean'];
  isTarget19Keep: Scalars['Boolean'];
  isTarget20Bust: Scalars['Boolean'];
  isTarget20Keep: Scalars['Boolean'];
  isTargetBullBust: Scalars['Boolean'];
  isTargetBullKeep: Scalars['Boolean'];
  isTripleRingBust: Scalars['Boolean'];
  isTripleRingKeep: Scalars['Boolean'];
};

export type DeltaShootAnalysisResult = {
  __typename?: 'DeltaShootAnalysisResult';
  bedRateAverage: Scalars['Float'];
  bothRingBustRate: Scalars['Float'];
  bothRingKeepRate: Scalars['Float'];
  doubleRingBustRate: Scalars['Float'];
  doubleRingKeepRate: Scalars['Float'];
  innerBullBustRate: Scalars['Float'];
  innerBullKeepRate: Scalars['Float'];
  noBustRate: Scalars['Float'];
  target10BustRate: Scalars['Float'];
  target10KeepRate: Scalars['Float'];
  target11BustRate: Scalars['Float'];
  target11KeepRate: Scalars['Float'];
  target12BustRate: Scalars['Float'];
  target12KeepRate: Scalars['Float'];
  target13BustRate: Scalars['Float'];
  target13KeepRate: Scalars['Float'];
  target14BustRate: Scalars['Float'];
  target14KeepRate: Scalars['Float'];
  target15BustRate: Scalars['Float'];
  target15KeepRate: Scalars['Float'];
  target16BustRate: Scalars['Float'];
  target16KeepRate: Scalars['Float'];
  target17BustRate: Scalars['Float'];
  target17KeepRate: Scalars['Float'];
  target18BustRate: Scalars['Float'];
  target18KeepRate: Scalars['Float'];
  target19BustRate: Scalars['Float'];
  target19KeepRate: Scalars['Float'];
  target20BustRate: Scalars['Float'];
  target20KeepRate: Scalars['Float'];
  targetBullBustRate: Scalars['Float'];
  targetBullKeepRate: Scalars['Float'];
  tripleRingBustRate: Scalars['Float'];
  tripleRingKeepRate: Scalars['Float'];
};

export enum DeltaShootMode {
  GranMaster = 'GranMaster',
  Master = 'Master',
  Normal = 'Normal'
}

export type DeltaShootOption = {
  __typename?: 'DeltaShootOption';
  mode: DeltaShootMode;
};

export type DeltaShootOptionInput = {
  mode: DeltaShootMode;
};

export type DeltaShootPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  analysisData?: InputMaybe<DeltaShootAnalysisDataInput>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type DeltaShootUnitDataInput = {
  playerDatas: Array<DeltaShootPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type Distributor = {
  __typename?: 'Distributor';
  address: Scalars['String'];
  countryCode: Scalars['String'];
  dealers: Array<Dealer>;
  email: Scalars['String'];
  id: Scalars['Int'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  province: Scalars['String'];
  status: DistributorStatus;
  zipCode: Scalars['String'];
};

export type DistributorInput = {
  distributorId: Scalars['Int'];
};

export type DistributorSession = {
  __typename?: 'DistributorSession';
  accessToken?: Maybe<CognitoAccessToken>;
  clockDrift?: Maybe<Scalars['Int']>;
  idToken?: Maybe<CognitoIdToken>;
  refreshToken?: Maybe<CognitoRefreshToken>;
};

export enum DistributorStatus {
  Deleted = 'Deleted',
  Enabled = 'Enabled',
  OutOfService = 'OutOfService'
}

export type DistributorWithSession = {
  __typename?: 'DistributorWithSession';
  distributor: Distributor;
  session: DistributorSession;
};

export type DowngradeProKitSubscriptionPlanInput = {
  proKitId: Scalars['Int'];
};

export type EditAppVersionInput = {
  description?: InputMaybe<Scalars['String']>;
  dev?: InputMaybe<Scalars['Boolean']>;
  onlineAvailable?: InputMaybe<Scalars['Boolean']>;
  platform: Platform;
  status?: InputMaybe<AppVersionStatus>;
  version: Scalars['String'];
};

export type EditChatMessageInput = {
  content: Scalars['String'];
  messageId: Scalars['ID'];
  playerId: Scalars['Int'];
};

export type EditClubInput = {
  boardCamRestriction?: InputMaybe<Scalars['Boolean']>;
  clubId: Scalars['Int'];
  clubImg?: InputMaybe<Scalars['Upload']>;
  clubType?: InputMaybe<ClubTypeOption>;
  comment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  participateMethod?: InputMaybe<ParticipateMethodOption>;
  password?: InputMaybe<Scalars['String']>;
  playerId: Scalars['Int'];
};

export type EditDealerEmailInput = {
  dealerId: Scalars['Int'];
  email: Scalars['String'];
  newEmail: Scalars['String'];
};

export type EditDealerInformationInput = {
  address?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  dealerId: Scalars['Int'];
  iconImage?: InputMaybe<Scalars['Upload']>;
  languageCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type EditGranPlayerAppVersionInput = {
  description?: InputMaybe<Scalars['String']>;
  dev?: InputMaybe<Scalars['Boolean']>;
  platform: Platform;
  status?: InputMaybe<AppVersionStatus>;
  version: Scalars['String'];
};

export type EditInAppInformationInput = {
  apps?: InputMaybe<Array<App>>;
  countryCodes?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  listingDeadline?: InputMaybe<Scalars['Date']>;
};

export type EditInAppInformationRawContentInput = {
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  informationId: Scalars['Int'];
  languageCode: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  webLinkUrl?: InputMaybe<Scalars['String']>;
};

export type EditIncomeConversionRateInput = {
  incomeConversionRate?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['Int'];
};

export type EditMachineGameSettingInput = {
  machineIds: Array<Scalars['Int']>;
  machineSettingUpdatePassword?: InputMaybe<Scalars['String']>;
  setting: Scalars['JSON'];
};

export type EditMachineInformationInput = {
  isEmittingShopBeacon?: InputMaybe<Scalars['Boolean']>;
  machineId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  teamViewerId?: InputMaybe<Scalars['String']>;
};

export type EditProAppVersionInput = {
  description?: InputMaybe<Scalars['String']>;
  dev?: InputMaybe<Scalars['Boolean']>;
  onlineAvailable?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<AppVersionStatus>;
  version: Scalars['String'];
};

export type EditShopEmailInput = {
  email: Scalars['String'];
  newEmail: Scalars['String'];
  shopId: Scalars['Int'];
};

export type EditShopInformationInput = {
  address?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  iconImage?: InputMaybe<Scalars['Upload']>;
  languageCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  shopId: Scalars['Int'];
  zipCode?: InputMaybe<Scalars['String']>;
};

export type EmailInput = {
  email: Scalars['String'];
};

export type EnterOnlineLobbyInput = {
  args?: InputMaybe<PaginationInput>;
  lobbyId: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type EnterOnlineTopInput = {
  deviceId: Scalars['String'];
  noExpiration?: InputMaybe<Scalars['Boolean']>;
  playerId: Scalars['Int'];
};

export type ExitMatchAndLobbyInput = {
  unitId: Scalars['Int'];
};

export type ExitMatchInput = {
  unitId: Scalars['Int'];
};

export type ExitOnlineLobbyInput = {
  unitId: Scalars['Int'];
};

export type ExitOnlineTopInput = {
  unitId: Scalars['Int'];
};

export type ExitedMatchEvent = RemoteOperationEvent & {
  __typename?: 'ExitedMatchEvent';
  code: RemoteOperationEventCode;
};

export type FindPrivateChatRoomInput = {
  ownPlayerId: Scalars['Int'];
  targetPlayerId: Scalars['Int'];
};

export type ForceSetDealerPasswordByDistributorInput = {
  dealerId: Scalars['Int'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ForceSetGranCoinBalanceInput = {
  amount: Scalars['Int'];
  method?: InputMaybe<ForceSetGranCoinBalanceMethod>;
  userId: Scalars['Int'];
};

export enum ForceSetGranCoinBalanceMethod {
  Absolute = 'Absolute',
  Decrement = 'Decrement',
  Increment = 'Increment'
}

export type ForceSetPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ForceSetShopPasswordByDistributorInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  shopId: Scalars['Int'];
};

export type FreezeZeroOnePlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  stats: ZeroOneStatsInput;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type FreezeZeroOneUnitDataInput = {
  playerDatas: Array<FreezeZeroOnePlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type FriendshipState = {
  __typename?: 'FriendshipState';
  blocked: Scalars['Boolean'];
  blocking: Scalars['Boolean'];
  followed: Scalars['Boolean'];
  following: Scalars['Boolean'];
};

export type GameBackgroundItem = {
  __typename?: 'GameBackgroundItem';
  contentImageUrl: Scalars['String'];
  customizeItemType: CustomizeItemType;
  gameCodes: Array<GameCode>;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
};

export type GameBackgroundSetting = {
  __typename?: 'GameBackgroundSetting';
  gameBackgroundItem: GameBackgroundItem;
  gameCode: GameCode;
};

export enum GameCode {
  BeyondTop = 'BeyondTop',
  Choice = 'Choice',
  CountUp = 'CountUp',
  Cricket = 'Cricket',
  CricketCountUp = 'CricketCountUp',
  CutThroatCricket = 'CutThroatCricket',
  DeltaShoot = 'DeltaShoot',
  FreezeZeroOne = 'FreezeZeroOne',
  HalfIt = 'HalfIt',
  HiddenCricket = 'HiddenCricket',
  HiddenCutThroatCricket = 'HiddenCutThroatCricket',
  KickDown = 'KickDown',
  MultipleCricket = 'MultipleCricket',
  Oniren = 'Oniren',
  Pirates = 'Pirates',
  Rotation = 'Rotation',
  ShootForce = 'ShootForce',
  Spider = 'Spider',
  TargetBull = 'TargetBull',
  TargetHat = 'TargetHat',
  TargetHorse = 'TargetHorse',
  TargetTwenty = 'TargetTwenty',
  TeamCricket = 'TeamCricket',
  WildCardCricket = 'WildCardCricket',
  ZeroOne = 'ZeroOne'
}

export enum GameCodeForProfile {
  BeyondTop = 'BeyondTop',
  CountUp = 'CountUp',
  CricketCountUp = 'CricketCountUp',
  CutThroatCricket = 'CutThroatCricket',
  DeltaShoot = 'DeltaShoot',
  FreezeZeroOne301 = 'FreezeZeroOne301',
  FreezeZeroOne501 = 'FreezeZeroOne501',
  FreezeZeroOne701 = 'FreezeZeroOne701',
  FreezeZeroOne901 = 'FreezeZeroOne901',
  FunMission = 'FunMission',
  HalfIt = 'HalfIt',
  HiddenCricket = 'HiddenCricket',
  HiddenCutThroat = 'HiddenCutThroat',
  HideAndSeek = 'HideAndSeek',
  HyperBull = 'HyperBull',
  MultipleCricket = 'MultipleCricket',
  Oniren = 'Oniren',
  Pirates = 'Pirates',
  Rotation = 'Rotation',
  ShootForce = 'ShootForce',
  Spider = 'Spider',
  StandardCricket = 'StandardCricket',
  Target20 = 'Target20',
  TargetBull = 'TargetBull',
  TargetHat = 'TargetHat',
  TargetHorse = 'TargetHorse',
  TeamCricket = 'TeamCricket',
  TicTacToe = 'TicTacToe',
  TreasureHunt = 'TreasureHunt',
  TwoLine = 'TwoLine',
  WildCardCricket = 'WildCardCricket',
  ZeroOne301 = 'ZeroOne301',
  ZeroOne501 = 'ZeroOne501',
  ZeroOne701 = 'ZeroOne701',
  ZeroOne901 = 'ZeroOne901',
  ZeroOne1101 = 'ZeroOne1101',
  ZeroOne1501 = 'ZeroOne1501'
}

export type GameCondition = {
  __typename?: 'GameCondition';
  gameCodeList: Array<OnlineMatchGameCode>;
  in: InCondition;
  medleyOrder?: Maybe<MedleyOrderCondition>;
  out: OutCondition;
  separateBull: Scalars['Boolean'];
  throwOrder: ThrowOrderCondition;
};

export type GameConditionInput = {
  gameCodeList: Array<OnlineMatchGameCode>;
  in: InCondition;
  medleyOrder?: InputMaybe<MedleyOrderCondition>;
  out: OutCondition;
  separateBull: Scalars['Boolean'];
  throwOrder: ThrowOrderCondition;
};

export type GameHitSoundItem = {
  __typename?: 'GameHitSoundItem';
  contentDoubleHitSoundUrl: Scalars['String'];
  contentSingleHitSoundUrl: Scalars['String'];
  contentTripleHitSoundUrl: Scalars['String'];
  customizeItemType: CustomizeItemType;
  gameCodes: Array<GameCode>;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  previewMovieUrl: Scalars['String'];
};

export type GameHitSoundSetting = {
  __typename?: 'GameHitSoundSetting';
  gameCode: GameCode;
  gameHitSoundItem: GameHitSoundItem;
};

export type GameOption = {
  __typename?: 'GameOption';
  double_in: Scalars['Boolean'];
  double_out: Scalars['Boolean'];
  master_in: Scalars['Boolean'];
  master_out: Scalars['Boolean'];
  separate_bull: Scalars['Boolean'];
};

export type GameWinCount = {
  __typename?: 'GameWinCount';
  receiver: Scalars['Int'];
  requester: Scalars['Int'];
};

export type GetActivePlayerInput = {
  boardSize: BoardSize;
  userId: Scalars['Int'];
};

export type GetAllShopsNameByDealerIdInput = {
  dealerId: Scalars['Int'];
};

export type GetAnimalWinCountsInput = {
  playerId: Scalars['Int'];
};

export type GetAppVersionsListInput = {
  args?: InputMaybe<PaginationInput>;
  filterOption?: InputMaybe<AppVersionFilterOption>;
};

export type GetAwardInput = {
  dateRange?: InputMaybe<AwardDateRangeInput>;
  playerId: Scalars['Int'];
};

export type GetClubBannedMembersListInput = {
  args?: InputMaybe<PaginationInput>;
  clubId: Scalars['Int'];
};

export type GetClubInvitationListInput = {
  playerId: Scalars['Int'];
};

export type GetClubJoinRequestListInput = {
  args?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
};

export type GetClubListInput = {
  args?: InputMaybe<PaginationInput>;
  filterOption?: InputMaybe<ClubFilterOption>;
  playerId: Scalars['Int'];
};

export type GetClubMembersListInput = {
  args?: InputMaybe<PaginationInput>;
  clubId: Scalars['Int'];
};

export type GetCountUpAnalysisResultInput = {
  option?: InputMaybe<CountUpOptionInput>;
  playerId: Scalars['Int'];
  playsCountToBeAnalyzed?: InputMaybe<Scalars['Int']>;
};

export type GetCountUpScoreInput = {
  playerId: Scalars['Int'];
  separatedBull?: InputMaybe<Scalars['Boolean']>;
};

export type GetCricketActivityInput = {
  date: Scalars['Date'];
  playerId: Scalars['Int'];
};

export type GetCricketActivityListInput = {
  days?: InputMaybe<Scalars['Int']>;
  playerId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type GetCricketAnalysisResultInput = {
  option?: InputMaybe<CricketOptionInput>;
  playerId: Scalars['Int'];
  playsCountToBeAnalyzed?: InputMaybe<Scalars['Int']>;
};

export type GetCricketCountUpAnalysisResultInput = {
  playerId: Scalars['Int'];
  playsCountToBeAnalyzed?: InputMaybe<Scalars['Int']>;
};

export type GetCricketStatsInput = {
  playerId: Scalars['Int'];
};

export type GetDealersListOfDistributorInput = {
  args?: InputMaybe<PaginationInput>;
  distributorId: Scalars['Int'];
  filterOption?: InputMaybe<DealerFilterOption>;
};

export type GetDeltaShootAnalysisResultInput = {
  option?: InputMaybe<DeltaShootOptionInput>;
  playerId: Scalars['Int'];
  playsCountToBeAnalyzed?: InputMaybe<Scalars['Int']>;
};

export type GetGranPlayerAppVersionsListInput = {
  args?: InputMaybe<PaginationInput>;
  filterOption?: InputMaybe<GranPlayerAppVersionFilterOption>;
};

export type GetHalfItAnalysisResultInput = {
  option?: InputMaybe<HalfItOptionInput>;
  playerId: Scalars['Int'];
  playsCountToBeAnalyzed?: InputMaybe<Scalars['Int']>;
};

export type GetInAppInformationListByUserInput = {
  app: App;
  userId: Scalars['Int'];
};

export type GetInAppInformationListInput = {
  args?: InputMaybe<PaginationInput>;
  filterOption?: InputMaybe<InAppInformationFilterOption>;
};

export type GetLastFiveShopIncomeCollectionEventsInput = {
  shopId: Scalars['Int'];
};

export type GetMachineListOfDealerInput = {
  args?: InputMaybe<PaginationInput>;
  dealerId: Scalars['Int'];
  filterOption?: InputMaybe<MachineFilterOption>;
};

export type GetMachineListOfShopInput = {
  args?: InputMaybe<PaginationInput>;
  filterOption?: InputMaybe<MachineFilterOption>;
  shopId: Scalars['Int'];
};

export type GetMachinesWithIncomeOfShopInput = {
  shopId: Scalars['Int'];
};

export type GetMonthlyProSubscriptionPlanBillingAmountOfDealerInput = {
  dealerId: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type GetMyClubListInput = {
  args?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
};

export type GetOnlineUnitInput = {
  ownPlayerId?: InputMaybe<Scalars['Int']>;
  unitId: Scalars['Int'];
};

export type GetOnlineUnitListInput = {
  args?: InputMaybe<PaginationInput>;
  filterOption?: InputMaybe<OnlineUnitFilterOption>;
  lobbyId: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type GetOverallRatingInformationInput = {
  date: Scalars['Date'];
  playerId: Scalars['Int'];
};

export type GetOverallRatingInformationListInput = {
  days?: InputMaybe<Scalars['Int']>;
  playerId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type GetPaypayAccountLinkUrlInput = {
  dealerId: Scalars['Int'];
};

export type GetPermissionToUpdateMachineSetting = {
  machineId: Scalars['Int'];
  password: Scalars['String'];
};

export type GetPlayerProfileInput = {
  playerId: Scalars['Int'];
};

export type GetProAppVersionsListInput = {
  args?: InputMaybe<PaginationInput>;
  filterOption?: InputMaybe<ProAppVersionFilterOption>;
};

export type GetProKitListOfDealerInput = {
  args?: InputMaybe<PaginationInput>;
  dealerId: Scalars['Int'];
  filterOption?: InputMaybe<ProKitFilterOption>;
};

export type GetRemoteAcceptableFriendsInput = {
  playerId: Scalars['Int'];
};

export type GetRotationAnalysisResultInput = {
  option?: InputMaybe<RotationOptionInput>;
  playerId: Scalars['Int'];
  playsCountToBeAnalyzed?: InputMaybe<Scalars['Int']>;
};

export type GetShopByBeaconIdInput = {
  beaconId: Scalars['String'];
};

export type GetShopIncomeCollectionEventListInput = {
  pagination?: InputMaybe<PaginationInput>;
  shopId: Scalars['Int'];
};

export type GetShopsListOfDealerInput = {
  args?: InputMaybe<PaginationInput>;
  dealerId: Scalars['Int'];
  filterOption?: InputMaybe<ShopFilterOption>;
};

export type GetStoreAwardMovieItemListInput = {
  awardType: AwardKey;
  onlyPurchased?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
};

export type GetStoreGameBackgroundItemListInput = {
  gameCode?: InputMaybe<GameCode>;
  onlyPurchased?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
};

export type GetStoreGameHitSoundItemListInput = {
  gameCode?: InputMaybe<GameCode>;
  onlyPurchased?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
};

export type GetStoreHitEffectItemListInput = {
  hitEffectType: HitEffectType;
  onlyPurchased?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
};

export type GetStorePlayerChangeSoundItemListInput = {
  onlyPurchased?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
};

export type GetStoreSoundItemListInput = {
  onlyPurchased?: InputMaybe<Scalars['Boolean']>;
  pagination?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
  soundType: SoundType;
};

export type GetSubordinateDealersListOfPartnerInput = {
  args?: InputMaybe<PaginationInput>;
  filterOption?: InputMaybe<DealerFilterOption>;
  partnerDealerId: Scalars['Int'];
};

export type GetTargetBullAnalysisResultInput = {
  option?: InputMaybe<TargetBullOptionInput>;
  playerId: Scalars['Int'];
  playsCountToBeAnalyzed?: InputMaybe<Scalars['Int']>;
};

export type GetTargetTwentyAnalysisResultInput = {
  option?: InputMaybe<TargetTwentyOptionInput>;
  playerId: Scalars['Int'];
  playsCountToBeAnalyzed?: InputMaybe<Scalars['Int']>;
};

export type GetUploadedByPlayerGameBackgroundItemOfGameCodeInput = {
  gameCode: GameCode;
  playerId: Scalars['Int'];
};

export type GetUserSerialCodeIssueListByDateInput = {
  filterDate: Scalars['Date'];
};

export type GetVisitingPlayerStatisticsOfShopInput = {
  aggregationPeriod: AggregationPeriod;
  args: PaginationInput;
  shopId: Scalars['Int'];
};

export type GetWildCardCricketPlayListInput = {
  listSize?: InputMaybe<Scalars['Int']>;
  option?: InputMaybe<WildCardCricketOptionInput>;
  playerId: Scalars['Int'];
  toGetOnlyOwnPlayerAndResult?: InputMaybe<Scalars['Boolean']>;
};

export type GetZeroOneActivityInput = {
  date: Scalars['Date'];
  playerId: Scalars['Int'];
};

export type GetZeroOneActivityListInput = {
  days?: InputMaybe<Scalars['Int']>;
  playerId: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type GetZeroOneAnalysisResultInput = {
  option?: InputMaybe<ZeroOneAnalysisResultOptionInput>;
  playerId: Scalars['Int'];
  playsCountToBeAnalyzed?: InputMaybe<Scalars['Int']>;
};

export type GetZeroOneStatsInput = {
  playerId: Scalars['Int'];
};

export type GooglePurchaseInput = {
  receipt: GoogleReceipt;
  userId: Scalars['Int'];
};

export type GoogleReceipt = {
  packageName: Scalars['String'];
  productId: Scalars['String'];
  purchaseToken: Scalars['String'];
  quantity: Scalars['Int'];
};

export type GotRequestMatchResultEvent = RemoteOperationEvent & {
  __typename?: 'GotRequestMatchResultEvent';
  code: RemoteOperationEventCode;
  result: MatchRequestResult;
};

export type GotUnitListEvent = RemoteOperationEvent & {
  __typename?: 'GotUnitListEvent';
  code: RemoteOperationEventCode;
  units: OnlineUnitConnection;
};

export type GranCoinPurchasedListInput = {
  args: PaginationInput;
  userId: Scalars['Int'];
};

export type GranPlayerAppVersion = {
  __typename?: 'GranPlayerAppVersion';
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  dev: Scalars['Boolean'];
  platform: Platform;
  status: AppVersionStatus;
  updatedAt?: Maybe<Scalars['Date']>;
  version: Scalars['String'];
};

export type GranPlayerAppVersionConnection = {
  __typename?: 'GranPlayerAppVersionConnection';
  edges?: Maybe<Array<Maybe<GranPlayerAppVersionEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GranPlayerAppVersionEdge = {
  __typename?: 'GranPlayerAppVersionEdge';
  cursor: Scalars['String'];
  node?: Maybe<GranPlayerAppVersion>;
};

export type GranPlayerAppVersionFilterOption = {
  dev?: InputMaybe<Scalars['Boolean']>;
  platform?: InputMaybe<Platform>;
  status?: InputMaybe<AppVersionStatus>;
};

export type GranPlayerAppVersionInput = {
  platform?: InputMaybe<Platform>;
  version: Scalars['String'];
};

export type GranPlayerLoginInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  deviceModel?: InputMaybe<Scalars['String']>;
  deviceToken?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  osVersion?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  platform: Platform;
};

export enum GranProSubscriptionPlan {
  Basic = 'Basic',
  Enterprise = 'Enterprise'
}

export type GranProSubscriptionPlanSchedule = {
  __typename?: 'GranProSubscriptionPlanSchedule';
  subscriptionPlan: GranProSubscriptionPlan;
  validFrom: Scalars['Date'];
};

export type Guest = {
  __typename?: 'Guest';
  guest_player: Player;
  id: Scalars['Int'];
  player: Player;
  status?: Maybe<GuestStatus>;
};

export type GuestListInput = {
  args: PaginationInput;
  playerId: Scalars['Int'];
};

export enum GuestStatus {
  Disable = 'DISABLE',
  Enable = 'ENABLE'
}

export type HalfItAnalysisDataInput = {
  bedRate: Scalars['Float'];
  isDoubleRingHalf: Scalars['Boolean'];
  isDoubleRingKeep: Scalars['Boolean'];
  isNoHalf: Scalars['Boolean'];
  isTarget15Half: Scalars['Boolean'];
  isTarget15Keep: Scalars['Boolean'];
  isTarget16Half: Scalars['Boolean'];
  isTarget16Keep: Scalars['Boolean'];
  isTarget17Half: Scalars['Boolean'];
  isTarget17Keep: Scalars['Boolean'];
  isTarget18Half: Scalars['Boolean'];
  isTarget18Keep: Scalars['Boolean'];
  isTarget19Half: Scalars['Boolean'];
  isTarget19Keep: Scalars['Boolean'];
  isTarget20Half: Scalars['Boolean'];
  isTarget20Keep: Scalars['Boolean'];
  isTargetBullHalf: Scalars['Boolean'];
  isTargetBullKeep: Scalars['Boolean'];
  isTripleRingHalf: Scalars['Boolean'];
  isTripleRingKeep: Scalars['Boolean'];
};

export type HalfItAnalysisResult = {
  __typename?: 'HalfItAnalysisResult';
  bedRateAverage: Scalars['Float'];
  doubleRingHalfRate: Scalars['Float'];
  doubleRingKeepRate: Scalars['Float'];
  noHalfRate: Scalars['Float'];
  target15HalfRate: Scalars['Float'];
  target15KeepRate: Scalars['Float'];
  target16HalfRate: Scalars['Float'];
  target16KeepRate: Scalars['Float'];
  target17HalfRate: Scalars['Float'];
  target17KeepRate: Scalars['Float'];
  target18HalfRate: Scalars['Float'];
  target18KeepRate: Scalars['Float'];
  target19HalfRate: Scalars['Float'];
  target19KeepRate: Scalars['Float'];
  target20HalfRate: Scalars['Float'];
  target20KeepRate: Scalars['Float'];
  targetBullHalfRate: Scalars['Float'];
  targetBullKeepRate: Scalars['Float'];
  tripleRingHalfRate: Scalars['Float'];
  tripleRingKeepRate: Scalars['Float'];
};

export enum HalfItMode {
  GranMaster = 'GranMaster',
  Master = 'Master',
  Normal = 'Normal'
}

export type HalfItOption = {
  __typename?: 'HalfItOption';
  mode: HalfItMode;
};

export type HalfItOptionInput = {
  mode: HalfItMode;
};

export type HalfItPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  analysisData?: InputMaybe<HalfItAnalysisDataInput>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type HalfItUnitDataInput = {
  playerDatas: Array<HalfItPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type HiddenCricketOptionInput = {
  maxRound: Scalars['Int'];
};

export type HiddenCricketPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  stats: CricketStatsInput;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type HiddenCricketUnitDataInput = {
  playerDatas: Array<HiddenCricketPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type HiddenCutThroatCricketOptionInput = {
  maxRound: Scalars['Int'];
};

export type HiddenCutThroatCricketPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  stats: CricketStatsInput;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type HiddenCutThroatCricketUnitDataInput = {
  playerDatas: Array<HiddenCutThroatCricketPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type HitEffectItem = {
  __typename?: 'HitEffectItem';
  customizeItemType: CustomizeItemType;
  hitEffectType: HitEffectType;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  previewMovieUrl: Scalars['String'];
  unityAddressableKey: Scalars['String'];
};

export type HitEffectSetting = {
  __typename?: 'HitEffectSetting';
  hitEffectItem: HitEffectItem;
  hitEffectType: HitEffectType;
};

export enum HitEffectType {
  DoubleBull = 'DoubleBull',
  SingleBull = 'SingleBull',
  Triple20 = 'Triple20'
}

export type HostListInput = {
  args: PaginationInput;
  guestPlayerId: Scalars['Int'];
};

export type InAppInformation = {
  __typename?: 'InAppInformation';
  apps: Array<App>;
  content?: Maybe<InAppInformationContent>;
  countryCodes?: Maybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  listingDeadline?: Maybe<Scalars['Date']>;
  rawContents: Array<InAppInformationRawContent>;
};

export type InAppInformationConnection = {
  __typename?: 'InAppInformationConnection';
  edges: Array<InAppInformationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type InAppInformationContent = {
  __typename?: 'InAppInformationContent';
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  webLinkUrl?: Maybe<Scalars['String']>;
};

export type InAppInformationEdge = {
  __typename?: 'InAppInformationEdge';
  cursor: Scalars['String'];
  node: InAppInformation;
};

export type InAppInformationFilterOption = {
  app?: InputMaybe<App>;
  countryCode?: InputMaybe<Scalars['String']>;
};

export type InAppInformationRawContent = {
  __typename?: 'InAppInformationRawContent';
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  informationId: Scalars['Int'];
  languageCode: Scalars['String'];
  title: Scalars['String'];
  webLinkUrl?: Maybe<Scalars['String']>;
};

export type InAppInformationRawContentInput = {
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  languageCode: Scalars['String'];
  title: Scalars['String'];
  webLinkUrl?: InputMaybe<Scalars['String']>;
};

export enum InCondition {
  DoubleIn = 'DoubleIn',
  MasterIn = 'MasterIn',
  None = 'None'
}

export enum InGameStatus {
  Idle = 'Idle',
  InGame = 'InGame'
}

export type InterMediateDataForMigration = {
  __typename?: 'InterMediateDataForMigration';
  v2Id?: Maybe<Scalars['Int']>;
};

export type InviteFriendToClubInput = {
  clubId: Scalars['Int'];
  playerId: Scalars['Int'];
};

export type InviteSubordinateDealerInput = {
  address: Scalars['String'];
  countryCode: Scalars['String'];
  email: Scalars['String'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
  ownDealerId: Scalars['Int'];
  phoneNumber: Scalars['String'];
  province: Scalars['String'];
  zipCode: Scalars['String'];
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['Int'];
  name: Scalars['String'];
  type: ItemType;
};

export enum ItemType {
  Award = 'Award',
  Background = 'Background',
  Game = 'Game',
  Image = 'Image',
  Theme = 'Theme'
}

export type ItemsPurchasedHistoryInput = {
  args: PaginationInput;
  userId: Scalars['Int'];
};

export type JoinClubInput = {
  clubId: Scalars['Int'];
  password?: InputMaybe<Scalars['String']>;
  playerId: Scalars['Int'];
};

export enum KickDownGoalScore {
  G301 = 'G301',
  G501 = 'G501'
}

export type KickDownOptionInput = {
  goalScore: KickDownGoalScore;
  inCondition?: InputMaybe<ZeroOneInCondition>;
  maxRound: Scalars['Int'];
  outCondition?: InputMaybe<ZeroOneOutCondition>;
  overScorePenalty?: InputMaybe<KickDownOverScorePenalty>;
  separatedBull?: InputMaybe<Scalars['Boolean']>;
};

export enum KickDownOverScorePenalty {
  Minus100 = 'Minus100',
  Minus150 = 'Minus150',
  Random = 'Random'
}

export type KickDownPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type KickDownUnitDataInput = {
  playerDatas: Array<KickDownPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type Language = {
  __typename?: 'Language';
  created_at?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type LaunchAppInput = {
  app_version?: InputMaybe<Scalars['String']>;
  bl_version?: InputMaybe<Scalars['String']>;
  device_id: Scalars['String'];
  device_model?: InputMaybe<Scalars['String']>;
  device_token?: InputMaybe<Scalars['String']>;
  fw_version?: InputMaybe<Scalars['String']>;
  os_version?: InputMaybe<Scalars['String']>;
  platform: Platform;
  userId: Scalars['Int'];
};

export type LaunchGranPlayerAppInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  deviceModel?: InputMaybe<Scalars['String']>;
  deviceToken?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  platform: Platform;
  userId: Scalars['Int'];
};

export type LeaveChatRoomInput = {
  playerId: Scalars['Int'];
  roomId: Scalars['Int'];
};

export type LiftDealerLoginProhibitionInput = {
  dealerId: Scalars['Int'];
};

export type LiftShopLoginProhibitionInput = {
  shopId: Scalars['Int'];
};

export type LiftShopMachineProhibitionInput = {
  machineId: Scalars['Int'];
};

export enum LobbyStatus {
  InMatch = 'InMatch',
  NotInLobby = 'NotInLobby',
  Standby = 'Standby'
}

export type LoginInput = {
  app_version?: InputMaybe<Scalars['String']>;
  bl_version?: InputMaybe<Scalars['String']>;
  device_id: Scalars['String'];
  device_model?: InputMaybe<Scalars['String']>;
  device_token?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fw_version?: InputMaybe<Scalars['String']>;
  os_version?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  platform: Platform;
};

export type LogoutInput = {
  accessToken: Scalars['String'];
};

export type Machine = {
  __typename?: 'Machine';
  dummyPlayerId?: Maybe<Scalars['Int']>;
  freeModeEnd?: Maybe<Scalars['Date']>;
  freeModeStart?: Maybe<Scalars['Date']>;
  gameSetting: Scalars['JSON'];
  id: Scalars['Int'];
  inGameStatus: InGameStatus;
  isEmittingShopBeacon: Scalars['Boolean'];
  isOnline: Scalars['Boolean'];
  isPasswordRequired: Scalars['Boolean'];
  lastInformationEditedAt?: Maybe<Scalars['Date']>;
  lastLoginAt?: Maybe<Scalars['Date']>;
  machineName: Scalars['String'];
  playerEntries: Array<Maybe<Player>>;
  proKit?: Maybe<ProKit>;
  serviceMode: ProKitServiceModeOption;
  shop: Shop;
  status: MachineStatus;
  teamViewerId?: Maybe<Scalars['String']>;
};

export type MachineConnection = {
  __typename?: 'MachineConnection';
  edges: Array<Maybe<MachineEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MachineEdge = {
  __typename?: 'MachineEdge';
  cursor: Scalars['String'];
  node?: Maybe<Machine>;
};

export type MachineFilterOption = {
  status?: InputMaybe<MachineStatusFilter>;
};

export type MachineFilterOptionByDistributor = {
  dealerId?: InputMaybe<Scalars['Int']>;
  plan?: InputMaybe<GranProSubscriptionPlan>;
  shopId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<MachineStatus>;
};

export type MachineInput = {
  machineId: Scalars['Int'];
};

export enum MachineStatus {
  Deleted = 'Deleted',
  Enabled = 'Enabled',
  OutOfService = 'OutOfService'
}

export enum MachineStatusFilter {
  Enabled = 'Enabled',
  OutOfService = 'OutOfService'
}

export type MachineWithIncome = {
  __typename?: 'MachineWithIncome';
  income: Scalars['Int'];
  machine: Machine;
};

export type MakeBoardSerialCodeInvalidInput = {
  boardId: Scalars['Int'];
};

export type MakeProEntryByMachineIdInput = {
  entryPlayerId: Scalars['Int'];
  machineId: Scalars['Int'];
};

export type MakeProEntryByQrCodeInput = {
  entryPlayerId: Scalars['Int'];
  qrCode: Scalars['String'];
};

export type MakePublicEntryInput = {
  entryPlayerId: Scalars['Int'];
  qrCode: Scalars['String'];
};

export enum MatchGameFilter {
  Cricket = 'Cricket',
  Medley = 'Medley',
  ZeroOne = 'ZeroOne'
}

export type MatchGameOption = {
  __typename?: 'MatchGameOption';
  continueMode?: Maybe<Scalars['Boolean']>;
  handicap: Scalars['Boolean'];
  inCondition?: Maybe<ZeroOneInCondition>;
  maxRound: Scalars['Int'];
  outCondition?: Maybe<ZeroOneOutCondition>;
  separatedBull?: Maybe<Scalars['Boolean']>;
  startScore?: Maybe<ZeroOneStartScore>;
};

export type MatchInfo = {
  __typename?: 'MatchInfo';
  matchId: Scalars['ID'];
  matchServer: Scalars['String'];
  throwOrder: ThrowOrder;
};

export type MatchInput = {
  id: Scalars['ID'];
};

export type MatchPlay = {
  __typename?: 'MatchPlay';
  gameCode?: Maybe<GameCode>;
  id: Scalars['ID'];
  isFinished: Scalars['Boolean'];
  option?: Maybe<MatchGameOption>;
  units: Array<MatchPlayUnit>;
  winnerUnitId?: Maybe<Scalars['Int']>;
};

export type MatchPlaySet = {
  __typename?: 'MatchPlaySet';
  id: Scalars['ID'];
  isFinished: Scalars['Boolean'];
  isOnline: Scalars['Boolean'];
  legs: Array<MatchPlay>;
  startAt: Scalars['Date'];
  winnerUnitId?: Maybe<Scalars['Int']>;
};

export type MatchPlayUnit = {
  __typename?: 'MatchPlayUnit';
  id: Scalars['Int'];
  playersAndResults: Array<MatchUnitPlayerAndResult>;
  score?: Maybe<Scalars['Int']>;
  throwingIndex: Scalars['Int'];
};

export enum MatchRequestFailedReason {
  Cancel = 'Cancel',
  NotStandby = 'NotStandby',
  Refuse = 'Refuse',
  Timeout = 'Timeout'
}

export type MatchRequestInfo = {
  __typename?: 'MatchRequestInfo';
  condition: GameCondition;
  unit: OnlineUnit;
};

export type MatchRequestResult = {
  __typename?: 'MatchRequestResult';
  failedReason?: Maybe<MatchRequestFailedReason>;
  info?: Maybe<MatchInfo>;
  playSet?: Maybe<PlaySet>;
  signalingServer?: Maybe<Scalars['String']>;
  stunServer?: Maybe<Scalars['String']>;
  turnServer?: Maybe<Scalars['String']>;
  unitRatings?: Maybe<Array<UnitRating>>;
  winCount?: Maybe<MatchUpWinCount>;
};

export type MatchRequestedInput = {
  unitId: Scalars['Int'];
};

export type MatchRespondResult = {
  __typename?: 'MatchRespondResult';
  info?: Maybe<MatchInfo>;
  playSet?: Maybe<PlaySet>;
  signalingServer?: Maybe<Scalars['String']>;
  stunServer?: Maybe<Scalars['String']>;
  turnServer?: Maybe<Scalars['String']>;
  unitRatings?: Maybe<Array<UnitRating>>;
  winCount?: Maybe<MatchUpWinCount>;
};

export enum MatchResponse {
  Accept = 'Accept',
  Refuse = 'Refuse'
}

export enum MatchResult {
  Draw = 'Draw',
  FirstWin = 'FirstWin',
  SecondWin = 'SecondWin'
}

export type MatchStats = {
  __typename?: 'MatchStats';
  mpr80?: Maybe<Scalars['Float']>;
  mpr100?: Maybe<Scalars['Float']>;
  ppd100?: Maybe<Scalars['Float']>;
  ppr80?: Maybe<Scalars['Float']>;
};

export type MatchUnitPlayerAndResult = PlayerIdentity & {
  __typename?: 'MatchUnitPlayerAndResult';
  countryCode?: Maybe<Scalars['String']>;
  granId: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  result?: Maybe<MatchUnitPlayerResult>;
};

export type MatchUnitPlayerResult = {
  __typename?: 'MatchUnitPlayerResult';
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: Award;
  stats: MatchStats;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type MatchUpWinCount = {
  __typename?: 'MatchUpWinCount';
  cricket: GameWinCount;
  zeroOne: GameWinCount;
};

export type MatchesGroupByDate = {
  __typename?: 'MatchesGroupByDate';
  date: Scalars['Date'];
  matches: Array<MatchPlaySet>;
};

export type MatchesGroupByDateInput = {
  end?: InputMaybe<Scalars['Date']>;
  game?: InputMaybe<MatchGameFilter>;
  isFinished?: InputMaybe<Scalars['Boolean']>;
  playerId: Scalars['Int'];
  start: Scalars['Date'];
};

export type MatchesInput = {
  ids: Array<Scalars['ID']>;
};

export type MatchesOfDayInput = {
  date: Scalars['Date'];
  game?: InputMaybe<MatchGameFilter>;
  opponentPlayerId?: InputMaybe<Scalars['Int']>;
  playerId: Scalars['Int'];
};

export type MatchesOfDayResult = {
  __typename?: 'MatchesOfDayResult';
  loseMatchesCount: Scalars['Int'];
  matches: Array<MatchPlaySet>;
  previousDate?: Maybe<Scalars['Date']>;
  winMatchesCount: Scalars['Int'];
};

export enum MedleyOrderCondition {
  AlternativeStart = 'AlternativeStart',
  LoserFirst = 'LoserFirst'
}

export type Message = {
  __typename?: 'Message';
  body: Scalars['String'];
  from: Player;
  service_code: ServiceCode;
  to_player_id: Scalars['Int'];
};

export type MessageFilterInput = {
  exclude?: InputMaybe<Array<ServiceCode>>;
};

export type MigrateUserFromV2Input = {
  country_code: Scalars['String'];
  email: Scalars['String'];
  language_code: Scalars['String'];
  password: Scalars['String'];
};

export type MonthlyBillingDataPerProKit = {
  __typename?: 'MonthlyBillingDataPerProKit';
  billingAmount: Scalars['Int'];
  billingType: BillingType;
  histories: Array<ProKitStartupHistory>;
  planAndDayCount: Array<PlanAndDayCount>;
  proKitId: Scalars['Int'];
};

export type MonthlyProSubscriptionPlanBillingAmountOfDealer = {
  __typename?: 'MonthlyProSubscriptionPlanBillingAmountOfDealer';
  billingAmount: Scalars['Int'];
  dealerId: Scalars['Int'];
  month: Scalars['Int'];
  perProKitData: Array<MonthlyBillingDataPerProKit>;
  year: Scalars['Int'];
};

export type MultipleCricketOptionInput = {
  onlyTriple?: InputMaybe<Scalars['Boolean']>;
};

export type MultipleCricketPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type MultipleCricketUnitDataInput = {
  playerDatas: Array<MultipleCricketPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activate_dealer: SuccessResponse;
  activate_kit: SuccessResponse;
  activate_pro_kit: SuccessResponse;
  activate_shop: SuccessResponse;
  add_chat_member: ChatRoom;
  add_new_app_version: AppVersion;
  add_new_gran_player_app_version: GranPlayerAppVersion;
  add_new_pro_app_version: ProAppVersion;
  add_player: Player;
  add_public_dummy: Player;
  apple_purchase_gran_coin: PurchaseCoinsResult;
  apple_purchase_gran_coin_for_gran_player: PurchaseCoinsResult;
  approve_pair: Pair;
  auth_board?: Maybe<AuthBoardResult>;
  ban_user: SuccessResponse;
  block_follow: Player;
  break_up_remote: SuccessResponse;
  breakup_pair: Pair;
  cancel_hourly_rental_of_machine: Machine;
  cancel_pro_entry: SuccessResponse;
  close_chat_room: SuccessResponse;
  confirm_password: Confirmation;
  create_chat_room: ChatRoom;
  create_club: Club;
  create_machine_for_pro_kit: ProKit;
  create_shop_income_collection_event: ProShopIncomeCollectionEvent;
  delete_app_version: SuccessResponse;
  delete_chat_message: SuccessResponse;
  delete_dealer: SuccessResponse;
  delete_gran_player_app_version: SuccessResponse;
  delete_machine: SuccessResponse;
  delete_my_club: SuccessResponse;
  delete_own_club: SuccessResponse;
  delete_pro_app_version: SuccessResponse;
  delete_shop: SuccessResponse;
  delete_user: Confirmation;
  downgrade_pro_kit_subscription_plan: ProKit;
  edit_app_version: AppVersion;
  edit_chat_message: ChatMessage;
  edit_club: Club;
  edit_dealer_email: SuccessResponse;
  edit_dealer_information: Dealer;
  edit_gran_player_app_version: GranPlayerAppVersion;
  edit_in_app_information: InAppInformation;
  edit_in_app_information_raw_content: InAppInformation;
  edit_income_conversion_rate: SuccessResponse;
  edit_machine_game_setting: SuccessResponse;
  edit_machine_information: Machine;
  edit_pro_app_version: ProAppVersion;
  edit_shop_email: SuccessResponse;
  edit_shop_information: Shop;
  enter_online_lobby: OnlineUnitConnection;
  enter_online_top: OnlineUnit;
  exit_match: SuccessResponse;
  exit_match_and_lobby: SuccessResponse;
  exit_online_lobby: SuccessResponse;
  exit_online_top: SuccessResponse;
  follow: Player;
  force_set_dealer_password_by_distributor: SuccessResponse;
  force_set_gran_coin_balance: User;
  force_set_password: SuccessResponse;
  force_set_shop_password_by_distributor: SuccessResponse;
  google_purchase_gran_coin: PurchaseCoinsResult;
  gran_player_login: UserWithSession;
  invite_friend_to_club: SuccessResponse;
  invite_subordinate_dealer: SuccessResponse;
  join_club: SuccessResponse;
  launch_app: User;
  launch_gran_player_app: User;
  leave_chat_room: SuccessResponse;
  lift_dealer_login_prohibition: SuccessResponse;
  lift_shop_login_prohibition: SuccessResponse;
  lift_shop_machine_prohibition: SuccessResponse;
  login: UserWithSession;
  make_board_serial_code_invalid: SuccessResponse;
  make_pro_entry_by_machine_id: SuccessResponse;
  make_pro_entry_by_qr_code: SuccessResponse;
  migrate_user_from_v2: User;
  post_chat_message: ChatMessage;
  post_in_app_information: InAppInformation;
  pro_shop_login: ProKitWithSession;
  prohibit_dealer_login: SuccessResponse;
  prohibit_shop_login: SuccessResponse;
  prohibit_shop_machine: SuccessResponse;
  purchase_items: SuccessResponse;
  purchase_store_customize_item: StoreCustomizeItem;
  register_by_email: User;
  register_dealer: Dealer;
  register_guest: Guest;
  register_player_visit_to_store: SuccessResponse;
  register_shop: Shop;
  remove_pro_kit_from_machine: Machine;
  remove_subordinate_dealer_from_partner: SuccessResponse;
  rent_machine_by_hour: Machine;
  request_club_join: SuccessResponse;
  request_match: MatchRequestResult;
  request_pair: Pair;
  request_qr_code: Scalars['String'];
  request_remote_doubles: RemoteRequestResult;
  reset_board_serial_code_activate_count?: Maybe<SuccessResponse>;
  reset_board_serial_code_activate_count_by_string: SuccessResponse;
  reset_machine_income: SuccessResponse;
  respond_to_club_invitation: RespondToClubInvitation;
  respond_to_club_join_request: RespondToClubJoinRequest;
  respond_to_doubles_request: RemoteRespondResult;
  respond_to_match_request: MatchRespondResult;
  save_count_up_best_score_per_option: SuccessResponse;
  select_machine_for_pro_kit: ProKit;
  send_animal_win_count: AnimalWinCount;
  send_app_log: SuccessResponse;
  send_beyond_top_play_data: SuccessResponse;
  send_count_up_play_data: SuccessResponse;
  send_cricket_count_up_play_data: SuccessResponse;
  send_cricket_play_data: SuccessResponse;
  send_cut_throat_cricket_play_data: SuccessResponse;
  send_delta_shoot_play_data: SuccessResponse;
  send_direct_message: SuccessResponse;
  send_freeze_zero_one_play_data: SuccessResponse;
  send_half_it_play_data: SuccessResponse;
  send_hidden_cricket_play_data: SuccessResponse;
  send_hidden_cut_throat_cricket_play_data: SuccessResponse;
  send_kick_down_play_data: SuccessResponse;
  send_launch_team_viewer_notification: SuccessResponse;
  send_machine_income: SuccessResponse;
  send_machine_reboot_notification: SuccessResponse;
  send_machine_reset_notification: SuccessResponse;
  send_multiple_cricket_play_data: SuccessResponse;
  send_oniren_play_data: SuccessResponse;
  send_online_match_report: SuccessResponse;
  send_pirates_play_data: SuccessResponse;
  send_remote_support_request: SuccessResponse;
  send_rotation_play_data: SuccessResponse;
  send_shoot_force_play_data: SuccessResponse;
  send_spider_play_data: SuccessResponse;
  send_target_bull_play_data: SuccessResponse;
  send_target_hat_play_data: SuccessResponse;
  send_target_horse_play_data: SuccessResponse;
  send_target_twenty_play_data: SuccessResponse;
  send_team_cricket_play_data: SuccessResponse;
  send_thumbs_up_reaction: SuccessResponse;
  send_wild_card_cricket_play_data: SuccessResponse;
  send_zero_one_play_data: SuccessResponse;
  set_award_movie_item?: Maybe<AwardMovieItem>;
  set_game_background_item?: Maybe<GameBackgroundItem>;
  set_game_hit_sound_item?: Maybe<GameHitSoundItem>;
  set_hit_effect_item?: Maybe<HitEffectItem>;
  set_local_players: OnlineUnit;
  set_player_change_sound_item?: Maybe<PlayerChangeSoundItem>;
  set_pro_kit_subscription_plan_after_demo: ProKit;
  set_sound_item?: Maybe<SoundItem>;
  start_local_play: PlaySet;
  support_user_qr: SupportUserQrResponse;
  swap_remote_throw_order: OnlineUnit;
  switch_card: Player;
  switch_club_member_ban: ClubMember;
  switch_partner_dealer_status: SuccessResponse;
  switch_pre_match: SuccessResponse;
  switch_setting_menu: SettingMenuOption;
  sync_unit_list_page: SuccessResponse;
  unblock_follow: Player;
  unfollow: Player;
  unregistered_guest: SuccessResponse;
  update_machine_game_status: SuccessResponse;
  update_online_unit_member_setting: OnlineUnitMemberSetting;
  update_online_unit_setting: OnlineUnit;
  update_pair: Pair;
  update_player: Player;
  update_player_profile: PlayerProfile;
  update_pro_shop_password_of_machine: SuccessResponse;
  update_store_award_movie_item: StoreAwardMovieItem;
  update_store_game_background_item: StoreGameBackgroundItem;
  update_store_game_hit_sound_item: StoreGameHitSoundItem;
  update_store_hit_effect_item: StoreHitEffectItem;
  update_store_player_change_sound_item: StorePlayerChangeSoundItem;
  update_store_sound_item: StoreSoundItem;
  update_user: User;
  upgrade_pro_kit_subscription_plan: ProKit;
  upload_game_background_item: UploadedByPlayerGameBackgroundItem;
  upload_store_award_movie_item: StoreAwardMovieItem;
  upload_store_game_background_item: StoreGameBackgroundItem;
  upload_store_game_hit_sound_item: StoreGameHitSoundItem;
  upload_store_hit_effect_item: StoreHitEffectItem;
  upload_store_player_change_sound_item: StorePlayerChangeSoundItem;
  upload_store_sound_item: StoreSoundItem;
  verify_change_email: UserWithSession;
  verify_dealer_email: DealerWithSession;
  verify_dealer_password_reset: SuccessResponse;
  verify_shop_email: ShopWithSession;
  verify_shop_password_reset: SuccessResponse;
  view_chat_messages: ChatMessageConnection;
  web_dealer_login: DealerWithSession;
  web_distributor_login: DistributorWithSession;
  web_shop_login: ShopWithSession;
};


export type MutationActivate_DealerArgs = {
  input: ActivateDealerInput;
};


export type MutationActivate_KitArgs = {
  input: ActivateKitInput;
};


export type MutationActivate_Pro_KitArgs = {
  input: ActivateProKitInput;
};


export type MutationActivate_ShopArgs = {
  input: ActivateShopInput;
};


export type MutationAdd_Chat_MemberArgs = {
  input: AddChatMemberInput;
};


export type MutationAdd_New_App_VersionArgs = {
  input: AddNewAppVersionInput;
};


export type MutationAdd_New_Gran_Player_App_VersionArgs = {
  input: AddNewGranPlayerAppVersionInput;
};


export type MutationAdd_New_Pro_App_VersionArgs = {
  input: AddNewProAppVersionInput;
};


export type MutationAdd_PlayerArgs = {
  input: AddPlayerInput;
};


export type MutationAdd_Public_DummyArgs = {
  input: AddPublicDummyInput;
};


export type MutationApple_Purchase_Gran_CoinArgs = {
  data: ApplePurchaseInput;
};


export type MutationApple_Purchase_Gran_Coin_For_Gran_PlayerArgs = {
  data: ApplePurchaseInput;
};


export type MutationApprove_PairArgs = {
  id: Scalars['Int'];
};


export type MutationAuth_BoardArgs = {
  input: AuthBoardInput;
};


export type MutationBan_UserArgs = {
  input: BanUserInput;
};


export type MutationBlock_FollowArgs = {
  input: PlayerRelationInput;
};


export type MutationBreak_Up_RemoteArgs = {
  input: BreakUpRemoteInput;
};


export type MutationBreakup_PairArgs = {
  id: Scalars['Int'];
};


export type MutationCancel_Hourly_Rental_Of_MachineArgs = {
  input: CancelHourlyRentalOfMachineInput;
};


export type MutationCancel_Pro_EntryArgs = {
  input: CancelProEntryInput;
};


export type MutationClose_Chat_RoomArgs = {
  input: CloseChatRoomInput;
};


export type MutationConfirm_PasswordArgs = {
  data?: InputMaybe<VerificationPasswordInput>;
};


export type MutationCreate_Chat_RoomArgs = {
  input: CreateChatRoomInput;
};


export type MutationCreate_ClubArgs = {
  input: CreateClubInput;
};


export type MutationCreate_Machine_For_Pro_KitArgs = {
  input: CreateMachineForProKitInput;
};


export type MutationCreate_Shop_Income_Collection_EventArgs = {
  input: CreateShopIncomeCollectionEventInput;
};


export type MutationDelete_App_VersionArgs = {
  input: DeleteAppVersionInput;
};


export type MutationDelete_Chat_MessageArgs = {
  input: DeleteChatMessageInput;
};


export type MutationDelete_DealerArgs = {
  input: DeleteDealerInput;
};


export type MutationDelete_Gran_Player_App_VersionArgs = {
  input: DeleteGranPlayerAppVersionInput;
};


export type MutationDelete_MachineArgs = {
  input: DeleteMachineInput;
};


export type MutationDelete_My_ClubArgs = {
  input: DeleteMyClubInput;
};


export type MutationDelete_Own_ClubArgs = {
  input: DeleteOwnClubInput;
};


export type MutationDelete_Pro_App_VersionArgs = {
  input: DeleteProAppVersionInput;
};


export type MutationDelete_ShopArgs = {
  input: DeleteShopInput;
};


export type MutationDelete_UserArgs = {
  input: DeleteUserInput;
};


export type MutationDowngrade_Pro_Kit_Subscription_PlanArgs = {
  input: DowngradeProKitSubscriptionPlanInput;
};


export type MutationEdit_App_VersionArgs = {
  input: EditAppVersionInput;
};


export type MutationEdit_Chat_MessageArgs = {
  input: EditChatMessageInput;
};


export type MutationEdit_ClubArgs = {
  input: EditClubInput;
};


export type MutationEdit_Dealer_EmailArgs = {
  input: EditDealerEmailInput;
};


export type MutationEdit_Dealer_InformationArgs = {
  input: EditDealerInformationInput;
};


export type MutationEdit_Gran_Player_App_VersionArgs = {
  input: EditGranPlayerAppVersionInput;
};


export type MutationEdit_In_App_InformationArgs = {
  input: EditInAppInformationInput;
};


export type MutationEdit_In_App_Information_Raw_ContentArgs = {
  input: EditInAppInformationRawContentInput;
};


export type MutationEdit_Income_Conversion_RateArgs = {
  input: EditIncomeConversionRateInput;
};


export type MutationEdit_Machine_Game_SettingArgs = {
  input: EditMachineGameSettingInput;
};


export type MutationEdit_Machine_InformationArgs = {
  input: EditMachineInformationInput;
};


export type MutationEdit_Pro_App_VersionArgs = {
  input: EditProAppVersionInput;
};


export type MutationEdit_Shop_EmailArgs = {
  input: EditShopEmailInput;
};


export type MutationEdit_Shop_InformationArgs = {
  input: EditShopInformationInput;
};


export type MutationEnter_Online_LobbyArgs = {
  input: EnterOnlineLobbyInput;
};


export type MutationEnter_Online_TopArgs = {
  input: EnterOnlineTopInput;
};


export type MutationExit_MatchArgs = {
  input: ExitMatchInput;
};


export type MutationExit_Match_And_LobbyArgs = {
  input: ExitMatchAndLobbyInput;
};


export type MutationExit_Online_LobbyArgs = {
  input: ExitOnlineLobbyInput;
};


export type MutationExit_Online_TopArgs = {
  input: ExitOnlineTopInput;
};


export type MutationFollowArgs = {
  input: PlayerRelationInput;
};


export type MutationForce_Set_Dealer_Password_By_DistributorArgs = {
  input: ForceSetDealerPasswordByDistributorInput;
};


export type MutationForce_Set_Gran_Coin_BalanceArgs = {
  input: ForceSetGranCoinBalanceInput;
};


export type MutationForce_Set_PasswordArgs = {
  input: ForceSetPasswordInput;
};


export type MutationForce_Set_Shop_Password_By_DistributorArgs = {
  input: ForceSetShopPasswordByDistributorInput;
};


export type MutationGoogle_Purchase_Gran_CoinArgs = {
  data: GooglePurchaseInput;
};


export type MutationGran_Player_LoginArgs = {
  input: GranPlayerLoginInput;
};


export type MutationInvite_Friend_To_ClubArgs = {
  input: InviteFriendToClubInput;
};


export type MutationInvite_Subordinate_DealerArgs = {
  input: InviteSubordinateDealerInput;
};


export type MutationJoin_ClubArgs = {
  input: JoinClubInput;
};


export type MutationLaunch_AppArgs = {
  input: LaunchAppInput;
};


export type MutationLaunch_Gran_Player_AppArgs = {
  input: LaunchGranPlayerAppInput;
};


export type MutationLeave_Chat_RoomArgs = {
  input: LeaveChatRoomInput;
};


export type MutationLift_Dealer_Login_ProhibitionArgs = {
  input: LiftDealerLoginProhibitionInput;
};


export type MutationLift_Shop_Login_ProhibitionArgs = {
  input: LiftShopLoginProhibitionInput;
};


export type MutationLift_Shop_Machine_ProhibitionArgs = {
  input: LiftShopMachineProhibitionInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationMake_Board_Serial_Code_InvalidArgs = {
  input: MakeBoardSerialCodeInvalidInput;
};


export type MutationMake_Pro_Entry_By_Machine_IdArgs = {
  input: MakeProEntryByMachineIdInput;
};


export type MutationMake_Pro_Entry_By_Qr_CodeArgs = {
  input: MakeProEntryByQrCodeInput;
};


export type MutationMigrate_User_From_V2Args = {
  input: MigrateUserFromV2Input;
};


export type MutationPost_Chat_MessageArgs = {
  input: PostChatMessageInput;
};


export type MutationPost_In_App_InformationArgs = {
  input: PostInAppInformationInput;
};


export type MutationPro_Shop_LoginArgs = {
  input: ProShopLoginInput;
};


export type MutationProhibit_Dealer_LoginArgs = {
  input: ProhibitDealerLoginInput;
};


export type MutationProhibit_Shop_LoginArgs = {
  input: ProhibitShopLoginInput;
};


export type MutationProhibit_Shop_MachineArgs = {
  input: ProhibitShopMachineInput;
};


export type MutationPurchase_ItemsArgs = {
  input: PurchaseItemsInput;
};


export type MutationPurchase_Store_Customize_ItemArgs = {
  input: PurchaseStoreCustomizeItemInput;
};


export type MutationRegister_By_EmailArgs = {
  data: RegisterInput;
};


export type MutationRegister_DealerArgs = {
  input: RegisterDealerInput;
};


export type MutationRegister_GuestArgs = {
  input: RegisterGuestInput;
};


export type MutationRegister_Player_Visit_To_StoreArgs = {
  input: RegisterPlayerVisitToStoreInput;
};


export type MutationRegister_ShopArgs = {
  input: RegisterShopInput;
};


export type MutationRemove_Pro_Kit_From_MachineArgs = {
  input: RemoveProKitFromMachineInput;
};


export type MutationRemove_Subordinate_Dealer_From_PartnerArgs = {
  input: RemoveSubordinateDealerFromPartnerInput;
};


export type MutationRent_Machine_By_HourArgs = {
  input: RentMachineByHourInput;
};


export type MutationRequest_Club_JoinArgs = {
  input: RequestClubJoinInput;
};


export type MutationRequest_MatchArgs = {
  input: RequestMatchInput;
};


export type MutationRequest_PairArgs = {
  input: RequestPairInput;
};


export type MutationRequest_Qr_CodeArgs = {
  input: PlayerIdInput;
};


export type MutationRequest_Remote_DoublesArgs = {
  input: RequestRemoteDoublesInput;
};


export type MutationReset_Board_Serial_Code_Activate_CountArgs = {
  input: ResetBoardSerialCodeActivateCountInput;
};


export type MutationReset_Board_Serial_Code_Activate_Count_By_StringArgs = {
  input: ResetBoardSerialCodeActivateCountByStringInput;
};


export type MutationReset_Machine_IncomeArgs = {
  input: ResetMachineIncomeInput;
};


export type MutationRespond_To_Club_InvitationArgs = {
  input: RespondToClubInvitationInput;
};


export type MutationRespond_To_Club_Join_RequestArgs = {
  input: RespondToClubJoinRequestInput;
};


export type MutationRespond_To_Doubles_RequestArgs = {
  input: RespondToDoublesRequestInput;
};


export type MutationRespond_To_Match_RequestArgs = {
  input: RespondToMatchRequestInput;
};


export type MutationSave_Count_Up_Best_Score_Per_OptionArgs = {
  input: SaveCountUpBestScorePerOptionInput;
};


export type MutationSelect_Machine_For_Pro_KitArgs = {
  input: SelectMachineForProKitInput;
};


export type MutationSend_Animal_Win_CountArgs = {
  input: SendAnimalWinCountInput;
};


export type MutationSend_App_LogArgs = {
  input: SendAppLogInput;
};


export type MutationSend_Beyond_Top_Play_DataArgs = {
  input: SendBeyondTopPlayDataInput;
};


export type MutationSend_Count_Up_Play_DataArgs = {
  input: SendCountUpPlayDataInput;
};


export type MutationSend_Cricket_Count_Up_Play_DataArgs = {
  input: SendCricketCountUpPlayDataInput;
};


export type MutationSend_Cricket_Play_DataArgs = {
  input: SendCricketPlayDataInput;
};


export type MutationSend_Cut_Throat_Cricket_Play_DataArgs = {
  input: SendCutThroatCricketPlayDataInput;
};


export type MutationSend_Delta_Shoot_Play_DataArgs = {
  input: SendDeltaShootPlayDataInput;
};


export type MutationSend_Direct_MessageArgs = {
  input: SendDirectMessageInput;
};


export type MutationSend_Freeze_Zero_One_Play_DataArgs = {
  input: SendFreezeZeroOnePlayDataInput;
};


export type MutationSend_Half_It_Play_DataArgs = {
  input: SendHalfItPlayDataInput;
};


export type MutationSend_Hidden_Cricket_Play_DataArgs = {
  input: SendHiddenCricketPlayDataInput;
};


export type MutationSend_Hidden_Cut_Throat_Cricket_Play_DataArgs = {
  input: SendHiddenCutThroatCricketPlayDataInput;
};


export type MutationSend_Kick_Down_Play_DataArgs = {
  input: SendKickDownPlayDataInput;
};


export type MutationSend_Launch_Team_Viewer_NotificationArgs = {
  input: SendLaunchTeamViewerNotificationInput;
};


export type MutationSend_Machine_IncomeArgs = {
  input: SendMachineIncomeInput;
};


export type MutationSend_Machine_Reboot_NotificationArgs = {
  input: SendMachineRebootNotificationInput;
};


export type MutationSend_Machine_Reset_NotificationArgs = {
  input: SendMachineResetNotificationInput;
};


export type MutationSend_Multiple_Cricket_Play_DataArgs = {
  input: SendMultipleCricketPlayDataInput;
};


export type MutationSend_Oniren_Play_DataArgs = {
  input: SendOnirenPlayDataInput;
};


export type MutationSend_Online_Match_ReportArgs = {
  input: SendOnlineMatchReportInput;
};


export type MutationSend_Pirates_Play_DataArgs = {
  input: SendPiratesPlayDataInput;
};


export type MutationSend_Remote_Support_RequestArgs = {
  input: SendRemoteSupportRequestInput;
};


export type MutationSend_Rotation_Play_DataArgs = {
  input: SendRotationPlayDataInput;
};


export type MutationSend_Shoot_Force_Play_DataArgs = {
  input: SendShootForcePlayDataInput;
};


export type MutationSend_Spider_Play_DataArgs = {
  input: SendSpiderPlayDataInput;
};


export type MutationSend_Target_Bull_Play_DataArgs = {
  input: SendTargetBullPlayDataInput;
};


export type MutationSend_Target_Hat_Play_DataArgs = {
  input: SendTargetHatPlayDataInput;
};


export type MutationSend_Target_Horse_Play_DataArgs = {
  input: SendTargetHorsePlayDataInput;
};


export type MutationSend_Target_Twenty_Play_DataArgs = {
  input: SendTargetTwentyPlayDataInput;
};


export type MutationSend_Team_Cricket_Play_DataArgs = {
  input: SendTeamCricketPlayDataInput;
};


export type MutationSend_Thumbs_Up_ReactionArgs = {
  input: SendThumbsUpReactionInput;
};


export type MutationSend_Wild_Card_Cricket_Play_DataArgs = {
  input: SendWildCardCricketPlayDataInput;
};


export type MutationSend_Zero_One_Play_DataArgs = {
  input: SendZeroOnePlayDataInput;
};


export type MutationSet_Award_Movie_ItemArgs = {
  input: SetAwardMovieItemInput;
};


export type MutationSet_Game_Background_ItemArgs = {
  input: SetGameBackgroundItemInput;
};


export type MutationSet_Game_Hit_Sound_ItemArgs = {
  input: SetGameHitSoundItemInput;
};


export type MutationSet_Hit_Effect_ItemArgs = {
  input: SetHitEffectItemInput;
};


export type MutationSet_Local_PlayersArgs = {
  input: SetLocalPlayerInput;
};


export type MutationSet_Player_Change_Sound_ItemArgs = {
  input: SetPlayerChangeSoundItemInput;
};


export type MutationSet_Pro_Kit_Subscription_Plan_After_DemoArgs = {
  input: SetProKitSubscriptionPlanAfterDemoInput;
};


export type MutationSet_Sound_ItemArgs = {
  input: SetSoundItemInput;
};


export type MutationStart_Local_PlayArgs = {
  input: StartLocalPlayInput;
};


export type MutationSupport_User_QrArgs = {
  input: SupportUserQrInput;
};


export type MutationSwap_Remote_Throw_OrderArgs = {
  input: SwapRemoteThrowOrderInput;
};


export type MutationSwitch_CardArgs = {
  input: SwitchCardInput;
};


export type MutationSwitch_Club_Member_BanArgs = {
  input: SwitchClubMemberBanInput;
};


export type MutationSwitch_Partner_Dealer_StatusArgs = {
  input: SwitchPartnerDealerStatusInput;
};


export type MutationSwitch_Pre_MatchArgs = {
  input: SwitchPreMatchInput;
};


export type MutationSwitch_Setting_MenuArgs = {
  input: SwitchSettingMenuInput;
};


export type MutationSync_Unit_List_PageArgs = {
  input: SyncUnitListPageInput;
};


export type MutationUnblock_FollowArgs = {
  input: PlayerRelationInput;
};


export type MutationUnfollowArgs = {
  input: PlayerRelationInput;
};


export type MutationUnregistered_GuestArgs = {
  input: UnregisteredGuestInput;
};


export type MutationUpdate_Machine_Game_StatusArgs = {
  input: UpdateMachineGameStatusInput;
};


export type MutationUpdate_Online_Unit_Member_SettingArgs = {
  input: UpdateOnlineUnitMemberSettingInput;
};


export type MutationUpdate_Online_Unit_SettingArgs = {
  input: UpdateOnlineUnitSettingInput;
};


export type MutationUpdate_PairArgs = {
  input: UpdatePairInput;
};


export type MutationUpdate_PlayerArgs = {
  input: UpdatePlayerInput;
};


export type MutationUpdate_Player_ProfileArgs = {
  input: UpdatePlayerProfileInput;
};


export type MutationUpdate_Pro_Shop_Password_Of_MachineArgs = {
  input: UpdateProShopPasswordOfMachineInput;
};


export type MutationUpdate_Store_Award_Movie_ItemArgs = {
  input: UpdateStoreAwardMovieItemInput;
};


export type MutationUpdate_Store_Game_Background_ItemArgs = {
  input: UpdateStoreGameBackgroundItemInput;
};


export type MutationUpdate_Store_Game_Hit_Sound_ItemArgs = {
  input: UpdateStoreGameHitSoundItemInput;
};


export type MutationUpdate_Store_Hit_Effect_ItemArgs = {
  input: UpdateStoreHitEffectItemInput;
};


export type MutationUpdate_Store_Player_Change_Sound_ItemArgs = {
  input: UpdateStorePlayerChangeSoundItemInput;
};


export type MutationUpdate_Store_Sound_ItemArgs = {
  input: UpdateStoreSoundItemInput;
};


export type MutationUpdate_UserArgs = {
  data: UpdateUserInput;
};


export type MutationUpgrade_Pro_Kit_Subscription_PlanArgs = {
  input: UpgradeProKitSubscriptionPlanInput;
};


export type MutationUpload_Game_Background_ItemArgs = {
  input: UploadGameBackgroundItemInput;
};


export type MutationUpload_Store_Award_Movie_ItemArgs = {
  input: UploadStoreAwardMovieItemInput;
};


export type MutationUpload_Store_Game_Background_ItemArgs = {
  input: UploadStoreGameBackgroundItemInput;
};


export type MutationUpload_Store_Game_Hit_Sound_ItemArgs = {
  input: UploadStoreGameHitSoundItemInput;
};


export type MutationUpload_Store_Hit_Effect_ItemArgs = {
  input: UploadStoreHitEffectItemInput;
};


export type MutationUpload_Store_Player_Change_Sound_ItemArgs = {
  input: UploadStorePlayerChangeSoundItemInput;
};


export type MutationUpload_Store_Sound_ItemArgs = {
  input: UploadStoreSoundItemInput;
};


export type MutationVerify_Change_EmailArgs = {
  input: VerifyChangeEmailInput;
};


export type MutationVerify_Dealer_EmailArgs = {
  input: VerifyDealerEmailInput;
};


export type MutationVerify_Dealer_Password_ResetArgs = {
  input: VerifyDealerPasswordResetInput;
};


export type MutationVerify_Shop_EmailArgs = {
  input: VerifyShopEmailInput;
};


export type MutationVerify_Shop_Password_ResetArgs = {
  input: VerifyShopPasswordResetInput;
};


export type MutationView_Chat_MessagesArgs = {
  input: ViewChatMessagesInput;
};


export type MutationWeb_Dealer_LoginArgs = {
  input: WebDealerLoginInput;
};


export type MutationWeb_Distributor_LoginArgs = {
  input: WebDistributorLoginInput;
};


export type MutationWeb_Shop_LoginArgs = {
  input: WebShopLoginInput;
};

export type MyClubsInput = {
  playerId: Scalars['Int'];
};

export type OnirenOptionInput = {
  oniDouble: Scalars['Boolean'];
  oniSingle: Scalars['Boolean'];
  oniTriple: Scalars['Boolean'];
};

export type OnirenPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type OnirenUnitDataInput = {
  playerDatas: Array<OnirenPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type OnlineLobbyInfo = {
  __typename?: 'OnlineLobbyInfo';
  inLobbyUnits: Scalars['Int'];
  localDoublesUnits: Scalars['Int'];
  noHeartbeatUnits: OnlineUnitCountPerStatus;
  noHeartbeatUnitsDetail: OnlineUnitInfoPerStatus;
  remoteDoublesUnits: Scalars['Int'];
  singlesUnits: Scalars['Int'];
  standbyUnits: Scalars['Int'];
  totalUnits: Scalars['Int'];
};

export type OnlineMatchCountRanking = PlayerIdentity & {
  __typename?: 'OnlineMatchCountRanking';
  countryCode?: Maybe<Scalars['String']>;
  granId: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  rank80: Scalars['String'];
  rank100: Scalars['String'];
  score: Scalars['Int'];
};

export type OnlineMatchCountRankingConnection = {
  __typename?: 'OnlineMatchCountRankingConnection';
  edges?: Maybe<Array<Maybe<OnlineMatchCountRankingEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OnlineMatchCountRankingEdge = {
  __typename?: 'OnlineMatchCountRankingEdge';
  cursor: Scalars['String'];
  node?: Maybe<OnlineMatchCountRanking>;
};

export type OnlineMatchCountRankingFilterInput = {
  countryCode?: InputMaybe<Scalars['String']>;
};

export type OnlineMatchCountRankingInput = {
  filter?: InputMaybe<OnlineMatchCountRankingFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type OnlineMatchCountRankingPlayerPositionAndScoreInput = {
  filter?: InputMaybe<OnlineMatchCountRankingFilterInput>;
  playerId: Scalars['Int'];
};

export enum OnlineMatchGameCode {
  Choice = 'Choice',
  Cricket = 'Cricket',
  ZeroOne301 = 'ZeroOne301',
  ZeroOne501 = 'ZeroOne501',
  ZeroOne701 = 'ZeroOne701',
  ZeroOne901 = 'ZeroOne901'
}

export type OnlineMatchLatencyInfo = {
  host: Scalars['String'];
  latency?: InputMaybe<Scalars['Int']>;
};

export type OnlineMatchLeg = {
  __typename?: 'OnlineMatchLeg';
  code: OnlineMatchGameCode;
  result?: Maybe<Array<OnlineUnitLegResult>>;
};

export type OnlineMatchRoom = {
  __typename?: 'OnlineMatchRoom';
  id: Scalars['Int'];
  inCondition: InCondition;
  legs: Array<OnlineMatchLeg>;
  matchId: Scalars['ID'];
  matchServer: Scalars['String'];
  matchWebRTCRoom: OnlineWebRtcRoom;
  medleyOrder?: Maybe<MedleyOrderCondition>;
  outCondition: OutCondition;
  playSet: PlaySet;
  separateBull: Scalars['Boolean'];
  unitRatings?: Maybe<Array<UnitRating>>;
  units: Array<OnlineUnit>;
  withHandicap: Scalars['Boolean'];
};

export type OnlineMatchServerInfo = {
  __typename?: 'OnlineMatchServerInfo';
  host: Scalars['String'];
};

export type OnlineMatchWinCountRanking = PlayerIdentity & {
  __typename?: 'OnlineMatchWinCountRanking';
  countryCode?: Maybe<Scalars['String']>;
  granId: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  rank80: Scalars['String'];
  rank100: Scalars['String'];
  score: Scalars['Int'];
};

export type OnlineMatchWinCountRankingConnection = {
  __typename?: 'OnlineMatchWinCountRankingConnection';
  edges?: Maybe<Array<Maybe<OnlineMatchWinCountRankingEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OnlineMatchWinCountRankingEdge = {
  __typename?: 'OnlineMatchWinCountRankingEdge';
  cursor: Scalars['String'];
  node?: Maybe<OnlineMatchWinCountRanking>;
};

export type OnlineMatchWinCountRankingFilterInput = {
  countryCode?: InputMaybe<Scalars['String']>;
};

export type OnlineMatchWinCountRankingInput = {
  filter?: InputMaybe<OnlineMatchWinCountRankingFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type OnlineMatchWinCountRankingPlayerPositionAndScoreInput = {
  filter?: InputMaybe<OnlineMatchWinCountRankingFilterInput>;
  playerId: Scalars['Int'];
};

export type OnlineUnit = {
  __typename?: 'OnlineUnit';
  id: Scalars['Int'];
  isClubOnly: Scalars['Boolean'];
  lobbyId?: Maybe<Scalars['Int']>;
  lobbyStatus: LobbyStatus;
  matchRoom?: Maybe<OnlineMatchRoom>;
  members: Array<OnlineUnitMemberWithSetting>;
  rank01?: Maybe<Scalars['String']>;
  rankCr?: Maybe<Scalars['String']>;
  rankCricket80?: Maybe<Scalars['String']>;
  rankCricket100?: Maybe<Scalars['String']>;
  rankZeroOne80?: Maybe<Scalars['String']>;
  rankZeroOne100?: Maybe<Scalars['String']>;
  remoteWebRTCRoom?: Maybe<OnlineWebRtcRoom>;
  type: UnitType;
  unitSetting: OnlineUnitSetting;
};

export type OnlineUnitConnection = {
  __typename?: 'OnlineUnitConnection';
  edges?: Maybe<Array<Maybe<OnlineUnitEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OnlineUnitCountPerStatus = {
  __typename?: 'OnlineUnitCountPerStatus';
  inMatch: Scalars['Int'];
  matchRequesting: Scalars['Int'];
  notInLobby: Scalars['Int'];
  standby: Scalars['Int'];
};

export type OnlineUnitEdge = {
  __typename?: 'OnlineUnitEdge';
  cursor: Scalars['String'];
  node?: Maybe<OnlineUnit>;
};

export type OnlineUnitFilterOption = {
  boardSize?: InputMaybe<BoardSize>;
  country?: InputMaybe<Scalars['String']>;
  friendsOf?: InputMaybe<Scalars['Int']>;
};

export type OnlineUnitInfo = {
  __typename?: 'OnlineUnitInfo';
  id: Scalars['Int'];
  lobbyId?: Maybe<Scalars['Int']>;
  lobbyStatus: LobbyStatus;
  memberPlayerIds: Array<Scalars['Int']>;
  unitType: UnitType;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type OnlineUnitInfoPerStatus = {
  __typename?: 'OnlineUnitInfoPerStatus';
  inMatch: Array<OnlineUnitInfo>;
  matchRequesting: Array<OnlineUnitInfo>;
  notInLobby: Array<OnlineUnitInfo>;
  standby: Array<OnlineUnitInfo>;
};

export type OnlineUnitLegResult = {
  __typename?: 'OnlineUnitLegResult';
  score: Scalars['Int'];
  stats: Scalars['Float'];
  unitId: Scalars['Int'];
  win: Scalars['Boolean'];
};

export type OnlineUnitMemberSetting = {
  __typename?: 'OnlineUnitMemberSetting';
  blur: Scalars['Boolean'];
  boardCam: BoardCamOption;
  boardCamLotate: BoardCamLotateOption;
  clubOnly: Scalars['Boolean'];
  handicap: Scalars['Boolean'];
  mic: Scalars['Boolean'];
  remoteAcceptable: Scalars['Boolean'];
  scoreLayout01: ScoreLayoutOption01;
  scoreLayoutCr: ScoreLayoutOptionCr;
  show01Arrange: Scalars['Boolean'];
  show01CenterScore: Scalars['Boolean'];
  show01RoundResult: Scalars['Boolean'];
  show01Stats: Scalars['Boolean'];
  showCrRoundResult: Scalars['Boolean'];
  showCrStats: Scalars['Boolean'];
  standby01: Scalars['Boolean'];
  standbyCr: Scalars['Boolean'];
  standbyMed: Scalars['Boolean'];
  standbyPra: Scalars['Boolean'];
};

export type OnlineUnitMemberSettingAttributesInput = {
  blur?: InputMaybe<Scalars['Boolean']>;
  boardCam?: InputMaybe<BoardCamOption>;
  boardCamLotate?: InputMaybe<BoardCamLotateOption>;
  clubOnly?: InputMaybe<Scalars['Boolean']>;
  handicap?: InputMaybe<Scalars['Boolean']>;
  mic?: InputMaybe<Scalars['Boolean']>;
  remoteAcceptable?: InputMaybe<Scalars['Boolean']>;
  scoreLayout01?: InputMaybe<ScoreLayoutOption01>;
  scoreLayoutCr?: InputMaybe<ScoreLayoutOptionCr>;
  show01Arrange?: InputMaybe<Scalars['Boolean']>;
  show01CenterScore?: InputMaybe<Scalars['Boolean']>;
  show01RoundResult?: InputMaybe<Scalars['Boolean']>;
  show01Stats?: InputMaybe<Scalars['Boolean']>;
  showCrRoundResult?: InputMaybe<Scalars['Boolean']>;
  showCrStats?: InputMaybe<Scalars['Boolean']>;
  standby01?: InputMaybe<Scalars['Boolean']>;
  standbyCr?: InputMaybe<Scalars['Boolean']>;
  standbyMed?: InputMaybe<Scalars['Boolean']>;
  standbyPra?: InputMaybe<Scalars['Boolean']>;
};

export type OnlineUnitMemberWithSetting = {
  __typename?: 'OnlineUnitMemberWithSetting';
  member: Player;
  memberSetting: OnlineUnitMemberSetting;
};

export type OnlineUnitSetting = {
  __typename?: 'OnlineUnitSetting';
  handicap: Scalars['Boolean'];
  standby01: Scalars['Boolean'];
  standbyCr: Scalars['Boolean'];
  standbyMed: Scalars['Boolean'];
  standbyPra: Scalars['Boolean'];
};

export type OnlineUnitSettingAttributesInput = {
  handicap?: InputMaybe<Scalars['Boolean']>;
  standby01?: InputMaybe<Scalars['Boolean']>;
  standbyCr?: InputMaybe<Scalars['Boolean']>;
  standbyMed?: InputMaybe<Scalars['Boolean']>;
  standbyPra?: InputMaybe<Scalars['Boolean']>;
};

export type OnlineWebRtcRoom = {
  __typename?: 'OnlineWebRTCRoom';
  id: Scalars['Int'];
  signalingServer: Scalars['String'];
  stunServer: Scalars['String'];
  turnSerer: Scalars['String'];
};

export type OrderDetail = {
  __typename?: 'OrderDetail';
  orderItems: Array<OrderItem>;
  orderedAt: Scalars['Date'];
  paymentId: Scalars['String'];
  totalPrice: Price;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  name: Scalars['String'];
  quantity: Scalars['Int'];
  unitPrice: Price;
};

export enum OutCondition {
  DoubleOut = 'DoubleOut',
  MasterOut = 'MasterOut',
  None = 'None'
}

export type OverallRating = {
  __typename?: 'OverallRating';
  rating80: Scalars['Float'];
  rating100: Scalars['Float'];
};

export type OverallRatingInformation = {
  __typename?: 'OverallRatingInformation';
  ratingOfTheDay?: Maybe<OverallRating>;
  ratingOfThePreviousDay: OverallRating;
};

export type OwnClubsInput = {
  playerId: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type PagedUnitListEvent = RemoteOperationEvent & {
  __typename?: 'PagedUnitListEvent';
  code: RemoteOperationEventCode;
  unitIdList: Array<Scalars['Int']>;
};

export type PaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type Pair = {
  __typename?: 'Pair';
  board_size: Scalars['Int'];
  card_bg_index: Scalars['Int'];
  card_bg_url?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  onlinematch_total: Scalars['Int'];
  partner_player?: Maybe<Player>;
  player?: Maybe<Player>;
  rank: Scalars['String'];
  rating_80: Scalars['Float'];
  rating_100: Scalars['Float'];
  status: PairStatus;
};

export type PairConnection = {
  __typename?: 'PairConnection';
  edges?: Maybe<Array<Maybe<PairEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PairEdge = {
  __typename?: 'PairEdge';
  cursor: Scalars['String'];
  node?: Maybe<Pair>;
};

export enum PairStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Unapproved = 'Unapproved'
}

export type PairsInput = {
  args?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
};

export enum ParticipateMethodOption {
  Open = 'Open',
  OwnerConfirmation = 'OwnerConfirmation',
  Password = 'Password'
}

export enum PaymentPlatform {
  Apple = 'apple',
  Google = 'google'
}

export type PaypayAccountLinkUrl = {
  __typename?: 'PaypayAccountLinkUrl';
  url: Scalars['String'];
};

export enum PiratesBullMode {
  Kids = 'Kids',
  Master = 'Master',
  Normal = 'Normal'
}

export type PiratesOptionInput = {
  mode: PiratesBullMode;
};

export type PiratesPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type PiratesUnitDataInput = {
  playerDatas: Array<PiratesPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type PlanAndDayCount = {
  __typename?: 'PlanAndDayCount';
  dayCount: Scalars['Int'];
  plan: GranProSubscriptionPlan;
};

export enum Platform {
  Android = 'android',
  Ios = 'ios'
}

export type Play = {
  __typename?: 'Play';
  gameCode: GameCode;
  id: Scalars['ID'];
  isFinished: Scalars['Boolean'];
  units: Array<PlayUnit>;
  winnerUnitId?: Maybe<Scalars['Int']>;
};

export type PlayKey = {
  __typename?: 'PlayKey';
  id: Scalars['ID'];
  keys: Array<Scalars['Int']>;
  options: GameOption;
  play_id: Scalars['ID'];
  player: Player;
};

export type PlaySet = {
  __typename?: 'PlaySet';
  id: Scalars['ID'];
  isFinished: Scalars['Boolean'];
  isMatch: Scalars['Boolean'];
  isMedley: Scalars['Boolean'];
  isOnline: Scalars['Boolean'];
  plays: Array<Play>;
  startAt: Scalars['Date'];
  unitCount: Scalars['Int'];
  winnerUnitId?: Maybe<Scalars['Int']>;
};

export type PlayUnit = {
  __typename?: 'PlayUnit';
  id: Scalars['Int'];
  playersAndResults: Array<PlayerAndResult>;
  throwingIndex?: Maybe<Scalars['Int']>;
};

export type Player = {
  __typename?: 'Player';
  animal_win_count: Array<Maybe<AnimalWinCount>>;
  board_size?: Maybe<BoardSize>;
  calc_both_bull_option: Scalars['Boolean'];
  card_bg_color: CardBgColor;
  card_bg_index: Scalars['Int'];
  card_bg_url?: Maybe<Scalars['String']>;
  cricket_next_rank: Scalars['String'];
  cricket_next_rank_100: Scalars['String'];
  cricket_rank: Scalars['String'];
  cricket_rank_100: Scalars['String'];
  cricket_rating_80: Scalars['Float'];
  cricket_rating_100: Scalars['Float'];
  cricket_up_to_next_rank: Scalars['Float'];
  cricket_up_to_next_rank_100: Scalars['Float'];
  event_point: Scalars['Int'];
  follow_total: Scalars['Int'];
  follower_total: Scalars['Int'];
  friends_total: Scalars['Int'];
  good_total: Scalars['Int'];
  gran_id: Scalars['ID'];
  icon_url?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mpr: Scalars['Float'];
  mpr_100: Scalars['Float'];
  next_rank: Scalars['String'];
  next_rank_100: Scalars['String'];
  onlinematch_total: Scalars['Int'];
  pairs?: Maybe<PairConnection>;
  player_name: Scalars['String'];
  ppd_100: Scalars['Float'];
  ppr: Scalars['Float'];
  rank: Scalars['String'];
  rank_100: Scalars['String'];
  rating_80: Scalars['Float'];
  rating_100: Scalars['Float'];
  status: PlayerStatus;
  type: PlayerType;
  up_to_next_rank: Scalars['Float'];
  up_to_next_rank_100: Scalars['Float'];
  user?: Maybe<User>;
  zero_one_next_rank: Scalars['String'];
  zero_one_next_rank_100: Scalars['String'];
  zero_one_rank: Scalars['String'];
  zero_one_rank_100: Scalars['String'];
  zero_one_rating_80: Scalars['Float'];
  zero_one_rating_100: Scalars['Float'];
  zero_one_up_to_next_rank: Scalars['Float'];
  zero_one_up_to_next_rank_100: Scalars['Float'];
};


export type PlayerPairsArgs = {
  input?: InputMaybe<PaginationInput>;
};

export type PlayerAndResult = PlayerIdentity & {
  __typename?: 'PlayerAndResult';
  countryCode?: Maybe<Scalars['String']>;
  granId: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  result?: Maybe<PlayerResult>;
};

export type PlayerChangeSoundItem = {
  __typename?: 'PlayerChangeSoundItem';
  contentPlayerDisplaySoundUrl?: Maybe<Scalars['String']>;
  contentRemoveDartsSoundUrl: Scalars['String'];
  contentRoundDisplaySoundUrl?: Maybe<Scalars['String']>;
  customizeItemType: CustomizeItemType;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  previewMovieUrl: Scalars['String'];
};

export type PlayerConnection = {
  __typename?: 'PlayerConnection';
  edges?: Maybe<Array<Maybe<PlayerEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PlayerCustomizeSetting = {
  __typename?: 'PlayerCustomizeSetting';
  awardMovie: Array<AwardMovieSetting>;
  gameBackground: Array<GameBackgroundSetting>;
  gameHitSound: Array<GameHitSoundSetting>;
  hitEffect: Array<HitEffectSetting>;
  playerChangeSound?: Maybe<PlayerChangeSoundItem>;
  playerId: Scalars['Int'];
  sound: Array<SoundSetting>;
};

export type PlayerCustomizeSettingInput = {
  awardTypeList?: InputMaybe<Array<AwardKey>>;
  gameCodeList?: InputMaybe<Array<GameCode>>;
  hitEffectTypeList?: InputMaybe<Array<HitEffectType>>;
  playerId: Scalars['Int'];
  soundTypeList?: InputMaybe<Array<SoundType>>;
};

export type PlayerCustomizeSettingListInput = {
  awardTypeList?: InputMaybe<Array<AwardKey>>;
  gameCodeList?: InputMaybe<Array<GameCode>>;
  hitEffectTypeList?: InputMaybe<Array<HitEffectType>>;
  playerIdList: Array<Scalars['Int']>;
  soundTypeList?: InputMaybe<Array<SoundType>>;
};

export type PlayerEdge = {
  __typename?: 'PlayerEdge';
  cursor: Scalars['String'];
  node?: Maybe<Player>;
};

export type PlayerIdInput = {
  playerId: Scalars['Int'];
};

export type PlayerIdentity = {
  countryCode?: Maybe<Scalars['String']>;
  granId: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PlayerProfile = {
  __typename?: 'PlayerProfile';
  bgImageUrl?: Maybe<Scalars['String']>;
  dartsHistory?: Maybe<Scalars['Date']>;
  favoriteGame?: Maybe<GameCodeForProfile>;
  favoriteMaker?: Maybe<Scalars['String']>;
  favoriteTarget?: Maybe<TargetSegment>;
  onlineTimeFrom?: Maybe<Scalars['Int']>;
  onlineTimeTo?: Maybe<Scalars['Int']>;
  playLocation?: Maybe<Scalars['String']>;
  playerId: Scalars['Int'];
  selfIntroduction?: Maybe<Scalars['String']>;
  sns: Array<UserSnsProfile>;
};

export type PlayerRelationInput = {
  ownPlayerId: Scalars['Int'];
  targetPlayerId: Scalars['Int'];
};

export type PlayerRelationListInput = {
  args?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
  searchString?: InputMaybe<Scalars['String']>;
};

export type PlayerResult = {
  __typename?: 'PlayerResult';
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: Award;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export enum PlayerStatus {
  Inactive = 'Inactive',
  Local = 'Local',
  Online = 'Online'
}

export enum PlayerType {
  ProDummy = 'ProDummy',
  PublicDummy = 'PublicDummy',
  RealPlayer = 'RealPlayer'
}

export type PlayersInput = {
  args?: InputMaybe<PaginationInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  ownUserId?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Array<Scalars['String']>>;
  searchString?: InputMaybe<Scalars['String']>;
};

export type PostChatMessageInput = {
  content: Scalars['String'];
  playerId: Scalars['Int'];
  roomId: Scalars['Int'];
};

export type PostInAppInformationInput = {
  apps: Array<App>;
  countryCodes?: InputMaybe<Array<Scalars['String']>>;
  listingDeadline?: InputMaybe<Scalars['Date']>;
  rawContents: Array<InAppInformationRawContentInput>;
};

export type PracticeAverageScore = {
  __typename?: 'PracticeAverageScore';
  countUp: Scalars['Float'];
  countUpFatBull: Scalars['Float'];
  countUpSeparatedBull: Scalars['Float'];
  cricketCountUp: Scalars['Float'];
  deltaShoot: Scalars['Float'];
  halfIt: Scalars['Float'];
  oniren: Scalars['Float'];
  pirates: Scalars['Float'];
  rotation: Scalars['Float'];
  shootForce: Scalars['Float'];
  spider: Scalars['Float'];
};

export type PracticeAverageScoreInput = {
  playerId: Scalars['Int'];
  playsCountToBeAveraged: Scalars['Int'];
};

export type PracticeBestScore = {
  __typename?: 'PracticeBestScore';
  countUp: Scalars['Int'];
  countUpFatBull: Scalars['Int'];
  countUpSeparatedBull: Scalars['Int'];
  cricketCountUp: Scalars['Int'];
  deltaShoot: Scalars['Int'];
  halfIt: Scalars['Int'];
  oniren: Scalars['Int'];
  pirates: Scalars['Int'];
  rotation: Scalars['Int'];
  shootForce: Scalars['Int'];
  spider: Scalars['Int'];
};

export type PracticeBestScoreInput = {
  playerId: Scalars['Int'];
};

export type PracticeScoreRanking = PlayerIdentity & {
  __typename?: 'PracticeScoreRanking';
  countryCode?: Maybe<Scalars['String']>;
  granId: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  rank80: Scalars['String'];
  rank100: Scalars['String'];
  score: Scalars['Int'];
};

export type PracticeScoreRankingConnection = {
  __typename?: 'PracticeScoreRankingConnection';
  edges?: Maybe<Array<Maybe<PracticeScoreRankingEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PracticeScoreRankingEdge = {
  __typename?: 'PracticeScoreRankingEdge';
  cursor: Scalars['String'];
  node?: Maybe<PracticeScoreRanking>;
};

export type PracticeScoreRankingFilterInput = {
  boardType?: InputMaybe<BoardType>;
  countryCode?: InputMaybe<Scalars['String']>;
};

export type PracticeScoreRankingInput = {
  filter?: InputMaybe<PracticeScoreRankingFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type PracticeScoreRankingPlayerPositionAndScoreInput = {
  filter?: InputMaybe<PracticeScoreRankingFilterInput>;
  playerId: Scalars['Int'];
};

export enum PreMatchOption {
  Close = 'Close',
  Open = 'Open'
}

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Int'];
  currency: Currency;
};

export type ProAppVersion = {
  __typename?: 'ProAppVersion';
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  dev: Scalars['Boolean'];
  downloadUrl: Scalars['String'];
  onlineAvailable: Scalars['Boolean'];
  status: AppVersionStatus;
  updatedAt?: Maybe<Scalars['Date']>;
  version: Scalars['String'];
};

export type ProAppVersionConnection = {
  __typename?: 'ProAppVersionConnection';
  edges: Array<Maybe<ProAppVersionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProAppVersionEdge = {
  __typename?: 'ProAppVersionEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProAppVersion>;
};

export type ProAppVersionFilterOption = {
  dev?: InputMaybe<Scalars['Boolean']>;
  onlineAvailable?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<AppVersionStatus>;
};

export type ProAppVersionInput = {
  version: Scalars['String'];
};

export type ProEntryArrivedInput = {
  proDummyPlayerId: Scalars['Int'];
};

export type ProEntryCanceledInput = {
  proDummyPlayerId: Scalars['Int'];
};

export type ProKit = {
  __typename?: 'ProKit';
  activateHistory: Array<ProKitActivateHistory>;
  cpuTemperature?: Maybe<Scalars['Float']>;
  cpuUsage?: Maybe<Scalars['Float']>;
  dealer?: Maybe<Dealer>;
  demonstrationPeriodEndAt?: Maybe<Scalars['Date']>;
  distributor?: Maybe<Distributor>;
  id: Scalars['Int'];
  isActivated: Scalars['Boolean'];
  lastActiveAt?: Maybe<Scalars['Date']>;
  machine?: Maybe<Machine>;
  memoryUsage?: Maybe<Scalars['Float']>;
  modelName: Scalars['String'];
  serialCode: Scalars['String'];
  shop?: Maybe<Shop>;
  status: ProKitStatus;
  subscriptionPlan?: Maybe<GranProSubscriptionPlan>;
  subscriptionPlanSchedule?: Maybe<GranProSubscriptionPlanSchedule>;
  totalActivateCount: Scalars['Int'];
  usageReportedAt?: Maybe<Scalars['Date']>;
};

export type ProKitActivateHistory = {
  __typename?: 'ProKitActivateHistory';
  activateAt: Scalars['Date'];
  activateBy: Scalars['String'];
};

export type ProKitConnection = {
  __typename?: 'ProKitConnection';
  edges: Array<Maybe<ProKitEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProKitEdge = {
  __typename?: 'ProKitEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProKit>;
};

export type ProKitFilterOption = {
  status?: InputMaybe<ProKitStatusFilter>;
};

export type ProKitInput = {
  proKitId: Scalars['Int'];
};

export enum ProKitServiceModeOption {
  Credit = 'Credit',
  Free = 'Free'
}

export type ProKitStartupHistory = {
  __typename?: 'ProKitStartupHistory';
  date: Scalars['Date'];
  dealerId: Scalars['Int'];
  id: Scalars['ID'];
  machineId: Scalars['Int'];
  plan: GranProSubscriptionPlan;
  proKitId: Scalars['Int'];
  shopId: Scalars['Int'];
};

export enum ProKitStatus {
  Deleted = 'Deleted',
  InService = 'InService',
  OutOfService = 'OutOfService'
}

export enum ProKitStatusFilter {
  InService = 'InService',
  OutOfService = 'OutOfService'
}

export type ProKitWithSession = {
  __typename?: 'ProKitWithSession';
  proKit: ProKit;
  session: UserSession;
};

export type ProShopIncomeCollectionEvent = {
  __typename?: 'ProShopIncomeCollectionEvent';
  automaticallyCollectedTotalCount: Scalars['Int'];
  eventName: Scalars['String'];
  id: Scalars['ID'];
  lastMachineIncomeCollectionEventDate?: Maybe<Scalars['Date']>;
  manuallyInputTotalCount: Scalars['Int'];
};

export type ProShopIncomeCollectionEventConnection = {
  __typename?: 'ProShopIncomeCollectionEventConnection';
  edges: Array<Maybe<ProShopIncomeCollectionEventEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProShopIncomeCollectionEventEdge = {
  __typename?: 'ProShopIncomeCollectionEventEdge';
  cursor: Scalars['String'];
  node: ProShopIncomeCollectionEvent;
};

export type ProShopLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  proKitSerialCode: Scalars['String'];
};

export type ProShopVisitorStatistics = PlayerIdentity & {
  __typename?: 'ProShopVisitorStatistics';
  countryCode?: Maybe<Scalars['String']>;
  granId: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  visitedCount: Scalars['Int'];
};

export type ProShopVisitorStatisticsConnection = {
  __typename?: 'ProShopVisitorStatisticsConnection';
  edges: Array<Maybe<ProShopVisitorStatisticsEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProShopVisitorStatisticsEdge = {
  __typename?: 'ProShopVisitorStatisticsEdge';
  cursor: Scalars['String'];
  node: ProShopVisitorStatistics;
};

export type ProhibitDealerLoginInput = {
  dealerId: Scalars['Int'];
};

export type ProhibitShopLoginInput = {
  shopId: Scalars['Int'];
};

export type ProhibitShopMachineInput = {
  machineId: Scalars['Int'];
};

export type PublicEntryArrivedInput = {
  publicDummyPlayerId: Scalars['Int'];
};

export type Purchase = {
  __typename?: 'Purchase';
  amount: Scalars['Int'];
  coin?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  itemName?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['Int']>;
  purchase_at?: Maybe<Scalars['Date']>;
  status: PurchaseStatus;
  type: PurchaseType;
  userId: Scalars['Int'];
};

export type PurchaseCoinsResult = {
  __typename?: 'PurchaseCoinsResult';
  noOfCoinPurchased: Scalars['Int'];
  numberOfPurchasedCoin: Scalars['Int'];
  updatedCoinBalanceOfUser: Scalars['Int'];
};

export type PurchaseConnection = {
  __typename?: 'PurchaseConnection';
  edges?: Maybe<Array<Maybe<PurchaseEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PurchaseEdge = {
  __typename?: 'PurchaseEdge';
  cursor: Scalars['String'];
  node?: Maybe<Purchase>;
};

export type PurchaseItemsInput = {
  itemId: Scalars['Int'];
  userId: Scalars['Int'];
};

export enum PurchaseStatus {
  Canceled = 'Canceled',
  Pending = 'Pending',
  Purchased = 'Purchased'
}

export type PurchaseStoreCustomizeItemInput = {
  customizeItemId: Scalars['Int'];
  playerId: Scalars['Int'];
};

export enum PurchaseType {
  Award = 'Award',
  Background = 'Background',
  Coin = 'Coin',
  Game = 'Game',
  Image = 'Image',
  Theme = 'Theme'
}

export type QrCodeInfo = {
  __typename?: 'QrCodeInfo';
  text: Scalars['String'];
  url: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  app_version: AppVersion;
  block_list: PlayerConnection;
  cancel_change_email: SuccessResponse;
  chat_room_list: ChatRoomConnection;
  check_following: Array<Scalars['Boolean']>;
  check_friendship: Array<FriendshipState>;
  check_gran_id_duplication: SuccessResponse;
  club: Club;
  club_invitable_friends: Array<Maybe<Player>>;
  current_weekly_bull_count_ranking: AwardRankingConnection;
  current_weekly_bull_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_count_up_score_ranking: PracticeScoreRankingConnection;
  current_weekly_count_up_score_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_cricket_count_up_score_ranking: PracticeScoreRankingConnection;
  current_weekly_cricket_count_up_score_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_delta_shoot_score_ranking: PracticeScoreRankingConnection;
  current_weekly_delta_shoot_score_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_half_it_score_ranking: PracticeScoreRankingConnection;
  current_weekly_half_it_score_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_hat_trick_count_ranking: AwardRankingConnection;
  current_weekly_hat_trick_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_online_cricket_match_count_ranking: OnlineMatchCountRankingConnection;
  current_weekly_online_cricket_match_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_online_cricket_match_win_count_ranking: OnlineMatchWinCountRankingConnection;
  current_weekly_online_cricket_match_win_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_online_zero_one_match_count_ranking: OnlineMatchCountRankingConnection;
  current_weekly_online_zero_one_match_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_online_zero_one_match_win_count_ranking: OnlineMatchWinCountRankingConnection;
  current_weekly_online_zero_one_match_win_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_shoot_force_score_ranking: PracticeScoreRankingConnection;
  current_weekly_shoot_force_score_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_three_in_a_bed_count_ranking: AwardRankingConnection;
  current_weekly_three_in_a_bed_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_three_in_the_black_count_ranking: AwardRankingConnection;
  current_weekly_three_in_the_black_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_ton_80_count_ranking: AwardRankingConnection;
  current_weekly_ton_80_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_triple_20_count_ranking: AwardRankingConnection;
  current_weekly_triple_20_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  current_weekly_white_horse_count_ranking: AwardRankingConnection;
  current_weekly_white_horse_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  dealer: Dealer;
  distributor: Distributor;
  find_private_chat_room: ChatRoom;
  follow_list: PlayerConnection;
  follower_list: PlayerConnection;
  friend_list: PlayerConnection;
  get_active_player?: Maybe<Player>;
  get_animal_win_counts: Array<Maybe<AnimalWinCount>>;
  get_app_versions_list: AppVersionConnection;
  get_award: Award;
  get_club_banned_members_list: ClubMemberConnection;
  get_club_invitation_list: Array<Maybe<Club>>;
  get_club_join_request_list: ClubMemberConnection;
  get_club_list: ClubConnection;
  get_club_members_list: ClubMemberConnection;
  get_count_up_analysis_result: CountUpAnalysisResult;
  get_count_up_score: Array<Scalars['Int']>;
  get_cricket_activity: CricketActivity;
  get_cricket_activity_list: Array<CricketActivity>;
  get_cricket_analysis_result: CricketAnalysisResult;
  get_cricket_count_up_analysis_result: CricketCountUpAnalysisResult;
  get_cricket_stats: Array<CricketStats>;
  get_current_online_matches: Scalars['JSON'];
  get_dealers_list_of_distributor: DealerConnection;
  get_delta_shoot_analysis_result: DeltaShootAnalysisResult;
  get_gran_player_app_versions_list: GranPlayerAppVersionConnection;
  get_half_it_analysis_result: HalfItAnalysisResult;
  get_in_app_information_list: InAppInformationConnection;
  get_in_app_information_list_by_user: Array<InAppInformation>;
  get_last_five_shop_income_collection_events: Array<ProShopIncomeCollectionEvent>;
  get_machine_list_of_dealer: MachineConnection;
  get_machine_list_of_shop: MachineConnection;
  get_machines_with_income_of_shop: Array<MachineWithIncome>;
  get_monthly_pro_subscription_plan_billing_amount_of_dealer: MonthlyProSubscriptionPlanBillingAmountOfDealer;
  get_my_club_list: ClubMemberConnection;
  get_online_lobby_info: OnlineLobbyInfo;
  get_online_unit: OnlineUnit;
  get_online_unit_list: OnlineUnitConnection;
  get_overall_rating_information: OverallRatingInformation;
  get_overall_rating_information_list: Array<Maybe<OverallRatingInformation>>;
  get_permission_to_update_machine_setting: SuccessResponse;
  get_player_profile: PlayerProfile;
  get_pro_app_versions_list: ProAppVersionConnection;
  get_pro_kit_list_of_dealer: ProKitConnection;
  get_random_play_key: PlayKey;
  get_remote_acceptable_friends: Array<Maybe<Player>>;
  get_rotation_analysis_result: RotationAnalysisResult;
  get_shop_by_beacon_id: Shop;
  get_shop_income_collection_event_list: ProShopIncomeCollectionEventConnection;
  get_shops_list_of_dealer: ShopConnection;
  get_store_award_movie_item_list: StoreAwardMovieItemConnection;
  get_store_game_background_item_list: StoreGameBackgroundItemConnection;
  get_store_game_hit_sound_item_list: StoreGameHitSoundItemConnection;
  get_store_hit_effect_item_list: StoreHitEffectItemConnection;
  get_store_player_change_sound_item_list: StorePlayerChangeSoundItemConnection;
  get_store_sound_item_list: StoreSoundItemConnection;
  get_subordinate_dealers_list_of_partner: DealerConnection;
  get_target_bull_analysis_result: TargetBullAnalysisResult;
  get_target_twenty_analysis_result: TargetTwentyAnalysisResult;
  get_user_serial_code_issue_list_by_date: Array<UserBoardSerialCodeIssue>;
  get_user_uploaded_game_background_item_of_game_code?: Maybe<UploadedByPlayerGameBackgroundItem>;
  get_visiting_player_statistics_of_shop: ProShopVisitorStatisticsConnection;
  get_wild_card_cricket_play_list: Array<WildCardCricketPlay>;
  get_zero_one_activity: ZeroOneActivity;
  get_zero_one_activity_list: Array<ZeroOneActivity>;
  get_zero_one_analysis_result: ZeroOneAnalysisResult;
  get_zero_one_stats: Array<ZeroOneStats>;
  gran_player_app_version: GranPlayerAppVersion;
  grancoin_purchase_history: PurchaseConnection;
  guest_list: PlayerConnection;
  host_list: PlayerConnection;
  items_purchased_history: PurchaseConnection;
  languages?: Maybe<Array<Maybe<Language>>>;
  latest_pro_app_download_url: Scalars['String'];
  logout: SuccessResponse;
  machine: Machine;
  make_public_entry: SuccessResponse;
  match: MatchPlaySet;
  matches: Array<MatchPlaySet>;
  matches_grouped_by_date: Array<MatchesGroupByDate>;
  matches_of_day: MatchesOfDayResult;
  online_match_servers: Array<OnlineMatchServerInfo>;
  own_clubs: Array<Maybe<ClubMember>>;
  pairs: PairConnection;
  player: Player;
  player_customize_setting: PlayerCustomizeSetting;
  player_customize_setting_list: Array<PlayerCustomizeSetting>;
  players: PlayerConnection;
  practice_average_score: PracticeAverageScore;
  practice_best_score: PracticeBestScore;
  previous_weekly_bull_count_ranking: AwardRankingConnection;
  previous_weekly_bull_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_count_up_score_ranking: PracticeScoreRankingConnection;
  previous_weekly_count_up_score_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_cricket_count_up_score_ranking: PracticeScoreRankingConnection;
  previous_weekly_cricket_count_up_score_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_delta_shoot_score_ranking: PracticeScoreRankingConnection;
  previous_weekly_delta_shoot_score_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_half_it_score_ranking: PracticeScoreRankingConnection;
  previous_weekly_half_it_score_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_hat_trick_count_ranking: AwardRankingConnection;
  previous_weekly_hat_trick_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_online_cricket_match_count_ranking: OnlineMatchCountRankingConnection;
  previous_weekly_online_cricket_match_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_online_cricket_match_win_count_ranking: OnlineMatchWinCountRankingConnection;
  previous_weekly_online_cricket_match_win_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_online_zero_one_match_count_ranking: OnlineMatchCountRankingConnection;
  previous_weekly_online_zero_one_match_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_online_zero_one_match_win_count_ranking: OnlineMatchWinCountRankingConnection;
  previous_weekly_online_zero_one_match_win_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_shoot_force_score_ranking: PracticeScoreRankingConnection;
  previous_weekly_shoot_force_score_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_three_in_a_bed_count_ranking: AwardRankingConnection;
  previous_weekly_three_in_a_bed_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_three_in_the_black_count_ranking: AwardRankingConnection;
  previous_weekly_three_in_the_black_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_ton_80_count_ranking: AwardRankingConnection;
  previous_weekly_ton_80_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_triple_20_count_ranking: AwardRankingConnection;
  previous_weekly_triple_20_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  previous_weekly_white_horse_count_ranking: AwardRankingConnection;
  previous_weekly_white_horse_count_ranking_player_position_and_score?: Maybe<RankingPlayerPositionAndScore>;
  pro_app_version: ProAppVersion;
  pro_kit: ProKit;
  refresh_token: UserWithSession;
  request_change_email: SuccessResponse;
  request_dealer_password_reset_code: SuccessResponse;
  request_shop_password_reset_code: SuccessResponse;
  resend_activation_email: Confirmation;
  resend_change_email: SuccessResponse;
  resend_dealer_email_verification_code: SuccessResponse;
  resend_shop_email_verification_code: SuccessResponse;
  reset_password: Confirmation;
  search_club: ClubConnection;
  send_stamp_messages: SuccessResponse;
  shop: Shop;
  total_uncollected_income_of_shop: Scalars['Int'];
  user?: Maybe<User>;
  user_in_db: UserInDb;
  users?: Maybe<Array<Maybe<User>>>;
  verify_email: SuccessResponse;
  win_and_lose_count: WinAndLoseCount;
};


export type QueryApp_VersionArgs = {
  input: AppVersionInput;
};


export type QueryBlock_ListArgs = {
  input: PlayerRelationListInput;
};


export type QueryCancel_Change_EmailArgs = {
  input: CancelChangeEmailInput;
};


export type QueryChat_Room_ListArgs = {
  input: ChatRoomListInput;
};


export type QueryCheck_FollowingArgs = {
  input: CheckFollowingInput;
};


export type QueryCheck_FriendshipArgs = {
  input: CheckFriendshipInput;
};


export type QueryCheck_Gran_Id_DuplicationArgs = {
  input: CheckGranIdDuplicationInput;
};


export type QueryClubArgs = {
  input: ClubInput;
};


export type QueryClub_Invitable_FriendsArgs = {
  input: ClubInvitableFriendsInput;
};


export type QueryCurrent_Weekly_Bull_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryCurrent_Weekly_Bull_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Count_Up_Score_RankingArgs = {
  input: PracticeScoreRankingInput;
};


export type QueryCurrent_Weekly_Count_Up_Score_Ranking_Player_Position_And_ScoreArgs = {
  input: PracticeScoreRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Cricket_Count_Up_Score_RankingArgs = {
  input: PracticeScoreRankingInput;
};


export type QueryCurrent_Weekly_Cricket_Count_Up_Score_Ranking_Player_Position_And_ScoreArgs = {
  input: PracticeScoreRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Delta_Shoot_Score_RankingArgs = {
  input: PracticeScoreRankingInput;
};


export type QueryCurrent_Weekly_Delta_Shoot_Score_Ranking_Player_Position_And_ScoreArgs = {
  input: PracticeScoreRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Half_It_Score_RankingArgs = {
  input: PracticeScoreRankingInput;
};


export type QueryCurrent_Weekly_Half_It_Score_Ranking_Player_Position_And_ScoreArgs = {
  input: PracticeScoreRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Hat_Trick_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryCurrent_Weekly_Hat_Trick_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Online_Cricket_Match_Count_RankingArgs = {
  input: OnlineMatchCountRankingInput;
};


export type QueryCurrent_Weekly_Online_Cricket_Match_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: OnlineMatchCountRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Online_Cricket_Match_Win_Count_RankingArgs = {
  input: OnlineMatchWinCountRankingInput;
};


export type QueryCurrent_Weekly_Online_Cricket_Match_Win_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: OnlineMatchWinCountRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Online_Zero_One_Match_Count_RankingArgs = {
  input: OnlineMatchCountRankingInput;
};


export type QueryCurrent_Weekly_Online_Zero_One_Match_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: OnlineMatchCountRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Online_Zero_One_Match_Win_Count_RankingArgs = {
  input: OnlineMatchWinCountRankingInput;
};


export type QueryCurrent_Weekly_Online_Zero_One_Match_Win_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: OnlineMatchWinCountRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Shoot_Force_Score_RankingArgs = {
  input: PracticeScoreRankingInput;
};


export type QueryCurrent_Weekly_Shoot_Force_Score_Ranking_Player_Position_And_ScoreArgs = {
  input: PracticeScoreRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Three_In_A_Bed_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryCurrent_Weekly_Three_In_A_Bed_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Three_In_The_Black_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryCurrent_Weekly_Three_In_The_Black_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Ton_80_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryCurrent_Weekly_Ton_80_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_Triple_20_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryCurrent_Weekly_Triple_20_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryCurrent_Weekly_White_Horse_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryCurrent_Weekly_White_Horse_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryDealerArgs = {
  input: DealerInput;
};


export type QueryDistributorArgs = {
  input: DistributorInput;
};


export type QueryFind_Private_Chat_RoomArgs = {
  input: FindPrivateChatRoomInput;
};


export type QueryFollow_ListArgs = {
  input: PlayerRelationListInput;
};


export type QueryFollower_ListArgs = {
  input: PlayerRelationListInput;
};


export type QueryFriend_ListArgs = {
  input: PlayerRelationListInput;
};


export type QueryGet_Active_PlayerArgs = {
  input: GetActivePlayerInput;
};


export type QueryGet_Animal_Win_CountsArgs = {
  input: GetAnimalWinCountsInput;
};


export type QueryGet_App_Versions_ListArgs = {
  input: GetAppVersionsListInput;
};


export type QueryGet_AwardArgs = {
  input: GetAwardInput;
};


export type QueryGet_Club_Banned_Members_ListArgs = {
  input: GetClubBannedMembersListInput;
};


export type QueryGet_Club_Invitation_ListArgs = {
  input: GetClubInvitationListInput;
};


export type QueryGet_Club_Join_Request_ListArgs = {
  input: GetClubJoinRequestListInput;
};


export type QueryGet_Club_ListArgs = {
  input: GetClubListInput;
};


export type QueryGet_Club_Members_ListArgs = {
  input: GetClubMembersListInput;
};


export type QueryGet_Count_Up_Analysis_ResultArgs = {
  input: GetCountUpAnalysisResultInput;
};


export type QueryGet_Count_Up_ScoreArgs = {
  input: GetCountUpScoreInput;
};


export type QueryGet_Cricket_ActivityArgs = {
  input: GetCricketActivityInput;
};


export type QueryGet_Cricket_Activity_ListArgs = {
  input: GetCricketActivityListInput;
};


export type QueryGet_Cricket_Analysis_ResultArgs = {
  input: GetCricketAnalysisResultInput;
};


export type QueryGet_Cricket_Count_Up_Analysis_ResultArgs = {
  input: GetCricketCountUpAnalysisResultInput;
};


export type QueryGet_Cricket_StatsArgs = {
  input: GetCricketStatsInput;
};


export type QueryGet_Dealers_List_Of_DistributorArgs = {
  input: GetDealersListOfDistributorInput;
};


export type QueryGet_Delta_Shoot_Analysis_ResultArgs = {
  input: GetDeltaShootAnalysisResultInput;
};


export type QueryGet_Gran_Player_App_Versions_ListArgs = {
  input: GetGranPlayerAppVersionsListInput;
};


export type QueryGet_Half_It_Analysis_ResultArgs = {
  input: GetHalfItAnalysisResultInput;
};


export type QueryGet_In_App_Information_ListArgs = {
  input: GetInAppInformationListInput;
};


export type QueryGet_In_App_Information_List_By_UserArgs = {
  input: GetInAppInformationListByUserInput;
};


export type QueryGet_Last_Five_Shop_Income_Collection_EventsArgs = {
  input: GetLastFiveShopIncomeCollectionEventsInput;
};


export type QueryGet_Machine_List_Of_DealerArgs = {
  input: GetMachineListOfDealerInput;
};


export type QueryGet_Machine_List_Of_ShopArgs = {
  input: GetMachineListOfShopInput;
};


export type QueryGet_Machines_With_Income_Of_ShopArgs = {
  input: GetMachinesWithIncomeOfShopInput;
};


export type QueryGet_Monthly_Pro_Subscription_Plan_Billing_Amount_Of_DealerArgs = {
  input: GetMonthlyProSubscriptionPlanBillingAmountOfDealerInput;
};


export type QueryGet_My_Club_ListArgs = {
  input: GetMyClubListInput;
};


export type QueryGet_Online_UnitArgs = {
  input: GetOnlineUnitInput;
};


export type QueryGet_Online_Unit_ListArgs = {
  input: GetOnlineUnitListInput;
};


export type QueryGet_Overall_Rating_InformationArgs = {
  input: GetOverallRatingInformationInput;
};


export type QueryGet_Overall_Rating_Information_ListArgs = {
  input: GetOverallRatingInformationListInput;
};


export type QueryGet_Permission_To_Update_Machine_SettingArgs = {
  input: GetPermissionToUpdateMachineSetting;
};


export type QueryGet_Player_ProfileArgs = {
  input: GetPlayerProfileInput;
};


export type QueryGet_Pro_App_Versions_ListArgs = {
  input: GetProAppVersionsListInput;
};


export type QueryGet_Pro_Kit_List_Of_DealerArgs = {
  input: GetProKitListOfDealerInput;
};


export type QueryGet_Random_Play_KeyArgs = {
  input: PlayerIdInput;
};


export type QueryGet_Remote_Acceptable_FriendsArgs = {
  input: GetRemoteAcceptableFriendsInput;
};


export type QueryGet_Rotation_Analysis_ResultArgs = {
  input: GetRotationAnalysisResultInput;
};


export type QueryGet_Shop_By_Beacon_IdArgs = {
  input: GetShopByBeaconIdInput;
};


export type QueryGet_Shop_Income_Collection_Event_ListArgs = {
  input: GetShopIncomeCollectionEventListInput;
};


export type QueryGet_Shops_List_Of_DealerArgs = {
  input: GetShopsListOfDealerInput;
};


export type QueryGet_Store_Award_Movie_Item_ListArgs = {
  input: GetStoreAwardMovieItemListInput;
};


export type QueryGet_Store_Game_Background_Item_ListArgs = {
  input: GetStoreGameBackgroundItemListInput;
};


export type QueryGet_Store_Game_Hit_Sound_Item_ListArgs = {
  input: GetStoreGameHitSoundItemListInput;
};


export type QueryGet_Store_Hit_Effect_Item_ListArgs = {
  input: GetStoreHitEffectItemListInput;
};


export type QueryGet_Store_Player_Change_Sound_Item_ListArgs = {
  input: GetStorePlayerChangeSoundItemListInput;
};


export type QueryGet_Store_Sound_Item_ListArgs = {
  input: GetStoreSoundItemListInput;
};


export type QueryGet_Subordinate_Dealers_List_Of_PartnerArgs = {
  input: GetSubordinateDealersListOfPartnerInput;
};


export type QueryGet_Target_Bull_Analysis_ResultArgs = {
  input: GetTargetBullAnalysisResultInput;
};


export type QueryGet_Target_Twenty_Analysis_ResultArgs = {
  input: GetTargetTwentyAnalysisResultInput;
};


export type QueryGet_User_Serial_Code_Issue_List_By_DateArgs = {
  input: GetUserSerialCodeIssueListByDateInput;
};


export type QueryGet_User_Uploaded_Game_Background_Item_Of_Game_CodeArgs = {
  input: GetUploadedByPlayerGameBackgroundItemOfGameCodeInput;
};


export type QueryGet_Visiting_Player_Statistics_Of_ShopArgs = {
  input: GetVisitingPlayerStatisticsOfShopInput;
};


export type QueryGet_Wild_Card_Cricket_Play_ListArgs = {
  input: GetWildCardCricketPlayListInput;
};


export type QueryGet_Zero_One_ActivityArgs = {
  input: GetZeroOneActivityInput;
};


export type QueryGet_Zero_One_Activity_ListArgs = {
  input: GetZeroOneActivityListInput;
};


export type QueryGet_Zero_One_Analysis_ResultArgs = {
  input: GetZeroOneAnalysisResultInput;
};


export type QueryGet_Zero_One_StatsArgs = {
  input: GetZeroOneStatsInput;
};


export type QueryGran_Player_App_VersionArgs = {
  input: GranPlayerAppVersionInput;
};


export type QueryGrancoin_Purchase_HistoryArgs = {
  input: GranCoinPurchasedListInput;
};


export type QueryGuest_ListArgs = {
  input: GuestListInput;
};


export type QueryHost_ListArgs = {
  input: HostListInput;
};


export type QueryItems_Purchased_HistoryArgs = {
  input: ItemsPurchasedHistoryInput;
};


export type QueryLogoutArgs = {
  input: LogoutInput;
};


export type QueryMachineArgs = {
  input: MachineInput;
};


export type QueryMake_Public_EntryArgs = {
  input: MakePublicEntryInput;
};


export type QueryMatchArgs = {
  input: MatchInput;
};


export type QueryMatchesArgs = {
  input: MatchesInput;
};


export type QueryMatches_Grouped_By_DateArgs = {
  input: MatchesGroupByDateInput;
};


export type QueryMatches_Of_DayArgs = {
  input: MatchesOfDayInput;
};


export type QueryOwn_ClubsArgs = {
  input: OwnClubsInput;
};


export type QueryPairsArgs = {
  input: PairsInput;
};


export type QueryPlayerArgs = {
  id: Scalars['Int'];
};


export type QueryPlayer_Customize_SettingArgs = {
  input: PlayerCustomizeSettingInput;
};


export type QueryPlayer_Customize_Setting_ListArgs = {
  input: PlayerCustomizeSettingListInput;
};


export type QueryPlayersArgs = {
  input: PlayersInput;
};


export type QueryPractice_Average_ScoreArgs = {
  input: PracticeAverageScoreInput;
};


export type QueryPractice_Best_ScoreArgs = {
  input: PracticeBestScoreInput;
};


export type QueryPrevious_Weekly_Bull_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryPrevious_Weekly_Bull_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Count_Up_Score_RankingArgs = {
  input: PracticeScoreRankingInput;
};


export type QueryPrevious_Weekly_Count_Up_Score_Ranking_Player_Position_And_ScoreArgs = {
  input: PracticeScoreRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Cricket_Count_Up_Score_RankingArgs = {
  input: PracticeScoreRankingInput;
};


export type QueryPrevious_Weekly_Cricket_Count_Up_Score_Ranking_Player_Position_And_ScoreArgs = {
  input: PracticeScoreRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Delta_Shoot_Score_RankingArgs = {
  input: PracticeScoreRankingInput;
};


export type QueryPrevious_Weekly_Delta_Shoot_Score_Ranking_Player_Position_And_ScoreArgs = {
  input: PracticeScoreRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Half_It_Score_RankingArgs = {
  input: PracticeScoreRankingInput;
};


export type QueryPrevious_Weekly_Half_It_Score_Ranking_Player_Position_And_ScoreArgs = {
  input: PracticeScoreRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Hat_Trick_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryPrevious_Weekly_Hat_Trick_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Online_Cricket_Match_Count_RankingArgs = {
  input: OnlineMatchCountRankingInput;
};


export type QueryPrevious_Weekly_Online_Cricket_Match_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: OnlineMatchCountRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Online_Cricket_Match_Win_Count_RankingArgs = {
  input: OnlineMatchWinCountRankingInput;
};


export type QueryPrevious_Weekly_Online_Cricket_Match_Win_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: OnlineMatchWinCountRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Online_Zero_One_Match_Count_RankingArgs = {
  input: OnlineMatchCountRankingInput;
};


export type QueryPrevious_Weekly_Online_Zero_One_Match_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: OnlineMatchCountRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Online_Zero_One_Match_Win_Count_RankingArgs = {
  input: OnlineMatchWinCountRankingInput;
};


export type QueryPrevious_Weekly_Online_Zero_One_Match_Win_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: OnlineMatchWinCountRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Shoot_Force_Score_RankingArgs = {
  input: PracticeScoreRankingInput;
};


export type QueryPrevious_Weekly_Shoot_Force_Score_Ranking_Player_Position_And_ScoreArgs = {
  input: PracticeScoreRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Three_In_A_Bed_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryPrevious_Weekly_Three_In_A_Bed_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Three_In_The_Black_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryPrevious_Weekly_Three_In_The_Black_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Ton_80_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryPrevious_Weekly_Ton_80_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_Triple_20_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryPrevious_Weekly_Triple_20_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryPrevious_Weekly_White_Horse_Count_RankingArgs = {
  input: AwardRankingInput;
};


export type QueryPrevious_Weekly_White_Horse_Count_Ranking_Player_Position_And_ScoreArgs = {
  input: AwardRankingPlayerPositionAndScoreInput;
};


export type QueryPro_App_VersionArgs = {
  input: ProAppVersionInput;
};


export type QueryPro_KitArgs = {
  input: ProKitInput;
};


export type QueryRefresh_TokenArgs = {
  input: RefreshTokenInput;
};


export type QueryRequest_Change_EmailArgs = {
  input: RequestChangeEmailInput;
};


export type QueryResend_Activation_EmailArgs = {
  data: EmailInput;
};


export type QueryResend_Change_EmailArgs = {
  input: ResendChangeEmailInput;
};


export type QueryResend_Dealer_Email_Verification_CodeArgs = {
  input: ResendDealerEmailVerificationCodeInput;
};


export type QueryResend_Shop_Email_Verification_CodeArgs = {
  input: ResendShopEmailVerificationCodeInput;
};


export type QueryReset_PasswordArgs = {
  data: EmailInput;
};


export type QuerySearch_ClubArgs = {
  input: SearchClubInput;
};


export type QuerySend_Stamp_MessagesArgs = {
  input: SendStampMessagesInput;
};


export type QueryShopArgs = {
  input: ShopInput;
};


export type QueryTotal_Uncollected_Income_Of_ShopArgs = {
  input: TotalUncollectedIncomeOfShopInput;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUser_In_DbArgs = {
  input: UserInDbInput;
};


export type QueryVerify_EmailArgs = {
  input: VerifyEmailInput;
};


export type QueryWin_And_Lose_CountArgs = {
  input: WinAndLoseCountInput;
};

export type RankingPlayerPositionAndScore = {
  __typename?: 'RankingPlayerPositionAndScore';
  position: Scalars['Int'];
  score: Scalars['Int'];
};

export type RatingPerGame = {
  __typename?: 'RatingPerGame';
  cricket: Scalars['Float'];
  zeroOne: Scalars['Float'];
};

export type ReceiveMessageInput = {
  filter?: InputMaybe<MessageFilterInput>;
  receiverId: ReceiverId;
};

export type ReceiveRemoteOperationInput = {
  unitId: Scalars['Int'];
};

export type ReceiverId = {
  playerId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type RefreshTokenInput = {
  refresh_token: Scalars['String'];
};

export type RegisterDealerInput = {
  address: Scalars['String'];
  countryCode: Scalars['String'];
  distributorId: Scalars['Int'];
  email: Scalars['String'];
  isPartnerDealer: Scalars['Boolean'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  province: Scalars['String'];
  zipCode: Scalars['String'];
};

export type RegisterGuestInput = {
  guestPlayerId: Scalars['Int'];
  qrCode: Scalars['String'];
};

export type RegisterInput = {
  country_code: Scalars['String'];
  email: Scalars['String'];
  language_code: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterPlayerVisitToStoreInput = {
  playerId: Scalars['Int'];
  shopId: Scalars['Int'];
};

export type RegisterShopInput = {
  address: Scalars['String'];
  countryCode: Scalars['String'];
  dealerId: Scalars['Int'];
  email: Scalars['String'];
  languageCode: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  province: Scalars['String'];
  zipCode: Scalars['String'];
};

export type RemoteDoublesRequestedInput = {
  playerId: Scalars['Int'];
};

export enum RemoteDoublesResponse {
  Accept = 'Accept',
  Refuse = 'Refuse'
}

export type RemoteOperationEvent = {
  code: RemoteOperationEventCode;
};

export enum RemoteOperationEventCode {
  BrokenUpRemote = 'BrokenUpRemote',
  ChangedLobbyStatus = 'ChangedLobbyStatus',
  ChangedThrowOrder = 'ChangedThrowOrder',
  ExitedMatch = 'ExitedMatch',
  GotRequestMatchResult = 'GotRequestMatchResult',
  GotUnitList = 'GotUnitList',
  PagedUnitList = 'PagedUnitList',
  RequestedMatch = 'RequestedMatch',
  RespondedMatchRequest = 'RespondedMatchRequest'
}

export enum RemoteRequestFailedReason {
  NotAcceptable = 'NotAcceptable',
  Refused = 'Refused',
  Timeout = 'Timeout'
}

export type RemoteRequestResult = {
  __typename?: 'RemoteRequestResult';
  failedReason?: Maybe<RemoteRequestFailedReason>;
  unit?: Maybe<OnlineUnit>;
};

export type RemoteRespondResult = {
  __typename?: 'RemoteRespondResult';
  unit?: Maybe<OnlineUnit>;
};

export type RemoveProKitFromMachineInput = {
  machineId: Scalars['Int'];
};

export type RemoveSubordinateDealerFromPartnerInput = {
  subordinateDealerId: Scalars['Int'];
};

export enum RentHours {
  OneHour = 'OneHour',
  ThreeHour = 'ThreeHour',
  TwoHour = 'TwoHour'
}

export type RentMachineByHourInput = {
  machineId: Scalars['Int'];
  rentHours: RentHours;
};

export type RequestChangeEmailInput = {
  language_code: Scalars['String'];
  newEmail: Scalars['String'];
  password: Scalars['String'];
};

export type RequestClubJoinInput = {
  clubId: Scalars['Int'];
  playerId: Scalars['Int'];
};

export type RequestMatchInput = {
  condition: GameConditionInput;
  latencyInfo?: InputMaybe<Array<OnlineMatchLatencyInfo>>;
  receiverId: Scalars['Int'];
  requesterId: Scalars['Int'];
};

export type RequestPairInput = {
  boardSize: Scalars['Int'];
  cardBgImg?: InputMaybe<Scalars['Upload']>;
  cardBgIndex?: InputMaybe<Scalars['Int']>;
  partnerPlayerId: Scalars['Int'];
  playerId: Scalars['Int'];
};

export type RequestRemoteDoublesInput = {
  latencyInfo?: InputMaybe<Array<OnlineMatchLatencyInfo>>;
  receiverId: Scalars['Int'];
  requesterId: Scalars['Int'];
};

export type RequestedMatchEvent = RemoteOperationEvent & {
  __typename?: 'RequestedMatchEvent';
  code: RemoteOperationEventCode;
  requestInfo: MatchRequestInfo;
};

export type ResendChangeEmailInput = {
  newEmail: Scalars['String'];
};

export type ResendDealerEmailVerificationCodeInput = {
  accessToken: Scalars['String'];
};

export type ResendShopEmailVerificationCodeInput = {
  accessToken: Scalars['String'];
};

export type ResetBoardSerialCodeActivateCountByStringInput = {
  serialCode: Scalars['String'];
};

export type ResetBoardSerialCodeActivateCountInput = {
  qrImage: Scalars['Upload'];
};

export type ResetMachineIncomeInput = {
  machineId: Scalars['Int'];
  shopIncomeCollectionEventId: Scalars['ID'];
};

export type RespondToClubInvitation = {
  __typename?: 'RespondToClubInvitation';
  club: Club;
  player: Player;
  response: ClubJoinResponse;
};

export type RespondToClubInvitationInput = {
  clubId: Scalars['Int'];
  playerId: Scalars['Int'];
  response: ClubJoinResponse;
};

export type RespondToClubJoinRequest = {
  __typename?: 'RespondToClubJoinRequest';
  club: Club;
  player: Player;
  response: ClubJoinResponse;
};

export type RespondToClubJoinRequestInput = {
  clubId: Scalars['Int'];
  playerId: Scalars['Int'];
  response: ClubJoinResponse;
};

export type RespondToDoublesRequestInput = {
  latencyInfo?: InputMaybe<Array<OnlineMatchLatencyInfo>>;
  receiverId: Scalars['Int'];
  requesterId: Scalars['Int'];
  response: RemoteDoublesResponse;
};

export type RespondToMatchRequestInput = {
  latencyInfo?: InputMaybe<Array<OnlineMatchLatencyInfo>>;
  receiverId: Scalars['Int'];
  requesterId: Scalars['Int'];
  response: MatchResponse;
};

export type RespondedMatchRequestEvent = RemoteOperationEvent & {
  __typename?: 'RespondedMatchRequestEvent';
  code: RemoteOperationEventCode;
  matchInfo?: Maybe<MatchInfo>;
  playSet?: Maybe<PlaySet>;
  response: MatchResponse;
  signalingServer?: Maybe<Scalars['String']>;
  stunServer?: Maybe<Scalars['String']>;
  turnServer?: Maybe<Scalars['String']>;
  unitRatings?: Maybe<Array<UnitRating>>;
  winCount?: Maybe<MatchUpWinCount>;
};

export type RotationAnalysisDataInput = {
  continuousRoundRate: Scalars['Float'];
  finishRoundCount: Scalars['Int'];
  hasThrownAtTarget1: Scalars['Boolean'];
  hasThrownAtTarget2: Scalars['Boolean'];
  hasThrownAtTarget3: Scalars['Boolean'];
  hasThrownAtTarget4: Scalars['Boolean'];
  hasThrownAtTarget5: Scalars['Boolean'];
  hasThrownAtTarget6: Scalars['Boolean'];
  hasThrownAtTarget7: Scalars['Boolean'];
  hasThrownAtTarget8: Scalars['Boolean'];
  hasThrownAtTarget9: Scalars['Boolean'];
  hasThrownAtTarget10: Scalars['Boolean'];
  hasThrownAtTarget11: Scalars['Boolean'];
  hasThrownAtTarget12: Scalars['Boolean'];
  hasThrownAtTarget13: Scalars['Boolean'];
  hasThrownAtTarget14: Scalars['Boolean'];
  hasThrownAtTarget15: Scalars['Boolean'];
  hasThrownAtTarget16: Scalars['Boolean'];
  hasThrownAtTarget17: Scalars['Boolean'];
  hasThrownAtTarget18: Scalars['Boolean'];
  hasThrownAtTarget19: Scalars['Boolean'];
  hasThrownAtTarget20: Scalars['Boolean'];
  hasThrownAtTargetBull: Scalars['Boolean'];
  target1SuccessRate: Scalars['Float'];
  target2SuccessRate: Scalars['Float'];
  target3SuccessRate: Scalars['Float'];
  target4SuccessRate: Scalars['Float'];
  target5SuccessRate: Scalars['Float'];
  target6SuccessRate: Scalars['Float'];
  target7SuccessRate: Scalars['Float'];
  target8SuccessRate: Scalars['Float'];
  target9SuccessRate: Scalars['Float'];
  target10SuccessRate: Scalars['Float'];
  target11SuccessRate: Scalars['Float'];
  target12SuccessRate: Scalars['Float'];
  target13SuccessRate: Scalars['Float'];
  target14SuccessRate: Scalars['Float'];
  target15SuccessRate: Scalars['Float'];
  target16SuccessRate: Scalars['Float'];
  target17SuccessRate: Scalars['Float'];
  target18SuccessRate: Scalars['Float'];
  target19SuccessRate: Scalars['Float'];
  target20SuccessRate: Scalars['Float'];
  targetBullSuccessRate: Scalars['Float'];
};

export type RotationAnalysisResult = {
  __typename?: 'RotationAnalysisResult';
  continuousRoundRateAverage: Scalars['Float'];
  finishRoundCountAverage: Scalars['Float'];
  target1SuccessRateAverage: Scalars['Float'];
  target2SuccessRateAverage: Scalars['Float'];
  target3SuccessRateAverage: Scalars['Float'];
  target4SuccessRateAverage: Scalars['Float'];
  target5SuccessRateAverage: Scalars['Float'];
  target6SuccessRateAverage: Scalars['Float'];
  target7SuccessRateAverage: Scalars['Float'];
  target8SuccessRateAverage: Scalars['Float'];
  target9SuccessRateAverage: Scalars['Float'];
  target10SuccessRateAverage: Scalars['Float'];
  target11SuccessRateAverage: Scalars['Float'];
  target12SuccessRateAverage: Scalars['Float'];
  target13SuccessRateAverage: Scalars['Float'];
  target14SuccessRateAverage: Scalars['Float'];
  target15SuccessRateAverage: Scalars['Float'];
  target16SuccessRateAverage: Scalars['Float'];
  target17SuccessRateAverage: Scalars['Float'];
  target18SuccessRateAverage: Scalars['Float'];
  target19SuccessRateAverage: Scalars['Float'];
  target20SuccessRateAverage: Scalars['Float'];
  targetBullSuccessRateAverage: Scalars['Float'];
};

export enum RotationMode {
  OnlyDouble = 'OnlyDouble',
  OnlyTriple = 'OnlyTriple',
  Open = 'Open'
}

export type RotationOption = {
  __typename?: 'RotationOption';
  clockwise: Scalars['Boolean'];
  mode: RotationMode;
};

export type RotationOptionInput = {
  clockwise?: InputMaybe<Scalars['Boolean']>;
  mode: RotationMode;
};

export type RotationPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  analysisData?: InputMaybe<RotationAnalysisDataInput>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type RotationUnitDataInput = {
  playerDatas: Array<RotationPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type SaveCountUpBestScorePerOptionInput = {
  playerId: Scalars['Int'];
  separatedBull: Scalars['Boolean'];
};

export enum ScoreLayoutOption01 {
  LayoutA = 'LayoutA',
  LayoutB = 'LayoutB',
  LayoutC = 'LayoutC',
  LayoutD = 'LayoutD'
}

export enum ScoreLayoutOptionCr {
  LayoutA = 'LayoutA',
  LayoutB = 'LayoutB',
  LayoutC = 'LayoutC',
  LayoutD = 'LayoutD'
}

export type SearchClubInput = {
  args?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
  searchString: Scalars['String'];
};

export type SelectMachineForProKitInput = {
  machineId: Scalars['Int'];
  proKitId: Scalars['Int'];
};

export type SendAnimalWinCountInput = {
  animal: Animals;
  code: GameCode;
  playerId: Scalars['Int'];
  win: Scalars['Boolean'];
};

export type SendAppLogInput = {
  appName: Scalars['String'];
  category: AppLogCategory;
  description?: InputMaybe<Scalars['String']>;
  errorCode: Scalars['String'];
  logFile: Scalars['Upload'];
  playerId: Scalars['Int'];
  screenShot?: InputMaybe<Scalars['Upload']>;
  userId: Scalars['Int'];
};

export type SendBeyondTopPlayDataInput = {
  playId: Scalars['ID'];
  unitDatas: Array<BeyondTopUnitDataInput>;
};

export type SendCountUpPlayDataInput = {
  option: CountUpOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<CountUpUnitDataInput>;
};

export type SendCricketCountUpPlayDataInput = {
  playId: Scalars['ID'];
  unitDatas: Array<CricketCountUpUnitDataInput>;
};

export type SendCricketPlayDataInput = {
  onlineMatchId?: InputMaybe<Scalars['ID']>;
  option: CricketOptionInput;
  playId: Scalars['ID'];
  roundCount: Scalars['Int'];
  unitDatas: Array<CricketUnitDataInput>;
  winnerUnitId?: InputMaybe<Scalars['Int']>;
};

export type SendCutThroatCricketPlayDataInput = {
  option: CutThroatCricketOptionInput;
  playId: Scalars['ID'];
  roundCount: Scalars['Int'];
  unitDatas: Array<CutThroatCricketUnitDataInput>;
  winnerUnitId?: InputMaybe<Scalars['Int']>;
};

export type SendDeltaShootPlayDataInput = {
  option: DeltaShootOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<DeltaShootUnitDataInput>;
};

export type SendDirectMessageInput = {
  content: Scalars['String'];
  fromPlayerId: Scalars['Int'];
  toPlayerId: Scalars['Int'];
};

export type SendFreezeZeroOnePlayDataInput = {
  option: ZeroOneOptionInput;
  playId: Scalars['ID'];
  roundCount: Scalars['Int'];
  unitDatas: Array<FreezeZeroOneUnitDataInput>;
  winnerUnitId?: InputMaybe<Scalars['Int']>;
};

export type SendHalfItPlayDataInput = {
  option: HalfItOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<HalfItUnitDataInput>;
};

export type SendHiddenCricketPlayDataInput = {
  option: HiddenCricketOptionInput;
  playId: Scalars['ID'];
  roundCount: Scalars['Int'];
  unitDatas: Array<HiddenCricketUnitDataInput>;
  winnerUnitId?: InputMaybe<Scalars['Int']>;
};

export type SendHiddenCutThroatCricketPlayDataInput = {
  option: HiddenCutThroatCricketOptionInput;
  playId: Scalars['ID'];
  roundCount: Scalars['Int'];
  unitDatas: Array<HiddenCutThroatCricketUnitDataInput>;
  winnerUnitId?: InputMaybe<Scalars['Int']>;
};

export type SendKickDownPlayDataInput = {
  option: KickDownOptionInput;
  playId: Scalars['ID'];
  roundCount: Scalars['Int'];
  unitDatas: Array<KickDownUnitDataInput>;
  winnerUnitId?: InputMaybe<Scalars['Int']>;
};

export type SendLaunchTeamViewerNotificationInput = {
  machineId: Scalars['Int'];
};

export type SendMachineIncomeInput = {
  machineId: Scalars['Int'];
};

export type SendMachineRebootNotificationInput = {
  machineId: Scalars['Int'];
};

export type SendMachineResetNotificationInput = {
  machineId: Scalars['Int'];
};

export type SendMultipleCricketPlayDataInput = {
  option: MultipleCricketOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<MultipleCricketUnitDataInput>;
};

export type SendOnirenPlayDataInput = {
  option: OnirenOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<OnirenUnitDataInput>;
};

export type SendOnlineMatchReportInput = {
  appRunningFrom: Scalars['Date'];
  appVersion: Scalars['String'];
  aspectRatio?: InputMaybe<Scalars['String']>;
  bluetoothVersion?: InputMaybe<Scalars['String']>;
  continuousPlayCount: Scalars['Int'];
  deviceId: Scalars['String'];
  deviceModel?: InputMaybe<Scalars['String']>;
  disconnectCountGRPC: Scalars['Int'];
  disconnectCountWebSocket: Scalars['Int'];
  disconnectSceneAndDateTimesWebSocket: Array<InputMaybe<DisconnectSceneAndDateTime>>;
  endDateTime: Scalars['Date'];
  farmWareVersion?: InputMaybe<Scalars['String']>;
  gameCode?: InputMaybe<OnlineMatchGameCode>;
  latencyInfo?: InputMaybe<Array<OnlineMatchLatencyInfo>>;
  matchId: Scalars['String'];
  matchLegNumber: Scalars['Int'];
  matchServer: Scalars['String'];
  networkReachabillity: Scalars['String'];
  osVersion?: InputMaybe<Scalars['String']>;
  playerId: Scalars['Int'];
  recovered: Scalars['Boolean'];
  scoreLayout01?: InputMaybe<ScoreLayoutOption01>;
  scoreLayoutCr?: InputMaybe<ScoreLayoutOptionCr>;
  startDateTime: Scalars['Date'];
  unitId: Scalars['Int'];
  unitMembers: Array<Scalars['Int']>;
  unitType: UnitType;
};

export type SendPiratesPlayDataInput = {
  option: PiratesOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<PiratesUnitDataInput>;
};

export type SendRemoteSupportRequestInput = {
  machineId: Scalars['Int'];
};

export type SendRotationPlayDataInput = {
  option: RotationOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<RotationUnitDataInput>;
};

export type SendShootForcePlayDataInput = {
  playId: Scalars['ID'];
  unitDatas: Array<ShootForceUnitDataInput>;
};

export type SendSpiderPlayDataInput = {
  option: SpiderOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<SpiderUnitDataInput>;
};

export type SendStampMessagesInput = {
  fromPlayerId: Scalars['Int'];
  message: StampMessage;
  receiveUnitId: Scalars['Int'];
};

export type SendTargetBullPlayDataInput = {
  option: TargetBullOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<TargetBullUnitDataInput>;
};

export type SendTargetHatPlayDataInput = {
  option: TargetHatOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<TargetHatUnitDataInput>;
};

export type SendTargetHorsePlayDataInput = {
  option: TargetHorseOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<TargetHorseUnitDataInput>;
};

export type SendTargetTwentyPlayDataInput = {
  option: TargetTwentyOptionInput;
  playId: Scalars['ID'];
  unitDatas: Array<TargetTwentyUnitDataInput>;
};

export type SendTeamCricketPlayDataInput = {
  option: TeamCricketOptionInput;
  playId: Scalars['ID'];
  roundCount: Scalars['Int'];
  unitDatas: Array<TeamCricketUnitDataInput>;
  winnerUnitId?: InputMaybe<Scalars['Int']>;
};

export type SendThumbsUpReactionInput = {
  fromPlayerId: Scalars['Int'];
  toPlayerId: Scalars['Int'];
};

export type SendWildCardCricketPlayDataInput = {
  option: WildCardCricketOptionInput;
  playId: Scalars['ID'];
  roundCount: Scalars['Int'];
  unitDatas: Array<WildCardCricketUnitDataInput>;
  winnerUnitId?: InputMaybe<Scalars['Int']>;
};

export type SendZeroOnePlayDataInput = {
  onlineMatchId?: InputMaybe<Scalars['ID']>;
  option: ZeroOneOptionInput;
  playId: Scalars['ID'];
  roundCount: Scalars['Int'];
  unitDatas: Array<ZeroOneUnitDataInput>;
  winnerUnitId?: InputMaybe<Scalars['Int']>;
};

export enum ServiceCode {
  ClubJoinRequestAccepted = 'clubJoinRequestAccepted',
  ClubJoinRequestRefused = 'clubJoinRequestRefused',
  NewChatMessage = 'newChatMessage',
  NewClubJoinRequest = 'newClubJoinRequest',
  NewClubMember = 'newClubMember',
  NewFollower = 'newFollower',
  NewFriend = 'newFriend',
  NewThumbsUpReceive = 'newThumbsUpReceive',
  NewUnregisteredRequest = 'newUnregisteredRequest'
}

export type SetAwardMovieItemInput = {
  awardMovieItemId?: InputMaybe<Scalars['Int']>;
  awardType: AwardKey;
  playerId: Scalars['Int'];
};

export type SetGameBackgroundItemInput = {
  gameBackgroundItemId?: InputMaybe<Scalars['Int']>;
  gameCode: Array<GameCode>;
  playerId: Scalars['Int'];
};

export type SetGameHitSoundItemInput = {
  gameCode: Array<GameCode>;
  gameHitSoundItemId?: InputMaybe<Scalars['Int']>;
  playerId: Scalars['Int'];
};

export type SetHitEffectItemInput = {
  hitEffectItemId?: InputMaybe<Scalars['Int']>;
  hitEffectType: HitEffectType;
  playerId: Scalars['Int'];
};

export type SetLocalPlayerInput = {
  firstPlayer: Scalars['Int'];
  secondPlayer?: InputMaybe<Scalars['Int']>;
  unitId: Scalars['Int'];
};

export type SetPlayerChangeSoundItemInput = {
  playerChangeSoundItemId?: InputMaybe<Scalars['Int']>;
  playerId: Scalars['Int'];
};

export type SetProKitSubscriptionPlanAfterDemoInput = {
  proKitId: Scalars['Int'];
  subscriptionPlan: GranProSubscriptionPlan;
};

export type SetSoundItemInput = {
  playerId: Scalars['Int'];
  soundItemId?: InputMaybe<Scalars['Int']>;
  soundType: SoundType;
};

export enum SettingMenuOption {
  Close = 'Close',
  Open = 'Open'
}

export type ShootForcePlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type ShootForceUnitDataInput = {
  playerDatas: Array<ShootForcePlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type Shop = {
  __typename?: 'Shop';
  address: Scalars['String'];
  beaconId?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  dealer: Dealer;
  email: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  incomeConversionRate: Scalars['Int'];
  isActivated: Scalars['Boolean'];
  languageCode: Scalars['String'];
  lastIncomeCollectedAt?: Maybe<Scalars['Date']>;
  lastInformationEditedAt?: Maybe<Scalars['Date']>;
  lastInformationEditedBy?: Maybe<ShopInformationEditor>;
  machines: Array<Machine>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  province: Scalars['String'];
  status: ShopStatus;
  totalUncollectedIncome: Scalars['Int'];
  user?: Maybe<User>;
  zipCode: Scalars['String'];
};

export type ShopConnection = {
  __typename?: 'ShopConnection';
  edges: Array<Maybe<ShopEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ShopEdge = {
  __typename?: 'ShopEdge';
  cursor: Scalars['String'];
  node?: Maybe<Shop>;
};

export type ShopFilterOption = {
  province?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ShopStatusFilter>;
};

export enum ShopInformationEditor {
  Dealer = 'Dealer',
  Shop = 'Shop'
}

export type ShopInput = {
  shopId: Scalars['Int'];
};

export type ShopName = {
  __typename?: 'ShopName';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ShopSession = {
  __typename?: 'ShopSession';
  accessToken?: Maybe<CognitoAccessToken>;
  clockDrift?: Maybe<Scalars['Int']>;
  idToken?: Maybe<CognitoIdToken>;
  refreshToken?: Maybe<CognitoRefreshToken>;
};

export enum ShopStatus {
  Deleted = 'Deleted',
  Enabled = 'Enabled',
  OutOfService = 'OutOfService'
}

export enum ShopStatusFilter {
  Enabled = 'Enabled',
  OutOfService = 'OutOfService'
}

export type ShopWithSession = {
  __typename?: 'ShopWithSession';
  session: ShopSession;
  shop: Shop;
};

export type SoundItem = {
  __typename?: 'SoundItem';
  contentSoundUrl: Scalars['String'];
  customizeItemType: CustomizeItemType;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  previewMovieUrl: Scalars['String'];
  soundType: SoundType;
};

export type SoundSetting = {
  __typename?: 'SoundSetting';
  soundItem: SoundItem;
  soundType: SoundType;
};

export enum SoundType {
  DoubleBullHit = 'DoubleBullHit',
  SingleBullHit = 'SingleBullHit',
  Triple20Hit = 'Triple20Hit'
}

export enum SpiderMode {
  Advanced = 'Advanced',
  Normal = 'Normal'
}

export type SpiderOptionInput = {
  mode: SpiderMode;
  outCondition?: InputMaybe<SpiderOutCondition>;
  separatedBull?: InputMaybe<Scalars['Boolean']>;
};

export enum SpiderOutCondition {
  DoubleOut = 'DoubleOut',
  MasterOut = 'MasterOut',
  None = 'None'
}

export type SpiderPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type SpiderUnitDataInput = {
  playerDatas: Array<SpiderPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type Stamp = {
  __typename?: 'Stamp';
  message: StampMessage;
  player: Player;
};

export enum StampMessage {
  NiceGame = 'NiceGame',
  PlayAgain = 'PlayAgain',
  ThankYou = 'ThankYou',
  YouAreGreat = 'YouAreGreat'
}

export type StampMessagesReceivedInput = {
  unitId: Scalars['Int'];
};

export type StartLocalPlayInput = {
  plays: Array<GameCode>;
  units: Array<Array<Scalars['Int']>>;
};

export type StoreAwardMovieItem = {
  __typename?: 'StoreAwardMovieItem';
  awardType: AwardKey;
  contentMovieUrl: Scalars['String'];
  customizeItemType: CustomizeItemType;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  isNew: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  previewMovieUrl: Scalars['String'];
  price: Scalars['Int'];
};

export type StoreAwardMovieItemConnection = {
  __typename?: 'StoreAwardMovieItemConnection';
  edges: Array<StoreAwardMovieItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StoreAwardMovieItemEdge = {
  __typename?: 'StoreAwardMovieItemEdge';
  cursor: Scalars['String'];
  node: StoreAwardMovieItem;
};

export type StoreCustomizeItem = {
  __typename?: 'StoreCustomizeItem';
  customizeItemType: CustomizeItemType;
  id: Scalars['Int'];
  isNew: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  managementType: CustomizeItemManagementType;
  price: Scalars['Int'];
};

export type StoreGameBackgroundItem = {
  __typename?: 'StoreGameBackgroundItem';
  contentImageUrl: Scalars['String'];
  customizeItemType: CustomizeItemType;
  gameCodes: Array<GameCode>;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  isNew: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  price: Scalars['Int'];
};

export type StoreGameBackgroundItemConnection = {
  __typename?: 'StoreGameBackgroundItemConnection';
  edges: Array<StoreGameBackgroundItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StoreGameBackgroundItemEdge = {
  __typename?: 'StoreGameBackgroundItemEdge';
  cursor: Scalars['String'];
  node: StoreGameBackgroundItem;
};

export type StoreGameHitSoundItem = {
  __typename?: 'StoreGameHitSoundItem';
  contentDoubleHitSoundUrl: Scalars['String'];
  contentSingleHitSoundUrl: Scalars['String'];
  contentTripleHitSoundUrl: Scalars['String'];
  customizeItemType: CustomizeItemType;
  gameCodes: Array<GameCode>;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  isNew: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  previewMovieUrl: Scalars['String'];
  price: Scalars['Int'];
};

export type StoreGameHitSoundItemConnection = {
  __typename?: 'StoreGameHitSoundItemConnection';
  edges: Array<StoreGameHitSoundItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StoreGameHitSoundItemEdge = {
  __typename?: 'StoreGameHitSoundItemEdge';
  cursor: Scalars['String'];
  node: StoreGameHitSoundItem;
};

export type StoreHitEffectItem = {
  __typename?: 'StoreHitEffectItem';
  customizeItemType: CustomizeItemType;
  hitEffectType: HitEffectType;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  isNew: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  previewMovieUrl: Scalars['String'];
  price: Scalars['Int'];
  unityAddressableKey: Scalars['String'];
};

export type StoreHitEffectItemConnection = {
  __typename?: 'StoreHitEffectItemConnection';
  edges: Array<StoreHitEffectItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StoreHitEffectItemEdge = {
  __typename?: 'StoreHitEffectItemEdge';
  cursor: Scalars['String'];
  node: StoreHitEffectItem;
};

export type StorePlayerChangeSoundItem = {
  __typename?: 'StorePlayerChangeSoundItem';
  contentPlayerDisplaySoundUrl?: Maybe<Scalars['String']>;
  contentRemoveDartsSoundUrl: Scalars['String'];
  contentRoundDisplaySoundUrl?: Maybe<Scalars['String']>;
  customizeItemType: CustomizeItemType;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  isNew: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  previewMovieUrl: Scalars['String'];
  price: Scalars['Int'];
};

export type StorePlayerChangeSoundItemConnection = {
  __typename?: 'StorePlayerChangeSoundItemConnection';
  edges: Array<StorePlayerChangeSoundItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StorePlayerChangeSoundItemEdge = {
  __typename?: 'StorePlayerChangeSoundItemEdge';
  cursor: Scalars['String'];
  node: StorePlayerChangeSoundItem;
};

export type StoreSoundItem = {
  __typename?: 'StoreSoundItem';
  contentSoundUrl: Scalars['String'];
  customizeItemType: CustomizeItemType;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  isNew: Scalars['Boolean'];
  isPurchased: Scalars['Boolean'];
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  previewMovieUrl: Scalars['String'];
  price: Scalars['Int'];
  soundType: SoundType;
};

export type StoreSoundItemConnection = {
  __typename?: 'StoreSoundItemConnection';
  edges: Array<StoreSoundItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StoreSoundItemEdge = {
  __typename?: 'StoreSoundItemEdge';
  cursor: Scalars['String'];
  node: StoreSoundItem;
};

export type Subscription = {
  __typename?: 'Subscription';
  card_switched: Player;
  guest_registered: Guest;
  match_requested: MatchRequestInfo;
  pro_entry_arrived: Player;
  pro_entry_canceled: Scalars['String'];
  public_entry_arrived: Player;
  receive_message: Message;
  receive_remote_operation: RemoteOperationEvent;
  remote_doubles_requested: Player;
  stamp_messages_received: Stamp;
};


export type SubscriptionCard_SwitchedArgs = {
  input: CardSwitchedInput;
};


export type SubscriptionGuest_RegisteredArgs = {
  input: PlayerIdInput;
};


export type SubscriptionMatch_RequestedArgs = {
  input: MatchRequestedInput;
};


export type SubscriptionPro_Entry_ArrivedArgs = {
  input: ProEntryArrivedInput;
};


export type SubscriptionPro_Entry_CanceledArgs = {
  input: ProEntryCanceledInput;
};


export type SubscriptionPublic_Entry_ArrivedArgs = {
  input: PublicEntryArrivedInput;
};


export type SubscriptionReceive_MessageArgs = {
  input: ReceiveMessageInput;
};


export type SubscriptionReceive_Remote_OperationArgs = {
  input: ReceiveRemoteOperationInput;
};


export type SubscriptionRemote_Doubles_RequestedArgs = {
  input: RemoteDoublesRequestedInput;
};


export type SubscriptionStamp_Messages_ReceivedArgs = {
  input: StampMessagesReceivedInput;
};

export enum SuccessResponse {
  Success = 'success'
}

export type SupportUserErrorResponse = {
  __typename?: 'SupportUserErrorResponse';
  codeError: Scalars['String'];
  failedImgUrl?: Maybe<Scalars['String']>;
  messageError: Scalars['String'];
  serialCode?: Maybe<Scalars['String']>;
};

export type SupportUserQrInput = {
  productionLotImage: Scalars['Upload'];
};

export type SupportUserQrResponse = QrCodeInfo | SupportUserErrorResponse;

export type SwapRemoteThrowOrderInput = {
  unitId: Scalars['Int'];
};

export type SwitchCardInput = {
  playerId: Scalars['Int'];
};

export type SwitchClubMemberBanInput = {
  banned: Scalars['Boolean'];
  clubId: Scalars['Int'];
  playerId: Scalars['Int'];
  target: Scalars['Int'];
};

export type SwitchPartnerDealerStatusInput = {
  dealerId: Scalars['Int'];
  isPartnerDealer: Scalars['Boolean'];
};

export type SwitchPreMatchInput = {
  operation: PreMatchOption;
  receiverId: Scalars['Int'];
  requesterId: Scalars['Int'];
};

export type SwitchSettingMenuInput = {
  operation: SettingMenuOption;
  unitId: Scalars['Int'];
};

export type SyncUnitListPageInput = {
  unitId: Scalars['Int'];
  unitIdList: Array<Scalars['Int']>;
};

export type TargetBullAnalysisDataInput = {
  bullRate: Scalars['Float'];
  consecutiveHitCount: Scalars['Int'];
  finishRoundCount: Scalars['Int'];
  hitDouble1Rate?: InputMaybe<Scalars['Float']>;
  hitDouble2Rate?: InputMaybe<Scalars['Float']>;
  hitDouble3Rate?: InputMaybe<Scalars['Float']>;
  hitDouble4Rate?: InputMaybe<Scalars['Float']>;
  hitDouble5Rate?: InputMaybe<Scalars['Float']>;
  hitDouble6Rate?: InputMaybe<Scalars['Float']>;
  hitDouble7Rate?: InputMaybe<Scalars['Float']>;
  hitDouble8Rate?: InputMaybe<Scalars['Float']>;
  hitDouble9Rate?: InputMaybe<Scalars['Float']>;
  hitDouble10Rate?: InputMaybe<Scalars['Float']>;
  hitDouble11Rate?: InputMaybe<Scalars['Float']>;
  hitDouble12Rate?: InputMaybe<Scalars['Float']>;
  hitDouble13Rate?: InputMaybe<Scalars['Float']>;
  hitDouble14Rate?: InputMaybe<Scalars['Float']>;
  hitDouble15Rate?: InputMaybe<Scalars['Float']>;
  hitDouble16Rate?: InputMaybe<Scalars['Float']>;
  hitDouble17Rate?: InputMaybe<Scalars['Float']>;
  hitDouble18Rate?: InputMaybe<Scalars['Float']>;
  hitDouble19Rate?: InputMaybe<Scalars['Float']>;
  hitDouble20Rate?: InputMaybe<Scalars['Float']>;
  hitInnerBullRate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle1Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle2Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle3Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle4Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle5Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle6Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle7Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle8Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle9Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle10Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle11Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle12Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle13Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle14Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle15Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle16Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle17Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle18Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle19Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle20Rate?: InputMaybe<Scalars['Float']>;
  hitOuterBullRate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle1Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle2Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle3Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle4Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle5Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle6Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle7Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle8Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle9Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle10Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle11Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle12Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle13Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle14Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle15Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle16Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle17Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle18Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle19Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle20Rate?: InputMaybe<Scalars['Float']>;
  hitTriple1Rate?: InputMaybe<Scalars['Float']>;
  hitTriple2Rate?: InputMaybe<Scalars['Float']>;
  hitTriple3Rate?: InputMaybe<Scalars['Float']>;
  hitTriple4Rate?: InputMaybe<Scalars['Float']>;
  hitTriple5Rate?: InputMaybe<Scalars['Float']>;
  hitTriple6Rate?: InputMaybe<Scalars['Float']>;
  hitTriple7Rate?: InputMaybe<Scalars['Float']>;
  hitTriple8Rate?: InputMaybe<Scalars['Float']>;
  hitTriple9Rate?: InputMaybe<Scalars['Float']>;
  hitTriple10Rate?: InputMaybe<Scalars['Float']>;
  hitTriple11Rate?: InputMaybe<Scalars['Float']>;
  hitTriple12Rate?: InputMaybe<Scalars['Float']>;
  hitTriple13Rate?: InputMaybe<Scalars['Float']>;
  hitTriple14Rate?: InputMaybe<Scalars['Float']>;
  hitTriple15Rate?: InputMaybe<Scalars['Float']>;
  hitTriple16Rate?: InputMaybe<Scalars['Float']>;
  hitTriple17Rate?: InputMaybe<Scalars['Float']>;
  hitTriple18Rate?: InputMaybe<Scalars['Float']>;
  hitTriple19Rate?: InputMaybe<Scalars['Float']>;
  hitTriple20Rate?: InputMaybe<Scalars['Float']>;
  innerBullCountDividedByWholeBullCountRate: Scalars['Float'];
};

export type TargetBullAnalysisResult = {
  __typename?: 'TargetBullAnalysisResult';
  bullRateAverage: Scalars['Float'];
  consecutiveHitCountAverage: Scalars['Float'];
  finishRoundCountAverage: Scalars['Float'];
  hitDouble1RateAverage: Scalars['Float'];
  hitDouble2RateAverage: Scalars['Float'];
  hitDouble3RateAverage: Scalars['Float'];
  hitDouble4RateAverage: Scalars['Float'];
  hitDouble5RateAverage: Scalars['Float'];
  hitDouble6RateAverage: Scalars['Float'];
  hitDouble7RateAverage: Scalars['Float'];
  hitDouble8RateAverage: Scalars['Float'];
  hitDouble9RateAverage: Scalars['Float'];
  hitDouble10RateAverage: Scalars['Float'];
  hitDouble11RateAverage: Scalars['Float'];
  hitDouble12RateAverage: Scalars['Float'];
  hitDouble13RateAverage: Scalars['Float'];
  hitDouble14RateAverage: Scalars['Float'];
  hitDouble15RateAverage: Scalars['Float'];
  hitDouble16RateAverage: Scalars['Float'];
  hitDouble17RateAverage: Scalars['Float'];
  hitDouble18RateAverage: Scalars['Float'];
  hitDouble19RateAverage: Scalars['Float'];
  hitDouble20RateAverage: Scalars['Float'];
  hitInnerBullRateAverage: Scalars['Float'];
  hitInnerSingle1RateAverage: Scalars['Float'];
  hitInnerSingle2RateAverage: Scalars['Float'];
  hitInnerSingle3RateAverage: Scalars['Float'];
  hitInnerSingle4RateAverage: Scalars['Float'];
  hitInnerSingle5RateAverage: Scalars['Float'];
  hitInnerSingle6RateAverage: Scalars['Float'];
  hitInnerSingle7RateAverage: Scalars['Float'];
  hitInnerSingle8RateAverage: Scalars['Float'];
  hitInnerSingle9RateAverage: Scalars['Float'];
  hitInnerSingle10RateAverage: Scalars['Float'];
  hitInnerSingle11RateAverage: Scalars['Float'];
  hitInnerSingle12RateAverage: Scalars['Float'];
  hitInnerSingle13RateAverage: Scalars['Float'];
  hitInnerSingle14RateAverage: Scalars['Float'];
  hitInnerSingle15RateAverage: Scalars['Float'];
  hitInnerSingle16RateAverage: Scalars['Float'];
  hitInnerSingle17RateAverage: Scalars['Float'];
  hitInnerSingle18RateAverage: Scalars['Float'];
  hitInnerSingle19RateAverage: Scalars['Float'];
  hitInnerSingle20RateAverage: Scalars['Float'];
  hitOuterBullRateAverage: Scalars['Float'];
  hitOuterSingle1RateAverage: Scalars['Float'];
  hitOuterSingle2RateAverage: Scalars['Float'];
  hitOuterSingle3RateAverage: Scalars['Float'];
  hitOuterSingle4RateAverage: Scalars['Float'];
  hitOuterSingle5RateAverage: Scalars['Float'];
  hitOuterSingle6RateAverage: Scalars['Float'];
  hitOuterSingle7RateAverage: Scalars['Float'];
  hitOuterSingle8RateAverage: Scalars['Float'];
  hitOuterSingle9RateAverage: Scalars['Float'];
  hitOuterSingle10RateAverage: Scalars['Float'];
  hitOuterSingle11RateAverage: Scalars['Float'];
  hitOuterSingle12RateAverage: Scalars['Float'];
  hitOuterSingle13RateAverage: Scalars['Float'];
  hitOuterSingle14RateAverage: Scalars['Float'];
  hitOuterSingle15RateAverage: Scalars['Float'];
  hitOuterSingle16RateAverage: Scalars['Float'];
  hitOuterSingle17RateAverage: Scalars['Float'];
  hitOuterSingle18RateAverage: Scalars['Float'];
  hitOuterSingle19RateAverage: Scalars['Float'];
  hitOuterSingle20RateAverage: Scalars['Float'];
  hitTriple1RateAverage: Scalars['Float'];
  hitTriple2RateAverage: Scalars['Float'];
  hitTriple3RateAverage: Scalars['Float'];
  hitTriple4RateAverage: Scalars['Float'];
  hitTriple5RateAverage: Scalars['Float'];
  hitTriple6RateAverage: Scalars['Float'];
  hitTriple7RateAverage: Scalars['Float'];
  hitTriple8RateAverage: Scalars['Float'];
  hitTriple9RateAverage: Scalars['Float'];
  hitTriple10RateAverage: Scalars['Float'];
  hitTriple11RateAverage: Scalars['Float'];
  hitTriple12RateAverage: Scalars['Float'];
  hitTriple13RateAverage: Scalars['Float'];
  hitTriple14RateAverage: Scalars['Float'];
  hitTriple15RateAverage: Scalars['Float'];
  hitTriple16RateAverage: Scalars['Float'];
  hitTriple17RateAverage: Scalars['Float'];
  hitTriple18RateAverage: Scalars['Float'];
  hitTriple19RateAverage: Scalars['Float'];
  hitTriple20RateAverage: Scalars['Float'];
  innerBullCountDividedByWholeBullCountRateAverage: Scalars['Float'];
};

export enum TargetBullMode {
  Hit5 = 'Hit5',
  Hit10 = 'Hit10',
  Hit30 = 'Hit30',
  Hit50 = 'Hit50',
  Hit100 = 'Hit100',
  Round10 = 'Round10'
}

export type TargetBullOption = {
  __typename?: 'TargetBullOption';
  mode: TargetBullMode;
};

export type TargetBullOptionInput = {
  mode: TargetBullMode;
};

export type TargetBullPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  analysisData?: InputMaybe<TargetBullAnalysisDataInput>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type TargetBullUnitDataInput = {
  playerDatas: Array<TargetBullPlayerDataInput>;
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export enum TargetHatMode {
  Hit5 = 'Hit5',
  Hit10 = 'Hit10',
  Hit30 = 'Hit30',
  Hit50 = 'Hit50',
  Hit100 = 'Hit100'
}

export type TargetHatOptionInput = {
  mode: TargetHatMode;
};

export type TargetHatPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type TargetHatUnitDataInput = {
  playerDatas: Array<TargetHatPlayerDataInput>;
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export enum TargetHorseMode {
  Hit5 = 'Hit5',
  Hit10 = 'Hit10',
  Hit15 = 'Hit15',
  Hit25 = 'Hit25',
  Hit30 = 'Hit30'
}

export type TargetHorseOptionInput = {
  mode: TargetHorseMode;
};

export type TargetHorsePlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type TargetHorseUnitDataInput = {
  playerDatas: Array<TargetHorsePlayerDataInput>;
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export enum TargetSegment {
  Double1 = 'Double1',
  Double2 = 'Double2',
  Double3 = 'Double3',
  Double4 = 'Double4',
  Double5 = 'Double5',
  Double6 = 'Double6',
  Double7 = 'Double7',
  Double8 = 'Double8',
  Double9 = 'Double9',
  Double10 = 'Double10',
  Double11 = 'Double11',
  Double12 = 'Double12',
  Double13 = 'Double13',
  Double14 = 'Double14',
  Double15 = 'Double15',
  Double16 = 'Double16',
  Double17 = 'Double17',
  Double18 = 'Double18',
  Double19 = 'Double19',
  Double20 = 'Double20',
  InBull = 'InBull',
  InSingle1 = 'InSingle1',
  InSingle2 = 'InSingle2',
  InSingle3 = 'InSingle3',
  InSingle4 = 'InSingle4',
  InSingle5 = 'InSingle5',
  InSingle6 = 'InSingle6',
  InSingle7 = 'InSingle7',
  InSingle8 = 'InSingle8',
  InSingle9 = 'InSingle9',
  InSingle10 = 'InSingle10',
  InSingle11 = 'InSingle11',
  InSingle12 = 'InSingle12',
  InSingle13 = 'InSingle13',
  InSingle14 = 'InSingle14',
  InSingle15 = 'InSingle15',
  InSingle16 = 'InSingle16',
  InSingle17 = 'InSingle17',
  InSingle18 = 'InSingle18',
  InSingle19 = 'InSingle19',
  InSingle20 = 'InSingle20',
  OutBull = 'OutBull',
  OutSingle1 = 'OutSingle1',
  OutSingle2 = 'OutSingle2',
  OutSingle3 = 'OutSingle3',
  OutSingle4 = 'OutSingle4',
  OutSingle5 = 'OutSingle5',
  OutSingle6 = 'OutSingle6',
  OutSingle7 = 'OutSingle7',
  OutSingle8 = 'OutSingle8',
  OutSingle9 = 'OutSingle9',
  OutSingle10 = 'OutSingle10',
  OutSingle11 = 'OutSingle11',
  OutSingle12 = 'OutSingle12',
  OutSingle13 = 'OutSingle13',
  OutSingle14 = 'OutSingle14',
  OutSingle15 = 'OutSingle15',
  OutSingle16 = 'OutSingle16',
  OutSingle17 = 'OutSingle17',
  OutSingle18 = 'OutSingle18',
  OutSingle19 = 'OutSingle19',
  OutSingle20 = 'OutSingle20',
  Triple1 = 'Triple1',
  Triple2 = 'Triple2',
  Triple3 = 'Triple3',
  Triple4 = 'Triple4',
  Triple5 = 'Triple5',
  Triple6 = 'Triple6',
  Triple7 = 'Triple7',
  Triple8 = 'Triple8',
  Triple9 = 'Triple9',
  Triple10 = 'Triple10',
  Triple11 = 'Triple11',
  Triple12 = 'Triple12',
  Triple13 = 'Triple13',
  Triple14 = 'Triple14',
  Triple15 = 'Triple15',
  Triple16 = 'Triple16',
  Triple17 = 'Triple17',
  Triple18 = 'Triple18',
  Triple19 = 'Triple19',
  Triple20 = 'Triple20'
}

export type TargetTwentyAnalysisDataInput = {
  consecutiveHitCount: Scalars['Int'];
  finishRoundCount: Scalars['Int'];
  hitDouble1Rate?: InputMaybe<Scalars['Float']>;
  hitDouble2Rate?: InputMaybe<Scalars['Float']>;
  hitDouble3Rate?: InputMaybe<Scalars['Float']>;
  hitDouble4Rate?: InputMaybe<Scalars['Float']>;
  hitDouble5Rate?: InputMaybe<Scalars['Float']>;
  hitDouble6Rate?: InputMaybe<Scalars['Float']>;
  hitDouble7Rate?: InputMaybe<Scalars['Float']>;
  hitDouble8Rate?: InputMaybe<Scalars['Float']>;
  hitDouble9Rate?: InputMaybe<Scalars['Float']>;
  hitDouble10Rate?: InputMaybe<Scalars['Float']>;
  hitDouble11Rate?: InputMaybe<Scalars['Float']>;
  hitDouble12Rate?: InputMaybe<Scalars['Float']>;
  hitDouble13Rate?: InputMaybe<Scalars['Float']>;
  hitDouble14Rate?: InputMaybe<Scalars['Float']>;
  hitDouble15Rate?: InputMaybe<Scalars['Float']>;
  hitDouble16Rate?: InputMaybe<Scalars['Float']>;
  hitDouble17Rate?: InputMaybe<Scalars['Float']>;
  hitDouble18Rate?: InputMaybe<Scalars['Float']>;
  hitDouble19Rate?: InputMaybe<Scalars['Float']>;
  hitDouble20Rate?: InputMaybe<Scalars['Float']>;
  hitInnerBullRate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle1Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle2Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle3Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle4Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle5Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle6Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle7Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle8Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle9Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle10Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle11Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle12Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle13Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle14Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle15Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle16Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle17Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle18Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle19Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle20Rate?: InputMaybe<Scalars['Float']>;
  hitOuterBullRate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle1Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle2Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle3Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle4Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle5Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle6Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle7Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle8Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle9Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle10Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle11Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle12Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle13Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle14Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle15Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle16Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle17Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle18Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle19Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle20Rate?: InputMaybe<Scalars['Float']>;
  hitTriple1Rate?: InputMaybe<Scalars['Float']>;
  hitTriple2Rate?: InputMaybe<Scalars['Float']>;
  hitTriple3Rate?: InputMaybe<Scalars['Float']>;
  hitTriple4Rate?: InputMaybe<Scalars['Float']>;
  hitTriple5Rate?: InputMaybe<Scalars['Float']>;
  hitTriple6Rate?: InputMaybe<Scalars['Float']>;
  hitTriple7Rate?: InputMaybe<Scalars['Float']>;
  hitTriple8Rate?: InputMaybe<Scalars['Float']>;
  hitTriple9Rate?: InputMaybe<Scalars['Float']>;
  hitTriple10Rate?: InputMaybe<Scalars['Float']>;
  hitTriple11Rate?: InputMaybe<Scalars['Float']>;
  hitTriple12Rate?: InputMaybe<Scalars['Float']>;
  hitTriple13Rate?: InputMaybe<Scalars['Float']>;
  hitTriple14Rate?: InputMaybe<Scalars['Float']>;
  hitTriple15Rate?: InputMaybe<Scalars['Float']>;
  hitTriple16Rate?: InputMaybe<Scalars['Float']>;
  hitTriple17Rate?: InputMaybe<Scalars['Float']>;
  hitTriple18Rate?: InputMaybe<Scalars['Float']>;
  hitTriple19Rate?: InputMaybe<Scalars['Float']>;
  hitTriple20Rate?: InputMaybe<Scalars['Float']>;
  target20Rate: Scalars['Float'];
  target20TripleRate: Scalars['Float'];
};

export type TargetTwentyAnalysisResult = {
  __typename?: 'TargetTwentyAnalysisResult';
  consecutiveHitCountAverage: Scalars['Float'];
  finishRoundCountAverage: Scalars['Float'];
  hitDouble1RateAverage: Scalars['Float'];
  hitDouble2RateAverage: Scalars['Float'];
  hitDouble3RateAverage: Scalars['Float'];
  hitDouble4RateAverage: Scalars['Float'];
  hitDouble5RateAverage: Scalars['Float'];
  hitDouble6RateAverage: Scalars['Float'];
  hitDouble7RateAverage: Scalars['Float'];
  hitDouble8RateAverage: Scalars['Float'];
  hitDouble9RateAverage: Scalars['Float'];
  hitDouble10RateAverage: Scalars['Float'];
  hitDouble11RateAverage: Scalars['Float'];
  hitDouble12RateAverage: Scalars['Float'];
  hitDouble13RateAverage: Scalars['Float'];
  hitDouble14RateAverage: Scalars['Float'];
  hitDouble15RateAverage: Scalars['Float'];
  hitDouble16RateAverage: Scalars['Float'];
  hitDouble17RateAverage: Scalars['Float'];
  hitDouble18RateAverage: Scalars['Float'];
  hitDouble19RateAverage: Scalars['Float'];
  hitDouble20RateAverage: Scalars['Float'];
  hitInnerBullRateAverage: Scalars['Float'];
  hitInnerSingle1RateAverage: Scalars['Float'];
  hitInnerSingle2RateAverage: Scalars['Float'];
  hitInnerSingle3RateAverage: Scalars['Float'];
  hitInnerSingle4RateAverage: Scalars['Float'];
  hitInnerSingle5RateAverage: Scalars['Float'];
  hitInnerSingle6RateAverage: Scalars['Float'];
  hitInnerSingle7RateAverage: Scalars['Float'];
  hitInnerSingle8RateAverage: Scalars['Float'];
  hitInnerSingle9RateAverage: Scalars['Float'];
  hitInnerSingle10RateAverage: Scalars['Float'];
  hitInnerSingle11RateAverage: Scalars['Float'];
  hitInnerSingle12RateAverage: Scalars['Float'];
  hitInnerSingle13RateAverage: Scalars['Float'];
  hitInnerSingle14RateAverage: Scalars['Float'];
  hitInnerSingle15RateAverage: Scalars['Float'];
  hitInnerSingle16RateAverage: Scalars['Float'];
  hitInnerSingle17RateAverage: Scalars['Float'];
  hitInnerSingle18RateAverage: Scalars['Float'];
  hitInnerSingle19RateAverage: Scalars['Float'];
  hitInnerSingle20RateAverage: Scalars['Float'];
  hitOuterBullRateAverage: Scalars['Float'];
  hitOuterSingle1RateAverage: Scalars['Float'];
  hitOuterSingle2RateAverage: Scalars['Float'];
  hitOuterSingle3RateAverage: Scalars['Float'];
  hitOuterSingle4RateAverage: Scalars['Float'];
  hitOuterSingle5RateAverage: Scalars['Float'];
  hitOuterSingle6RateAverage: Scalars['Float'];
  hitOuterSingle7RateAverage: Scalars['Float'];
  hitOuterSingle8RateAverage: Scalars['Float'];
  hitOuterSingle9RateAverage: Scalars['Float'];
  hitOuterSingle10RateAverage: Scalars['Float'];
  hitOuterSingle11RateAverage: Scalars['Float'];
  hitOuterSingle12RateAverage: Scalars['Float'];
  hitOuterSingle13RateAverage: Scalars['Float'];
  hitOuterSingle14RateAverage: Scalars['Float'];
  hitOuterSingle15RateAverage: Scalars['Float'];
  hitOuterSingle16RateAverage: Scalars['Float'];
  hitOuterSingle17RateAverage: Scalars['Float'];
  hitOuterSingle18RateAverage: Scalars['Float'];
  hitOuterSingle19RateAverage: Scalars['Float'];
  hitOuterSingle20RateAverage: Scalars['Float'];
  hitTriple1RateAverage: Scalars['Float'];
  hitTriple2RateAverage: Scalars['Float'];
  hitTriple3RateAverage: Scalars['Float'];
  hitTriple4RateAverage: Scalars['Float'];
  hitTriple5RateAverage: Scalars['Float'];
  hitTriple6RateAverage: Scalars['Float'];
  hitTriple7RateAverage: Scalars['Float'];
  hitTriple8RateAverage: Scalars['Float'];
  hitTriple9RateAverage: Scalars['Float'];
  hitTriple10RateAverage: Scalars['Float'];
  hitTriple11RateAverage: Scalars['Float'];
  hitTriple12RateAverage: Scalars['Float'];
  hitTriple13RateAverage: Scalars['Float'];
  hitTriple14RateAverage: Scalars['Float'];
  hitTriple15RateAverage: Scalars['Float'];
  hitTriple16RateAverage: Scalars['Float'];
  hitTriple17RateAverage: Scalars['Float'];
  hitTriple18RateAverage: Scalars['Float'];
  hitTriple19RateAverage: Scalars['Float'];
  hitTriple20RateAverage: Scalars['Float'];
  target20RateAverage: Scalars['Float'];
  target20TripleRateAverage: Scalars['Float'];
};

export enum TargetTwentyMode {
  Hit5 = 'Hit5',
  Hit10 = 'Hit10',
  Hit30 = 'Hit30',
  Hit50 = 'Hit50',
  Hit100 = 'Hit100',
  Round10 = 'Round10'
}

export type TargetTwentyOption = {
  __typename?: 'TargetTwentyOption';
  mode: TargetTwentyMode;
};

export type TargetTwentyOptionInput = {
  mode: TargetTwentyMode;
};

export type TargetTwentyPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  analysisData?: InputMaybe<TargetTwentyAnalysisDataInput>;
  award: AwardInput;
  playerId: Scalars['Int'];
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type TargetTwentyUnitDataInput = {
  playerDatas: Array<TargetTwentyPlayerDataInput>;
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type TeamCricketOptionInput = {
  maxRound: Scalars['Int'];
};

export type TeamCricketPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  stats: CricketStatsInput;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type TeamCricketUnitDataInput = {
  playerDatas: Array<TeamCricketPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export enum ThrowOrder {
  Cork = 'Cork',
  First = 'First',
  Second = 'Second'
}

export enum ThrowOrderCondition {
  Cork = 'Cork',
  Random = 'Random',
  Receiver = 'Receiver',
  Requester = 'Requester'
}

export type TotalUncollectedIncomeOfShopInput = {
  shopId: Scalars['Int'];
};

export type UnitRating = {
  __typename?: 'UnitRating';
  ratings80: Array<RatingPerGame>;
  ratings100: Array<RatingPerGame>;
  unitId: Scalars['Int'];
};

export enum UnitType {
  LocalDoubles = 'LocalDoubles',
  RemoteDoubles = 'RemoteDoubles',
  Single = 'Single'
}

export type UnregisteredGuestInput = {
  guest_player_id: Scalars['Int'];
  host_player_id: Scalars['Int'];
};

export type UpdateMachineGameStatusInput = {
  cpuTemperature: Scalars['Float'];
  cpuUsage: Scalars['Float'];
  dummyPlayerId: Scalars['Int'];
  inGameStatus: InGameStatus;
  memoryUsage: Scalars['Float'];
};

export type UpdateOnlineUnitMemberSettingInput = {
  attributes: OnlineUnitMemberSettingAttributesInput;
  playerId: Scalars['Int'];
};

export type UpdateOnlineUnitSettingInput = {
  attributes: OnlineUnitSettingAttributesInput;
  unitId: Scalars['Int'];
};

export type UpdatePairInput = {
  cardBgImg?: InputMaybe<Scalars['Upload']>;
  cardBgIndex?: InputMaybe<Scalars['Int']>;
  pairId: Scalars['Int'];
};

export type UpdatePlayerInput = {
  calcBothBullOption?: InputMaybe<Scalars['Boolean']>;
  cardBgColor?: InputMaybe<CardBgColor>;
  cardBgImg?: InputMaybe<Scalars['Upload']>;
  cardBgIndex?: InputMaybe<Scalars['Int']>;
  iconImg?: InputMaybe<Scalars['Upload']>;
  playerId: Scalars['Int'];
  playerName?: InputMaybe<Scalars['String']>;
};

export type UpdatePlayerProfileInput = {
  bgImageUrl?: InputMaybe<Scalars['Upload']>;
  dartsHistory?: InputMaybe<Scalars['Date']>;
  favoriteGame?: InputMaybe<GameCodeForProfile>;
  favoriteMaker?: InputMaybe<Scalars['String']>;
  favoriteTarget?: InputMaybe<TargetSegment>;
  onlineTimeFrom?: InputMaybe<Scalars['Int']>;
  onlineTimeTo?: InputMaybe<Scalars['Int']>;
  playLocation?: InputMaybe<Scalars['String']>;
  playerId: Scalars['Int'];
  selfIntroduction?: InputMaybe<Scalars['String']>;
  sns?: InputMaybe<Array<UserSnsProfileInput>>;
};

export type UpdateProShopPasswordOfMachineInput = {
  password: Scalars['String'];
  shopId: Scalars['Int'];
};

export type UpdateStoreAwardMovieItemInput = {
  contentMovie?: InputMaybe<Scalars['Upload']>;
  iconImage?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  previewImage?: InputMaybe<Scalars['Upload']>;
  previewMovieUrl?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
};

export type UpdateStoreGameBackgroundItemInput = {
  contentImage?: InputMaybe<Scalars['Upload']>;
  iconImage?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  previewImage?: InputMaybe<Scalars['Upload']>;
  price?: InputMaybe<Scalars['Int']>;
};

export type UpdateStoreGameHitSoundItemInput = {
  contentDoubleHitSound?: InputMaybe<Scalars['Upload']>;
  contentSingleHitSound?: InputMaybe<Scalars['Upload']>;
  contentTripleHitSound?: InputMaybe<Scalars['Upload']>;
  iconImage?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  previewImage?: InputMaybe<Scalars['Upload']>;
  previewMovieUrl?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
};

export type UpdateStoreHitEffectItemInput = {
  iconImage?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  previewImage?: InputMaybe<Scalars['Upload']>;
  previewMovieUrl?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
};

export type UpdateStorePlayerChangeSoundItemInput = {
  contentPlayerDisplaySound?: InputMaybe<Scalars['Upload']>;
  contentRemoveDartsSound?: InputMaybe<Scalars['Upload']>;
  contentRoundDisplaySound?: InputMaybe<Scalars['Upload']>;
  iconImage?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  previewImage?: InputMaybe<Scalars['Upload']>;
  previewMovieUrl?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
};

export type UpdateStoreSoundItemInput = {
  contentSound?: InputMaybe<Scalars['Upload']>;
  iconImage?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
  previewImage?: InputMaybe<Scalars['Upload']>;
  previewMovieUrl?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
};

export type UpdateUserInput = {
  country_code: Scalars['String'];
  language_code: Scalars['String'];
  user_id: Scalars['Int'];
};

export type UpgradeProKitSubscriptionPlanInput = {
  proKitId: Scalars['Int'];
};

export type UploadGameBackgroundItemInput = {
  contentImage: Scalars['Upload'];
  gameCode: GameCode;
  iconImage: Scalars['Upload'];
  playerId: Scalars['Int'];
  previewImage: Scalars['Upload'];
};

export type UploadStoreAwardMovieItemInput = {
  awardType: AwardKey;
  contentMovie: Scalars['Upload'];
  iconImage: Scalars['Upload'];
  previewImage: Scalars['Upload'];
  previewMovieUrl: Scalars['String'];
  price: Scalars['Int'];
};

export type UploadStoreGameBackgroundItemInput = {
  contentImage: Scalars['Upload'];
  iconImage: Scalars['Upload'];
  previewImage: Scalars['Upload'];
  price: Scalars['Int'];
};

export type UploadStoreGameHitSoundItemInput = {
  contentDoubleHitSound: Scalars['Upload'];
  contentSingleHitSound: Scalars['Upload'];
  contentTripleHitSound: Scalars['Upload'];
  iconImage: Scalars['Upload'];
  previewImage: Scalars['Upload'];
  previewMovieUrl: Scalars['String'];
  price: Scalars['Int'];
};

export type UploadStoreHitEffectItemInput = {
  hitEffectType: HitEffectType;
  iconImage: Scalars['Upload'];
  previewImage: Scalars['Upload'];
  previewMovieUrl: Scalars['String'];
  price: Scalars['Int'];
  unityAddressableKey: Scalars['String'];
};

export type UploadStorePlayerChangeSoundItemInput = {
  contentPlayerDisplaySound?: InputMaybe<Scalars['Upload']>;
  contentRemoveDartsSound: Scalars['Upload'];
  contentRoundDisplaySound?: InputMaybe<Scalars['Upload']>;
  iconImage: Scalars['Upload'];
  previewImage: Scalars['Upload'];
  previewMovieUrl: Scalars['String'];
  price: Scalars['Int'];
};

export type UploadStoreSoundItemInput = {
  contentSound: Scalars['Upload'];
  iconImage: Scalars['Upload'];
  previewImage: Scalars['Upload'];
  previewMovieUrl: Scalars['String'];
  price: Scalars['Int'];
  soundType: SoundType;
};

export type UploadedByPlayerGameBackgroundItem = {
  __typename?: 'UploadedByPlayerGameBackgroundItem';
  contentImageUrl: Scalars['String'];
  customizeItemType: CustomizeItemType;
  gameCodeUploadedFor: GameCode;
  gameCodes: Array<GameCode>;
  iconImageUrl: Scalars['String'];
  id: Scalars['Int'];
  isInUse?: Maybe<Scalars['Boolean']>;
  managementType: CustomizeItemManagementType;
  previewImageUrl: Scalars['String'];
  uploadedPlayerId: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  active_player_132?: Maybe<Scalars['Int']>;
  active_player_155?: Maybe<Scalars['Int']>;
  active_player_steel?: Maybe<Scalars['Int']>;
  auth_board?: Maybe<Scalars['Boolean']>;
  cognito_id?: Maybe<Scalars['String']>;
  coin_balance?: Maybe<Scalars['Int']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  device: Array<UserDevice>;
  deviceId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  itemsPurchased?: Maybe<Array<Maybe<Item>>>;
  language?: Maybe<Language>;
  last_login_at?: Maybe<Scalars['Date']>;
  player: Array<Player>;
  status?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type UserBoardSerialCodeIssue = {
  __typename?: 'UserBoardSerialCodeIssue';
  board_id: Scalars['Int'];
  created_at?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  issued_qr_image_url: Scalars['String'];
  lot_number: Scalars['String'];
  serial_code: Scalars['String'];
  updated_at?: Maybe<Scalars['Date']>;
  user: User;
};

export type UserConnectionWithV2 = {
  __typename?: 'UserConnectionWithV2';
  v2Id?: Maybe<Scalars['Int']>;
};

export type UserDevice = {
  __typename?: 'UserDevice';
  appSetting?: Maybe<Scalars['JSON']>;
  appVersion?: Maybe<Scalars['String']>;
  bluetoothVersion?: Maybe<Scalars['String']>;
  boardSetting?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  deviceId?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  endpointArn?: Maybe<Scalars['String']>;
  firmwareVersion?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  osVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserInDb = {
  __typename?: 'UserInDb';
  cognito?: Maybe<CognitoUser>;
  db?: Maybe<DbUser>;
  intermediateDataForMigration?: Maybe<InterMediateDataForMigration>;
};

export type UserInDbInput = {
  email: Scalars['String'];
};

export type UserSession = {
  __typename?: 'UserSession';
  accessToken?: Maybe<CognitoAccessToken>;
  clockDrift?: Maybe<Scalars['Int']>;
  idToken?: Maybe<CognitoIdToken>;
  refreshToken?: Maybe<CognitoRefreshToken>;
};

export type UserSnsProfile = {
  __typename?: 'UserSnsProfile';
  profileUrl: Scalars['String'];
  serviceName: UserSnsServiceName;
};

export type UserSnsProfileInput = {
  profileUrl?: InputMaybe<Scalars['String']>;
  serviceName: UserSnsServiceName;
};

export enum UserSnsServiceName {
  Discord = 'Discord',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Line = 'Line',
  Tumblr = 'Tumblr',
  Twitter = 'Twitter',
  WeChat = 'WeChat',
  Weibo = 'Weibo'
}

export type UserWithSession = {
  __typename?: 'UserWithSession';
  session: UserSession;
  user: User;
};

export type VerificationPasswordInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type VerifyChangeEmailInput = {
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
  password: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type VerifyDealerEmailInput = {
  accessToken: Scalars['String'];
  code: Scalars['String'];
  dealerId: Scalars['Int'];
  newEmail: Scalars['String'];
  password: Scalars['String'];
};

export type VerifyDealerPasswordResetInput = {
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export type VerifyEmailInput = {
  email: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type VerifyShopEmailInput = {
  accessToken: Scalars['String'];
  code: Scalars['String'];
  newEmail: Scalars['String'];
  password: Scalars['String'];
  shopId: Scalars['Int'];
};

export type VerifyShopPasswordResetInput = {
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ViewChatMessagesInput = {
  args?: InputMaybe<PaginationInput>;
  playerId: Scalars['Int'];
  roomId: Scalars['Int'];
};

export type WebDealerLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type WebDistributorLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type WebShopLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type WildCardCricketOption = {
  __typename?: 'WildCardCricketOption';
  maxRound: Scalars['Int'];
};

export type WildCardCricketOptionInput = {
  maxRound: Scalars['Int'];
};

export type WildCardCricketPlay = {
  __typename?: 'WildCardCricketPlay';
  gameCode: GameCode;
  id: Scalars['ID'];
  isFinished: Scalars['Boolean'];
  option: WildCardCricketOption;
  units: Array<WildCardCricketPlayUnit>;
  winnerUnitId?: Maybe<Scalars['Int']>;
};

export type WildCardCricketPlayUnit = {
  __typename?: 'WildCardCricketPlayUnit';
  id: Scalars['Int'];
  playersAndResults: Array<WildCardCricketPlayerAndResult>;
  score?: Maybe<Scalars['Int']>;
  throwingIndex?: Maybe<Scalars['Int']>;
};

export type WildCardCricketPlayerAndResult = PlayerIdentity & {
  __typename?: 'WildCardCricketPlayerAndResult';
  countryCode?: Maybe<Scalars['String']>;
  granId: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  result?: Maybe<WildCardCricketPlayerResult>;
};

export type WildCardCricketPlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: AwardInput;
  playerId: Scalars['Int'];
  stats: CricketStatsInput;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type WildCardCricketPlayerResult = {
  __typename?: 'WildCardCricketPlayerResult';
  actualHitAreaKeys: Array<Scalars['Int']>;
  award: Award;
  stats: CricketStats;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export type WildCardCricketUnitDataInput = {
  playerDatas: Array<WildCardCricketPlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type WinAndLoseCount = {
  __typename?: 'WinAndLoseCount';
  cricket: WinAndLoseCountOfGame;
  zeroOne: WinAndLoseCountOfGame;
};

export type WinAndLoseCountInput = {
  opponentPlayerId: Scalars['Int'];
  playerId: Scalars['Int'];
};

export type WinAndLoseCountOfGame = {
  __typename?: 'WinAndLoseCountOfGame';
  lose: Scalars['Int'];
  win: Scalars['Int'];
};

export type ZeroOneActivity = {
  __typename?: 'ZeroOneActivity';
  playedGamesStatsList: Array<ZeroOneStats>;
  statsOfPreviousDay: ZeroOneStats;
};

export type ZeroOneAnalysisDataInput = {
  bullRate?: InputMaybe<Scalars['Float']>;
  bustCount: Scalars['Int'];
  checkout2TryCount?: InputMaybe<Scalars['Int']>;
  checkout4TryCount?: InputMaybe<Scalars['Int']>;
  checkout6TryCount?: InputMaybe<Scalars['Int']>;
  checkout8TryCount?: InputMaybe<Scalars['Int']>;
  checkout10TryCount?: InputMaybe<Scalars['Int']>;
  checkout12TryCount?: InputMaybe<Scalars['Int']>;
  checkout14TryCount?: InputMaybe<Scalars['Int']>;
  checkout16TryCount?: InputMaybe<Scalars['Int']>;
  checkout18TryCount?: InputMaybe<Scalars['Int']>;
  checkout20TryCount?: InputMaybe<Scalars['Int']>;
  checkout22TryCount?: InputMaybe<Scalars['Int']>;
  checkout24TryCount?: InputMaybe<Scalars['Int']>;
  checkout26TryCount?: InputMaybe<Scalars['Int']>;
  checkout28TryCount?: InputMaybe<Scalars['Int']>;
  checkout30TryCount?: InputMaybe<Scalars['Int']>;
  checkout32TryCount?: InputMaybe<Scalars['Int']>;
  checkout34TryCount?: InputMaybe<Scalars['Int']>;
  checkout36TryCount?: InputMaybe<Scalars['Int']>;
  checkout38TryCount?: InputMaybe<Scalars['Int']>;
  checkout40TryCount?: InputMaybe<Scalars['Int']>;
  checkout50TryCount?: InputMaybe<Scalars['Int']>;
  checkoutNumber?: InputMaybe<Scalars['Int']>;
  checkoutTryCount?: InputMaybe<Scalars['Int']>;
  firstNineDartsPPR?: InputMaybe<Scalars['Float']>;
  hasCompletedArrange: Scalars['Boolean'];
  hasCompletedFinish: Scalars['Boolean'];
  hasCompletedReduce: Scalars['Boolean'];
  hatChanceCount?: InputMaybe<Scalars['Int']>;
  hatTrickRate?: InputMaybe<Scalars['Float']>;
  highOffTryCount: Scalars['Int'];
  hitDouble1Rate?: InputMaybe<Scalars['Float']>;
  hitDouble2Rate?: InputMaybe<Scalars['Float']>;
  hitDouble3Rate?: InputMaybe<Scalars['Float']>;
  hitDouble4Rate?: InputMaybe<Scalars['Float']>;
  hitDouble5Rate?: InputMaybe<Scalars['Float']>;
  hitDouble6Rate?: InputMaybe<Scalars['Float']>;
  hitDouble7Rate?: InputMaybe<Scalars['Float']>;
  hitDouble8Rate?: InputMaybe<Scalars['Float']>;
  hitDouble9Rate?: InputMaybe<Scalars['Float']>;
  hitDouble10Rate?: InputMaybe<Scalars['Float']>;
  hitDouble11Rate?: InputMaybe<Scalars['Float']>;
  hitDouble12Rate?: InputMaybe<Scalars['Float']>;
  hitDouble13Rate?: InputMaybe<Scalars['Float']>;
  hitDouble14Rate?: InputMaybe<Scalars['Float']>;
  hitDouble15Rate?: InputMaybe<Scalars['Float']>;
  hitDouble16Rate?: InputMaybe<Scalars['Float']>;
  hitDouble17Rate?: InputMaybe<Scalars['Float']>;
  hitDouble18Rate?: InputMaybe<Scalars['Float']>;
  hitDouble19Rate?: InputMaybe<Scalars['Float']>;
  hitDouble20Rate?: InputMaybe<Scalars['Float']>;
  hitInnerBullRate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle1Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle2Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle3Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle4Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle5Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle6Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle7Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle8Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle9Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle10Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle11Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle12Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle13Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle14Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle15Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle16Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle17Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle18Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle19Rate?: InputMaybe<Scalars['Float']>;
  hitInnerSingle20Rate?: InputMaybe<Scalars['Float']>;
  hitOuterBullRate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle1Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle2Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle3Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle4Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle5Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle6Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle7Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle8Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle9Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle10Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle11Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle12Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle13Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle14Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle15Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle16Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle17Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle18Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle19Rate?: InputMaybe<Scalars['Float']>;
  hitOuterSingle20Rate?: InputMaybe<Scalars['Float']>;
  hitTriple1Rate?: InputMaybe<Scalars['Float']>;
  hitTriple2Rate?: InputMaybe<Scalars['Float']>;
  hitTriple3Rate?: InputMaybe<Scalars['Float']>;
  hitTriple4Rate?: InputMaybe<Scalars['Float']>;
  hitTriple5Rate?: InputMaybe<Scalars['Float']>;
  hitTriple6Rate?: InputMaybe<Scalars['Float']>;
  hitTriple7Rate?: InputMaybe<Scalars['Float']>;
  hitTriple8Rate?: InputMaybe<Scalars['Float']>;
  hitTriple9Rate?: InputMaybe<Scalars['Float']>;
  hitTriple10Rate?: InputMaybe<Scalars['Float']>;
  hitTriple11Rate?: InputMaybe<Scalars['Float']>;
  hitTriple12Rate?: InputMaybe<Scalars['Float']>;
  hitTriple13Rate?: InputMaybe<Scalars['Float']>;
  hitTriple14Rate?: InputMaybe<Scalars['Float']>;
  hitTriple15Rate?: InputMaybe<Scalars['Float']>;
  hitTriple16Rate?: InputMaybe<Scalars['Float']>;
  hitTriple17Rate?: InputMaybe<Scalars['Float']>;
  hitTriple18Rate?: InputMaybe<Scalars['Float']>;
  hitTriple19Rate?: InputMaybe<Scalars['Float']>;
  hitTriple20Rate?: InputMaybe<Scalars['Float']>;
  innerBullCountDividedByWholeBullCountRate?: InputMaybe<Scalars['Float']>;
  isHighOffSuccess: Scalars['Boolean'];
  lowTonRate?: InputMaybe<Scalars['Float']>;
  noBullRoundRate?: InputMaybe<Scalars['Float']>;
  over100RoundCount?: InputMaybe<Scalars['Int']>;
  over140RoundCount?: InputMaybe<Scalars['Int']>;
  roundCount: Scalars['Int'];
  target20Rate?: InputMaybe<Scalars['Float']>;
  throwCount: Scalars['Int'];
  throwCountToArrange: Scalars['Int'];
  throwCountToFinish: Scalars['Int'];
  throwCountToReduce: Scalars['Int'];
  ton80ChanceCount?: InputMaybe<Scalars['Int']>;
};

export type ZeroOneAnalysisResult = {
  __typename?: 'ZeroOneAnalysisResult';
  bullRateAverage?: Maybe<Scalars['Float']>;
  bustCountAverage: Scalars['Float'];
  checkout2SuccessCount?: Maybe<Scalars['Int']>;
  checkout2SuccessRate?: Maybe<Scalars['Float']>;
  checkout2TryCountTotal?: Maybe<Scalars['Int']>;
  checkout4SuccessCount?: Maybe<Scalars['Int']>;
  checkout4SuccessRate?: Maybe<Scalars['Float']>;
  checkout4TryCountTotal?: Maybe<Scalars['Int']>;
  checkout6SuccessCount?: Maybe<Scalars['Int']>;
  checkout6SuccessRate?: Maybe<Scalars['Float']>;
  checkout6TryCountTotal?: Maybe<Scalars['Int']>;
  checkout8SuccessCount?: Maybe<Scalars['Int']>;
  checkout8SuccessRate?: Maybe<Scalars['Float']>;
  checkout8TryCountTotal?: Maybe<Scalars['Int']>;
  checkout10SuccessCount?: Maybe<Scalars['Int']>;
  checkout10SuccessRate?: Maybe<Scalars['Float']>;
  checkout10TryCountTotal?: Maybe<Scalars['Int']>;
  checkout12SuccessCount?: Maybe<Scalars['Int']>;
  checkout12SuccessRate?: Maybe<Scalars['Float']>;
  checkout12TryCountTotal?: Maybe<Scalars['Int']>;
  checkout14SuccessCount?: Maybe<Scalars['Int']>;
  checkout14SuccessRate?: Maybe<Scalars['Float']>;
  checkout14TryCountTotal?: Maybe<Scalars['Int']>;
  checkout16SuccessCount?: Maybe<Scalars['Int']>;
  checkout16SuccessRate?: Maybe<Scalars['Float']>;
  checkout16TryCountTotal?: Maybe<Scalars['Int']>;
  checkout18SuccessCount?: Maybe<Scalars['Int']>;
  checkout18SuccessRate?: Maybe<Scalars['Float']>;
  checkout18TryCountTotal?: Maybe<Scalars['Int']>;
  checkout20SuccessCount?: Maybe<Scalars['Int']>;
  checkout20SuccessRate?: Maybe<Scalars['Float']>;
  checkout20TryCountTotal?: Maybe<Scalars['Int']>;
  checkout22SuccessCount?: Maybe<Scalars['Int']>;
  checkout22SuccessRate?: Maybe<Scalars['Float']>;
  checkout22TryCountTotal?: Maybe<Scalars['Int']>;
  checkout24SuccessCount?: Maybe<Scalars['Int']>;
  checkout24SuccessRate?: Maybe<Scalars['Float']>;
  checkout24TryCountTotal?: Maybe<Scalars['Int']>;
  checkout26SuccessCount?: Maybe<Scalars['Int']>;
  checkout26SuccessRate?: Maybe<Scalars['Float']>;
  checkout26TryCountTotal?: Maybe<Scalars['Int']>;
  checkout28SuccessCount?: Maybe<Scalars['Int']>;
  checkout28SuccessRate?: Maybe<Scalars['Float']>;
  checkout28TryCountTotal?: Maybe<Scalars['Int']>;
  checkout30SuccessCount?: Maybe<Scalars['Int']>;
  checkout30SuccessRate?: Maybe<Scalars['Float']>;
  checkout30TryCountTotal?: Maybe<Scalars['Int']>;
  checkout32SuccessCount?: Maybe<Scalars['Int']>;
  checkout32SuccessRate?: Maybe<Scalars['Float']>;
  checkout32TryCountTotal?: Maybe<Scalars['Int']>;
  checkout34SuccessCount?: Maybe<Scalars['Int']>;
  checkout34SuccessRate?: Maybe<Scalars['Float']>;
  checkout34TryCountTotal?: Maybe<Scalars['Int']>;
  checkout36SuccessCount?: Maybe<Scalars['Int']>;
  checkout36SuccessRate?: Maybe<Scalars['Float']>;
  checkout36TryCountTotal?: Maybe<Scalars['Int']>;
  checkout38SuccessCount?: Maybe<Scalars['Int']>;
  checkout38SuccessRate?: Maybe<Scalars['Float']>;
  checkout38TryCountTotal?: Maybe<Scalars['Int']>;
  checkout40SuccessCount?: Maybe<Scalars['Int']>;
  checkout40SuccessRate?: Maybe<Scalars['Float']>;
  checkout40TryCountTotal?: Maybe<Scalars['Int']>;
  checkout50SuccessCount?: Maybe<Scalars['Int']>;
  checkout50SuccessRate?: Maybe<Scalars['Float']>;
  checkout50TryCountTotal?: Maybe<Scalars['Int']>;
  checkoutSuccessCount?: Maybe<Scalars['Int']>;
  checkoutSuccessRate?: Maybe<Scalars['Float']>;
  checkoutTryCountTotal?: Maybe<Scalars['Int']>;
  firstNineDartsPPRAverage?: Maybe<Scalars['Float']>;
  hatChanceCountTotal?: Maybe<Scalars['Int']>;
  hatCountTotal?: Maybe<Scalars['Int']>;
  hatSuccessRate?: Maybe<Scalars['Float']>;
  hatTrickRateAverage?: Maybe<Scalars['Float']>;
  highOffCountTotal: Scalars['Int'];
  highOffSuccessRate: Scalars['Float'];
  highOffTryCountTotal: Scalars['Int'];
  hitDouble1RateAverage: Scalars['Float'];
  hitDouble2RateAverage: Scalars['Float'];
  hitDouble3RateAverage: Scalars['Float'];
  hitDouble4RateAverage: Scalars['Float'];
  hitDouble5RateAverage: Scalars['Float'];
  hitDouble6RateAverage: Scalars['Float'];
  hitDouble7RateAverage: Scalars['Float'];
  hitDouble8RateAverage: Scalars['Float'];
  hitDouble9RateAverage: Scalars['Float'];
  hitDouble10RateAverage: Scalars['Float'];
  hitDouble11RateAverage: Scalars['Float'];
  hitDouble12RateAverage: Scalars['Float'];
  hitDouble13RateAverage: Scalars['Float'];
  hitDouble14RateAverage: Scalars['Float'];
  hitDouble15RateAverage: Scalars['Float'];
  hitDouble16RateAverage: Scalars['Float'];
  hitDouble17RateAverage: Scalars['Float'];
  hitDouble18RateAverage: Scalars['Float'];
  hitDouble19RateAverage: Scalars['Float'];
  hitDouble20RateAverage: Scalars['Float'];
  hitInnerBullRateAverage?: Maybe<Scalars['Float']>;
  hitInnerSingle1RateAverage: Scalars['Float'];
  hitInnerSingle2RateAverage: Scalars['Float'];
  hitInnerSingle3RateAverage: Scalars['Float'];
  hitInnerSingle4RateAverage: Scalars['Float'];
  hitInnerSingle5RateAverage: Scalars['Float'];
  hitInnerSingle6RateAverage: Scalars['Float'];
  hitInnerSingle7RateAverage: Scalars['Float'];
  hitInnerSingle8RateAverage: Scalars['Float'];
  hitInnerSingle9RateAverage: Scalars['Float'];
  hitInnerSingle10RateAverage: Scalars['Float'];
  hitInnerSingle11RateAverage: Scalars['Float'];
  hitInnerSingle12RateAverage: Scalars['Float'];
  hitInnerSingle13RateAverage: Scalars['Float'];
  hitInnerSingle14RateAverage: Scalars['Float'];
  hitInnerSingle15RateAverage: Scalars['Float'];
  hitInnerSingle16RateAverage: Scalars['Float'];
  hitInnerSingle17RateAverage: Scalars['Float'];
  hitInnerSingle18RateAverage: Scalars['Float'];
  hitInnerSingle19RateAverage: Scalars['Float'];
  hitInnerSingle20RateAverage: Scalars['Float'];
  hitOuterBullRateAverage?: Maybe<Scalars['Float']>;
  hitOuterSingle1RateAverage: Scalars['Float'];
  hitOuterSingle2RateAverage: Scalars['Float'];
  hitOuterSingle3RateAverage: Scalars['Float'];
  hitOuterSingle4RateAverage: Scalars['Float'];
  hitOuterSingle5RateAverage: Scalars['Float'];
  hitOuterSingle6RateAverage: Scalars['Float'];
  hitOuterSingle7RateAverage: Scalars['Float'];
  hitOuterSingle8RateAverage: Scalars['Float'];
  hitOuterSingle9RateAverage: Scalars['Float'];
  hitOuterSingle10RateAverage: Scalars['Float'];
  hitOuterSingle11RateAverage: Scalars['Float'];
  hitOuterSingle12RateAverage: Scalars['Float'];
  hitOuterSingle13RateAverage: Scalars['Float'];
  hitOuterSingle14RateAverage: Scalars['Float'];
  hitOuterSingle15RateAverage: Scalars['Float'];
  hitOuterSingle16RateAverage: Scalars['Float'];
  hitOuterSingle17RateAverage: Scalars['Float'];
  hitOuterSingle18RateAverage: Scalars['Float'];
  hitOuterSingle19RateAverage: Scalars['Float'];
  hitOuterSingle20RateAverage: Scalars['Float'];
  hitTriple1RateAverage: Scalars['Float'];
  hitTriple2RateAverage: Scalars['Float'];
  hitTriple3RateAverage: Scalars['Float'];
  hitTriple4RateAverage: Scalars['Float'];
  hitTriple5RateAverage: Scalars['Float'];
  hitTriple6RateAverage: Scalars['Float'];
  hitTriple7RateAverage: Scalars['Float'];
  hitTriple8RateAverage: Scalars['Float'];
  hitTriple9RateAverage: Scalars['Float'];
  hitTriple10RateAverage: Scalars['Float'];
  hitTriple11RateAverage: Scalars['Float'];
  hitTriple12RateAverage: Scalars['Float'];
  hitTriple13RateAverage: Scalars['Float'];
  hitTriple14RateAverage: Scalars['Float'];
  hitTriple15RateAverage: Scalars['Float'];
  hitTriple16RateAverage: Scalars['Float'];
  hitTriple17RateAverage: Scalars['Float'];
  hitTriple18RateAverage: Scalars['Float'];
  hitTriple19RateAverage: Scalars['Float'];
  hitTriple20RateAverage?: Maybe<Scalars['Float']>;
  innerBullCountDividedByWholeBullCountRateAverage?: Maybe<Scalars['Float']>;
  lowTonRateAverage?: Maybe<Scalars['Float']>;
  noBullRoundRateAverage?: Maybe<Scalars['Float']>;
  over100RoundCountAverage?: Maybe<Scalars['Float']>;
  over140RoundCountAverage?: Maybe<Scalars['Float']>;
  ppd100Average: Scalars['Float'];
  ppd100Max: Scalars['Float'];
  ppr80Average: Scalars['Float'];
  ppr80Max: Scalars['Float'];
  target20RateAverage?: Maybe<Scalars['Float']>;
  throwCountToArrangeAverage?: Maybe<Scalars['Float']>;
  throwCountToFinishAverage?: Maybe<Scalars['Float']>;
  throwCountToReduceAverage?: Maybe<Scalars['Float']>;
  ton80ChanceCountTotal?: Maybe<Scalars['Int']>;
  ton80CountTotal?: Maybe<Scalars['Int']>;
  ton80RoundCountAverage?: Maybe<Scalars['Float']>;
  ton80SuccessRate?: Maybe<Scalars['Float']>;
};

export type ZeroOneAnalysisResultOptionInput = {
  continueMode?: InputMaybe<Scalars['Boolean']>;
  handicap?: InputMaybe<Scalars['Boolean']>;
  inCondition?: InputMaybe<ZeroOneInCondition>;
  maxRound?: InputMaybe<Scalars['Int']>;
  outCondition?: InputMaybe<ZeroOneOutCondition>;
  separatedBull?: InputMaybe<Scalars['Boolean']>;
  startScore: ZeroOneStartScore;
};

export enum ZeroOneInCondition {
  DoubleIn = 'DoubleIn',
  MasterIn = 'MasterIn',
  None = 'None'
}

export type ZeroOneOption = {
  __typename?: 'ZeroOneOption';
  continueMode: Scalars['Boolean'];
  handicap?: Maybe<Scalars['Boolean']>;
  inCondition: ZeroOneInCondition;
  maxRound: Scalars['Int'];
  outCondition: ZeroOneOutCondition;
  separatedBull: Scalars['Boolean'];
  startScore: ZeroOneStartScore;
};

export type ZeroOneOptionInput = {
  continueMode?: InputMaybe<Scalars['Boolean']>;
  handicap?: InputMaybe<Scalars['Boolean']>;
  inCondition?: InputMaybe<ZeroOneInCondition>;
  maxRound: Scalars['Int'];
  outCondition?: InputMaybe<ZeroOneOutCondition>;
  separatedBull?: InputMaybe<Scalars['Boolean']>;
  startScore: ZeroOneStartScore;
};

export enum ZeroOneOutCondition {
  DoubleOut = 'DoubleOut',
  MasterOut = 'MasterOut',
  None = 'None'
}

export type ZeroOnePlayerDataInput = {
  actualHitAreaKeys: Array<Scalars['Int']>;
  analysisData?: InputMaybe<ZeroOneAnalysisDataInput>;
  award: AwardInput;
  playerId: Scalars['Int'];
  stats: ZeroOneStatsInput;
  virtualHitAreaKeys: Array<Scalars['Int']>;
};

export enum ZeroOneStartScore {
  Z301 = 'Z301',
  Z501 = 'Z501',
  Z701 = 'Z701',
  Z901 = 'Z901',
  Z1101 = 'Z1101',
  Z1501 = 'Z1501'
}

export type ZeroOneStats = {
  __typename?: 'ZeroOneStats';
  ppd100: Scalars['Float'];
  ppr80: Scalars['Float'];
};

export type ZeroOneStatsInput = {
  ppd100: Scalars['Float'];
  ppr80: Scalars['Float'];
};

export type ZeroOneUnitDataInput = {
  playerDatas: Array<ZeroOnePlayerDataInput>;
  score: Scalars['Int'];
  throwingIndex: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type DisconnectSceneAndDateTime = {
  dateTime: Scalars['Date'];
  scene: Scalars['String'];
};
