import { useTranslation } from 'react-i18next';
import { Badge, Box, Flex, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { MachineDetailType } from '../MachineDetailType';
import Card from 'components/card/Card';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import React, { useState } from 'react';
import { GranProSubscriptionPlan } from 'types/graphql';
import { useUpgradeProKitSubscriptionPlanMutation } from 'graphql/mutations/machine/__generated__/upgradeProKitSubscriptionPlan.generated';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import { GetMachineByIdDocument } from 'graphql/queries/machine/__generated__/getMachineById.generated';
import { useDowngradeProKitSubscriptionPlanMutation } from 'graphql/mutations/machine/__generated__/downgradeProKitSubscriptionPlan.generated';
import EnterprisePlanSession from './EnterprisePlanSession';
import BasicPlanSession from './BasicPlanSession';
import { useSetProKitSubscriptionPlanAfterDemoMutation } from '../../../../../../graphql/mutations/machine/__generated__/setProKitSubscriptionPlanAfterDemo.generated';
import {
    isCurrentDateGreaterThanPeriodEnd,
    resolverSubscriptionPlanToStr,
} from '../../../../../../utils/resolver-enum';
import GranProEditBtn from '../../../../../../components/button/GranProEditBtn';
import { useEditable } from '../../../../../../components/hooks/useEditable';
import DownGradeProkitSuccessModal from './DownGradeProkitSuccessModal';
import UpgradeProkitSuccessModal from './UpgradeProkitSuccessModal';

export default function PlanCard(props: MachineDetailType) {
    const { machine } = props;

    return (
        <Card
            // mt={{ base: '20px' }}
            pt={8}
            pb={9}
            px={{ base: '20px', lg: '30px', '2xl': '30px' }}
            display="flex"
            flexDirection="column"
            gap={4}
        >
            <OtherRolePlanCard machine={machine} />
        </Card>
    );
}

function OtherRolePlanCard(props: MachineDetailType) {
    const { machine } = props;
    const { t } = useTranslation(['common', 'machine']);
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const freePlanColor = useColorModeValue('#FF61C0', 'brand');
    const textPlanColor = useColorModeValue('white', 'white');
    const enterprisePlanScheme = useColorModeValue('#9F7AEA', 'brand');
    const basicPlanScheme = useColorModeValue('#5193F5', 'brand');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');

    const {
        isOpen: isOpenDowngradeProKitSuccess,
        onOpen: onOpenDowngradeProKitSuccess,
        onClose: onCloseDowngradeProKitSuccess,
    } = useDisclosure();

    const {
        isOpen: isOpenUpgradeProKitSuccess,
        onOpen: onOpenUpgradeProKitSuccess,
        onClose: onCloseUpgradeProKitSuccess,
    } = useDisclosure();

    const {
        isOpen: isOpenMutationBoundaryResult,
        onOpen: onOpenMutationBoundaryResult,
        onClose: onCloseMutationBoundaryResult,
    } = useDisclosure();
    const { isEdit, onChangeEditable, onEditDisable, ...other } = useEditable(false);
    const { accountType } = useAuthContext();

    const [upgradeProKitSubscriptionPlanFunc, upgradeProKitSubscriptionPlanMutationResult] =
        useUpgradeProKitSubscriptionPlanMutation();

    const [downgradeProKitSubscriptionPlanFunc, downgradeProKitSubscriptionPlanMutationResult] =
        useDowngradeProKitSubscriptionPlanMutation();

    const [setProKitSubscriptionPlanAfterDemoFunc, setProKitSubscriptionPlanAfterDemoMutationResult] =
        useSetProKitSubscriptionPlanAfterDemoMutation();
    const onHandleUpgradeProKitPlan = async () => {
        try {
            await upgradeProKitSubscriptionPlanFunc({
                variables: {
                    input: {
                        proKitId: machine.proKit.id,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onChangeEditable();
                        onOpenUpgradeProKitSuccess();
                    }
                },
                onError: async () => {
                    onOpenMutationBoundaryResult();
                },
                refetchQueries: () => [
                    {
                        query: GetMachineByIdDocument,
                        variables: {
                            input: {
                                machineId: machine.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const onHandleDowngradeProKitPlan = async () => {
        try {
            await downgradeProKitSubscriptionPlanFunc({
                variables: {
                    input: {
                        proKitId: machine.proKit.id,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onChangeEditable();
                        onOpenDowngradeProKitSuccess();
                    }
                },
                onError: async () => {
                    onOpenMutationBoundaryResult();
                },
                refetchQueries: () => [
                    {
                        query: GetMachineByIdDocument,
                        variables: {
                            input: {
                                machineId: machine.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleSetProKitSubscriptionPlanAfterDemo = async (subscriptionPlan: GranProSubscriptionPlan) => {
        try {
            await setProKitSubscriptionPlanAfterDemoFunc({
                variables: {
                    input: {
                        proKitId: machine.proKit.id,
                        subscriptionPlan,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onChangeEditable();
                        onOpenMutationBoundaryResult();
                    }
                },
                onError: async () => {
                    onOpenMutationBoundaryResult();
                },
                refetchQueries: () => [
                    {
                        query: GetMachineByIdDocument,
                        variables: {
                            input: {
                                machineId: machine.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenMutationBoundaryResult}
            onClose={onCloseMutationBoundaryResult}
            onOpen={onOpenMutationBoundaryResult}
            mutationResult={
                machine.proKit?.demonstrationPeriodEndAt &&
                !isCurrentDateGreaterThanPeriodEnd(new Date(machine.proKit?.demonstrationPeriodEndAt))
                    ? setProKitSubscriptionPlanAfterDemoMutationResult
                    : machine.proKit?.subscriptionPlan === GranProSubscriptionPlan.Enterprise
                    ? downgradeProKitSubscriptionPlanMutationResult
                    : upgradeProKitSubscriptionPlanMutationResult
            }
            toastSuccessDescription={
                machine.proKit?.demonstrationPeriodEndAt &&
                !isCurrentDateGreaterThanPeriodEnd(new Date(machine.proKit?.demonstrationPeriodEndAt))
                    ? t('setProKitSubscriptionDescription', { ns: 'machine' })
                    : machine.proKit?.subscriptionPlan === GranProSubscriptionPlan.Enterprise
                    ? t('downgradeProKitDescription', { ns: 'machine' })
                    : t('upgradeProKitDescription', { ns: 'machine' })
            }
            toastSuccessTitle={
                machine.proKit?.demonstrationPeriodEndAt &&
                !isCurrentDateGreaterThanPeriodEnd(new Date(machine.proKit.demonstrationPeriodEndAt))
                    ? t('setProKitSubscriptionTitle', { ns: 'machine' })
                    : machine.proKit?.subscriptionPlan === GranProSubscriptionPlan.Enterprise
                    ? t('downgradeProKitTitle', { ns: 'machine' })
                    : t('upgradeProKitTitle', { ns: 'machine' })
            }
        >
            <Flex direction={'row'} justifyContent="space-between" alignItems="stretch" w={'100%'}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="20px" mb="4px">
                    {t('planOption1', { ns: 'common' })}
                </Text>
                {accountType === AccountType.dealer && (
                    <GranProEditBtn
                        isEdit={isEdit}
                        onChangeEditable={onChangeEditable}
                        disabled={accountType !== AccountType.dealer}
                        onEditDisable={onEditDisable}
                        {...other}
                    />
                )}
            </Flex>
            {!isEdit && (
                <Flex direction="row" justifyContent="flex-start" alignItems="center" mt={3} gap={5}>
                    <Box display="flex" minW="100px" alignItems="center" alignContent="center">
                        <Text color={textColorSecondary} fontSize="md" fontWeight="400">
                            {t('currentPlanWithoutFreeLabel', { ns: 'common' })}
                        </Text>
                    </Box>
                    {machine.proKit?.demonstrationPeriodEndAt &&
                    !isCurrentDateGreaterThanPeriodEnd(new Date(machine.proKit?.demonstrationPeriodEndAt)) ? (
                        <Badge
                            // ms="auto"
                            justifyContent="center"
                            alignItems="center"
                            alignSelf="center"
                            textAlign="center"
                            h="30px"
                            minW="100px"
                            backgroundColor={freePlanColor}
                            color={textPlanColor}
                            fontSize="sm"
                            borderRadius="6px"
                            textTransform="capitalize"
                        >
                            Free
                        </Badge>
                    ) : (
                        <Badge
                            justifyContent="center"
                            alignItems="center"
                            alignSelf="center"
                            textAlign="center"
                            h="28px"
                            minW="100px"
                            backgroundColor={
                                machine.proKit?.subscriptionPlan === GranProSubscriptionPlan.Enterprise
                                    ? enterprisePlanScheme
                                    : basicPlanScheme
                            }
                            color={textPlanColor}
                            fontSize="sm"
                            borderRadius="6px"
                            textTransform="capitalize"
                        >
                            {resolverSubscriptionPlanToStr(machine.proKit?.subscriptionPlan, t)}
                        </Badge>
                    )}
                </Flex>
            )}

            {machine.proKit?.subscriptionPlan &&
            machine.proKit.subscriptionPlan === GranProSubscriptionPlan.Enterprise ? (
                <EnterprisePlanSession
                    onUpgradeProKitSubscriptionPlan={onHandleUpgradeProKitPlan}
                    onSetProKitSubscriptionPlanAfterDemo={handleSetProKitSubscriptionPlanAfterDemo}
                    isEdit={isEdit}
                    machine={machine}
                    onDowngradeProKitSubscriptionPlan={onHandleDowngradeProKitPlan}
                />
            ) : (
                <BasicPlanSession
                    machine={machine}
                    isEdit={isEdit}
                    onUpgradeProKitSubscriptionPlan={onHandleUpgradeProKitPlan}
                />
            )}
            <DownGradeProkitSuccessModal
                isOpen={isOpenDowngradeProKitSuccess}
                onOpen={onOpenDowngradeProKitSuccess}
                onClose={onCloseDowngradeProKitSuccess}
                validFrom={machine.proKit?.subscriptionPlanSchedule?.validFrom}
            />
            <UpgradeProkitSuccessModal
                onOpen={onOpenUpgradeProKitSuccess}
                onClose={onCloseUpgradeProKitSuccess}
                isOpen={isOpenUpgradeProKitSuccess}
            />
        </ApolloMutationBoundary>
    );
}
