// noinspection DuplicatedCode

import { Button, Flex, Input, Select, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { DealerStatusFilter } from 'types/graphql';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { PATH_DASHBOARD } from 'paths';
import { useHistory } from 'react-router-dom';
import { ApolloQueryBoundary } from 'components/graphql/ApolloQueryBoundary';
import SkeletonTableRow from 'components/skeleton/SkeletonTableRow';
import DealersTableContainer from './DealersTableContainer';
import { PaginationState } from '@tanstack/react-table';
import { DefaultPageSize } from '../../../../../../constant/defaultValues';
import { useGetSubordinateDealersListOfPartnerQuery } from '../../../../../../graphql/queries/dealer/__generated__/getSubordinateDealersListOfPartner.generated';

// ----------------------------------------------------------------------

export default function SubordinateDealerList() {
    const { userId } = useAuthContext();
    const history = useHistory();
    // Style
    // ----------------------------------------------------------------------
    const [isLargerThan960] = useMediaQuery('(min-width: 960px)');
    const [isLargerThan320] = useMediaQuery('(min-width: 460px)');
    const { t } = useTranslation(['common']);
    const focusColor = useColorModeValue('#2B3674', 'white');
    // ----------------------------------------------------------------------

    const [paginationState, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: DefaultPageSize,
    });
    const [dealerStatus, setDealerStatus] = useState('default');
    const [provinceFilter, setProvinceFilter] = useState('');

    const queryResult = useGetSubordinateDealersListOfPartnerQuery({
        variables: {
            input: {
                partnerDealerId: userId,
                filterOption: {
                    status: dealerStatus === 'default' ? null : (dealerStatus as DealerStatusFilter),
                    province: provinceFilter === '' ? null : provinceFilter,
                },
                args: {
                    first: paginationState.pageSize,
                    after: `${Number(paginationState.pageSize * paginationState.pageIndex)}`,
                },
            },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });

    const handleChangeDealerStatus = (event: ChangeEvent<HTMLSelectElement>) => {
        const status =
            event.target.value === 'enabled'
                ? DealerStatusFilter.Enabled
                : event.target.value === 'outOfService'
                ? DealerStatusFilter.OutOfService
                : null;

        setDealerStatus && setDealerStatus(status);
    };

    const handleClickToNewDealer = () => {
        history.push(PATH_DASHBOARD.dealer.createNew);
    };

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: paginationState.pageSize,
        });
    }, [dealerStatus, provinceFilter]);

    return (
        <Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }} minH="30vh">
            <Flex
                w="100%"
                align={{ sm: 'flex-start', lg: 'stretch' }}
                pt={{ sm: '20px', lg: '30px' }}
                flexDirection={{ sm: 'column', lg: 'row' }}
                px={{ sm: '0px', lg: '50px' }}
            >
                <Flex
                    justifyContent={{ sm: 'space-around', md: 'flex-start', lg: 'flex-start' }}
                    w="100%"
                    mb={{ sm: '20px', md: '30px', lg: '30px' }}
                    px={{ sm: '10px', md: '30px', lg: '0px' }}
                >
                    <Select
                        fontSize="sm"
                        variant="main"
                        borderRadius="10px"
                        h="40px"
                        maxH="44px"
                        maxW={150}
                        onChange={(event) => handleChangeDealerStatus(event)}
                    >
                        <option value="default">{t('statusOption1', { ns: 'common' })}</option>
                        <option value="enabled">{t('statusOption2', { ns: 'common' })}</option>
                        <option value="outOfService">{t('statusOption4', { ns: 'common' })}</option>
                    </Select>

                    <Input
                        w={{ base: '100%', lg: '320px' }}
                        placeholder={t('searchProvinceHolder', { ns: 'common' })}
                        borderRadius="10px"
                        marginLeft={{ sm: '8px', md: '16px' }}
                        h="40px"
                        maxH="44px"
                        padding="8px"
                        _focus={{ borderColor: focusColor, borderWidth: '0.5px' }}
                        fontSize={'13px'}
                        value={provinceFilter}
                        onChange={(e) => setProvinceFilter(e.target.value)}
                    />
                </Flex>
                {isLargerThan320 && isLargerThan960 && (
                    <Button
                        fontSize="14px"
                        variant="brand"
                        fontWeight="500"
                        w={120}
                        maxH={40}
                        borderRadius="10"
                        backgroundColor="#4318FF"
                        onClick={handleClickToNewDealer}
                    >
                        {t('newDealer')}
                    </Button>
                )}
            </Flex>

            <ApolloQueryBoundary
                queryResult={queryResult}
                loadingComponent={<SkeletonTableRow />}
                render={({
                    data: {
                        get_subordinate_dealers_list_of_partner: { pageInfo, edges, totalCount },
                    },
                }) => (
                    <DealersTableContainer
                        totalCount={totalCount}
                        edges={edges}
                        pageInfo={pageInfo}
                        paginationState={paginationState}
                        setPagination={setPagination}
                    />
                )}
            />
        </Flex>
    );
}
