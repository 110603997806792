import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import illustration from 'assets/img/auth/auth.png';
import DefaultAuth from 'layouts/auth/variants/Default';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import AdminConsoleLogo from '../../../components/logo/AdminConsoleLogo';

function SignUp() {
    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                h="100%"
                alignItems="center"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                // px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', md: '14vh' }}
                flexDirection="column"
            >
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', md: '420px' }}
                    maxW="100%"
                    // background='transparent'
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                >
                    <AdminConsoleLogo />

                    <Box me="auto" justifyContent="center">
                        <Heading color={textColor} fontSize={{ base: '34px', lg: '36px' }} mb="10px">
                            Sign Up
                        </Heading>
                        <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
                            Enter your email and password to sign up!
                        </Text>
                    </Box>
                    <Box me="auto" justifyContent="center">
                        <Text mb="48px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
                            Dealer Name AAA
                        </Text>
                    </Box>
                    <FormControl>
                        <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            type="email"
                            placeholder="admin@gran-dart.com"
                            mb="24px"
                            size="lg"
                        />
                        <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                            Password<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size="md">
                            <Input
                                isRequired={true}
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '4px' }}
                                placeholder="Min. 8 characters"
                                mb="24px"
                                size="lg"
                                type={show ? 'text' : 'password'}
                            />
                            <InputRightElement display="flex" alignItems="center" mt="4px">
                                <Icon
                                    color={textColorSecondary}
                                    _hover={{ cursor: 'pointer' }}
                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                    onClick={handleClick}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <Flex justifyContent="space-between" align="center" mb="24px">
                            <FormControl display="flex" alignItems="start">
                                <Checkbox id="remember-login" colorScheme="brandScheme" me="10px" mt="3px" />
                                <FormLabel
                                    htmlFor="remember-login"
                                    mb="0"
                                    fontWeight="normal"
                                    color={textColor}
                                    fontSize="sm"
                                >
                                    By creating an account means you agree to the{' '}
                                    <Link href="https://simmmple.com/terms-of-service" fontWeight="500">
                                        Terms and Conditions,
                                    </Link>{' '}
                                    and our{' '}
                                    <Link href="https://simmmple.com/privacy-policy" fontWeight="500">
                                        Privacy Policy
                                    </Link>
                                </FormLabel>
                            </FormControl>
                        </Flex>
                        <Button variant="brand" fontSize="14px" fontWeight="500" w="100%" h="50" mb="24px">
                            Create my account
                        </Button>
                    </FormControl>
                    <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
                        <Text color={textColorDetails} fontWeight="400" fontSize="sm">
                            Already a member?
                            <NavLink to="/auth/sign-in">
                                <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                                    Sign in
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default SignUp;
