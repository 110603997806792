import {
    Box,
    Button,
    Flex,
    FormControl,
    Heading,
    PinInput,
    PinInputField,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import illustration from 'assets/img/auth/auth.png';
import DefaultAuth from 'layouts/auth/variants/Default';
import React from 'react';
import AdminConsoleLogo from '../../../components/logo/AdminConsoleLogo';

function VerificationDefault() {
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                h="100%"
                alignItems="center"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', md: '14vh' }}
                flexDirection="column"
            >
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', md: '495px' }}
                    maxW="100%"
                    // background='transparent'
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                >
                    <AdminConsoleLogo />
                    <Box me="auto" mb="34px">
                        <Heading
                            color={textColor}
                            fontSize="36px"
                            mb="16px"
                            mx={{ base: 'auto', lg: 'unset' }}
                            textAlign={{ base: 'center', lg: 'left' }}
                        >
                            2-Step Verification
                        </Heading>
                        <Text
                            color="gray.400"
                            fontSize="md"
                            maxW={{ base: '95%', md: '100%' }}
                            mx={{ base: 'auto', lg: 'unset' }}
                            textAlign={{ base: 'center', lg: 'left' }}
                        >
                            Enter your 2-Step Verification code to reset password!
                        </Text>
                    </Box>
                    <FormControl>
                        <Flex justify="center">
                            <PinInput mx="auto" otp>
                                <PinInputField
                                    fontSize="36px"
                                    color={textColor}
                                    borderRadius="16px"
                                    borderColor={borderColor}
                                    h={{ base: '63px', md: '70px' }}
                                    w={{ base: '63px', md: '70px' }}
                                    me="10px"
                                />
                                <PinInputField
                                    fontSize="36px"
                                    color={textColor}
                                    borderRadius="16px"
                                    borderColor={borderColor}
                                    h={{ base: '63px', md: '70px' }}
                                    w={{ base: '63px', md: '70px' }}
                                    me="10px"
                                />
                                <PinInputField
                                    fontSize="36px"
                                    color={textColor}
                                    borderRadius="16px"
                                    borderColor={borderColor}
                                    h={{ base: '63px', md: '70px' }}
                                    w={{ base: '63px', md: '70px' }}
                                    me="10px"
                                />
                                <PinInputField
                                    fontSize="36px"
                                    color={textColor}
                                    borderRadius="16px"
                                    borderColor={borderColor}
                                    h={{ base: '63px', md: '70px' }}
                                    w={{ base: '63px', md: '70px' }}
                                    me="10px"
                                />

                                <PinInputField
                                    fontSize="36px"
                                    color={textColor}
                                    borderRadius="16px"
                                    borderColor={borderColor}
                                    h={{ base: '63px', md: '70px' }}
                                    w={{ base: '63px', md: '70px' }}
                                    me="10px"
                                />

                                <PinInputField
                                    fontSize="36px"
                                    color={textColor}
                                    borderRadius="16px"
                                    borderColor={borderColor}
                                    h={{ base: '63px', md: '70px' }}
                                    w={{ base: '63px', md: '70px' }}
                                />
                            </PinInput>
                        </Flex>

                        <Button
                            fontSize="14px"
                            variant="brand"
                            borderRadius="16px"
                            fontWeight="500"
                            w="100%"
                            h="50"
                            mb="24px"
                            mt="12px"
                        >
                            Send code
                        </Button>
                    </FormControl>
                    <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
                        <Text
                            color={textColorDetails}
                            fontWeight="400"
                            fontSize="14px"
                            mx={{ base: 'auto', lg: 'unset' }}
                            textAlign={{ base: 'center', lg: 'left' }}
                        >
                            Haven't received it?
                            <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                                Resend a new code
                            </Text>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default VerificationDefault;
