import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProKitByIdQueryVariables = Types.Exact<{
    input: Types.ProKitInput;
}>;

export type GetProKitByIdQuery = {
    __typename?: 'Query';
    pro_kit: {
        __typename?: 'ProKit';
        id: number;
        modelName: string;
        serialCode: string;
        lastActiveAt?: Date | null;
        totalActivateCount: number;
        dealer?: { __typename?: 'Dealer'; id: number; name: string; iconUrl?: string | null } | null;
        shop?: { __typename?: 'Shop'; id: number; name: string; iconUrl?: string | null } | null;
        machine?: { __typename?: 'Machine'; id: number; machineName: string } | null;
    };
};

export const GetProKitByIdDocument = gql`
    query getProKitById($input: ProKitInput!) {
        pro_kit(input: $input) {
            id
            modelName
            serialCode
            lastActiveAt
            totalActivateCount
            dealer {
                id
                name
                iconUrl
            }
            shop {
                id
                name
                iconUrl
            }
            machine {
                id
                machineName
            }
        }
    }
`;

/**
 * __useGetProKitByIdQuery__
 *
 * To run a query within a React component, call `useGetProKitByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProKitByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProKitByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProKitByIdQuery(
    baseOptions: Apollo.QueryHookOptions<GetProKitByIdQuery, GetProKitByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetProKitByIdQuery, GetProKitByIdQueryVariables>(GetProKitByIdDocument, options);
}
export function useGetProKitByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetProKitByIdQuery, GetProKitByIdQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetProKitByIdQuery, GetProKitByIdQueryVariables>(GetProKitByIdDocument, options);
}
export type GetProKitByIdQueryHookResult = ReturnType<typeof useGetProKitByIdQuery>;
export type GetProKitByIdLazyQueryHookResult = ReturnType<typeof useGetProKitByIdLazyQuery>;
export type GetProKitByIdQueryResult = Apollo.QueryResult<GetProKitByIdQuery, GetProKitByIdQueryVariables>;
