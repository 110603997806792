import { Box, Button, Flex, Icon, Select, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from 'react';
import { ChangeEvent, useMemo, useState } from 'react';
import { BiTime } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { MachineDetailType } from '../MachineDetailType';
import { isEmpty } from 'lodash';
import StopTimeChargeConfirmModal from './StopTimeChargeConfirmModal';
import { calculateDateTimeDistanceInHours, fHhMm, isCurrentTimeBetweenDates } from 'utils/format-time';
import { RentHours } from '../../../../../../types/graphql';
import { ApolloMutationBoundary } from '../../../../../../components/graphql/ApolloMutationBoundary';
import { useRentMachineByHourMutation } from 'graphql/mutations/machine/__generated__/rentMachineByHour.generated';
import { useCancelHourlyRentalOfMachineMutation } from 'graphql/mutations/machine/__generated__/cancelHourlyRentalOfMachine.generated';
import { GetMachineByIdDocument } from '../../../../../../graphql/queries/machine/__generated__/getMachineById.generated';
import { useAuthContext } from '../../../../../../layouts/auth/useAuthContext';
import { AccountType } from '../../../../../../types/user';

type TimeChargeCardProps = MachineDetailType & {
    [key: string]: any;
};

export default function TimeChargeCard(props: TimeChargeCardProps) {
    const { machine, ...rest } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColor = useColorModeValue('#707EAE', 'white');
    const endTimeColor = useColorModeValue('#2B3674', 'white');
    const startBtnColor = useColorModeValue('#4318FF', '#4318FF');
    const stopBtnColor = useColorModeValue('#D11828', '#D11828');
    const { t } = useTranslation(['common', 'machine']);
    const { accountType } = useAuthContext();
    const [rentalHour, setRentalHour] = useState<RentHours>(RentHours.OneHour);

    const { isOpen: isOpenConfirmModal, onOpen: onOpenConfirmModal, onClose: onCloseConfirmModal } = useDisclosure();
    const {
        isOpen: isOpenStartStopRentalHourMutationResult,
        onOpen: onOpenStartStopRentalHourMutationResult,
        onClose: onCloseStartStopRentalHourMutationResult,
    } = useDisclosure();

    const isInPlayTime = useMemo(() => {
        const currentTime = new Date().toISOString();
        console.log('currentTime: ', currentTime);
        return (
            machine.freeModeStart &&
            machine.freeModeEnd &&
            isCurrentTimeBetweenDates(
                new Date(machine.freeModeStart).toISOString(),
                new Date(machine.freeModeEnd).toISOString(),
                new Date().toISOString(),
            )
        );
    }, [machine.freeModeStart, machine.freeModeEnd]);

    const machineFreePlay = useMemo(() => {
        const jsonObj = isEmpty(machine.gameSetting) ? {} : machine.gameSetting;
        let isFreePlay = false;

        if (isEmpty(jsonObj)) {
            return {
                freePlay: isFreePlay,
            };
        } else {
            isFreePlay = jsonObj?.freePlay && typeof jsonObj?.freePlay === 'boolean' ? jsonObj?.freePlay : false;
            return {
                freePlay: isFreePlay,
            };
        }
    }, [machine]);

    const [rentMachineByHourFunc, rentMachineByHourResult] = useRentMachineByHourMutation();
    const [cancelHourlyRentalOfMachineFunc, cancelHourlyRentalOfMachineResult] =
        useCancelHourlyRentalOfMachineMutation();

    const onClickStartRentMachineByHour = async () => {
        try {
            await rentMachineByHourFunc({
                variables: {
                    input: {
                        machineId: machine.id,
                        rentHours: rentalHour,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenStartStopRentalHourMutationResult();
                    }
                },
                onError: async () => {
                    onOpenStartStopRentalHourMutationResult();
                },
                refetchQueries: () => [
                    {
                        query: GetMachineByIdDocument,
                        variables: {
                            input: {
                                machineId: machine.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const onClickCancelHourlyRentalOfMachine = async () => {
        try {
            await cancelHourlyRentalOfMachineFunc({
                variables: {
                    input: {
                        machineId: machine.id,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onOpenStartStopRentalHourMutationResult();
                    }
                },
                onError: async () => {
                    onOpenStartStopRentalHourMutationResult();
                },
                refetchQueries: () => [
                    {
                        query: GetMachineByIdDocument,
                        variables: {
                            input: {
                                machineId: machine.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const onHandleConfirmCancel = async () => {
        await onClickCancelHourlyRentalOfMachine();
        onCloseConfirmModal();
    };

    const handleChangeRentalHourOption = (event: ChangeEvent<HTMLSelectElement>) => {
        setRentalHour && setRentalHour(event.target.value as RentHours);
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenStartStopRentalHourMutationResult}
            onClose={onCloseStartStopRentalHourMutationResult}
            onOpen={onOpenStartStopRentalHourMutationResult}
            mutationResult={isInPlayTime ? cancelHourlyRentalOfMachineResult : rentMachineByHourResult}
            toastSuccessTitle={
                isInPlayTime
                    ? t('cancelHourlyRentalOfMachineSuccessTitle', { ns: 'machine' })
                    : t('rentMachineByHourSuccessTitle', { ns: 'machine' })
            }
            toastSuccessDescription={
                isInPlayTime
                    ? t('cancelHourlyRentalOfMachineSuccessDescription', { ns: 'machine' })
                    : t('rentMachineByHourSuccessDescription', { ns: 'machine' })
            }
        >
            <Card py={8} px={{ base: '20px', lg: '30px', '2xl': '30px' }} {...rest}>
                <Flex direction={'row'} justifyContent="space-between" alignItems="stretch" w={'100%'}>
                    <Text color={textColorPrimary} fontWeight="bold" fontSize="20px" mt="10px" mb="4px">
                        {t('timeChargeLabel', { ns: 'machine' })}
                    </Text>
                </Flex>
                {machineFreePlay.freePlay ? (
                    <Text py={6} textAlign="left" color={textColor} fontSize="md" fontWeight="500">
                        {t('timeChargeMessageOnFreePlay', { ns: 'machine' })}
                    </Text>
                ) : isInPlayTime ? (
                    <Flex direction={'column'} mt={5} gap={1.5}>
                        <Flex direction="row" justifyContent="flex-start" alignItems="stretch">
                            <Text color={textColor} fontSize="sm" fontWeight="500">
                                {`${t('timeChargeStartTimeLabel', { ns: 'machine' })}: ${fHhMm(machine.freeModeStart)}`}
                            </Text>
                        </Flex>

                        <Flex direction="row" justifyContent="flex-start" alignItems="stretch">
                            <Text color={textColor} fontSize="sm" fontWeight="500">
                                {`${t('timeChargePlayTimeLabel', {
                                    ns: 'machine',
                                })}: ${calculateDateTimeDistanceInHours(
                                    new Date(machine.freeModeStart).toISOString(),
                                    new Date(machine.freeModeEnd).toISOString(),
                                )} ${t('hourOption4', { ns: 'machine' })}`}
                            </Text>
                        </Flex>

                        <Flex
                            direction={{ base: 'column', md: 'row' }}
                            justifyContent={{ base: 'center', md: 'flex-start' }}
                            alignItems="center"
                            mt={5}
                            gap={5}
                            w="100%"
                        >
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Icon as={BiTime} w="30px" h="30px" color={textColor} mr={2} />

                                <Text color={endTimeColor} fontSize="md" fontWeight="500">
                                    {`${t('timeChargeEndTimeLabel', { ns: 'machine' })}: ${fHhMm(machine.freeModeEnd)}`}
                                </Text>
                            </Box>

                            <Box>
                                {(accountType === AccountType.dealer || accountType === AccountType.shop) && (
                                    <Button
                                        py="0px"
                                        px="4px"
                                        variant="brand"
                                        fontWeight="500"
                                        minW={'120px'}
                                        h={'38px'}
                                        borderRadius="10px"
                                        backgroundColor={stopBtnColor}
                                        onClick={onOpenConfirmModal}
                                    >
                                        <Text fontSize="16px">{t('stopBtnLabel', { ns: 'machine' })}</Text>
                                    </Button>
                                )}
                            </Box>
                        </Flex>
                    </Flex>
                ) : (
                    <Flex direction={'column'} mt={5} gap={1.5}>
                        <Flex direction="row" justifyContent="flex-start" alignItems="stretch">
                            <Text color={textColor} fontSize="sm" fontWeight="500">
                                {`${t('rentalRemotelyMessage', { ns: 'machine' })}`}
                            </Text>
                        </Flex>

                        {accountType === AccountType.dealer || accountType === AccountType.shop ? (
                            <Flex
                                direction={{ base: 'column', md: 'row' }}
                                justifyContent={{ base: 'center', md: 'flex-start' }}
                                alignItems="center"
                                mt={5}
                                gap={5}
                                w="100%"
                            >
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Icon as={BiTime} w="30px" h="30px" color={textColor} mr={2} />
                                    <Select
                                        fontSize="sm"
                                        variant="main"
                                        borderRadius={'10px'}
                                        w={'200px'}
                                        h={'40px'}
                                        color={textColorPrimary}
                                        textColor={textColor}
                                        fontWeight={700}
                                        defaultValue={RentHours.OneHour}
                                        onChange={(e) => handleChangeRentalHourOption(e)}
                                    >
                                        <option value={RentHours.OneHour}>{t('hourOption1', { ns: 'machine' })}</option>
                                        <option value={RentHours.TwoHour}>{t('hourOption2', { ns: 'machine' })}</option>
                                        <option value={RentHours.ThreeHour}>
                                            {t('hourOption3', { ns: 'machine' })}
                                        </option>
                                    </Select>
                                </Box>

                                <Box>
                                    <Button
                                        p="0px"
                                        ms="auto"
                                        variant="brand"
                                        fontWeight="500"
                                        minW={'120px'}
                                        h={'38px'}
                                        borderRadius="10px"
                                        backgroundColor={startBtnColor}
                                        onClick={onClickStartRentMachineByHour}
                                    >
                                        <Text fontSize="16px">{t('start', { ns: 'common' })}</Text>
                                    </Button>
                                </Box>
                            </Flex>
                        ) : (
                            <Flex direction="row" justifyContent="flex-start" alignItems="stretch">
                                <Text color={textColor} fontSize="sm" fontWeight="500">
                                    {`${t('apiControlByDealerAndShopMessage', { ns: 'machine' })}`}
                                </Text>
                            </Flex>
                        )}
                    </Flex>
                )}
                <StopTimeChargeConfirmModal
                    isOpen={isOpenConfirmModal}
                    onClose={onCloseConfirmModal}
                    onOpen={onOpenConfirmModal}
                    onHandleStop={onHandleConfirmCancel}
                />
            </Card>
        </ApolloMutationBoundary>
    );
}
