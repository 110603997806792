//
import { avatar, firstName, fullName, lastName, province } from './dummy';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const _mock = {
    id: (index: number) => `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${index + 1}`,
    name: {
        firstName: (index: number) => firstName[index],
        lastName: (index: number) => lastName[index],
        fullName: (index: number) => fullName[index],
    },
    province: () => sample(province),
    avatar: () => sample(avatar),
};

export default _mock;
