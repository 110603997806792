import { Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import * as React from 'react';
import { DealerDetailProps } from './dealerDetailTypes';
import { useTranslation } from 'react-i18next';
import GranProEditBtn from '../../../../../../components/button/GranProEditBtn';
import { useEditable } from '../../../../../../components/hooks/useEditable';
import ChangeDealerPasswordControlFeature from './ChangeDealerPasswordControlFeature';

export default function DealerSignInCard(props: DealerDetailProps) {
    const { dealer } = props;
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const btnColorScheme = useColorModeValue('#4318FF', 'white');
    const { isEdit, onChangeEditable, onEditDisable, ...other } = useEditable(false);
    const { t } = useTranslation(['common']);

    return (
        <Card py={8} px={{ base: '20px', lg: '30px', xl: '40px', '2xl': '50px' }} borderRadius="30px">
            <Flex direction="row" justifyContent="space-between" alignItems="stretch" w={'100%'}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                    {t('signInInfoLabel', { ns: 'common' })}
                </Text>
                <GranProEditBtn
                    isEdit={isEdit}
                    onChangeEditable={onChangeEditable}
                    onEditDisable={onEditDisable}
                    // onExtraHandleCancel={reset}
                    {...other}
                />
            </Flex>

            <Flex alignItems="flex-start" direction="column" gap={5} justifyContent="center" w="100%" mt={5} pb={2}>
                {!isEdit && (
                    <HStack alignItems="center" mt={4}>
                        <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                            {t('emailLabel', { ns: 'common' })}
                        </Text>
                        <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                            {dealer.email}
                        </Text>
                    </HStack>
                )}
                {isEdit ? (
                    <ChangeDealerPasswordControlFeature dealer={dealer} onEditDisable={onEditDisable} />
                ) : (
                    <HStack alignItems="center">
                        <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                            {t('passwordLabel', { ns: 'common' })}
                        </Text>
                        <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                            *********
                        </Text>
                    </HStack>
                )}
            </Flex>
        </Card>
    );
}
