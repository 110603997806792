import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveSubordinateDealerFromPartnerMutationVariables = Types.Exact<{
    input: Types.RemoveSubordinateDealerFromPartnerInput;
}>;

export type RemoveSubordinateDealerFromPartnerMutation = {
    __typename?: 'Mutation';
    remove_subordinate_dealer_from_partner: Types.SuccessResponse;
};

export const RemoveSubordinateDealerFromPartnerDocument = gql`
    mutation removeSubordinateDealerFromPartner($input: RemoveSubordinateDealerFromPartnerInput!) {
        remove_subordinate_dealer_from_partner(input: $input)
    }
`;
export type RemoveSubordinateDealerFromPartnerMutationFn = Apollo.MutationFunction<
    RemoveSubordinateDealerFromPartnerMutation,
    RemoveSubordinateDealerFromPartnerMutationVariables
>;

/**
 * __useRemoveSubordinateDealerFromPartnerMutation__
 *
 * To run a mutation, you first call `useRemoveSubordinateDealerFromPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubordinateDealerFromPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubordinateDealerFromPartnerMutation, { data, loading, error }] = useRemoveSubordinateDealerFromPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSubordinateDealerFromPartnerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RemoveSubordinateDealerFromPartnerMutation,
        RemoveSubordinateDealerFromPartnerMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        RemoveSubordinateDealerFromPartnerMutation,
        RemoveSubordinateDealerFromPartnerMutationVariables
    >(RemoveSubordinateDealerFromPartnerDocument, options);
}
export type RemoveSubordinateDealerFromPartnerMutationHookResult = ReturnType<
    typeof useRemoveSubordinateDealerFromPartnerMutation
>;
export type RemoveSubordinateDealerFromPartnerMutationResult =
    Apollo.MutationResult<RemoveSubordinateDealerFromPartnerMutation>;
export type RemoveSubordinateDealerFromPartnerMutationOptions = Apollo.BaseMutationOptions<
    RemoveSubordinateDealerFromPartnerMutation,
    RemoveSubordinateDealerFromPartnerMutationVariables
>;
