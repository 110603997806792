import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendMachineResetNotificationMutationVariables = Types.Exact<{
    input: Types.SendMachineResetNotificationInput;
}>;

export type SendMachineResetNotificationMutation = {
    __typename?: 'Mutation';
    send_machine_reset_notification: Types.SuccessResponse;
};

export const SendMachineResetNotificationDocument = gql`
    mutation sendMachineResetNotification($input: SendMachineResetNotificationInput!) {
        send_machine_reset_notification(input: $input)
    }
`;
export type SendMachineResetNotificationMutationFn = Apollo.MutationFunction<
    SendMachineResetNotificationMutation,
    SendMachineResetNotificationMutationVariables
>;

/**
 * __useSendMachineResetNotificationMutation__
 *
 * To run a mutation, you first call `useSendMachineResetNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMachineResetNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMachineResetNotificationMutation, { data, loading, error }] = useSendMachineResetNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMachineResetNotificationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SendMachineResetNotificationMutation,
        SendMachineResetNotificationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SendMachineResetNotificationMutation, SendMachineResetNotificationMutationVariables>(
        SendMachineResetNotificationDocument,
        options,
    );
}
export type SendMachineResetNotificationMutationHookResult = ReturnType<typeof useSendMachineResetNotificationMutation>;
export type SendMachineResetNotificationMutationResult = Apollo.MutationResult<SendMachineResetNotificationMutation>;
export type SendMachineResetNotificationMutationOptions = Apollo.BaseMutationOptions<
    SendMachineResetNotificationMutation,
    SendMachineResetNotificationMutationVariables
>;
