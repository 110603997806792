import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProKitListOfDealerQueryVariables = Types.Exact<{
    input: Types.GetProKitListOfDealerInput;
}>;

export type GetProKitListOfDealerQuery = {
    __typename?: 'Query';
    get_pro_kit_list_of_dealer: {
        __typename?: 'ProKitConnection';
        totalCount: number;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
        edges: Array<{
            __typename?: 'ProKitEdge';
            cursor: string;
            node?: {
                __typename?: 'ProKit';
                id: number;
                serialCode: string;
                modelName: string;
                demonstrationPeriodEndAt?: Date | null;
                subscriptionPlan?: Types.GranProSubscriptionPlan | null;
                status: Types.ProKitStatus;
                isActivated: boolean;
                lastActiveAt?: Date | null;
                totalActivateCount: number;
                dealer?: { __typename?: 'Dealer'; id: number; name: string; iconUrl?: string | null } | null;
            } | null;
        } | null>;
    };
};

export const GetProKitListOfDealerDocument = gql`
    query getProKitListOfDealer($input: GetProKitListOfDealerInput!) {
        get_pro_kit_list_of_dealer(input: $input) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    serialCode
                    modelName
                    demonstrationPeriodEndAt
                    subscriptionPlan
                    status
                    isActivated
                    lastActiveAt
                    totalActivateCount
                    dealer {
                        id
                        name
                        iconUrl
                    }
                }
            }
        }
    }
`;

/**
 * __useGetProKitListOfDealerQuery__
 *
 * To run a query within a React component, call `useGetProKitListOfDealerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProKitListOfDealerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProKitListOfDealerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProKitListOfDealerQuery(
    baseOptions: Apollo.QueryHookOptions<GetProKitListOfDealerQuery, GetProKitListOfDealerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetProKitListOfDealerQuery, GetProKitListOfDealerQueryVariables>(
        GetProKitListOfDealerDocument,
        options,
    );
}
export function useGetProKitListOfDealerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetProKitListOfDealerQuery, GetProKitListOfDealerQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetProKitListOfDealerQuery, GetProKitListOfDealerQueryVariables>(
        GetProKitListOfDealerDocument,
        options,
    );
}
export type GetProKitListOfDealerQueryHookResult = ReturnType<typeof useGetProKitListOfDealerQuery>;
export type GetProKitListOfDealerLazyQueryHookResult = ReturnType<typeof useGetProKitListOfDealerLazyQuery>;
export type GetProKitListOfDealerQueryResult = Apollo.QueryResult<
    GetProKitListOfDealerQuery,
    GetProKitListOfDealerQueryVariables
>;
