import { Text } from '@chakra-ui/react';
import * as React from 'react';
import { ColumnHelper } from '@tanstack/react-table';
import { GameSettingObj } from './GameSettingTable';
import { RHFNumberSelect } from 'components/hook-form/RHFNumberSelect';
import { TFunction } from 'i18next';
import { resolverMaxRound, resolverMinRound } from './ganeUtil';

export const CreateGameTableColumn = (
    columnHelper: ColumnHelper<GameSettingObj>,
    textColorPrimary: string,
    isEdit: boolean,
    tabValue: string,
    t: TFunction,
) => [
    columnHelper.accessor('gameType', {
        id: 'gameType',
        header: () => (
            <Text
                justifyContent="space-between"
                align={{ base: 'start', lg: 'center', '2xl': 'center' }}
                fontSize={{ sm: '10px', lg: '12px' }}
                color="gray.400"
                textTransform="capitalize"
            >
                {t('gameLabel', { ns: 'common' })}
            </Text>
        ),
        cell: (info: any) => (
            <Text color={textColorPrimary} fontSize="sm" fontWeight="600">
                {info.getValue()}
            </Text>
        ),
    }),
    columnHelper.accessor('round', {
        id: 'round',
        header: () => (
            <Text
                justifyContent="space-between"
                align="center"
                fontSize={{ sm: '10px', lg: '12px' }}
                color="gray.400"
                textTransform="capitalize"
            >
                {t('roundLabel', { ns: 'common' })}
            </Text>
        ),
        cell: (info) => {
            // console.log('info: ', `${tabValue}.${info.row.id}`);
            if (isEdit) {
                return (
                    <RHFNumberSelect
                        flexKey={info.row.id}
                        min={resolverMinRound(`${tabValue}.${info.row.id}`)}
                        max={resolverMaxRound(`${tabValue}.${info.row.id}`)}
                        name={`${tabValue}.${info.row.id}.round`}
                    />
                );
            } else {
                return (
                    <Text color={textColorPrimary} fontSize="sm" fontWeight="500">
                        {info.getValue()}
                    </Text>
                );
            }
        },
    }),
    columnHelper.accessor('credit', {
        id: 'credit',
        header: () => (
            <Text
                justifyContent="space-between"
                align="center"
                fontSize={{ sm: '10px', lg: '12px' }}
                color="gray.400"
                textTransform="capitalize"
            >
                {t('creditLabel', { ns: 'common' })}
            </Text>
        ),
        cell: (info) => {
            if (isEdit) {
                return (
                    <RHFNumberSelect flexKey={info.row.id} min={1} max={5} name={`${tabValue}.${info.row.id}.credit`} />
                );
            } else {
                return (
                    <Text color={textColorPrimary} fontSize="sm" fontWeight="500">
                        {info.getValue()}
                    </Text>
                );
            }
        },
    }),
];
