import {
    Button,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import { IoCheckmarkDoneCircleSharp } from 'react-icons/io5';

type SuccessChangePwModalProps = UseDisclosureProps & {
    onExtraHandle?: () => void;
};

export default function SuccessChangePwModal(props: SuccessChangePwModalProps) {
    const { isOpen, onClose, onExtraHandle } = props;
    const textPrimaryColor = useColorModeValue('black', 'white');
    const handleSuccessClose = () => {
        onClose();
        if (onExtraHandle) {
            onExtraHandle();
        }
    };

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Request change password</ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" alignItems="center" justifyContent="center" py={4}>
                    <VStack spacing={5} alignItems="center">
                        <Icon as={IoCheckmarkDoneCircleSharp} w="80px" h="80px" color="green.500" />
                        <VStack px={2} spacing={0}>
                            <Text color={textPrimaryColor} fontSize="16px" fontWeight="500" textAlign="center">
                                You've just made requested change password successfully
                            </Text>
                        </VStack>
                    </VStack>
                </ModalBody>

                <ModalFooter display="flex" alignItems="center" justifyContent="center" pb={10}>
                    <HStack>
                        <Button
                            colorScheme="gray.500"
                            variant="outline"
                            fontSize="sm"
                            color={'gray.500'}
                            w="120px"
                            borderRadius={10}
                            fontWeight="500"
                            ms="auto"
                            onClick={handleSuccessClose}
                        >
                            OK
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
