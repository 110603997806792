import {
    Button,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    UseDisclosureProps,
    VStack,
} from '@chakra-ui/react';
import { SendMailIcon, WarningIcon } from '../icons/Icons';

export type CreateNewResultModalCommonProps = UseDisclosureProps & {
    onHandleConfirm: () => void;
    // btnColorScheme?: ThemeTypings['colorSchemes'];
    confirmBtnLabel?: string;
    isSuccess?: boolean;
    modalLabel: {
        modalHeader?: string;
        modalBodyMessage1: string;
        modalBodyMessage2?: string;
        modalBodyMessage3?: string;
        [key: string]: string;
    };
};

export default function CreateNewResultModalCommon(props: CreateNewResultModalCommonProps) {
    const { isOpen, onClose, isSuccess = true, modalLabel, confirmBtnLabel = 'Close', onHandleConfirm } = props;
    // const textPrimaryColor = useColorModeValue('navy.650', 'white');
    const textPrimaryColor = useColorModeValue('black', 'white');
    const handleClose = () => {
        onClose();
        onHandleConfirm();
    };
    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent style={{ paddingTop: '24px', paddingBottom: '8px', minHeight: '350px' }}>
                {modalLabel.modalHeader && <ModalHeader>{modalLabel.modalHeader}</ModalHeader>}
                <ModalCloseButton />
                <ModalBody display="flex" alignItems="center" justifyContent="center" py={4}>
                    {isSuccess ? (
                        <VStack spacing={5}>
                            <Icon as={SendMailIcon} w="120px" h="80px" color="#DCDCDC" />
                            <Text color={textPrimaryColor} fontSize="14px" fontWeight="500">
                                {modalLabel.modalBodyMessage1}
                            </Text>
                            <VStack gap="0px" spacing={0}>
                                {modalLabel.modalBodyMessage2 && (
                                    <Text color={textPrimaryColor} fontSize="14px" fontWeight="500">
                                        {modalLabel.modalBodyMessage2}
                                    </Text>
                                )}
                                {modalLabel.modalBodyMessage3 && (
                                    <Text color={textPrimaryColor} fontSize="14px" fontWeight="500">
                                        {modalLabel.modalBodyMessage3}
                                    </Text>
                                )}
                            </VStack>
                        </VStack>
                    ) : (
                        <VStack spacing={5}>
                            <Icon as={WarningIcon} w="80px" h="80px" color="#DCDCDC" />
                            <VStack spacing={0}>
                                <Text color={textPrimaryColor} fontSize="14px" fontWeight="500">
                                    {modalLabel.modalBodyMessage1}
                                </Text>
                                {modalLabel.modalBodyMessage2 && (
                                    <Text color={textPrimaryColor} fontSize="14px" fontWeight="500">
                                        {modalLabel.modalBodyMessage2}
                                    </Text>
                                )}
                            </VStack>

                            {modalLabel.modalBodyMessage3 && (
                                <Text color={textPrimaryColor} fontSize="14px" fontWeight="500">
                                    {modalLabel.modalBodyMessage3}
                                </Text>
                            )}
                        </VStack>
                    )}
                </ModalBody>

                <ModalFooter display="flex" alignItems="center" justifyContent="center">
                    <HStack>
                        <Button
                            // colorScheme="gray.700"
                            variant="ghost"
                            fontSize="lg"
                            color={textPrimaryColor}
                            w="86px"
                            backgroundColor="secondaryGray.300"
                            borderRadius={10}
                            fontWeight="900"
                            ms="auto"
                            onClick={handleClose}
                        >
                            {confirmBtnLabel}
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
