import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendMachineRebootNotificationMutationVariables = Types.Exact<{
    input: Types.SendMachineRebootNotificationInput;
}>;

export type SendMachineRebootNotificationMutation = {
    __typename?: 'Mutation';
    send_machine_reboot_notification: Types.SuccessResponse;
};

export const SendMachineRebootNotificationDocument = gql`
    mutation sendMachineRebootNotification($input: SendMachineRebootNotificationInput!) {
        send_machine_reboot_notification(input: $input)
    }
`;
export type SendMachineRebootNotificationMutationFn = Apollo.MutationFunction<
    SendMachineRebootNotificationMutation,
    SendMachineRebootNotificationMutationVariables
>;

/**
 * __useSendMachineRebootNotificationMutation__
 *
 * To run a mutation, you first call `useSendMachineRebootNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMachineRebootNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMachineRebootNotificationMutation, { data, loading, error }] = useSendMachineRebootNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMachineRebootNotificationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SendMachineRebootNotificationMutation,
        SendMachineRebootNotificationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SendMachineRebootNotificationMutation, SendMachineRebootNotificationMutationVariables>(
        SendMachineRebootNotificationDocument,
        options,
    );
}
export type SendMachineRebootNotificationMutationHookResult = ReturnType<
    typeof useSendMachineRebootNotificationMutation
>;
export type SendMachineRebootNotificationMutationResult = Apollo.MutationResult<SendMachineRebootNotificationMutation>;
export type SendMachineRebootNotificationMutationOptions = Apollo.BaseMutationOptions<
    SendMachineRebootNotificationMutation,
    SendMachineRebootNotificationMutationVariables
>;
