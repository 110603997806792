import * as React from 'react';
import { ColumnHelper } from '@tanstack/react-table';
import { MachineIncomeRowObj } from '../variables';
import { Flex, Text } from '@chakra-ui/react';
import { fCurrencyWithoutLabel } from 'utils/format-number';
import { TFunction } from 'i18next';

export const CreateMachineReceiptTblColum = (
    isLargerThan960: boolean,
    isLargerThan320: boolean,
    columnHelper: ColumnHelper<MachineIncomeRowObj>,
    textColor: string,
    t: TFunction,
) =>
    isLargerThan960
        ? [
              columnHelper.accessor('machineName', {
                  id: 'machineName',
                  header: () => (
                      <Text
                          justifyContent="start"
                          align="start"
                          textTransform="capitalize"
                          fontSize="14px"
                          color="secondaryGray.600"
                      >
                          {t('machineNameLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex direction="column" minW={{ base: '100px', lg: '200px' }}>
                          <Text color={textColor} fontSize="16px" textTransform="uppercase" fontWeight="500">
                              {info.getValue()}
                          </Text>
                      </Flex>
                  ),
              }),
              // TODO: Maybe came back later
              // TODO: https://luxzajp.atlassian.net/browse/GPR-669
              // columnHelper.accessor('coinPlay', {
              //     id: 'coinPlay',
              //     header: () => (
              //         <Text
              //             justifyContent="start"
              //             align="start"
              //             textTransform="capitalize"
              //             fontSize="14px"
              //             color="secondaryGray.600"
              //         >
              //             {t('coinPlay', { ns: 'common' })}
              //         </Text>
              //     ),
              //     cell: (info) => (
              //         <Flex direction="column">
              //             <Text color={textColor} fontSize="16px" textTransform="capitalize" fontWeight="500">
              //                 {info.getValue()}
              //             </Text>
              //         </Flex>
              //     ),
              // }),
              // columnHelper.accessor('freePlay', {
              //     id: 'freePlay',
              //     header: () => (
              //         <Text
              //             justifyContent="start"
              //             align="start"
              //             textTransform="capitalize"
              //             fontSize="14px"
              //             color="secondaryGray.600"
              //         >
              //             {t('freePlay', { ns: 'common' })}
              //         </Text>
              //     ),
              //     cell: (info) => (
              //         <Flex direction="column">
              //             <Text color={textColor} fontSize="16px" textTransform="capitalize" fontWeight="500">
              //                 {info.getValue()}
              //             </Text>
              //         </Flex>
              //     ),
              // }),
              // columnHelper.accessor('timePlay', {
              //     id: 'timePlay',
              //     header: () => (
              //         <Text
              //             justifyContent="start"
              //             align="start"
              //             textTransform="capitalize"
              //             fontSize="14px"
              //             color="secondaryGray.600"
              //         >
              //             {t('timePlay', { ns: 'common' })}
              //         </Text>
              //     ),
              //     cell: (info) => (
              //         <Flex direction="column">
              //             <Text color={textColor} fontSize="16px" textTransform="capitalize" fontWeight="500">
              //                 {info.getValue()}
              //             </Text>
              //         </Flex>
              //     ),
              // }),
              columnHelper.accessor('amount', {
                  id: 'amount',
                  header: () => (
                      <Text justifyContent="space-between" align="end" fontSize={{ sm: '14px' }} color="gray.400">
                          {t('amountLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex direction="column">
                          <Text color={textColor} fontSize="sm" fontWeight="500">
                              {fCurrencyWithoutLabel(info.getValue())}
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('btn', {
                  id: 'btn',
                  header: () => <></>,
                  cell: (info) => info.getValue(),
              }),
          ]
        : isLargerThan320
        ? [
              columnHelper.accessor('machineName', {
                  id: 'machineName',
                  header: () => (
                      <Text
                          justifyContent="start"
                          align="start"
                          textTransform="capitalize"
                          fontSize="14px"
                          color="secondaryGray.600"
                      >
                          {t('machineNameLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex direction="column" minW="250px">
                          <Text color={textColor} fontSize="16px" textTransform="capitalize" fontWeight="500">
                              {info.getValue()}
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('amount', {
                  id: 'amount',
                  header: () => (
                      <Text
                          justifyContent="space-between"
                          align="end"
                          textTransform="capitalize"
                          fontSize="14px"
                          color="secondaryGray.600"
                      >
                          {t('amountLabel', { ns: 'common' })}
                      </Text>
                  ),
                  cell: (info) => (
                      <Flex direction="column">
                          <Text color={textColor} fontSize="16px" textTransform="capitalize" fontWeight="500">
                              {fCurrencyWithoutLabel(info.getValue())}
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('btn', {
                  id: 'btn',
                  header: () => <></>,
                  cell: (info) => info.getValue(),
              }),
          ]
        : [
              columnHelper.accessor('common', {
                  id: 'common',
                  header: () => <></>,
                  cell: (info) => (
                      <Flex direction="column">
                          <Text color={textColor} fontSize="16px" textTransform="uppercase" fontWeight="500">
                              {info.getValue()[0]}
                          </Text>

                          <Text color={textColor} fontSize="16px" textTransform="uppercase" fontWeight="500">
                              {info.getValue()[1]}
                          </Text>
                      </Flex>
                  ),
              }),
              columnHelper.accessor('btn', {
                  id: 'btn',
                  header: () => <></>,
                  cell: (info) => info.getValue(),
              }),
          ];
