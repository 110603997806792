import { Flex, FormControl, FormErrorMessage, Select, useColorModeValue } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectProps } from '@chakra-ui/select/src/select';
import { ChangeEvent } from 'react';

type RHFLanguageSelectWithoutLabelProps = SelectProps & {
    name: string;
};

export function RHFLanguageSelectWithoutLabel({ name, ...other }: RHFLanguageSelectWithoutLabelProps) {
    const { control, setValue } = useFormContext();
    useColorModeValue('secondaryGray.900', 'white');
    const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setValue(name, event.target.value);
    };
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl isInvalid={!!error}>
                    <Flex direction="column">
                        <Select
                            {...field}
                            {...other}
                            onChange={(event) => handleOnChange(event)}
                            fontSize="sm"
                            id="currency"
                            variant="main"
                            h="44px"
                            maxH="44px"
                            // defaultValue="ja"
                        >
                            <option value="de">Deutsch</option>
                            <option value="en">English</option>
                            <option value="es">Español</option>
                            <option value="fr">Français</option>
                            <option value="ja">日本語</option>
                            <option value="zh">中文</option>
                        </Select>
                        <FormErrorMessage>{error && error.message}</FormErrorMessage>
                    </Flex>
                </FormControl>
            )}
        />
    );
}
