import * as Types from '../../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShopIncomeCollectionEventListQueryVariables = Types.Exact<{
    input: Types.GetShopIncomeCollectionEventListInput;
}>;

export type GetShopIncomeCollectionEventListQuery = {
    __typename?: 'Query';
    get_shop_income_collection_event_list: {
        __typename?: 'ProShopIncomeCollectionEventConnection';
        totalCount: number;
        pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
        edges: Array<{
            __typename?: 'ProShopIncomeCollectionEventEdge';
            cursor: string;
            node: {
                __typename?: 'ProShopIncomeCollectionEvent';
                id: string;
                eventName: string;
                automaticallyCollectedTotalCount: number;
                manuallyInputTotalCount: number;
                lastMachineIncomeCollectionEventDate?: Date | null;
            };
        } | null>;
    };
};

export const GetShopIncomeCollectionEventListDocument = gql`
    query getShopIncomeCollectionEventList($input: GetShopIncomeCollectionEventListInput!) {
        get_shop_income_collection_event_list(input: $input) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    eventName
                    automaticallyCollectedTotalCount
                    manuallyInputTotalCount
                    lastMachineIncomeCollectionEventDate
                }
            }
        }
    }
`;

/**
 * __useGetShopIncomeCollectionEventListQuery__
 *
 * To run a query within a React component, call `useGetShopIncomeCollectionEventListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopIncomeCollectionEventListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopIncomeCollectionEventListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShopIncomeCollectionEventListQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetShopIncomeCollectionEventListQuery,
        GetShopIncomeCollectionEventListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetShopIncomeCollectionEventListQuery, GetShopIncomeCollectionEventListQueryVariables>(
        GetShopIncomeCollectionEventListDocument,
        options,
    );
}
export function useGetShopIncomeCollectionEventListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetShopIncomeCollectionEventListQuery,
        GetShopIncomeCollectionEventListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetShopIncomeCollectionEventListQuery, GetShopIncomeCollectionEventListQueryVariables>(
        GetShopIncomeCollectionEventListDocument,
        options,
    );
}
export type GetShopIncomeCollectionEventListQueryHookResult = ReturnType<
    typeof useGetShopIncomeCollectionEventListQuery
>;
export type GetShopIncomeCollectionEventListLazyQueryHookResult = ReturnType<
    typeof useGetShopIncomeCollectionEventListLazyQuery
>;
export type GetShopIncomeCollectionEventListQueryResult = Apollo.QueryResult<
    GetShopIncomeCollectionEventListQuery,
    GetShopIncomeCollectionEventListQueryVariables
>;
