import { ShopDetailProps } from '../shopDetailTypes';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { Box, Button, Flex, HStack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useTranslation } from 'react-i18next';
import { useEditIncomeConversionRateMutation } from '../../../../../../graphql/mutations/shop/__generated__/editIncomeConversionRate.generated';
import { GetShopByIdDocument } from '../../../../../../graphql/queries/shop/__generated__/getShopById.generated';
import { AccountType } from '../../../../../../types/user';
import FormProvider, { RHFTextField } from '../../../../../../components/hook-form';
import * as Yup from 'yup';
import { ChangeEvent, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

type UpdateIncomeConversionRateControlFeatureProps = ShopDetailProps & {
    isEdit: boolean;
    onEditDisable: () => void;
    [key: string]: any;
};
export default function UpdateIncomeConversionRateControlFeature(props: UpdateIncomeConversionRateControlFeatureProps) {
    const { shop, isEdit, onEditDisable } = props;
    const { accountType } = useAuthContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const { t } = useTranslation(['common', 'shop']);
    const {
        isOpen: isOpenEditIncomeConversionRateResult,
        onOpen: onOpenEditIncomeConversionRateResult,
        onClose: onCloseEditIncomeConversionRateResult,
    } = useDisclosure();
    const [editIncomeConversionRateMutationFunc, editIncomeConversionRateMutationResult] =
        useEditIncomeConversionRateMutation();

    const IncomeConversionRateSchema = Yup.object().shape({
        convertIncome: Yup.number().notRequired(),
    });

    const defaultValues = useMemo(
        () => ({
            convertIncome: shop.incomeConversionRate ?? 1,
        }),
        [],
    );

    const methods = useForm({
        resolver: yupResolver(IncomeConversionRateSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        setValue,
        watch,
    } = methods;
    const values = watch();
    const onHandleEditIncomeConversionRate = async (data: { convertIncome: number }) => {
        try {
            await editIncomeConversionRateMutationFunc({
                variables: {
                    input: {
                        shopId: shop.id,
                        incomeConversionRate: data.convertIncome ? Number(data.convertIncome) : undefined,
                    },
                },
                onCompleted: async (res) => {
                    if (res) {
                        onEditDisable();
                        onOpenEditIncomeConversionRateResult();
                    }
                },
                onError: async () => {
                    onOpenEditIncomeConversionRateResult();
                },
                refetchQueries: () => [
                    {
                        query: GetShopByIdDocument,
                        variables: {
                            input: {
                                shopId: shop.id,
                            },
                        },
                    },
                ],
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleConvertIncomeInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        const trimmedString = numericValue.replace(/^0+/, '');
        setValue('convertIncome', Number(trimmedString));
    };

    return (
        <ApolloMutationBoundary
            isOpen={isOpenEditIncomeConversionRateResult}
            onClose={onCloseEditIncomeConversionRateResult}
            onOpen={onOpenEditIncomeConversionRateResult}
            mutationResult={editIncomeConversionRateMutationResult}
            toastSuccessTitle={t('editIncomeConventionRateSuccessTitle', { ns: 'shop' })}
            toastSuccessDescription={t('editIncomeConventionRateSuccessMessage', { ns: 'shop' })}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onHandleEditIncomeConversionRate)}>
                {accountType === AccountType.dealer &&
                    (isEdit ? (
                        <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" w="100%" gap={1}>
                            <HStack justifyContent="flex-start" alignItems="center" w="100%">
                                <Box minW="125px">
                                    <Text
                                        color={textColorSecondary}
                                        fontSize="14px"
                                        fontWeight="400"
                                        mr={3}
                                        alignItems="center"
                                    >
                                        {t('convertIncomeLabel', { ns: 'common' })}
                                    </Text>
                                </Box>

                                <RHFTextField
                                    name="convertIncome"
                                    isRequired={false}
                                    borderRadius={'10px !important'}
                                    fontWeight="500"
                                    variant="main"
                                    // maxW={'240px'}
                                    value={values.convertIncome}
                                    _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                                    maxH={'40px'}
                                    onChange={handleConvertIncomeInputChange}
                                />
                            </HStack>

                            <HStack
                                justifyContent={{ base: 'flex-start', sm: 'flex-start', md: 'flex-end' }}
                                alignItems="center"
                                w="100%"
                            >
                                <Box display="flex" flexDirection="row">
                                    <Button
                                        py="0px"
                                        px="8px"
                                        ms="auto"
                                        variant="brand"
                                        fontWeight="500"
                                        minW="125px"
                                        maxH="35px"
                                        borderRadius="10px"
                                        backgroundColor="#4318FF"
                                        type="submit"
                                        isLoading={isSubmitting}
                                        isDisabled={
                                            shop.incomeConversionRate === Number(values.convertIncome) ||
                                            Number(values.convertIncome) === 0
                                        }
                                    >
                                        <Text fontSize="16px">{t('saveLabel', { ns: 'common' })}</Text>
                                    </Button>
                                </Box>
                            </HStack>
                        </Flex>
                    ) : (
                        <HStack justifyContent="flex-start" alignItems="center" w="100%">
                            <Box minW="125px">
                                <Text color={textColorSecondary} fontSize="14px" fontWeight="400" mr={3}>
                                    {t('convertIncomeLabel', { ns: 'common' })}
                                </Text>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Text color={textColorPrimary} fontSize="16px" fontWeight="700">
                                    {shop.incomeConversionRate}
                                </Text>
                            </Box>
                        </HStack>
                    ))}
            </FormProvider>
        </ApolloMutationBoundary>
    );
}
