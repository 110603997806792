import {
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Usa from 'assets/img/dashboards/ic_flag_us.svg';
import Japan from 'assets/img/dashboards/ic_flag_ja.svg';
import German from 'assets/img/dashboards/ic_flag_de.svg';
import * as React from 'react';

export default function LanguagePopover() {
    const { i18n } = useTranslation(['dashboard']);
    const separatorColor = useColorModeValue('#E9EDF7', '#E9EDF7');
    const menuBg = useColorModeValue('#E9EDF7', 'navy.800');
    return (
        <Flex
            // width="50px"
            // height="30px"
            // // backgroundColor="#000054"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            alignSelf="center"
            paddingX={3}
        >
            <Menu>
                <MenuButton>
                    <Image
                        width="26px"
                        height="19px"
                        borderRadius={1}
                        borderColor={menuBg}
                        border="solid 1px #E9EDF7"
                        src={i18n.language === 'en' ? Usa : i18n.language === 'ja' ? Japan : German}
                    />
                </MenuButton>
                <MenuList
                    mt="10px"
                    py="20px"
                    borderRadius="20px"
                    border="none"
                    minW="200px"
                    bg={menuBg}
                    borderColor={separatorColor}
                    defaultValue={i18n.language}
                >
                    <MenuOptionGroup defaultValue={i18n.language} type="radio">
                        <MenuItemOption onClick={() => i18n.changeLanguage('en')} value="en">
                            English
                        </MenuItemOption>
                        <MenuItemOption onClick={() => i18n.changeLanguage('ja')} value="ja">
                            日本語
                        </MenuItemOption>
                        <MenuItemOption onClick={() => i18n.changeLanguage('de')} value="de">
                            Deutsch
                        </MenuItemOption>
                    </MenuOptionGroup>
                </MenuList>
            </Menu>
        </Flex>
    );
}
