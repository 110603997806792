import React from 'react';
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Icon,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import Content from 'components/sidebar/components/Content';
import { renderThumb, renderTrack, renderView } from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { AiOutlineMenu } from 'react-icons/ai';

function Sidebar(props: { routes: RoutesType[]; [x: string]: any }) {
    const { routes } = props;
    const variantChange = '0.2s linear';
    const shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
    const sidebarBg = useColorModeValue('white', 'navy.800');
    const sidebarRadius = '0px';
    const sidebarMargins = '0px';
    // SIDEBAR
    return (
        <Box display={{ sm: 'none', '2xl': 'block' }} position="fixed" minH="100%">
            <Box
                bg={sidebarBg}
                transition={variantChange}
                w="285px"
                ms={{
                    sm: '16px',
                }}
                my={{
                    sm: '16px',
                }}
                h="calc(100vh - 32px)"
                m={sidebarMargins}
                borderRadius={sidebarRadius}
                minH="100%"
                overflowX="hidden"
                boxShadow={shadow}
            >
                <Scrollbars
                    autoHide
                    renderTrackVertical={renderTrack}
                    renderThumbVertical={renderThumb}
                    renderView={renderView}
                >
                    <Content routes={routes} />
                </Scrollbars>
            </Box>
        </Box>
    );
}

// FUNCTIONS
export function SidebarResponsive(props: { routes: RoutesType[]; [key: string]: any }) {
    const sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
    const menuColor = useColorModeValue('navy.650', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    const { routes, ...rest } = props;

    return (
        <Flex display={{ sm: 'flex', '2xl': 'none' }} alignItems="center" {...rest}>
            <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
                <Icon
                    as={AiOutlineMenu}
                    color={menuColor}
                    my="auto"
                    w="26px"
                    h="24px"
                    me="10px"
                    _hover={{ cursor: 'pointer' }}
                />
            </Flex>
            <Drawer
                isOpen={isOpen}
                onClose={onClose}
                placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent
                    w="285px"
                    maxW="285px"
                    ms={{
                        sm: '16px',
                    }}
                    my={{
                        sm: '16px',
                    }}
                    borderRadius="16px"
                    bg={sidebarBackgroundColor}
                >
                    <DrawerCloseButton
                        zIndex="3"
                        onClick={onClose}
                        _focus={{ boxShadow: 'none' }}
                        _hover={{ boxShadow: 'none' }}
                    />
                    <DrawerBody maxW="285px" px="0rem" pb="0">
                        <Scrollbars
                            autoHide
                            renderTrackVertical={renderTrack}
                            renderThumbVertical={renderThumb}
                            renderView={renderView}
                        >
                            <Content routes={routes} />
                        </Scrollbars>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
}

export default Sidebar;
