import {
    Box,
    Button,
    Flex,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    useColorModeValue,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { DealerDetailProps } from './dealerDetailTypes';
import { DealerStatus } from 'types/graphql';
import { ApolloMutationBoundary } from 'components/graphql/ApolloMutationBoundary';
import { useProhibitDealerLoginMutation } from 'graphql/mutations/distributor/__generated__/prohibitDealerLogin.generated';
import { useLiftDealerLoginProhibitionMutation } from 'graphql/mutations/distributor/__generated__/liftDealerLoginProhibition.generated';
import { useAuthContext } from 'layouts/auth/useAuthContext';
import { AccountType } from 'types/user';
import DeleteDealerControlFeature from './DeleteDealerControlFeature';
import { useTranslation } from 'react-i18next';
import GranProEditBtn from '../../../../../../components/button/GranProEditBtn';
import React from 'react';
import { useEditable } from '../../../../../../components/hooks/useEditable';
import EnableStatus from '../../../../../../components/elements/EnableStatus';
import { resolverDealerStatusToStr } from '../../../../../../utils/resolver-enum';
import DisableStatus from '../../../../../../components/elements/DisableStatus';
import { AlertIcon } from '../../../../../../components/icons/Icons';

export default function DealerControlCard(props: DealerDetailProps) {
    const { dealer } = props;
    const { accountType } = useAuthContext();
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { t } = useTranslation(['common', 'dealer']);

    const { isEdit, onChangeEditable, onEditDisable, ...other } = useEditable(false);

    return (
        <Card py={8} px={{ base: '20px', lg: '30px', xl: '40px', '2xl': '50px' }} borderRadius="30px">
            <Flex direction={'row'} justifyContent="space-between" alignItems="stretch" w={'100%'}>
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                    {t('control', { ns: 'common' })}
                </Text>
                <GranProEditBtn
                    isEdit={isEdit}
                    onChangeEditable={onChangeEditable}
                    disabled={accountType !== AccountType.distributor}
                    onEditDisable={onEditDisable}
                    {...other}
                />
            </Flex>

            <Flex
                alignItems="stretch"
                direction="column"
                mb={{ base: '20px' }}
                gap={5}
                justifyContent="center"
                w="100%"
                mt={5}
            >
                {accountType === AccountType.distributor && (
                    <ControlFeature
                        dealer={dealer}
                        featureName={
                            dealer.status === DealerStatus.Enabled
                                ? `${t('disableDealerAccountLabel', { ns: 'dealer' })}`
                                : `${t('enableDealerAccountLabel', { ns: 'dealer' })}`
                        }
                        btnName={
                            dealer.status === DealerStatus.Enabled
                                ? `${t('disable', { ns: 'common' })}`
                                : `${t('statusOption2', { ns: 'common' })}`
                        }
                        isEdit={isEdit}
                        isDisabled={!isEdit}
                        btnColorScheme={dealer.status === DealerStatus.Enabled ? 'red' : 'green'}
                    />
                )}
            </Flex>
        </Card>
    );
}

function ControlFeature(
    props: DealerDetailProps & {
        isDisabled: boolean;
        featureName: string;
        btnName: string;
        btnColorScheme?: string;
        isEdit: boolean;
        [x: string]: any;
    },
) {
    const { dealer, isDisabled, featureName, btnName, btnColorScheme = 'red', isEdit, ...rest } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation(['common', 'dealer']);
    const {
        isOpen: isOpenChangeDealerStatusResult,
        onOpen: onOpenChangeDealerStatusResult,
        onClose: onCloseChangeDealerStatusResult,
    } = useDisclosure();
    const textColor = useColorModeValue('secondaryGray.700', 'white');
    const stopBtnColor = '#D11828';
    const cancelBtnColor = '#A3AED0';
    const modalBtnHover = useColorModeValue(
        { color: 'secondaryGray.900', bg: '#A3AED2' },
        { color: '#000000', bg: '#A3AED2' },
    );
    const disableBtnColorPrimary = '#D11828';
    const enableBtnColorPrimary = '#01B574';

    const [prohibitDealerLoginMutation, prohibitDealerMutationResult] = useProhibitDealerLoginMutation();
    const [liftDealerLoginFunc, liftDealerMutationResult] = useLiftDealerLoginProhibitionMutation();

    const {
        isOpen: isOpenConfirmDeleteDealer,
        onOpen: onOpenConfirmDeleteDealer,
        onClose: onCloseConfirmDeleteDealer,
    } = useDisclosure();
    const onHandleChangeDealerState = async () => {
        try {
            if (dealer.status === DealerStatus.OutOfService) {
                onClose();
                await liftDealerLoginFunc({
                    variables: {
                        input: {
                            dealerId: dealer.id,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onOpenChangeDealerStatusResult();
                        }
                    },
                    onError: async () => {
                        onOpenChangeDealerStatusResult();
                    },
                    // refetchQueries: () => [
                    //     {
                    //         query: GetDealerByIdDocument,
                    //         variables: {
                    //             input: {
                    //                 dealerId: dealer.id,
                    //             },
                    //         },
                    //     },
                    // ],
                });
            } else if (dealer.status === DealerStatus.Enabled) {
                onClose();
                await prohibitDealerLoginMutation({
                    variables: {
                        input: {
                            dealerId: dealer.id,
                        },
                    },
                    onCompleted: async (res) => {
                        if (res) {
                            onOpenChangeDealerStatusResult();
                        }
                    },
                    onError: async () => {
                        onOpenChangeDealerStatusResult();
                    },
                    // refetchQueries: () => [
                    //     {
                    //         query: GetDealerByIdDocument,
                    //         variables: {
                    //             input: {
                    //                 dealerId: dealer.id,
                    //             },
                    //         },
                    //     },
                    // ],
                });
                // onClose();
            }
        } catch (error) {
            onClose();
            console.error(error);
        }
    };

    const onHandleSuccess = () => {
        window.location.reload();
    };

    return (
        <div style={{ width: '100%' }}>
            <ApolloMutationBoundary
                isOpen={isOpenChangeDealerStatusResult}
                onClose={onCloseChangeDealerStatusResult}
                onOpen={onOpenChangeDealerStatusResult}
                onExtraAfterSuccessHandler={onHandleSuccess}
                mutationResult={
                    dealer.status === DealerStatus.OutOfService
                        ? liftDealerMutationResult
                        : prohibitDealerMutationResult
                }
                toastSuccessDescription={
                    dealer.status === DealerStatus.OutOfService
                        ? t('liftedDealerSuccessfully', { ns: 'dealer' })
                        : t('prohibitDealerSuccessfully', { ns: 'dealer' })
                }
                toastSuccessTitle={
                    dealer.status === DealerStatus.OutOfService
                        ? t('dealerEnabledTitle', { ns: 'dealer' })
                        : t('dealerDisableTitle', { ns: 'dealer' })
                }
            >
                <Box
                    display="flex"
                    flexDirection={{ base: 'column', md: 'row' }}
                    gap={{ base: 5, md: 5, xl: 12, '2xl': 15 }}
                    justifyContent="flex-start"
                    w="100%"
                >
                    <HStack>
                        <Box minW={{ base: '125px', md: '95px' }}>
                            <Text color={textColor} fontSize="14px" me="4px" fontWeight="400">
                                Dealer Status
                            </Text>
                        </Box>

                        <Flex align="center" justifyContent="center" style={{ ...rest }}>
                            {dealer.status === DealerStatus.Enabled ? (
                                <EnableStatus enableTextValue={resolverDealerStatusToStr(dealer.status, t)} />
                            ) : (
                                <DisableStatus disableTextValue={resolverDealerStatusToStr(dealer.status, t)} />
                            )}
                        </Flex>
                    </HStack>
                    <Box gap={5} display="flex" alignItems="flex-start">
                        {dealer.status === DealerStatus.Enabled && !isDisabled && (
                            <Button
                                borderColor={disableBtnColorPrimary}
                                color={disableBtnColorPrimary}
                                variant="outline"
                                fontSize="sm"
                                h="40px"
                                minW="125px"
                                borderRadius={10}
                                fontWeight="500"
                                onClick={onOpen}
                                disabled={isDisabled}
                                borderWidth="2px"
                            >
                                {btnName}
                            </Button>
                        )}

                        {dealer.status === DealerStatus.OutOfService && !isDisabled && (
                            <Flex
                                direction={{ base: 'row', md: 'column' }}
                                justifyContent={{ base: 'center', md: 'center' }}
                                gap={1}
                                w="100%"
                            >
                                <Button
                                    // colorScheme={btnColorScheme}
                                    variant="outline"
                                    borderColor={enableBtnColorPrimary}
                                    color={enableBtnColorPrimary}
                                    fontSize="sm"
                                    h="40px"
                                    minW="125px"
                                    borderRadius={10}
                                    borderWidth="2px"
                                    fontWeight="500"
                                    onClick={onHandleChangeDealerState}
                                    disabled={isDisabled}
                                >
                                    {btnName}
                                </Button>

                                <Button
                                    variant="outline"
                                    borderColor={disableBtnColorPrimary}
                                    color={disableBtnColorPrimary}
                                    fontSize="sm"
                                    h="40px"
                                    minW="125px"
                                    borderRadius={10}
                                    borderWidth="2px"
                                    fontWeight="500"
                                    onClick={onOpenConfirmDeleteDealer}
                                    disabled={isDisabled}
                                >
                                    {t('delete', { ns: 'common' })}
                                </Button>
                            </Flex>
                        )}
                    </Box>
                </Box>
                <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton />
                        <ModalBody w="100%" minH={'300px'}>
                            <Flex
                                align="center"
                                direction="column"
                                justifyContent="center"
                                w="100%"
                                minH={'300px'}
                                gap={12}
                            >
                                <VStack gap={1}>
                                    <Icon as={AlertIcon} w="50px" h="42px" color={'red.550'} mb={2} />
                                    <Text
                                        textAlign="center"
                                        color={textColor}
                                        fontSize={{ base: '14px', lg: '16px' }}
                                        fontWeight="500"
                                        lineHeight="100%"
                                    >
                                        {t('disableModalMessage1', { ns: 'dealer' })}
                                    </Text>

                                    <Text
                                        textAlign="center"
                                        color={textColor}
                                        fontSize={{ base: '14px', lg: '16px' }}
                                        fontWeight="500"
                                        lineHeight="100%"
                                    >
                                        {t('disableModalMessage2', { ns: 'dealer' })}
                                    </Text>
                                </VStack>

                                <HStack gap={7}>
                                    <Button
                                        backgroundColor={cancelBtnColor}
                                        variant="ghost"
                                        color="white"
                                        fontSize="sm"
                                        borderRadius={10}
                                        fontWeight="500"
                                        minW="140px"
                                        onClick={onClose}
                                        _hover={modalBtnHover}
                                    >
                                        {t('cancelBtn', { ns: 'common' })}
                                    </Button>

                                    <Button
                                        backgroundColor={stopBtnColor}
                                        variant="ghost"
                                        color="white"
                                        fontSize="sm"
                                        borderRadius="10px"
                                        fontWeight="700"
                                        minW="140px"
                                        _hover={modalBtnHover}
                                        // isLoading={isSubmitting}
                                        onClick={onHandleChangeDealerState}
                                    >
                                        {t('changeDisableLabel', { ns: 'common' })}
                                    </Button>
                                </HStack>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </ApolloMutationBoundary>
            <DeleteDealerControlFeature
                isOpen={isOpenConfirmDeleteDealer}
                onClose={onCloseConfirmDeleteDealer}
                onOpen={onOpenConfirmDeleteDealer}
                dealer={dealer}
            />
        </div>
    );
}
